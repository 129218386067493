import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { TutoriaisPage } from '../pages/dashboard/TutoriaisPage'
import { MenuTestPage } from '../pages/MenuTestPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const CompanyPage = lazy(() => import('../modules/profile/CompanyPage'))
  const CommumPage = lazy(() => import('../modules/profile/CommumPage'))
  const ActionPage = lazy(() => import('../modules/profile/ActionsPage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/tutoriais' component={TutoriaisPage} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/company/' component={CompanyPage} />
        <Route path='/crafted/pages/action/' component={ActionPage} />
        <Route path='/crafted/pages/commum/' component={CommumPage} />
        {/* <Route path='/crafted/pages' component={ProfilePage} /> */}
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
