import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../helpers';
import { useIntl } from 'react-intl'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 400,
  maxHeight: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

interface IModalMessageProps {
  showModal: boolean;
  title: string;
  message: string;
  loading?: boolean
  onClose: () => void;
  onAction: () => void;
}

const ModalMessageConfirm: React.FC<IModalMessageProps> = ({
  ...props
}) => {
  const intl = useIntl();   

  return(
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
          <span className="modal-title fw-bold fs-3">{props.title}</span>
          
          {!props.loading?
            <div
              className="btn btn-icon btn-sm btn-active-light-primary"
              onClick={()=>props.onClose()}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
            :null
          }
        </div>
        <div className='px-5 my-10 text-center' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 350}}>
          <span className='text-gray-700 fw-bold fs-4'>{props.message}</span>
        </div>
        <div className='d-flex flex-row w-100 justify-content-end p-5'>
          
          
          
          
          
          
          {/* <button type='button' onClick={()=>props.onAction()} className='btn btn-md btn-primary me-3'>
            Yes
          </button> */}

          <button type='button' onClick={()=>props.onAction()} className={'btn btn-md me-3 '+(!props.loading?'btn-primary':'btn-warning')} disabled={props.loading}>
            {!props.loading && <span className='indicator-label text-dark'>{intl.formatMessage({id: 'Yes'})}</span>}
            {props.loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'wait'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          
          
          {!props.loading ? 
            <button type='button' onClick={()=>props.onClose()} className='btn btn-md btn-danger'>{intl.formatMessage({id: 'No'})}</button>
            :
            null
          }



          
        </div>
      </Box>
    </Modal>
  )
}
export default ModalMessageConfirm;