/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Box, Modal } from '@mui/material';
import { KTSVG } from '../../../../helpers';
import { useIntl } from 'react-intl'


const style = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 500,
  maxHeight: 400,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 3,
  boxShadow: 24,
  outline: 'none'
};


type Props = {
  users: any;
  openModal: any;
  closeModal: (action: boolean) => void;
}

const ModalReportUser: React.FC<Props> = ({...props}) => {
  const intl = useIntl();      
  const [dataUsers, setDataUsers] = useState <any> ();
  const [viewUsers, setViewUsers] = useState(true);

  let activesUsers: any = 0;
  let inactivesUsers: any = 0;
  let deletedUsers: any = 0;

  let dpos: any = 0;
  let companies: any = 0;
  let empresasPrestadorasDeServico: any = 0;
  let startups: any = 0;
  let liberalProfessionais: any = 0;
  let adm: any = 0;

  useEffect(() => {
   setDataUsers(props.users.data)
  }, [props.users])
  dataUsers?.map((item: any, index: any)=>{
    item.status === 'active' ? activesUsers ++ : (item.deleted_users ? deletedUsers ++ : inactivesUsers ++)
  })
  return (
    <>
      
      <Modal
          open={props.openModal}
          onClose={()=>{}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >

            <Box sx={style}>
                <div className='d-flex justify-content-between align-items-center p-2'>
                    <div className='p-5'>
                    {
                        viewUsers ?
                        <p className='fw-bolder fs-4'> {intl.formatMessage({id: 'Number of Users'})}</p>
                        :
                        <p className='fw-bolder fs-4'> {intl.formatMessage({id: 'Types of Users'})}</p>

                    }
                    </div>
                    <div
                    className="btn btn-icon btn-sm btn-active-light-primary "
                    onClick={()=>[props.closeModal(false)]}
                    >
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                    />
                    </div>
                </div>

               <div style={{overflowY: "scroll", maxHeight: '200px'}}>
               {
                viewUsers ?
                <div className='p-5'>
                    <p className='fs-4'><span className='fw-bolder'>{intl.formatMessage({id: 'Total'})}: </span>{dataUsers?.length}</p>
                    <p className='fs-4'><span className='fw-bolder'>{intl.formatMessage({id: 'Active'})}: </span>{activesUsers}</p>
                    <p className='fs-4'><span className='fw-bolder'>{intl.formatMessage({id: 'Inactive'})}: </span>{inactivesUsers}</p>
                    <p className='fs-4'><span className='fw-bolder'>{intl.formatMessage({id: 'Deleted'})}: </span>{inactivesUsers}</p>
                </div>
                :
                <div className='p-5'>
                    <p className='fs-4'><span className='fw-bolder'>{intl.formatMessage({id: 'DPO'})}: </span>{dataUsers?.length}</p>
                    <p className='fs-4'><span className='fw-bolder'>{intl.formatMessage({id: 'Company'})}: </span>{activesUsers}</p>
                    <p className='fs-4'><span className='fw-bolder'>{intl.formatMessage({id: 'Empresa Prestadora de Serviço'})}: </span>{inactivesUsers}</p>
                    <p className='fs-4'><span className='fw-bolder'>{intl.formatMessage({id: 'Startup'})}: </span>{inactivesUsers}</p>
                    <p className='fs-4'><span className='fw-bolder'>{intl.formatMessage({id: 'Liberal Professional'})}: </span>{dataUsers?.length}</p>
                    <p className='fs-4'><span className='fw-bolder'>{intl.formatMessage({id: 'Admin'})}: </span>{activesUsers}</p>
                    <p className='fs-4'><span className='fw-bolder'>{intl.formatMessage({id: 'Empresa Prestadora de Serviço'})}: </span>{inactivesUsers}</p>
                    <p className='fs-4'><span className='fw-bolder'>{intl.formatMessage({id: 'Startup'})}: </span>{inactivesUsers}</p>
                </div>
               }
               </div>

               <div className='d-flex justify-content-between'>
               <button
                className='btn btn-sm text-black'
                onClick={()=>[setViewUsers(true)]}
                disabled={viewUsers}
                >
                    <KTSVG
                    path="/media/icons/duotune/arrows/arr002.svg"
                    className="svg-icon svg-icon-2x"/>
                </button>
                <button
                className='border-0 btn btn-sm'
                onClick={()=>[setViewUsers(false)]}
                disabled={!viewUsers}
                >
                    <KTSVG
                    path="/media/icons/duotune/arrows/arr001.svg"
                    className="svg-icon svg-icon-2x"/>
                </button>
               </div>
                    
                   
            </Box>
          </Modal>          
      
    </>
  )
}

export {ModalReportUser}
