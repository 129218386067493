/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {loginCripted, loginNormal} from '../redux/AuthCRUD'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import { handshake } from "../../helpers/encrypt-handshake/handshake";
import EmailValidation from './EmailValidation'
import { Box, Modal } from '@mui/material'

const loginSchema = Yup.object().shape({
  // email: Yup.string()
  //   .email('The value is not a valid email address')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Email is required'),
  // password: Yup.string()
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Password is required'),
})
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '45%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  maxHeight: 350,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

const initialValues = {
  email: '',
  password: ''
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [messageEmail, setMessageEmail] = useState<any>('');
  const dispatch = useDispatch()
  const intl = useIntl();   
  const [seePassword, setSeePassword] = useState('password');

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      
      setLoading(true)

      loginNormal(values).then((data) => {
        
        if(data.status === 200){        
          toast.success(`${intl.formatMessage({id: 'Você fez login com sucesso'})}` );
          localStorage.setItem("token", data.data.access_token);
          dispatch(auth.actions.login(data.data.access_token));
        }
        
      }).catch((error) => {

        if (error.response) {

          if(error.response.data.status === "error_mail_check"){
            setOpenModal(true)
            setMessageEmail(error.response.data.message)
          } else {
            toast.error(error.response.data.error);
            toast.error(error.response.data.message);
          }

        } else {
          console.log(error)
        }
       
        setSubmitting(false)
        
      }).finally(() =>{
        setLoading(false)
      });
   


      // handshake(JSON.stringify(values)).then(vData => {
        
      //   loginCripted(vData).then((data) => {

      //     setLoading(false);
          
      //     if(data.statusText === 'OK'){
            
      //       setTimeout(() => {
      //         toast.success('you have successfully logged in!');
      //       }, 1000)

      //       localStorage.setItem("token", data.data.access_token);
      //       dispatch(auth.actions.login(data.data.access_token));
      //     }
      
      //   }).catch((error) => {
      //     if(error.response.data.status === "error_mail_check"){

      //       setOpenModal(true)
      //       setMessageEmail(error.response.data.message)
      //     }
      //     setLoading(false)
      //     setSubmitting(false)
      //     if(error.response){
      //       // console.log(error.response.data.status)
      //       toast.error(error.response.data.error);
      //       toast.error(error.response.data.message);
      //     }

      //   });

      // }).catch((error) => {
      //   console.log(error) 
      // }).finally(() =>{
      //   // console.log('finish')
      // });


      

    },

    
  })

  return (
    <div className='d-flex flex-column flex-column-fluid w-100 bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed '
    style={{
      backgroundImage:`url(${process.env.PUBLIC_URL + '/media/logos/background.png'})`,
      backgroundPosition: 'center',
      backgroundColor: '#1E1E2D',
      backgroundSize: 'cover' 
    }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20'>
        {/* <div className='mb-5'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/breven_law_color_logo.svg')} className='h-95px' />
        </div> */}
        <div className='w-lg-457px bg-white rounded-5 shadow-sm p-10 p-lg-15 mx-auto' style={{maxWidth: '480px'}}>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            <div className='mb-10'>
              <h1 className='text-dark mb-3 fs-1'>{intl.formatMessage({id: 'Entrar'})}</h1>
              <div className='text-dark fw-bold fs-4'>
              {intl.formatMessage({id: 'Novo aqui?'})}{' '}
                <Link to='/auth/registration' className='link-dark fw-bold'>
                <span className='text-primary text-hover-secondary'>{intl.formatMessage({id: 'Criar uma conta'})}</span>
                </Link>
              </div>
            </div>

            {/* {formik.status ? (
              <div className='mb-lg-5 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (<></>)} */}

            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark '>{intl.formatMessage({id: 'E-mail'})}</label>
              <input
                placeholder='example@mail.com'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid ',
                  // {'is-invalid': formik.touched.email && formik.errors.email},
                  // {
                  //   'is-valid': formik.touched.email && !formik.errors.email,
                  // }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              {/* {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )} */}
            </div>

            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                {/* <div className='d-flex flex-stack mb-2 w-100'>
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({id: 'Senha'})}</label>
                  
                </div> */}
              </div>
              <div  className='d-flex justify-content-between w-100 p-0'>
                <input
                  type={seePassword}
                  autoComplete='off'
                  placeholder={intl.formatMessage({id: 'Senha'})}
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid w-90 ',
                    // {
                    //   'is-invalid': formik.touched.password && formik.errors.password,
                    // },
                    // {
                    //   'is-valid': formik.touched.password && !formik.errors.password,
                    // }
                  )}
                />
                {
                        seePassword === 'password'?
                        <span
                        style={{ borderCollapse: 'collapse', backgroundColor: 'black'}}
                        onClick={()=>setSeePassword('text')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                          <i className="bi bi-eye-slash-fill fs-2x"></i>
                        </span>
                        :
                        <span onClick={()=>setSeePassword('password')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                          <i className="bi bi-eye-fill fs-2x"></i>
                        </span>
                }
              </div>
              {/* {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )} */}
            </div>


            <div className='row d-flex align-items-center'>
              
              <div className='text-center  col-12 col-sm-6 mb-5  order-2 order-sm-1' >
                <Link to='/auth/forgot-password' className='link-dark fs-6 fw-bold' >{intl.formatMessage({id: 'Esqueceu a senha?'})} </Link>
              </div>
              <div className='text-center col-12  col-sm-6       order-1 order-sm-2' >
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn bg-hover-dark mb-5 text-white w-100'
                  style={{ background: '#65638E', borderRadius: 13}}

                >
                  {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Entrar'})}</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>

            </div>
          </form>
          {/* <div className='text-center'>
                <p className='fw-bold fs-5 text-gray-500'>
                  <span style={{borderBottom: '1px solid gray'}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  {intl.formatMessage({id: 'or'})} 
                  &nbsp;&nbsp;&nbsp;
                  <span style={{borderBottom: '1px solid gray'}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>

                </p>
            </div> */}
            {/* <div className='d-flex justify-content-between'>
              <button 
              className='btn bg-hover-secondary' 
              style={{width: '180px', height: '50px', border: '1px solid #DCDBDD', borderRadius: '12px' }}
              >
              
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/ic_google logo.svg')} style={{marginRight: '10px'}} />
              
                Google
              </button>
              <button 
              className='btn bg-hover-secondary' style={{width: '180px', height: '50px', border: '1px solid #DCDBDD', borderRadius: '12px' }}
              >
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/ic_fb logo.svg')} style={{marginRight: '10px'}} />
                Facebook
              </button>
            </div> */}

            <div className='mt-5 '>
              <p className='fs-7 text-gray-600'>{intl.formatMessage({id: 'message_protectin_term'})} 
              {/* <span style={{color: '#009580'}}> {intl.formatMessage({id: ''})}</span> {intl.formatMessage({id: 'and'})} <span style={{color: '#009580'}}>{intl.formatMessage({id: ''})}</span> */}
                                 
              </p>
            </div>
        </div>
      </div>
      <Modal
         open={openModal}
         onClose={()=>{}}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='text-center mb-10'>
              <div className='text-dark-400 fw-bold fs-4'>{messageEmail}</div>
            </div>
            <div className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({id: 'Verification code'})}</label>
              <input
                type='text'
                placeholder='0000'
                autoComplete='off'
                {...formik.getFieldProps('code_email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  
                )}
              />
            
            </div>
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary fw-bolder me-4 text-dark'
              >
                <span className='indicator-label'>{intl.formatMessage({id: 'Enviar'})}</span>
                {loading && (
                  <span className='indicator-progress'>
                    {intl.formatMessage({id: 'Please wait'})}...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <Link to='/auth/login'>
                <button
                  type='reset'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-lg btn-light-danger fw-bolder'
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={()=>[setOpenModal(false), window.location.reload() ]}
                >
                  {intl.formatMessage({id: 'Cancelar'})}
                </button>
              </Link>{' '}
            </div>
          </form>
        </div>
          </Box>
        
        </Modal>
    </div>
  )
}
