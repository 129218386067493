
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { riskAnalysisModelStep, riskAnalysisPost, riskGeneratePdf, riskSendPdf } from '../../../modules/helpers/Api';
import { useIntl } from 'react-intl'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { converteData2, handleDownload } from '../../../modules/helpers/helpers'
import { useLang } from '../../../../_metronic/i18n/Metronici18n'
import $ from "jquery";
import { toast } from 'react-toastify';
// import { info } from 'console';

interface ITask {
    name: string;
    done: boolean;
}

interface Props {
    token: string;
    edit: boolean
}

const initialValues = {
    email: '',
    name: '',
    rights: '',
    description: '',
}

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

const Step2: React.FC<Props> = ({ ...props }) => {

    const [loading, setLoading] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)
    const [pdfLoading, setPdfLoading] = useState(false)

    const lang = useLang()
    const intl = useIntl();
    const urlLang = 'pt';
    const [step, setStep] = useState('step-2');
    const [riskAnaliseData, setRiskAnaliseData] = useState({ 'uuid': '', 'title': '', 'description': '', 'themes': [], 'owner_name': '', 'owner_email': '', 'mail': '', 'receive_attach': '', 'resp_attach': '' })
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [modalConfirmation, setModalConfirmation] = useState(false);
    const [messageConfirmation, setMessageConfirmation] = useState('');
    const [openModalReqAtention, setOpenModalReqAtention] = useState(false);
    const [openModalActionAtention, setOpenModalActionAtention] = useState(false);
    const [responseData, setResponseData] = useState([]);
    const [openModalRelatorio, setOpenModalRelatorio] = useState(false);
    const [openModalNotification, setOpenModalNotification] = useState(false);
    const [analisyNotification, setAnalisyNotification] = useState('');
    const [hasError, setHasError] = useState('');
    // const [analisyResp, setAnalisyResp] = useState([]);
    // const [resp, setResp] = useState([]);
    const [riskCollapse, setRiskCollapse] = useState('');
    const [actionCollapse, setActionCollapse] = useState('');
    const [loadingRelatorio, setLoadingRelatorio] = useState(false)
    const [locked, setLocked] = useState<string>('');
    const [locked2, setLocked2] = useState<string>('');
    const [closeStepOne, setCloseStepOne] = useState(false)
    const [openModalActionOther, setOpenModalActionOther] = useState(false);
    const [actionRiskUuidOther, setActionRiskUuidOther] = useState('');
    const [respSendAttach, setRespSendAttach] = useState(false);
    const [modalRespSendAttach, setModalRespSendAttach] = useState(false);

    if (['pt', 'en'].includes(urlLang) && lang !== urlLang) {
        // setLanguage(urlLang)
        localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: urlLang }))
        window.location.reload()
    }

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxWidth: 750,
        maxHeight: 600,
        bgcolor: 'background.paper',
        border: 'none',
        borderRadius: 1,
        boxShadow: 24,
        outline: 'none'
    };
    const modalStyle2 = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxWidth: 300,
        maxHeight: 500,
        bgcolor: 'background.paper',
        border: 'none',
        borderRadius: 1,
        boxShadow: 24,
        outline: 'none'
    };
    useEffect(() => {
        if (props.token) {
            handleRiscAnalisyStep()
        }
    }, [props.token]);

    const registrationSchema = Yup.object().shape({})

    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            handleSubmitEtapa2();
        },
    })

    const formik2 = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email(`${intl.formatMessage({ id: 'O valor não é um endereço de e-mail válido' })}`)
                .max(50, `${intl.formatMessage({ id: 'Máximo de 50 símbolos' })}`)
                .required(`${intl.formatMessage({ id: 'E-mail' })} ${intl.formatMessage({ id: 'is required' })}`),
        }),
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setPdfLoading(true);
            // setLoading(true)
            // setLoadingRelatorio(true)
            let send = riskSendPdf({ token: props.token, email: values.email });
            send
                .then((res) => {
                    if (res.data.status === 'success') {
                        setModalConfirmation(true)

                        setMessageConfirmation(res.data.status)
                    } else {
                        setModalConfirmation(true)
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error('Erro ao enviar o email');
                })
                .finally(() => {
                    setPdfLoading(false)
                    // setLoading(false)
                    // setLoadingRelatorio(false)

                }

                )

        },
    })

    const formik3 = useFormik({
        initialValues: {
            new_action: ''
        },
        validationSchema: Yup.object().shape({
            new_action: Yup.string()
                .required(`${intl.formatMessage({ id: 'Action' })} ${intl.formatMessage({ id: 'is required' })}`),
        }),
        onSubmit: (values, { setStatus, setSubmitting }) => {
            // action.appendTo(`#ktformrisk${risk_uuid}actions`);

            let key = `outro_${(new Date()).valueOf().toString()}`
            addChkRiscActionForm({ action: key, done: false, title: values.new_action }, actionRiskUuidOther)
            formik3.setFieldValue('new_action', '')
            setActionRiskUuidOther('')
            setOpenModalActionOther(false)
        },
    })



    function gerarRelatorioPdf() {
        setPdfLoading(true);
        const get = riskGeneratePdf(props.token);
        get.then((res) => {

            if (res.statusText === 'OK') {
                handleDownload(res, riskAnaliseData.title, 'pdf');
            } else {
                alert('desculpe, um erro ocorreu!');
            }

        }).catch((error) => {
            if (error.response.status == 404) {

                let file = new Blob([error.response.data], { type: 'application/json' });

                file.text()
                    .then(value => {
                        let object = JSON.parse(value);
                        alert(object.message);
                    })
                    .catch(error => {
                        alert('Desculpe um erro ocorreu')
                    });

            } else {
                alert('Indiponível no momento!')
            }
        }).finally(() => {
            setPdfLoading(false)
        });
    }

    function SendEmalPdf() {

    }

    function getDataActions(risk: any) {

        let cks: any = [];
        $(`#ktformrisk${risk}actions input:checkbox`).each(function () {

            let act_data: any = {
                action: '',
                done: null,
            }

            let action = $(this).attr('action');
            let name = $(this).attr('name');

            if (action && action.includes('outro_')) {
                let text = $(`#label_act_${action}`).html();
                act_data.text = text
            }

            act_data.action = action;

            act_data.done = $(`#ktformrisk${risk}actions input:checkbox[name=${name}]:checked`).is(":checked");

            let act_q1_onde = $(`#ktformrisk${risk}actions input:text[name='${action}_onde']`).val();
            if (act_q1_onde) {
                act_data.onde = act_q1_onde
            }

            let act_q2_por_que = $(`#ktformrisk${risk}actions input:text[name='${action}_por_que']`).val();
            if (act_q2_por_que) {
                act_data.por_que = act_q2_por_que
            }

            let act_q3_quando = $(`#ktformrisk${risk}actions input:text[name='${action}_quando']`).val();
            if (act_q3_quando) {
                act_data.quando = act_q3_quando
            }

            let act_q4_quem = $(`#ktformrisk${risk}actions input:text[name='${action}_quem']`).val();
            if (act_q4_quem) {
                act_data.quem = act_q4_quem
            }

            let act_q5_como = $(`#ktformrisk${risk}actions input:text[name='${action}_como']`).val();
            if (act_q5_como) {
                act_data.como = act_q5_como
            }

            let act_q6_quanto = $(`#ktformrisk${risk}actions input:text[name='${action}_quanto']`).val();
            if (act_q6_quanto) {
                act_data.quanto = act_q6_quanto
            }

            cks.push(act_data);

        });

        return cks;






    }

    function mountData2(step: any) {

        let checkFields = true;
        let checkAction = true;

        var dataResponse = $('#kt_form_ram').serializeArray().reduce(function (obj: any, item) {

            if (!item.value) {
                checkFields = false;
            }

            obj[item.name] = item.value;
            return obj;

        }, {});

        let finalData: any = [];

        riskAnaliseData.themes.forEach((theme: any) => {

            let standards: any = theme['standards'];

            standards.forEach((standard: any) => {

                let risks: any = standard['risks'];

                risks.forEach((risk: any) => {

                    if (step == 'step-2') {

                        if (dataResponse['prob_' + risk.uuid] && dataResponse['impact_' + risk.uuid] && dataResponse['nivel_value_' + risk.uuid]) {
                            finalData.push({
                                'standard': standard.uuid,
                                'risk': risk.uuid,
                                'prob': dataResponse['prob_' + risk.uuid],
                                'impact': dataResponse['impact_' + risk.uuid],
                                'nivel': dataResponse['nivel_value_' + risk.uuid]
                            });
                        }

                    } else if (step == 'step-3') {

                        if (dataResponse['treatment_' + risk.uuid] && (dataResponse['mitigate_' + risk.uuid] != "") && (dataResponse['providence_' + risk.uuid] != "")) {

                            finalData.push({
                                'treatment': dataResponse['treatment_' + risk.uuid],
                                'risk': risk.uuid,
                                'mitigate': dataResponse['mitigate_' + risk.uuid],
                                'providence': dataResponse['providence_' + risk.uuid],
                                'actions': getDataActions(risk.uuid)
                            });

                        } else {

                            let actionsList = getDataActions(risk.uuid)
                            if (actionsList && actionsList.length >= 1) {
                                checkRiskCollapse(risk.uuid)
                                checkAction = false;
                            }

                        }

                    }

                });

            });

        });

        if (!checkFields && closeStepOne) {
            setOpenModalReqAtention(true)
            return;
        }

        if (!checkAction) {
            setOpenModalActionAtention(true)
            return;
        }

        return finalData;
    }

    function getProbText(value: number) {
        if (value === 1) {
            return 'Muito Baixa'
        } else if (value === 2) {
            return 'Baixa'
        } else if (value === 5) {
            return 'Média'
        } else if (value === 8) {
            return 'Alta'
        } else if (value === 10) {
            return 'Muito Alta'
        } else {
            return '???'
        }
    }

    function geImpacto(value: number) {
        if (value === 1) {
            return 'Muito Baixa'
        } else if (value === 2) {
            return 'Baixa'
        } else if (value === 5) {
            return 'Média'
        } else if (value === 8) {
            return 'Alta'
        } else if (value === 10) {
            return 'Muito Alta'
        } else {
            return '???'
        }
    }

    function addChkRiscActionForm(item: any, risk_uuid: any) {

        if ($(`#ktformrisk${risk_uuid}actions #chk_${risk_uuid}_${item.action}`).text()) {
            alert('Essa ação já está presente na lista!');
            return;
        }

        let action = $(`<div>
            <div class=' d-flex text-dark mb-4' id='chk_${risk_uuid}_${item.action}'>

                ${!locked2 && props.edit ?
                `<span class='me-2'>
                        <img src='/media/icons/duotune/general/gen034.svg' class='btn-del-chk ' data-chk-action='chk_${risk_uuid}_${item.action}' />
                    </span>`
                :
                ''
            }

                <input
                    id='2chk_${risk_uuid}_${item.action}'
                    class='form-check-input me-2'
                    type='checkbox'
                    name='${item.action}'
                    action='${item.action}'
                    style="min-width:22px;margin-top:1px;"
                    ${item.done ? 'checked' : ''}
                    ${![null, ''].includes(locked2) || !props.edit ? 'disabled' : ''}
                />
                
                <div class='w-100'>
                    <label for='2chk_${risk_uuid}_${item.action}' style="${props.edit ? 'cursor:pointer;' : ''}">
                    <span id='label_act_${item.action}'>${item.text ? item.text : item.title}</span>
                    </label>
                    <div class='mt-4'>

                        <div class='mb-4'>
                            <label>Onde?</label>
                            <input
                                name='${item.action}_onde'
                                action='${item.action}'
                                value='${item.where ? item.where : ''}'
                                class='form-control form-control-lg form-control-solid'
                                autoComplete='off'
                                type='text'                            
                                ${![null, ''].includes(locked2) || !props.edit ? 'disabled' : ''}
                            />
                        </div>

                        <div class='mb-4'>
                            <label>Por quê?</label>
                            <input
                                name='${item.action}_por_que'
                                action='${item.action}'
                                value='${item.why ? item.why : ''}'
                                class='form-control form-control-lg form-control-solid'
                                autoComplete='off'
                                type='text'                            
                                ${![null, ''].includes(locked2) || !props.edit ? 'disabled' : ''}
                            />
                        </div>

                        <div class='mb-4'>
                            <label>Quando?</label>
                            <input
                                name='${item.action}_quando'
                                action='${item.action}'
                                value='${item.when ? item.when : ''}'
                                class='form-control form-control-lg form-control-solid'
                                autoComplete='off'
                                type='text'                            
                                ${![null, ''].includes(locked2) || !props.edit ? 'disabled' : ''}
                            />
                        </div>

                        <div class='mb-4'>
                            <label>Quem?</label>
                            <input
                                name='${item.action}_quem'
                                action='${item.action}'
                                value='${item.Who ? item.Who : ''}'
                                class='form-control form-control-lg form-control-solid'
                                autoComplete='off'
                                type='text'                            
                                ${![null, ''].includes(locked2) || !props.edit ? 'disabled' : ''}
                            />
                        </div>

                        <div class='mb-4'>
                            <label>Como?</label>
                            <input
                                name='${item.action}_como'
                                action='${item.action}'
                                value='${item.as ? item.as : ''}'
                                class='form-control form-control-lg form-control-solid'
                                autoComplete='off'
                                type='text'                            
                                ${![null, ''].includes(locked2) || !props.edit ? 'disabled' : ''}
                            />
                        </div>

                        <div class='mb-4'>
                            <label>Quanto?</label>
                            <input
                                name='${item.action}_quanto'
                                action='${item.action}'
                                value='${item.how_much ? item.how_much : ''}'
                                class='form-control form-control-lg form-control-solid'
                                autoComplete='off'
                                type='text'                            
                                ${![null, ''].includes(locked2) || !props.edit ? 'disabled' : ''}
                            />
                        </div>

                    </div>
                </div>

            </div>
        </div>`
        )
        .on('click', '.btn-del-chk', function () {
            let btnChk = $(this).data("chk-action")
            $(`#${btnChk}`).remove();
            $(`#action_${risk_uuid} option[value='${item.action}']`).show();

        })

        if (['Outro'].includes(item.action)) {
            setActionRiskUuidOther(risk_uuid)
            setOpenModalActionOther(true)

        } else {
            action.appendTo(`#ktformrisk${risk_uuid}actions`);
            $(`#action_${risk_uuid} option[value='${item.action}']`).hide();
        }



    }

    function mountStep3RespData(formData: any) {

        formData.themes.forEach((theme: any) => {
            theme['standards'].forEach((standard: any) => {
                standard['risks'].forEach((risk: any) => {

                    if (risk.resp_data) {

                        $(`#prob_${risk.resp_data.model_risk_uuid}`).val(risk.resp_data.prob).trigger('change');
                        $(`#impact_${risk.resp_data.model_risk_uuid}`).val(risk.resp_data.impact).trigger('change');
                        calcNivel(risk.resp_data.model_risk_uuid, risk.resp_data.prob, risk.resp_data.impact);

                    }

                    if (risk.resp_management) {

                        $(`#ktformrisk${risk.uuid}actions`).html('')

                        if (risk.resp_management.actions.length > 0) {
                            risk.resp_management.actions.forEach((item: any) => {
                                addChkRiscActionForm(item, risk.uuid)
                            });
                        } else if (locked2) {
                            $(`#ktformrisk${risk.uuid}actions`).html('Nenhuma ação foi adicionada!')
                        }


                        $(`#treatment_${risk.uuid}`).val(risk.resp_management.treatment);

                        checkRiskTreatment(null, risk.uuid, risk.resp_management.treatment);

                        $(`#mitigate_${risk.uuid}`).val(risk.resp_management.mitigate);
                        $(`#providence_${risk.uuid}`).val(risk.resp_management.providence)

                        calcMitigationEnd(risk.uuid)

                    }

                    // if (risk.actions) {
                    //   risk.actions.forEach((action: any) => {

                    //     if ( action.resp_data ) {

                    //       $(`#treatment_${action.uuid}`).val(action.resp_data.treatment).trigger('change');
                    //       $(`#mitigate_${action.uuid}`).val(action.resp_data.mitigate).trigger('change');
                    //       $(`#providence_${action.uuid}`).val( action.resp_data.providence )

                    //       calcMitigationEnd(risk.uuid)
                    //     }

                    //   });
                    // }

                });
            });
        });

        // setAnalisyResp([])
    }

    function closeModalRelatorio() {
        formik2.resetForm()
        setOpenModalRelatorio(false)
    }

    function handleRiscAnalisyStep() {

        setLoading(true);

        const get = riskAnalysisModelStep(props.token, step);

        get.then((res) => {

            if (res.data.status === 'success') {

                setRiskAnaliseData(res.data.data)

                if (res.data.data.finish_at) {
                    setLocked(res.data.data.finish_at)
                }

                if (res.data.data.finish_at_2) {
                    setLocked2(res.data.data.finish_at_2)
                }

                //   setTimeout(() => {
                //     setLayout(config)
                //     setConfigLoading(false)
                //   }, 1000)


                $(document).ready(function () {

                    // setTimeout(() => {

                    mountStep3RespData(res.data.data)

                    $(".form-imput-percent").keyup(function (e) {

                        var charCode = (e.which) ? e.which : e.keyCode;
                        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                            return false;
                        }

                    });

                    // }, 1000 );

                });

            }
            else if (res.data.status === 'error') {

                setHasError(res.data.message);
            }

        }).catch((error) => {

            if (error.response) {
                setHasError(error.response.data.message);
            } else {
                setHasError('Desculpe, não foi possivel recuperar as informações, tente novamente mais tarde...')
            }

        }).finally(() => {
            setLoading(false)
        });
    }

    function between(x: any, min: any, max: any) {
        return x >= min && x <= max;
    }

    function calcNivel(el_id: any, v_prob: any = null, v_impact: any = null) {

        let prob = 0;
        let impact = 0;
        let nivel = 0;
        let color = 'secondary';
        let text = '- - -';

        if (v_prob) {
            prob = v_prob;
        } else {
            let selectedProb = $(`#prob_${el_id} :selected`);
            if (selectedProb) {
                prob = Number(selectedProb.val());
            }
        }

        if (v_impact) {
            impact = v_impact
        } else {
            let selectedImpact = $(`#impact_${el_id} :selected`);
            if (selectedImpact) {
                impact = Number(selectedImpact.val());
            }
        }

        $(`#nivel_color_${el_id}`).removeClass('bg-ram');
        $(`#nivel_color_${el_id}`).removeClass('bg-ram-rb');
        $(`#nivel_color_${el_id}`).removeClass('bg-ram-rm');
        $(`#nivel_color_${el_id}`).removeClass('bg-ram-ra');
        $(`#nivel_color_${el_id}`).removeClass('bg-ram-re');

        $(`#nivel_color2_${el_id}`).removeClass('bg-ram');
        $(`#nivel_color2_${el_id}`).removeClass('bg-ram-rb');
        $(`#nivel_color2_${el_id}`).removeClass('bg-ram-rm');
        $(`#nivel_color2_${el_id}`).removeClass('bg-ram-ra');
        $(`#nivel_color2_${el_id}`).removeClass('bg-ram-re');

        if (prob != 0 && impact != 0) {

            nivel = prob * impact;

            if (between(nivel, 0, 9.99)) {
                color = 'ram-rb';
                text = 'RB';
            }
            else if (between(nivel, 10, 39.99)) {
                color = 'ram-rm';
                text = 'RM';
            }
            else if (between(nivel, 40, 79.99)) {
                color = 'ram-ra';
                text = 'RA';
            }
            else if (between(nivel, 80, 100)) {
                color = 'ram-re';
                text = 'RE';
            } else {
                color = 'ram';
                text = '- - -';
            }

            $(`#nivel_color_${el_id}`).addClass(`bg-${color}`);
            $(`#nivel_color2_${el_id}`).addClass(`bg-${color}`);

            $(`#nivel_value_${el_id}`).val(nivel);
            $(`#nivel_text_${el_id}`).val(`${nivel} - ${text} `);

        } else {
            $(`#nivel_color_${el_id}`).addClass('bg-ram');
            $(`#nivel_color2_${el_id}`).addClass('bg-ram');

            $(`#nivel_value_${el_id}`).val(0);
            $(`#nivel_text_${el_id}`).val('- - -');
        }

    }

    function handleSubmitEtapa2() {
        setStep('step-2')
        let finalData = mountData2('step-2');
        if (finalData) {
            setOpenModalConfirm(true)
            setResponseData(finalData);
        }
    }

    function handleSubmitEtapa3() {
        setStep('step-3')
        let finalData = mountData2('step-3');
        if (finalData) {

            setOpenModalConfirm(true)
            setResponseData(finalData);
        }

        // var finalData:any = [];
        // let checkFields = true;

        // riskAnaliseData.themes.forEach((theme: any) => {

        //   let standards: any = theme['standards'];

        //   standards.forEach((standard: any) => {

        //     let risks: any = standard['risks'];       

        //     risks.forEach((risk: any) => {

        //       let dataResponse = $(`#form_risk_${risk.uuid}`).serializeArray().reduce(function (obj: any, item) {
        //         if (!item.value) {
        //           checkFields = false;
        //         }
        //         obj[item.name] = item.value;
        //         return obj;
        //       }, {});

        //       if (risk['actions']) {
        //         risk['actions'].forEach((action: any) => {

        //           if ( dataResponse['treatment_' + action.uuid] && dataResponse['mitigate_' + action.uuid] && dataResponse['providence_' + action.uuid] ) {
        //             finalData.push({
        //               'standard': standard.uuid,
        //               'risk': risk.uuid,
        //               'action': action.uuid,
        //               'treatment': dataResponse['treatment_' + action.uuid],
        //               'mitigate': dataResponse['mitigate_' + action.uuid],
        //               'providence': dataResponse['providence_' + action.uuid]
        //             });
        //           }    

        //         })
        //       }

        //     })

        //   })
        // })

        // if (finalData) {
        //   setOpenModalConfirm(true)
        //   setResponseData(finalData);
        // }

    }

    function checkRiskTreatment(event: any, risk_uuid: any, value: any = null) {

        let selectdValue = null;

        if (event) {

            let selected = $(`select#${event.target.id} option:selected`)
            if (selected && selected.val()) {

                selectdValue = selected.val();


            }
        } else if (value) {
            selectdValue = value;
        }

        if (selectdValue) {

            let risk = $(`#mitigate_${risk_uuid}`);

            if (selectdValue == 1) {
                risk.val(100);
                risk.prop('readonly', true);

            } else if (selectdValue == 4) {
                risk.val(0);
                risk.prop('readonly', true);

            } else {
                risk.val('');
                risk.prop('readonly', false);
            }

            // calcMitigationEnd(risk_uuid)

        }

    }

    function calcMitigationEnd(risk_uuid: any) {
        let nivel_value = Number($(`#nivel_value_${risk_uuid}`).val());
        let mitigation = Number($(`#mitigate_${risk_uuid}`).val());
        let calc = (mitigation / 100) * nivel_value;
        if (mitigation < 0 || mitigation > 100) {
            $(`#mitigate_${risk_uuid}`).val(0);
            calc = nivel_value
            
        } else {
            calc = Number((nivel_value - calc).toFixed(2));
            $(`#risk_nivel_end_${risk_uuid}`).val(calc);
        }
        let color = '';
        let text = '';

        if (calc != 0) {
            if (between(calc, 0, 9.99)) {
                color = 'ram-rb';
                text = 'RB';
            } else if (between(calc, 10, 39.99)) {
                color = 'ram-rm';
                text = 'RM';
            } else if (between(calc, 40, 79.99)) {
                color = 'ram-ra';
                text = 'RA';
            } else if (between(calc, 80, 100)) {
                color = 'ram-re';
                text = 'RE';
            } else {
                color = 'ram';
            }
        } else {
            color = 'ram-rb';


        }
        $(`#nivel_color_${risk_uuid}`).removeClass('bg-ram bg-ram-rb bg-ram-rm bg-ram-ra bg-ram-re').addClass(`bg-${color}`);
        $(`#nivel_color2_${risk_uuid}`).removeClass('bg-ram bg-ram-rb bg-ram-rm bg-ram-ra bg-ram-re').addClass(`bg-${color}`);
        $(`#risk_nivel_end_${risk_uuid}`).val(`${calc} - ${text}`);
        $(`#risk_nivel_end_${risk_uuid}`).val(`${calc}`);




    }



    function checkRiskCollapse(key: any) {
        setRiskCollapse(`kt_collapse_${key}`)
    }
    function checkActionCollapse(key: any) {
        setActionCollapse(`kta_collapse_${key}`)
    }

    function handleSendResponse() {

        setLoadingSave(true)

        const create = riskAnalysisPost({
            token: props.token,
            step: step,
            finish: $('#chk_finish').is(":checked") ? '1' : '0',
            finish_2: $('#chk_finish_2').is(":checked") ? '1' : '0',
            data: responseData,
            respSendAttach: respSendAttach
        });

        create.then((res) => {

            if (res.data.status === 'success') {

                setCloseStepOne(false)

                setOpenModalNotification(true)
                setAnalisyNotification(res.data.message)

                if (res.data.finish) {

                    if (step == 'step-2') {
                        handleRiscAnalisyStep();
                    }
                }

                if (res.data.finish_2) {

                    setLocked(res.data.finish)
                    setLocked2(res.data.finish_2)
                }

                handleRiscAnalisyStep()

            }
            else if (res.data.status === 'error') {
                setOpenModalNotification(true)
                setAnalisyNotification(res.data.message)
            }

        }).catch((error) => {
            // console.log(error)
        }).finally(() => {
            setOpenModalConfirm(false)
            setLoadingSave(false)

        });
    }

    function setActionToRisk(event: any, risk_uuid: any) {

        let actionSelected = $(`select#${event.target.id} option:selected`)

        if (actionSelected && actionSelected.val()) {

            let item: any = {
                title: actionSelected.text(),
                done: false,
                action: actionSelected.val(),
            }

            addChkRiscActionForm(item, risk_uuid)

            $(`select#${event.target.id}`).val('').trigger('change');
        }

    }
    const handleOpenEmail = () => {
        const mailtoUrl = `mailto:${riskAnaliseData.mail}?subject=${encodeURIComponent("Evidências: " + riskAnaliseData.title + "(" + riskAnaliseData.owner_name + ")")}`;
        window.open(mailtoUrl, '_blank');
    };

    return (

        <div>
            <Modal
                open={modalConfirmation}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle2}>

                    <div className='container mt-5 p-5 text-center '>
                        <p className='w-90 mx-auto fs-4 mt-5'>
                            {messageConfirmation === 'success' ? "Relatorio enviado para o email informado!" : "Erro ao enviar, tente novamente mais tarde!"}
                        </p>
                    </div>
                    <div className='d-flex justify-content-center p-4'>
                        <button
                            onClick={() => [setModalConfirmation(false), setMessageConfirmation(''), formik2.setValues({
                                email: ''
                            })]}
                            className={`btn btn-sm btn-primary `} >
                            <span className='text-black'>Ok</span>
                        </button>
                    </div>

                </Box>
            </Modal >

            <div style={{ maxWidth: '980px' }}>

                {
                    loading ?
                        <div className='text-center'>
                            <div><h4 >Aguarde</h4></div>
                            <div>
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Processando <span className='spinner-border spinner-border-sm align-middle mr-2'></span>
                                </span>
                            </div>
                        </div>

                        :
                        <div>
                            {hasError ?
                                <div className='text-center'>
                                    <div>{hasError}</div>
                                </div>

                                :

                                <div>

                                    {riskAnaliseData.uuid ?

                                        <div>

                                            <div className=' mt-10 '>
                                                <div> <h6>{riskAnaliseData.title}</h6> </div>
                                                <div> <div>{riskAnaliseData.description}</div> </div>

                                            </div>

                                            {riskAnaliseData.uuid ?
                                                <div className='mb-4'>
                                                    <small >
                                                        <div><strong>Chave:</strong> {props.token}</div>
                                                        <div><strong>Nome:</strong> {riskAnaliseData.owner_name ? (riskAnaliseData.owner_name + ' (' + riskAnaliseData.owner_email + ')') : 'Nome: - - -'}</div>
                                                        {locked ? <div><strong>Análise finalizada em:</strong> {converteData2(locked)}</div> : ''}
                                                        {locked2 ? <div><strong>Tratamento finalizado em:</strong> {converteData2(locked2)}</div> : ''}
                                                        {riskAnaliseData.receive_attach ?
                                                            <>
                                                                <div><strong>Anexo de evidências:</strong> {riskAnaliseData.mail}</div>
                                                                <div><strong>Envio de evidências:</strong> {riskAnaliseData.resp_attach ? 'Sim' : 'Não'}</div>
                                                            </>
                                                            :
                                                            <>
                                                            </>

                                                        }
                                                    </small>
                                                </div>
                                                :
                                                ''
                                            }

                                            <div className='mb-4 text-center'>
                                                <h6>{
                                                    step == 'step-2' && !locked ? 'Análise'
                                                        : step == 'step-2' && locked ? (locked2 ? 'Visualização' : 'Tratamento')
                                                            : step == 'step-3' && locked ? (locked2 ? 'Visualização' : 'Tratamento')
                                                                : 'Passo Desconhecido!'
                                                }</h6>
                                            </div>


                                            <form
                                                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework fs-7'
                                                noValidate
                                                id='kt_form_ram'
                                                name='kt_form_ram'
                                                onSubmit={formik.handleSubmit}
                                            >

                                                {riskAnaliseData.themes?.map((theme: any, index: any) =>

                                                    <div key={`theme_${index}`} className='my-4' style={{ border: '2px solid #e4e6ef', padding: '8px', borderRadius: '5px' }}>

                                                        {/* <div><strong>Setor:</strong> </div> */}
                                                        <div><strong>Tema:</strong> {theme.sector} - {theme.title}</div>

                                                        {theme?.standards?.map((standard: any, standard_index: any) =>

                                                            <div key={`standard_${standard_index}_${standard.uuid}`}>

                                                                <div className='mt-4'><strong>Norma:</strong> {standard.title}</div>

                                                                <div className='mt-2 '>

                                                                    <div><strong>Riscos</strong></div>
                                                                    {/* <div className='scroll-y h-200px'> */}
                                                                    <div >
                                                                        {standard?.risks?.map((risk: any, index3: any) =>

                                                                            <div key={`risk_${risk.uuid}`} className='d-flex mb-5'>

                                                                                <div id={`nivel_color_${risk.uuid}`} className='fv-plugins-message-container bg-ram p-1'></div>
                                                                                <div style={{ backgroundColor: '#efeff3' }} className='w-100' >

                                                                                    <div id={`nivel_color2_${risk.uuid}`} data-bs-toggle='collapse' data-bs-target={`#kt_collapse_${risk.uuid}`} onClick={() => [checkRiskCollapse(risk.uuid)]} className='p-2'>
                                                                                        <span className='cursor-pointer fw-bolder mx-4' >{index3 + 1} - {risk.title}</span>
                                                                                    </div>

                                                                                    <div id={`kt_collapse_${risk.uuid}`} className={`collapse ${riskCollapse === `kt_collapse_${risk.uuid}` ? 'show' : 'hide'} p-4`}>

                                                                                        <div className='row'>

                                                                                            <div className='col-12 col-sm-12 col-md-3 mt-2'>
                                                                                                <label htmlFor="">Probabilidade</label>
                                                                                                <select id={`prob_${risk.uuid}`} name={`prob_${risk.uuid}`}
                                                                                                    disabled={loading || !props.edit || ![null, ''].includes(locked)}
                                                                                                    className='form-select form-control form-control-solid fs-6'
                                                                                                    onChange={() => calcNivel(`${risk.uuid}`)}
                                                                                                >
                                                                                                    <option value=''>{intl.formatMessage({ id: 'Select' })}</option>
                                                                                                    <option value='1'>Muito Baixa</option>
                                                                                                    <option value='2'>Baixa</option>
                                                                                                    <option value='5'>Média</option>
                                                                                                    <option value='8'>Alta</option>
                                                                                                    <option value='10'>Muito Alta</option>
                                                                                                </select>

                                                                                            </div>

                                                                                            <div className='col-12 col-sm-12 col-md-3 mt-2'>
                                                                                                <label htmlFor="">Impacto</label>
                                                                                                <select id={`impact_${risk.uuid}`} name={`impact_${risk.uuid}`}
                                                                                                    disabled={loading || !props.edit || ![null, ''].includes(locked)}
                                                                                                    className='form-select form-control form-control-solid fs-6'
                                                                                                    onChange={() => calcNivel(`${risk.uuid}`)}
                                                                                                >
                                                                                                    <option value=''>{intl.formatMessage({ id: 'Select' })}</option>
                                                                                                    <option value='1'>Muito Baixo</option>
                                                                                                    <option value='2'>Baixo</option>
                                                                                                    <option value='5'>Médio</option>
                                                                                                    <option value='8'>Alto</option>
                                                                                                    <option value='10'>Muito Alto</option>
                                                                                                </select>

                                                                                            </div>

                                                                                            <div className='col-12 col-sm-12 col-md-2 mt-2'>
                                                                                                <label htmlFor="">Nível</label>
                                                                                                <input id={`nivel_value_${risk.uuid}`} name={`nivel_value_${risk.uuid}`} type='hidden' disabled={loading || !props.edit || ![null, ''].includes(locked)} />
                                                                                                <input className="form-control form-control-solid " id={`nivel_text_${risk.uuid}`} type='text' disabled />
                                                                                            </div>

                                                                                            {locked ?
                                                                                                <div>
                                                                                                    <div className='row'>

                                                                                                        <div className='col-12 col-sm-12 col-md-3 mt-2 form_risk_treatment'>
                                                                                                            <label htmlFor="">Tratamento</label>
                                                                                                            <select id={`treatment_${risk.uuid}`} name={`treatment_${risk.uuid}`}
                                                                                                                disabled={loading || !props.edit || ![null, ''].includes(locked2)}
                                                                                                                className='form-select form-control form-control-solid fs-6 '
                                                                                                                onChange={(evt) => [checkRiskTreatment(evt, risk.uuid), calcMitigationEnd(risk.uuid)]}
                                                                                                            >
                                                                                                                <option value=''>{intl.formatMessage({ id: 'Select' })}</option>
                                                                                                                <option value='1'>Evitar o risco</option>
                                                                                                                <option value='2'>Reduzir ou mitigar</option>
                                                                                                                <option value='3'>Compartilhar ou transferir</option>
                                                                                                                <option value='4'>Aceitar ou tolerar</option>
                                                                                                            </select>
                                                                                                        </div>

                                                                                                        <div className='col-12 col-sm-12 col-md-3 mt-2'>
                                                                                                            <label htmlFor="">Mitigação %</label>
                                                                                                            <input
                                                                                                                id={`mitigate_${risk.uuid}`}
                                                                                                                name={`mitigate_${risk.uuid}`}
                                                                                                                className={`form-input form-control form-control-solid fs-6 form-imput-percent
                                                                    ${'calc-mity-' + risk.uuid} `}

                                                                                                                onChange={() => calcMitigationEnd(risk.uuid)}
                                                                                                                disabled={loading || !props.edit || ![null, ''].includes(locked2)}

                                                                                                                type="number"
                                                                                                                min="0"
                                                                                                                max="100"


                                                                                                            />
                                                                                                            {/* <select id={`mitigate_${risk.uuid}`} name={`mitigate_${risk.uuid}`}
                                                                    // disabled={loading || ![null, ''].includes(locked2)}
                                                                    className={`form-select form-control form-control-solid fs-6 ${'calc-mity-' + risk.uuid}`}
                                                                    onChange={() => calcMitigationEnd(risk.uuid)}
                                                                    >
                                                                    <option value=''>{intl.formatMessage({ id: 'Select' })}</option>
                                                                    <option value='0'>0%</option>
                                                                    <option value='20'>20%</option>
                                                                    <option value='40'>40%</option>
                                                                    <option value='60'>60%</option>
                                                                    <option value='80'>80%</option>
                                                                    <option value='100'>100%</option>
                                                                    </select> */}

                                                                                                        </div>

                                                                                                        {/* <div className='col-12 col-sm-12 col-md-2 mt-2'>
                                                                    <label htmlFor="">Mitigação %</label>
                                                                    <input className="form-input form-control form-control-solid fs-6 " id={`risk_mity_end_${risk.uuid}`} type='text' disabled />
                                                                </div> */}

                                                                                                        <div className='col-12 col-sm-12 col-md-2 mt-2'>
                                                                                                            <label htmlFor="">Nivel Final</label>
                                                                                                            <input className="form-control form-control-solid " id={`risk_nivel_end_${risk.uuid}`} type='text' disabled />
                                                                                                        </div>



                                                                                                    </div>

                                                                                                    {/* <div className='row'>

                                                                                                        <div className='col-12 col-sm-12 col-md-12 mt-2'>
                                                                                                            <label htmlFor="">Providencias</label>
                                                                                                            <input
                                                                                                                id={`providence_${risk.uuid}`}
                                                                                                                name={`providence_${risk.uuid}`}
                                                                                                                type="text"
                                                                                                                className="form-input form-control form-control-solid fs-6 "
                                                                                                                disabled={loading || !props.edit || ![null, ''].includes(locked2)}
                                                                                                            />
                                                                                                        </div>



                                                                                                    </div> */}
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }


                                                                                        </div>

                                                                                        {locked ?
                                                                                            <div className='mt-4'>
                                                                                                <label htmlFor="" className='mb-2'>Ações</label>

                                                                                                <form
                                                                                                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework fs-7'
                                                                                                    noValidate
                                                                                                    id={`ktformrisk${risk.uuid}actions`}
                                                                                                    name={`ktformrisk${risk.uuid}actions`}
                                                                                                />

                                                                                                {!locked2 && props.edit ?
                                                                                                    <div className=' d-flex text-dark mb-4'>

                                                                                                        <select id={`action_${risk.uuid}`}
                                                                                                            // disabled={loading || ![null, ''].includes(locked)}
                                                                                                            className='form-select form-control form-control-solid fs-7 '
                                                                                                            onChange={e => setActionToRisk(e, risk.uuid)}
                                                                                                        >

                                                                                                            <option value=''>Selecione uma ação</option>


                                                                                                            {risk?.risk_actions?.length > 0 ?
                                                                                                                risk?.risk_actions.map((item: any, indexaction: any) =>
                                                                                                                    <option key={`${risk.uuid}_${item.uuid}`} value={item.uuid}>{item.title}</option>
                                                                                                                )
                                                                                                                :
                                                                                                                null
                                                                                                            }

                                                                                                            <option key={`${risk.uuid}_outro`} value={'Outro'}>Outro</option>

                                                                                                        </select>
                                                                                                    </div>
                                                                                                    : ''
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            ''
                                                                                        }

















                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        )}


                                                    </div>

                                                )}

                                                {props.edit && !locked ?
                                                    <div>
                                                        <div className=' d-flex justify-content-end text-dark '>
                                                            <label className='px-4' htmlFor='chk_finish' >Desejo finalizar a etapa 1.</label>
                                                            <input
                                                                id='chk_finish'
                                                                className='form-check-input me-3'
                                                                type='checkbox'
                                                                name='finished'
                                                                onClick={() => setCloseStepOne(!closeStepOne)}
                                                            />
                                                        </div>

                                                        <div className='card-footer d-flex justify-content-end pt-4'>
                                                            <button type='submit' className='btn btn-primary text-dark btn-sm' disabled={loading}>
                                                                {!loading ?
                                                                    closeStepOne ? 'Finalizar etapa 1' : intl.formatMessage({ id: 'Save' })
                                                                    :
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Processando...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }



                                            </form>
                                        </div>

                                        :

                                        'Não encontrado..'

                                    }


                                    {
                                        props.edit && locked ?

                                            <div>

                                                {!locked2 ?
                                                    <div>

                                                        <div className=' d-flex justify-content-end text-dark py-8'>
                                                            <span className='px-4'>Desejo finalizar a etapa 2.</span>
                                                            <input
                                                                id='chk_finish_2'
                                                                className='form-check-input me-3'
                                                                type='checkbox'
                                                                name='finished_2'
                                                                onClick={() => setCloseStepOne(!closeStepOne)}
                                                            />
                                                        </div>

                                                        <div className=' d-flex justify-content-end'>

                                                            <button type='button' className='btn btn-primary text-dark btn-sm mx-2' onClick={() => setOpenModalRelatorio(true)}>
                                                                <span>Relatório</span>
                                                            </button>

                                                            {/* <button type='button' className='btn btn-primary text-dark btn-sm' disabled={loading} onClick={() => setModalRespSendAttach(true)}> */}
                                                            <button type='button' className='btn btn-primary text-dark btn-sm' disabled={loading} onClick={() => [handleSubmitEtapa3(), setModalRespSendAttach(true)]}>
                                                                {/* {!loading && intl.formatMessage({ id: 'Save' })}
                                    {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Processando...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                    )} */}

                                                                {!loading ?
                                                                    closeStepOne ? 'Finalizar etapa 2' : intl.formatMessage({ id: 'Save' })
                                                                    :
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Processando...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                }

                                                            </button>

                                                        </div>

                                                    </div>

                                                    :

                                                    <div>

                                                        <div className=' d-flex justify-content-end'>

                                                            <button type='button' className='btn btn-primary text-dark btn-sm mx-2' onClick={() => setOpenModalRelatorio(true)}>
                                                                <span>Relatório</span>
                                                            </button>

                                                        </div>

                                                    </div>

                                                }

                                            </div>
                                            :
                                            null
                                    }

                                </div>

                            }
                        </div>
                }

            </div>

            <Modal
                open={openModalConfirm}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>

                    <div className='container mt-5 p-5 text-center '>
                        {riskAnaliseData.receive_attach && modalRespSendAttach && closeStepOne ?
                            <>
                                <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}>Evidências</h1>

                                <p className='w-90 mx-auto fs-4 mt-5'>
                                    Caso tenha documentos, fotos ou qualquer outro tipo de arquivo que possa
                                    contribuir para a análise, você pode anexá-los enviando por e-mail.
                                    Isso inclui PDFs, imagens, entre outros formatos compatíveis.
                                </p>
                                <a className='btn btn-primary text-dark mx-auto fs-4 mt-5' href="#" onClick={handleOpenEmail}>Clique aqui para enviar</a>
                                <p className='w-90 mx-auto fs-4 mt-5'>
                                    Você já enviou as evidências para o e-mail <b>{riskAnaliseData.mail}</b> ou planeja fazer isso em breve? Sua resposta é crucial para garantir que todas as informações pertinentes sejam consideradas na análise.
                                </p>

                            </> :
                            <>
                                <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> Confirmação</h1>
                                <p className='w-90 mx-auto fs-4 mt-5'>

                                    As informaçoes serão enviadas

                                    {!closeStepOne ?
                                        ' e Armazenadas para que possa continuar o processo de'
                                        :
                                        ' e Armazenadas finalizando o processo de '
                                    }

                                    {
                                        step == 'step-2' && !locked ? ' Análise'
                                            : step == 'step-2' && locked ? (locked2 ? 'Visualização' : ' Tratamento')
                                                : step == 'step-3' && locked ? (locked2 ? 'Visualização' : ' Tratamento')
                                                    : ' Passo Desconhecido! '
                                    }

                                    {!closeStepOne ?
                                        ' posteriormente'
                                        :
                                        ''
                                    }

                                    , deseja continuar?

                                </p>
                            </>


                        }
                    </div>


                    {
                        modalRespSendAttach && closeStepOne ?
                            <>
                                <div className='d-flex justify-content-center mb-4'>
                                    {!loadingSave ?
                                        <button
                                            onClick={() => [setModalRespSendAttach(false)]}
                                            disabled={loadingSave}
                                            style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark mx-2'>
                                            {intl.formatMessage({ id: 'No' })}
                                        </button>
                                        :
                                        null
                                    }

                                    <button
                                        disabled={loadingSave}
                                        onClick={() => [setModalRespSendAttach(false), setRespSendAttach(true)]}
                                        style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark'>

                                        {!loadingSave ?
                                            intl.formatMessage({ id: 'Yes' })
                                            :
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Processando...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        }

                                    </button>
                                </div>
                            </>
                            :
                            <div className='d-flex justify-content-center mb-4'>
                                {!loadingSave ?
                                    <button
                                        onClick={() => [setOpenModalConfirm(false)]}
                                        disabled={loadingSave}
                                        style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark mx-2'>
                                        {intl.formatMessage({ id: 'Close' })}
                                    </button>
                                    :
                                    null
                                }

                                <button
                                    disabled={loadingSave}
                                    onClick={() => [handleSendResponse()]}
                                    style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark'>

                                    {!loadingSave ?
                                        closeStepOne ? 'Finalizar etapa' : intl.formatMessage({ id: 'Save' })
                                        :
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Processando...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    }

                                </button>

                            </div>
                    }
                </Box>
            </Modal>

            <Modal
                open={openModalReqAtention}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>

                    <div className='container mt-5 p-5 text-center '>
                        <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> Atenção</h1>
                        <p className='w-90 mx-auto fs-4 mt-5'>
                            Um ou mais campos não foram preenchidos, favor verificar!
                        </p>
                    </div>
                    <div className='d-flex justify-content-center mb-4'>
                        <button
                            onClick={() => [setOpenModalReqAtention(false)]}
                            style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark mx-2'>
                            {intl.formatMessage({ id: 'Close' })}
                        </button>
                    </div>

                </Box>
            </Modal>

            <Modal
                open={openModalActionAtention}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>

                    <div className='container mt-5 p-5 text-center '>
                        <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> Atenção</h1>
                        <p className='w-90 mx-auto fs-4 mt-5'>
                            Uma ou mais ações inseridas, os campos devem ser preenchidos
                        </p>
                    </div>
                    <div className='d-flex justify-content-center mb-4'>
                        <button
                            onClick={() => [setOpenModalActionAtention(false)]}
                            style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark mx-2'>
                            {intl.formatMessage({ id: 'Close' })}
                        </button>
                    </div>

                </Box>
            </Modal>

            <Modal
                open={openModalNotification}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>

                    <div className='container mt-5 p-5 text-center '>
                        <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> Análise</h1>
                        <p className='w-90 mx-auto fs-4 mt-5'>
                            {analisyNotification}
                        </p>
                    </div>
                    <div className='d-flex justify-content-center mb-4'>
                        <button
                            onClick={() => [setOpenModalNotification(false)]}
                            style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark mx-2'>
                            {intl.formatMessage({ id: 'Close' })}
                        </button>
                    </div>

                </Box>
            </Modal>

            {/* Modal Relatorio */}
            <Modal
                open={openModalRelatorio}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>



                    <div className='container mt-5 p-5 text-center '>
                        <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> Relatório</h1>
                        <p className='w-70 mx-auto mt-5'>
                            {
                                pdfLoading ?
                                    <span>
                                        <span className='spinner-border spinner-border-sm align-middle mx-2'></span>
                                        <span>Aguarde, trabalhando em sua solicitação...</span>
                                    </span>
                                    :
                                    <span>Caso queria emitir o relatório em PDF <span onClick={() => gerarRelatorioPdf()} className='text-primary' style={{ cursor: 'pointer' }}>Clique Aqui</span>, ou informe o endereço abaixo para recebe-lo por e-mail.</span>

                            }

                        </p>
                    </div>

                    {!pdfLoading ?
                        <div className='mx-10'>

                            <form
                                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                                noValidate
                                id='kt_form_relatorio'
                                onSubmit={formik2.handleSubmit}
                            >

                                {/* email */}
                                <div className='fv-row mb-12'>

                                    <div className='d-flex'>
                                        <input
                                            disabled={loading}
                                            placeholder={intl.formatMessage({ id: 'E-mail' })}
                                            type='email'
                                            autoComplete='off'
                                            {...formik2.getFieldProps('email')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                { 'is-invalid': formik2.touched.email && formik2.errors.email },
                                                {
                                                    'is-valid': formik2.touched.email && !formik2.errors.email,
                                                }
                                            )}
                                        />
                                        <button type='submit' disabled={loadingRelatorio} style={{ border: '1.5px solid #000000', width: '90px' }} className='btn btn-sm btn-light text-dark mx-2' >
                                            <span></span>
                                            {!loadingRelatorio ?
                                                intl.formatMessage({ id: 'Send' })
                                                :
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            }
                                        </button>

                                    </div>
                                </div>

                                <div className='d-flex justify-content-end mb-4'>
                                    <button
                                        type='button'
                                        onClick={() => [closeModalRelatorio()]}
                                        style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark mx-2'>
                                        {intl.formatMessage({ id: 'Close' })}
                                    </button>
                                </div>

                            </form>

                        </div>
                        :
                        null
                    }




                </Box>
            </Modal>


            {/* Modal - ACTION - OUTRo */}
            <Modal
                open={openModalActionOther}
                onClose={() => { }}
                aria-labelledby="modal-action-other"
                aria-describedby="modal-action-other"
            >
                <Box sx={modalStyle}>

                    <div className='container mt-5 p-5 text-center '>
                        <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}>{intl.formatMessage({ id: 'Action' })}</h1>
                        <p className='w-70 mx-auto mt-5'>
                            Informe no campo abaixo qual foi a ação realizada
                        </p>
                    </div>

                    <div className='mx-10'>

                        <form
                            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                            noValidate
                            id='kt_form_relatorio'
                            onSubmit={formik3.handleSubmit}
                        >

                            {/* email */}
                            <div className='fv-row mb-12'>

                                <div className='d-flex'>
                                    <input
                                        disabled={loading}
                                        type='text'
                                        autoComplete='off'
                                        {...formik3.getFieldProps('new_action')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            { 'is-invalid': formik3.touched.new_action && formik3.errors.new_action },
                                            {
                                                'is-valid': formik3.touched.new_action && !formik3.errors.new_action,
                                            }
                                        )}
                                    />
                                </div>
                            </div>

                            <div className='d-flex justify-content-end mb-4'>
                                <button
                                    type='button'
                                    onClick={() => [setOpenModalActionOther(false), formik3.setFieldValue('new_action', ''), setActionRiskUuidOther('')]}
                                    style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark mx-2'>
                                    {intl.formatMessage({ id: 'Cancel' })}
                                </button>

                                <button type='submit' disabled={loadingRelatorio} style={{ border: '1.5px solid #000000', width: '90px' }} className='btn btn-sm btn-light text-dark mx-2' onClick={SendEmalPdf}>
                                    <span></span>
                                    {!loadingRelatorio ?
                                        intl.formatMessage({ id: 'Ok' })
                                        :
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    }
                                </button>
                            </div>

                        </form>

                    </div>

                </Box>
            </Modal>

        </div >

    )
}
export default Step2;