import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { KTSVG } from '../../../../../helpers';
import { getCompanies, getSector } from '../../../../../../app/modules/helpers/Api';
import { Load } from '../../../spinner/Spinner';
import ModalMessage from '../../../modals/modalMessage';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

interface IModalDataMappingTriagemProps {
  showModal: boolean;
  title: string;
  onClose: () => void;
  goToForm: (action: boolean, data: any) => void;
}

const ModalDataMappingTriagem: React.FC<IModalDataMappingTriagemProps> = ({
  ...props
}) => {

  const [title, setTitle] = useState('');
  const [sector, setSector] = useState('');
  const [company, setCompany] = useState('');
  const [step, setStep] = useState(1);

  const [openModalCancel, setOpenModalCancel] = useState(false);

  const [sectorData, setSectorData] = useState<any>([]);
  const [companyData, setCompanyData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(()=> {
    setLoading(true);

    const get = getSector();
    get.then((res)=>{
      setSectorData(res.data.data.data);
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      }else{
        toast.error('Sorry, an error occurred!');
      }
    });

    const getCompania = getCompanies();
    getCompania.then((res)=>{
      setCompanyData(res.data.data.data);
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      }else{
        toast.error('Sorry, an error occurred!');
      }
    })
    .finally(()=>setLoading(false))

  },[]);

  const [errorMsg, setErrorMsg] = useState('');

  const handleTriagem = (answer: any) => {
    var error = false;
    if(step === 1 || step === 1 && answer === 'yes'){
      if(title === ''){
        setErrorMsg('Title is required')
        error = true;
      }
    }
    if(step === 2 && answer === 'yes'){
      if(company === ''){
        setErrorMsg('Company is required')
        error = true;
      }
    }
    if(!error){
      const dataMappingTriagem = {
        title: title,
        companyId: company !== ''? parseInt(company.split(',')[1]) : '',
        company: company.split(',')[0],
        sectorId: sector !== ''? parseInt(sector.split(',')[1]) : '',
        sector: sector.split(',')[0]
      } 
      if(step !== 3){
        setStep(step+1);
      } else {      
        toast.success('Data has been saved successfully, lets start the form!');
        props.onClose();
        clear();
        props.goToForm(true, dataMappingTriagem);
      }
    }
  }

  function clear(){
    setStep(1);
    setTitle('');
    setSector('');
    setCompany(''); 
  }

  return (
    <>
      <ModalMessage
        message='Are you sure you want to cancel?'
        title='Attention!'
        showModal={openModalCancel}
        onClose={()=>setOpenModalCancel(false)}
        onAction={()=>[
          setOpenModalCancel(false),
          props.onClose(),
          clear()
        ]}
      />
      <Modal
        open={props.showModal}
        onClose={()=>{}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
            <span className="modal-title fw-bold fs-3">{props.title}</span>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary"
              onClick={()=>[props.onClose(), clear()]}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className='px-5 my-5' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 350}}>
            {
              loading?
              <Load/>
              :
              <>
                {
                  step === 1?
                  <div className='w-100'>
                    <div className='col-lg-12'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <label className='col-lg-12 col-form-label fw-bold fs-5 required'>Let's start? Enter a title for your data mapping.</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-5"
                            placeholder="Title"
                            value={title}
                            onChange={e=>[setTitle(e.currentTarget.value), setErrorMsg('')]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <>
                    {
                      step === 2?
                      <div className='w-100'>
                        <div className='col-lg-12'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <label className='col-lg-12 col-form-label fw-bold fs-5'>Do you want to add a company to Data Mapping?</label>
                              <select className='form-select form-select-solid form-select-lg fw-bold fs-4' 
                                onChange={e => [setCompany(e.currentTarget.value), setErrorMsg('')]}
                              >
                                <option value=''>... Select an option</option>
                                {
                                  companyData.map((item: any, index: any)=>
                                    <option key={index} value={[item.fantasy_name, item.id]}>{item.fantasy_name}</option>
                                  )
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className='col-lg-12'>
                        <div className='row'>
                          <div className='col-lg-12 fv-row'>
                            <label className='col-lg-12 col-form-label fw-bold fs-5'>Do you want to add a sector to Data Mapping?</label>
                            <select className='form-select form-select-solid form-select-lg fw-bold fs-4' 
                              onChange={e => [setSector(e.currentTarget.value), setErrorMsg('')]}
                            >
                              <option value=''>... Select an option</option>
                              {
                                sectorData.map((item: any, index: any)=>
                                  <option key={index} value={[item.name, item.id]}>{item.name}</option>
                                )
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    }
                  </>
                }
                {errorMsg && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errorMsg}</span>
                    </div>
                  </div>
                )}
              </>
            }
          </div>

          {
            step === 3?
            <div className='d-flex flex-row w-100 justify-content-end p-5'>
              <button type='button' onClick={()=>handleTriagem('yes')} className='btn btn-md btn-primary me-3'>
                Finish
              </button>
              <button type='button' onClick={()=>setOpenModalCancel(true)} className='btn btn-md btn-danger'>
                Cancel
              </button>
            </div>
            :
            <div className='d-flex flex-row w-100 justify-content-end p-5'>
              <button type='button' onClick={()=>handleTriagem('yes')} className='btn btn-md btn-primary me-3'>
                Yes
              </button>
              <button type='button' onClick={()=>handleTriagem('no')} className='btn btn-md btn-danger'>
                No
              </button>
            </div>
          }
        
        </Box>
      </Modal>
    </>
  )
}
export default ModalDataMappingTriagem;