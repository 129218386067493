/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {upUserPass } from '../../../../app/modules/helpers/Api'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import { toast } from 'react-toastify'
import clsx from 'clsx'
import ModalMessageConfirm from '../modals/modalMessageConfirm'
import { trataRespota } from '../../../../app/modules/helpers/helpers'
import { useIntl } from 'react-intl'



const ChangePass: FC = () => {
  
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
  const[loading, setLoading] = useState(false);
  const [seePassword, setSeePassword] = useState('password');
  const intl = useIntl();   
  const initialValues = {
    current_pass: '',
    new_pass: '',
    confirm_pass: ''
  }

  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const [value, setValue] = useState<any>({});

  const schema = Yup.object().shape({
    current_pass: Yup.string()
    .min(3, `${intl.formatMessage({id: 'Minimum 6 symbols'})}` )
    .max(50, `${intl.formatMessage({id: 'Maximum 50 symbols'})}`)
    .required(`${intl.formatMessage({id: 'Current password is required'})}`),
    new_pass: Yup.string()
    .min(3, `${intl.formatMessage({id: 'Minimum 6 symbols'})}`)
    .max(50,`${intl.formatMessage({id: 'Maximum 50 symbols'})}` )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%^&*])(?=.{6,})/,
      `${intl.formatMessage({id: 'Use 6 or more characters with a mix of letters, numbers & symbols'})}`
      
    )
    .required(`${intl.formatMessage({id: 'New password is required'})}`),
    confirm_pass: Yup.string()
    .required(`${intl.formatMessage({id: 'Password confirmation is required'})}`)
    .when('new_pass', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('new_pass')],`${intl.formatMessage({id: "New Password and Confirm Password did'nt match"})}` ),
    }),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        setValue(values);
        setOpenModalSubmit(true);
        setLoading(false)
      }, 1000)
    },
  })

  function save(value: any){
    const data = {
      current_password: value.current_pass,
      new_password: value.new_pass,
      confirm_password: value.confirm_pass
    }
    const save = upUserPass(user.id, data);
    save.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        formik.resetForm();
        setOpenModalSubmit(false);
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      trataRespota('error', error); 
      // if(error.response){
      //   toast.error(error.response.message);
      // } else {
      //   toast.error('Sorry, an error occurred!');
      // }
    })
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <ModalMessageConfirm
        message={`${intl.formatMessage({id: "Are you sure you want to edition this item?"})}`}
        title={`${intl.formatMessage({id: "Attention"})}`}
        showModal={openModalSubmit}
        onClose={()=>setOpenModalSubmit(false)}
        onAction={()=>save(value)}
      />
      <div className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='p-8   '>
            
            <div className='mb-2 fv-row pt-5' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: "Current Password"})}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <input
                      type='password' 
                      placeholder={`${intl.formatMessage({id: "Current Password"})}`}
                      autoComplete='off'
                      {...formik.getFieldProps('current_pass')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-100 me-1',
                        {
                          'is-invalid': formik.touched.current_pass && formik.errors.current_pass,
                        },
                        {
                          'is-valid': formik.touched.current_pass && !formik.errors.current_pass,
                        }
                      )}
                    />
                  </div>
                  {formik.touched.current_pass && formik.errors.current_pass && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.current_pass}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: "New Password"})}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <input
                      type={seePassword}
                      placeholder={`${intl.formatMessage({id: "New Password"})}`}
                      autoComplete='off'
                      {...formik.getFieldProps('new_pass')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-90 me-1',
                        {
                          'is-invalid': formik.touched.new_pass && formik.errors.new_pass,
                        },
                        {
                          'is-valid': formik.touched.new_pass && !formik.errors.new_pass,
                        }
                      )}
                    />
                    {
                      seePassword === 'password'?
                      <button onClick={()=>setSeePassword('text')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                        <i className="bi bi-eye-slash-fill fs-2x"></i>
                      </button>
                      :
                      <button onClick={()=>setSeePassword('password')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                        <i className="bi bi-eye-fill fs-2x"></i>
                      </button>
                    }
                  </div>
                  {formik.touched.new_pass && formik.errors.new_pass && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.new_pass}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='fv-row mb-5'>
              <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: "Confirm Password"})}</label>
              <input
                type='password'
                placeholder={`${intl.formatMessage({id: "Confirm Password"})}`}
                autoComplete='off'
                {...formik.getFieldProps('confirm_pass')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.confirm_pass && formik.errors.confirm_pass,
                  },
                  {
                    'is-valid': formik.touched.confirm_pass && !formik.errors.confirm_pass,
                  }
                )}
              />
              {formik.touched.confirm_pass && formik.errors.confirm_pass && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.confirm_pass}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex justify-content-end mt-12'>
            
            <button type='submit' className='btn btn-primary text-dark' disabled={loading}>
              {!loading && intl.formatMessage({id: "Save"})}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: "Processing"})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

          </div>
            
          </div>

        </form>
      </div>
    </div>
  )
}

export {ChangePass}
