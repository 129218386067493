/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { countries } from '../../../../../../app/modules/helpers/country'
import { StepSix } from './Step06'

const schema = Yup.object().shape({

})
type Props = {
  move: (action: boolean) => void;
  dataStep1: any;
  dataStep2: any;
  dataStep3: any;
  dataStep4: any;
}

const StepFive: FC<Props> = ({...props}) => {  

  const[loading, setLoading] = useState(false);
  const[next, setNext] = useState('');
  const[dataStep5, setDataStep5] = useState<any>({});

  function move (action: boolean) {
    if(action){
      setNext('');
    }
  }

  const initialValues = {
    who_has_access: '',
    who_has_access_why: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false)
        setDataStep5({
          who_has_access: values.who_has_access,
          who_has_access_why: values.who_has_access_why
        })
        setNext('Next')
      }, 1000)
    },
  })

  return (
    <>
    { next === ''?
    <div className='card mb-5 mb-xl-10'>
      <div className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top px-5 py-5'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <label className='form-label fw-bolder text-dark fs-3 '>New Data Mapping - Step 05 of 06</label>
              {/* <button onClick={()=>props.create(false)} type='submit' className='btn btn-sm btn-secondary'>
                Back
              </button> */}
            </div>
            
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1 mt-4'>
                <label className='form-label fw-bold text-dark fs-6'>Who has Access</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <input
                      type='text'
                      placeholder='Who has Access'
                      autoComplete='off'
                      {...formik.getFieldProps('who_has_access')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-100 me-1',
                        
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>            
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1 mt-4'>
                <label className='form-label fw-bold text-dark fs-6'>Who has Access Why</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <input
                      type='text'
                      placeholder='Who has Access Why'
                      autoComplete='off'
                      {...formik.getFieldProps('who_has_access_why')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-100 me-1',
                        
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>            
            
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-secondary me-3' onClick={()=>props.move(true)}>
              Back
            </button>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              
              {!loading && 'Next'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Processando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
      :
      <StepSix 
        move={move}
        dataStep1={props.dataStep1}
        dataStep2={props.dataStep2}
        dataStep3={props.dataStep3}
        dataStep4={props.dataStep4}
        dataStep5={dataStep5}
      />
    }
    </>
  )
}

export {StepFive}
