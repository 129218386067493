import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { getAction, newAction } from '../../../../../../app/modules/helpers/Api';
import DatePicker from "react-datepicker";
import { validarData } from '../../../../../../app/modules/helpers/helpers';
import moment from 'moment';
import ModalMessageConfirm from '../../../modals/modalMessageConfirm';
import { IActions } from './ActionPlanActionList';
import { Load } from '../../../spinner/Spinner';
import { useIntl } from 'react-intl'

type Props = {
  action: IActions;
  onCancel: () => void;
  onFinish: (action: boolean) => void;
}

const schema = Yup.object().shape({
  // action: Yup.string().required('Action is required'),
  // objective: Yup.string().required('Objective is required')
});

const ActionPlanActionEdition: React.FC<Props> = ({...props}) => {

  const labelTitle = props.action.title
  const newStartDate = props.action.date_start
  const newEndDate = props.action.date_end

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const intl = useIntl();   

  const [actionList, setActionList] = useState<any>([]);
  const [actionValues, setActionValues] = useState<any>({});

  const [startDate, setStartDate] = useState(new Date(moment(newStartDate).format('YYYY/MM/DD')));
  const [endDate, setEndDate] = useState(new Date(moment(newEndDate).format('YYYY/MM/DD')));
  // const [reviewDate, setReviewDate] = useState(new Date());
  
  const [errorDateStart, setErrorDateStart] = useState('');
  const [errorDateEnd, setErrorDateEnd] = useState('');
  const [errorUseDegree, setErrorUseDegree] = useState('');
  const [errorJustification, setErrorJustification] = useState('');
  const [errorObjective, setErrorObjective ] = useState ('');

  let model = '';
  useEffect(()=>{
    setLoading(true);
    
    const action = getAction(model);
    action.then((res)=>{
      setActionList(res.data.data.data);
    }).catch((error)=> {
      toast.error(error.response.data.message)
    })
    .finally(()=>setLoading(false));

  },[props.action.action_id]);
  let date = new Date;
  let dateDay = date.getDate() + 1
  let dateMonth = date.getMonth() + 1
  let dateYear = date.getFullYear()
  let Fulldate = `${dateYear}-${dateMonth}-${dateDay}`
  const formik = useFormik({
    initialValues: {
      id: props.action.id,
      action_plan_id: props.action.action_plan_id,
      action: props.action.action_id,
      objective: props.action.objective,
      justification: props.action.justification,
      use_degree: props.action.use_degree
    },
    validationSchema: schema,
    onSubmit: (values, actions) => {
      setIsLoading(true);
      var error = false;

        // if(validarData(startDate)){setErrorDateStart(`${intl.formatMessage({id: 'Invalid initial date'})}`); error = true}
        if(validarData(endDate)){setErrorDateEnd(`${intl.formatMessage({id: 'Invalid end date'})}`); error = true}
        if((values.objective === '' || values.objective === null)){
          setErrorObjective(`${intl.formatMessage({id: 'Objective is required'})}`); error = true
        
        }
        if(values.objective === 'yes'){
          if(values.use_degree === '' || values.use_degree === null){
            setErrorUseDegree(`${intl.formatMessage({id: 'Use degree is required'})}`); error = true
          }
        } else {
          if(values.justification === '' || values.justification === null){
            setErrorJustification(`${intl.formatMessage({id: 'Justification is required'})}`); error = true
          }
        }
   

        setActionValues(values);
        if(!error){setOpenModalSubmit(true)}
        setIsLoading(false);
     
    },
  })

  function handleSubmit(){
    const data = {
      id: parseInt(actionValues.id),
      action_id: parseInt(actionValues.action),
      date_start: moment(startDate).format('YYYY-MM-DD'),
      date_end:moment(endDate).format('YYYY-MM-DD'),
      action_plan_id: actionValues.action_plan_id,
      objective: actionValues.objective,
      justification: actionValues.justification,
      use_degree: actionValues.use_degree,
      edition: true
    }

    const save = newAction(data.action_id, data);
    save.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        props.onFinish(true);
        formik.resetForm();
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      }
    }) 
  }


  return(
    <div className='card-body border-dashed border-2 border-gray-300 rounded px-5 py-5 my-2'>
      <ModalMessageConfirm
        message={intl.formatMessage({id: 'Are you sure you want to edition this item?'})}
        title={intl.formatMessage({id: 'Attention'})}
        showModal={openModalSubmit}
        onClose={()=>setOpenModalSubmit(false)}
        onAction={handleSubmit}
      />
      {
        loading?
        <Load/>
        :
        <div className='mx-4 align-items-center'>
          <form onSubmit={formik.handleSubmit} id='form-action-plan-action' noValidate className='form w-100'>
            <div className='align-items-center w-100'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                    <label className='col-lg-12 col-form-label fw-bold fs-5'>{labelTitle}</label>
                    {/* <select className='form-select form-select-solid text-gray-400 form-select-lg fw-bold fs-4' 
                      {...formik.getFieldProps('action')}
                      disabled
                    >
                      <option value='' >... Select an Action</option>
                      {
                        actionList.map((item: any, index: any)=>
                          <option className='fs-6 flex-wrap w-100px' key={index} value={item.id}>{item.title.slice(0,100)}...</option>
                        )
                      }
                      
                    </select> */}
                    
                    {/* {formik.touched.action && formik.errors.action && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fs-5'>{formik.errors.action}</div>
                      </div>
                    )} */}
                  </div>
                  <div className='col-sm-4 fv-row'>
                    <label className='col-lg-5 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Initial Date'})}</label>
                    <DatePicker 
                      selected={startDate} 
                      disabled
                      dateFormat={'dd/MM/yyyy'}
                      onChange={(date:Date) => [setStartDate(date), setErrorDateStart('')]} 
                      className='form-control form-control-md form-control-solid fs-4 text-gray-400'
                    />
                    {errorDateStart && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fs-6 mt-1'>{errorDateStart}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-sm-4 fv-row'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Final Date'})}</label>
                    <DatePicker 
                      selected={endDate} 
                      dateFormat={'dd/MM/yyyy'}
                      minDate={moment(Fulldate).toDate()}
                      onChange={(date:Date) => [setEndDate(date), setErrorDateEnd('')]} 
                      className='form-control form-control-md form-control-solid fs-4'
                      disabled={moment(endDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')}
                      
                    />
                    {errorDateEnd && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fs-6 mt-1'>{errorDateEnd}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-sm-4 fv-row'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Objective'})}</label>
                    <select className='form-select form-select-solid form-select-lg fw-bold fs-4' 
                      {...formik.getFieldProps('objective')}
                    >
                      <option value=''>... {intl.formatMessage({id: 'Select an action'})}</option>
                      <option value='yes'>{intl.formatMessage({id: 'Yes'})}</option>
                      <option value='no'>{intl.formatMessage({id: 'No'})}</option>
                    </select>
                    {errorObjective && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger fs-6 mt-1'>{errorObjective}</div>
                        </div>
                      )}
                   
                  </div>
                  {
                    (formik.values.objective === 'yes' || props.action.objective === 1) && formik.values.objective !== ''?
                    <div className='col-lg-12 fv-row'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Operation difficulty level'})}</label>
                      <select className='form-select form-select-solid form-select-lg fw-bold fs-4' 
                        onFocus={()=>setErrorUseDegree('')}
                        {...formik.getFieldProps('use_degree')}
                      >
                        <option value=''>...{intl.formatMessage({id: 'Select an option'})}</option>
                        <option value="max">{intl.formatMessage({id: 'Max'})}</option>
                        <option value="min">{intl.formatMessage({id: 'Min'})}</option>
                      </select>
                      {errorUseDegree && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger fs-6 mt-1'>{errorUseDegree}</div>
                        </div>
                      )}
                    </div>
                    :
                    <>
                      {
                        (formik.values.objective === 'no' || props.action.objective === 0) && formik.values.objective !== ''?
                        <div className='col-lg-12 fv-row'>
                          <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Justification'})}</label>
                          <textarea
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'Justification'})}
                            onFocus={()=>setErrorJustification('')}
                            {...formik.getFieldProps('justification')}
                          />
                          {errorJustification && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fs-6 mt-1'>{errorJustification}</div>
                            </div>
                          )}
                        </div>
                        :
                        <></>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </form>
          <div className='d-flex flex-row w-100 justify-content-end py-5'>
            <button type='button' onClick={()=>props.onCancel()} title={intl.formatMessage({id: 'Cancel edition'})} form='form-action-plan-action' className='btn me-2 btn-sm btn-secondary' disabled={isLoading}>
            {intl.formatMessage({id: 'Cancel'})}
            </button>
            <button type='submit' title={intl.formatMessage({id: 'Save changes'})} form='form-action-plan-action' className='btn btn-sm btn-primary text-dark' disabled={isLoading}>
              {!isLoading && intl.formatMessage({id: 'Save'})}
              {isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'Processing'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      }
    </div>
  )
}
export default ActionPlanActionEdition;