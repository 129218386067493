import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../../../helpers';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { getResourceLink, upProfile } from '../../../../../../../app/modules/helpers/Api';
import ModalMessageConfirm from '../../../../modals/modalMessageConfirm';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { countries } from '../../../../../../../app/modules/helpers/country'
import { trataRespota } from '../../../../../../../app/modules/helpers/helpers';
import { useIntl } from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'
import { UserModel } from '../../../../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../../../../setup';
import { mask } from '../../../../../../../app/modules/helpers/helpers'
import clsx from 'clsx'
import InputMask from "react-input-mask"
import { info } from 'console';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IModalProfileProps {
  showModal: boolean;
  title: string;
  onClose: () => void;
  onAction: () => void;
  fetchUser: (userId: any) => void;
  id: any;
  data: any
}


const ModalProfile: React.FC<IModalProfileProps> = ({
  ...props
}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel


  const[loading, setLoading] = useState(false);
  const [value, setValue] = useState<any>({});
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [errorDateStart, setErrorDateStart] = useState('');
  const intl = useIntl();   
  const [postalcode, setPostalcode] = useState(user.post_code ? user.post_code : '');
  const [document, setDocument] = useState(user.document || '');
  const [errorDocument, setErrorDocument] = useState('');

  const [cepError, setCepError] = useState(false);
  const[cepData, setCep] = useState({
    city:       user.city       || undefined,
    state:      user.state      || undefined,
    district:   user.district   || undefined,
    street:     user.street     || undefined,
    complement: user.complement || undefined,
	});


  function addMask(e: any){
    setDocument(mask(e));
  }
  useEffect(()=>{
    setDocument(props.data.document)
  },[props.data.document])
  const schema = Yup.object().shape({
    name: Yup.string().required(`${intl.formatMessage({id: 'Name'})} ${intl.formatMessage({id: 'is required'})}`),
    lastName: Yup.string().required(`${intl.formatMessage({id: 'Last Name'})} ${intl.formatMessage({id: 'is required'})}`),
    cpf: Yup.string().min(14, `${intl.formatMessage({id: 'Minimum 14 symbols'})}`)  
  })  
  const initialValues = {
    name: props.data.name,
    use_terms: props.data.use_terms,
    lastName: props.data.last_name,
    birthday: props.data.birthday ? moment(props.data.birthday).format('DD-MM-YYYY') : undefined,
    // birthday: props.data.birthday? props.data.birthday : '',
    language: props.data.language,
    phone: props.data.phone? props.data.phone : '',
    phone_prefix: props.data.phone_prefix? props.data.phone_prefix : '',
    country: props.data.country? props.data.country : '',
    state: props.data.state? props.data.state : '',
    city: props.data.city? props.data.city : '',
    postCode: props.data.post_code? props.data.post_code: '',
    street: props.data.street? props.data.street : '',
    number: props.data.number? props.data.number : '',
    // trial: props.data.trial? props.data.trial : '',
    trial: props.data.trial ? moment(props.data.trial).format('DD-MM-YYYY') : undefined,
    current_plan_date: props.data.current_plan_date ? moment(props.data.current_plan_date).format('DD-MM-YYYY') : undefined,
    status: props.data.status? props.data.status : '',
    email_verified_at: props.data.email_verified_at ? props.data.email_verified_at: '',
    cpf: props.data.cpf ? props.data.cpf: ''
  }
  
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      // setTimeout(() => {
        setValue(values);
        setOpenModalSubmit(true);
        setLoading(false)
      // }, 1000)
    },
  })
  
  function handleGetResource(hash: any) {
    const get = getResourceLink(hash);
    // get.then((res)=>{
    
    //   let myBlob = new Blob([res.data], {
    //     type: "application/pdf",
    //   });
    
    //   const fileURL = URL.createObjectURL(myBlob);
    //   window.open(fileURL);
    // })
    }

  function save(value: any){

    const data = {
      name: value.name,
      last_name: value.lastName,
      cpf: value.cpf,
      document: document,
      use_terms: value.use_terms,
      email_verified_at: value.email_verified_at,
      // birthday: moment(startDate).format('YYYY-MM-DD'),
      birthday: value.birthday ? moment(value.birthday, 'DDMMYYYY').format('YYYY-MM-DD') : null,
      language: value.language,
      phone: value.phone,
      phone_prefix: value.phone_prefix,
      country: value.country,
      city: value.city,
      state: value.state,
      street: value.street,
      number: value.number,
      post_code: value.postCode,
      // trial: value.trial,
      // trial: value.birthday ?moment(value.trial).format('YYYY-MM-DD') || null,
      trial: value.trial ? moment(value.trial, 'DDMMYYYY').format('YYYY-MM-DD') : 'empty',
      status: value.status,
      current_plan_date: value.current_plan_date ? moment(value.current_plan_date, 'DDMMYYYY').format('YYYY-MM-DD') : 'empty',
    }
    const save = upProfile(props.id, data);
    save.then((res)=>{
      if(res.data.status === 'success'){
        setOpenModalSubmit(false);
        props.onAction();
        props.fetchUser(props.id);
        toast.success(res.data.message);
        // formik.resetForm();

        
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      trataRespota('error', error); 
      // if(error.response){
      //   toast.error(error.response.message);
      // } else {
      //   toast.error('Sorry, an error occurred!');
      // }
    })
  }

  return(
    <>
    <ModalMessageConfirm
      message={intl.formatMessage({id: 'Are you sure you want to edition this item?'})}
      title={intl.formatMessage({id: 'Attention'})}
      showModal={openModalSubmit}
      onClose={()=>setOpenModalSubmit(false)}
      onAction={()=>save(value)}
    />
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
          <span className="modal-title fw-bold fs-3">{props.title}</span>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>props.onClose()}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className='px-5 mb-5 mt-1' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 350}}>

          <form onSubmit={formik.handleSubmit} noValidate className='form' id='form' encType="multipart/form-data">
            <div className='card-body'>
              <div className='col-lg-12 fv-row mb-3'>
                <div className='row'>
                  <div className='col-lg-12'>
                    {/* <label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Photo'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type={'file'}
                          placeholder={intl.formatMessage({id: 'Photo'})}
                          {...formik.getFieldProps('photo')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className='col-lg-6'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Name'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'Name'})}
                          autoComplete='off'
                          {...formik.getFieldProps('name')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.name}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Last Name'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'Last Name'})}
                          autoComplete='off'
                          {...formik.getFieldProps('lastName')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.lastName && formik.errors.lastName && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.lastName}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'CPF'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <InputMask
                          mask="999.999.999-99"
                          type="text"
                          className="form-control form-control-solid fs-6"
                          placeholder="xxx.xxx.xxx-xx"
                          name='cpf'
                          onChange={ (e) => formik.setFieldValue('cpf', e.target.value)}
                          value={formik.getFieldProps('cpf').value}
                        />	
                      </div>
                      
                    </div>
                  </div>

                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Document'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={'CNPJ'}
                          autoComplete='off'
                          onChange={e=>[addMask(e.currentTarget.value), setErrorDocument('')]}                
                          value={document}
                          maxLength={18}
                          className={clsx(
                            'form-control form-control-lg form-control-solid w-100 me-1',
                            {'is-invalid': errorDocument || ![14,18].includes(document?.length)},
                            {
                              'is-valid': [14,18].includes(document?.length),
                            }
                          )}
                        />
                      </div>
                      
                    </div>
                  </div>
                  
                  {/* <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6'>Language</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder='Language'
                          autoComplete='off'
                          {...formik.getFieldProps('language')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.language && formik.errors.language && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.language}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div> */}
                  <div className='col-lg-6'>
                    <label className='col-lg-5 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Phone prefix'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'Phone prefix'})}
                          autoComplete='off'
                          {...formik.getFieldProps('phone_prefix')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.phone_prefix && formik.errors.phone_prefix && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.phone_prefix}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Phone'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'Phone'})}
                          autoComplete='off'
                          {...formik.getFieldProps('phone')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.phone && formik.errors.phone && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.phone}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Birthday'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                            <InputMask
                              mask="99/99/9999"
                              type="text"
                              className="form-control form-control-solid fs-6" 
                              placeholder="DD/MM/YYYY"
                              name='birthday'
                              onChange={ (e) => formik.setFieldValue('birthday', e.target.value)}
                              value={formik.getFieldProps('birthday').value}
                              
                            />
                        {/* <DatePicker 
                          selected={startDate} 
                          dateFormat={'dd/MM/yyyy'}
                          onChange={(date:Date) => [setStartDate(date), setErrorDateStart('')]} 
                          className='form-control form-control-md form-control-solid fs-4'
                        /> */}
                      </div>
                      {errorDateStart && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger fs-6 mt-1'>{errorDateStart}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-12 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Postal Code'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'Postal Code'})}
                          autoComplete='off'
                          {...formik.getFieldProps('postCode')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.postCode && formik.errors.postCode && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.postCode}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Country'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <select className='form-select form-control form-control-solid fs-6' 
                          {...formik.getFieldProps('country')} 
                        >
                          
                          {
                            countries.map((item: any)=>
                              <option key={item.name} value={item.code}>{item.name}</option>
                            )
                          }
                        </select>
                      </div>
                      {formik.touched.country && formik.errors.country && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.country}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'State'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'State'})}
                          autoComplete='off'
                          {...formik.getFieldProps('state')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.state && formik.errors.state && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.state}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'City'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'City'})}
                          autoComplete='off'
                          {...formik.getFieldProps('city')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.city && formik.errors.city && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.city}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Postal Code'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'Postal Code'})}
                          autoComplete='off'
                          {...formik.getFieldProps('postCode')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.postCode && formik.errors.postCode && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.postCode}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div> */}
                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Street'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'Street'})}
                          autoComplete='off'
                          {...formik.getFieldProps('street')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.street && formik.errors.street && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.street}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Number'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'Number'})}
                          autoComplete='off'
                          {...formik.getFieldProps('number')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.number && formik.errors.number && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.number}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>


                </div>


                <h5 className='mt-10'>{intl.formatMessage({id: 'User Status'})}</h5>
                <hr className='mt-0'/>
                <div className='col-sm-12 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Status'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <select className='form-select form-control form-control-solid fs-6' 
                          {...formik.getFieldProps('status')} 
                        >
                          
                          <option value='' disabled>{intl.formatMessage({id: 'Select an status'})}...</option>
                          <option value='active'>{intl.formatMessage({id: 'Active'})}</option>
                          <option value='inative'>{intl.formatMessage({id: 'Inactive'})}</option>

                        </select>
                      </div>
                    </div>
                  </div>




                  <h5 className='mt-10'>{intl.formatMessage({id: 'Trial period'})}</h5>
                  <hr className='mt-0'/>
                
                  <div className='row'>
                    <div className='col-12 fv-row mb-3'>
                      <label className='col-12 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Enter a valid date for the test time'})}</label>
                      <div className='position-relative mb-3'>
                        <div className='d-flex justify-content-between w-100'>
                          {/* <input
                            type='text'
                            placeholder='ex: 2000-01-01'
                            autoComplete='off'
                            {...formik.getFieldProps('trial')}
                            className='form-control form-control-lg form-control-solid w-100 me-1'
                          /> */}

                            <InputMask
                              mask="99/99/9999"
                              type="text"
                              className="form-control form-control-solid fs-6"
                              placeholder="DD/MM/YYYY"
                              name='trial'
                              onChange={ (e) => formik.setFieldValue('trial', e.target.value)}
                              value={formik.getFieldProps('trial').value}
                              
                            />


                        </div>
                        {formik.touched.trial && formik.errors.trial && (
                          <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.trial}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>




                  <h5 className='mt-10'>Plano Atual</h5>
                  <hr className='mt-0'/>
                
                  <div className='row'>
                    <div className='col-12 fv-row mb-3'>
                      <label className='col-12 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'A data exibida representa a data de válidade do plano atual, caso não exista insira se necessario.'})}</label>
                      <div className='position-relative mb-3'>
                        <div className='d-flex justify-content-between w-100'>
                          {/* <input
                            type='text'
                            placeholder='ex: 2000-01-01'
                            autoComplete='off'
                            {...formik.getFieldProps('trial')}
                            className='form-control form-control-lg form-control-solid w-100 me-1'
                          /> */}

                            <InputMask
                              mask="99/99/9999"
                              type="text"
                              className="form-control form-control-solid fs-6"
                              placeholder="DD/MM/YYYY"
                              name='current_plan_date'
                              onChange={ (e) => formik.setFieldValue('current_plan_date', e.target.value)}
                              value={formik.getFieldProps('current_plan_date').value}
                              
                            />


                        </div>
                        {formik.touched.trial && formik.errors.trial && (
                          <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.trial}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>





              </div>
            </div>
            <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={formik.values.use_terms}
                  id='kt_login_toc_agree'
                  {...formik.getFieldProps('use_terms')}
                />
                <label
                  className='form-check-label fw-bold text-gray-700 fs-6'
                  htmlFor='kt_login_toc_agree'
                >
                  {intl.formatMessage({id: 'I Agree with the'})}{' '}
                  <a onClick={()=> handleGetResource('user_term')}  className='ms-1 link-primary'>
                  {intl.formatMessage({id: 'terms and conditions'})}
                  </a>
                  
                  {/* <Link to='/auth/file/show?name=use_terms' className='ms-1 link-primary'>
                    terms and conditions
                  </Link> */}
                  
                </label>
                {formik.touched.use_terms && formik.errors.use_terms && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.use_terms}</span>
                    </div>
                  </div>
                )}
              </div>


              {
                props.data.email_verified_at?
                <></>
                :
                <div className='form-check form-check-custom form-check-solid mt-3'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={formik.values.email_verified_at}
                  id='kt_login_toc_agree'
                  {...formik.getFieldProps('email_verified_at')}
                />
                <label
                  className='form-check-label fw-bold text-gray-700 fs-6'
                  htmlFor='kt_login_toc_agree'
                >
                  {intl.formatMessage({id: 'Verificar E-mail'})}{' '}                  
                </label>
                {formik.touched.email_verified_at && formik.errors.email_verified_at && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email_verified_at}</span>
                    </div>
                  </div>
                )}
              </div>
              }
          </form>

        </div>


        <div className='d-flex flex-row w-100 justify-content-end p-5'>
          <button
            type="button"
            className="btn btn-danger mx-2"
            onClick={()=>props.onClose()}
          >
            {intl.formatMessage({id: 'Cancel'})}
          </button>
          <button type='submit' form='form' className='btn btn-primary text-dark' disabled={loading}>
            {!loading && intl.formatMessage({id: 'Save'})}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
               {intl.formatMessage({id: 'Processing'})} ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </Box>
    </Modal>
    </>
  )
}
export default ModalProfile;