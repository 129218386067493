/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
// import {KTSVG} from '../../../helpers'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemArrow } from './AsideMenuItemArrow'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel


  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />


      {
        user.roles !== 'free' ?
          <>
            <div className='menu-item'>
              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({ id: 'Management' })}</span>
              </div>
            </div>
            <AsideMenuItemArrow
              to='/crafted/pages/company/companies'
              icon='/media/icons/duotune/finance/fin006.svg'
              title={intl.formatMessage({ id: 'Companies' })}
              fontIcon='bi-layers'
            />
          </>
          :
          <></>
      } 

      <AsideMenuItemArrow
        to='/crafted/pages/commum/calculator'
        icon='/media/icons/duotune/finance/fin007.svg'
        title={intl.formatMessage({ id: 'Calculator' })}
        fontIcon='bi-layers'
      />

      <AsideMenuItemArrow
        to='/crafted/pages/commum/tools'
        icon='/media/icons/duotune/finance/fin005.svg'
        title={intl.formatMessage({ id: 'Tools' })}
        fontIcon='bi-layers'
      />


            <AsideMenuItemArrow
              to='/crafted/pages/commum/categories'
              icon='/media/icons/duotune/layouts/lay002.svg'
              title={intl.formatMessage({ id: 'Categories' })}
              fontIcon='bi-layers'
            />
    


      {/* <AsideMenuItemArrow
        to='/crafted/pages/commum/data-mapping'
        icon='/media/icons/duotune/general/gen005.svg'
        title='Data Mapping'
        fontIcon='bi-layers'
      /> */}
      <AsideMenuItemArrow
        to='/crafted/pages/commum/resources'
        icon='/media/icons/duotune/general/gen028.svg'
        title={intl.formatMessage({ id: 'MENU.RESOURCES' })}
        fontIcon='bi-layers'
      />

      {
        user.roles !== 'free' ?
          <>
            {
              ['super-admin'].includes(user.roles) ?
                <AsideMenuItemArrow
                  to='/crafted/pages/commum/gaps'
                  icon='/media/icons/duotune/general/gen035.svg'
                  title={intl.formatMessage({ id: 'Gaps' })}
                  fontIcon='bi-layers'
                />
               :
                <></>
            }
            
            {/* <AsideMenuItemArrow
            to='/crafted/pages/action/actions'
            icon='/media/icons/duotune/general/gen035.svg'
            title={intl.formatMessage({id: 'Actions'})}
            fontIcon='bi-layers'
          /> */}

            <AsideMenuItemArrow
              to='/crafted/pages/action/plansactions'
              icon='/media/icons/duotune/general/gen048.svg'
              title={intl.formatMessage({ id: 'Actions Plans' })}
              fontIcon='bi-layers'
            />
          </>
          :
          <></>
      }
      {
        user.roles === 'super-admin' || user.roles === 'admin' ?
          <>
            {/* <AsideMenuItemArrow 
          to='/crafted/pages/commum/forms'
          icon='/media/icons/duotune/layouts/lay006.svg'
          title={intl.formatMessage({id: 'Forms'})}
          fontIcon='bi-layers'
        /> */}
            <AsideMenuItemArrow
              to='/crafted/pages/commum/questions'
              icon='/media/icons/duotune/layouts/lay002.svg'
              title={intl.formatMessage({ id: 'Questions' })}
              fontIcon='bi-layers'
            />
            <AsideMenuItemArrow
              to='/crafted/pages/commum/users'
              icon='/media/icons/duotune/communication/com006.svg'
              title={intl.formatMessage({ id: 'Users' })}
              fontIcon='bi-layers'
            />
            <AsideMenuItemArrow
              to='/crafted/pages/commum/coupons'
              icon='/media/icons/duotune/communication/com006.svg'
              title={intl.formatMessage({ id: 'Coupons' })}
              fontIcon='bi-layers'
            />
          </>
          :
          <></>
      }

      <AsideMenuItemWithSub
        to='/crafted/pages/commum/store'
        title={intl.formatMessage({ id: 'Store' })}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/ecommerce/ecm004.svg'
      >
        <AsideMenuItemArrow
          to='/crafted/pages/commum/store/products'
          icon='/media/icons/duotune/ecommerce/ecm004.svg'
          title={intl.formatMessage({ id: 'Products' })}
          fontIcon='bi-layers'
        />
        <AsideMenuItemArrow
          to='/crafted/pages/commum/payments'
          icon='/media/icons/duotune/finance/fin010.svg'
          title={intl.formatMessage({ id: 'Requests' })}
          fontIcon='bi-layers'
        />

      </AsideMenuItemWithSub>


      <AsideMenuItemArrow
        to='/tutoriais'
        icon='/media/icons/duotune/communication/com005.svg'
        title={intl.formatMessage({ id: 'MENU.TUTORIAL' })}
        fontIcon='bi-layers'
      />


      {/* <AsideMenuItemArrow
        to='/crafted/pages/commum/profile'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Profile'
        fontIcon='bi-layers'
      /> */}

      {/*
        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
