import moment from "moment";
import { toast } from 'react-toastify'

export function mask (v: any){
    v = v.replace(/\D/g, "")
  
    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    } else {
      v = v.replace(/^(\d{2})(\d)/, "$1.$2")
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
      v = v.replace(/(\d{4})(\d)/, "$1-$2")
    }
  
    return v
  }
export function maskCnpj (v: any){
    v = v.replace(/\D/g, "")
  
    v = v.replace(/^(\d{2})(\d)/, "$1.$2")
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
    v = v.replace(/(\d{4})(\d)/, "$1-$2")
    
    return v
  }

  export function converteData (data: string){ 
    const dataConvertida = moment(data).format("DD/MM/YYYY, h:mm a");
    return dataConvertida;
  }
  export function converteData2 (data: string){
    const dataConvertida = moment(data).format("DD/MM/YYYY");
    return dataConvertida;
  }
  export function reconverteData (data: string){
    const dataConvertida = `${data.slice(-4)}-${data.slice(3,5)}-${data.slice(0,2)}`
    return dataConvertida;
  }      

  export function validarData(data: any) {
    var date = moment( data );
    if ( !date.isValid() ) {
      console.log('Invalide date', data)
      return 'Invalid date';
    }

    return false;
    
    // const dateFormatInput = moment(data).format('YYYY-MM-DD');
    // const dateFormatToday = moment().format('YYYY-MM-DD');
    // const dateInput = dateFormatInput.replace(/[^a-zA-Z0-9]/g, '');
    // const dateToday = dateFormatToday.replace(/[^a-zA-Z0-9]/g, '');

    // if(dateInput.length === 8){
    //     if( dateInput.slice(4,6) <= dateToday.slice(4,6)){
    //       if(dateInput.slice(6,8) <= '00' || dateInput.slice(6,8) > '31' || dateInput.slice(6,8) < dateToday.slice(6,8)){
    //         return 'Invalid date';
    //       }
    //     }
    //     if(dateInput.slice(4,6) <= '00' || dateInput.slice(4,6) > '12' || dateInput.slice(4,6) < dateToday.slice(4,6)){
    //         return 'Invalid date';
    //     }
    //     if(dateInput.slice(0,4) < dateToday.slice(0,4)){
    //         return 'Invalid date';
    //     }
    // }else{
    //     return 'Invalid date';
    // }
    // return false;
  }

  export function handleDownload(data: any, name: any, extension: any) {
    let filename = `${name.replaceAll(' ', '_')}.${extension}`;
    filename = decodeURI(filename);
    const url = window.URL.createObjectURL(new Blob([data.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }

  export function trataRespota (type: string, data: any){
    if (type === 'error') {
      if (data.response.status === 422) {
        if(data.response.data.errors) {
          let errorValue = data.response.data.errors[Object.keys(data.response.data.errors)[0]][0];
          toast.error(errorValue);
        } else if (data.response.data.message) {
          toast.error(data.response.data.message);
        } else {
          console.log(data)
        }
      } else {
        toast.error(data.response.data.message);
      }      
    }
  }

  export function copyText(text: any, intl: any) {
    
    try {

      navigator.clipboard.writeText(text);

      // const el = document.createElement('textarea');
      // el.value = text;
      // document.body.appendChild(el);
      // el.select();
      // document.execCommand('copy');
      // document.body.removeChild(el);

      // console.log('copied...')

      if (intl) {
        toast.success(`${intl.formatMessage({id: 'Copied'})}`);
      } else {
        toast.success('Copied');
      }
      
    } catch (err) {
      // console.log('Oops, unable to copy');
      toast.error('Oops, unable to copy');
    }
  }

  export function limitString (string:any, count:any=10){ 
    if (string) {
      return string.substring(0, count) + (string.length >=count ? '...' : '')
    }
    return string
  }