import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../../helpers';
import { useIntl } from 'react-intl'
import { defaultPaginationList, getTreinamentoUsuario } from '../../../../../../app/modules/helpers/Api';
import { toast } from 'react-toastify';
import { Box, Modal } from '@mui/material';
import { converteData } from '../../../../../../app/modules/helpers/helpers';
import moment from 'moment';
type Props = {
	data: any;
}
let widthVar = 550;

if (window.screen.width < 600) {
	widthVar = 500;
	if (window.screen.width < 500) {
		widthVar = 350;
		if (window.screen.width < 400) {
			widthVar = 300;
		}
	}
	if (window.screen.width < 300) {
		widthVar = 250;
	}

}
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: widthVar,
	maxWidth: 857,
	maxHeight: 500,
	bgcolor: 'background.paper',
	border: 'none',
	borderRadius: 2,
	boxShadow: 24,
	outline: 'none'
};

let searchData = {
	page: 1,
	perpage: 1000,

}
let searchData2 = {
	page: 1,
	perpage: 1000,
	user_id: '',
	chapter_id: ''
}
const ProfileDetail: React.FC<Props> = ({ ...props }) => {
	const intl = useIntl();
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false)
	const [data, setData] = useState<any>();
	const [dataModule, setDataModule] = useState<any>();
	const [openModal, setOpenModal] = useState(false);
	const [loadingScreen, setLoadingScreen] = useState(false);
	const [idChapter, setIdChapter] = useState<any>();
	const [indexChapter, setIndexChapter] = useState<any>()
	const [dataTreinamento, setDataTreinamento] = useState<any>()


	useEffect(() => {
		handleGetList(null)
		getDataUser(props.data.id ? props.data.id : 0)
	}, [props.data.id])

	let now: any = moment(new Date());
	let past: any = dataTreinamento ? moment(dataTreinamento.created_at) : ''
	let duration: any = Math.floor(moment.duration(now.diff(past)).asDays())


	function getDataUser(id: any) {
		if (id !== 0) {
			const get = getTreinamentoUsuario(id)
			get.then((res) => {
				setDataTreinamento(res.data.data);

			}).catch((error) => {
				// toast.error(error.response.data.message)
			})
				.finally(() => [
					setLoading(false),
				]);
		}
	}

	function handleGetList(data: null) {
		setLoading(true);
		const get = defaultPaginationList('chapters', searchData)
		get.then((res) => {
			setData(res.data.data);

		}).catch((error) => {
			toast.error(error.response.data.message)
		})
			.finally(() => [
				setLoading(false),
			]);
	}

	function getModuleById(data: null) {
		searchData2.chapter_id = idChapter
		searchData2.user_id = props.data.id
		setLoading2(true);
		const get = defaultPaginationList('moduleschapters', searchData2)
		get.then((res) => {
			setDataModule(res.data.data);
			if (res.data.status === 'success') {
				setOpenModal(true)
			}
		}).catch((error) => {
			toast.error(error.response.data.message)
		})
			.finally(() => [
				setLoading2(false),
			]);
	}

	let colorRightquestion = dataModule?.data[0]?.user_points_tutorials?.length;

	return (
		<>
			<div className="card card-custom card-flush mb-5">

				<div className="card-header m-0">
					<h3 className="card-title fs-2 fw-bolder m-0 p-0">{intl.formatMessage({ id: 'Details' })}</h3>
				</div>
				<div className="card-body m-0 pt-0 w-100">

					<div className='w-100 border-bottom-dashed border-1 border-gray-400 py-3'>
						<div className='w-100 d-flex flex-row align-items-center'>
							{
								props.data.email_verified_at ?
									<KTSVG
										path='/media/icons/duotune/general/gen037.svg'
										className='svg-icon-2x svg-icon-success'
									/>
									:
									<KTSVG
										path='/media/icons/duotune/general/gen034.svg'
										className='svg-icon-2x svg-icon-danger'
									/>
							}

							<div className='d-flex flex-column mx-3'>
								<span className='text-gray-700 fw-bold fs-6'>{intl.formatMessage({ id: 'Verified E-mail' })}</span>
								<span className='text-gray-600 fw-bold fs-7'>{props.data.email_verified_at ? 'Verified' : 'Not verified'}</span>
							</div>
						</div>
					</div>
					<div className='w-100 pt-3'>
						<div className='w-100 d-flex flex-row align-items-center'>
							{
								props.data.use_terms ?
									<KTSVG
										path='/media/icons/duotune/general/gen037.svg'
										className='svg-icon-2x svg-icon-success'
									/>
									:
									<KTSVG
										path='/media/icons/duotune/general/gen034.svg'
										className='svg-icon-2x svg-icon-danger'
									/>
							}

							<div className='d-flex flex-column mx-3'>
								<span className='text-gray-700 fw-bold fs-6'>{intl.formatMessage({ id: 'Use terms' })}</span>
								<span className='text-gray-600 fw-bold fs-7'>{props.data.use_terms ? `${intl.formatMessage({ id: 'Accepted' })}` : `${intl.formatMessage({ id: 'Not Accepted' })}`}</span>
							</div>
						</div>
					</div>

				</div>
			</div>


			<div className="card card-custom card-flush mb-5">

				<div className="card-header m-0 ">
					<h3 className="card-title fs-2 fw-bolder m-0 p-0">{intl.formatMessage({ id: 'Tutorial' })}</h3>
				</div>
				
				{ 
					dataTreinamento ?
				
						<div>
							<div className='container fw-bolder text-gray-600 fs-4'>
								<p>Iniciado: {dataTreinamento ? converteData(dataTreinamento.created_at) : '?'}</p>
								<p>Duração: {duration ? duration : '?'} {intl.formatMessage({ id: 'Dias' })}</p>
								<p>Finalizado: ?</p>
							</div>
							<div className="card-body m-0 pt-0 w-100" style={{ maxHeight: 300, overflowY: 'auto' }}>

								{
									data?.data.map((item: any, index: any) =>
										<div
											className={`py-3`}
										>
											<button
												onMouseEnter={() => setIdChapter(item.id)}
												onClick={() => [getModuleById(null), setIndexChapter(index)]}
												disabled={loading2}
												className="w-100 text-dark text-hover-primary bg-white bg-hover-light-primary border-1 border-dashed border-primary  d-flex flex-column flex-sm-row p-4 mb-2"

											>
												<div className="d-flex flex-column text-center w-100 pe-0 pe-sm-10">

													{!(loading2 && indexChapter === index) && <span className='fs-4 fw-bold'>{item.titulo}</span>}
													{(loading2 && indexChapter === index) && (
														<span className='indicator-progress' style={{ display: 'block' }}>
															<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
														</span>
													)}

												</div>
											</button>
										</div>
									)
								}

							</div>
						</div>

					:

					<div>
						<div className='container fw-bolder text-gray-600 fs-4 mb-4'>
							<small>Não encontrado ou não iniciado!</small>
						</div>
					</div>
				}

			</div>
			<Modal
				open={openModal}
				onClose={() => { }}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div className='w-100 px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
						<p className='fw-bolder text-gray-600 fs-4'>{dataModule?.data[0].title}</p>
						<div
							className="btn btn-icon btn-sm btn-active-light-primary"
							onClick={() => [setOpenModal(false)]}
						>
							<KTSVG
								path="/media/icons/duotune/arrows/arr061.svg"
								className="svg-icon svg-icon-2x"
							/>
						</div>

					</div>

					<div className='container mt-5 ' >

						{
							dataModule?.data[0]?.user_points_tutorials.length === 0 ?
								<>
									<div className='aling-items-center text-center my-6'>
										<div className='fs-5 text-gray-600 '>{intl.formatMessage({ id: 'There are no registered yet' })}</div>
									</div>
								</>
								:
								<>
									{
										<div className='p-5' style={{ maxHeight: 340, overflowY: 'auto', marginBottom: 50 }}>
											{
												dataModule?.data[0]?.user_points_tutorials?.map((item: any, index: any) =>
													<div key={index} className="d-flex align-items-center">
														<div
															id="answer_choose"
															className={`${colorRightquestion - 1 === index ? 'color_green w-90' : 'color_red w-90'}`}
														>
															<p>{item.tutorial_answer}</p><span> {converteData(item.created_at)}</span>
														</div>

													</div>
												)
											}

										</div>

									}
								</>
						}

					</div>

				</Box>

			</Modal>

		</>
	)
}
export default ProfileDetail;