import React, { FC, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { trataRespota } from '../../../../../../app/modules/helpers/helpers'
import { createGaps, getCompaniesByUser } from '../../../../../../app/modules/helpers/Api'
import { toast } from 'react-toastify'

type Props = {
  onCancelGap: (bol: any) => void;
  onGetList: () => void;
  prescription: Number
  lang: String
}

const QuestionButton: React.FC<Props> = ({ ...props }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      gap: '',
      risk: '',
      classification: '',
      degree: '',
      lang: props.lang ? props.lang : '',
      prescription: ''
    },
    validationSchema: Yup.object().shape({
      gap: Yup.string().required(`${intl.formatMessage({ id: 'Gap' })} ${intl.formatMessage({ id: 'is required' })}`),
      risk: Yup.string().required(`${intl.formatMessage({ id: 'Risk' })} ${intl.formatMessage({ id: 'is required' })}`),
      classification: Yup.string().required(`${intl.formatMessage({ id: 'Classification' })} ${intl.formatMessage({ id: 'is required' })}`),
      degree: Yup.string().required(`${intl.formatMessage({ id: 'Degree' })} ${intl.formatMessage({ id: 'is required' })}`),
      lang: Yup.string().required(`${intl.formatMessage({ id: 'Language' })} ${intl.formatMessage({ id: 'is required' })}`),
    }),
    onSubmit: (values) => {
      console.clear()
      handleSave(values);
    },
  })

  function handleSave (values: any){
    setLoading(true);
    const data = {
      gap: values.gap,
      risk: values.risk,
      classification: values.classification,
      degree: values.degree,
      lang: values.lang,
      form_id: props.prescription
    }
      
    const create = createGaps(data);
    create.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        props.onGetList()
        handleCancel()
      } else if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      trataRespota('error', error);
    }).finally(() => {
      setLoading(false);
    });
  }

  function handleCancel() {
    props.onCancelGap(false)
  }

  return (
    <div className='p-5'>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        {/* <div className='mb-2 fv-row border-top' data-kt-password-meter='true'>
        <div className='mb-1 mt-4'>
          <label className='form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'Company' })}</label>

          <div className='position-relative mb-3'>
            <div className='d-flex justify-content-between w-100'>
              <select {...formik.getFieldProps('company_id')} disabled={loadingCompanies}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.company_id && formik.errors.company_id },
                  {
                    'is-valid': formik.touched.company_id && !formik.errors.company_id,
                  }
                )}
              >
                <option value='' disabled>{loadingCompanies ? 'Loading...' : intl.formatMessage({ id: 'Select' })}</option>
                {
                  companies.map((companiesList: any) =>
                    <option key={companiesList.value} value={companiesList.value}>{companiesList.text}</option>
                  )

                }
              </select>
            </div>
            {formik.touched.company_id && formik.errors.company_id && (
              <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.company_id}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div> */}


        <div className='mb-2 fv-row ' data-kt-password-meter='true'>
          <div className='mb-1 mt-4'>
            <label className='form-label required fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Gap' })}</label>
            <div className='position-relative mb-3'>
              <div className='d-flex justify-content-between w-100'>
                <input
                  type='text'
                  placeholder={intl.formatMessage({ id: 'Gap' })}
                  autoComplete='off'
                  {...formik.getFieldProps('gap')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid w-100 me-1',
                    {
                      'is-invalid': formik.touched.gap && formik.errors.gap,
                    },
                    {
                      'is-valid': formik.touched.gap && !formik.errors.gap,
                    }
                  )}
                />
              </div>
              {formik.touched.gap && formik.errors.gap && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.gap}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='mb-2 fv-row' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label required fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Risk' })}</label>
            <div className='position-relative mb-3'>
              <div className='d-flex justify-content-between w-100'>
                <input
                  type='text'
                  placeholder={intl.formatMessage({ id: 'Risk' })}
                  autoComplete='off'
                  {...formik.getFieldProps('risk')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid w-100 me-1',
                    {
                      'is-invalid': formik.touched.risk && formik.errors.risk,
                    },
                    {
                      'is-valid': formik.touched.risk && !formik.errors.risk,
                    }
                  )}
                />
              </div>
              {formik.touched.risk && formik.errors.risk && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.risk}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='mb-2 fv-row' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label required fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Classification' })}</label>
            <div className='position-relative mb-3'>
              <div className='d-flex justify-content-between w-100'>
                <select className='form-select form-select-solid form-select-lg fw-bold fs-4'
                  {...formik.getFieldProps('classification')}
                >
                  <option value="">{intl.formatMessage({ id: 'Select' })}</option>
                  <option value="1">{intl.formatMessage({ id: 'Low' })}</option>
                  <option value="2">{intl.formatMessage({ id: 'Medium' })}</option>
                  <option value="3">{intl.formatMessage({ id: 'High' })}</option>
                  <option value="4">{intl.formatMessage({ id: 'Most High' })}</option>
                </select>
              </div>
              {formik.touched.classification && formik.errors.classification && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.classification}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='mb-2 fv-row' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label required fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Degree' })}</label>
            <div className='position-relative mb-3'>
              <div className='d-flex justify-content-between w-100'>
                <input
                  type='number'
                  min={1}
                  max={100}
                  placeholder={intl.formatMessage({ id: 'Degree' })}
                  autoComplete='off'
                  {...formik.getFieldProps('degree')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid w-100 me-1',
                    {
                      'is-invalid': formik.touched.degree && formik.errors.degree,
                    },
                    {
                      'is-valid': formik.touched.degree && !formik.errors.degree,
                    }
                  )}
                />
              </div>
              {formik.touched.degree && formik.errors.degree && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.degree}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        { !props.lang ? 
        <div className='mb-2 fv-row' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label required fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Language' })}</label>
            <div className='position-relative mb-3'>
              <div className='d-flex justify-content-between w-100'>
                <select
                  {...formik.getFieldProps('lang')}
                  className={clsx(
                    'form-select form-control form-control-solid fs-6',
                    {
                      'is-invalid': formik.touched.lang && formik.errors.lang,
                    },
                    {
                      'is-valid': formik.touched.lang && !formik.errors.lang,
                    }
                  )}
                >
                  <option value=''>{intl.formatMessage({ id: 'select' })}...</option>
                  {/* <option value='todos'>{intl.formatMessage({id: 'All'})}</option> */}
                  <option value='en'>{intl.formatMessage({ id: 'English' })}</option>
                  <option value='pt'>{intl.formatMessage({ id: 'Portuguese' })}</option>

                </select>
              </div>
              {formik.touched.degree && formik.errors.degree && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.degree}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        :
        null
        }

        {/* <div className='mb-2 fv-row mt-6' data-kt-password-meter='true'>
        <div className='mb-1 mt-4'>
          <div className='form-check form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('default')}
            />
            <label
              className='form-check-label fw-bold text-gray-700 fs-6'
              htmlFor='kt_login_toc_agree'
            >
              Tornar padrão

            </label>
            {formik.touched.default && formik.errors.default && (
              <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.default}</span>
                </div>
              </div>
            )}
          </div>

        </div>
      </div> */}

        <div className='d-flex flex-row w-100 justify-content-end '>

          <button type='button' onClick={() => handleCancel()} disabled={loading} className='btn btn-sm btn-primary text-white mt-5 me-2'>{intl.formatMessage({ id: 'back' })}</button>
          <button type='submit' className='btn btn-sm btn-primary text-white mt-5 me-2' disabled={loading}>
            {!loading && intl.formatMessage({ id: 'Save' })}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                {intl.formatMessage({ id: 'Processing' })}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

      </form>
    </div>
  );
}
export default QuestionButton;