/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import { KTSVG } from '../../../_metronic/helpers'
import {UserModel} from '../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import { Link } from 'react-router-dom'
import TutoriaisFeed from '../../../_metronic/partials/widgets/pages/Tutoriais/TutoriaisFeed'

const TutoriaisPage: FC = () => {
  const intl = useIntl();
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      
      {
        user.roles === 'free'?
        <Link to='/crafted/pages/commum/store/products' className="alert alert-info d-flex align-items-center p-5 mb-10">
          <KTSVG path="/media/icons/duotune/general/gen044.svg" className="svg-icon-info svg-icon-2hx me-5" />        
          <div className="d-flex flex-column">
            <h5 className="mb-1">{intl.formatMessage({id: 'Attention'})}</h5>
            <span className='fs-5'>{intl.formatMessage({id: 'You are using a free account. Click here and check out our plans'})}</span>
          </div>
        </Link>
        :
        <></>
      }
      <TutoriaisFeed/>
    </>
  )
}

export {TutoriaisPage}
