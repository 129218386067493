import React from 'react';
import { useIntl } from 'react-intl'
import { copyText } from '../../../../../../app/modules/helpers/helpers';

interface IReportDegreeRiskProps {
	dataReport: any,
}

const ReportDegreeRisk: React.FC<IReportDegreeRiskProps> = ({...props}) => {
	const intl = useIntl();   

  return(
    <>
			{
				props.dataReport.gap_degree.length !== 0 && props.dataReport.gap_degree !== null?
				<div 
				className='w-100 my-5 d-flex flex-column rounded border-dashed border-1 p-3 border-primary'
				// style={{maxHeight: 400, overflowY: 'scroll'}}
				>
					<p className='text-gray-600 text-center fw-bold fs-3'>{intl.formatMessage({id: 'Risk Degree'})} 1 x {intl.formatMessage({id: 'Gaps'})}</p>
					{
						props.dataReport.gap_degree.map((item: any, index: any)=>
							<div key={index} className='d-flex flex-row'>
								<div className='timeline-label'>
									<div className='timeline-item'>
										<div className='timeline-label fw-bolder text-gray-800 fs-5'>{item.degree}</div>
										<div className='timeline-badge'>
											<i className={`fa fa-genderless fs-1
												${item.degree === 5? 'text-danger' 
													: item.degree === 4? 'text-warning' 
													: item.degree === 3? 'text-primary'
													: item.degree === 2? 'text-info'
													: 'text-light'
												}`}></i>
										</div>
										<div className='fw-bold text-gray-600 ps-3' style={{textAlign: 'left'}}>
											<span>{item.gap}</span>
											<ul>
												{
													item.actions2?.map((item: any, index: any)=>
													<li key={index} className=' reportDegreeeAction' onClick={() => copyText(item.action_uuid, intl)}>
															{item.title}
													</li>
													)
												}
											</ul>
										</div>
										
									</div>
									
								</div>
							</div>
						)
					}
				</div>
				:
				<></>
			}
		</>
  )
}
export default ReportDegreeRisk;