/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { RootState } from '../../setup'
import { MasterInit } from '../../_metronic/layout/MasterInit'
import { ResetEmail } from '../modules/auth/components/ResetEmail'
import RegistrationToken from '../../app/modules/auth/components/RegistrationToken'

import ManagementHolderRequest from '../pages/public/ManagementHolderRequest'
import ManagementPreferenceConsentResponse from '../pages/public/ManagementPreferenceConsentResponse'

import RiskAnalysisStep2 from '../pages/public/RiskAnalysis/Step2'
import ManagementHolderResponse from '../pages/public/ManagementHolderResponse'
import ManagementHolderSelect from '../pages/public/ManagementHolderSelect'


const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)

  return (
    <>
      <Switch>

        <Route path='/management/holder/request/:lang/:token' component={ManagementHolderRequest} />
        <Route path='/acompaiment' component={ManagementHolderSelect} />
        <Route path='/management/holder/response/:lang/:token' component={ManagementHolderResponse} />
        <Route path='/management/preference_consent/:lang/:token/:type/response' component={ManagementPreferenceConsentResponse} />
        <Route path='/register/:role/:token' component={RegistrationToken} />

        <Route path='/risk_analysis/:token/analysis' component={RiskAnalysisStep2} />



        {/* <Route path='/risk_analysis/report/preview' component={RiskAnalysReportPreview} />  */}



        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from='/auth' to='/' />
        )}

        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />
        <Route path='/reset-email/:token' component={ResetEmail} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/auth/login' />
        ) : (
          <>
            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          </>
        )}
      </Switch>
      <MasterInit />
    </>
  )
}

export { Routes }
