import React, { useState } from 'react';
import { KTSVG } from '../../../../../helpers';
import ModalMessage from '../../../modals/modalMessage';
import QuestionAddAnswer from './questionAddAnswer';
import { useIntl } from 'react-intl'
import { removeFormQuestion, removeAnswerQuestion } from '../../../../../../app/modules/helpers/Api';
import { toast } from 'react-toastify';

type Props = {
	data: any;
	delete: (index: any) => void;
	deleteAnswer: (index: any, index_answer: any) => void;
	addAnswer: (index: any, answer: any) => void;
	onGetFormData: () => void;
	onModalAddQuestion: (bol:any) => void;
	id: any;
	model: any;
	language: String
}

const QuestionList: React.FC<Props> = ({ ...props }) => {

	const [openModalDelete, setOpenModalDelete] = useState(false);
	const [openModalDeleteAnswer, setOpenModalDeleteAnswer] = useState(false);
	const [openModalAnswer, setOpenModalAnswer] = useState(false);
	const [index, setIndex] = useState<number>();
	// eslint-disable-next-line
	const [indexAnswer, setIndexAnswer] = useState<number>();
	// eslint-disable-next-line
	const [loading, setLoading] = useState(false);
	const intl = useIntl();  

	const [questionAnswer, setQuestionAnswer] = useState<string>();

	function handleAnswer(answer: any) {
		props.addAnswer(index, answer);
	}

	function handleFormQuestionRemove(index:any) {
		
		setLoading(true)

		setOpenModalDelete(false)

		const get = removeFormQuestion( props.data[index].id );
		get.then((res)=>{

			if (res.data.status === 'success') {
				props.onGetFormData();
				toast.success(res.data.message);
			} else {
				toast.error(res.data.message);
			}

		}).catch((error)=>{
			if(error.response){
				toast.error(error.response.message);
			}
		}).finally(()=>{ 
			setLoading(false)
	  });

	}

	function handleAnswerQuestionRemove() {

		setLoading(true)

		setOpenModalDeleteAnswer(false);

		const get = removeAnswerQuestion( questionAnswer );
		get.then((res)=>{

			if (res.data.status === 'success') {
				props.onGetFormData();
				toast.success(res.data.message);
			} else {
				toast.error(res.data.message);
			}

		}).catch((error)=>{
			if(error.response){
				toast.error(error.response.message);
			}
		}).finally(()=>{ 
			setLoading(false)
	  });
	}






	return (
		<>
			<ModalMessage
				message={`Deseja remover a pergunta Nº ${index?index+1:'0000'} desse formulário?`}
				title={intl.formatMessage({id: 'Attention'})}
				showModal={openModalDelete}
				onClose={() => setOpenModalDelete(false)}
				onAction={() => [ handleFormQuestionRemove(index) ]}
			/>
			<ModalMessage
				message={intl.formatMessage({id: 'Are you sure you want to delete this item?'})}
				title={intl.formatMessage({id: 'Attention'})}
				showModal={openModalDeleteAnswer}
				onClose={() => setOpenModalDeleteAnswer(false)}
				onAction={() => [

					handleAnswerQuestionRemove(),

					// props.deleteAnswer(index, indexAnswer),
					
					// setIndex(-1)
				]}
			/>

			<QuestionAddAnswer
				question={ props.data.length>0 ? props.data[index?index:0]?.id : '' }
				title={(index?index+1: 0) +' - '+intl.formatMessage({id: 'Answer'})}				
				id={props.id}
				model={props.model}
				language={props.language}
				showModal={openModalAnswer}
				onClose={() => setOpenModalAnswer(false)}
				onAction={handleAnswer}
				onGetForm={props.onGetFormData}
			/>

			{
				props.data.map((item: any, index: any) =>
					<div className='rounded text-dark text-primary  bg-light-primary border-1 border-dashed border-primary  mb-4'>

						<div className='bg-light-primary p-2'>

							<div className='row d-flex ' >

								<div className='col col-sm-11 d-flex'>
									<span className='bg-white border-dashed me-2 d-flex flex-row w-30px h-30px rounded border-1 border-primary align-items-center justify-content-center'><strong>{index + 1}</strong></span> 
									<span className='mt-2'><strong>{item.question_name}</strong></span>
								</div>

								<div className='col d-flex flex-row  justify-content-end'>
									<button
										className='btn btn-icon bg-hover-secondary btn-sm '
										onClick={() => [setIndex(index), setOpenModalDelete(true)]}
									>
										<KTSVG
											path='/media/icons/duotune/general/gen034.svg'
											className='svg-icon-2x svg-icon-danger'
										/>

									</button>
								</div>

							</div>

							{/* <div className='col d-flex' >
								<span className='bg-light-success border-dashed me-2 d-flex flex-row w-30px h-30px rounded align-items-center justify-content-center'>{index + 1}</span> 
								<span>
									{item.question_name}
								</span>

								

							</div> */}

							<hr className='bg-light-primary border-1 border-dashed border-primary mt-1'/>

						</div>

						<div className='row mb-5' >
							
							<div className='px-12'>
								<p className='px-3 mt-4'>

									{
										item.type === 'single' ? <span>* {intl.formatMessage({id: 'Selection of'})}<strong> {intl.formatMessage({id: 'an'})} </strong>{intl.formatMessage({id: 'item is'})} </span>:
											item.type === 'mult' ? <span>* {intl.formatMessage({id: 'Selection of'})}<strong> {intl.formatMessage({id: 'one or more'})} </strong>{intl.formatMessage({id: 'items is'})} </span> :
												item.type === 'input' ? <span>* {intl.formatMessage({id: 'Text input'})} </span> :
													`* ${intl.formatMessage({id: 'Unknown item type'})}`

									}

									<strong>{item.required ? `${intl.formatMessage({id: 'mandatory'})}` : `${intl.formatMessage({id: 'optional'})}`}</strong>

									{/* // item.type=='single'?'um item é ': 'um ou mais itens é '} {item.required? 'Obrigatório.' : 'opcional.'}</span> */}
								</p>

								<p className='px-3 mt-4'>
									{
										item.is_key===1 || item.is_key===true? <span>*  <strong>{intl.formatMessage({id: 'key question'})}</strong>.</span> : null 
									}
								</p>

								<ul style={{listStyleType: 'none', padding: "0"}}>

									{
										item.answer_list.length !== 0 ?
											<>
												{
													item.answer_list.map((item_b: any, index_b: any) =>

														<li>

															<button
																className='btn btn-icon bg-hover-secondary btn-sm'
																onClick={() => [setIndex(index), setIndexAnswer(index_b), setQuestionAnswer(item_b.id), setOpenModalDeleteAnswer(true)]}
																disabled={item.type==='input'}
															>
																<KTSVG
																	path='/media/icons/duotune/general/gen034.svg'
																	className='svg-icon-2x svg-icon-danger'
																/>
															</button>

															{item_b.answer_name}.
																{props.model === 2 || props.model === 1 ? <span> (<strong>{intl.formatMessage({id: 'Points'})}: {item_b.gap_list ? item_b.gap_list.points : item_b.points ? item_b.points : <></>}</strong>) </span> : ''} 
																({item_b.next_question === 0 ? <strong>{intl.formatMessage({id: 'End Form'})}</strong>: <strong>{intl.formatMessage({id: 'Next'})}: {item_b.next_question + (index+1)}</strong> })
															
															<div style={{marginLeft: '32px'}} className=' mb-2'>
																

																{item_b.gaps && item_b.gaps.length > 0 ? 
																											
																	<hr className='mt-0 mb-0' />
														
																: ''
																}

																{
																	item_b.gaps && item_b.gaps.length > 0 ? item_b.gaps.map((gap_item: any, gap_index: any) =>

																	<li>Gap: {gap_item.degree}pts - {gap_item.gap}</li>

																	)
																	: ''
																}
															

															</div>

														</li>

													)
												}
											</>
											:
											null

									}

									{
										item.type !== 'input' ? <li>

											{/* <button
												id='btn_form_add_question'
												className='btn btn-icon  btn-sm w-30'
												onClick={() => [setIndex(index), setOpenModalAnswer(true)]}
											>
												<KTSVG
													path='/media/icons/duotune/general/gen035.svg'
													className='svg-icon-2x svg-icon-success'
												/>

											</button> */}

											{/* <label htmlFor="btn_form_add_question" className='cursor-pointer'>{intl.formatMessage({id: 'Add answer'})}</label> */}

											<label className='cursor-pointer' onClick={() => [setIndex(index), setOpenModalAnswer(true)]}>
												<KTSVG
													path='/media/icons/duotune/general/gen035.svg'
													className='svg-icon-2x svg-icon-success mx-2'
												/>
												{intl.formatMessage({id: 'Add answer'})}
											</label>


											
										</li>
											:
											null
									}


								</ul>

							</div>

						</div>

					</div>

				)

			}

			<div onClick={()=>props.onModalAddQuestion(true)} className='rounded text-primary text-hover-dark bg-white bg-hover-primary border-1 border-dashed border-primary  '>
				<div className='p-2'>
					<div className='row d-flex'>
						<div className='col col-sm-11 d-flex'>
							<span className='bg-white border-dashed me-2 d-flex flex-row w-30px h-30px rounded border-1 border-primary align-items-center justify-content-center'><strong>{props.data.length+1}</strong></span> 
							<span className='mt-2'><strong>Adicionar Pergunta N°{props.data.length+1}</strong></span>
						</div>
					</div>
				</div>
			</div>

		</>
	)
}
export default QuestionList;