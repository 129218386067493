/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { changeStatus, createCompanies, delCompanies, defaultPaginationList } from '../../../../app/modules/helpers/Api'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { countries } from '../../../../app/modules/helpers/country'
import { maskCnpj } from '../../../../app/modules/helpers/helpers'
import clsx from 'clsx'
import { toast } from 'react-toastify';
import { trataRespota } from '../../../../app/modules/helpers/helpers'
import Pagination from '../../../helpers/components/CustomPagination/CustomPagination'
import { useIntl } from 'react-intl'
import { Box, Modal } from '@mui/material'
import { Load } from '../spinner/Spinner'

let searchData = {
  search_value: '',
  status: '',
  page: 1,
  perpage: 9
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  minWidth: 375,
  maxWidth: 850,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 5,
  boxShadow: 24,
};

type Props = {
  change: (id: number, change: boolean) => void;
  create: (change: boolean, id?: any, name?: string) => void;
}

const TablesCompanies: React.FC<Props> = ({...props}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const intl = useIntl();   
  const [loading, setLoading] = useState(false);
  const [isloading, setisLoading] = useState(false);
  const [companiesList, setCompaniesList] = useState<any>([]);
  const [idCompany, setIdCompany] = useState<number>(0);
  const [document, setDocument] = useState('');
  const [errorDocument, setErrorDocument] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [searchValueFilter, setSearchValueFilter] = useState<any>();
  const [statusFilter, setStatusFilter] = useState<any>();
  const [isloading3, setisLoading3] = useState(false);
  const [status, setStatus] = useState('');

  const schema = Yup.object().shape({
    company: Yup.string().required(`${intl.formatMessage({id: 'Company'})} ${intl.formatMessage({id: 'is required'})}`),
    corporate_name: Yup.string().required(`${intl.formatMessage({id: 'Corporate name'})} ${intl.formatMessage({id: 'is required'})}`),
    fantasy_name: Yup.string().required(`${intl.formatMessage({id: 'Fantasy name'})} ${intl.formatMessage({id: 'is required'})}` ),
  })

  useEffect(()=>{
    handleGetList(null)
  },[isloading]);

  function handleFilter() {
    searchData.page = 1;
    searchData.search_value = searchValueFilter ? searchValueFilter : '';
    searchData.status = statusFilter ? statusFilter : '';
    // searchData.model = 'Company';
    // searchData.model_id = props.company_id;

    handleGetList(null);
  }

  const formik = useFormik({
    initialValues: {
      company: '',
      corporate_name: '',
      fantasy_name: '',
      zip_code: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      phone: '',
      country: '',
      street: '',
      whatsapp_number: '',
      state_reg: '',
      municipal_reg: '',
      instagram: '',
      facebook: '',
      logo_url: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setisLoading3(true);
      var error = false;
      setTimeout(() => {
        
        if(document === ''){setErrorDocument(`${intl.formatMessage({id: 'Document'})} ${intl.formatMessage({id: 'is required'})}`); error = true}
        if(!error){store(values)}
        setisLoading3(false);
        setLoading(false)
      }, 1000)
    },
  })

  function handleGetList(data:null) {
    setLoading(true);
    const get = defaultPaginationList('companies', searchData)
    get.then((res)=>{
      if (res.data.status=='success') {
        setCompaniesList(res.data.data);
      } else {
        setCompaniesList([]);
      }
    }).catch((error)=>{
      toast.error(error.response.data.message)
    })
    .finally(()=>[setLoading(false), setisLoading]);
  }

  function store (value: any){
    const company = {
      company: value.company,
      corporate_name: value.corporate_name,
      fantasy_name: value.fantasy_name,
      document: document,
      zip_code: value.zip_code,
      number: value.number,
      complement: value.complement,
      neighborhood: value.neighborhood,
      city: value.city,
      uf: value.state,
      tel: value.phone,
      country: value.country,
      street: value.street,
      whatsapp_number: value.whatsapp_number,
      reg_state: value.state_reg,
      reg_municipal: value.municipal_reg,
      instagram: value.instagram,
      facebook: value.facebook,
      logo_url: value.logo_url,
      observation: value.observation,
    }
    const save = createCompanies(company);
    save.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        handleGetList(null)
        setOpenModal(false)
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      trataRespota('error', error); 
      //toast.error(error.response.data.message);
    }).finally(()=>[setisLoading(true), setLoading(false)])
  }

  function handleDelete(id: number){
    const del = delCompanies(id);
    del.then((res)=>{
        if(res.data.status === 'success'){
          toast.success(res.data.message);
        }
        if(res.data.status === 'error'){
          toast.error(res.data.message);
        }
      }).catch((error)=>{
        toast.error(error.response.data.message);
      }).finally(()=>[setisLoading(true), setLoading(false)]);
  }
  
  function addMask(e: any){
    setDocument(maskCnpj(e));
  }

  function upStatus(){
    const data = {
      company: idCompany,
      status: status
    }
    const save = changeStatus(data);
    save.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      toast.error(error.response.data.message);
    });
  }

  return (
    <>
      {
        loading?
        <Load/>
        :
        <div>

          <h3 className='card-title align-items-center '>
            <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: 'Companies'})}</span>
            <span className='text-gray-500 fw-bold bold fs-7 px-3'>{intl.formatMessage({id: 'All Companies'})}</span>
          </h3>

          <div   className='card mt-4'>

          <div className=" row d-flex p-6" >

            <div className="col-md-3" >

              <input
                type="text"
                className="form-control form-control-solid fs-6 rounded-3"
                placeholder={intl.formatMessage({ id: 'Search' })}
                name="search_value"
                autoComplete='off'
                value={searchValueFilter}
                onChange={(e) => [setSearchValueFilter(e.target.value)]}
              />

            </div>

            <div
              className='col-md-3'
            >
              <select className='form-select form-control form-control-solid fs-6'
                name="status"
                value={statusFilter}
                onChange={(e) => [setStatusFilter(e.target.value)]}

              >
                <option value=''>{intl.formatMessage({id: 'All'})}</option>
                <option value='pending'>{intl.formatMessage({id: 'Pending'})}</option>
                <option value='active'>{intl.formatMessage({id: 'Active'})}</option>
                <option value='inactive'>{intl.formatMessage({id: 'Inactive'})}</option>
                <option value='expired'>{intl.formatMessage({id: 'Expired'})}</option>
              </select>
            </div>

            <div className='col-12 col-sm-12 col-md-6 d-flex justify-content-end' >


              <div className='row  d-flex'>

                <div className='col-sm-12 d-flex'>

                  <button type='button' className='btn btn-primary text-dark me-2' disabled={loading} onClick={() => handleFilter()}>
                    {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Search' })}</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>

                  {
                user.roles !== 'admin' 
                && user.roles !== 'super-admin' && user.roles !== 'free' && user.roles !== 'manager'
                ?
                <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title={user.package === null ? `${intl.formatMessage({id: 'You need an active plan'})}`  : `${intl.formatMessage({id: 'Create a company'})}`}
              >
                <button
                  className='btn  btn-dark'
                  disabled={user.package !== null? false : true}
                  title={user.package === null ? `${intl.formatMessage({id: 'You need an active plan'})}`  : `${intl.formatMessage({id: 'Create a company'})}`}
                  onClick={()=>[
                    formik.resetForm(),
                    setDocument(''),
                    setOpenModal(true)
                  ]}
                  // data-bs-toggle='modal'
                  // data-bs-target='#kt_modal_create_company_2'
                >
                  {intl.formatMessage({id: 'Create'})}
                </button>
              </div>
              :
              null
              }


                </div>

              </div>



            </div>

          </div>

            <div className='card-body'>
              <div className='table-responsive p-3'>
                {
                  companiesList.data?.length === 0?
                  <div className='text-center my-4'>
                    <span className='fs-5 text-gray-600'>{intl.formatMessage({id: 'No record found'})}</span>
                  </div>
                  :
                <div className='row'>
                  {companiesList.data?.map((item: any)=>
                  <div 
                    key={item.id} 
                    className='bg-white col-lg-4  mt-2' 
                  >

                    <div className='bg-white rounded-3 border-hover-primary p-4'  style={{position:'relative', border: '4px solid #F5F8FA', height: '227px'}}>


                      <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                        <div className='me-7 mb-4'>

                          <div className='symbol symbol-45px me-5'>
                            {
                              item.logo?
                              <img alt='img logo' src={`data:image/jpeg;base64,${item.logo}`} />
                              :
                              <>
                              {
                                item.logo_url ?
                                <img src={`${item.logo_url}`} alt='' />
                                :
                                <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
                              }
                              </>
                            }

                            {          
                              item.status === 'pending'
                              ? <div role={'button'} title={intl.formatMessage({id: 'pending'})} className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-primary rounded-circle border border-4 border-white h-20px w-20px'></div>
                              : item.status === 'active'
                              ? <div role={'button'} title={intl.formatMessage({id: 'active'})} className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                              : item.status === 'expired' 
                              ? <div role={'button'} title={intl.formatMessage({id: 'expired'})} className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-danger rounded-circle border border-4 border-white h-20px w-20px'></div>
                              : <div role={'button'} title={item.status} className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-light rounded-circle border border-4 border-white h-20px w-20px'></div>
                            }
                          </div>

                        </div>

                        <div className='flex-grow-1'>
                          <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                            <div className='d-flex flex-column'>
                              <div className='d-flex'>
                                <span className='fw-bolder fs-6 text-hover-primary' onClick={()=>props.change(item.id, true)} role={'button'}>
                                  {item.fantasy_name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>



                      </div>

                      <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>

                        <div className='flex-grow-1'>
                          <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                            <div className='d-flex flex-column'>

                              <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>

                              <span className='d-flex align-items-center text-gray-400 me-5 mb-2' title={intl.formatMessage({id: 'Type'})}>
                                <KTSVG
                                  path='/media/icons/duotune/communication/com009.svg'
                                  className='svg-icon-4 me-1'
                                />
                                {
                                  item.document_type === 1?
                                  <>CPF</>
                                  :
                                  <>
                                    {
                                      item.document_type === 2?
                                      <>Matriz</>
                                      :
                                      <>Filial</>
                                    }
                                  </>
                                }
                              </span>

                                <span className='d-flex align-items-center text-gray-400 me-5 mb-2' title={intl.formatMessage({id: 'Document'})}>
                                  <KTSVG
                                    path='/media/icons/duotune/communication/com009.svg'
                                    className='svg-icon-4 me-1'
                                  />
                                  {item.document ? item.document : '- - -'}
                                </span>

                              </div>

                            </div>
                          </div>
                        </div>



                      </div>

                      <div className='p-3' style={{position: 'absolute', bottom: '0', left: '0', right: '0'}}>
                        
                        <div className='row d-flex justify-content-between'  >
                            
                            <div   className='col-6 text-center'>
                                
                              <div  className='fs-7' style={{border: '1px dashed #E4E6EF'}}>
                                <span className='fw-bolder '>{intl.formatMessage({id: 'DPO'})}</span>
                                {
                                  item.data_dpo?
                                  <p className='text-gray-500 fw-bold bold fs-9'>{item.data_dpo.name} {item.data_dpo.last_name.split('',9)}...</p>
                                  :
                                    <p className='text-gray-500 fw-bold bold fs-9'>- - -</p>                              
                                }
                              </div>

                            </div>

                            <div   className='col-6 text-center'>
                                
                              <div  className='fs-7' style={{border: '1px dashed #E4E6EF'}}>
                                <span className='fw-bolder '>{intl.formatMessage({id: 'Manager'})}</span>
                                {
                                  item.data_manager?
                                  <p className='text-gray-500 fw-bold bold fs-9'>{item.data_manager.name} {item.data_manager.last_name.split('',9)}...</p>
                                  :
                                    <p className='text-gray-500 fw-bold bold fs-9'>- - - </p>                              
                                }
                              </div>

                            </div>

                          
                        </div>   

                      </div>

                    </div>                 
                
                  </div>
                  )
                  
                }
                  
                </div>
                
                }
              </div>
             
              <Pagination data={companiesList} onSearch={handleGetList} searchData={searchData}/>

            </div>

          </div>        

        </div>
      }
      <Modal
      open={openModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"

      >
        <Box sx={style}>
       { 
      
       <div
           className="p-5" 
          tabIndex={-1} 
          // id="kt_modal_create_company_2"
         >
					<div 
          className="modal-dialog modal-dialog-centered mw-900px bg-white"
          >
						<div 
            className="modal-content mh-600px"
            >
							<div 
              className="modal-header"
              >
								<h5 className="">{intl.formatMessage({id: 'New Company'})}</h5>
								<div
									className="btn btn-icon btn-sm btn-active-light-primary ms-2"
									// data-bs-dismiss="modal"
									// aria-label="Close"
                  onClick={()=>setOpenModal(false)}
								>
									<KTSVG
										path="/media/icons/duotune/arrows/arr061.svg"
										className="svg-icon svg-icon-2x"
									/>
								</div>
							</div>
							<div className="modal-body w-100 pt-1 " style={{overflow:'auto', overflowX: 'hidden'}}>
								<form onSubmit={formik.handleSubmit} noValidate className='form w-100 p-5'>
									<div className='align-items-center w-100'>
										<div className='col-md-12'>
											<div className='row'>
                        <div className='col-md-6 fv-row'>
													<label className='col-md-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Fantasy name'})}</label>
													<input
														type="text"
														className="form-control form-control-solid fs-5"
														placeholder={`${intl.formatMessage({id: 'Fantasy name'})}`}
														{...formik.getFieldProps('fantasy_name')}
													/>
													{formik.touched.fantasy_name && formik.errors.fantasy_name && (
														<div className='fv-plugins-message-container'>
															<div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.fantasy_name}</div>
														</div>
													)}
												</div>
                        <div className='col-md-6 fv-row'>
                          <label className='col-md-6 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Document'})}</label>
                          <input
                            placeholder='CNPJ'
                            type='text'
                            autoComplete='off'
                            name='document'
                            maxLength={18}
                            onChange={e=>[addMask(e.currentTarget.value), setErrorDocument('')]}                
                            value={document}
                            className={clsx(
                              'form-control form-control-solid fs-5',
                              {'is-invalid': errorDocument},
                              {
                                'is-valid': document.length >= 14,
                              }
                            )}
                          />
                          {errorDocument && (
                            <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errorDocument}</span>
                              </div>
                            </div>
                          )}
                        </div>
												<div className='col-md-6 fv-row'>
													<label className='col-md-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Corporate name'})}</label>
													<input
														type="text"
														className="form-control form-control-solid fs-5"
														placeholder={`${intl.formatMessage({id: 'Corporate name'})}`}
														{...formik.getFieldProps('corporate_name')}
													/>
													{formik.touched.corporate_name && formik.errors.corporate_name && (
														<div className='fv-plugins-message-container'>
															<div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.corporate_name}</div>
														</div>
													)}
												</div>
												<div className='col-md-6 fv-row'>
													<label className='col-md-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Company'})}</label>
													<input
														type="text"
														className="form-control form-control-solid fs-5"
														placeholder={`${intl.formatMessage({id: 'Company'})}`}
														{...formik.getFieldProps('company')}
													/>
													{formik.touched.company && formik.errors.company && (
														<div className='fv-plugins-message-container'>
															<div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.company}</div>
														</div>
													)}
												</div>
                        <div className='col-md-12 fv-row mt-10'>
													<label className='col-md-6 col-form-label fw-bold fs-5'>Logo URL</label>
													<input
														type="text"
														className="form-control form-control-solid fs-5"
														placeholder="https://via.placeholder.com/200x200.png/007722?text=tempore"
														{...formik.getFieldProps('logo_url')}
													/>
												</div>
												
												<div className='col-md-6 fv-row'>
													<label className='col-md-6 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Municipal Registration'})}</label>
													<input
														type="text"
														className="form-control form-control-solid fs-5"
														placeholder={`${intl.formatMessage({id: 'Municipal Registration'})}`}
														{...formik.getFieldProps('municipal_reg')}
													/>
												</div>
												<div className='col-md-6 fv-row'>
													<label className='col-md-6 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'State Registration'})}</label>
													<input
														type="text"
														className="form-control form-control-solid fs-5"
														placeholder={`${intl.formatMessage({id: 'State Registration'})}`}
														{...formik.getFieldProps('state_reg')}
													/>
												</div>
												
												<div className='col-md-12 fv-row'>
													<label className='col-md-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Observation'})}</label>
													<textarea
														rows={3}
														className="form-control form-control-solid fs-5"
														placeholder={`${intl.formatMessage({id: 'Observation'})}`}
														{...formik.getFieldProps('observation')}
													/>
												</div>
											</div>
										</div>
                            
										<div className='col-md-12'>
                      <label className='col-md-4 col-form-label fw-bolder fs-3'>{intl.formatMessage({id: 'Address Details'})}</label>
                      <div className='row'>
                        <div className='col-md-6 fv-row mb-3'>
                          <label className='col-md-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Phone'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'Phone'})}`}
                            {...formik.getFieldProps('phone')}
                          />
                        </div>
                        <div className='col-md-6 fv-row mb-3'>
                          <label className='col-md-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Postal Code'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'Postal Code'})}`}
                            {...formik.getFieldProps('zip_code')}
                          />
                        </div>
                        <div className='col-md-6 fv-row mb-3'>
                          <label className='col-md-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'State'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'State'})}`}
                            {...formik.getFieldProps('state')}
                          />
                        </div>
                        <div className='col-md-6 fv-row mb-3'>
                          <label className='col-md-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'City'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'City'})}`}
                            {...formik.getFieldProps('city')}
                          />
                        </div>
                        <div className='col-md-6 fv-row mb-3'>
                          <label className='col-md-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Neighborhood'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'Neighborhood'})}`}
                            {...formik.getFieldProps('neighborhood')}
                          />
                        </div>
                        <div className='col-md-6 fv-row mb-3'>
                          <label className='col-md-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Street'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'Street'})}`}
                            {...formik.getFieldProps('street')}
                          />
                        </div>
                        <div className='col-md-6 fv-row mb-3'>
                          <label className='col-md-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Number'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'Number'})}`}
                            {...formik.getFieldProps('number')}
                          />
                        </div>
                        <div className='col-md-6 fv-row mb-3'>
                          <label className='col-md-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Complement'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'Complement'})}`}
                            {...formik.getFieldProps('complement')}
                          />
                        </div>
                        <div className='col-md-12 fv-row mb-3'>
                          <label className='col-md-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Country'})}</label>
                          <select className='form-select form-control form-control-solid fs-6' 
                            {...formik.getFieldProps('country')}
                          >
                            {
                              countries.map((item: any)=>
                                <option key={item.name} value={item.code}>{item.name}</option>
                              )
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                            
										<div className='col-md-12'>
                      <label className='col-md-4 col-form-label fw-bolder fs-3'>Social</label>
                      <div className='row'>
                        <div className='col-md-12 fv-row mb-3'>
                          <label className='col-md-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Whatsapp Number'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'Whatsapp Number'})}`}
                            {...formik.getFieldProps('whatsapp_number')}
                          />
                        </div>
                        <div className='col-md-12 fv-row mb-3'>
                          <label className='col-md-4 col-form-label fw-bold fs-5'>Facebook</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Facebook"
                            {...formik.getFieldProps('facebook')}
                          />
                        </div>
                        <div className='col-md-12 fv-row mb-3'>
                          <label className='col-md-4 col-form-label fw-bold fs-5'>Instagram</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Instagram"
                            {...formik.getFieldProps('instagram')}
                          />
                        </div>
                      </div>
                    </div>
									</div>
									<div className='border-top pt-5 mt-5 d-flex justify-content-end'>
										<button type='button' onClick={()=>setOpenModal(false)} className='btn btn-md btn-light ms-4 me-2'>{intl.formatMessage({id: 'Close'})}</button>
										<button onClick={()=>    setLoading(true)} type='submit' className='btn btn-md btn-primary text-white' disabled={isloading3}>
											{!isloading3 && intl.formatMessage({id: 'Save'})}
											{isloading3 && (
												<span className='indicator-progress' style={{display: 'block'}}>
													{intl.formatMessage({id: 'Processing'})}...{' '}
													<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
												</span>
											)}
										</button>
									</div>
								</form>
							</div>		
						</div>
					</div>
				</div>}
        </Box>
      </Modal>
        
        <div className="modal fade" tabIndex={-1} id="kt_modal_form_status">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{intl.formatMessage({id: 'Choose a status'})}</h5>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                  />
                </div>
              </div>
              <div className="modal-body mx-7 pb-3 p-0">
                <div className='align-items-center'>
                  <div className='col-lg-12 m-0'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row mb-3'>
                        <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Status'})}</label>
                          <select className='form-select form-control form-control-solid fs-5' 
                            value={status}
                            onChange={e=>[setStatus(e.currentTarget.value)]}
                          >
                            <option value=''>{intl.formatMessage({id: 'Select a option'})}...</option>
                            <option value='active'>{intl.formatMessage({id: 'Active'})}</option>
                            <option value='inactive'>{intl.formatMessage({id: 'Inactive'})}</option>
                            <option value='called off'>{intl.formatMessage({id: 'Called off'})}</option>
                            
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  {intl.formatMessage({id: 'Close'})}
                </button>
                <button onClick={()=>upStatus()} type="button" className="btn btn-primary text-white">
                {intl.formatMessage({id: 'Save changes'})} 
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" tabIndex={-1} id="kt_modal_del_company">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fs-3">{intl.formatMessage({id: 'Attention'})}!</h5>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                  />
                  
                </div>
                
              </div>
              <div className="modal-body">
                <p className='text-gray-700 fs-4'>{intl.formatMessage({id: 'Are you sure you want to delete this item?'})}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary text-white"
                  data-bs-dismiss="modal"
                >
                 {intl.formatMessage({id: 'No'})} 
                </button>
                <button type="button" onClick={()=>handleDelete(idCompany)} data-bs-dismiss='modal' className="btn btn-danger">
                {intl.formatMessage({id: 'Yes'})}  
                </button>
              </div>
            </div>
          </div>
        </div>

    </>
  )
}

export {TablesCompanies}
