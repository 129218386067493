import react, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Warning from '../../../warning/Warning';

import { KTSVG } from '../../../../../helpers';
import { Load } from '../../../spinner/Spinner';
import { info } from 'console';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '95%',
	maxWidth: 750,
	maxHeight: 600,
	bgcolor: 'background.paper',
	border: 'none',
	borderRadius: 1,
	boxShadow: 24,
	outline: 'none'
};

interface IModalSelectActionProps {
	showModal: boolean;
	title: string;
	dataAction: any;
	onClose: () => void;
	onAction: (id: any, index: any, title: any) => void;
}

const ActionPlanSelectAction: React.FC<IModalSelectActionProps> = ({ ...props }) => {

	const [dataActionCut, setDataActionCut] = useState<any>([]);
	const [isSearch, setIsSearch] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		setDataActionCut(props.dataAction)
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	}, []);

	const busca = (text: React.ChangeEvent<HTMLInputElement>) => {
		setIsSearch(true);
		let dado = JSON.parse(JSON.stringify(props.dataAction));

		setDataActionCut(dado.filter((d: any) => (
			d.title.toString().toLowerCase().indexOf(text.currentTarget.value.toString().toLowerCase()) > -1
		)));

		const dataInput = (document.getElementById('input_action_title') as HTMLInputElement).value;
		if (dataInput === '') {
			setIsSearch(false);
			setDataActionCut(props.dataAction);
		}
	}

	const buscaCode = (text: React.ChangeEvent<HTMLInputElement>) => {
		setIsSearch(true);
		let dado = JSON.parse(JSON.stringify(props.dataAction));

		setDataActionCut(dado.filter((d: any) => (
			d.uuid.toString().toLowerCase().indexOf(text.currentTarget.value.toString().toLowerCase()) > -1
		)));

		const dataInput = (document.getElementById('input_action_code') as HTMLInputElement).value;
		if (dataInput === '') {
			setIsSearch(false);
			setDataActionCut(props.dataAction);
		}
	}

	return (
		<Modal
			open={props.showModal}
			onClose={() => { }}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
					<span className="modal-title fw-bold fs-3">{props.title}</span>
					<div
						className="btn btn-icon btn-sm btn-active-light-primary"
						onClick={() => props.onClose()}
					>
						<KTSVG
							path="/media/icons/duotune/arrows/arr061.svg"
							className="svg-icon svg-icon-2x"
						/>
					</div>
				</div>


				<div className='row p-4'>
					<div className='col-sm-6'>
						<input
							type='text'
							placeholder='Ação'
							id='input_action_title'
							onChange={(event) => busca(event)}
							className='form-control form-control-lg form-control-solid w-100 me-1'
						/>
					</div>
					<div className='col-sm-6'>
						<input
							type='text'
							placeholder='Code'
							id='input_action_code'
							onChange={(event) => buscaCode(event)}
							className='form-control form-control-lg form-control-solid w-100 me-1'
						/>
					</div>
				</div>


				<div className='px-5 mb-10 mt-5 d-flex flex-column w-100' style={{ overflow: 'auto', overflowX: 'hidden', maxHeight: 350 }}>

					{
						loading ?
							<Load />
							:
							<>
								{
									dataActionCut.lenght !== 0 ?
										<>

											{
												isSearch ?
													<>
														{dataActionCut.map((item: any, index: any) =>
															<div key={index} className='h-50px mb-2'>
																<button
																	className='border-0 bg-light h-50px bg-hover-secondary rounded fs-6 text-gray-800 flex-wrap w-100'
																	style={{ textAlign: 'justify' }}
																	onClick={() => props.onAction(item.id, index, item.title)}
																>
																	<span>({index + 1}) {item.title.slice(0, 100)}...</span>
																</button>
															</div>
														)}
													</>
													:
													<>
														{props.dataAction.map((item: any, index: any) =>
															<div key={index} className='h-50px mb-2'>
																<button
																	className='border-0 bg-light h-50px bg-hover-secondary rounded fs-6 text-gray-800 flex-wrap w-100'
																	style={{ textAlign: 'justify' }}
																	onClick={() => props.onAction(item.id, index, item.title)}
																>
																	<span>({index + 1}) {item.title.slice(0, 100)}...</span>
																	
																</button>
															</div>
														)}
													</>
											}
										</>
										:
										<Warning message='Sorry, an error occurred!' />
								}
							</>

							
					}
					

				</div>
			</Box>
		</Modal>
	)
}
export default ActionPlanSelectAction;