import react, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG, toAbsoluteUrl } from '../../../../../helpers';
import { getPrescriptions } from '../../../../../../app/modules/helpers/Api';
import { Load } from '../../../spinner/Spinner';
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'


let paginationData ={
  currentPage: 1,
  total: 0,
  from: 0,
  to: 0,
  lastPage: 0
}



let initialSearchValues = {
  title: '',
  code: '',
  page: 1
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IModalCalculatorTypeProps {
  showModal: boolean;
  title: string;
  onClose: () => void;
  onAction: (id: any, form: string) => void;
}

const ModalCalculatorType: React.FC<IModalCalculatorTypeProps> = ({...props}) => {

  const [prescriptions, setPrescriptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<any>();
  const [name, setName] = useState('');
  const intl = useIntl();   

  
  // PAGINATION
  const [lastPage, setLastPage] = useState(0);

  useEffect(()=>{
    if (props.showModal == true) {
      setId('');
      setName('');
      getFormList()
    }    
  },[props.showModal]);

  function onClear(){
    setId('');
    setName('');
    paginationData.from = 0
    paginationData.to = 0
    paginationData.total =0
    paginationData.lastPage = 0

    initialSearchValues.title = ''
    initialSearchValues.code = ''
    initialSearchValues.page = 1

  }

  function handleNextPrevPage(value:any){
    if (value=='prev' && initialSearchValues.page >1){
      initialSearchValues.page--;
      getFormList();
    }
    if (value=='next' && initialSearchValues.page <lastPage){
      initialSearchValues.page++;
      getFormList();
    }
	}



  const formik = useFormik({
    initialValues: initialSearchValues,

    onSubmit: (values:any) => {
      initialSearchValues.title = values.title;
      initialSearchValues.code = values.code;
      getFormList()
    },
  })

  function getFormList(){
    setLoading(true);
    const get = getPrescriptions(initialSearchValues);
    get.then((res)=>{
      paginationData.from = res.data.data.from
      paginationData.to = res.data.data.to
      paginationData.total = res.data.data.total
      paginationData.lastPage = res.data.data.last_page 
      setLastPage(res.data.data.last_page);
      setPrescriptions(res.data.data.data);
     
    }).catch((error)=>alert(error))
    .finally(()=>setLoading(false));
	}

  return(
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
          
        {/* <div className='px-5 d-flex flex-row justify-content-between align-items-center w-100'>
          
          <div className='d-flex fw-bold h-60px flex-row align-items-center border-1 border-bottom border-dark w-100'>
              <span className="modal-title fw-bold fs-3">{props.title}</span>
              
          </div>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>[props.onClose(), onClear()]}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div> */}

        <div>
          <form onSubmit={formik.handleSubmit} noValidate className=''>

            <div className="row p-4">
              <div className="col-12 col-sm-4">

                <input
                  type="text"
                  className="form-control form-control-solid fs-6"
                  placeholder={intl.formatMessage({id: 'Title'})}
                  autoComplete='off'
                  {...formik.getFieldProps('title')}
                />

              </div>
              <div className="col-12 col-sm-4">
                <input
                  type="text"
                  className="form-control form-control-solid fs-6"
                  placeholder={intl.formatMessage({id: 'Code'})}
                  autoComplete='off'
                  {...formik.getFieldProps('code')}
                />
              </div>
              
              <div className='col-12 col-sm-4 my-sm-3 my-md-0'>
                <div className="d-flex flex-row w-100 justify-content-end ">

                  <button type='submit' className='btn btn-primary text-dark' disabled={loading}>
                    {!loading && 'Ok'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>

                </div>
                
            </div>            

            </div>
           

          </form>

   
          {/* <div className="d-flex flex-row w-100 justify-content-end p-4">
              <a href='' className='link-warning fs-5'>{intl.formatMessage({id: 'Doubt about how to proceed? Click here'})}</a>
          </div>      */}

          <div className='row mt-4'>
              <div className='col-12 mx-4'>
                 <p className='text-gray-500 fw-bold fs-5'>{intl.formatMessage({id: 'Select a form'})}</p>
              </div>
            
          </div>
        </div>
        
        <div className='px-5 my-1 ' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 250}}>
          {
            loading?
            <Load/>
            :
            <>
            {
              prescriptions.map((item: any) => 
                <label key={item.id} role={'button'} className={`alert mb-3 p-5 alert-dismissible ${
                  // item.model===1? ' border-success bg-light-success ' :
                  // item.model===2? ' border-info bg-light-info ' :
                  // item.model===3? ' border-warning bg-light-warning ' :
                  // item.model===4? ' border-dark bg-light-dark ' :
                  ' border-primary  '

                }  border border-dashed  d-flex  justify-content-between flex-sm-row form-check form-check-custom form-check-solid align-items-center`}>
                  
                  <div className='d-flex'>
                    
                    <span className="me-2">
                      <i className="fas fa-arrow-right fs-4 text-dark"></i>
                    </span>
                    
                    <div className='d-flex'>
                      <div>
                      { item.status === 2 ? <div className="badge badge-primary text-dark me-2">{intl.formatMessage({id: 'Test'})}</div> : null }
                      </div>
                      <div className="text-left ">                      
                        <div>{item.name}</div>
                        <div className='small text-gray-500 fw-bold '>{item.cat_title} {item.company?('| '+item.company):null}</div>          
                      </div>
                    </div>

                  </div>

                  <div>
                    <input
                      type='radio'
                      name='form'
                      className='form-check-input me-2'
                      onChange={()=>[setId(item.id), setName(item)]}
                    />
                  </div>                  
                </label>
              )
            }
            </>
          }
        </div>

        <div className='d-flex flex-stack flex-wrap p-5'>
          <div className='fs-6 fw-bold text-gray-700'>{intl.formatMessage({id: 'Showing'})} {paginationData.from} {intl.formatMessage({id: 'to'})} {paginationData.to} {intl.formatMessage({id: 'of'})} {paginationData.total} {intl.formatMessage({id: 'entries'})}.</div>

          <ul className='pagination'>
            <li className={'page-item previous '+(initialSearchValues.page==1?'disabled':'')} onClick={()=> handleNextPrevPage('prev')}>
              <a href='#' className='page-link'>
                <i className='previous' ></i>
              </a>
            </li>

            <li className='page-item active'>
              <a href='#' className='page-link bg-primary text-dark'>
                {initialSearchValues.page}
              </a>
            </li>

            <li className={'page-item next '+(initialSearchValues.page>=paginationData.lastPage?'disabled':'')} onClick={()=> handleNextPrevPage('next')}>
              <a href='#' className='page-link'>
                <i className='next' ></i>
              </a>
            </li>

          </ul>
        </div>

        <div className='d-flex flex-row w-100 justify-content-end p-5'>

          <button disabled={loading} type='button' onClick={()=>[props.onClose(), onClear()]} className='btn btn-md btn-danger text-white me-2 btn-sm'>
            {intl.formatMessage({id: 'Cancel'})}
          </button>

          {/* <button className='btn btn-md btn-secondary text-gray-600' style={{marginRight: '5px'}}>Previous</button> */}
          <button disabled={name === ''? true : false} type='button' onClick={()=>[props.onAction(id, name),onClear()]} className='btn btn-md btn-primary text-dark btn-sm'>
          {intl.formatMessage({id: 'Continue'})}
          </button>
        </div>
      </Box>
    </Modal>
  )
}
export default ModalCalculatorType;