/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import {KTSVG} from '../../../helpers'
import { useIntl } from 'react-intl'

const Alert01: FC = () => {
  const intl = useIntl();   

  return(
    <div className="modal fade" tabIndex={-1} id="kt_modal_1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header p-2 m-0 justify-content-end">
            {/* <h5 className="modal-title"></h5> */}
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body">
            <p className='fs-5 text-center'>{intl.formatMessage({id: 'Sorry, this feature is still under development'})}</p>
          </div>
          <div className="modal-footer p-2 m-0">
            <button
              type="button"
              className="btn btn-primary text-white"
              data-bs-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Alert01}
