import React, { useState } from 'react';
import { KTSVG } from '../../../../../helpers';
import ModalEmail from './modals/modalEmail';
import ModalPassword from './modals/modalPassword';
import ModalRoles from './modals/modalRoles';
import { useIntl } from 'react-intl'
import { Box, Modal } from '@mui/material';
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { trataRespota } from '../../../../../../app/modules/helpers/helpers'
import { requestEmail } from '../../../../../../app/modules/auth/redux/AuthCRUD';
import { toast } from 'react-toastify';
import clsx from 'clsx'
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { UserModel } from '../../../../../../app/modules/auth/models/UserModel';
const initialValues = {
	newEmail: '',
  }

const style = {
	position: 'absolute' as 'absolute',
	top: '40%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '95%',
	maxWidth: 500,
	maxHeight: 320,
	bgcolor: 'background.paper',
	border: 'none',
	borderRadius: 3,
	boxShadow: 24,
	outline: 'none'
};

type Props = {
	data: any;
	reload: (action: boolean) => void;

}

const ProfileResume: React.FC<Props> = ({...props}) => {
	const [openModalEditionMail, setOpenModalEditionMail] = useState(false);
	const [openModalEditionPass, setOpenModalEditionPass] = useState(false);
	const [openModalEditionRole, setOpenModalEditionRole] = useState(false);
	const [openModalEmail, setOpenModalEmail] = useState (false);
	const intl = useIntl();  
	const [loading, setLoading] = useState(false)
	const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
	const forgotPasswordSchema = Yup.object().shape({
		newEmail: Yup.string()
		  .email(`${intl.formatMessage({id: 'Formato de e-mail incorreto'})}`)
		  .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
		  .required(`${intl.formatMessage({id: 'E-mail'})} ${intl.formatMessage({id: 'È requerido'})}`),
	})

	const formik = useFormik({
		initialValues, 
		validationSchema: forgotPasswordSchema,
		onSubmit: (values, {setStatus, setSubmitting}) => {
		  setLoading(true)
			requestEmail(props.data.email, values.newEmail)
			  .then((data) => {
				if(data.data.status === 'error'){
				  toast.error(data.data.message);
				}else {
				  toast.success(data.data.message);
				  setOpenModalEditionMail(false)
				}
				setLoading(false)
			  })
			  .catch((error) => {
				trataRespota('error', error); 
				setLoading(false)
				setSubmitting(false)
				setStatus(`${intl.formatMessage({id: 'Os detalhes de login estão incorretos'})}`)
			  })
		},
	  }) 

  return(
		<>
		<div className="card card-custom card-flush mb-5">
		{/* <ModalEmail
        title={`${intl.formatMessage({id: 'Edit'})} ${intl.formatMessage({id: 'E-mail'})}`}
        showModal={openModalEditionMail}
        onClose={()=>setOpenModalEditionMail(false)}
        onAction={()=>[setOpenModalEditionMail(false), props.reload(true)]}
        id={props.data.id}
        data={props.data}
      /> */}
		<ModalPassword
        title={intl.formatMessage({id: 'Change Password'})}
        showModal={openModalEditionPass}
        onClose={()=>setOpenModalEditionPass(false)}
        onAction={()=>[setOpenModalEditionPass(false), props.reload(true)]}
        id={props.data.id}
      />
			<ModalRoles
        title={intl.formatMessage({id: 'Change Role'})}
        showModal={openModalEditionRole}
        onClose={()=>setOpenModalEditionRole(false)}
        onAction={()=>[setOpenModalEditionRole(false), props.reload(true)]}
        id={props.data.id}
      />
	 <div className="card-header m-0">
      	<h3 className="card-title fs-2 fw-bolder m-0 p-0">{intl.formatMessage({id: 'Credenciais'})}</h3>
      </div>
			<div className="card-body m-0 pt-0 w-100">

				<div className='col-lg-12 w-100 border-bottom-dashed border-1 border-gray-400 py-3'>
					<div className='w-100 row d-flex align-items-center'>
						<div className='col-lg-5'>
							<span className='text-gray-700 fw-bold fs-6'>{intl.formatMessage({id: 'E-mail'})}</span>
						</div>
						<div className='col-lg-3 fv-row'>
							<span className='text-gray-700 fw-bold fs-6'>{props.data.email}</span>
						</div>
						{
							['admin', 'super-admin'].includes(user.roles) ?
							<div className='col-lg-4 fv-row text-end m-0 p-0'>
							<button
								className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm'
								onClick={()=>setOpenModalEditionMail(true)}
							>
								<KTSVG
									path='/media/icons/duotune/art/art005.svg'
									className='svg-icon-3 svg-icon-warning'
								/>
							</button>
						</div>
						:
						<></>
						}
					</div>
				</div>

				<div className='col-lg-12 w-100 py-3'>
					<div className='w-100 row d-flex align-items-center'>
						<div className='col-lg-5'>
							<span className='text-gray-700 fw-bold fs-6'>{intl.formatMessage({id: 'Password'})}</span>
						</div>
						<div className='col-lg-3 fv-row'>
							<span className='text-gray-700 fw-bold fs-6'>******</span>
						</div>
						{
							['admin', 'super-admin'].includes(user.roles) ?
							<div className='col-lg-4 fv-row text-end m-0 p-0'>
							<button
								className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm'
								onClick={()=>setOpenModalEditionPass(true)}
							>
								<KTSVG
									path='/media/icons/duotune/art/art005.svg'
									className='svg-icon-3 svg-icon-warning'
								/>
							</button>
						</div>
						:
						<></>
						}
						
					</div>
				</div>
				{/* <div className='col-lg-12 w-100 pt-3'>
					<div className='w-100 row d-flex align-items-center'>
						<div className='col-lg-5'>
							<span className='text-gray-700 fw-bold fs-6'>Role</span>
						</div>
						<div className='col-lg-3 fv-row'>
							<span className='text-gray-700 fw-bold fs-6'>{props.data.roles? props.data.roles[0] : '---'}</span>
						</div>
						<div className='col-lg-4 fv-row text-end m-0 p-0'>
							<button
								className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm'
								onClick={()=>setOpenModalEditionRole(true)}
							>
								<KTSVG
									path='/media/icons/duotune/art/art005.svg'
									className='svg-icon-3 svg-icon-primary'
								/>
							</button>
						</div>
					</div>
				</div> */}
				
			</div>
			<Modal
            open={openModalEditionMail}
            onClose={()=>{}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
				
                <div className='d-flex justify-content-between p-2'>
				<div className='m-3'><p className='fw-bolder fs-4'>Troca de E-mail</p></div>
                    <div
                    className="btn btn-icon btn-sm btn-active-light-primary "
                    onClick={()=>[setOpenModalEditionMail(false)]}
                    >
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                    />
                    </div>
                </div>
					
			<div className='text-center mb-1'>
				<p className='fw-bolder fs-7'>
					Para realizar a troca de E-mail, digite o novo E-mail abaixo !!! <br/>
					Será enviado um Token, para a confimação e alteração do E-mail
				</p>

            </div>
			<form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='fv-row mb-1 p-3'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({id: 'E-mail'})}</label>
              <input
                type='email'
                placeholder='novoEmail@mail.com'
                autoComplete='off'
                {...formik.getFieldProps('newEmail')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.newEmail && formik.errors.newEmail},
                  {
                    'is-valid': formik.touched.newEmail && !formik.errors.newEmail,
                  }
                )}
              />
              {formik.touched.newEmail && formik.errors.newEmail && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block text-danger mt-2 fs-5'>
                    <span role='alert'>{formik.errors.newEmail}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='d-flex flex-wrap justify-content-center pb-lg-0 row'>
              <div className='col-6'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn bg-hover-dark mb-5 text-white w-100'
                  style={{width: '168px', height: '48px', background: '#65638E', borderRadius: 13}}
                >
                  {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Enviar'})}</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
			</form>
            </Box>
          </Modal>
		</div>
		</>
  )
}
export default ProfileResume;