import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../../helpers';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { getDegreeByCompany, downResource, downCalculatorResult } from '../../../../../../app/modules/helpers/Api';
import { Load } from '../../../spinner/Spinner';
import ReportPoints from './ReportPoints';
import Warning from '../../../warning/Warning';
import ReportDegreeRisk from './ReportDegreeRisk';
import ReportClassificaiton from './ReportClassification';
import { converteData, copyText, handleDownload } from '../../../../../../app/modules/helpers/helpers';
import ReportListAnswers from '../../Answers/Report/ReportListAnswers'
import BarCharts from './BarCharts';
import { useIntl } from 'react-intl'


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  minWidth: 300,
  maxWidth: 750,
  maxHeight: 650,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

interface IModalReportGraphProps {
  showModal: boolean;
  title: string;
  id: any;
	reportPosition?: any;
	onClose: () => void;
}

const ModalReportGraph: React.FC<IModalReportGraphProps> = ({...props}) => {

  const intl = useIntl();   
	const [dataDegree, setDataDegree] = useState<any>([]);
  const [degree, setDegree] = useState<any>([]);
	const [loading, setLoading] = useState(false);
  const [listQuestions, setQuestions] = useState<any>([]);
  const [viewInfo, setViewInfo] = useState('questions');
  const [seriesData, setSeriesData] = useState <any> ([]);
  const [titles, setTitles] = useState <any> ([])
  
	useEffect(()=>{
		setLoading(true);
    const getDegree = getDegreeByCompany(props.id);
    getDegree.then((res)=>{
      if(res.data.data){
        if(res.data.data.mapp_result){
          let mappResult = JSON.parse(res.data.data.mapp_result)
          let titles: any = [];
          let seriesData: any = [];
          Object.entries(mappResult).map(([item, index])=>
          {
            let n: any = index
            let i: any = item
            titles.push(item)
            seriesData.push(mappResult[i]['percent'])
          }
          )
          setTitles(titles)
          setSeriesData(seriesData)
        }

        setDataDegree(res.data.data);
        setDegree(res.data.data.classification);
        setQuestions(res.data.data.questions);
      }
    }).catch((error)=>alert(error))
    .finally(()=>setLoading(false));

    setViewInfo('questions');

	},[props.id])
 

  const  series = [{
    data: seriesData
  }]
  const options: any = {
      chart: {
        type: 'bar'
      },
      responsive: [{
        breakpoint: 725,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          size: '200px',
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: titles
        
      },
      yaxis: [ {
        max: 100,
        min: 0
        // opposite: true,
      }],
      series: [{
        tooltip: {
          valueDecimals: 1,
          valueSuffix: '%'
        }
      }]
    }
  
  
    function downloadResource (id: any, name: any, extension: any) {
    const get = downCalculatorResult(id);
    get.then((res)=>{
      if(res.statusText === 'OK'){
        toast.success('Operação realizada!');
        handleDownload(res, name, extension);
      } else {
        toast.error('desculpe, um erro ocorreu!');
      }
    }).catch((error)=>{
      if (error.response.status == 404) {
        
        let file = new Blob([error.response.data], {type: 'application/json'});

        file.text()
        .then(value => {

          let object = JSON.parse(value);

          toast.warning(object.message);

        })
        .catch(error => {
          toast.error('Desculpe um erro ocorreu')
          console.log("Desculpe um erro ocorreu" + error);
        });

      } else {
        toast.error('Indiponível no momento!')
      }
    })
  }

	return(
		<Modal
      open={props.showModal}
      onClose={()=>{}}
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
          <span className="modal-title fw-bold fs-3">{dataDegree.name}</span>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>props.onClose()}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className='px-5'>

          <p className='text-gray-600 text-end'>
            {converteData(dataDegree.created_at)}
          </p>

          <div className='row text-center'>
            <div className='col'>
              <h5 className='mt-4 mb-4'>{dataDegree.final_sentence}</h5>
            </div>
          </div>
          <div className='rounded align-items-center border-dashed border-1 p-3 mb-4 border-primary'>
            <div className='row'>
              <div className='col'>
                <label className='form-check form-check-custom form-check-solid align-items-center'>
                  <input
                    type='radio'
                    name='role'
                    checked={viewInfo === 'questions'}
                    value='questions'
                    defaultChecked={false}
                    className='form-check-input me-3'
                    onChange={()=>setViewInfo('questions')}
                  />
                  <span className='form-check-label d-flex flex-column align-items-start'>
                    <span className='fw-bolder text-gray-800 fs-6 mb-0'>{intl.formatMessage({id: 'Questions'})}</span>
                    
                  </span>
                </label>
              </div>
              <div className='col'>
                <label className='form-check form-check-custom form-check-solid align-items-center'>
                  <input
                    type='radio'
                    name='role'
                    checked={viewInfo === 'info'}
                    value='info'
                    disabled={[3].includes(dataDegree.model)}
                    defaultChecked={false}
                    className='form-check-input me-3'
                    onChange={()=>setViewInfo('info')}
                  />
                  <span className='form-check-label d-flex flex-column align-items-start'>
                    <span className='fw-bolder text-gray-800 fs-6 mb-0'>{intl.formatMessage({id: 'Info'})}</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      
        <div className='px-5 text-center' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 300}}>
					{
						loading?
						<Load/>
						:
						<>
							{
								dataDegree.lenght !== 0?
								// <>
								// 	<ReportPoints
								// 		dataReport={dataDegree}
								// 	/>
								// 	{
								// 		dataDegree.model === 1?
								// 		<>
								// 			<ReportDegreeRisk
								// 				dataReport={dataDegree}
								// 			/>
								// 			<ReportClassificaiton
								// 				dataReport={dataDegree}
								// 				degree={degree}
								// 			/>
								// 		</>
								// 		:
								// 		<></>
								// 	}
								// </>
                <>
                {
                  dataDegree.model === 1 || dataDegree.model === 2?
                  <div>
                    
                    {
                      viewInfo === 'questions' ?
                      <ReportListAnswers dataList={listQuestions}/>
                      :
                      viewInfo === 'info' ?
                      <>
                      <ReportPoints dataReport={dataDegree}/>
                      {
                        dataDegree.model === 1?
                        <>
                          <ReportDegreeRisk
                            dataReport={dataDegree}
                          />
                          <ReportClassificaiton
                            dataReport={dataDegree}
                            degree={degree}
                          />
                        </>
                        :
                        null
                      }
                      </>
                      :
                      <div className='rounded text-dark text-primary border-1 border-dashed border-primary p-5 mb-4'>
                        <h5 className='m-4'>{intl.formatMessage({id: 'Nothing to show'})}</h5>
                       
                      </div>
                      
                    }
                    {/* <ReportPoints dataReport={dataDegree}/> */}
                    {/* {
                      dataDegree.model === 1?
                      <>
                        <ReportDegreeRisk
                          dataReport={dataDegree}
                        />
                        <ReportClassificaiton
                          dataReport={dataDegree}
                          degree={degree}
                        />
                      </>
                      :
                      <></>
                    } */}
                  </div>
                  :
                  <div>
                    {
                      dataDegree.model === 3?
                        viewInfo === 'questions' ?
                        <ReportListAnswers dataList={listQuestions}/>
                        :
                        <div className='rounded text-dark text-primary border-1 border-dashed border-primary p-5 mb-4'>
                          <h5 className='m-4'>{intl.formatMessage({id: 'Nothing to show'})}</h5>
                        </div>
                      :
                      <div>
                        {
                          
                          dataDegree.model === 4?
                            viewInfo === 'questions' ?
                            <ReportListAnswers dataList={listQuestions}/>
                            :
                            <div>
  
                              { dataDegree.mapp_result ? 
                                 //Object.entries(JSON.parse(dataDegree.mapp_result)).map(([item,index])=>
                                  <div id='kt_apexcharts_1' className='row rounded text-dark text-primary p-5 mb-4'>
                                    {/* <div><strong>{item}</strong></div>
        
                                    <strong>{JSON.parse(dataDegree.mapp_result)[item].total}</strong>
                                    <strong>{JSON.parse(dataDegree.mapp_result)[item].percent}</strong> */}
                                    {/* <div>
                                      {
                                      JSON.parse(dataDegree.mapp_result)[item].total<10 ? 
                                      ('0'+JSON.parse(dataDegree.mapp_result)[item].total) : 
                                      JSON.parse(dataDegree.mapp_result)[item].total} | 
                                      {JSON.parse(dataDegree.mapp_result)[item].percent}%
                                    </div> */}
                                    
                                    <div className='col-12'>
                                    <BarCharts options={options} series={series}/>
                                    </div>
                                    {/* <div className='col-3 p-xxm-4 p-lg-0 w-25 d-flex flex-column rounded align-items-center justify-content-center mt-xxm-10 mt-lg-0'>
                                      <span className='text-dark fw-bolder fs-2'>{JSON.parse(dataDegree.mapp_result)[item].total}</span>
                                      <span className='text-gray-600 fw-bold fs-3'>Total</span>
                                    </div> */}
                                  
                                    
                                  </div>
                               // )
                                :
                                <div className='rounded text-dark text-primary border-1 border-dashed border-primary p-5 mb-4'>
                                  <h5 className='m-4'>{intl.formatMessage({id: 'Nothing to show'})}</h5>
                                </div>
                              }                               
                              
                            </div>
                          :
                          <div>
                            {intl.formatMessage({id: 'Model'})} {intl.formatMessage({id: 'Not Found'})}...
                          </div>
                        }
                      </div>
                    }
                  </div>
                }
                </>

								:
								<Warning message={intl.formatMessage({id: 'Sorry, no content to show yet'})}/>
							}
						</>
					}

        </div>
        
          
          {
            dataDegree.next_form != null?
            <div 
            className='text-center bolder w-75 mx-auto fs-5 rounded text-dark text-primary '
            
            >
              <p>{intl.formatMessage({id: 'To continue this form, use this code'})}</p>
              <span className='text-success bg-hover-success text-hover-white p-2 rounded' 
              onClick={()=>copyText(dataDegree.next_form, intl)}
              >{dataDegree.next_form}</span> 
           </div>
           :
           <></>
         }
        
        <div className='d-flex flex-row w-100 justify-content-end mt-5 px-5 pb-5'>

          
          

          <button type='button'
            onClick={() => downloadResource(dataDegree.answers_code, 'calculadora_'+dataDegree.name, 'pdf') } 
            className='btn btn-icon btn-bg-secondary bg-hover-secondary btn-sm me-1'
            title={`${intl.formatMessage({id: 'PDF download'})}`}
          >
            <KTSVG
              path='/media/icons/duotune/files/fil021.svg'
              className='svg-icon-2x svg-icon-info'
            />
          </button>



          <button type='button' onClick={()=>props.onClose()} className='btn btn-md btn-primary w-100 text-dark btn-sm'>
          {intl.formatMessage({id: 'Close'})}
          </button>

        </div>
      </Box>
    </Modal>
	)
}

export default ModalReportGraph;