import react, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG, toAbsoluteUrl } from '../../../../helpers';
import { defaultPaginationList, storeHolderRequestToken, updateHolderRequestToken, getCompaniesByUser, verifyTypeRequest, storeQuestionsRequestToken } from '../../../../../app/modules/helpers/Api';
import { trataRespota, copyText } from '../../../../../app/modules/helpers/helpers'
import { toast } from 'react-toastify'
import { Load } from '../../spinner/Spinner';
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { info } from 'console';
import Warning from '../../warning/Warning'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Item1 } from '../../../content/activity/Item1';

let paginationData = {
  currentPage: 1,
  total: 0,
  from: 0,
  to: 0,
  lastPage: 0
}

let initialSearchValues = {
  title: '',
  code: '',
  page: 1
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: '80%',
  maxHeight: 800,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};


interface IModalRequerimentTokenProps {
  showModal: boolean;
  title: string;
  // company_id: any;
  onClose: () => void;
  onAction: (id: any, form: string) => void;
}

const ModalRequerimentToken: React.FC<IModalRequerimentTokenProps> = ({ ...props }) => {

  const [prescriptions, setPrescriptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<any>();
  const [name, setName] = useState('');
  const intl = useIntl();
  const [seeToken, setSeeToken] = useState(false);
  const [newToken, setNewToken] = useState(false);
  const [tokenCreated, setTokenCreated] = useState(0);
  const [companies, setCompanies] = useState<any>([]);
  const currentLocation = window.location.origin;
  const [questions, setQuestions] = useState<any>([]);

  const handleAddQuestion = () => {
    setQuestions([...questions, { text: '', type: 'input', options: [] }]);
  };

  const handleQuestionChange = (index: any, event: any) => {
    const newQuestions = [...questions];
    newQuestions[index].text = event.target.value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex: any, optionIndex: any, event: any) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = event.target.value;
    setQuestions(newQuestions);
  };

  const handleAddOption = (questionIndex: any) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.push('');
    setQuestions(newQuestions);
  };

  const handleRemoveQuestion = (index: any) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const handleRemoveOption = (questionIndex: any, optionIndex: any) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestions(newQuestions);
  };

  const initialValues = {
    title: '',
    description: '',
    status: '',
    type: '',
    company_id: ''
  }

  // PAGINATION
  const [lastPage, setLastPage] = useState(0);


  const registrationSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, `${intl.formatMessage({ id: 'Mínimo de 3 símbolos' })}`)
      .required(`${intl.formatMessage({ id: 'Title' })} ${intl.formatMessage({ id: 'is required' })}`),
    description: Yup.string()
      .required(`${intl.formatMessage({ id: 'Description' })} ${intl.formatMessage({ id: 'is required' })}`),
    status: Yup.string()
      .required(`${intl.formatMessage({ id: 'Status' })} ${intl.formatMessage({ id: 'is required' })}`),
    type: Yup.string()
      .required(`${intl.formatMessage({ id: 'Type' })} ${intl.formatMessage({ id: 'is required' })}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      if (formik.getFieldProps('id').value) {
        // alert('update')
        handleUpdateToken(values);
      } else {
        handleStoreToken(values);
      }


    },
  })

  const [questionOptions, setQuestionOptions] = useState<any>([])
  function handleViewdetails(item: any) {
    setNewToken(true)

    formik.setFieldValue('id', item.id);
    formik.setFieldValue('company_name', item.company_name);
    formik.setFieldValue('company_id', item.company_id);
    formik.setFieldValue('title', item.title);
    formik.setFieldValue('description', item.description);
    formik.setFieldValue('status', item.status);
    formik.setFieldValue('type', item.type_req);
    formik.setFieldValue('url', `${currentLocation}/management/holder/request/pt/${item.token}`);

    // tratando o array de perguntas e options
    const cleanedData = item.questions.replace(/\\/g, '');
    const formattedData = cleanedData.replace(/""/g, '"');
    const questionsArray = JSON.parse(`[${formattedData}]`);
    const filteredQuestions = questionsArray.reduce((acc: any, question: any) => {
      const existingQuestion = acc.find((q: any) => q.id === question.id);
      if (existingQuestion) {
        existingQuestion.options.push(question.options);
      } else {
        acc.push({ ...question, options: [question.options] });
      }
      return acc;
    }, []);

    setQuestionOptions(filteredQuestions);
  }

  function saveQuestions(token: number) {

    setLoading(true);

    let store = storeQuestionsRequestToken({
      'token': token,
      company_id: formik.values.company_id,
      questions,

    });

    store.then((res) => {

      if (res.data.status === 'success') {

        formik.resetForm()
        setQuestions([])
        setNewToken(false)
        setTokenCreated(0)
        getFormList()
        // toast.success(res.data.message);

        // setResponseMessage(res.data.message)
        // setOpenModalConfirm(true)

      }
      else if (res.data.status === 'error') {
        toast.error(res.data.message);

        // setResponseMessage(res.data.message)
        // setOpenModalConfirm(true)
      }

    }).catch((error) => {
      trataRespota('error', error);
    }).finally(() => {
      setLoading(false)
    });
  }
  function handleStoreToken(values: any) {

    if (!values.company_id) {
      toast.success('Empresa não selecionada!');
      return
    }

    setLoading(true);

    let store = storeHolderRequestToken({
      company_id: values.company_id,
      title: values.title,
      description: values.description,
      status: values.status,
      type_req: values.type
    });

    store.then((res) => {

      if (res.data.status === 'success') {


        setTokenCreated(res.data.token)
        saveQuestions(res.data.token)
        toast.success(res.data.message);

        // setResponseMessage(res.data.message)
        // setOpenModalConfirm(true)

      }
      else if (res.data.status === 'error') {
        toast.error(res.data.message);

        // setResponseMessage(res.data.message)
        // setOpenModalConfirm(true)
      }

    }).catch((error) => {
      trataRespota('error', error);
    }).finally(() => {
      setLoading(false)
    });
  }

  function handleUpdateToken(values: any) {

    setLoading(true);

    let update = updateHolderRequestToken({
      id: values.id,
      title: values.title,
      description: values.description,
      status: values.status,
      type_req: values.type,
      company_id: values.company_id
    });

    update.then((res) => {

      if (res.data.status === 'success') {
        formik.resetForm()
        setNewToken(false)
        getFormList()
        toast.success(res.data.message);
      }
      else if (res.data.status === 'error') {
        toast.error(res.data.message);
      }

    }).catch((error) => {
      trataRespota('error', error);
    }).finally(() => {
      setLoading(false)
    });
  }


  let searchData = {
    code: '',
    page: 1,
    perpage: 10,
    solved: 0,
    unread: 1,
    in_progress: 0,
    search: ''
  }

  useEffect(() => {
    // setLoading(true);
    // setId('');
    // setName('');

    formik.resetForm()
    if (props.showModal === true) {
      getFormList()
    }
  }, [props.showModal]);

  function onClear() {
    setId('');
    setName('');
    paginationData.from = 0
    paginationData.to = 0
    paginationData.total = 0
    paginationData.lastPage = 0

    initialSearchValues.title = ''
    initialSearchValues.code = ''
    initialSearchValues.page = 1

  }

  function handleNextPrevPage(value: any) {
    if (value == 'prev' && initialSearchValues.page > 1) {
      initialSearchValues.page--;
      getFormList();
    }
    if (value == 'next' && initialSearchValues.page < lastPage) {
      initialSearchValues.page++;
      getFormList();
    }
  }





  function getFormList() {

    setLoading(true);

    const get = defaultPaginationList('company/requeriment/tokens', initialSearchValues)
    get.then((res) => {

      paginationData.from = res.data.data.from
      paginationData.to = res.data.data.to
      paginationData.total = res.data.data.total
      paginationData.lastPage = res.data.data.last_page

      setLastPage(res.data.data.last_page);
      setPrescriptions(res.data.data.data);

    }).catch((error) => alert(error))
      .finally(() => setLoading(false));
  }

  function handleSetNewToken() {
    formik.resetForm()
    handleCompaniesByUser();
    setNewToken(true)
  }

  function handleCompaniesByUser() {
    const getCompanies = getCompaniesByUser();
    setLoading(true);
    getCompanies.then((res) => {
      if (res.data.status === 'success') {

        setCompanies(res.data.data)
      } else {
        console.log(res.data.message)
      }

    }).catch((error) => {
      // console.log(error)
    }).finally(() => {
      setLoading(false);
    });


  }


  const handleTypeChange = (index: any, e: any) => {
    const newQuestions = [...questions];
    newQuestions[index].type = e.target.value;
    if (newQuestions[index].type === 'input') {
      newQuestions[index].options = []; // Limpar opções se o tipo mudar para 'input'
    }
    setQuestions(newQuestions);
  };
  return (
    <Modal
      open={props.showModal}
      onClose={() => { }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>

        <div className='px-5 d-flex flex-row justify-content-between align-items-center w-100'>

          <div className='d-flex fw-bold h-60px flex-row align-items-center border-1 border-bottom border-dark w-100'>
            <span className="modal-title fw-bold fs-3">



              {
                !newToken ?
                  'Lista de Tokens'
                  :
                  formik.getFieldProps('id').value ? 'Visualizar Token' : 'Novo Token'

              }
            </span>

          </div>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={() => [setNewToken(false), setQuestionOptions([]), props.onClose(), onClear(), formik.resetForm(), setQuestions([])]}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>

        {
          newToken ?

            <div className='px-6 py-8'>

              {formik.getFieldProps('id').value ?
                <div className=' mb-6 fw-bold' >
                  <span className={'cursor-pointer text-hover-primary'} title={intl.formatMessage({ id: 'Copy' })} onClick={() => copyText(formik.getFieldProps('url').value, intl)}>
                    <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-3 svg-icon-success me-2' />
                    <span>{formik.getFieldProps('url').value}</span>
                  </span>
                </div>
                :
                null
              }



              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}

              >


                <div className="px-4 py-6" style={{ maxHeight: '500px', overflowY: 'auto' }} >
                  <div className='fv-row mb-3' >
                    <input
                      disabled
                      type='hidden'
                      autoComplete='off'
                      {...formik.getFieldProps('id')}
                      className='form-control form-control-lg form-control-solid'
                    />
                  </div>

                  <div className='fv-row mb-3' >

                    <label className='form-label fw-bold text-dark fs-5 required'>{intl.formatMessage({ id: 'Company' })}</label>

                    {formik.getFieldProps('id').value ?
                      <input readOnly value={formik.getFieldProps('company_name').value}
                        className='form-control form-control-lg form-control-solid w-100 me-1' />
                      :
                      <select {...formik.getFieldProps('company_id')}

                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          { 'is-invalid': formik.touched.company_id && formik.errors.company_id },
                          {
                            'is-valid': formik.touched.company_id && !formik.errors.company_id,
                          }
                        )}
                      >
                        {formik.touched.company_id && formik.errors.company_id && (
                          <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.company_id}</span>
                            </div>
                          </div>
                        )}
                        <option>{intl.formatMessage({ id: 'Select a company' })}</option>
                        {
                          companies.map((companiesList: any) =>
                            <option key={companiesList.value} value={companiesList.value}>{companiesList.text}</option>
                          )

                        }

                      </select>
                    }

                  </div>


                  <div className='row' >





                    <div className='col col-md-6 mb-3'>
                      <label className='form-label fw-bold text-dark fs-5 required'>{intl.formatMessage({ id: 'Title' })}</label>
                      <input
                        disabled={loading || formik.getFieldProps('id').value}

                        placeholder={intl.formatMessage({ id: 'Title' })}
                        type='title'
                        autoComplete='off'
                        {...formik.getFieldProps('title')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          { 'is-invalid': formik.touched.title && formik.errors.title },
                          {
                            'is-valid': formik.touched.title && !formik.errors.title,
                          }
                        )}
                      />
                      {formik.touched.title && formik.errors.title && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.title}</span>
                          </div>
                        </div>
                      )}
                    </div>


                    <div className='col col-md-3' >

                      <label className='class="form-label fw-bold text-dark fs-5 mb-1 required'>{intl.formatMessage({ id: 'Status' })}</label>
                      <select
                        disabled={loading}

                        {...formik.getFieldProps('status')}
                        className={clsx(
                          'form-select form-control form-control-solid fs-6 mt-2',
                          { 'is-invalid': formik.touched.status && formik.errors.status },
                          {
                            'is-valid': formik.touched.status && !formik.errors.status,
                          }
                        )}
                      >
                        {!formik.getFieldProps('id').value ?
                          <option value=''>{intl.formatMessage({ id: 'Select' })}</option>
                          :
                          null
                        }

                        <option value='active'>{intl.formatMessage({ id: 'Active' })}</option>
                        <option value='inactive'>{intl.formatMessage({ id: 'Inactive' })}</option>
                      </select>

                    </div>

                    {/* Tipo do token LGPD ou DENUNCIA */}
                    <div className='col col-md-3' >

                      <label className='class="form-label fw-bold text-dark fs-5 mb-1 required'>{intl.formatMessage({ id: 'Type' })}</label>
                      <select
                        disabled={loading || formik.getFieldProps('id').value}

                        {...formik.getFieldProps('type')}
                        className={clsx(
                          'form-select form-control form-control-solid fs-6 mt-2',
                          { 'is-invalid': formik.touched.type && formik.errors.type },
                          {
                            'is-valid': formik.touched.type && !formik.errors.type,
                          }
                        )}
                      >
                        {!formik.getFieldProps('id').value ?
                          <option value=''>{intl.formatMessage({ id: 'Select' })}</option>
                          :
                          null
                        }

                        <option value='0'>LGPD</option>
                        <option value='1'>{intl.formatMessage({ id: 'denuncia' })}</option>
                      </select>

                    </div>






                  </div>





                  {/* Description */}
                  <div className='mb-2 fv-row' data-kt-password-meter='true' >
                    <div className='mb-1'>
                      <label className='form-label fw-bold text-dark fs-6 '>{intl.formatMessage({ id: 'Description' })}</label>
                      <div className='position-relative mb-3'>
                        <div className='d-flex justify-content-between w-100'>
                          <textarea
                            disabled={loading || formik.getFieldProps('id').value}

                            placeholder={intl.formatMessage({ id: 'Description' })}
                            autoComplete='off'
                            {...formik.getFieldProps('description')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid w-100 me-1',
                              {
                                'is-invalid': formik.touched.description && formik.errors.description,
                              },
                              {
                                'is-valid': formik.touched.description && !formik.errors.description,
                              }
                            )}
                          />
                        </div>
                        {formik.touched.description && formik.errors.description && (
                          <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.description}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>



                  <div className=" mt-5" >

                    <div>

                      {questions.length > 0 && <h3 className='mb-3'> Perguntas({questions.length})</h3>}

                      {questions.map((question: any, index: any) => (
                        <div key={index} className="mb-5">
                          <label>Pergunta {index + 1}</label>

                          <div className="d-flex align-items-center mb-3">
                            <input
                              type='text'
                              value={question.text}
                              onChange={(e) => handleQuestionChange(index, e)}
                              placeholder='Digite sua pergunta'
                              className="form-control form-control-solid me-2"
                              style={{ flex: 1 }}
                            />
                            <select
                              value={question.type}
                              onChange={(e) => handleTypeChange(index, e)}
                              className="form-select form-select "
                              style={{ width: 'auto', boxShadow: 'none' }}
                            >
                              <option value="input">Texto</option>
                              <option value="select">Opção</option>
                            </select>
                            <button onClick={() => handleRemoveQuestion(index)} className="btn btn-danger ms-2" type="button">
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>

                          {question.type === 'select' && (
                            <>
                              <small className='m-10'>Opções ({question.options.length})</small>
                              {question.options.map((option: any, optionIndex: any) => (
                                <div key={optionIndex} className="mb-1">
                                  <div className="d-flex justify-content-end">
                                    <input
                                      type='text'
                                      value={option}
                                      onChange={(e) => handleOptionChange(index, optionIndex, e)}
                                      placeholder='Digite uma opção'
                                      className="form-control form-control-solid w-95"
                                    />
                                    <button onClick={() => handleRemoveOption(index, optionIndex)} className="btn btn-outline-danger" type="button">
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <button type='button' onClick={() => handleAddOption(index)} className="btn btn-outline-primary d-flex justify-content-end">Adicionar Opção</button>
                            </>
                          )}
                        </div>
                      ))}
                    </div>

                    {
                      questionOptions.length > 0 && questionOptions[0].id &&
                      <>
                        <hr />
                        <h3 className='mt-2 mb-4'>Perguntas ({questionOptions.length})</h3>
                      </>
                    }
                    {questionOptions && Array.isArray(questionOptions) && questionOptions.length > 0 && (
                      <>
                        {questionOptions.map((question, index) => {
                          // Verifica se os dados da questão são nulos
                          if (!question || !question.id || !question.text || !question.options || !Array.isArray(question.options) || question.options.length === 0) {
                            return null; // Ignora a questão se os dados forem nulos
                          }

                          // Verifica se já processamos a questão com este id
                          const processedQuestion = questionOptions.slice(0, index).find(q => q.id === question.id);
                          if (processedQuestion) {
                            return null; // Ignora a questão se já foi processada
                          }

                          // Caso contrário, processa a questão e suas opções
                          const options = questionOptions
                            .filter(q => q.id === question.id)
                            .flatMap(q => q.options.flat()); // Aqui usamos flat() para nivelar os arrays adicionais

                          return (
                            <div key={index} className='mb-8'>
                              <p><strong>{question.text}</strong></p>
                              
                              {options.map((option, optionIndex) => (
                                <input
                                  key={optionIndex}
                                  type="text"
                                  className="form-control form-control-lg form-control-solid w-100 me-1 mb-1"
                                  value={option.text == null ? 'Resposta dissertativa': option.text}
                                  disabled
                                />))}
                            </div>
                          );
                        })}
                      </>
                    )}






                    <div className="text-center">
                      {
                        !formik.getFieldProps('id').value &&
                        <button type='button' className='btn btn-info btn-sm fw-bold mb-3' onClick={handleAddQuestion}>
                          Adicionar pergunta
                        </button>
                      }

                    </div>
                  </div>


                </div>
                <div className="mt-2">
                  <div className='text-center row'>

                    <div className='text-center col-12  d-flex'>
                      <button
                        type='button'
                        className='btn bg-danger m-2 w-100 text-white'
                        //  disabled={!formik.values.acceptTerms}

                        onClick={() => [setNewToken(false), setQuestionOptions([]), setQuestions([])]}
                      >
                        {!tokenCreated ?
                          <span className='indicator-label'>{intl.formatMessage({ id: 'Cancel' })}</span>
                          :
                          <span className='indicator-label'>{intl.formatMessage({ id: 'Close' })}</span>
                        }
                      </button>




                      <button
                        type='submit'
                        className='btn bg-primary text-dark m-2 w-100'
                      >
                        {!loading && <span className='indicator-label'>
                          {formik.getFieldProps('id').value ?
                            intl.formatMessage({ id: 'Update' })
                            :
                            intl.formatMessage({ id: 'Create' })
                          }</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>



                    </div>


                  </div>

                </div>
              </form>
            </div>








            :

            <div>





              <div className='row py-6 px-5'>

                <div className='col-sm-8 '>
                  {/* <h6 className=''>{intl.formatMessage({ id: 'List of tokens available for requesting holders' })}</h6> */}
                </div>

                <div className='col-sm-4 d-flex justify-content-end'>
                  <button type='submit' className='btn btn-primary text-dark w-100 w-sm-50' disabled={loading} onClick={() => handleSetNewToken()}>
                    {intl.formatMessage({ id: 'New' })}
                  </button>
                </div>


              </div>

              <div className='px-5 my-1 text-center' style={{ overflow: 'auto', maxHeight: 300 }}>
                {
                  loading ?
                    <Load />
                    :
                    <>
                      {
                        prescriptions.length === 0 ?
                          <Warning message={intl.formatMessage({ id: 'There are no registered tokens yet' })} />
                          :
                          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            <thead>
                              <tr className='fw-bolder text-muted'>
                                <th className='min-w-30px text-center'>{intl.formatMessage({ id: 'Actions' })}</th>
                                <th style={{ minWidth: '200px' }} >{intl.formatMessage({ id: 'Company' })}</th>
                                <th style={{ minWidth: '200px' }}>{intl.formatMessage({ id: 'Title' })}</th>
                                <th className='min-w-60px'>{intl.formatMessage({ id: 'Type' })}</th>
                                <th style={{ minWidth: '200px' }}>{intl.formatMessage({ id: 'User' })}</th>
                                <th className='min-w-60px'>{intl.formatMessage({ id: 'Status' })}</th>
                                <th className='min-w-60px'>{intl.formatMessage({ id: 'Requests' })}</th>
                                <th className='min-w-60px'>LGPD/{intl.formatMessage({ id: 'denuncia' })}</th>
                              </tr>
                            </thead>
                            <tbody>

                              {prescriptions?.map((item: any, index: any) =>
                                <tr key={index}>

                                  <td>
                                    <button
                                      className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                      // title={`${intl.formatMessage({id: 'Copy'})}`}
                                      onClick={() => handleViewdetails(item)}
                                    > <i className="fas fa-eye text-info"></i>
                                    </button>
                                  </td>

                                  <td >
                                    <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                      {item.company_name}
                                    </span>
                                  </td>

                                  <td>
                                    <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                      {item.title}
                                    </span>
                                  </td>

                                  <td>
                                    <span className='text-gray-700 fw-bold fs-6'>
                                      {
                                        item.type === 'holders requests'
                                          ? <span className="badge badge-light-info">{intl.formatMessage({ id: item.type })}</span>
                                          : item.type
                                      }
                                    </span>
                                  </td>

                                  <td>
                                    <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                      {item.user}
                                    </span>
                                  </td>

                                  <td>
                                    <span className='text-gray-700 fw-bold fs-6'>
                                      {
                                        item.status === 'active'
                                          ? <span className="badge badge-light-success">{intl.formatMessage({ id: 'Active' })}</span>
                                          : item.status === 'inactive'
                                            ? <span className="badge badge-light-danger">{intl.formatMessage({ id: 'Inactive' })}</span>
                                            : item.status
                                      }
                                    </span>
                                  </td>

                                  <td>
                                    <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                      {item.req_count}
                                    </span>
                                  </td>
                                  <td>
                                    <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                      {item.type_req == 0 ?
                                        <span>
                                          <span className="badge badge-light-info">LGPD</span>
                                        </span>
                                        :
                                        <span>
                                          <span className="badge badge-light-danger">{intl.formatMessage({ id: 'denuncia' })}</span>

                                        </span>
                                      }
                                    </span>
                                  </td>

                                </tr>
                              )}

                            </tbody>
                          </table>
                      }

                    </>
                }
              </div>

              <div className='d-flex flex-stack flex-wrap p-5'>
                <div className='fs-6 fw-bold text-gray-700'>{intl.formatMessage({ id: 'Showing' })} {paginationData.from} {intl.formatMessage({ id: 'to' })} {paginationData.to} {intl.formatMessage({ id: 'of' })} {paginationData.total} {intl.formatMessage({ id: 'entries' })}.</div>

                <ul className='pagination'>
                  <li className={'page-item previous ' + (initialSearchValues.page == 1 ? 'disabled' : '')} onClick={() => handleNextPrevPage('prev')}>
                    <a href='#' className='page-link'>
                      <i className='previous' ></i>
                    </a>
                  </li>

                  <li className='page-item active'>
                    <a href='#' className='page-link bg-primary text-dark'>
                      {initialSearchValues.page}
                    </a>
                  </li>

                  <li className={'page-item next ' + (initialSearchValues.page >= paginationData.lastPage ? 'disabled' : '')} onClick={() => handleNextPrevPage('next')}>
                    <a href='#' className='page-link'>
                      <i className='next' ></i>
                    </a>
                  </li>

                </ul>
              </div>

            </div>

        }

      </Box>
    </Modal >
  )
}
export default ModalRequerimentToken;