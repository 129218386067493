/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import { StepTwo } from './Step02'

const schema = Yup.object().shape({
  purpose: Yup.string()
  .required('Purpose is required'),
  data: Yup.string()
  .required('Data is required'),
  classification: Yup.string()
  .required('Classification is required'),
  anonymization: Yup.string()
  .required('Anonymization is required'),
})

type Props = {
  create: (action: boolean) => void;
  name?: any;
  id?: any;
  data?: any;
}

const StepOne: FC<Props> = ({...props}) => {  

  const[loading, setLoading] = useState(false);
  const[next, setNext] = useState('');
  const[source, setSource] = useState('empresa');
  const[collection, setCollection] = useState('aplicativo');
  const[dataStep1, setDataStep1] = useState<any>({});

  const initialValues = {
    purpose: '',
    data: '',
    another: '',
    classification: '',
    anonymization: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false)
        setDataStep1({
          title: props.data.title || null,
          companyId: props.data.companyId || null,
          company: props.data.company || null,
          sectorId: props.data.sectorId || null,
          sector: props.data.sector || null,
          purpose: values.purpose,
          data: values.data,
          another: values.another,
          classification: values.classification,
          anonymization: values.anonymization,
          collection_origin: source,
          source_collection: collection,
        })
        setNext('Next')
      }, 1000)
    },
  })

  function move (action: boolean) {
    if(action){
      setNext('');
    }
  }

  return (
    <>
    { next === ''?
    <div className='card mb-5 mb-xl-10'>
      <div className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top px-5 py-5'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <label className='form-label fw-bolder text-dark fs-3 '>New Data Mapping - Step 01 of 06</label>
              <button onClick={()=>props.create(false)} type='submit' className='btn btn-sm btn-secondary'>
                Back
              </button>
            </div>
            <div className='mb-2 fv-row border-top pt-4' data-kt-password-meter='true'>
              {
                props.data.title?
                <label className='form-label fw-bold text-dark fs-3 mb-5'>{props.data.title}</label>
                :
                <></>
              }
              <div className='d-flex flex-column'>
                {props.data.company? <label className='form-label fw-bold text-dark fs-6'><strong>Company:</strong> {props.data.company}</label> : <></>}
                {props.data.sector? <label className='form-label fw-bold text-dark fs-6 mb-5'><strong>Sector:</strong> {props.data.sector}</label> : <></>}
              </div>
            </div>
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1 mt-4'>
                <label className='form-label fw-bold required text-dark fs-6'>Purpose</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <textarea
                      placeholder='Purpose'
                      autoComplete='off'
                      {...formik.getFieldProps('purpose')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-100 me-1',
                        {
                          'is-invalid': formik.touched.purpose && formik.errors.purpose,
                        },
                        {
                          'is-valid': formik.touched.purpose && !formik.errors.purpose,
                        }
                      )}
                    />
                  </div>
                  {formik.touched.purpose && formik.errors.purpose && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.purpose}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold required text-dark fs-6'>Data</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('data')}
                    >
                      <option value=''>...Select an option</option>
                      <option value='1'>Nome</option>
                      <option value='2'>CPF</option>
                      <option value='3'>Endereço</option>
                      <option value='4'>Data de Nascimento</option>
                      <option value='5'>Conta de Email</option> 
                      <option value='6'>Dados de Cartão de Crédito</option>
                      <option value='7'>Geolocalização</option>
                      <option value='8'>Dados Bancários</option>
                      <option value='9'>Desempenho Físico</option>
                      <option value='10'>Exame Médico</option>
                      <option value='11'>Exame Psicológico</option>
                      <option value='12'>Frequência Cardiáca</option>
                      <option value='13'>Desempenho usuário</option>
                      <option value='14'>Quilometragem percorrida</option>
                      <option value='15'>Trajeto realizado</option>
                      <option value='16'>Tempo Utilizado</option>
                      <option value='17'>IMEI </option>
                      <option value='18'>Lista de Contatos</option>
                      <option value='19'>Câmera</option>
                      <option value='20'>Fotos</option>
                      <option value='21'>Necessidade especial</option>
                      <option value='22'>Cor</option>
                      <option value='23'>Gênero</option>
                      <option value='24'>Área de formação</option>
                      <option value='25'>Nacionalidade</option>
                      <option value='26'>Naturalidade</option>
                      <option value='27'>CTPS</option>
                      <option value='28'>PIS</option>
                      <option value='29'>N° reservista</option>
                      <option value='30'>RG</option>
                      <option value='31'>N° titulo de eleitor</option>
                      <option value='32'>Registro profissional</option>
                      <option value='33'>Estado do registro</option>
                      <option value='34'>Nome do pai</option>
                      <option value='35'>Nome da mãe</option>
                      <option value='36'>Nome do conjuge</option>
                      <option value='37'>Telefone de contato próximo</option>
                      <option value='38'>Dependentes</option>
                      <option value='39'>Número de dependentes</option>
                      <option value='40'>Fumante</option>
                      <option value='41'>Veículo próprio</option>
                      <option value='42'>Tipo de veículo</option>
                      <option value='43'>CNH</option>
                      <option value='44'>Dados de formação acadêmica</option>
                      <option value='45'>Orientação sexual</option>
                      <option value='46'>Nome social</option>
                      <option value='outro'>Outros</option>
                    </select>
                  </div>
                  {formik.touched.data && formik.errors.data && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.data}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {
              formik.values.data === 'outro'?
              <div className='mb-2 fv-row' data-kt-password-meter='true'>
                <div className='mb-1 mt-4'>
                  <label className='form-label fw-bold required text-dark fs-6'>Another</label>
                  <div className='position-relative mb-3'>
                    <div className='d-flex justify-content-between w-100'>
                      <input
                        type='text'
                        placeholder='Another'
                        autoComplete='off'
                        {...formik.getFieldProps('another')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid w-100 me-1',
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              :
              <></>
            }
            <div className='d-flex flex-row w-100'>
              <div className='mb-2 fv-row me-10' data-kt-password-meter='true'>
                <div className='mb-1 mt-4'>
                  <label className='form-label fw-bold required text-dark fs-6'>Origin Collection</label>
                  <div className='position-relative mb-3'>
                    <div className='mt-3'>
                      <label className='form-check form-check-custom form-check-solid align-items-center'>
                        <input
                          type='checkbox'
                          name='role'
                          className='form-check-input me-1'
                          // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                        />
                        <span className='form-check-label d-flex flex-column align-items-start'>
                          <span className='fw-bolder text-gray-800 fs-6 mb-0'>EMPRESA</span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='position-relative mb-3'>
                    <div className='mt-3'>
                      <label className='form-check form-check-custom form-check-solid align-items-center'>
                        <input
                          type='checkbox'
                          name='role'
                          className='form-check-input me-1'
                          // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                        />
                        <span className='form-check-label d-flex flex-column align-items-start'>
                          <span className='fw-bolder text-gray-800 fs-6 mb-0'>PARCEIRO</span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='position-relative mb-3'>
                    <div className='mt-3'>
                      <label className='form-check form-check-custom form-check-solid align-items-center'>
                        <input
                          type='checkbox'
                          name='role'
                          className='form-check-input me-1'
                          // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                        />
                        <span className='form-check-label d-flex flex-column align-items-start'>
                          <span className='fw-bolder text-gray-800 fs-6 mb-0'>PRÓPRIO TITULAR</span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='position-relative mb-3'>
                    <div className='mt-3'>
                      <label className='form-check form-check-custom form-check-solid align-items-center'>
                        <input
                          type='checkbox'
                          name='role'
                          className='form-check-input me-1'
                          // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                        />
                        <span className='form-check-label d-flex flex-column align-items-start'>
                          <span className='fw-bolder text-gray-800 fs-6 mb-0'>TERCEIRO LIGADO AO TITULAR</span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='position-relative mb-3'>
                    <div className='mt-3'>
                      <label className='form-check form-check-custom form-check-solid align-items-center'>
                        <input
                          type='checkbox'
                          name='role'
                          className='form-check-input me-1'
                          // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                        />
                        <span className='form-check-label d-flex flex-column align-items-start'>
                          <span className='fw-bolder text-gray-800 fs-6 mb-0'>FORNECEDOR</span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='position-relative mb-3'>
                    <div className='mt-3'>
                      <label className='form-check form-check-custom form-check-solid align-items-center'>
                        <input
                          type='checkbox'
                          name='role'
                          className='form-check-input me-1'
                          // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                        />
                        <span className='form-check-label d-flex flex-column align-items-start'>
                          <span className='fw-bolder text-gray-800 fs-6 mb-0'>OUTROS</span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='position-relative mb-3'>
                    <div className='mt-3'>
                      <label className='form-check form-check-custom form-check-solid align-items-center'>
                        <input
                          type='checkbox'
                          name='role'
                          className='form-check-input me-1'
                          // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                        />
                        <span className='form-check-label d-flex flex-column align-items-start'>
                          <span className='fw-bolder text-gray-800 fs-6 mb-0'>NÃO SABE DIZER</span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-2 fv-row' data-kt-password-meter='true'>
                <div className='mb-1 mt-4'>
                  <label className='form-label fw-bold required text-dark fs-6'>Source Collection</label>
                  
                  <div className='d-flex flex-row'>
                    <div className='d-flex flex-column me-10'>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>APLICATIVO</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>SOFTWARE</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>SITE</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>E-MAIL</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>FACEBOOK</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>INSTAGRAM</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>WHATSAPP</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  
                    <div className='d-flex flex-column me-10'>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>LINKEDIN</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>LATTES</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>JUSBRASIL</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>ESCAVADOR</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>TIK TOK</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>YOUTUBE</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>TWITTER</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex flex-column'>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>RELÓGIO</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>ÓCULOS</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>COMPUTADOR</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>CELULAR</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='position-relative mb-3'>
                        <div className='mt-3'>
                          <label className='form-check form-check-custom form-check-solid align-items-center'>
                            <input
                              type='checkbox'
                              name='role'
                              className='form-check-input me-1'
                              // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                            />
                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder text-gray-800 fs-6 mb-0'>NÃO SABE DIZER</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold required text-dark fs-6'>Classification</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('classification')}
                    >
                      <option value=''>...Select an option</option>
                      <option value='sensivel'>Sensível</option>
                      <option value='comum'>Comum</option>
                      <option value='crianca e adolescentes'>Crianças e Adolescentes</option>
                    </select>
                  </div>
                  {formik.touched.classification && formik.errors.classification && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.classification}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold required text-dark fs-6'>Anonymization</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('anonymization')}
                    >
                      <option value=''>...Select an option</option>
                      <option value='yes'>Sim</option>
                      <option value='no'>Não</option>
                    </select>
                  </div>
                  {formik.touched.anonymization && formik.errors.anonymization && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.anonymization}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Next'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Processando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
      :
      <StepTwo move={move} dataStep1={dataStep1}/>
    }
    </>
  )
}

export {StepOne}
