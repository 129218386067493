/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import ModalMessageConfirm from '../../../modals/modalMessageConfirm';
import { toast } from 'react-toastify';
import { createDatamapping } from '../../../../../../app/modules/helpers/Api';

const schema = Yup.object().shape({

})

type Props = {
  move: (action: boolean) => void;
  dataStep1: any;
  dataStep2: any;
  dataStep3: any;
  dataStep4: any;
  dataStep5: any;
}

const StepSix: FC<Props> = ({...props}) => {  

  const[loading, setLoading] = useState(false);
  const[next, setNext] = useState('');
  const[dataStep6, setDataStep6] = useState<any>({});
  const[rightsHolders, setRightsHolders] = useState('acesso');
  const[openModalSubmit, setOpenModalSubmit] = useState(false);

  const initialValues = {
    lifespan: '',
    legal_basis_retain: '',
    rights_holders: '',
    storage_location: '',
    internal_safety_measures: '',
    destiny: '',
    comments: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false)
        setDataStep6({
          lifespan: values.lifespan,
          legal_basis_retain: values.legal_basis_retain,
          rights_holders: rightsHolders,
          storage_location: values.storage_location,
          internal_safety_measures: values.internal_safety_measures,
          destiny: values.destiny,
          comments: values.comments,
        })
        setNext('Next')
        setOpenModalSubmit(true);
      }, 1000)
    },
  })

  function handleSubmit () {
    const data = {
      title: props.dataStep1.title || null,
      companyId: props.dataStep1.companyId || null,
      company: props.dataStep1.company || null,
      sectorId: props.dataStep1.sectorId || null,
      sector: props.dataStep1.sector || null,
      purpose: props.dataStep1.purpose,
      data: props.dataStep1.data,
      other_data: props.dataStep1.another || null,
      origin_collection: props.dataStep1.collection_origin,
      source_collection: props.dataStep1.source_collection,
      classification: props.dataStep1.classification,
      anonymization: props.dataStep1.anonymization,
      legal_base: props.dataStep2.legal_base,
      complement: props.dataStep2.complement || null,
      share: props.dataStep3.share,
      share_with_whom: props.dataStep3.share_with_whom || null,
      share_why: props.dataStep3.share_why || null,
      share_like: props.dataStep3.share_like || null,
      location_treatment: props.dataStep4.location_treatment,
      country_has_treatment: props.dataStep4.country_has_treatment,
      who_has_access: props.dataStep5.who_has_access,
      who_has_access_why: props.dataStep5.who_has_access_why,
      lifespan: dataStep6.lifespan,
      legal_basis_retain: dataStep6.legal_basis_retain,
      rights_holders: dataStep6.rights_holders,
      storage_location: dataStep6.storage_location,
      internal_safety_measures: dataStep6.internal_safety_measures,
      destiny: dataStep6.destiny,
      comments: dataStep6.comments
    }
    const save = createDatamapping(data);
    save.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message)
        setOpenModalSubmit(false);
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message)
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message)
      } else {
        toast.error('Sorry, an error occurred!');
      }
    })
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <ModalMessageConfirm
        message='Are you sure you want to send this information?'
        title='Attention!'
        showModal={openModalSubmit}
        onClose={()=>setOpenModalSubmit(false)}
        onAction={handleSubmit}
      />
      <div className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top px-5 py-5'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <label className='form-label fw-bolder text-dark fs-3 '>New Data Mapping - Step 06 of 06</label>
              {/* <button onClick={()=>props.create(false)} type='submit' className='btn btn-sm btn-secondary'>
                Back
              </button> */}
            </div>
            <div className='mb-2 fv-row border-top pt-5' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>Lifespan</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('lifespan')}
                    >
                      <option value=''>...Select an option</option>
                      <option value='1'>2 meses</option>
                      <option value='2'>3 meses</option>
                      <option value='3'>6 meses</option>
                      <option value='4'>1 ano</option>
                      <option value='5'>2 anos</option>
                      <option value='6'>5 anos</option>
                      <option value='7'>Sem vida útil</option>
                      <option value='8'>Outro</option>
                      
                    </select>
                  </div>
                  {formik.touched.lifespan && formik.errors.lifespan && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.lifespan}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>Legal Basis Retain</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('legal_basis_retain')}
                    >
                      <option value=''>...Select an option</option>
                      <option value='1'>Consentimento</option>
                      <option value='2'>Legítimo interesse do controlador</option>
                      <option value='3'>Proteção à vida</option>
                      <option value='4'>Tutela da saúde</option>
                      <option value='5'>Execução do contrado</option>
                      <option value='6'>Obrigação legal</option>
                      <option value='7'>EXERCÍCIO REGULAR DO DIREITO EM PROCESSO</option>
                      <option value='8'>Políticas Públicas</option>
                      <option value='9'>Pesquisa</option>
                      <option value='10'>Proteção de Crédito</option>
                      
                    </select>
                  </div>
                  {formik.touched.legal_basis_retain && formik.errors.legal_basis_retain && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.legal_basis_retain}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='mb-2 fv-row me-10' data-kt-password-meter='true'>
                  <div className='mb-1 mt-4'>
                    <label className='form-label fw-bold required text-dark fs-6'>Rights Holders</label>
                    <div className='position-relative mb-3'>
                      <div className='mt-3'>
                        <label className='form-check form-check-custom form-check-solid align-items-center'>
                          <input
                            type='checkbox'
                            name='role'
                            className='form-check-input me-1'
                            // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                          />
                          <span className='form-check-label d-flex flex-column align-items-start'>
                            <span className='fw-bolder text-gray-800 fs-6 mb-0'>ACESSO</span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='position-relative mb-3'>
                      <div className='mt-3'>
                        <label className='form-check form-check-custom form-check-solid align-items-center'>
                          <input
                            type='checkbox'
                            name='role'
                            className='form-check-input me-1'
                            // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                          />
                          <span className='form-check-label d-flex flex-column align-items-start'>
                            <span className='fw-bolder text-gray-800 fs-6 mb-0'>RETIFICAÇÃO</span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='position-relative mb-3'>
                      <div className='mt-3'>
                        <label className='form-check form-check-custom form-check-solid align-items-center'>
                          <input
                            type='checkbox'
                            name='role'
                            className='form-check-input me-1'
                            // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                          />
                          <span className='form-check-label d-flex flex-column align-items-start'>
                            <span className='fw-bolder text-gray-800 fs-6 mb-0'>CANCELAMENTO</span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='position-relative mb-3'>
                      <div className='mt-3'>
                        <label className='form-check form-check-custom form-check-solid align-items-center'>
                          <input
                            type='checkbox'
                            name='role'
                            className='form-check-input me-1'
                            // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                          />
                          <span className='form-check-label d-flex flex-column align-items-start'>
                            <span className='fw-bolder text-gray-800 fs-6 mb-0'>OPOSIÇÃO</span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='position-relative mb-3'>
                      <div className='mt-3'>
                        <label className='form-check form-check-custom form-check-solid align-items-center'>
                          <input
                            type='checkbox'
                            name='role'
                            className='form-check-input me-1'
                            // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                          />
                          <span className='form-check-label d-flex flex-column align-items-start'>
                            <span className='fw-bolder text-gray-800 fs-6 mb-0'>REVISÃO DAS DECISÕES AUTOMATIZADAS</span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='position-relative mb-3'>
                      <div className='mt-3'>
                        <label className='form-check form-check-custom form-check-solid align-items-center'>
                          <input
                            type='checkbox'
                            name='role'
                            className='form-check-input me-1'
                            // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                          />
                          <span className='form-check-label d-flex flex-column align-items-start'>
                            <span className='fw-bolder text-gray-800 fs-6 mb-0'>PORTABILIDADE</span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              <div className='mb-2 fv-row' data-kt-password-meter='true'>
                <div className='mb-1 mt-4'>
                  <label className='form-label fw-bold text-dark fs-6'>Storage Location</label>
                  <div className='position-relative mb-3'>
                    <div className='d-flex justify-content-between w-100'>
                      <input
                        type='text'
                        placeholder='Storage Location'
                        autoComplete='off'
                        {...formik.getFieldProps('storage_location')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid w-100 me-1',
                          
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div> 
              <div className='mb-2 fv-row' data-kt-password-meter='true'>
                <div className='mb-1 mt-4'>
                  <label className='form-label fw-bold text-dark fs-6'>Internal Safety Measures</label>
                  <div className='position-relative mb-3'>
                    <div className='d-flex justify-content-between w-100'>
                      <input
                        type='text'
                        placeholder='Internal Safety Measures'
                        autoComplete='off'
                        {...formik.getFieldProps('internal_safety_measures')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid w-100 me-1',
                          
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div> 
              <div className='mb-2 fv-row' data-kt-password-meter='true'>
                <div className='mb-1 mt-4'>
                  <label className='form-label fw-bold text-dark fs-6'>Destiny</label>
                  <div className='position-relative mb-3'>
                    <div className='d-flex justify-content-between w-100'>
                      <input
                        type='text'
                        placeholder='Destiny'
                        autoComplete='off'
                        {...formik.getFieldProps('destiny')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid w-100 me-1',
                          
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div> 
              <div className='mb-2 fv-row' data-kt-password-meter='true'>
                <div className='mb-1 mt-4'>
                  <label className='form-label fw-bold text-dark fs-6'>Comments</label>
                  <div className='position-relative mb-3'>
                    <div className='d-flex justify-content-between w-100'>
                      <input
                        type='text'
                        placeholder='Comments'
                        autoComplete='off'
                        {...formik.getFieldProps('comments')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid w-100 me-1',
                          
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div> 
            </div>
            
            
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-secondary me-3' onClick={()=>props.move(true)}>
              Back
            </button>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              
              {!loading && 'Finish'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Processando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {StepSix}
