import react, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../helpers';
import { defaultPaginationList, getHoldeRequest, setResponseHolderRequest } from '../../../../../app/modules/helpers/Api';
import { trataRespota, copyText } from '../../../../../app/modules/helpers/helpers'
import { toast } from 'react-toastify'
import { Load } from '../../spinner/Spinner';
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../../setup'

let paginationData = {
  currentPage: 1,
  total: 0,
  from: 0,
  to: 0,
  lastPage: 0
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

let initialSearchValues = {
  title: '',
  code: '',
  page: 1
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IModalHolderRequestProps {
  showModal: boolean;
  holderRequest: any;
  handleGetList: () => void;
  onClose: () => void;
  onAction: (id: any, form: string) => void;
}

const ModalHolderRequest: React.FC<IModalHolderRequestProps> = ({ ...props }) => {

  const [prescriptions, setPrescriptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [id, setId] = useState<any>();
  const [name, setName] = useState('');
  const intl = useIntl();

  const [seeToken, setSeeToken] = useState(false);
  const [newToken, setNewToken] = useState(false);
  const [questions, setQuestions] = useState<any>([]);

  const currentLocation = window.location.origin;

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const initialValues = {
    application: '',
    rights: '',
    created_at: '',
    deadline: '',
    deleted_at: '',
    description: '',
    observation: '',
    reply_at: '',
    request_at: '',
    requester_mail: '',
    requester_name: '',
    response: '',
    status: 'Pending',
    type: '',
    updated_at: '',
    uuid: '',
    user_name: '',
    accepted: '',
    company_id: ''
  }

  // PAGINATION
  const [lastPage, setLastPage] = useState(0);


  const registrationSchema = Yup.object().shape({
    // requester_name: Yup.string(),
    // title: Yup.string()
    //   .min(3, `${intl.formatMessage({ id: 'Mínimo de 3 símbolos' })}`)
    //   .required(`${intl.formatMessage({ id: 'Title' })} ${intl.formatMessage({ id: 'is required' })}`),
    // description: Yup.string()
    //   .required(`${intl.formatMessage({ id: 'Description' })} ${intl.formatMessage({ id: 'is required' })}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      handleResponseHolderRequest(values);

    },
  })

  function handleConfirmResponse() {

    setLoading(true);
    setOpenModalConfirm(false);

    let update = setResponseHolderRequest(formik.getFieldProps('uuid').value, {
      accepted: formik.getFieldProps('accepted').value,
      response: formik.getFieldProps('response').value,
      description: formik.getFieldProps('description').value,
      observation: formik.getFieldProps('observation').value,
      status: formik.getFieldProps('status').value,
      company_id: formik.getFieldProps('company_id').value
    });

    update.then((res) => {

      if (res.data.status === 'success') {
        // formik.resetForm()
        toast.success(res.data.message)
        setOpenModalConfirm(false)
        props.handleGetList()
        props.onClose()

      }
      else if (res.data.status === 'error') {
        toast.error(res.data.message);
      }

    }).catch((error) => {
      trataRespota('error', error);
    }).finally(() => {
      setLoading(false)

    });
  }

  function handleResponseHolderRequest(values: any) {

    if (values.status == 'Done') {
      setOpenModalConfirm(true)
    } else {
      handleConfirmResponse();
    }

  }

  useEffect(() => {

    formik.setFieldValue('accepted', '');
    formik.setFieldValue('user_name', '');

    handleGetHolderRequest()
  }, [props.holderRequest.uuid, props.holderRequest.updated_at]);


  function handleGetHolderRequest() {

    if (!props.holderRequest.uuid) {
      return
    }

    setLoading2(true);

    const get = getHoldeRequest(props.holderRequest.uuid)

    get.then((res) => {

      if (res.data.status === 'success') {

        formik.setFieldValue('application', res.data.data.requeriment.application);
        formik.setFieldValue('created_at', res.data.data.requeriment.created_at);
        formik.setFieldValue('deadline', res.data.data.requeriment.deadline);
        formik.setFieldValue('deleted_at', res.data.data.requeriment.deleted_at);
        formik.setFieldValue('description', res.data.data.requeriment.description);
        formik.setFieldValue('observation', res.data.data.requeriment.observation);
        formik.setFieldValue('reply_at', res.data.data.requeriment.reply_at);
        formik.setFieldValue('request_at', res.data.data.requeriment.request_at);
        formik.setFieldValue('requester_mail', res.data.data.requeriment.requester_mail == 0 ? 'Anônimo' : res.data.data.requeriment.requester_mail);
        formik.setFieldValue('requester_name', res.data.data.requeriment.requester_name == 0 ? 'Anônimo' : res.data.data.requeriment.requester_name);
        formik.setFieldValue('response', res.data.data.requeriment.response);
        formik.setFieldValue('status', res.data.data.requeriment.status ? res.data.data.requeriment.status : 'Pending');
        formik.setFieldValue('type', res.data.data.requeriment.type);
        formik.setFieldValue('updated_at', res.data.data.requeriment.updated_at);
        formik.setFieldValue('uuid', res.data.data.requeriment.uuid);
        formik.setFieldValue('user_name', res.data.data.requeriment.user_name);
        formik.setFieldValue('rights', res.data.data.requeriment.rights);
        formik.setFieldValue('company_id', res.data.data.requeriment.company_id);

        const cleanedData = res.data.data.requeriment.answers.replace(/\\/g, '');
        const formattedData = cleanedData.replace(/""/g, '"');
        const questionsArray = JSON.parse(`[${formattedData}]`);
        setQuestions(questionsArray);

      }


    }).catch((error) => {
      toast.error(error.response.data.message)
    })
      .finally(() => [setLoading2(false), setLoading(false)]);

    // }, 3000)

  }

  function onClear() {
    setId('');
    setName('');
    paginationData.from = 0
    paginationData.to = 0
    paginationData.total = 0
    paginationData.lastPage = 0

    initialSearchValues.title = ''
    initialSearchValues.code = ''
    initialSearchValues.page = 1

  }

  function handleNextPrevPage(value: any) {
    if (value == 'prev' && initialSearchValues.page > 1) {
      initialSearchValues.page--;
      getFormList();
    }
    if (value == 'next' && initialSearchValues.page < lastPage) {
      initialSearchValues.page++;
      getFormList();
    }
  }







  function getFormList() {

    setLoading(true);

    const get = defaultPaginationList('company/requeriment/tokens', initialSearchValues)
    get.then((res) => {

      paginationData.from = res.data.data.from
      paginationData.to = res.data.data.to
      paginationData.total = res.data.data.total
      paginationData.lastPage = res.data.data.last_page

      setLastPage(res.data.data.last_page);
      setPrescriptions(res.data.data.data);

    }).catch((error) => alert(error))
      .finally(() => setLoading(false));
  }

  function handleSetNewToken() {
    formik.resetForm()
    setNewToken(true)
  }

  return (
    <div>
      <Modal
        open={props.showModal}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div className='px-5 d-flex flex-row justify-content-between align-items-center w-100'>

            <div className='d-flex fw-bold h-60px flex-row align-items-center border-1 border-bottom border-dark w-100'>
              <div className="modal-title fw-bold fs-3" style={{ marginRight: '6px' }}>{intl.formatMessage({ id: 'holders requests' })} </div>
              <div className="badge badge-info">{intl.formatMessage({ id: formik.getFieldProps('status').value })}</div>

            </div>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary"
              onClick={() => [props.onClose(), onClear()]}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>


          <div className='px-6 py-8'>

            {loading2 ?
              <Load />
              :
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}


              >




                <div className='fv-row mb-3'>

                  <input
                    disabled
                    type='hidden'
                    autoComplete='off'
                    {...formik.getFieldProps('id')}
                    className='form-control form-control-lg form-control-solid'
                  />
                </div>

                <input
                  disabled
                  placeholder={intl.formatMessage({ id: 'Requester' })}
                  type='hidden'
                  autoComplete='off'
                  {...formik.getFieldProps('uuid')}
                  className='form-control form-control-lg form-control-solid'
                />

                <div className='scroll-y pe-5 h-250px '>








                  {/* requester_name */}
                  <div className='row mb-3'>

                    <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'Requester' })}</label>

                    <div className='col-sm-6'>
                      <input
                        disabled
                        placeholder={intl.formatMessage({ id: 'Requester' })}
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('requester_name')}
                        className='form-control form-control-lg form-control-solid'
                      />
                    </div>
                    {formik.getFieldProps('requester_mail').value != 'Anônimo' &&
                      <div className='col-sm-6'>
                        <input
                          disabled
                          placeholder='requester_mail'
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('requester_mail')}
                          className='form-control form-control-lg form-control-solid'
                        />
                      </div>
                    }

                    {/* <div className='fv-plugins-message-container text-gray fs-6 mt-1'>
                    <div className='px-6'>
                      <span role='info'>E-mail: {formik.getFieldProps('requester_mail').value ? formik.getFieldProps('requester_mail').value : '- - -'}</span>
                    </div>
                  </div> */}

                  </div>

                  {/* application */}
                  <div className='mb-2 fv-row' data-kt-password-meter='true'>
                    <div className='mb-1'>
                      <label className='form-label fw-bold text-dark fs-6 '>{intl.formatMessage({ id: 'Application' })}</label>
                      <div className='position-relative mb-3'>
                        <div className='d-flex justify-content-between w-100'>
                          <textarea
                            disabled
                            placeholder={intl.formatMessage({ id: 'Application' })}
                            autoComplete='off'
                            {...formik.getFieldProps('application')}
                            className='form-control form-control-lg form-control-solid w-100 me-1'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {questions && Array.isArray(questions) && (
                    questions.map((questionItem, index) => (
                      questionItem && questionItem.questions && questionItem.answers && (
                        <div key={index} className='mb-2 fv-row' data-kt-password-meter='true'>
                          <div className='mb-1'>
                            <label className='form-label fw-bold text-dark fs-6 '>{questionItem.questions}</label>
                            <div className='position-relative mb-3'>
                              <div className='d-flex justify-content-between w-100'>
                                <input
                                  disabled
                                  type='text'
                                  autoComplete='off'
                                  value={questionItem.answers}
                                  className='form-control form-control-lg form-control-solid w-100 me-1'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ))
                  )}



                  {/* rights */}
                  {formik.getFieldProps('requester_mail').value != 'Anônimo' &&
                    <div className='mb-2 fv-row' data-kt-passwor-meter='true'>
                      <div className='mb-1'>
                        <label className='form-label fw-bold text-dark fs-6 '>{intl.formatMessage({ id: 'Rights' })}</label>
                        <div className='position-relative mb-3'>
                          <div className='d-flex justify-content-between w-100'>
                            <select
                              disabled
                              className='form-select form-control form-control-solid fs-6'
                              {...formik.getFieldProps('rights')}
                            >
                              <option value=''>Anonimo</option>
                              <option value='Access'>{intl.formatMessage({ id: 'Access' })}</option>
                              <option value='Cancellation'>{intl.formatMessage({ id: 'Cancellation' })}</option>
                              <option value='Rectification'>{intl.formatMessage({ id: 'Rectification' })}</option>
                              <option value='Opposition'>{intl.formatMessage({ id: 'Opposition' })}</option>
                              <option value='Automated decision review'>{intl.formatMessage({ id: 'Automated decision review' })}</option>
                              <option value='Portability'>{intl.formatMessage({ id: 'Portability' })}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  <div className='row'>

                    <div className='col-sm-4'>
                      <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'Request in' })}</label>
                      <input
                        disabled
                        type='text'
                        autoComplete='off'
                        placeholder='- - -'
                        {...formik.getFieldProps('request_at')}
                        className='form-control form-control-lg form-control-solid'
                      />
                    </div>

                    <div className='col-sm-4'>
                      <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'Deadline' })}</label>
                      <input
                        disabled
                        type='text'
                        autoComplete='off'
                        placeholder='- - -'
                        {...formik.getFieldProps('deadline')}
                        className='form-control form-control-lg form-control-solid'
                      />
                    </div>

                    <div className='col-sm-4'>
                      <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'Reply in' })}</label>
                      <input
                        disabled
                        type='text'
                        autoComplete='off'
                        placeholder='- - -'
                        {...formik.getFieldProps('reply_at')}
                        className='form-control form-control-lg form-control-solid'
                      />
                    </div>




                  </div>

                  <hr />







                  {/* RESPONDIDO PELO USUARIO */}
                  <div className='fv-row mb-3'>





                    {
                      !formik.getFieldProps('user_name').value ?
                        <div className='form-check form-check-custom form-check-solid mb-4'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='kt_login_toc_agree'
                            {...formik.getFieldProps('accepted')}
                          />
                          <label className='form-check-label fw-bold text-gray-700 fs-6' htmlFor='kt_login_toc_agree' >
                            {intl.formatMessage({ id: 'I will proceed' })}
                          </label>
                        </div>
                        :
                        <div>
                          <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'User' })}</label>
                          <input
                            disabled
                            placeholder={intl.formatMessage({ id: 'User' })}
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('user_name')}
                            className='form-control form-control-lg form-control-solid'
                          />
                        </div>
                    }





                  </div>

                  {formik.getFieldProps('accepted').value || formik.getFieldProps('user_name').value ?

                    <div>

                      {/* Status */}
                      {formik.getFieldProps('user_name').value ?
                        <div className='mb-2 fv-row' data-kt-password-meter='true'>
                          <div className='mb-1'>
                            <label className='form-label fw-bold text-dark fs-6 '>{intl.formatMessage({ id: 'Status' })}</label>
                            <div className='position-relative mb-3'>
                              <div className='d-flex justify-content-between w-100'>
                                <select
                                  disabled={loading || formik.getFieldProps('reply_at').value}
                                  className='form-select form-control form-control-solid fs-6 mt-2'
                                  {...formik.getFieldProps('status')}
                                >
                                  <option value='Pending'>{intl.formatMessage({ id: 'Pending' })}</option>
                                  <option value='Checking'>{intl.formatMessage({ id: 'Checking' })}</option>
                                  <option value='Canceled'>{intl.formatMessage({ id: 'Canceled' })}</option>
                                  <option value='Done'>{intl.formatMessage({ id: 'Done' })}</option>
                                  <option value='Out of date' disabled>{intl.formatMessage({ id: 'Out of date' })}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        null
                      }


                      {/* response */}
                      <div className='mb-2 fv-row' data-kt-password-meter='true'>
                        <div className='mb-1'>
                          <label className='form-label fw-bold text-dark fs-6 '>{intl.formatMessage({ id: 'Response' })}</label>
                          <div className='position-relative mb-3'>
                            <div className='d-flex justify-content-between w-100'>
                              <textarea
                                disabled={loading || formik.getFieldProps('reply_at').value}
                                placeholder={intl.formatMessage({ id: 'Response' })}
                                autoComplete='off'
                                {...formik.getFieldProps('response')}
                                className='form-control form-control-lg form-control-solid w-100 me-1'
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* description */}
                      <div className='mb-2 fv-row' data-kt-password-meter='true'>
                        <div className='mb-1'>
                          <label className='form-label fw-bold text-dark fs-6 '>{intl.formatMessage({ id: 'Description' })}</label>
                          <div className='position-relative mb-3'>
                            <div className='d-flex justify-content-between w-100'>
                              <textarea
                                disabled={loading || formik.getFieldProps('reply_at').value}
                                placeholder={intl.formatMessage({ id: 'Description' })}
                                autoComplete='off'
                                {...formik.getFieldProps('description')}
                                className='form-control form-control-lg form-control-solid w-100 me-1'
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* observation */}
                      <div className='mb-2 fv-row' data-kt-password-meter='true'>
                        <div className='mb-1'>
                          <label className='form-label fw-bold text-dark fs-6 '>{intl.formatMessage({ id: 'Observation' })}</label>
                          <div className='position-relative mb-3'>
                            <div className='d-flex justify-content-between w-100'>
                              <textarea
                                disabled={loading || formik.getFieldProps('reply_at').value}
                                placeholder={intl.formatMessage({ id: 'Observation' })}
                                autoComplete='off'
                                {...formik.getFieldProps('observation')}
                                className='form-control form-control-lg form-control-solid w-100 me-1'
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    :
                    null
                  }


                </div>




                <div className='text-center row'>

                  <div className='text-center col-12 mt-12 d-flex'>
                    <button
                      type='button'
                      className='btn bg-danger m-2 w-100 text-white'
                      //  disabled={!formik.values.acceptTerms}

                      onClick={() => props.onClose()}
                    >
                      <span className='indicator-label'>{intl.formatMessage({ id: 'Close' })}</span>
                    </button>


                    {formik.getFieldProps('accepted').value || formik.getFieldProps('user_name').value && !formik.getFieldProps('reply_at').value ?
                      <button
                        type='submit'
                        className='btn bg-primary text-dark m-2 w-100'
                      >
                        {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Save' })}</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      :
                      null
                    }
                  </div>


                </div>
              </form>
            }

          </div>












        </Box>
      </Modal>

      <Modal
        open={openModalConfirm}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>

          <div className='container mt-5 p-5 text-center '>
            <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> {intl.formatMessage({ id: 'Requirement' })}</h1>
            <p className='w-90 mx-auto fs-4 mt-5'>
              {/* { responseMessage } */}
              {formik.getFieldProps('requester_mail').value == 'Anônimo' ?
                <span className='text-capitalize'>{intl.formatMessage({ id: 'do you want to proceed?' })}</span>
                :
                <span>
                  {intl.formatMessage({ id: 'A response email will be sent to:' })} {formik.getFieldProps('requester_mail').value}, {intl.formatMessage({ id: 'do you want to proceed?' })}

                </span>
              }
            </p>

          </div>

          <div className='row p-4'>

            <div className='col-sm-6'>
              <button
                onClick={() => [setOpenModalConfirm(false)]}
                className='btn btn-sm btn-danger w-100'>
                {intl.formatMessage({ id: 'No' })}
              </button>
            </div>

            <div className='col-sm-6'>
              <button
                onClick={() => [handleConfirmResponse()]}
                className='btn btn-sm btn-success w-100'>
                {intl.formatMessage({ id: 'Yes' })}
              </button>
            </div>

          </div>

        </Box>
      </Modal>


    </div>
  )
}
export default ModalHolderRequest;