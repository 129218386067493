import { useEffect, useState } from 'react';
import { KTSVG } from '../../../../helpers';
import { sendMailPrefConsent } from '../../../../../app/modules/helpers/Api';
import { trataRespota } from '../../../../../app/modules/helpers/helpers';
import { toast } from 'react-toastify';
import { Load } from '../../spinner/Spinner';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import clsx from 'clsx';
import * as Yup from 'yup';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 950,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IModalSendMailConsentProps {
  showModal: boolean;
  consentData: any;
  onClose: () => void;
}

const ModalSendMailConsent: React.FC<IModalSendMailConsentProps> = ({ ...props }) => {

  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const initialValues = {
    name: '',
    email: ''
  }

  const registrationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Mínimo 3 letras')
      .required(`${intl.formatMessage({ id: 'Name' })} ${intl.formatMessage({ id: 'is required' })}`),
    email: Yup.string()
      .email(`${intl.formatMessage({ id: 'O valor não é um endereço de e-mail válido' })}`)
      .max(50, `${intl.formatMessage({ id: 'Máximo de 50 símbolos' })}`)
      .required(`${intl.formatMessage({ id: 'E-mail' })} ${intl.formatMessage({ id: 'is required' })}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setOpenModalConfirm(true);
    },
  })

  function handleConfirmSendMail() {

    setLoading(true);
    setOpenModalConfirm(false);

    let post = sendMailPrefConsent({
      ref_uuid: props.consentData.uuid,
      name: formik.getFieldProps('name').value,
      email: formik.getFieldProps('email').value,
    });

    post.then((res) => {

      if (res.data.status === 'success') {
        formik.resetForm()
        toast.success(res.data.message)
        props.onClose()
      }
      else if (res.data.status === 'error') {
        toast.error(res.data.message);
      }

    }).catch((error) => {
      trataRespota('error', error);
    }).finally(() => {
      setLoading(false)

    });
  }

  useEffect(() => {
    if (props.consentData) {
      formik.resetForm()
    }
    // eslint-disable-next-line
  }, [props.consentData]);

  return (
    <div>
      <Modal
        open={props.showModal}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div className='px-5 d-flex flex-row justify-content-between align-items-center w-100'>

            <div className='d-flex fw-bold h-60px flex-row align-items-center border-1 border-bottom border-dark w-100'>
              <div className="modal-title fw-bold fs-3" style={{ marginRight: '6px' }}>{intl.formatMessage({ id: 'Send' })} {props.consentData.title}</div>

              <span className='text-gray-800 fw-bold d-block fs-6  text-center'>
                {
                  props.consentData.status === 'active' ?
                    <span className='badge badge-light-success'>{intl.formatMessage({ id: 'Active' })}</span>
                    : props.consentData.status === 'inactive' ?
                      <span className='badge badge-light-danger'>{intl.formatMessage({ id: 'Inactive' })}</span>
                      : props.consentData.status === 'old' ?
                        <span className='badge badge-light-secondary'>{intl.formatMessage({ id: 'Old' })}</span>
                        : '- - -'
                }
              </span>

            </div>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary"
              onClick={() => [props.onClose()]}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>

          <div className='px-6 py-8'>

            {loading ?
              <Load />
              :
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}


              >

                <input
                  disabled
                  type='hidden'
                  autoComplete='off'
                  value={props.consentData.uuid}
                  className='form-control form-control-lg form-control-solid'
                />

                <div className='scroll-y pe-5 mb-12'>

                  {/* requester_name */}
                  <div className='row mb-3'>

                    <div className='col-12'>
                      <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'Name' })}</label>
                      <input
                        disabled={props.consentData.status === 'old' || loading}
                        placeholder={intl.formatMessage({ id: 'Name' })}
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('name')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid w-100 me-1',
                          {
                            'is-invalid': formik.touched.name && formik.errors.name,
                          },
                          {
                            'is-valid': formik.touched.name && !formik.errors.name,
                          }
                        )}
                      />
                    </div>
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}

                  </div>

                  <div className='row mb-3'>

                    <div className='col-12'>
                      <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'E-mail' })}</label>
                      <input
                        disabled={props.consentData.status === 'old' || loading}
                        placeholder={intl.formatMessage({ id: 'E-mail' })}
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('email')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid w-100 me-1',
                          {
                            'is-invalid': formik.touched.email && formik.errors.email,
                          },
                          {
                            'is-valid': formik.touched.email && !formik.errors.email,
                          }
                        )}
                      />
                    </div>
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}

                  </div>

                </div>

                <div className='text-center row'>

                  <div className='text-center col-12 d-flex'>

                    <button
                      disabled={props.consentData.status === 'old'}
                      type='submit'
                      className='btn bg-primary text-dark m-2 w-100'
                    >
                      {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Send' })}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>


                  </div>

                </div>
              </form>
            }

          </div>

        </Box>
      </Modal>

      {/* MODAL DE CONFIRMAÇÂO */}
      <Modal
        open={openModalConfirm}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>

          <div className='container mt-5 p-5 text-center '>
            {/* <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> {intl.formatMessage({ id: 'Requirement' })}</h1> */}
            <p className='w-90 mx-auto fs-4 mt-5'>
              {/* { responseMessage } */}
              Um e-email sera enviado para: <b>{formik.getFieldProps('email').value}</b> deseja continuar?
            </p>
          </div>

          <div className='row p-4'>

            <div className='col-sm-6'>
              <button
                onClick={() => [setOpenModalConfirm(false)]}
                className='btn btn-sm btn-danger w-100'>
                {intl.formatMessage({ id: 'No' })}
              </button>
            </div>

            <div className='col-sm-6'>
              <button
                onClick={() => [handleConfirmSendMail()]}
                className='btn btn-sm btn-success w-100'>
                {intl.formatMessage({ id: 'Yes' })}
              </button>
            </div>

          </div>

        </Box>
      </Modal>


    </div>
  )
}
export default ModalSendMailConsent;