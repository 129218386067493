import React from 'react';
import { converteData } from '../../../../../../app/modules/helpers/helpers';

interface IReportListAnswers {
	dataList: [],
}

const ReportListAnswers: React.FC<IReportListAnswers> = ({...props}) => {

	return(
		<div style={{textAlign: 'left'}} >
		{
			props.dataList.map((item: any, index: any)=>
				<>
				{ 
					
					item.answers.length>0?
					<div key={index} className='rounded text-dark text-primary  bg-light-primary border-1 border-dashed border-primary  mb-4'>
						
						
						<div className='bg-light-primary p-2 d-flex'> 
							
							<span className='bg-white border-dashed me-2 d-flex flex-row w-30px h-30px rounded border-1 border-primary align-items-center justify-content-center'><strong>{ (index+1)<10? ('0'+(index+1)) : (index+1) }</strong></span> 
							<span className={`mt-3 me-1 ${item.is_required?'required':''}`}/>
							<span className='mt-2'><strong className='' dangerouslySetInnerHTML={{ __html: `${item.question}`}}/></span>
							
							{/* <strong>({ (index+1)<10? ('0'+(index+1)) : (index+1) }) {item.question}</strong> */}
						</div>
						
						<hr className='bg-light-primary border-1 border-dashed border-primary mt-1'/>
						
						{
							<ul>
								{ item.answers.length>1 ?
									item.answers.map((item2: any, index2: any)=>
									<div key={index2} >
										
										<li>{item2.answer}</li>
										
									</div>
									)
									:
									<span>R: {item.answers[0].answer}</span>
								}
					
							</ul>
						}
					</div>
					:
					null
				}
				</>
			)
		}
		</div>
		
  )
}
export default ReportListAnswers;