import React, {FC, createContext, useContext} from 'react'
import { upLanguageUser } from '../../app/modules/helpers/Api'
import { toast } from 'react-toastify';

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  // selectedLang: 'de' | 'en' | 'es' | 'fr' | 'ja' | 'zh'
  selectedLang: 'pt' | 'de' | 'en' | 'es' | 'fr' | 'ja' | 'zh'
}
const initialState: Props = {
  selectedLang: 'pt',
}

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

// Side effect
export function setLanguage(lang: string) {
  const putLang = upLanguageUser({ language: lang });
  putLang.then((res)=>{
    toast.success(res.data.message);
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
    window.location.reload()
  }).catch((error)=>{
    toast.error(error.response.message);
  }).finally(()=> {
    // setLoading2(false)
  });
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const MetronicI18nProvider: FC = ({children}) => {
  const lang = getConfig()
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useLang}
