import react, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../../helpers';
import { useIntl } from 'react-intl'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IModalCalculatorTitleProps {
  showModal: boolean;
  title: string;
  onClose: () => void;
  onAction: (title: string) => void;
}

const ModalCalculatorTitle: React.FC<IModalCalculatorTitleProps> = ({...props}) => {

  const [title, setTitle] = useState('');
  const intl = useIntl();   

  function onClear(){
    setTitle('');
  }

  return(
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-70px d-flex flex-row justify-content-between align-items-center w-100'>
          <div className='d-flex flex-row align-items-center'>
              <div className="d-flex bg-light p-3 align-items-center justify-content-center rounded me-4">
                <KTSVG
                  path="/media/icons/duotune/art/art002.svg"
                  className="svg-icon svg-icon-2x svg-icon-primary"
                />
              </div>
              <span className="modal-title fw-bold fs-3">{props.title}</span>
          </div>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>[props.onClose(), onClear()]}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className='px-5 my-5 text-left' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 350}}>
          
          <div className='w-100'>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-lg-12 col-form-label fw-bold fs-5'>{intl.formatMessage({id: "Let's start? Enter a title for your calculator"})}</label>
                  <input
                    type="text"
                    className="form-control form-control-solid fs-5"
                    placeholder={intl.formatMessage({id: "Title"})}
                    value={title}
                    onChange={e=>setTitle(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className='d-flex flex-row w-100 justify-content-end p-5'>
          <button disabled={title === ''? true : false} type='button' onClick={()=>[props.onAction(title), onClear()]} className='btn btn-md btn-primary text-dark'>
          {intl.formatMessage({id: "Continue"})}
          </button>
        </div>
      </Box>
    </Modal>
  )
}
export default ModalCalculatorTitle;