/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {register} from '../redux/AuthCRUD'
import {Link} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { toast } from 'react-toastify'
import { getResourceLink} from '../../../../app/modules/helpers/Api';
import { trataRespota } from '../../helpers/helpers'
import { useIntl } from 'react-intl'

// import { mask } from '../../helpers/helpers'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '', 
  password: '',
  changepassword: '',
  role: 'free',
  accepted: 1,
  community: "0",
  acceptTerms: false,
  token: null
}

function handleGetResource(hash: any) {
  const get = getResourceLink(hash);
  // get.then((res)=>{

  //   let myBlob = new Blob([res.data], {
  //     type: "application/pdf",
  //   });

  //   const fileURL = URL.createObjectURL(myBlob);
  //   window.open(fileURL);
  // })
}

export function Registration() {
  const intl = useIntl();   

  const [loading, setLoading] = useState(false)
  // const [role, setRole] = useState('');
  // const [errorRole, setErrorRole] = useState('');
  // const [document, setDocument] = useState('');
  // const [errorDocument, setErrorDocument] = useState('');
  const [seePassword, setSeePassword] = useState('password');
  
  const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
      .required(`${intl.formatMessage({id: 'O primeiro nome é requerido'})}`),
    email: Yup.string()
      .email(`${intl.formatMessage({id: 'O valor não é um endereço de e-mail válido'})}`)
      .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
      .required(`${intl.formatMessage({id: 'O e-mail é requerido'})}`),
    lastname: Yup.string()
      .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
      .required(`${intl.formatMessage({id: 'O sobrenome é requerido'})}`),
    password: Yup.string()
      .min(3, `${intl.formatMessage({id: 'Mínimo de 6 símbolos'})}`)
      .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%^&*])(?=.{6,})/,
        `${intl.formatMessage({id: 'Use 6 ou mais caracteres com uma mistura de letras, números e símbolos'})}`
      )
      .required(`${intl.formatMessage({id: 'A senha é requerida'})}`),
    changepassword: Yup.string()
      .required(`${intl.formatMessage({id: 'A confirmação da senha é necessária'})}`)
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], `${intl.formatMessage({id: 'Senha e Confirmar senha não coincidem'})}`),
      }),
    acceptTerms: Yup.bool().required(`${intl.formatMessage({id: 'Você deve aceitar os Termos e Condições'})}`),
  })
  const dispatch = useDispatch()
  
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      var error = false;

        // if(role === ''){setErrorRole('Role is required'); error = true}
        // if(document === ''){setErrorDocument('Document is required'); error = true}
        if(!error){
          register(
            values.email, 
            values.firstname, 
            values.lastname, 
            values.password, 
            values.changepassword, 
            values.acceptTerms, 
            'free',
            1,
            0,
            null
          )
          .then((res) => {
            if(res.data.status === 'success'){
              toast.success(res.data.message);
              
              // login(values.email, values.password)
              // .then(({data: {access_token}}) => {
              //   setLoading(false);kt_login_signup_form_cancel_button
              //   dispatch(auth.actions.login(access_token))
              // })
              window.location.href = "/auth/login"

            }
            if(res.data.status === 'error'){
              toast.error(res.data.message);
            }
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            trataRespota('error', error); 
            // if(error.response){
            //   toast.error(error.response.data.message);
            // } else {
            //   toast.error('Sorry, an error occurred!');
            // }
          }).finally(()=>setLoading(false));
        }
    },
  })

  function cancelRegister() {    
    window.location.href = "/auth/login"
  }

  // function addMask(e: any){
  //   setDocument(mask(e));
  // }

  return (
    <div className='d-flex flex-column bg-gray-middle flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/logos/background.png')})`,
        backgroundPosition: 'center',
        backgroundColor: '#1E1E2D',
        backgroundSize: 'cover' 
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20'>
        {/* <div className='mb-5'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/breven_law_color_logo.svg')} className='h-95px' />
        </div> */}
          <div className='w-lg-600px bg-white rounded-5 shadow-sm p-10 p-lg-15 mx-auto'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
            >
            <div className='mb-10 text-center'>
              <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'Criar uma conta'})}</h1>
              <div className='text-dark fw-bold fs-4'>
              {intl.formatMessage({id: 'já tem uma conta?'})}
                <Link to='/auth/login' className='link-primary fw-bold text-hover-secondary' style={{marginLeft: '5px'}}>
                {intl.formatMessage({id: 'Faça login aqui?'})}
                </Link>
              </div>
            </div>

            <div className='d-flex align-items-center mb-10'>
              <div className='border-bottom border-gray-300 mw-50 w-100'></div>
              <span className='fw-bold text-gray-400 fs-7 mx-2'>{intl.formatMessage({id: 'OU'})}</span>
              <div className='border-bottom border-gray-300 mw-50 w-100'></div>
            </div>
            
            {/* {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )} */}
            
            <div className='row fv-row mb-2'>
              <div className='col-xl-6'>
                <div className='fv-row mb-3'>
                  <label className='class="form-label fw-bold text-dark fs-5 mb-1 required'>{intl.formatMessage({id: 'Primeiro nome'})}</label>
                  <input
                    placeholder={intl.formatMessage({id: 'Primeiro nome'})}
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('firstname')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.firstname && formik.errors.firstname,
                      },
                      {
                        'is-valid': formik.touched.firstname && !formik.errors.firstname,
                      }
                    )}
                  />
                  {formik.touched.firstname && formik.errors.firstname && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.firstname}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>


              <div className='col-xl-6'>
                <div className='fv-row mb-3'>
                  <label className='class="form-label fw-bold text-dark fs-5 required mb-1'>{intl.formatMessage({id: 'Sobrenome'})}</label>
                  <input
                    placeholder={intl.formatMessage({id: 'Sobrenome'})}
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('lastname')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.lastname && formik.errors.lastname,
                      },
                      {
                        'is-valid': formik.touched.lastname && !formik.errors.lastname,
                      }
                    )}
                  />
                  {formik.touched.lastname && formik.errors.lastname && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.lastname}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>


            <div className='fv-row mb-3'>
              <label className='form-label fw-bold text-dark fs-5 required'>{intl.formatMessage({id: 'E-mail'})}</label>
              <input
                placeholder={intl.formatMessage({id: 'E-mail'})}
                type='email'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>


            {/* <div className='fv-row my-4'>
              <label className='form-label fw-bold text-dark fs-5 required'>Role</label>
              <div className='mt-3'>
                <label className='form-check form-check-custom form-check-solid align-items-center'>
                  <input
                    type='radio'
                    name='role'
                    className='form-check-input me-3'
                    onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                  />
                  <span className='form-check-label d-flex flex-column align-items-start'>
                    <span className='fw-bolder text-gray-800 fs-6 mb-0'>DPO</span>
                    <span className='text-gray-600 fs-6 text-justify'>
                      In charge of processing personal data of a company, and that does not fit the company's staff.
                    </span>
                  </span>
                </label>
                <div className='separator separator-dashed my-6'></div>
              </div>
              <div className='mt-3'>
                <label className='form-check form-check-custom form-check-solid align-items-center'>
                  <input
                    type='radio'
                    name='role'
                    className='form-check-input me-3'
                    onChange={()=>[setRole('company'), setErrorRole('')]}
                  />
                  <span className='form-check-label d-flex flex-column align-items-start'>
                    <span className='fw-bolder text-gray-800 fs-6 mb-0'>Company</span>
                    <span className='text-gray-600 fs-6 text-justify'>
                      An organization composed of people whose objective is to carry out an economic activity seeking commercial results through the production and/or sale of goods and services.
                    </span>
                  </span>
                </label>
                <div className='separator separator-dashed my-6'></div>
              </div>
              <div className='mt-3'>
                <label className='form-check form-check-custom form-check-solid align-items-center'>
                  <input
                    type='radio'
                    name='role'
                    className='form-check-input me-3'
                    onChange={()=>[setRole('freelancer'), setErrorRole('')]}
                  />
                  <span className='form-check-label d-flex flex-column align-items-start'>
                    <span className='fw-bolder text-gray-800 fs-6 mb-0'>Freelancer</span>
                    <span className='text-gray-600 fs-6 text-justify'>
                      They are professionals who carry out their activity on their own without an employment relationship with anyone, but they must be registered with a professional council body.
                    </span>
                  </span>
                </label>
                <div className='separator separator-dashed mt-6 mb-1'></div>
              </div>
              {errorRole && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errorRole}</span>
                  </div>
                </div>
              )}
            </div>
                
            <div className='fv-row mb-3'>
              <label className='form-label fw-bold text-dark fs-5 required'>Document (CPF/CNPJ)</label>
              <input
                placeholder='CPF/CNPJ'
                type='text'
                autoComplete='off'
                maxLength={18}
                onChange={e=>[addMask(e.currentTarget.value), setErrorDocument('')]}                
                value={document}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': errorDocument},
                  {
                    'is-valid': document.length >= 14,
                  }
                )}
              />
              {errorDocument && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errorDocument}</span>
                  </div>
                </div>
              )}
            </div> */}

            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Senha'})}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <input
                      type={seePassword}
                      placeholder={intl.formatMessage({id: 'Senha'})}
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-90 me-1',
                        {
                          'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {
                      seePassword === 'password'?
                      <span onClick={()=>setSeePassword('text')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                        <i className="bi bi-eye-slash-fill fs-2x"></i>
                      </span>
                      :
                      <span onClick={()=>setSeePassword('password')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                        <i className="bi bi-eye-fill fs-2x"></i>
                      </span>
                    }
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='fv-row mb-5'>
              <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Confirme a Senha'})}</label>
              <input
                type='password'
                placeholder={intl.formatMessage({id: 'Confirme a Senha'})}
                autoComplete='off'
                {...formik.getFieldProps('changepassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                  },
                  {
                    'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                  }
                )}
              />
              {formik.touched.changepassword && formik.errors.changepassword && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.changepassword}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-10'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='kt_login_toc_agree'
                  {...formik.getFieldProps('acceptTerms')}
                />
                <label
                  className='form-check-label fw-bold text-gray-700 fs-6'
                  // htmlFor='kt_login_toc_agree'
                >
                  {intl.formatMessage({id: 'Eu concordo com os'})}{' '}
                  <a onClick={()=> handleGetResource('user_term')}  className='ms-1 link-primary'>
                  {intl.formatMessage({id: 'termos e condições'})}
                  </a>
                  
                </label>
                {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.acceptTerms}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='text-center row'>
              <div className='col-6'>
            
                  <button
                    type='button'
                    id='kt_login_signup_form_cancel_button'
                    className='btn btn-lg btn-light-danger w-100 mb-5'
                    disabled={loading}
                    onClick={() => cancelRegister()}
                    style={{border: '1px solid #d9214e', width: '168px', height: '48px', borderRadius: 13}} >
                    {intl.formatMessage({id: 'Cancelar'})}
                  </button>
            
              </div>
              <div className='text-center col-6'>
                <button
                 type='submit'
                 id='kt_sign_up_submit'
                 className='btn bg-hover-dark mb-5 w-100 text-white'
                 disabled={!formik.values.acceptTerms || loading}
                 style={{width: '168px', height: '48px', background: '#65638E', borderRadius: 13}}

                >
                  {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Enviar'})}</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>

              {/* <button
                
              >
                {!loading && <span className='indicator-label '>{intl.formatMessage({id: 'Enviar'})}</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'Por favor, espere'})}...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
