/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { getActionPlansById, defaultPaginationList } from '../../../../../app/modules/helpers/Api';
import { Load } from '../../spinner/Spinner';
import Warning from '../../warning/Warning';
import Pagination from '../../../../helpers/components/CustomPagination/CustomPagination'
import { Box, Modal } from '@mui/material';
import { KTSVG } from '../../../../helpers';
import ActionPlanActionList from './ActionsPlansCards/ActionPlanActionList';
import ActionPlanActionCreate from './ActionsPlansCards/ActionPlanActionCreate';
import ActionPlanButtonCreate from './ActionsPlansCards/ActionPlanButtonCreate';
import ActionPlanGraphModal from './ActionsPlansCards/ActionPlanGraphModal';
import { useIntl } from 'react-intl'


const style = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 3,
  boxShadow: 24,
  outline: 'none'
};
const style2 = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 850,
  maxHeight: 500,
  bgcolor: 'white',
  border: 'none',
  borderRadius: 3,
  boxShadow: 24,
  outline: 'none'
};

let searchData = {
  title: '',
  code: '',
  page: 1,
  perpage: 10
}

type Props = {
  
  id?: any;
  idLocation?: any;
  viewDetail: (action: boolean, id?: any, form_id?: any) => void;  
}

const ActionsPlansList: React.FC<Props> = ({...props}) => {

  const [actionsPlansData, setActionsPlansData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [titleFilter, setTitleFilter ]= useState<any>('');
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [actionPlanData, setActionPlanData] = useState<any>([]);
  const [actionData, setActionData] = useState<any>([]);
  const [graphData, setGraphData] = useState<any>([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [createAction, setCreateAction] = useState(false);
  const [editActionPlanTitle, setEditActionPlanTitle] = useState(false);
  const [idAction, setIdAction] = useState <any> ();
  const [openModalChart, setOpenModalChart] = useState (false);
  const [openModalNumber, setOpenModalNumber] = useState(0);
  const intl = useIntl();   

  searchData.title = titleFilter

  function handleLoadScreen(action: boolean){
    setLoadingScreen(action);
  }
  useEffect(()=>{
    handleGetList(null)
  },[loading2]);

  function handleGetList(data:null) {
    setLoading(true);
    const get = defaultPaginationList('actionplans', searchData)
    get.then((res)=>{
      setActionsPlansData(res.data.data);
    }).catch((error)=>{
      toast.error(error.response.data.message)
    })
    .finally(()=>[setLoading(false), setLoading2]);
  }
  
  function handleGetListModalDetails(){
    setLoading(true);

    setOpenModalDelete(false);
    setCreateAction(false);
    setEditActionPlanTitle(false);

    const get = getActionPlansById(props.idLocation? props.idLocation : idAction);
    get.then((res)=>{
      setActionPlanData(res.data.data);
      setActionData(res.data.data.actions);
      setGraphData(res.data.data.action_progress_details.graph.map((item: any)=> item));
      if(openModalNumber === 1){
        setOpenModalDetails(true)
      }if(openModalNumber === 2){
        setOpenModalChart(true)
      }
    }).catch((error)=>{
      toast.error(error.response.message);
    }).finally(()=>[
      setLoading(false), 
      setLoadingScreen(false),  
      
    ]);
}


  return (
    <>
      <div>
        <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: 'Action Plans'})}</span>
        </h3>
        <p className='fw-bolder text-gray-500 fs-6'>{intl.formatMessage({id: 'Home - Action Plans'})}</p>
      </div>
      {
        loading?
          <Load/>
        :
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header pt-5 row d-flex justify-content-between'>
            

                <div className='col-md-3'>
                  <input
                    style={{height: '40px'}}
                    type="text"
                    className="form-control form-control-solid fs-6 rounded-3 "
                    placeholder={intl.formatMessage({id: 'Search a form'})}
                    autoComplete='off'
                    value={titleFilter}
                    onChange={(e)=>[setTitleFilter(e.target.value)]}
                    onKeyDown={(e)=>e.which === 13 ? handleGetList(null) : '' }
                  />
                </div>
                <div className='col-md-1' style={{marginRight: window.screen.width > 767 ? '70px' : '0px'}}>
                    <button 
                    type='button' 
                    className='btn bg-hover-dark mb-5 text-white ' 
                    disabled={loading}
                    onClick={()=>handleGetList(null)}
                    style={{width: '125px',height: '40px', background: '#65638E', borderRadius: 13}}
                    >
                      {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Search'})}</span>}
                        {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                       )}      
                    </button>
                </div>  
            
          </div>

         
          <div className='card-body py-3'>
            {
              actionsPlansData.length !== 0?
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bolder text-gray-500'>
                        <th className='min-w-150px'>{intl.formatMessage({id: 'ACTION PLAN TITLE'})}</th>
                        <th className='min-w-140px'>{intl.formatMessage({id: 'COMPANY'})}</th>
                        <th className='min-w-120px'>{intl.formatMessage({id: 'SECTOR'})}</th>
                        <th className='min-w-100px'>{intl.formatMessage({id: 'USER'})}</th>
                       
                        <th className='min-w-100px'>{intl.formatMessage({ id: 'ACTIONS' })}</th>
                        {/* <th className='min-w-100px'>DATE</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        actionsPlansData?.data?.map((item: any, index: any)=>
                          <tr key={index} onMouseMove={()=>[setIdAction(item.id)]}>
                            
                            <td style={{borderBottom: '1px dashed #E4E6EF'}}>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <span className='text-gray-700 fw-bold fs-6'>{item.title}</span>
                                  <span className='text-gray-600 fw-bold fs-7'>{item.form_name}</span>
                                </div>
                              </div>                              
                            </td>                           
                            <td style={{borderBottom: '1px dashed #E4E6EF'}}>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <span className='text-gray-700 fw-bold fs-6'>
                                    {item.fantasy_name ? item.fantasy_name : ' - - - '}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td style={{borderBottom: '1px dashed #E4E6EF'}}>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <span className='text-gray-700 fw-bold fs-6'>
                                    {item.sector_name ? item.sector_name : ' - - - '}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td style={{borderBottom: '1px dashed #E4E6EF'}}>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <span className='text-gray-700 fw-bold fs-6'>
                                    {item.user_full_name}
                                  </span>
                                </div>
                              </div>
                            </td>
                                                      
                            <td className='w-100 d-flex '>                       
                            
                              <button className="btn btn-icon btn-sm btn-active-light-success me-2"
                                title={intl.formatMessage({id: 'VIEW ACTIONS'})}
                                onMouseMove={()=>[setOpenModalNumber(1)]}
                                onClick={()=>[
                                props.viewDetail(false, item.id, item.form_id),
                                setIdAction(item.id),
                                setLoading(true),
                                setOpenModalNumber(1),
                                setTimeout(() => {
                                  handleGetListModalDetails()                                  
                                }, 200)]}>
                                <i className="fas fa-external-link-alt"></i>
                              </button>

                              <button className="btn btn-icon btn-sm btn-active-light-success me-2"
                                title={intl.formatMessage({id: 'PROGRESS CHART'})}
                                onMouseMove={()=>[setOpenModalNumber(2)]}
                                onClick={()=>[
                                  setLoading(true),
                                  setOpenModalNumber(2),
                                  setTimeout(() => {
                                    handleGetListModalDetails()
                                  }, 200)
                                ]}
                                >
                                <KTSVG path="/media/icons/duotune/arrows/arr070.svg" className="svg-icon svg-icon-2x " />
                              </button>

                              <button className="btn btn-icon btn-sm btn-active-light-success"
                                title={intl.formatMessage({id: 'ViEW DETAILS'})}
                                onClick={()=>props.viewDetail(true, item.id, item.form_id)}                                     
                                >
                                <i className="fas fa-eye "></i>
                              </button>

                            </td>
                          
                          </tr>
                        )
                      }
                    </tbody>
                  </table><Pagination data={actionsPlansData} onSearch={handleGetList} searchData={searchData}/>
                </div>
              :
              <Warning message={intl.formatMessage({id: 'No action plans to view yet'})}  />
            }
          </div>
        </div>
      }
      <Modal
          open={openModalDetails}
          onClose={()=>{}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >

            <Box sx={style}>
                
                <div className='d-flex justify-content-end p-2'>
                    
                    <div
                    className="btn btn-icon btn-sm btn-active-light-primary "
                    onClick={()=>[setOpenModalDetails(false)]}
                    >
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                    />
                    </div>
                </div>
                <div >
                            
                            <div style={{overflow: 'scroll', overflowX: 'hidden', maxHeight: 350}}>
                            {
                            actionData.length === 0?
                            <></>
                            :
                            <>
                                {
                                
                                  <div className='w-95 mx-auto pb-5'>
                                     <p className='fw-bolder fs-4 m-0'>{intl.formatMessage({id: 'Actions'})}</p>
                                     <strong className='fs-7 m-0 text-gray-600'>{intl.formatMessage({id: 'It will only be possible to edit/delete where the final data will be less than or equal to the current data'})}</strong>
              
                                  </div>
                                
                                }
                                {
                                loading ?
                                <Load/>
                                :
                                <>
                                  {
                                  actionData.map((item: any, index: any)=>{
                                  
                                      return(
                                      
                                        <ActionPlanActionList
                                          onReload={handleGetListModalDetails}
                                          key={index}
                                          index={index}
                                          action={item}
                                          onFinish={handleLoadScreen}/>
                                      
                                      
                                      )
                                  })
                                  }
                                </>
                                }
                            </>
                            }
                            </div>
                          
                            
                              {
                                createAction?
                                
                                
                                
                                  <div 
                                      className='fixed-top bg-white rounded-5'
                                      style={{height: '400px'}}                                                             
                                    >
                                    <ActionPlanActionCreate
                                    onReload={handleGetListModalDetails}
                                    model={actionPlanData.prescription_answered?.model}
                                    onCancel={()=>setCreateAction(false)}
                                    idActionPlanItem={actionPlanData.id}
                                    form_id={actionPlanData.form_id}
                                    onFinish={handleLoadScreen}
                                  />
                                  </div>
                              
                                    
                                :
                                <div  className=" bg-white">
                                  <ActionPlanButtonCreate
                                    onClick={()=>setCreateAction(true)

                                    }
                                />
                                </div>
                                }
                            
                           
                    </div>
                    
                   
            </Box>
          </Modal>

          <Modal
            open={openModalChart}
            onClose={()=>{}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>

              <div className="p-5" tabIndex={-1} >
                <div className="modal-dialog modal-dialog-centered mw-900px bg-white" >
                  <div className="modal-content mh-600px" >

                    <div className="modal-header" >
                      <h5 className="">{intl.formatMessage({id: 'Progress Chart'})}</h5>
                      <div>
                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={()=>[setOpenModalChart(false)]}>
                          <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
                        </div>
                      </div>

                    </div>

                    <div className='container'>
                      {
                        graphData.length === 0?
                        <></>
                        :
                        <>
                          {
                            graphData.find((element: any) => element > 0)?
                            <div className='card card-body mt-5'>
                              <ActionPlanGraphModal
                                graph={graphData}
                                total={actionPlanData.action_progress_details.progress_result}
                              />
                            </div>
                            :
                            <><div className='container text-center p-5'><h2 className='text-gray-600'>{intl.formatMessage({id: 'There is no graph/data to show'})}</h2></div></>
                          }
                        </>
                      }                 
                    </div>
                    
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
      
    </>
  )
}

export {ActionsPlansList}
