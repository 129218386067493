import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../../helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import moment from 'moment';
import ModalMessage from '../../../modals/modalMessage';
import { toast } from 'react-toastify';
import { monitoringUpdate } from '../../../../../../app/modules/helpers/Api';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  maxHeight: 450,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

interface IActionPlanResourcesProps {
  showModal: boolean;
  title: string;
  action_id: any;
  id: any;
  action_plan_id: any;
  onClose: () => void;
  onAction: () => void;
}

const schema = Yup.object().shape({
  status: Yup.string().required('Status is required'),
  description: Yup.string().required('Description is required'),
});

const ActionPlanResourceEdition: React.FC<IActionPlanResourcesProps> = ({...props}) => {
  
  const [isLoading, setIsLoading] = useState(false);

  const data = [
    {id: '1', description: 'teste', date: '2022-06-30', status: '1'}
  ]

  const [date, setDate] = useState(new Date(moment(data[0].date).format('YYYY/MM/DD')));
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [dataValues, setDataValues] = useState<any>([]);

  const formik = useFormik({
    initialValues: {
      status: data[0].status,
      description:  data[0].description,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setIsLoading(true);
      setTimeout(() => {        
        setIsLoading(false);
        setDataValues(values);
        setOpenModalConfirm(true);
      }, 1000)
    }
  })

  const save = (values: any) => {
    const data = {
      action_id: props.action_id,
      action_plan_id: props.action_plan_id,
      status: values.status === '1'? 1 : 0,
      description: values.description,
      date: moment(date).format('YYYY-MM-DD')
    } 

    const send = monitoringUpdate(data, props.id);
    send.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        setOpenModalConfirm(false);
        props.onClose()
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      toast.error(error.response.data.message);
    })
  }

  return(
    <>
    <ModalMessage
      message='Are you sure you want to create this item?'
      title='Attention!'
      showModal={openModalConfirm}
      onClose={()=>setOpenModalConfirm(false)}
      onAction={()=>save(dataValues)}
    />
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
          <span className="modal-title fw-bold fs-3">{props.title}</span>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>props.onClose()}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className='px-5' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 350}}>

        <form onSubmit={formik.handleSubmit} id='form-action-plan-action' noValidate className='form w-100'>
          <div className='align-items-start w-100'>
            <div className='col-lg-12'>
              <div className='row'>

                <div className='col-lg-6 fv-row'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-5'>Date</label>
                  <DatePicker 
                    selected={date} 
                    dateFormat={'dd/MM/yyyy'}
                    minDate={moment().toDate()}
                    onChange={(date: Date) => setDate(date)} 
                    className='form-control form-control-md form-control-solid fs-4'
                    disabled={moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')}
                  />
                </div>

                <div className='col-lg-6 fv-row'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-5'>Status</label>
                  <select className='form-select form-select-solid form-select-lg fw-bold fs-4' 
                    {...formik.getFieldProps('status')}
                  >
                    <option value=''>... Select an status</option>
                    <option value='1'>Yes</option>
                    <option value='0'>No</option>
                  </select>
                  {formik.touched.status && formik.errors.status && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.status}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-12 fv-row'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-5'>Description</label>
                  <textarea
                    rows={5}
                    className="form-control form-control-solid fs-5"
                    placeholder="Description"
                    {...formik.getFieldProps('description')}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.description}</div>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
        </form>

        </div>
        <div className='d-flex flex-row w-100 justify-content-end p-5'>
          <button type='button' onClick={()=>props.onClose()} title='Cancel edition' form='form-action-plan-action' className='btn me-2 btn-sm btn-secondary' disabled={isLoading}>
            Cancel
          </button>
          <button type='submit' title='Save changes' form='form-action-plan-action' className='btn btn-sm btn-primary' disabled={isLoading}>
            {!isLoading && 'Save'}
            {isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Processando...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </Box>
    </Modal>
  </>
  )
}
export default ActionPlanResourceEdition;