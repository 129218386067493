import react, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../../helpers';
import { Load } from '../../../spinner/Spinner';
import ModalMessageConfirm from '../../../modals/modalMessageConfirm';
import { toast } from 'react-toastify';
import { saveAnswer } from '../../../../../../app/modules/helpers/Api';
import ModalReportGraph from '../../Answers/Report/ModalReportGraph';
import { useIntl } from 'react-intl'

import { Formik, Form, Field, FieldArray } from "formik";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IModalCalculatorQuestionsProps {
  showModal: boolean;
  title: string;
  question: any;
  origin: any;
  idForm: any;
  idSector: any;
  idCompany: any;
  model:any;
  onClose: () => void;
  onAction: () => void;
}

const ModalCalculatorQuestions: React.FC<IModalCalculatorQuestionsProps> = ({...props}) => {

  const [a, setA] = useState<any>([]);
	const [count, setCount] = useState(0);
	const [nextQuestion, setNextQuestion] = useState(1);
	const [question, setQuestion] = useState<any[]>([]);
	const [origin, setOrigin] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const [openModalReportGraph, setOpenModalReportGraph] = useState(false);
  const [dataDegreeId, setDataDegreeId] = useState('');
  const [endQuestions, setEndQuestions] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const intl = useIntl();   


  const [closeMapp, setCloseMapp] = useState(false);

  useEffect(()=>{
    setNextQuestion(1);
    setA([]);
    setCount(0);
    setOrigin([]);
    setQuestion([]);
    setLoading(true);
    setDataDegreeId('');
    setOrigin(props.origin);
    setQuestion(props.question);
    setTimeout(()=>{
      setLoading(false);
    }, 2000);
  },[props.question, props.origin])

  function formata(){

    // separa repetido por tempo de seleção, exceto questão mult
    var results: any[] = [];
    for (var i = 0; i < a.length - 1; i++) {
      if (a[i].type !== 'mult') {
        if (a[i + 1].question_id === a[i].question_id) {
          if (a[i + 1].date > a[i].date) {
            results.push(a[i]);
          }
        }
      }
    }

    // remove repetido 
    const sameValue = (a: any, b: any) => a === b;
    const checkValues = (left: any, right: any, compareFunction: any) => 
      left.filter((leftValue: any) => 
      !right.some((rightValue: any) => 
      compareFunction(leftValue, rightValue))
    );
    let arr = (checkValues(a, results, sameValue));

    // Apenas array com questão mult
    const lookup = arr.reduce((a:any, e:any) => {
      a[e.question_id] = ++a[e.question_id] || 0;
      return a;
    }, {});
    let arr1 = arr.filter((e:any) => lookup[e.question_id]);

    if(arr.length !== 0){
      
      // Separa id das respostas da questão mult
      let arr2 = [];
      let teste = {};
      for (let x=0; x<=arr1.length-1; x++){
        arr2.push(arr1[x].answer_id)
      }
      
      // Cria um objeto com a questão mult e seu array de respostas
      if(arr1[0]){
        teste = {question_id: arr1[0].question_id, answer_id: arr2 };
      }
      
      // Cria um array com todas as respostas e apenas um elemento com a questão mult, que será substituida pelo array de respostas depois
      const filteredArr = arr.reduce((acc: any, current: any) => {
        let x = acc.find((item: any) => item.question_id === current.question_id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      
      // Junta o array de respostas da questão mult com os demais
      const newArr = filteredArr.map((element: any) => {
        if (arr1[0]) {
          if (element.question_id === arr1[0].question_id) {
            return teste;
          }
        }
        return element;
      });

      return newArr;
      // return '';
    } else {
      return '';
    }
  }

  function send (){
    let x = formata();
    const data = {
      answers: x.length !== 0? x : a,
      company_id: props.idCompany.toString(),
      prescription: props.idForm.toString(),
      sector: props.idSector.toString(),
      title: props.title,
      close: closeMapp
    }

    setLoadingSend(true)

    const save = saveAnswer(data);
    save.then((res)=>{
      if(res.data.status === 'success'){
        if(res.data.report){
          setDataDegreeId(res.data.report.id);
          setEndQuestions(false)
          
        }
        toast.success(res.data.message);
        props.onClose();
        setOpenModalReportGraph(true);
        props.onAction();
        setOpenModalSubmit(false);
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.data.message);
      } else {
        toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}`);
      }
    }).finally(()=>{
      setLoadingSend(false);
      
    });
  }
  
  function next2(values:any, item:any) {

    if (item.required == 1 ) {
      let valid = true;
      if (item.type == 'mult') {
        if (values.values.resposta.length==0) {
          valid = false
        } 
      } else if (values.values.resposta == null || values.values.resposta == '') {
        valid = false
      } 
      if (!valid) {
        toast.error(`${intl.formatMessage({id: 'Mandatory answer'})}`);
        return;
      }
    }

    let data = {};
    let endForm = false;
    
    if (item.type === 'input') {
      data = {
        question_id: item.id,
        answer_id: values.values.value_id,
        type: item.type,
        answer: values.values.resposta ? values.values.resposta : ''
      }
    } else {
      data = {
        question_id: item.id,
        answer_id: values.values.resposta,
        type: item.type
      }
    }

    let nextQ = nextQuestion;

    if (item.type === 'single') {

      const idx = item.list.find( (x:any) => values.values.resposta.includes(x.id)); // Uh oh!

      if(idx) {

        nextQ = idx.next_question

        if(idx.next_question === 0) {

          endForm = true;
        } else {
          // setNextQuestion(idx.next_question)
        }
         
      }
    }

    if (!endQuestions) {
      setA([...a, data]);
    }

    if (origin.length === count || endForm) {

      setEndQuestions(true)
      setOpenModalSubmit(true)
    } else {

      setQuestion(origin.slice(count+nextQ-1 === 0? 1 : count+nextQ-1 === 1? 2 : count+nextQ-1, count+nextQ-1 === 0? 2 : count+nextQ-1 === 1? 3: count+nextQ));
      setCount(count+nextQ-1 === 0? count+2 : count+nextQ-1 === 1? count+3 : count+nextQ);

    }

  }

  return(
    <>
    <ModalMessageConfirm
      message={intl.formatMessage({id: 'Are you sure you want to send this information?'})}
      title={intl.formatMessage({id: 'Attention'})}
      showModal={openModalSubmit}
      onClose={()=>setOpenModalSubmit(false)}
      onAction={send}
      loading={loadingSend}
    />
    {
      dataDegreeId !== ''?
      <ModalReportGraph
        title={`${intl.formatMessage({id: 'Report'})}:  ${intl.formatMessage({id: 'Maturity analysis'})}`}
        id={dataDegreeId}
        showModal={openModalReportGraph}
        onClose={()=>setOpenModalReportGraph(false)}
      />
      :
      <></>
    }
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-70px d-flex flex-row justify-content-between align-items-center w-100'>
          <div className='d-flex flex-row align-items-center'>
              <div className="d-flex bg-light p-3 align-items-center justify-content-center rounded me-4">
                <KTSVG
                  path="/media/icons/duotune/art/art002.svg"
                  className="svg-icon svg-icon-2x svg-icon-primary"
                />
              </div>
              <span className="modal-title fw-bold fs-3">{props.title}</span>
          </div>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>props.onClose()}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
       
          {
            loading?
            <div className='p-5'><Load/></div>
            :
            <>
              {
                question.map((item: any)=>
                <div key={item.id} className='mb-5 mt-4'>
                  
                  <div className='d-flex flex-row align-items-start mb-3 px-4'>
                    
                    <span className="py-1 bg-light-info text-info border-info border-1 border  px-4 h-30px align-items-center justify-content-center rounded">
                      <span className='fs-6 fw-bold'>{item.number}/{origin.length}</span>
                    </span>

                    <span className={`py-1 fs-2 px-1 ${item.required?`${intl.formatMessage({id: 'is required'})}`:''}`} />
                    <span className='py-2'>
                      <h5 className='' dangerouslySetInnerHTML={{ __html: `${item.name}`}}/>
                      { 
                        item.category ? 
                         <small>{item.category}</small>
                        :
                          ''
                      }
                 
                    </span>
                    
                  </div>

                  {  
                  
                  <Formik initialValues={{ resposta: item.type === 'mult'? Array() : '', value_id: ''}} onSubmit={values => {/*console.log(values); */}}>
                    {propData => {
                      const { values } = propData;
                      return (
                        <Form>
                        <>
                          <div className='px-5 mb-5 mt-3 text-center' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 250}}>
                          
                            {/* <pre>{JSON.stringify(propData.values, null, 2)}</pre>  */}

                            { item.type === 'input' ? 
                              
                              <Field type="text" name="resposta" className="form-control form-control-solid fs-6" />
                                            
                              : 
                              <div>
                                {
                              item.list.map((subitem: any)=>
                              <>

                              {
                                item.type === 'input' ?
                                <>
                                <Field type="text" name="resposta" className="form-control form-control-solid fs-6" placeholder={subitem.answer_name} onClick={values.value_id=subitem.id}/>
                                {/* <Field type="text" name="value_id" value="447" className="form-control form-control-solid fs-6" placeholder={subitem.answer_name} /> */}

                                
                                </>
                                :
                                    
                                <label key={subitem.id} role={'button'} 
                                className='alert py-7 alert-dismissible bg-light-primary border border-dashed border-primary d-flex  justify-content-between flex-sm-row p-5 mb-3 form-check form-check-custom form-check-solid align-items-center'>
                                      
                                  <div className='d-flex'>
                                    <span className="me-4 mb-5 mb-sm-0 d-flex align-items-center mx-5 me-10">
                                      <i className="fas fa-arrow-right fs-3 text-dark"></i>
                                    </span>
                                    <div className="d-flex flex-column text-primary justify-content-center">
                                      <h5 className='m-0 p-0' style={{textAlign: 'left'}}>{subitem.answer_name}</h5>
                                    </div>
                                  </div>

                                  <div>

                                    {
                                      item.type === 'single' ?
                                        
                                      <Field type="radio" name="resposta" value={subitem.id} className='form-check-input' checked={values.resposta.includes(subitem.id)}/>

                                      :
                                        
                                      item.type === 'mult' ?
                                          
                                      <FieldArray name="resposta" render={arrayHelpers => (
                                        <div>
                                                
                                          <label key={subitem.value}>
                                            <input
                                              name="tags"
                                              type="checkbox"
                                              value={subitem}
                                              checked={values.resposta.includes(subitem.id)}
                                              className='form-check-input'
                                              onChange={e => {
                                                if (e.target.checked) {
                                                  arrayHelpers.push(subitem.id);
                                                } else {
                                                  const idx = values.resposta.indexOf(subitem.id);
                                                  arrayHelpers.remove(idx);
                                                }
                                              }}
                                            />
                                          </label>
                                                  
                                        </div>
                                        )}
                                      />
        
                                      :
                                      `${intl.formatMessage({id: 'Input not recognized'})}`
                                        
                                    }

                                  </div>

                                </label>

                              }

                              </>

                              )
                            }

                              </div> 
                            }

                          </div>

                          {
                            props.model === 4 && origin.length === count ?  
                              <div className='row mx-3'>
                                <div className='col-lg-9 col-xl-4 d-flex flex-row w-100 justify-content-end'>
                                  <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                                  <input
                                    name="tags"
                                    type="checkbox"
                                    value={'yes'}
                                    checked={closeMapp}
                                    className='form-check-input'
                                    onChange={e => {
                                      if (e.target.checked) {
                                        setCloseMapp(true)
                                      } else {
                                        setCloseMapp(false)
                                      }
                                    }}
                                  />
                                    <span className='form-check-label text-muted'>{intl.formatMessage({id: 'Finish protocol'})}</span>
                                  </label>
                                </div>
                              </div>
                            : null
                          }



                          <div className='d-flex flex-row w-100 justify-content-end p-5'>
                            <button 
                              type='button' 
                              onClick={()=>[next2(propData, item)]}
                              className='btn btn-md btn-primary text-dark'>
                              {intl.formatMessage({id: 'Continue'})}
                            </button>
                          </div>

                        </>
                        </Form>
                      );
                    }}
                  </Formik>

                  }




                </div>
                )
              }
            </>
          }
      </Box>
    </Modal>
    </>
  )
}
export default ModalCalculatorQuestions;