type Props = {
  message: string;
}

const Warning: React.FC<Props> = ({message}) =>{
  return(
    <div className='d-flex flex-column justify-items-center align-items-center w-100 my-10'>
      <span className='text-gray-600 fs-4'>{message}</span>
    </div>
  )
}
export default Warning;