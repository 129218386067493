import react, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Warning from '../warning/Warning';

import { KTSVG } from '../../../helpers';
import { Load } from '../spinner/Spinner';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '95%',
	maxWidth: 1250,
	maxHeight: 500,
	bgcolor: 'white',
	border: 'none',
	borderRadius: 3,
	boxShadow: 24,
	outline: 'none'
};

interface IModalSelectActionProps {
	showModal: boolean;
	title: string;
	dataAction: any;
	onClose: () => void;
	onAction: (id: any, title: any) => void;
}

const ActionSelectGap: React.FC<IModalSelectActionProps> = ({ ...props }) => {

	const [dataGapCut, setDataGapCut] = useState<any>([]);
	const [isSearch, setIsSearch] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		setDataGapCut(props.dataAction)
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	}, []);

	const busca = (text: React.ChangeEvent<HTMLInputElement>) => {
		setIsSearch(true);
		let dado = JSON.parse(JSON.stringify(props.dataAction));

		setDataGapCut(dado.filter((d: any) => (d.gap.toString().toLowerCase().indexOf(text.currentTarget.value.toString().toLowerCase()) > -1)));
		const dataInput = (document.getElementById('input') as HTMLInputElement).value;
		if (dataInput === '') {
			setIsSearch(false);
			setDataGapCut(props.dataAction);
		}

	}

	return (
		<Modal
			open={props.showModal}
			onClose={() => { }}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
					<span className="modal-title fw-bold fs-3">{props.title}</span>
					<div
						className="btn btn-icon btn-sm btn-active-light-primary"
						onClick={() => props.onClose()}
					>
						<KTSVG
							path="/media/icons/duotune/arrows/arr061.svg"
							className="svg-icon svg-icon-2x"
						/>
					</div>
				</div>

				{/* Filtro */}
				{!loading ?
					<div className='mb-2 fv-row mt-4 mx-4' data-kt-password-meter='true'>
						<div className='mb-1'>
							<div className='position-relative mb-3'>
								<div className='d-flex justify-content-between w-100'>
									<input
										type='text'
										placeholder='Search'
										id='input'
										onChange={(event) => busca(event)}
										className='form-control form-control-lg form-control-solid w-100 me-1'
									/>
								</div>
							</div>
						</div>
					</div>
					:
					null
				}

				{/* Listagem de gaps */}
				<div className='px-5 mb-10 mt-5 d-flex flex-column w-100' style={{ overflow: 'auto', overflowX: 'hidden', maxHeight: 350 }}>

					{
						loading ?
							<Load />
							:
							<>
								{
									dataGapCut.lenght !== 0 ?
										<>

											{
												isSearch ?
													<>
														{dataGapCut.map((item: any, index: any) =>
															<div key={index} className='h-50px mb-2'>
																<button
																	className='border-0 bg-light h-50px bg-hover-secondary rounded fs-6 text-gray-800 flex-wrap w-100'
																	style={{ textAlign: 'justify' }}
																	onClick={() => props.onAction(item.id, item.gap)}
																>
																	<span>({index + 1}) {item.gap}</span>
																</button>
															</div>
														)}
													</>
													:
													<>
														{props.dataAction?.map((item: any, index: any) =>
															<div key={index} className='h-50px mb-2'>
																<button
																	className='border-0 bg-light h-50px bg-hover-secondary rounded fs-6 text-gray-800 flex-wrap w-100'
																	style={{ textAlign: 'justify' }}
																	onClick={() => props.onAction(item.id, item.gap)}
																>
																	<span>({index + 1}) {item.gap}</span>
																</button>
															</div>
														)}
													</>
											}
										</>
										:
										<Warning message='Sorry, an error occurred!' />
								}
							</>
					}

				</div>
			</Box>
		</Modal>
	)
}
export default ActionSelectGap;