import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import { ConfirmEmail } from '../models/ConfirmEmail'
import { EmailRequest } from '../models/EmailRequest'
import { Password } from '../models/Password'
import { ResetEmail } from '../models/ResetEmail'
import { ResetPassword } from '../models/ResetPassword'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_ENDPOINT+'/api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/profile`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forget-password`
export const CONFIRM_EMAIL_URL = `${API_URL}/auth/confirm-email`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
// export const RESET_EMAIL_URL = `${API_URL}/auth/reset-email`
export const REQUEST_EMAIL_URL = `${API_URL}/auth/request-email`

// Server should return AuthModel

// export function login(email: string, password: string) {
//   return axios.post(LOGIN_URL, {email, password})
// }

export function loginCripted(data:any) {
  return axios.post(LOGIN_URL, data)
}
export function loginNormal(data:any) {
  return axios.post(LOGIN_URL, data)
}

// Server should return AuthModel
export function register(
    email: string, 
    name: string, 
    last_name: string, 
    password: string, 
    confirm_password: string, 
    use_terms: any, 
    role: any,
    accepted: any,
    community: any,
    token: any
  ) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    name,
    last_name,
    role,
    accepted,
    community,
    password,
    confirm_password,
    use_terms,
    token,
  })
}
export function registerToken(
  email: string, 
  name: string, 
  last_name: string, 
  password: string, 
  confirm_password: string, 
  use_terms: any, 
  role: any,
  accepted: any,
  community: any,
  token: any
) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    name,
    last_name,
    role,
    accepted,
    community,
    password,
    confirm_password,
    use_terms,
    token,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<Password>(REQUEST_PASSWORD_URL, {email})
}
export function confirmEmail(email: any, code_email: any) {
  return axios.post<ConfirmEmail>(CONFIRM_EMAIL_URL, {email, code_email})
}
export function resetPassword(email: string, password: string, password_confirmation: string, token: string) {
  return axios.post<ResetPassword>(RESET_PASSWORD_URL, {email, password, password_confirmation, token})
}

//emailchange
// export function resetEmail(token: string) {
//   return axios.post<ResetEmail>(RESET_EMAIL_URL, {token})
// }
export function requestEmail(email: string, newEmail: string) {
  return axios.post<EmailRequest>(REQUEST_EMAIL_URL, {email, newEmail})
}


export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
