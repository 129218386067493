/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { createResources } from '../../../../../app/modules/helpers/Api'
import { toast } from 'react-toastify'

import { CreateResourceActions } from '../../../../../_metronic/partials/widgets';

// import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"

const schema = Yup.object().shape({
  title: Yup.string()
  .required('Title is required'),
  type: Yup.string()
  .required('Type is required'),
  free: Yup.string()
  .required('Free is required'),
})

type Props = {
  create: (action: boolean) => void;
  id?: any
}

const CreateResource: FC<Props> = ({...props}) => {  
  const[loading, setLoading] = useState(false);
  const [file, setFile] = useState<any>('');
  const [errorFile, setErrorFile] = useState<any>('');
  const [fileType, setFileType] = useState<any>('');
  const [fileSize, setFileSize] = useState<any>();
  const [video, setVideo] = useState<any>('');

  const [uploadValue, setUploaValue] = useState(0);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(null);
  


const[upload, setUpload] = useState(false);
  

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const files = target.files as FileList;
    const arrayFiles = Array.from(files);
    setFileType(arrayFiles[0].type);
    setFileSize(arrayFiles[0].size);
    setFile(arrayFiles);
  }
  const uploadVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const files = target.files as FileList;
    const arrayFiles = Array.from(files);
    setFileType(arrayFiles[0].type);
    setFileSize(arrayFiles[0].size);
    setVideo(arrayFiles);
  }

  const initialValues = {
    modelId: '',
    model: '',
    title: '',
    free: false,
    type: '',
    url: '',
    form: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        // save(values);
        setLoading(false)
      }, 1000)
    },
  })

  function save (values: any){
    var error = false;

    if(values.type !== '1'){
      
      if(values.type === '3'){
        if(
          fileType !== 'application/pdf' && 
          fileType !== 'image/png' &&
          fileType !== 'image/jpeg' &&
          fileType !== 'image/jpg' &&
          fileType !== 'audio/mp3' &&
          fileType !== 'video/mp4' &&
          fileType !== 'application/vnd.ms-excel' &&
          fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ){
          setErrorFile('File type is invalid!');
          error = true;
        }
      }

      if(values.type === '4'){
        if(!['video/mp4','video/quicktime'].includes(fileType)){
          setErrorFile('File type is invalid!');
          error = true;
        }
      }

    }

    if(!error){
      var formData = new FormData();
      formData.append('modelId', props.id? props.id : values.modelId);
      formData.append('free', values.free? '1' : '0');
      formData.append('title', values.title);
      formData.append('type', values.type);
      formData.append('url', values.url);
      formData.append('form', values.form);
      formData.append('video', video[0]);
      formData.append('file', file[0]);

      setUpload(true);
      setUploadErrorMessage(null)

      const create = createResources(formData);
      // create.then((res)=>{
      //   if(res.data.status === 'success'){
      //     toast.success(res.data.message);

      //     setUploadErrorMessage(res.data.message)

      //   }
      //   if(res.data.status === 'error'){
      //     toast.error(res.data.message);
      //     setUploadErrorMessage(res.data.message)
      //   }
      // }).catch((error)=>{

      //   let errorMessage = error.response.data.message ? 
      //   error.response.data.message : (error.response.statusText ? error.response.statusText : 'Não foi possivel enviar!');

      //   setUploadErrorMessage(errorMessage)
      //   toast.error(errorMessage);
      // });
    }
  }

  function handleResource (resource: boolean, id?: any){
    props.create(false)
  }

  return (
    <div >

      <CreateResourceActions id={null} create={handleResource} model="system"/>
{/* 
      <div className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top px-5 py-5'>
            <div className='d-flex justify-content-between border-bottom pb-4 align-items-center mb-3'>
              <label className='form-label fw-bolder text-dark fs-3 '>New resource</label>
              <button onClick={()=>props.create(false)} type='submit' className='btn btn-sm btn-secondary'>
                Back
              </button>
            </div>
            
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>Type</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('type')}
                    >
                      <option value=''>...Select an option</option>
                      <option value='1'>URL</option>
                      <option value='2'>Form</option>
                      <option value='3'>File</option>
                      <option value='4'>Video</option>
                    </select>
                  </div>
                  {formik.touched.type && formik.errors.type && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.type}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
              <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>Title</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <input
                        type='text' 
                        placeholder='Title'
                        autoComplete='off'
                        {...formik.getFieldProps('title')}
                        className='form-control form-control-lg form-control-solid w-100 me-1'
                      />
                  </div>
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>




            {
              formik.values.type === '2'?
              <div className='mb-2 fv-row' data-kt-password-meter='true'>
                <div className='mb-1 mt-4'>
                  <label className='form-label fw-bold text-dark fs-6'>Form code</label>
                  <div className='position-relative mb-3'>
                    <div className='d-flex justify-content-between w-100'>
                      <input
                        type='text' 
                        placeholder='Form code'
                        autoComplete='off'
                        {...formik.getFieldProps('form')}
                        className='form-control form-control-lg form-control-solid w-100 me-1'
                      />
                    </div>
                  </div>
                </div>
              </div>
              :
              null
            }
           
            
            {
              formik.values.type === '1'?
              <div className='mb-2 fv-row' data-kt-password-meter='true'>
                <div className='mb-1 mt-4'>
                  <label className='form-label fw-bold text-dark fs-6'>URL</label>
                  <div className='position-relative mb-3'>
                    <div className='d-flex justify-content-between w-100'>
                      <input
                        type='text' 
                        placeholder='URL'
                        autoComplete='off'
                        {...formik.getFieldProps('url')}
                        className='form-control form-control-lg form-control-solid w-100 me-1'
                      />
                    </div>
                  </div>
                </div>
              </div>
              :
              <>
                {
                  formik.values.type === '3' || formik.values.type === '5'?
                  <div className='mb-2 fv-row' data-kt-password-meter='true'>
                    <div className='mb-1 mt-4'>
                      <label className='form-label fw-bold text-dark fs-6'>
                        File
                      </label>
                      <div className='position-relative mb-3'>
                        <div className='d-flex justify-content-between w-100 mb-1'>
                          <input
                            type='file' 
                            onChange={(event)=>[uploadFile(event), setErrorFile('')]}
                            className='form-control form-control-lg form-control-solid w-100 me-1'
                          />
                        </div>
                        <small>Format: PDF, PNG, JPEG, JPG, MP3, XLS, XLSX; Max upload: 8MB</small>
                      </div>
                      {errorFile && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errorFile}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  :
                  <>
                    {
                      formik.values.type === '4'?
                      <div className='mb-2 fv-row' data-kt-password-meter='true'>
                        <div className='mb-1 mt-4'>
                          <label className='form-label fw-bold text-dark fs-6'>
                            Video
                          </label>
                          <div className='position-relative mb-3'>
                            <div className='d-flex justify-content-between w-100 mb-1'>
                              <input
                                type='file' 
                                onChange={(event)=>[uploadFile(event), setErrorFile('')]}
                                className='form-control form-control-lg form-control-solid w-100 me-1'
                              />
                            </div>
                            <small>Format: MP4, MOV; Max upload: 500MB</small>
                          </div>
                          {errorFile && (
                            <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errorFile}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      :
                      <></>
                    }
                  </> 
                }
              </>
            }




            { upload ? <progress id="upload_bar_progress_1" max="100" value={uploadValue}></progress> : null}
            { uploadErrorMessage ? <p>{uploadErrorMessage}</p> : null }
            
            <p id="dialog_upload_span" />
            
            

            <div className='fv-row my-5'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='kt_login_toc_agree'
                  {...formik.getFieldProps('free')}
                />
                <label
                  className='form-check-label fw-bold text-gray-700 fs-6'
                  htmlFor='kt_login_toc_agree'
                >
                  Private resource
                </label>
                {formik.touched.free && formik.errors.free && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.free}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

          </div>
          
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Processando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div> */}
    </div>
  )
}

export {CreateResource}
