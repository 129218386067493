/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { deleteForm, defaultPaginationList} from '../../../../../app/modules/helpers/Api';
import { converteData } from '../../../../../app/modules/helpers/helpers';
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import ModalMessage from '../../modals/modalMessage';
import { Load } from '../../spinner/Spinner';
import Warning from '../../warning/Warning';
import { useIntl } from 'react-intl'
import { UserModel } from '../../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import Pagination from '../../../../helpers/components/CustomPagination/CustomPagination'

let searchData = {
  name: '',
  code: '',
  page: 1,
  perpage: 10,
  model: '',
  status: '',
  conformity_id: ''
}

type Props = {
  show: (action: boolean, id: any, title?: any) => void;
  create: (action: boolean) => void;
  edition: (action: boolean, id: any, model: any) => void;
  titleModule: any;
  id: any;
}

const FormsList: React.FC<Props> = ({...props}) => {
  const intl = useIntl();   
  const [formData, setFormData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [formId, setFormId] = useState('');
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [nameFilter, setNameFilter ]= useState<any>('');
  const [modelFilter, setModelFilter] = useState<any>('');
  const [statusFilter, setStatusFilter] = useState<any>('');
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  searchData.name = nameFilter
  searchData.model = modelFilter
  searchData.status = statusFilter
  useEffect(()=>{
    handleGetList(null)
  },[props.id]);

  function handleGetList(data:null) {
    searchData.conformity_id = props.id
    setLoading(true);
    const get = defaultPaginationList('prescriptions', searchData)
    get.then((res)=>{
      setFormData(res.data.data);
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      }
    })
    .finally(()=>[setLoading(false)]);
  }

  function handleDelete (id: any) {
    const del = deleteForm(id);
    del.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        setOpenModalDelete(false);
      }
      if(res.data.status === 'error'){
        toast.success(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      } else {
        toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}`);
      }
    })
  }

  return (
    <>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <div className='w-90'>
            <h3 className='card-title align-items-start flex-column mb-2'>
              <span className='card-label fw-bolder fs-3 mb-'>{intl.formatMessage({id: 'Conformity'})}: &nbsp;</span> <span className='card-label fw-bolder fs-5 mb-1 text-gray-700'> {props.titleModule}</span>
            </h3>
            <div >
            <h3 className='card-title align-items-start flex-column'>
             
            </h3>
            </div>
            <p className='text-gray-500 fw-bolder mt-3'>{intl.formatMessage({id: 'Form List'})}</p>
          </div>
          <button style={{width: '100px'}} onClick={()=>props.show(false, 'back')} type='button' className='btn btn-sm btn-secondary p-3'>
              <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark'/>
              {intl.formatMessage({id: 'Back'})}
          </button>
             
       </div>
        {/* <h3 className='card-title align-items-start flex-column mb-3'>
            <span className='card-label fw-bolder fs-3 mb-3'>{intl.formatMessage({id: ''})}</span>
        </h3> */}
      {
        loading?
        <Load/>
        :
        <div className={`card card mb-5 mb-xl-10`}>
          <ModalMessage
            message={intl.formatMessage({id: 'Are you sure you want to delete this item?'})}
            title={intl.formatMessage({id: 'Attention'})}
            showModal={openModalDelete}
            onClose={()=>setOpenModalDelete(false)}
            onAction={()=>handleDelete(formId)}
          />
          
          <div className='row card-header border-0 pt-5 d-flex justify-content-end align-items-center'>
            <div className='col-md-4 '>
              
              <input
                    type="text"
                    className="form-control form-control-solid fs-6  rounded-3"
                    placeholder={`${intl.formatMessage({id: 'Search a form'})}`}
                    autoComplete='off'
                    style={{height: '40px'}}
                    value={nameFilter}
                    onChange={(e)=>[setNameFilter(e.target.value)]}
                    onKeyDown={(e)=>e.which === 13 ? handleGetList(null) : '' }
                  
                  />
             
            </div>

            <div className='col-md-2'>
                <select className='form-select form-control form-control-solid fs-6' 
                  value={modelFilter}
                  onChange={(e)=>[setModelFilter(e.target.value)]}
                  style={{height: '40px'}}
                >
                  <option value='' >{intl.formatMessage({id: 'All Models'})}</option> 
                  <option value='1' >{intl.formatMessage({id: 'Maturity analysis'})}</option>
                  <option value='2'>{intl.formatMessage({id: 'Scored'})}</option>
                  <option value='3' >{intl.formatMessage({id: 'Not scored'})}</option>
                  <option value='4' >{intl.formatMessage({id: 'Data Mapping'})}</option>
                </select>
            </div>
            <div className='col-md-2'>
                <select className='form-select form-control form-control-solid fs-6' 
                  value={statusFilter}
                  onChange={(e)=>[setStatusFilter(e.target.value)]}
                  style={{height: '40px'}}
                >
                  <option value='' >{intl.formatMessage({id: 'All Status'})}</option> 
                  <option value='1' >{intl.formatMessage({id: 'Active'})}</option>
                  <option value='2'>{intl.formatMessage({id: 'Test'})}</option>
                </select>
            </div>
            <div  className='col-md-4 d-flex justify-content-between align-items-center'

            >
                <button 
                  type='button' 
                  className='btn bg-hover-dark text-white' 
                  disabled={loading}
                  onClick={()=>handleGetList(null)}
                  style={{width: '125px',height: '40px', background: '#65638E', borderRadius: 13, marginRight: '5px'}}
                  >
                   {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Search'})}</span>}
                        {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                </button>
              <button
                className='btn btn-sm btn-dark rounded-3 p-0'
                onClick={()=>props.create(true)}
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title={intl.formatMessage({id: 'Click to add a form'})}
                style={{height: '38px', width: '125px', marginRight: window.screen.width > 767 ? '15px' : '0px'}}
              >
                {intl.formatMessage({id: 'Create Form'})}
              </button>
            </div>
            </div>
                <div className=''>
                  
                </div>       
          {/* </div> */}
          <div className='card-body py-3'>
            {
              formData.length !== 0?
              <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder text-dark'>
                    <th className='w-110px text-center'></th>
                    <th className='min-w-150px'>{intl.formatMessage({id: 'NAME'})}</th>
                    <th className='min-w-140px'>{intl.formatMessage({id: 'STATUS'})}</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'MODEL'})}</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'QUESTIONS'})}</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'ANSWERED'})}</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'DATE'})}</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {
                    formData.data.map((item: any, index: any)=>
                    <tr>
                      <td>
                        <div className='d-flex justify-content-center flex-shrink-0'>
                          <button
                            className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-2'
                            onClick={()=>props.edition(true, item.id, item.model)}
                            // disabled={item.default && !['super-admin'].includes(user.roles) }
                          >
                            { item.default ?
                            <i className="fas fa-eye text-info"/>
                            :
                            <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _edit 2 outline_.svg')} /> 
                            }
                           
                            
                          </button>
                          <button
                            disabled={item.default && !['super-admin'].includes(user.roles) }
                            className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm'
                            onClick={()=>[
                              setFormId(item.id),
                              setOpenModalDelete(true)
                            ]}
                          >
                            <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _trash_.svg')} />  
                          </button>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-gray-700 fw-bold fs-6'>
                              {item.default ? <i className="fa fa-asterisk text-primary" /> : null}
                              <button onClick={()=>props.show(true, item.id, item.name)} className='border-0 text-hover-primary bg-transparent text-gray-700 '>
                              {item.name.split('', 25)}{item.name?.length >=25 ? '...' : ''}
                              </button>
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-gray-700 fw-bold fs-6'>
                              {                                
                                item.status === 0 ? <span className="badge badge-light">{intl.formatMessage({id: 'Inactive'})}</span> :
                                item.status === 1 ? <span className="badge badge-success">{intl.formatMessage({id: 'Active'})}</span>:
                                item.status === 2 ? <span className="badge badge-primary text-white">{intl.formatMessage({id: 'Test'})}</span> :
                                'Desconhecido'
                              }
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-gray-700 fw-bold fs-6'>
                              
                              <span className="badge badge-light-info">
                              {                                
                                item.model === 1 ? `${intl.formatMessage({id: 'Maturity analysis'})}`  :
                                item.model === 2 ? `${intl.formatMessage({id: 'Scored'})}` :
                                item.model === 3 ? `${intl.formatMessage({id: 'Not scored'})}` :
                                item.model === 4 ? `${intl.formatMessage({id: 'Data Mapping'})}` :
                                `${intl.formatMessage({id: 'Unknown'})}`
                              }
                              </span>

                              {item.is_public?<span className="badge badge-light-success mx-2">Public</span>:null}
                             
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-gray-700 fw-bold fs-6'>
                              {item.questions_count}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-gray-700 fw-bold fs-6'>
                              {item.answered_count}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-gray-700 fw-bold fs-6'>
                              {converteData(item.created_at)}
                            </span>
                          </div>
                        </div>
                      </td>                      
                    </tr>
                    )
                  }
                  
                </tbody>
              </table> <Pagination data={formData} onSearch={handleGetList} searchData={searchData}/>

            </div>
            :
            <Warning message={intl.formatMessage({id: 'Sorry, no content to show yet'})} />
            }
          </div>
        </div>
      }
    </>
  )
}

export {FormsList}
