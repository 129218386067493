import react, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ModalMessage from '../../modals/modalMessage';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { updatePlan} from '../../../../../app/modules/helpers/Api';
import { KTSVG } from '../../../../helpers';
import { useIntl } from 'react-intl'

let widthVar = 450

if(window.screen.width < 500){
    widthVar = 330
    if(window.screen.width < 300){
      widthVar = 250
  }
  
}


const style = {
  position: 'absolute' as 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: widthVar,
  height: 250,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

interface IStoreAdmin {
  showModal: boolean;
  title: string;
  id: any;
  titlePlan: any;
  value: any;
  duration: any;
  slug_name: any;

  onClose: () => void;
  onAction: () => void;

}

const StoreBuyForm: React.FC<IStoreAdmin> = ({...props}) => {

  const [openModalConfirmEdition, setOpenModalConfirmEdition] = useState(false);
  const [plan, setPlan] = useState('');
  const [value, setValue] = useState('');
  const [duration, setDuration] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();   

  useEffect(()=>{
    setDuration(props.duration)
    setPlan(props.titlePlan)
    setValue(props.value)
  },[props.titlePlan, props.duration, props.value]);

  // function handleEdition(){
  //   setLoad(true);
  //   setTimeout(()=>{
  //     setLoad(false);
  //   },2000);
  // }
  // const formikEdition = useFormik({
  //   initialValues: {
  //     plan: props.titlePlan,
  //     value: props.value,
  //     duration: props.duration
  //   },
  //   onSubmit: (values) => {

  //     setOpenModalConfirmEdition(true);
  //   }
    
  // })

  const saveEdition = () => {
    const data = {
      code: props.id,
      plan: plan,
      value: value,
      duration: duration
      
    } 
    
    setIsLoading(true);
    const send = updatePlan(data);
    send.then((res)=>{
      if(res.data.status === 'success'){
        setOpenModalConfirmEdition(false);
        props.onClose()
        props.onAction()
        toast.success(res.data.message);
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      }
    }).finally(()=>{    
      setIsLoading(false);
    });
  } 

  return(
    
   <>
    <ModalMessage
        message={intl.formatMessage({id: 'Are you sure you want to edition this item?'})}
        title={intl.formatMessage({id: 'Attention'})}
        showModal={openModalConfirmEdition}
        onClose={()=>setOpenModalConfirmEdition(false)}
        onAction={()=>[saveEdition()]}
      />  
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      
    
      <Box sx={style}>
        
        <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
          <span className="modal-title fw-bold fs-3">{props.title}</span>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>props.onClose()}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
          
       
        </div>
        <div
         className='mb-5 w-100 alert text-dark bg-white  border-2  ' 
         style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 400}}
         >
			
            <div className='d-flex flex-column border-dashed border-2 border-gray-400 rounded p-3'>
                <span className='fs-5 text-gray-700'><strong>{intl.formatMessage({id: 'Plan'})}:</strong> {props.titlePlan}</span>
                <span className='fs-5 text-gray-700'><strong>{intl.formatMessage({id: 'Value'})}:</strong> BRL {props.value}</span>
                <span className='fs-5 text-gray-700'><strong>{intl.formatMessage({id: 'Duration'})}:</strong> {props.duration} {intl.formatMessage({id: 'Days'})}</span>
                
                <form  id='form-action-plan-action' noValidate className='form w-100'>
                    <div className='align-items-start w-100 '>
                
                        <div className='row'>

                            <div className='col-sm-6 fv-row'>
                           
                            <label className='fs-5 text-gray-700 ' >{intl.formatMessage({id: 'Plan'})}:</label>
                            <input
                                type='text'
                                className="form-control form-control-solid fs-5"
                                placeholder={intl.formatMessage({id: 'New Plan'})}
                                value={plan}
                                onChange={(e)=>setPlan(e.currentTarget.value)}
                                
                            />
                
                            <label className='fs-5 text-gray-700' >{intl.formatMessage({id: 'Value'})}:</label>
                            <input
                                type='text'
                                className="form-control form-control-solid fs-5"
                                placeholder={intl.formatMessage({id: 'New Value'})}
                                value={value}
                                onChange={(e)=>setValue(e.currentTarget.value)}
                               
                            />
                            <label className='fs-5 text-gray-700' >{intl.formatMessage({id: 'Duration'})}:</label>
                            <input
                                type='text'
                                className="form-control form-control-solid fs-5"
                                placeholder={intl.formatMessage({id: 'New Duration'})}
                                value={duration}
                                onChange={(e)=>setDuration(e.currentTarget.value)}
                                
                            />
                           
                            </div>

                           

                        </div>
                    
                    </div>
                </form>
              
            </div>            
            <div className='d-flex flex-row w-100 justify-content-end p-5'>
          <button type='button' onClick={()=>setOpenModalConfirmEdition(true)} className='btn btn-md btn-primary text-white me-3'>
          {intl.formatMessage({id: 'Yes'})}
          </button>
          <button type='button' onClick={()=>props.onClose()} className='btn btn-md btn-danger'>
          {intl.formatMessage({id: 'No'})}
          </button>
        </div>
        </div>
        
        
        
      </Box>
    </Modal>
    </>
  )
}
export default StoreBuyForm;