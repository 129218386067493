import React, { useState } from 'react';
import './_style.scss';

interface ICustomVideoPlayerProps {
  // id: any;
  // props here
}

const controlsList = 'nodownload'; 

const CustomVideoPlayer: React.FC<ICustomVideoPlayerProps> = ({
  ...props
}) => {

  const handleClose = () => {
    
    
    var video = document.getElementById('example_video');
    // @ts-ignore: Object is possibly 'null'.
    video.pause()
    // @ts-ignore: Object is possibly 'null'.
    document.getElementById("custom-video-player").style.display = "none";
  }

  return(
    <div id='custom-video-player'>

      <div id='video-player-btn-close' onClick={handleClose}>Sair</div>
      
      <video  id="example_video"  controls controlsList={`${controlsList}`}>
        <source id='video-player-1' src="" type=""></source>
        Your browser does not support the video tag.
      </video>

    </div>
  )
}
export default CustomVideoPlayer;