/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { countries } from '../../../../app/modules/helpers/country'
import { putCompanies } from '../../../../app/modules/helpers/Api'
import { trataRespota } from '../../../../app/modules/helpers/helpers'
import { useIntl } from 'react-intl'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import clsx from 'clsx'
import { mask} from '../../../../app/modules/helpers/helpers'

type Props = {
  data: {
    id: number,
		company: string | '',
		corporate_name: string | '',
		fantasy_name: string | '',
		document: string | '',
    status: string | '',
		document_type: string | '',
		user_id: string | '',
		zip_code: string | '',
		number: string | '',
		complement: string | '',
		neighborhood: string | '',
		city: string | '',
		uf: string | '',
		tel: string | '',
		country: string | '',
		street: string | '',
		whatsapp_number: string | '',
		reg_state: string | '',
		reg_municipal: string | '',
		site: string | '',
		instagram: string | '',
		facebook: string | '',
		logo: string | '',
		observation: string | '',
		created_at: string | '',
		updated_at: string | '',
		deleted_at: string | ''
  }
  msg: (status: any, message: any) => void;
}

const EditionCompany: FC<Props> = ({...props}) => {
  const [loading, setLoading] = useState(false);
  const initialValues = props.data;
  const intl = useIntl();   
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const schema = Yup.object().shape({
    company: Yup.string().required(`${intl.formatMessage({id: 'Company'})} ${intl.formatMessage({id: 'is required'})}`),
    corporate_name: Yup.string().required(`${intl.formatMessage({id: 'Corporate name'})} ${intl.formatMessage({id: 'is required'})}`),
    fantasy_name: Yup.string().required(`${intl.formatMessage({id: 'Fantasy name'})} ${intl.formatMessage({id: 'is required'})}`)
  })

  const [document, setDocument] = useState(props.data.document || '');
  const [errorDocument, setErrorDocument] = useState('');
  const [companyStatus, setCompanyStatus] = useState(props.data.status || '');
  
  function save(value: any){
    const company = {
      id: props.data.id,
      company: value.company,
      corporate_name: value.corporate_name,
      fantasy_name: value.fantasy_name,
      document: document,
      status: value.status,
      document_type: props.data.document_type,
      user_id: props.data.user_id,
      zip_code: value.zip_code,
      number: value.number,
      complement: value.complement,
      neighborhood: value.neighborhood,
      city: value.city,
      uf: value.state,
      tel: value.tel,
      country: value.country,
      street: value.street,
      whatsapp_number: value.whatsapp_number,
      reg_state: value.reg_state,
      reg_municipal: value.reg_municipal,
      site: props.data.site,
      instagram: value.instagram,
      facebook: value.facebook,
      logo: value.logo,
      observation: value.observation,
    }
    const saveCompany = putCompanies(props.data.id, company);
    saveCompany.then((res)=>{
      if(res.data.status === 'success'){
        props.msg('success', res.data.message);
      }
      if(res.data.status === 'error'){
        props.msg('error', res.data.message);
      }
    }).catch((error)=>{
      trataRespota('error', error); 
     // props.msg('error', error.response.data.message);
    });
  }

  function addMask(e: any){
    setDocument(mask(e));
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='collapse show'>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => {
              setLoading(true);
              setTimeout(() => {
                save(values);
                setLoading(false);
              }, 1000);
            }}
          >
            {
             props => (
              <form onSubmit={props.handleSubmit} noValidate className='form'>
                <div className='card-body border-top px-5 py-5'>
                  <div className='mx-4 align-items-center'>
                    <div className='col-lg-12'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Company'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'Company'})}
                            name='company'
                            onChange={props.handleChange}
                            value={props.values.company}
                          />
                          {props.errors.company && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fs-5'>{props.errors.company}</div>
                            </div>
                          )}
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Corporate name'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'Corporate name'})}
                            name='corporate_name'
                            onChange={props.handleChange}
                            value={props.values.corporate_name}
                          />
                          {props.errors.corporate_name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fs-5'>{props.errors.corporate_name}</div>
                            </div>
                          )}
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Fantasy name'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'Fantasy name'})}
                            name='fantasy_name'
                            onChange={props.handleChange}
                            value={props.values.fantasy_name}
                          />
                          {props.errors.fantasy_name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fs-5'>{props.errors.fantasy_name}</div>
                            </div>
                          )}
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-6 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Municipal Registration'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'Municipal Registration'})}
                            name='reg_municipal'
                            onChange={props.handleChange}
                            value={props.values.reg_municipal}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-6 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'State Registration'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'State Registration'})}
                            name='reg_state'
                            onChange={props.handleChange}
                            value={props.values.reg_state}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-6 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Document'})}</label>
                          <input
                            type="text"
                            autoComplete='off'
                            maxLength={18}
                            placeholder={intl.formatMessage({id: 'Document'})}
                            disabled={!['super-admin'].includes(user.roles)}
                            name='document'
                            // onChange={props.handleChange}
                            onChange={e=>[addMask(e.currentTarget.value), setErrorDocument('')]}
                            value={document}
                            className={clsx(
                              'form-control form-control-solid fs-6',
                              {'is-invalid': errorDocument || ![14,18].includes(document.length)},
                              {
                                'is-valid': [14,18].includes(document.length),
                              }
                            )}
                          />
                        </div>

                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-6 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Status'})}</label>
                          <select
                            className='form-select form-select-solid form-select-lg fw-bold'
                            // onChange={e=>[addMask(e.currentTarget.value)]}
                            // value={companyStatus}
                            name='status'
                            onChange={props.handleChange}
                            value={props.values.status}
                            disabled={!['super-admin' ].includes(user.roles)}
                          >
                            <option value='pending'>Pendente</option>
                            <option value='active'>Ativo</option>
                            <option value='expired'>Expirado</option>
                            <option value='inactive'>Inativo</option>
                            <option value='called off'>called off</option>
                            
                          </select>
                        </div>

                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Logo Url</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Logo"
                            name='logo'
                            onChange={props.handleChange}
                            value={props.values.logo}
                          />
                        </div>
                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Observation'})}</label>
                          <textarea
                            rows={5}
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'Observation'})}
                            name='observation'
                            onChange={props.handleChange}
                            value={props.values.observation}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className='col-lg-12'>
                      <label className='col-lg-4 col-form-label fw-bolder fs-3'>Data Protection Officer</label>
                      <div className='row'>
                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label required fw-bold fs-5'>DPO</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="DPO"
                            name='dpo_user_id'
                            onChange={props.handleChange}
                            value={props.values.dpo_user_id}
                          />
                        </div>
                      </div>
                    </div> */}

                    <div className='col-lg-12'>
                      <label className='col-lg-4 col-form-label fw-bolder fs-3'>{intl.formatMessage({id: 'Address Details'})}</label>
                      <div className='row'>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Phone'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'Phone'})}
                            name='tel'
                            onChange={props.handleChange}
                            value={props.values.tel}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Postal Code'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'Postal Code'})}
                            name='zip_code'
                            onChange={props.handleChange}
                            value={props.values.zip_code}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'State'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'State'})}
                            name='state'
                            onChange={props.handleChange}
                            value={props.values.uf}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'City'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'City'})}
                            name='city'
                            onChange={props.handleChange}
                            value={props.values.city}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Neighborhood'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'Neighborhood'})}
                            name='neighborhood'
                            onChange={props.handleChange}
                            value={props.values.neighborhood}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Street'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'Street'})}
                            name='street'
                            onChange={props.handleChange}
                            value={props.values.street}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Number'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'Number'})}
                            name='number'
                            onChange={props.handleChange}
                            value={props.values.number}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Complement'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={intl.formatMessage({id: 'Complement'})}
                            name='complement'
                            onChange={props.handleChange}
                            value={props.values.complement}
                          />
                        </div>
                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Country'})}</label>
                          <select className='form-select form-control form-control-solid fs-6' 
                            name='country'
                            onChange={props.handleChange}
                            value={props.values.country}
                          >
                            
                            {
                              countries.map((item: any)=>
                                <option key={item.name} value={item.code}>{item.name}</option>
                              )
                            }
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-12'>
                      <label className='col-lg-4 col-form-label fw-bolder fs-3'>Social</label>
                      <div className='row'>
                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Whatsapp Number'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="21988743516"
                            name='whatsapp_number'
                            onChange={props.handleChange}
                            value={props.values.whatsapp_number}
                          />
                        </div>
                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Facebook</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Facebook"
                            name='facebook'
                            onChange={props.handleChange}
                            value={props.values.facebook}
                          />
                        </div>
                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Instagram</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Instagram"
                            name='instagram'
                            onChange={props.handleChange}
                            value={props.values.instagram}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button type='submit' className='btn btn-primary text-dark' disabled={loading}>
                    {!loading && intl.formatMessage({id: 'Save'})}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'Processing'})}...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
              )
            }
          </Formik>
      </div>
    </div>
  )
}

export {EditionCompany}
