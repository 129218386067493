import {FC, useEffect, useState} from 'react'

import { RouteComponentProps} from 'react-router';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import { trataRespota } from '../../helpers/helpers'
import { changeEmail } from '../../helpers/Api'
import { Load } from '../../../../_metronic/partials/widgets';

interface MatchParams {
  token: string;
}

const initialValues = {
  email: '',
  password: '',
  newEmail: '',
  emailConfirm: ''
}



export const ResetEmail: FC<RouteComponentProps<MatchParams>> = (props) => {
  const [loading, setLoading] = useState(false)
  const token = props.match.params.token;
  const history = useHistory();
  const intl = useIntl();   
  const [error, setError] = useState(false);
  const [description, setDescription] = useState('');
  useEffect(()=>{
    setLoading(true)
    let data = {
      token: token
    }
    changeEmail(data)
          .then((data) => {
            setDescription(data.data.message)
            if(data.data.status === 'error'){
              toast.error(data.data.message)
            } else {
              toast.success(data.data.message)
            }
            setLoading(false)
            if(data.data.status === 'success'){
              setTimeout(() => {
                history.push("/auth/login")
              }, 4000);
            }
            if(data.data.status === 'error'){
              setError(true)
            }
          })
          .catch((error) => {
            // toast.error(error.response.data.message)
            trataRespota('error', error); 
            setLoading(false)
          })
  },[token])

  return (
    <div className='d-flex flex-column bg-gray-middle flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    style={{
      backgroundImage:`url(${process.env.PUBLIC_URL + '/media/logos/background.png'})`,
      backgroundPosition: 'center',
      backgroundColor: '#1E1E2D',
      backgroundSize: 'cover' 
    }}
    >
        <div className='d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20'>
        {/* <div className='mb-5'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/breven_law_color_logo.svg')} className='h-95px' />
        </div> */}
        <div className='w-lg-500px bg-white rounded-5 shadow-sm p-10 p-lg-15 mx-auto'
        style={{maxWidth: '450px'}}
        >
         
           {
            loading ?
            <Load/>
            :
             <div className='text-center mb-10'>
             <h1 className='text-primary mb-3'>{intl.formatMessage({id: 'Troca de email'})}</h1>
            {
             error ?
               <div className='text-gray-700 fw-bold fs-4'>
                   {intl.formatMessage({id: 'Não foi possível atualizar o E-mail!!'})} <br/>
                   <p className='text-danger fw-bold fs-4'>{description}</p>
               </div>
               :
               <div className='text-gray-700 fw-bold fs-4'>
                 {intl.formatMessage({id: 'E-mail atualizado com Sucesso, você será redirecionado em instantes !!'})}
                 <br/>
                 <p className='text-success fw-bold fs-4'>{description}</p>
               </div>
            }
           </div>
           }
            
        </div>
      </div>
      
    </div>
  )
}
