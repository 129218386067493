import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG, } from '../../../../helpers';
import { getCompaniesByUser, storePreferenceConsent, updateHolderRequestToken } from '../../../../../app/modules/helpers/Api';
import { trataRespota, copyText } from '../../../../../app/modules/helpers/helpers'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import clsx from 'clsx'
import ReactQuill from 'react-quill';


let paginationData = {
  currentPage: 1,
  total: 0,
  from: 0,
  to: 0,
  lastPage: 0
}



let initialSearchValues = {
  title: '',
  code: '',
  page: 1
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 950,
  maxHeight: 620,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IModalNewPreferenceConsentProps {
  showModal: boolean;
  title: string;
  // company_id: any;
  onClose: () => void;
  onAction: (id: any, form: string) => void;
  handleGetList: () => void;
}

const ModalNewPreferenceConsent: React.FC<IModalNewPreferenceConsentProps> = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [companies, setCompanies] = useState<any>([]);
  const initialValues = {
    title: '',
    message: '',
    company_id: '',
    status: '',
    show_title: 0
  }
  const registrationSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, `${intl.formatMessage({ id: 'Mínimo de 3 símbolos' })}`)
      .required(`${intl.formatMessage({ id: 'Title' })} ${intl.formatMessage({ id: 'is required' })}`),
    message: Yup.string()
      .required(`${intl.formatMessage({ id: 'Message' })} ${intl.formatMessage({ id: 'is required' })}`),
    company_id: Yup.number()
      .required(`${intl.formatMessage({ id: 'Company' })} ${intl.formatMessage({ id: 'is required' })}`),
    show_title: Yup.boolean().nullable(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleStoreOrUpdate(values);
    },
  })

  function handleStoreOrUpdate(values: any) {

    setLoading(true);

    let create = null;

    if (formik.getFieldProps('id').value) {
      create = updateHolderRequestToken({
        id: values.id,
        title: values.title,
        message: values.message,
        status: values.status,
        show_title: values.show_title
      });
    }
    else {
      create = storePreferenceConsent({
        model: 'Company',
        model_id: values.company_id,
        title: values.title,
        message: values.message,
        show_title: values.show_title
      });
    }
    create.then((res) => {
      if (res.data.status === 'success') {
        formik.resetForm()
        props.handleGetList()
        props.onClose()
        toast.success(res.data.message);
      }
      else if (res.data.status === 'error') {
        toast.error(res.data.message);

      }

    }).catch((error) => {
      trataRespota('error', error);
    }).finally(() => {
      setLoading(false)

    });
  }

  useEffect(() => {

    if (props.showModal === true) {
      handleCompaniesByUser();
    }
  }, [props.showModal]);

  function onClear() {
    paginationData.from = 0
    paginationData.to = 0
    paginationData.total = 0
    paginationData.lastPage = 0

    initialSearchValues.title = ''
    initialSearchValues.code = ''
    initialSearchValues.page = 1

  }


  function handleCloseModalNew() {
    formik.resetForm()
    props.onClose()
  }

  function handleCompaniesByUser() {
    const getCompanies = getCompaniesByUser();
    setLoading(true);
    getCompanies.then((res) => {
      if (res.data.status === 'success') {
        setCompanies(res.data.data)
      } else {
        console.log(res)
      }


    }).catch((error) => {
      // console.log(error)
    }).finally(() => {
      setLoading(false);
    });

  }



  return (
    <Modal
      open={props.showModal}
      onClose={() => { }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>

        <div className='px-5 d-flex flex-row justify-content-between align-items-center w-100'>

          <div className='d-flex fw-bold h-60px flex-row align-items-center border-1 border-bottom border-dark w-100'>
            <span className="modal-title fw-bold fs-3">{intl.formatMessage({ id: 'Management of acepts documents' })}</span>
            {/* <span className="modal-title fw-bold fs-3">{intl.formatMessage({ id: 'New' })} {intl.formatMessage({ id: 'preferences_consent' })}</span> */}

          </div>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={() => [props.onClose(), onClear()]}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>



        <div className='px-6 py-8'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}


          >


            <div className='fv-row mb-3'>
              <input
                disabled
                type='hidden'
                autoComplete='off'
                {...formik.getFieldProps('id')}
                className='form-control form-control-lg form-control-solid'
              />
            </div>
            <div className='fv-row mb-3'>

              <label className='form-label fw-bold text-dark fs-5 required'>{intl.formatMessage({ id: 'Company' })}</label>
              <select {...formik.getFieldProps('company_id')}

                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.company_id && formik.errors.company_id },
                  {
                    'is-valid': formik.touched.company_id && !formik.errors.company_id,
                  }
                )}
              >
                {formik.touched.company_id && formik.errors.company_id && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.company_id}</span>
                    </div>
                  </div>
                )}
                <option value=''>{intl.formatMessage({ id: 'Select a company' })}</option>
                {
                  companies.map((companiesList: any) =>
                    <option key={companiesList.value} value={companiesList.value}>{companiesList.text}</option>
                  )

                }

              </select>
            </div>

            {/* TITLE */}
            <div className='fv-row mb-3'>
              <label className='form-label fw-bold text-dark fs-5 required'>{intl.formatMessage({ id: 'Title' })}</label>
              <input
                disabled={loading}
                placeholder={intl.formatMessage({ id: 'Title' })}
                type='title'
                autoComplete='off'
                {...formik.getFieldProps('title')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.title && formik.errors.title },
                  {
                    'is-valid': formik.touched.title && !formik.errors.title,
                  }
                )}
              />
              {formik.touched.title && formik.errors.title && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.title}</span>
                  </div>
                </div>
              )}
            </div>


            {/* MESSAGE */}
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6 '>{intl.formatMessage({ id: 'Message' })}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex flex-column w-100 align-items-start'>
                    <div className="mb-10 w-100">
                      <ReactQuill
                        style={{ width: '100%', height: 150 }}
                        readOnly={formik.getFieldProps('status').value === 'old'} // Alterado de "disabled" para "readOnly"
                        theme='snow'
                        placeholder={intl.formatMessage({ id: 'Message' })}
                        value={formik.getFieldProps('message').value || ''}
                        onChange={(value) => formik.setFieldValue('message', value)}
                      />
                    </div>


                    <div className="mt-4 fv-row">
                      <div className='form-check form-check-custom form-check-solid '>
                        <input
                          disabled={formik.getFieldProps('status').value === 'old'}
                          className='form-check-input'
                          type='checkbox'
                          id='kt_login_toc_agree'
                          checked={formik.getFieldProps('show_title').value === 1 ? true : false}
                          onChange={() => [formik.setFieldValue('show_title', formik.getFieldProps('show_title').value ? 0 : 1)]}
                        />
                        <label htmlFor='kt_login_toc_agree' className='form-check-label fw-bold text-gray-700 fs-6' >
                          {intl.formatMessage({ id: 'Display the Title described above to the user along with the informed message.' })}
                        </label>
                      </div>
                    </div>
                  </div>
                  {formik.touched.message && formik.errors.message && (
                    <div className='fv-plugins-message-container text-danger fs-6'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.message}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>


            </div>



            {formik.getFieldProps('id').value ?
              <div>

                <div className='fv-row mb-3'>
                  <label className='class="form-label fw-bold text-dark fs-5 mb-1'>{intl.formatMessage({ id: 'Status' })}</label>
                  <select
                    disabled={loading}
                    className='form-select form-control form-control-solid fs-6 mt-2'
                    {...formik.getFieldProps('status')}
                  >
                    <option value='active'>{intl.formatMessage({ id: 'Active' })}</option>
                    <option value='inactive'>{intl.formatMessage({ id: 'Inactive' })}</option>
                  </select>
                </div>



                <div className='fv-row mb-3'>
                  <label className='form-label fw-bold text-dark fs-5 '>{intl.formatMessage({ id: 'Url' })}</label>
                  <input
                    readOnly
                    placeholder={intl.formatMessage({ id: 'Url' })}
                    type='text'
                    title={`${intl.formatMessage({ id: 'Copy' })}`}
                    onClick={() => copyText(formik.getFieldProps('url').value, intl)}
                    autoComplete='off'
                    {...formik.getFieldProps('url')}
                    className='form-control form-control-lg form-control-solid'
                  />
                </div>
              </div>
              :
              null
            }


            <div className='text-center row'>

              <div className='text-center col-12 mt-12 d-flex'>
                <button
                  type='button'
                  className='btn bg-danger m-2 w-100 text-white'
                  //  disabled={!formik.values.acceptTerms}

                  onClick={() => handleCloseModalNew()}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'Cancel' })}</span>
                </button>



                <button
                  type='submit'
                  className='btn bg-primary text-dark m-2 w-100'
                >
                  {!loading && <span className='indicator-label'>
                    {formik.getFieldProps('id').value ?
                      intl.formatMessage({ id: 'Update' })
                      :
                      intl.formatMessage({ id: 'Save' })
                    }</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>


            </div>
          </form>


        </div>



      </Box>
    </Modal>
  )
}
export default ModalNewPreferenceConsent;