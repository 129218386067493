/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { delUsers, getUsersByCompany, defaultPaginationList, newUser, getSector } from '../../../../app/modules/helpers/Api'
import { converteData } from '../../../../app/modules/helpers/helpers'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import { useIntl } from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import Pagination from '../../../helpers/components/CustomPagination/CustomPagination'
import ViewUserCompany from '../pages/UsersCompany/ViewUserCompany'
import { trataRespota } from '../../../../app/modules/helpers/helpers'

let searchData = {
  title: '',
  code: '',
  page: 1,
  perpage: 10
}

type Props = {
  id: number,
  status: string,
  userData: any
}

const TablesUserCompany: React.FC<Props> = ({...props}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [viewUserList, setViewUserList] = useState(true);
  const [dataUser, setDataUser] = useState <any> ()
  const intl = useIntl();   
  const [id, setId] = useState <any>()

  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [sector, setSector] = useState('');
  const [errorSector, setErrorSector] = useState('');
  const [errorRole, setErrorRole] = useState('');
  const [errorEmail, setErrorEmail] = useState('');

  const [dataSector, setDataSector] = useState<any>([]);

  useEffect(()=>{
    handleGetList(null)
    const sector = getSector();
    sector.then((res)=>{
      setDataSector(res.data.data.data);
    });
  },[props.id, loading2]);

  function handleGetList(dataT:null) {
    setLoading(true);
    const get = defaultPaginationList('company/users?perpage=300&&company='+ props.id, searchData)
    get.then((res)=>{
      setData(res.data.data);
    }).catch((error)=>alert(error))
      .finally(()=>[setLoading(false), setLoading2]);
  }

  function handleEdition(action: boolean, id?: any){
    setId(id);
    setViewUserList(action);
  }
  function handleDelete (id: any){
    const del = delUsers(props.id, id);
    del.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      // toast.error(error.response.statusText?  error.response.statusText : 'Sorry, an error occurred!');
      toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}`);
    }).finally(()=>
      setTimeout(() => {
        setLoading2(false);
      }, 1000)
    );

  }

  function hanldeAddUserCode(){

    var error = false;
    if(role === ''){
      setErrorRole(`${intl.formatMessage({id: 'Type of user is required'})}`); 
      error = true
    }
    if(email === ''){setErrorEmail(`${intl.formatMessage({id: 'E-mail'})} ${intl.formatMessage({id: 'is required'})}`); error = true}
    if(role === 'company-user'){
      if(sector === ''){setErrorSector(`${intl.formatMessage({id: 'Sector'})} ${intl.formatMessage({id: 'is required'})}`); error = true}
    }

    if(!error){
      const data = {
        company: props.id,
        invite: role,
        sector: parseInt(sector) || null,
        email: email
      } 
 
      const save = newUser(data);
      save.then((res)=>{
        if(res.data.status === 'success'){
          toast.success(res.data.message);
        } else if (res.data.status === 'error'){
          toast.error(res.data.message);
        } else if (res.data.error_message_custom ){
          toast.error(res.data.error_message_custom);
        }
      }).catch((error)=>{
        trataRespota('error', error); 
        toast.error(error.response.data.message);
      })
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>


      {
        viewUserList ? 
        <div className=" row  p-6 d-flex " >

          <div className='col-12 d-flex justify-content-end' >

            {
              ['super-admin','company', 'dpo', 'manager-adm', 'manager', 'startup'].includes(user.roles)
              ?
              <button
                disabled={
                  props.userData.status === 'active' || user.trial
                  ? false
                  : !props.userData.data_manager || !props.userData.data_dpo
                  ? false
                  : true
                }
        
                className='btn btn-primary text-dark mx-2'
                onClick={()=>[
                  setEmail(''),
                  setSector(''),
                  setErrorRole(''),
                  setErrorEmail(''),
                  setErrorSector(''),
                ]}
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_user"
                title={intl.formatMessage({id: 'Add User'})}
              >
                { intl.formatMessage({id: 'New'})}
              </button>
              :
              <></>
            }

          </div>

        </div>
        :
        null
      }
      



      <div className="modal fade" tabIndex={-1} id="kt_modal_user">
        <div className="modal-dialog mw-700px">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title">{intl.formatMessage({id: 'Add an user'})}</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
            <div className='fv-row mb-4'>
              {/* COMPANY USER */}
              {
                  props.userData.data_manager?
                  <div className='mt-3'>
                    <label className='form-check form-check-custom form-check-solid align-items-center'>
                      <input
                        type='radio'
                        name='role'
                        defaultChecked={false}
                        className='form-check-input me-3'
                        onChange={()=>[setRole('company-user'), setErrorRole('')]}
                      />
                      <span className='form-check-label d-flex flex-column align-items-start'>
                        <span className='fw-bolder text-gray-800 fs-6 mb-0'>{intl.formatMessage({id: 'COMPANY USER'})}</span>
                        <span className='text-gray-600 fs-6 text-justify'>
                        {intl.formatMessage({id: "In charge of processing personal data of a company, and that does not fit the company's staff"})} 
                        </span>
                      </span>
                    </label>
                  </div>
                :
                <></>
              }

              {/* MANAGER */}
              {
                (user.roles === 'dpo' || user.roles === 'manager-adm') && !props.userData.data_manager?
                <div className='mt-3'>
                <label className='form-check form-check-custom form-check-solid align-items-center'>
                  <input
                    type='radio'
                    name='role'
                    defaultChecked={false}
                    className='form-check-input me-3'
                    onChange={()=>[setRole('manager'), setErrorRole('')]}
                  />
                  <span className='form-check-label d-flex flex-column align-items-start'>
                    <span className='fw-bolder text-gray-800 fs-6 mb-0'>{intl.formatMessage({id: 'MANAGER'})}</span>
                    <span className='text-gray-600 fs-6 text-justify'>
                    {intl.formatMessage({id: "Internal responsible for the company's adaptation program."})}
                    </span>
                  </span>
                </label>
              </div>
              :
              <></>
              }
              {/* DPO */}
              {
                user.roles === 'manager' && !props.userData.data_dpo?
                <div className='mt-3'>
                <label className='form-check form-check-custom form-check-solid align-items-center'>
                  <input
                    type='radio'
                    name='role'
                    defaultChecked={false}
                    className='form-check-input me-3'
                    onChange={()=>[setRole('dpo'), setErrorRole('')]}
                  />
                  <span className='form-check-label d-flex flex-column align-items-start'>
                    <span className='fw-bolder text-gray-800 fs-6 mb-0'>DPO</span>
                    <span className='text-gray-600 fs-6 text-justify'>
                    {intl.formatMessage({id: "In charge of processing personal data of a company, and that does not fit the company's staff"})}
                    </span>
                  </span>
                </label>
              </div>
              :
              <></>
              }
              {/* ADM USER */}
              {
                user.roles === 'manager-adm' && props.userData.data_manager?
                <div className='mt-3'>
                <div className='separator separator-dashed my-6'></div>

                <label className='form-check form-check-custom form-check-solid align-items-center'>
                  <input
                    type='radio'
                    name='role'
                    defaultChecked={false}
                    className='form-check-input me-3'
                    onChange={()=>[setRole('adm-user'), setErrorRole('')]}
                  />
                  <span className='form-check-label d-flex flex-column align-items-start'>
                    <span className='fw-bolder text-gray-800 fs-6 mb-0'>{intl.formatMessage({id: 'ADM USER'})}</span>
                    <span className='text-gray-600 fs-6 text-justify'>
                    {intl.formatMessage({id: "An organization composed of people whose objective is to carry out an economic activity seeking commercial results through the production and/or sale of goods and services"})}
                    </span>
                  </span>
                </label>
                <div className='separator separator-dashed my-6'></div>
              </div>
              :
              <></>
              }
              {errorRole && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errorRole}</span>
                  </div>
                </div>
              )}
            </div>
                {
                  dataSector.length !== 0?
                 <>
                  {
                    role === 'company-user'?
                    <div className='align-items-center'>
                    <div className='col-lg-12'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Sector'})}</label>
                            <select className='form-select form-control form-control-solid fs-5' 
                              onChange={e=>[setSector(e.currentTarget.value), setErrorSector('')]}
                              value={sector}
                            >
                              <option value=''>{intl.formatMessage({id: 'Select a sector'})}...</option>
                              {dataSector.map((item: any, index: any)=>
                                <option key={index} value={item.id}>{item.name}</option>
                              )}
                            </select>
                          {errorSector && (
                            <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errorSector}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  null
                  }
                 </>
              :
              'Lista de setores nao encontrada'
                }
              {
                role !== ''?
                <div className='align-items-center'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row mb-3'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'E-mail'})}</label>
                      <input
                        type="text"
                        className="form-control form-control-solid fs-6"
                        placeholder={`${intl.formatMessage({id: 'E-mail'})}`}
                        name='email'
                        value={email}
                        onChange={e => [setEmail(e.currentTarget.value), setErrorEmail('')]}
                      />
                      {errorEmail && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errorEmail}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              :
              <></>
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                {intl.formatMessage({id: 'Close'})}
              </button>
              <button type="button" onClick={()=>hanldeAddUserCode()} className="btn btn-primary text-dark">
              {intl.formatMessage({id: 'Send Invite'})}
              </button>
            </div>
          </div>
        </div>
      </div>




      <div className='card-body py-3'>
        {
          viewUserList ?
          <>
            <div className='table-responsive'>
            {
              loading && data.length === 0?
              <>...{intl.formatMessage({id: 'Loading'})}</>
              :
              <>
              {
                data.length === 0?
                <div className='d-flex flex-row aling-items-center my-6'>
                  <span className='fs-5 text-gray-600'>{intl.formatMessage({id: 'There are no registered users yet'})}</span>
                </div>
                :
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-150px'>{intl.formatMessage({id: 'USER'})}</th>
                      <th className='min-w-140px'>{intl.formatMessage({id: 'VERIFIED E-MAIL'})}</th>
                      <th className='min-w-140px'>{intl.formatMessage({id: 'SECTOR'})}</th>
                      <th className='min-w-120px'>{intl.formatMessage({id: 'TERMS'})}</th>
                      {
                        user.roles === 'manager-adm'?
                        <th className='min-w-120px text-center'>{intl.formatMessage({id: 'ACTIONS'})}</th>
                        :
                        <></>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {data.data.map((item: any)=>
                      <tr key={item.id}>
                        <td className='d-flex flex-row aling-items-center'>
                          <div className='symbol symbol-50px me-5'>
                            {
                              item.user_data.photo?
                              <img
                                src={item.user_data.photo}
                                className=''
                                alt=''
                              />
                              :
                              <img
                                src={toAbsoluteUrl('/media/avatars/blank.png')}
                                className=''
                                alt=''
                              />
                            }
                          </div>
                          <div className='d-flex flex-column justify-content-center'>
                            <span 
                              role={'button'} 
                              className='text-gray-800 fw-bold text-hover-primary fs-6'
                              // data-bs-toggle='modal'
                              // data-bs-target='#kt_modal_1'
                              onClick={()=>[setViewUserList(false), setDataUser(item)]}
                            >
                              {item.user_data.name} {item.user_data.last_name}
                            </span>
                            <span className='text-muted fw-bold text-gray-600 d-block fs-7'>{item.email}</span>
                          </div>
                        </td>
                        <td>
                          <span className='text-gray-800 fw-bold d-block fs-6'>
                            {converteData(item.email_verified_at)}
                          </span>
                        </td>
                        <td>
                          <span className='text-gray-800 fw-bold d-block fs-6'>
                            {item.sector_name}
                          </span>
                        </td>
                        <td>
                          <span className='text-gray-800 fw-bold d-block fs-6'>
                            {
                              item.use_terms?
                              <span className='badge badge-light-success fs-7'>{intl.formatMessage({id: 'Accepted'})}</span>
                              :
                              <span className='badge badge-light-danger fs-7'>{intl.formatMessage({id: 'Rejected'})}</span>
                            }
                          </span>
                        </td>
                        {
                          user.roles === 'manager-adm'?
                          <td className='d-flex align-items-center justify-content-center'>                      
                          <button 
                            disabled={props.status !== 'active' ? true : false}
                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                            onClick={()=>handleDelete(item.user_data.user_id)}
                          >
                            <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _trash_.svg')} style={{marginRight: '10px'}} />
                            {/* <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' /> */}
                          </button>
                        </td>
                        :
                        <></>
                        }
                      </tr>
                    )}
                  </tbody>
                </table>
              }
              </>
            }
            </div><Pagination data={data} onSearch={handleGetList} searchData={searchData}/>
          </>
          :
          <ViewUserCompany edition={handleEdition} data={dataUser}/>
      }
      </div> 
    </div>
  )
}

export {TablesUserCompany}
