import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG, toAbsoluteUrl } from '../../../../../helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { Load } from '../../../spinner/Spinner';
import { addAnswerQuestion, handleGapByForm, delGaps } from '../../../../../../app/modules/helpers/Api';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { useIntl } from 'react-intl'
import CreateEditGap from './CreateEditGap'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 350,
  width: '50%',
  // maxHeight: 550,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

interface IModalQuestionAddAnswerProps {
  question?: any;
  showModal: boolean;
  id: any;
  model: any;
  title: string;
  language: String;
  onClose: () => void;
  onAction: (answer: any) => void;
  onGetForm: () => void;
}

const QuestionAddAnswer: React.FC<IModalQuestionAddAnswerProps> = ({ ...props }) => {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [answer, setAnswer] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [errorAnswer, setErrorAnswer] = useState('');
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [data, setData] = useState<any>([]);
  const [original, setOriginal] = useState<any>([]);
  const [points, setPoints] = useState(0);
  const [gapArr, setGapArr] = useState<any>([]);
  const intl = useIntl();

  const [addGap, setAddGap] = useState(false);
  const [newGap, setNewGap] = useState(false);

  const [selectedGapId, setSselectedGapId] = useState('');

  const [loadingGap, setLoadingGap] = useState(false);


  const schema = Yup.object().shape({
    nextQuestion: Yup.string()
      .required(`${intl.formatMessage({ id: 'Next question is required' })}`),
  })
  useEffect(() => {

    if (props.showModal) {
      handleGapList()
      // const get = editionFormsList(props.id);
      // get.then((res) => {
      //   setData(res.data.data.gaps);
      //   setOriginal(res.data.data.gaps);
      // }).catch((error) => {
      //   toast.error(error.response.message)
      // }).finally(() => setLoading2(false));

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, props.showModal])

  const initialValues = {
    answer: '',
    answer_text: '',
    nextQuestion: '',
    next_question_text: '',
    points: '',
    teste: 0,
    gaps: []
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value, actions) => {

      var error = false;

      if (!value.answer) {
        toast.error(`${intl.formatMessage({ id: 'Unable to add information' })}`);
        return;
      }
      // if(answer === '') {setErrorAswer('Answer is required'); error = true}
      if (!error) {

        let answerData = null;

        if (props.model === 4) {
          answerData = {
            question: null,
            answer_name: value.answer === 'Outros' ? value.answer_text : value.answer,
            gap_list: { points: 0 },
            id: null,
            gaps: [],
            next_question: value.nextQuestion === 'Outros' ? parseInt(value.next_question_text) : parseInt(value.nextQuestion)
          }
          // props.onAction(data);
        }

        else if (props.model === 3) {
          answerData = {
            question: null,
            answer_name: value.answer === 'Outros' ? value.answer_text : value.answer,
            gap_list: { points: 0 },
            id: null,
            gaps: [],
            next_question: value.nextQuestion === 'Outros' ? parseInt(value.next_question_text) : parseInt(value.nextQuestion)
          }
          // props.onAction(data);
        }

        else if (props.model === 2) {

          answerData = {
            question: null,
            answer_name: value.answer === 'Outros' ? value.answer_text : value.answer,
            gap_list: { points: points },
            id: null,
            gaps: [],
            next_question: value.nextQuestion === 'Outros' ? parseInt(value.next_question_text) : parseInt(value.nextQuestion)
          }
          // props.onAction(data);
        }

        else if (props.model === 1) {
          answerData = {
            question: null,
            answer_name: value.answer === 'Outros' ? value.answer_text : value.answer,
            gap_list: { points: points },
            id: null,
            gaps: gapArr,
            next_question: value.nextQuestion === 'Outros' ? parseInt(value.next_question_text) : parseInt(value.nextQuestion)
          }
          // props.onAction(data);
        }

        if (answerData) {
          answerData['question'] = props.question;
          handleAnswerAdd(answerData);
        }

      }

    },
  })

  function handleAnswerAdd(data: any) {
    setLoading2(true)
    const get = addAnswerQuestion(data);
    get.then((res) => {

      if (res.data.status === 'success') {
        props.onClose();
        // actions.resetForm();
        setAnswer('');
        setPoints(0);
        setGapArr([]);
        setData(original)
        props.onGetForm();
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message)
      }

    }).catch((error) => {
      toast.error(error.response.message)
    }).finally(() => setLoading2(false));
  }

  function handleGap(value: any) {
    if (gapArr.indexOf(value) < 0) {
      // setGapArr([...gapArr, value.points])
      setGapArr([...gapArr, value.id])
      setPoints(Number(points) + Number(value.points));
    }
    setData((gaps: any) => gaps.filter((gap: any) => gap.value !== value.id))
  }

  function handleGapList() {
    setLoadingGap(true);
    const get = handleGapByForm(props.id);
    get.then((res) => {
      if (res.data.status === 'success') {
        clearGap();
        setData(res.data.data);
        setOriginal(res.data.data);

      } else {
        toast.error(res.data.message)
      }
    }).catch((error) => {
      toast.error(error.response.message)
    }).finally(() => { setLoadingGap(false); setLoading2(false) });
  }

  function clearGap() {
    setGapArr([]);
    setPoints(0);
    setData(original)
  }

  const answerOptions = [
    { value: 'Sim', label: `${intl.formatMessage({ id: 'Yes' })}` },
    { value: 'Não', label: `${intl.formatMessage({ id: 'No' })}` },
    { value: 'Todos', label: `${intl.formatMessage({ id: 'All' })}` },
    { value: 'Nenhum', label: `${intl.formatMessage({ id: 'None' })}` },
    { value: 'Talvez', label: `${intl.formatMessage({ id: 'Perhaps' })}` },
    { value: 'Em parte', label: `${intl.formatMessage({ id: 'In part' })}` },
    { value: 'Algo semelhante', label: `${intl.formatMessage({ id: 'Something similar' })}` },
    { value: 'Mais', label: `${intl.formatMessage({ id: 'More' })}` },
    { value: 'Menos', label: `${intl.formatMessage({ id: 'Less' })}` },
  ]

  function handleChange2(e: any) {

    if (e) {
      formik.setFieldValue('answer', e.value)
    }



  }

  function handleInputChange2(e: any) {

    if (e) {
      formik.setFieldValue('answer', e)
    }



  }

  function cancelNewGap() {
    setNewGap(false);
  }

  function handleDeleteGap() {
    setLoadingGap(true)
    const del = delGaps(selectedGapId);
    del.then((res) => {
      if (res.data.status === 'success') {
        toast.success(res.data.message);
        handleGapList()
      }
      if (res.data.status === 'error') {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      toast.error(error.response.data.message);
    }).finally(() => {
      setLoadingGap(false);
      setSselectedGapId('');
    })
  }

  return (
    <Modal
      open={props.showModal}
      onClose={() => { }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
          <span className="modal-title fw-bold fs-3">{addGap ? (newGap ? 'Gap' : 'Seleção de Gaps') : props.title}</span>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={() => [props.onClose(), clearGap(), setAddGap(false)]}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        {
          loading2 ?
            <Load />
            :
            <div>

              {!addGap ?
                <form onSubmit={formik.handleSubmit} noValidate className='form' id='form'>
                  <div className='card-body'>
                    <div className='col-lg-12'>
                      <div className='row m-1'>

                        <div className='col-sm-12 fv-row mb-5'>
                          <span className='col-lg-4 fs-5 text-gray-700 required'>{intl.formatMessage({ id: 'Select or type an answer' })}</span>
                          <CreatableSelect
                            isClearable
                            onChange={e => handleChange2(e)}
                            onInputChange={e => handleInputChange2(e)}
                            options={answerOptions}
                          />
                        </div>

                        {
                          formik.values.nextQuestion !== 'Outros' ?
                            <div className='col-sm-12 fv-row mb-5'>
                              <span className='col-lg-4 fs-5 text-gray-700 required'>{intl.formatMessage({ id: 'Next question' })}</span>

                              <ul className="text-muted mt-4 mb-4">
                                <li>{intl.formatMessage({ id: 'Enter 0 to end the form with this answer' })}</li>
                                <li>{intl.formatMessage({ id: 'Enter 1 if there is a next question and you want to go to it' })}</li>
                                <li>{intl.formatMessage({ id: 'If you want to skip questions, consider the sum of the current question number plus the value entered' })}</li>
                                <li>{intl.formatMessage({ id: 'Only numbers between 0 and 100' })}</li>
                              </ul>
                              {/* <select className='form-select form-control form-control-solid fs-6 mt-2' 
                              {...formik.getFieldProps('nextQuestion')}
                            >
                              <option value='' disabled>Selecione</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                            </select> */}
                              <input
                                type='number'
                                placeholder={intl.formatMessage({ id: 'Insert a number' })}
                                className='form-control form-control-lg form-control-solid mt-2'
                                {...formik.getFieldProps('nextQuestion')}
                              />
                              {/* {formik.touched.nextQuestion && formik.errors.nextQuestion && (
                              <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{formik.errors.nextQuestion}</span>
                                </div>
                              </div>
                            )} */}
                            </div>
                            :
                            <div className='col-lg-6 fv-row mb-3'>
                              <span className='col-lg-4 fs-5 text-gray-700 required'>{intl.formatMessage({ id: 'Next question' })} ({intl.formatMessage({ id: 'Current question' })} +)</span>
                              <input
                                type='text'
                                placeholder={intl.formatMessage({ id: 'Inform next question' })}
                                className='form-control form-control-lg form-control-solid mt-2'
                                {...formik.getFieldProps('next_question_text')}
                              />
                            </div>
                        }
                        <div className='fv-row my-4'>
                          {errorAnswer && (
                            <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errorAnswer}</span>
                              </div>
                            </div>
                          )}

                          {props.model === 1 ?
                            <>
                              <div>
                                Total de pontos em gaps <strong className='mb-4'> {points >= 10 ? points : '0' + points}</strong>,
                                Click <strong onClick={() => [setAddGap(true)]} style={{ cursor: 'pointer', color: 'red' }}>{intl.formatMessage({ id: 'here' })} </strong>
                                para adicionar.
                              </div>



                            </>
                            :
                            props.model === 2 ?
                              <div>
                                <label className='col-lg-4 fs-5 text-gray-700 required'>{intl.formatMessage({ id: 'Points' })}</label>
                                <input
                                  type='text'
                                  placeholder={intl.formatMessage({ id: 'Inform the answer' })}
                                  className='form-control form-control-lg form-control-solid mt-2'
                                  onChange={e => setPoints(Number(e.target.value))}
                                  value={points}

                                />
                              </div>
                              :
                              <></>
                          }

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='card-footer'>

                    <div className='d-flex flex-row w-100 justify-content-end p-5'>
                      <button
                        type="button"
                        className="btn btn-danger mx-2"
                        onClick={() => [props.onClose(), setAnswer(''), formik.resetForm(), clearGap()]}
                      >
                        {intl.formatMessage({ id: 'Cancel' })}
                      </button>
                      <button type='submit' form='form' className='btn btn-primary text-white' disabled={loading}>
                        {!loading && intl.formatMessage({ id: 'Add' })}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            {intl.formatMessage({ id: 'Processing' })}...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>

                  </div>
                </form>
                :
                <div>

                  {newGap ?
                    <div>
                      <CreateEditGap onCancelGap={cancelNewGap} onGetList={handleGapList} prescription={props.id} lang={props.language} />
                    </div>
                    :

                    <div>
                      {loadingGap ?
                        <div className='text-center my-5'>Carregando, aguarde...</div>
                        :
                        <div className='border-gray-300 border-2 p-5 hover pointer rounded'>

                          {original.length > 0 ?
                            <p>{intl.formatMessage({ id: 'click' })} <strong onClick={() => setNewGap(true)} style={{ cursor: 'pointer', color: 'red' }}>{intl.formatMessage({ id: 'here' })}</strong> para adicionar um novo gap</p>
                            :
                            null
                          }

                          {
                            original.length > 0 ?

                              <div>

                                <div style={{ overflow: 'auto', overflowX: 'hidden', maxHeight: 300 }}>

                                  <div>
                                    {data.map((item: any, index: any) =>

                                      <div className='mb-2 d-flex align-items-center'>
                                        <button
                                          className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm mx-2'
                                          onClick={() => setSselectedGapId(item.value)}
                                          data-bs-toggle='modal'
                                          data-bs-target='#kt_modal_del_gap'
                                        >
                                          <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _trash_.svg')} />
                                        </button>
                                        <button type='button' onClick={() => handleGap({ id: item.value, points: item.degree })} className='btn btn-sm btn-primary text-white'>{intl.formatMessage({ id: 'Select' })}</button>
                                        <div className='fw-bold text-gray-800 fs-6 mx-4'>{item.text}</div>
                                      </div>
                                      // <div key={index} className='mt-4 border-dashed border-primary bg-light-primary border-1 p-5 rounded'>
                                      //   <div className='d-flex flex-column'>
                                      //     <span className='d-flex flex-column align-items-start'>
                                      //       <span className='fw-bold text-gray-800 fs-6 mb-0'>{item.text}</span>
                                      //     </span>
                                      //     <button type='button' onClick={()=>handleGap({
                                      //       id: item.value,
                                      //       points: item.degree
                                      //     })} className='btn btn-sm w-100 btn-primary text-white mt-5'>{intl.formatMessage({id: 'Select'})}</button>
                                      //   </div>
                                      // </div>
                                    )
                                    }



                                  </div>

                                </div>

                                <div className='mt-5'>
                                  <p>
                                    <strong>{data.length > 0 && data.length < 10 ? '0' + data.length : data.length}</strong> {intl.formatMessage({ id: 'remaining item' })},


                                  </p>

                                  <div className='text-center  '>
                                    <div><strong className='fs-3 text-danger mb-4'>{points >= 10 ? points : '0' + points} pts.</strong></div>
                                    <div>{intl.formatMessage({ id: 'click' })} <strong onClick={() => clearGap()} style={{ cursor: 'pointer', color: 'red' }}>{intl.formatMessage({ id: 'here' })}</strong> {intl.formatMessage({ id: 'to reset the score' })}</div>

                                  </div>

                                </div>

                              </div>

                              :
                              <div className='text-center my-5'>Nenhum Gap encontrado, click <strong onClick={() => setNewGap(true)} style={{ cursor: 'pointer', color: 'red' }}>{intl.formatMessage({ id: 'here' })}</strong> para adicionar</div>
                          }


                        </div>
                      }

                      <div className='d-flex flex-row w-100 justify-content-end p-5'>
                        <button type='button' onClick={() => setAddGap(false)} className='btn btn-sm btn-primary text-white mt-5' disabled={loadingGap}>{intl.formatMessage({ id: 'back' })}</button>
                      </div>
                    </div>
                  }

                </div>
              }
            </div>
        }



        <div className="modal fade" tabIndex={-1} id="kt_modal_del_gap">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fs-3">{intl.formatMessage({ id: 'Attention' })}</h5>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                  />
                </div>
              </div>
              <div className="modal-body">
                <p className='text-gray-700 fs-4'>{intl.formatMessage({ id: 'Are you sure you want to delete this item?' })}</p>
              </div>
              <div className="modal-footer">

                <button type="button" onClick={() => handleDeleteGap()} data-bs-dismiss="modal" className="btn btn-primary text-white">
                  {intl.formatMessage({ id: 'Yes' })}
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  {intl.formatMessage({ id: 'No' })}
                </button>
              </div>
            </div>
          </div>
        </div>



      </Box>
    </Modal>
  )
}
export default QuestionAddAnswer;