import react from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../../helpers';
import { useIntl } from 'react-intl'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IModalCalculatorAlertProps {
  showModal: boolean;
  message: string;
  title: string;
  onClose: () => void;
  onAction: () => void;
}

const ModalCalculatorAlert: React.FC<IModalCalculatorAlertProps> = ({...props}) => {
  const intl = useIntl();   

  return(
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-70px d-flex flex-row justify-content-between align-items-center w-100'>
          <div className='d-flex flex-row align-items-center'>
              <div className="d-flex bg-light p-3 align-items-center justify-content-center rounded me-4">
                <KTSVG
                  path="/media/icons/duotune/general/gen044.svg"
                  className="svg-icon svg-icon-2x svg-icon-danger"
                />
              </div>
              <span className="modal-title fw-bold fs-3">{props.title}</span>
          </div>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>props.onClose()}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className='px-5 my-5 text-left' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 350}}>
          
          <div className='d-flex flex-column align-items-center mb-5 w-100'>
            <div className='d-flex flex-column w-100'>
              <div className='w-100 d-flex flex-column rounded align-items-center text-center p-3 mb-2'>
                <span className='text-dark fw-bolder fs-4'>{props.message}</span>
              </div>
            </div>
          </div>

        </div>
        <div className='d-flex flex-row w-100 justify-content-end p-5'>
          <button type='button' onClick={()=>props.onAction()} className='btn btn-md btn-primary text-white'>
          {intl.formatMessage({id: 'Continue'})}
          </button>
        </div>
      </Box>
    </Modal>
  )
}
export default ModalCalculatorAlert;