import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../helpers';
import {useIntl} from 'react-intl'

let widthVar = 450

if(window.screen.width < 500){
    widthVar = 330
    if(window.screen.width < 300){
      widthVar = 250
  }
  
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: widthVar,
  maxHeight: 450,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

interface IModalMessageProps {
  showModal: boolean;
  title: string;
  message: string;
  onClose: () => void;
  onAction: () => void;
}

const ModalMessage: React.FC<IModalMessageProps> = ({
  ...props
}) => {
  const intl = useIntl(); 

  return(
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
          <span className="modal-title fw-bold fs-3">{props.title}</span>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>props.onClose()}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className='px-5 my-10 text-center' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 350}}>
          <span className='text-gray-700 fw-bold fs-4'>{props.message}</span>
        </div>
        <div className='d-flex flex-row w-100 justify-content-end p-5'>
          <button type='button' onClick={()=>props.onAction()} className='btn btn-md btn-primary text-white me-3'>
          {intl.formatMessage({id: 'Yes'})}
          </button>
          <button type='button' onClick={()=>props.onClose()} className='btn btn-md btn-danger '>
          {intl.formatMessage({id: 'No'})} 
          </button>
        </div>
      </Box>
    </Modal>
  )
}
export default ModalMessage;