/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Formik } from 'formik'
import { defaultPaginationList, getActionsById, upActions } from '../../../../app/modules/helpers/Api'
import { toast } from 'react-toastify'
import { trataRespota } from '../../../../app/modules/helpers/helpers'
import { KTSVG } from '../../../helpers'
import { useIntl } from 'react-intl'
import { Load } from '../spinner/Spinner'
import ActionSelectGap from './ActionSelectGap'


type Props = {
  create: (action: boolean) => void;
  id: any;
  idModulo: any;
  idForm: any;
}
let searchData = {
  title: '',
  code: '',
  module: '',
  form: '',
  page: 1,
  perpage: 100000,
}

let splitVar = 60

if (window.screen.width < 670) {
  splitVar = 50
  if (window.screen.width < 570) {
    splitVar = 40
    if (window.screen.width < 490) {
      splitVar = 25
      if (window.screen.width < 300) {
        splitVar = 15
      }
    }
  }

}

const EditionAction: FC<Props> = ({ ...val }) => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [data, setData] = useState<any>([]);
  const intl = useIntl();
  const [gapSelect, setGapSelect] = useState(`${intl.formatMessage({ id: 'Select' })}`);
  const [openModalSelectGap, setOpenModalSelectGap] = useState(false);
  const [gapList, setGapList] = useState<any>([]);
  const [gapSelectId, setGapSelectId] = useState('');

  const schema = Yup.object().shape({
    title: Yup.string()
      .required(`${intl.formatMessage({ id: 'Title' })} ${intl.formatMessage({ id: 'is required' })}`),
    degree_max: Yup.string()
      .required(`${intl.formatMessage({ id: 'Max Degree' })} ${intl.formatMessage({ id: 'is required' })}`),
    degree_min: Yup.string()
      .required(`${intl.formatMessage({ id: 'Min Degree' })} ${intl.formatMessage({ id: 'is required' })}`),
    value: Yup.string()
      .required(`${intl.formatMessage({ id: 'Value' })} ${intl.formatMessage({ id: 'is required' })}`),
    model: Yup.string()
      .required(`${intl.formatMessage({ id: 'Model' })} ${intl.formatMessage({ id: 'is required' })}`)
    // title_resource: Yup.string()
    // .required('Title resource is required'),
    // url: Yup.string()
    // .required('Url is required'),
  })
  useEffect(() => {
    setLoading2(true);
    const get = getActionsById(val.id);
    get.then((res) => {
      setData(res.data.data);
    }).catch((error) => alert(error))
      .finally(() => setLoading2(false));
  }, [val.id]);

  const initialValues = {
    title: data.title,
    degree_max: data.degree_max,
    degree_min: data.degree_min,
    value: data.action_legend_id,
    model: data.model,
  }

  function save(values: any) {
    const data = {
      title: values.title,
      degree_max: values.degree_max,
      degree_min: values.degree_min,
      action_legend_id: values.value,
      model: values.model,
      modulo_id: val.idModulo,
      form_id: val.idForm,
      gap_id: gapSelectId
    }

    setLoading(true);

    const create = upActions(val.id, data);
    create.then((res) => {
      if (res.data.status === 'success') {
        toast.success(res.data.message);
        val.create(false);
      }
      if (res.data.status === 'error') {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      trataRespota('error', error);
      // toast.error(error.response.data.message);
    })
    .finally(() => setLoading(false));
  }
  function handleGetGapList() {

    searchData.module = val.idModulo;
    searchData.form = val.idForm;

    setLoading(true);
    const action = defaultPaginationList('gaps', searchData);
    action.then((res) => {
      setGapList(res.data.data.data);
      setOpenModalSelectGap(true)
    }).catch((error) => {
      toast.error(error.response.data.message)
    })
      .finally(() => setLoading(false));
  }


  function handleSelect(id: any, title: any) {
    setGapSelect(title)
    setGapSelectId(id)
    setOpenModalSelectGap(false);
  }

  return (
    <>
      {
        gapList !== 0 ?
          <ActionSelectGap
            dataAction={gapList}
            title={intl.formatMessage({ id: 'Select a Gap' })}
            showModal={openModalSelectGap}
            onClose={() => setOpenModalSelectGap(false)}
            onAction={handleSelect}
          />
          :
          <></>
      }
      {
        loading2 ?
          <><Load /></>
          :
          <div className='card mb-5 mb-xl-10'>
            <div className='collapse show'>
              <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(values) => {
               
                
                    save(values);
                   
               
                }}
              >
                {
                  props => (
                    <form onSubmit={props.handleSubmit} noValidate className='form'>
                      <div className='card-body border-top px-5 py-5'>
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                          <label className='form-label fw-bolder text-dark fs-3 '>{intl.formatMessage({ id: 'Edit action' })}</label>
                          <button onClick={() => val.create(false)} type='button' className='btn btn-sm btn-secondary p-3'>
                            <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark' />
                            {intl.formatMessage({ id: 'Back' })}
                          </button>

                        </div>

                        <div className='mb-2 fv-row border-top' data-kt-password-meter='true'>
                          <div className='mb-1 mt-4'>
                            <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Title' })}</label>
                            <div className='position-relative mb-3'>
                              <div className='d-flex justify-content-between w-100'>
                                <input
                                  type='text'
                                  placeholder={intl.formatMessage({ id: 'Title' })}
                                  autoComplete='off'
                                  name='title'
                                  onChange={props.handleChange}
                                  value={props.values.title}
                                  className={clsx(
                                    'form-control form-control-lg form-control-solid w-100 me-1',
                                    {
                                      'is-invalid': props.touched.title && props.errors.title,
                                    },
                                    {
                                      'is-valid': props.touched.title && !props.errors.title,
                                    }
                                  )}
                                />
                              </div>
                              {props.touched.title && props.errors.title && (
                                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.title}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className='mb-2 fv-row' data-kt-password-meter='true'>
                            <div className='mb-1'>
                              <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Model' })}</label>
                              <div className='position-relative mb-3'>
                                <div className='d-flex justify-content-between w-100'>
                                  <select className='form-select form-select-solid form-select-lg fw-bold fs-4'
                                    name='model'
                                    onChange={props.handleChange}
                                    value={props.values.model}
                                  >
                                    <option value="">{intl.formatMessage({ id: 'Select a Model' })}</option>
                                    <option value="1">{intl.formatMessage({ id: 'Maturity analysis' })}</option>
                                    <option value="2">{intl.formatMessage({ id: 'Scored' })}</option>
                                    <option value="3">{intl.formatMessage({ id: 'Not scored' })}</option>
                                    <option value="4">{intl.formatMessage({ id: 'Data Mapping' })}</option>
                                  </select>
                                </div>
                                {props.touched.model && props.errors.model && (
                                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{props.errors.model}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className='mb-1 mt-4'>
                            <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Max Degree' })}</label>
                            <div className='position-relative mb-3'>
                              <div className='d-flex justify-content-between w-100'>
                                <input
                                  type='text'
                                  placeholder={intl.formatMessage({ id: 'Max Degree' })}
                                  autoComplete='off'
                                  name='degree_max'
                                  onChange={props.handleChange}
                                  value={props.values.degree_max}
                                  className={clsx(
                                    'form-control form-control-lg form-control-solid w-100 me-1',
                                    {
                                      'is-invalid': props.touched.degree_max && props.errors.degree_max,
                                    },
                                    {
                                      'is-valid': props.touched.degree_max && !props.errors.degree_max,
                                    }
                                  )}
                                />
                              </div>
                              {props.touched.degree_max && props.errors.degree_max && (
                                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.degree_max}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='mb-1 mt-4'>
                            <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Min Degree' })} </label>
                            <div className='position-relative mb-3'>
                              <div className='d-flex justify-content-between w-100'>
                                <input
                                  type='text'
                                  placeholder={intl.formatMessage({ id: 'Min Degree' })}
                                  autoComplete='off'
                                  name='degree_min'
                                  onChange={props.handleChange}
                                  value={props.values.degree_min}
                                  className={clsx(
                                    'form-control form-control-lg form-control-solid w-100 me-1',
                                    {
                                      'is-invalid': props.touched.degree_min && props.errors.degree_min,
                                    },
                                    {
                                      'is-valid': props.touched.degree_min && !props.errors.degree_min,
                                    }
                                  )}
                                />
                              </div>
                              {props.touched.degree_min && props.errors.degree_min && (
                                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.degree_min}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='mb-1 mt-4'>
                            <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Legend' })}</label>
                            <div className='position-relative mb-3'>
                              <div className='d-flex justify-content-between w-100'>
                                <select className='form-select form-control form-control-solid fs-6'
                                  name='value'
                                  onChange={props.handleChange}
                                  value={props.values.value}
                                >
                                  <option value=''>...{intl.formatMessage({ id: 'Select an option' })}</option>
                                  <option style={{ backgroundColor: '#c70100', color: '#fff' }} value='1'>1</option>
                                  <option style={{ backgroundColor: '#008fc7', color: '#fff' }} value='2'>2</option>
                                  <option style={{ backgroundColor: '#01771f', color: '#fff' }} value='3'>3</option>
                                  <option style={{ backgroundColor: '#01c732', color: '#fff' }} value='4'>4</option>
                                  <option style={{ backgroundColor: '#000000', color: '#fff' }} value='5'>5</option>
                                  <option style={{ backgroundColor: '#af48cf', color: '#fff' }} value='6'>6</option>
                                </select>
                                {/* <input
                          type='text' 
                          placeholder='Value'
                          autoComplete='off'
                          name='value'
                          onChange={props.handleChange}
                          value={props.values.value}
                          className={clsx(
                            'form-control form-control-lg form-control-solid w-100 me-1',
                            {
                              'is-invalid': props.touched.value && props.errors.value,
                            },
                            {
                              'is-valid': props.touched.value && !props.errors.value,
                            }
                          )}
                        /> */}
                              </div>
                              {props.touched.value && props.errors.value && (
                                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.value}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>



                        <div className='col-lg-12 fv-row'>
                          <label className='col-lg-4 col-form-label fw-bold '>{intl.formatMessage({ id: 'Gaps' })}</label>

                          {data && data.gaps ?
                            <ul>
                              {data.gaps.map((item: any, index: any) =>
                                <li key={index} >
                                  <span>{item.gap}</span>
                                </li>
                              )}

                              {gapSelectId ?
                                <li><span onClick={() => handleGetGapList()} className='text-primary cursor-pointer'>{gapSelect}</span></li>
                                :
                                <li><span onClick={() => handleGetGapList()} className='text-primary cursor-pointer'>Adicionar Lacuna</span></li>
                              }


                            </ul>
                            :
                            null
                          }

                        </div>



                      </div>
                      <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary text-dark' disabled={loading}>
                          {!loading && intl.formatMessage({ id: 'Save' })}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              {intl.formatMessage({ id: 'Processing' })}...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                  )
                }
              </Formik>
            </div>
          </div>
      }
    </>
  )
}

export { EditionAction }
