/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { StepFour } from './Step04'

const schema = Yup.object().shape({
  share: Yup.string()
  .required('Share is required'),
})

type Props = {
  move: (action: boolean) => void;
  dataStep1: any;
  dataStep2: any;
}

const StepThree: FC<Props> = ({...props}) => {  

  const[loading, setLoading] = useState(false);
  const[next, setNext] = useState('');
  const[dataStep3, setDataStep3] = useState<any>({});
  const[shareWithWhom, setShareWithWhom] = useState('empresa');

  const initialValues = {
    share: '',
    share_with_whom: '',
    share_why: '',
    share_like: ''
  }

  function move (action: boolean) {
    if(action){
      setNext('');
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false)
        setDataStep3({
          share: values.share === 'true'? 1 : 0,
          share_with_whom: shareWithWhom,
          share_why: values.share_why,
          share_like: values.share_like
        })
        setNext('Next')
      }, 1000)
    },
  })

  return (
    <>
    { next === ''?
    <div className='card mb-5 mb-xl-10'>
      <div className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top px-5 py-5'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <label className='form-label fw-bolder text-dark fs-3 '>New Data Mapping - Step 03 of 06</label>
              {/* <button onClick={()=>props.create(false)} type='submit' className='btn btn-sm btn-secondary'>
                Back
              </button> */}
            </div>
            <div className='mb-2 fv-row border-top pt-5' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold required text-dark fs-6'>Share</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('share')}
                    >
                      <option value=''>...Select an option</option>
                      <option value='true'>Sim</option>
                      <option value='false'>Não</option>
                    </select>
                  </div>
                  {formik.touched.share && formik.errors.share && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.share}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {
              formik.values.share === 'true'?
              <>
                <div className='mb-2 fv-row me-10' data-kt-password-meter='true'>
                  <div className='mb-1 mt-4'>
                    <label className='form-label fw-bold required text-dark fs-6'>Share with Whom</label>
                    <div className='position-relative mb-3'>
                      <div className='mt-3'>
                        <label className='form-check form-check-custom form-check-solid align-items-center'>
                          <input
                            type='checkbox'
                            name='role'
                            className='form-check-input me-1'
                            // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                          />
                          <span className='form-check-label d-flex flex-column align-items-start'>
                            <span className='fw-bolder text-gray-800 fs-6 mb-0'>EMPRESA</span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='position-relative mb-3'>
                      <div className='mt-3'>
                        <label className='form-check form-check-custom form-check-solid align-items-center'>
                          <input
                            type='checkbox'
                            name='role'
                            className='form-check-input me-1'
                            // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                          />
                          <span className='form-check-label d-flex flex-column align-items-start'>
                            <span className='fw-bolder text-gray-800 fs-6 mb-0'>PARCEIRO</span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='position-relative mb-3'>
                      <div className='mt-3'>
                        <label className='form-check form-check-custom form-check-solid align-items-center'>
                          <input
                            type='checkbox'
                            name='role'
                            className='form-check-input me-1'
                            // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                          />
                          <span className='form-check-label d-flex flex-column align-items-start'>
                            <span className='fw-bolder text-gray-800 fs-6 mb-0'>CLIENTE</span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='position-relative mb-3'>
                      <div className='mt-3'>
                        <label className='form-check form-check-custom form-check-solid align-items-center'>
                          <input
                            type='checkbox'
                            name='role'
                            className='form-check-input me-1'
                            // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                          />
                          <span className='form-check-label d-flex flex-column align-items-start'>
                            <span className='fw-bolder text-gray-800 fs-6 mb-0'>FORNECEDOR</span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='position-relative mb-3'>
                      <div className='mt-3'>
                        <label className='form-check form-check-custom form-check-solid align-items-center'>
                          <input
                            type='checkbox'
                            name='role'
                            className='form-check-input me-1'
                            // onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                          />
                          <span className='form-check-label d-flex flex-column align-items-start'>
                            <span className='fw-bolder text-gray-800 fs-6 mb-0'>INSTITUIÇÃO PÚBLICA - ÓRGÃOS GOVERNAMENTAIS</span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mb-2 fv-row' data-kt-password-meter='true'>
                  <div className='mb-1 mt-4'>
                    <label className='form-label fw-bold required text-dark fs-6'>Share Why</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder='Share Why'
                          autoComplete='off'
                          {...formik.getFieldProps('share_why')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid w-100 me-1',
                            
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mb-2 fv-row' data-kt-password-meter='true'>
                  <div className='mb-1 mt-4'>
                    <label className='form-label fw-bold required text-dark fs-6'>Share Like</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder='Share Like'
                          autoComplete='off'
                          {...formik.getFieldProps('share_like')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid w-100 me-1',
                            
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
              :
              <></>
            }
            
            
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-secondary me-3' onClick={()=>props.move(true)}>
              Back
            </button>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Next'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Processando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
      :
      <StepFour 
        move={move}
        dataStep1={props.dataStep1}
        dataStep2={props.dataStep2}
        dataStep3={dataStep3}
      />
    }
    </>
  )
}

export {StepThree}
