/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { countries } from '../../../../../../app/modules/helpers/country'
import { StepFive } from './Step05'

const schema = Yup.object().shape({
  location_treatment: Yup.string()
  .required('Location Treatment is required'),
  country_has_treatment: Yup.string()
  .required('Country has Treatment is required'),
})

type Props = {
  move: (action: boolean) => void;
  dataStep1: any;
  dataStep2: any;
  dataStep3: any;
}

const StepFour: FC<Props> = ({...props}) => {  

  const[loading, setLoading] = useState(false);
  const[next, setNext] = useState('');
  const[dataStep4, setDataStep4] = useState<any>({});

  const initialValues = {
    location_treatment: '',
    country_has_treatment: '',
  }

  function move (action: boolean) {
    if(action){
      setNext('');
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false)
        setDataStep4({
          location_treatment: values.location_treatment,
          country_has_treatment: values.country_has_treatment
        })
        setNext('Next');
      }, 1000)
    },
  })

  return (
    <>
    { next === ''?
    <div className='card mb-5 mb-xl-10'>
      <div className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top px-5 py-5'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <label className='form-label fw-bolder text-dark fs-3 '>New Data Mapping - Step 04 of 06</label>
              {/* <button onClick={()=>props.create(false)} type='submit' className='btn btn-sm btn-secondary'>
                Back
              </button> */}
            </div>
            <div className='mb-2 fv-row border-top pt-5' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold required text-dark fs-6'>Location Treatment</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('location_treatment')}
                    >
                      <option value=''>...Select an option</option>
                      {
                        countries.map((item: any)=>
                          <option key={item.name} value={item.code}>{item.name}</option>
                        )
                      }
                    </select>
                  </div>
                  {formik.touched.location_treatment && formik.errors.location_treatment && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.location_treatment}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='mb-1'>
                <label className='form-label fw-bold required text-dark fs-6'>Country has Treatment</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('country_has_treatment')}
                    >
                      <option value=''>...Select an option</option>
                      <option value='sim'>Sim</option>
                      <option value='não'>Não</option>
                    </select>
                  </div>
                  {formik.touched.country_has_treatment && formik.errors.country_has_treatment && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.country_has_treatment}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-secondary me-3' onClick={()=>props.move(true)}>
              Back
            </button>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              
              {!loading && 'Next'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Processando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
      :
      <StepFive 
        move={move}
        dataStep1={props.dataStep1}
        dataStep2={props.dataStep2}
        dataStep3={props.dataStep3}
        dataStep4={dataStep4}
      />
    }
    </>
  )
}

export {StepFour}
