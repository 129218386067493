/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { deleteUser, getUsers, defaultPaginationList } from '../../../../../app/modules/helpers/Api';
import { converteData, converteData2 } from '../../../../../app/modules/helpers/helpers';
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import ModalMessage from '../../modals/modalMessage';
import { Load } from '../../spinner/Spinner';
import Warning from '../../warning/Warning';
import { useIntl } from 'react-intl'

import Pagination from '../../../../helpers/components/CustomPagination/CustomPagination'
import { ModalReportUser } from './ModalReportUser';


let searchData = {
  title: '',
  code: '',
  page: 1,
  perpage: 10,
  email: '',
  status: ''
}

type Props = {
  create: (action: boolean) => void;
  edition: (action: boolean, id: any) => void;
}

const UsersList: React.FC<Props> = ({...props}) => {

  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState<any>([]);
  const [id, setId] = useState('');
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const intl = useIntl();   
  const [emailFilter, setEmailFilter] = useState <any> ('');
  const [statusFilter, setStatusFilter] = useState <any> ('');
  const [openModalReport, setOpenModalReport] = useState(false);

  searchData.email = emailFilter
  searchData.status = statusFilter

  useEffect(()=>{
    handleGetList(null)
  },[loading2]);
  
  function closeModal(closeModal: any){
    setOpenModalReport(closeModal)
  }

  function handleGetList(data:null) {
    setLoading(true);
    const get = defaultPaginationList('users', searchData)
    get.then((res)=>{
      setDataUser(res.data.data);
    }).catch((error)=>{
      toast.error(error.response.data.message)
    })
    .finally(()=>[setLoading(false), setLoading2]);
  }

  function handleDelete(id: any) {
    const del = deleteUser(id);
    del.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        setOpenModalDelete(false);
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      } else {
        toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}` )
      }
    }).finally(()=>setLoading2(true));
  } 

  return (
    <>
        <h3 className='card-title align-items-start flex-column mb-3'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: 'Users List'})}</span>&nbsp;
          &nbsp;&nbsp;&nbsp;
          {/* <button 
          onClick={()=>setOpenModalReport(true)}
          className='btn btn-light-warning btn-sm'>{intl.formatMessage({id: 'Report'})}</button> */}
        </h3>
        
      {
        loading?
        <Load/>
        :
        <div className={`card card mb-5 mb-xl-10`}>
          <ModalMessage
            message={intl.formatMessage({id: 'Are you sure you want to delete this item?'})} 
            title={intl.formatMessage({id: 'Attention'})}
            showModal={openModalDelete}
            onClose={()=>setOpenModalDelete(false)}
            onAction={()=>handleDelete(id)}
          />
          <div className='row d-flex align-items-center justify-content-end card-header border-0 pt-5'>
          <div className='col-md-4'>
              
                <input
                      type="text"
                      className="form-control form-control-solid fs-6 rounded-3"
                      placeholder={`${intl.formatMessage({id: 'Search a form'})}`}
                      autoComplete='off'
                      value={emailFilter}
                      style={{height: '40px'}}
                      onChange={(e)=>[setEmailFilter(e.target.value)]}
                      onKeyDown={(e)=>e.which === 13 ? handleGetList(null) : '' }
                    
                  />
              
          </div>
            {/* <div className='col-7 d-flex justify-content-end align-items-center'> */}
              <div className='col-md-2 '>
                  <select className='form-select form-control form-control-solid fs-6' 
                    value={statusFilter}
                    onChange={(e)=>[setStatusFilter(e.target.value)]}
                    style={{height: '40px'}}
                  >
                    <option value='' >{intl.formatMessage({id: 'All Status'})}</option> 
                    <option value='active' >{intl.formatMessage({id: 'Active'})}</option>
                    <option value='inative'>{intl.formatMessage({id: 'Inative'})}</option>
                    
                  </select>
              </div>
              <div className='col-md-6 d-flex justify-content-between align-items-center'  >

              <button 
                type='button' 
                className='btn bg-hover-dark  text-white' 
                disabled={loading}
                onClick={()=>handleGetList(null)}
                style={{width: '125px', height: '40px', background: '#65638E', borderRadius: 13, marginRight: '10px'}}
                >
                  {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Search'})}</span>}
                        {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                  )}      
                </button>
                <button
                  className='btn btn-sm btn-dark'
                  onClick={()=>props.create(true)}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title={intl.formatMessage({id: 'Click to add a user'})} 
                  style={{height: '40px', width: '125px', borderRadius: 11, marginRight: window.screen.width > 767 ? '13px' : '0px'}}
                >
                  <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  {intl.formatMessage({id: 'New User'})} 
                </button>
              </div>
            {/* </div> */}
           
           
          </div>
          <div className='card-body py-3'>
            {
              dataUser.length !== 0?
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bolder text-dark'>
                      <th className='min-w-120px'>{intl.formatMessage({id: 'USER'})}</th>
                      <th className='min-w-120px'>{intl.formatMessage({id: 'ROLE'})}</th>
                      <th className='min-w-120px'>{intl.formatMessage({id: 'STATUS'})}</th>
                      <th className='min-w-120px'>{intl.formatMessage({id: 'valid_at'})}</th>
                      <th className='min-w-120px'>{intl.formatMessage({id: 'VERIFIED E-MAIL'})}</th>
                      <th className='min-w-120px'>{intl.formatMessage({id: 'TERMS'})}</th>
                      <th className='min-w-120px text-center'>{intl.formatMessage({id: 'ACTIONS'})}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dataUser.data.map((item: any, index: any)=>
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                            {
                              item.user_data?.photo ?
                              <img src={`data:image/jpeg;base64,${item.user_data.photo}`} />
                              :
                              <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='Metornic' />
                            }
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-gray-700 fw-bold fs-6'>
                                {item.user_data?.name} {item.user_data?.last_name}
                              </a>
                              <span className='text-gray-600 fw-bold d-block fs-7'>{item.email}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='fw-bold text-gray-700 fs-6'>
                                {item.roles[0]? item.roles[0].name : '---'}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-gray-700 fw-bold fs-6'>
                              {
                                item.deleted_users ?
                                <span className="badge badge-light-danger">{intl.formatMessage({id: 'Deleted'})}</span>
                                :
                                <>
                                {                              
                                  item.status === 'inative' ? <span className="badge badge-light-danger">{intl.formatMessage({id: 'Inactive'})}</span> :
                                  item.status === 'active'? <span className="badge badge-light-success">{intl.formatMessage({id: 'Active'})}</span>:
                                  'status desconhecido'
                                }
                                </>
                              }
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='fw-bold text-gray-700 fs-6'>
                                {
                                  item.user_plan && item.user_plan.expire_at ?
                                  <div>{converteData2(item.user_plan.expire_at)}</div>
                                  :
                                  '- - -'
                                }
                              </span>
                            </div>
                          </div>
                        </td>


                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='fw-bold text-gray-700 fs-6'>
                                {
                                  item.email_verified_at?
                                  <>{converteData(item.email_verified_at)}</>
                                  :
                                  <>---</>
                                }
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='fw-bold text-gray-700 fs-6'>
                                {
                                  item.use_terms === null? '- -' : (item.use_terms === true ? <span className="badge badge-light-success">{intl.formatMessage({id: 'Accepted'})}</span> : <span className="badge badge-light-danger">{intl.formatMessage({id: 'Rejected'})}</span>)                                  
                                }
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-center flex-shrink-0'>
                            <button
                              className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                              onClick={()=>props.edition(true, item.id)}
                            >
                              <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _edit 2 outline_.svg')} style={{marginRight: '10px'}} />
                            </button>
                           {
                            item.deleted_users ?
                            <></>
                            :
                             <button
                             className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm'
                             onClick={()=>[
                               setId(item.id),
                               setOpenModalDelete(true)
                             ]}
                           >
                            <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _trash_.svg')} style={{marginRight: '10px'}} />
                           </button>
                           }
                          </div>
                        </td>
                      </tr>
                      )
                    }
                  </tbody>
                </table><Pagination data={dataUser} onSearch={handleGetList} searchData={searchData}/>

              </div>
              :
              <Warning message={intl.formatMessage({id: 'Sorry, no content to show yet'})} />
            }
          </div>
        </div>
      }
      <ModalReportUser users={dataUser} openModal={openModalReport} closeModal={closeModal}/>
    </>
  )
}

export {UsersList}
