import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
// import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword} from '../redux/AuthCRUD'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { RouteComponentProps} from 'react-router';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'

interface MatchParams {
  token: string;
}

const initialValues = {
  email: '',
  password: '',
  changepassword: ''
}



export const ResetPassword: FC<RouteComponentProps<MatchParams>> = (props) => {
  const [loading, setLoading] = useState(false)
  const [seePassword, setSeePassword] = useState('password');
  const token = props.match.params.token;
  const history = useHistory();
  const intl = useIntl();   

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
    .email(`${intl.formatMessage({id: 'Formato de e-mail incorreto'})}`)
    .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
    .required(`${intl.formatMessage({id: 'E-mail'})} ${intl.formatMessage({id: 'È requerido'})}`),
    password: Yup.string()
      .min(3, `${intl.formatMessage({id: 'Mínimo de 6 símbolos'})}`)
      .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%^&*])(?=.{6,})/,
        `${intl.formatMessage({id: 'Use 6 ou mais caracteres com uma mistura de letras, números e símbolos'})}`
      )
      .required(`${intl.formatMessage({id: 'A senha é requerida'})}`),
    changepassword: Yup.string()
      .required(`${intl.formatMessage({id: 'A confirmação da senha é necessária'})}`)
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], `${intl.formatMessage({id: 'Senha e Confirmar senha não coincidem'})}`),
      })
  })
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)

        resetPassword(values.email, values.password, values.changepassword, token)
          .then((data) => {
            if(data.data.status === 'error'){
              toast.error(data.data.message)
            } else {
              toast.success(data.data.message)
            }
            setLoading(false)
            if(data.data.status === 'success'){
              history.push("/auth/login")
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message)
            setLoading(false)
            setSubmitting(false)
            setStatus(`${intl.formatMessage({id: 'Os detalhes de login estão incorretos'})}`)
          })

    },
  })

  return (
    <div className='d-flex flex-column bg-gray-middle flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    style={{
      backgroundImage:`url(${process.env.PUBLIC_URL + '/media/logos/background.png'})`,
      backgroundPosition: 'center',
      backgroundColor: '#1E1E2D',
      backgroundSize: 'cover' 
    }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20'>
        {/* <div className='mb-5'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/breven_law_color_logo.svg')} className='h-95px' />
        </div> */}
        <div className='w-lg-500px bg-white rounded-5 shadow-sm p-10 p-lg-15 mx-auto'
        style={{maxWidth: '450px'}}
        >
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='text-center mb-10'>
              <h1 className='text-primary mb-3'>{intl.formatMessage({id: 'Redefinir senha'})}</h1>
              <div className='text-gray-400 fw-bold fs-4'>{intl.formatMessage({id: 'Digite seu e-mail e sua nova senha'})}</div>
            </div>
            {/* {hasErrors === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  {message}
                </div>
              </div>
            )}
            {hasErrors === false && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>{message}</div>
              </div>
            )} */}
            <div className='fv-row mb-2'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({id: 'E-mail'})}</label>
              <input
                type='email'
                placeholder='example@mail.com'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block text-danger mt-2 fs-5'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-4 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Senha'})}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <input
                      type={seePassword}
                      placeholder={intl.formatMessage({id: 'Senha'})}
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-90 me-1',
                        {
                          'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {
                      seePassword === 'password'?
                      <button onClick={()=>setSeePassword('text')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                        <i className="bi bi-eye-slash-fill fs-2x"></i>
                      </button>
                      :
                      <button onClick={()=>setSeePassword('password')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                        <i className="bi bi-eye-fill fs-2x"></i>
                      </button>
                    }
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='fv-row mb-5'>
              <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Confirme a Senha'})}</label>
              <input
                type='password'
                placeholder={intl.formatMessage({id: 'Confirme a Senha'})}
                autoComplete='off'
                {...formik.getFieldProps('changepassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                  },
                  {
                    'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                  }
                )}
              />
              {formik.touched.changepassword && formik.errors.changepassword && (
                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.changepassword}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn bg-hover-dark text-white'
                style={{width: '168px', height: '48px', background: '#65638E', borderRadius: 13}}
              >
                {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Enviar'})}</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {/* <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-lg btn-light-primary fw-bolder'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Cancel
                </button>
              </Link>{' '} */}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
