import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../../../helpers';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import clsx from 'clsx'
import { upPassword } from '../../../../../../../app/modules/helpers/Api';
import ModalMessageConfirm from '../../../../modals/modalMessageConfirm';
import { useIntl } from 'react-intl'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  maxHeight: 550,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

interface IModalPasswordProps {
  showModal: boolean;
  title: string;
  onClose: () => void;
  onAction: () => void;
  id: any;
}


const ModalPassword: React.FC<IModalPasswordProps> = ({
  ...props
}) => {

  const[loading, setLoading] = useState(false);
  const [seePassword, setSeePassword] = useState('password');
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const [value, setValue] = useState<any>({});
  const intl = useIntl();   

  const initialValues = {
    new_password: '',
    confirm_password: ''
  }

  const schema = Yup.object().shape({
    new_password: Yup.string()
    .min(3, `${intl.formatMessage({id: 'Minimum 6 symbols'})}`)
    .max(50, `${intl.formatMessage({id: 'Maximum 50 symbols'})}`)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%^&*])(?=.{6,})/,
      `${intl.formatMessage({id: 'Use 6 or more characters with a mix of letters, numbers & symbols'})}`
    )
    .required(`${intl.formatMessage({id: 'New password is required'})}`),
    confirm_password: Yup.string()
    .required(`${intl.formatMessage({id: 'Password confirmation is required'})}`)
    .when('new_pass', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('new_pass')], `${intl.formatMessage({id: "New Password and Confirm Password didn't match"})}`),
    }),
  })
  

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        setValue(values);
        setOpenModalSubmit(true);
        setLoading(false)
      }, 1000)
    },
  })

  function save(value: any){
    const data = {
      new_password: value.new_password,
      confirm_password: value.confirm_password
    }
    const save = upPassword(props.id, data);
    save.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        formik.resetForm();
        setOpenModalSubmit(false);
        props.onAction();
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response.data){
        toast.error(error.response.data.message);
      } else {
        toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}`);
      }
    })
  }

  return(
    <>
    <ModalMessageConfirm
      message={intl.formatMessage({id: 'Are you sure you want to edition this item?'})}
      title={intl.formatMessage({id: 'Attention'})}
      showModal={openModalSubmit}
      onClose={()=>setOpenModalSubmit(false)}
      onAction={()=>save(value)}
    />
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
          <span className="modal-title fw-bold fs-3">{props.title}</span>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>props.onClose()}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className='px-5 mb-5 mt-1' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 350}}>

          <form onSubmit={formik.handleSubmit} noValidate className='form' id='form'>
            <div className='card-body'>
              <div className='mb-2 fv-row' data-kt-password-meter='true'>
                <div className='mb-1'>
                  <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'New Password'})}</label>
                  <div className='position-relative mb-3'>
                    <div className='d-flex justify-content-between w-100'>
                      <input
                        type={seePassword}
                        placeholder={intl.formatMessage({id: 'New Password'})}
                        autoComplete='off'
                        {...formik.getFieldProps('new_password')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid w-90 me-1',
                          {
                            'is-invalid': formik.touched.new_password && formik.errors.new_password,
                          },
                          {
                            'is-valid': formik.touched.new_password && !formik.errors.new_password,
                          }
                        )}
                      />
                      {
                        seePassword === 'password'?
                        <button type='button' onClick={()=>setSeePassword('text')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                          <i className="bi bi-eye-slash-fill fs-2x"></i>
                        </button>
                        :
                        <button type='button' onClick={()=>setSeePassword('password')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                          <i className="bi bi-eye-fill fs-2x"></i>
                        </button>
                      }
                    </div>
                    {formik.touched.new_password && formik.errors.new_password && (
                      <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.new_password}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='fv-row mb-5'>
                <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Confirm Password'})}</label>
                <input
                  type='password'
                  placeholder={intl.formatMessage({id: 'Confirm Password'})}
                  autoComplete='off'
                  {...formik.getFieldProps('confirm_password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password,
                    },
                    {
                      'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
                    }
                  )}
                />
                {formik.touched.confirm_password && formik.errors.confirm_password && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.confirm_password}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>

        </div>
        <div className='d-flex flex-row w-100 justify-content-end p-5'>
          <button
            type="button"
            className="btn btn-danger mx-2"
            onClick={()=>props.onClose()}
          >
            {intl.formatMessage({id: 'Cancel'})}
          </button>
          <button type='submit' form='form' className='btn btn-primary text-dark' disabled={loading}>
            {!loading && intl.formatMessage({id: 'Save'})}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'Processing'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </Box>
    </Modal>
    </>
  )
}
export default ModalPassword;