import React, { FC, useState } from 'react'
import { KTSVG } from '../../../helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { newActionPlan } from '../../../../app/modules/helpers/Api'
import InputMask from 'react-input-mask';

const schema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
		initial_date: Yup.string().required('Initial date is required'),
		final_date: Yup.string().required('Final date is required'),
		review_date: Yup.string().required('Review date is required'),
})

const CreateAction: FC = () => {

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
						initial_date: '',
						final_date: '',
						review_date: '',
        },
        validationSchema: schema,
        onSubmit: (values) => {
            setLoading(true)
            setTimeout(() => {
								store(values);
                setLoading(false);
            }, 1000)
        },
    })

		function store (values: any){
			const data = {
				title: values.title,
				description: values.description,
				prescription_answered_id: 1,
				actions: [
					{action_id: 1, date_start: `${values.initial_date.replaceAll('/', '-')}`, date_end: `${values.final_date.replaceAll('/', '-')}`, date_review: `${values.review_date.replaceAll('/', '-')}` },
				]
			}
			const save = newActionPlan(data);
			save.then((res)=>{
				
			}).catch((error)=>alert(error));
		}

    return (
        <div className="modal fade" tabIndex={-1} id="kt_modal_action">
            <div className="modal-dialog modal-dialog-centered mw-900px">
                <div className="modal-content mh-600px">
                    <div className="modal-header">
                        <h5 className="modal-title">New Action</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body w-100 pt-1 " style={{overflow:'auto', overflowX: 'hidden'}}>
                        <form onSubmit={formik.handleSubmit} noValidate className='form w-100'>
                            <div className='align-items-center w-100'>
                                <div className='col-lg-12'>
                                    <div className='row'>
                                        <div className='col-lg-12 fv-row'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-5'>Titlse</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid fs-5"
                                                placeholder="Title"
                                                {...formik.getFieldProps('title')}
                                            />
                                            {formik.touched.title && formik.errors.title && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.title}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='col-lg-12 fv-row'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-5'>Description</label>
                                            <textarea
                                                rows={3}
                                                className="form-control form-control-solid fs-5"
                                                placeholder="Description"
                                                {...formik.getFieldProps('description')}
                                            />
                                            {formik.touched.description && formik.errors.description && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.description}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='col-lg-12 fv-row'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-5'>Initial Date</label>
                                            <InputMask 
                                                mask="99/99/9999" 
                                                className='form-control form-control-md form-control-solid fs-4'
                                                placeholder='99/99/9999'
                                                {...formik.getFieldProps('initial_date')}
                                            />
                                            {formik.touched.initial_date && formik.errors.initial_date && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.initial_date}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='col-lg-12 fv-row'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-5'>Final Date</label>
                                            <InputMask 
                                                mask="99/99/9999" 
                                                className='form-control form-control-md form-control-solid fs-4'
                                                placeholder='99/99/9999'
                                                {...formik.getFieldProps('final_date')}
                                            />
                                            {formik.touched.final_date && formik.errors.final_date && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.final_date}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='col-lg-12 fv-row'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-5'>Review Date</label>
                                            <InputMask 
																							mask="99/99/9999" 
																							className='form-control form-control-md form-control-solid fs-4'
																							placeholder='99/99/9999'
																							{...formik.getFieldProps('review_date')}
																						/>
                                            {formik.touched.review_date && formik.errors.review_date && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.review_date}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
														<div className='border-top pt-5 mt-5 d-flex justify-content-end'>
															<button type='button' data-bs-dismiss="modal" className='btn btn-md btn-light ms-4 me-2'>Close</button>
															<button type='submit' className='btn btn-md btn-primary' disabled={loading}>
																{!loading && 'Salvar'}
																{loading && (
																	<span className='indicator-progress' style={{display: 'block'}}>
																		Processando...{' '}
																		<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
																	</span>
																)}
															</button>
														</div>
                        </form>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export { CreateAction }