import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { RouteComponentProps } from 'react-router';
import { getHoldeRequest } from '../../modules/helpers/Api';
import { FormattedDate, FormattedTime, useIntl } from 'react-intl';
import { useLang } from '../../../_metronic/i18n/Metronici18n';
import { toast } from 'react-toastify';
import { Load } from '../../../_metronic/partials/widgets';

interface MatchParams {
  lang: string;
  token: string;
}

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';

const ManagementHolderResponse: React.FC<RouteComponentProps<MatchParams>> = (props) => {
  const lang = useLang();
  const intl = useIntl();
  const urlLang = props.match.params.lang;
  const token = props.match.params.token;
  const [procedure, setProcedure] = useState<any>([]);
  const [loading2, setLoading2] = useState(false);
  const [loading, setLoading] = useState(false);

  if (['pt', 'en'].includes(urlLang) && lang !== urlLang) {
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: urlLang }));
    window.location.reload();
  }

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setLoading(true);

    const get = getHoldeRequest(token);

    get.then((res) => {
      if (res.data.status === 'success') {
        setProcedure(res.data.data.requeriment); // Ajuste aqui
      }
    }).catch((error) => {
      toast.error(error.response.data.message);
    })
      .finally(() => [setLoading2(false), setLoading(false)]);

  }

  return (
    <div className='d-flex flex-column bg-gray-middle flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/logos/background.png')})`,
        backgroundPosition: 'center',
        backgroundColor: '#1E1E2D',
        backgroundSize: 'cover'
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20'>
        <div className='w-lg-700px bg-white rounded-5 shadow-sm p-10 p-lg-15 mx-auto'>
          <div className='mb-10 text-center'>
            <h1 className='mb-4'>{intl.formatMessage({ id: 'Requirement' })}</h1>
            <div className="text-center">
              <p className='fs-9'>
                {procedure.uuid}</p>
            </div>

          </div>
          {
            loading ?
              <Load /> :

              <div className=''>



                {

                  procedure &&
                  <>

                    <div className="row">
                      <div className="col-12">
                        {/* <p><strong>Token:</strong> {procedure.uuid}</p> */}
                        <h3><strong></strong></h3>
                        {/* <p><strong>Company ID:</strong> {procedure.company_id}</p> */}
                        <p className=' fs-6'> {procedure.requester_name == 0 ? <span className='badge badge-light fw-bold me-1'>Anônimo</span> : <span>{procedure.requester_name} - {procedure.requester_mail} </span>}</p>
                        <p className='mb-0'><strong>Detalhe do requerimento:</strong></p>
                        <p className='mt-0 '> {procedure.application}</p>

                        {procedure.answers && (
                          <div className="mb-10 w-75">
                            {JSON.parse("[" + procedure.answers + "]").map((answer: any, index: any) => (
                              <div className='row mb-4' key={index}>
                                <p className='mb-0'><strong>{answer.questions}</strong></p>
                                <small className='mb-0'>{answer.answers.replace(/\"/g, '')}</small>
                              </div>
                            ))}
                          </div>
                        )}



                        <p className='text-center m-2 fs-8'>
                          Esse requerimento foi criado em
                          <small className='m-2'>
                            {new Date(procedure.request_at).toLocaleDateString(undefined, {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })}{' '}
                            {new Date(procedure.request_at).toLocaleTimeString(undefined, {
                              hour: 'numeric',
                              minute: 'numeric',
                            })} {' '}
                          </small>
                          e tem prazo para resposta até
                          <small className='m-2'>
                            {new Date(procedure.deadline).toLocaleDateString(undefined, {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })}
                          </small>
                        </p>






                      </div>
                    </div>
                    <hr />
                    {/* resposta do maanager */}
                    {
                      procedure.reply_at ?
                        <div className="row">
                          <div className="col-12">
                            <p><strong>Respondido por:</strong> {procedure.user_name}</p>
                            <p><strong>Status: </strong>
                              {procedure.status == 'Done' && <span className='badge badge-light-success text-success'>Finalizado</span>}
                              {procedure.status == 'Canceled' && <span className='badge badge-light-danger text-danger'>Canceledo</span>}
                              {procedure.status == 'Checking' && <span className='badge badge-light-warning text-warning'>Checkando</span>}
                              {procedure.status == 'Pending' && <span className='badge badge-light-warning text-warning'>Pendente</span>}
                              {procedure.status == 'Out of date' && <span className='badge badge-light-dark text-dark'>Fora de data</span>}
                            </p>
                            <p><strong>Descrição:</strong> {procedure.description}</p>
                            <p><strong>Observação:</strong> {procedure.observation}</p>
                            {/* <p><strong>Requester Mail:</strong> {procedure.requester_mail}</p> */}

                            <p><strong>Resposta:</strong> {procedure.response}</p>
                            {/* <p><strong>Rights:</strong> {procedure.rights}</p> */}
                            {/* <p><strong>Type:</strong> {procedure.type}</p> */}
                          </div>
                          <p className=''>
                            <strong>Respondido em:</strong>
                            <small className='m-2'>
                              <FormattedDate
                                year="numeric"
                                month="2-digit"
                                day="2-digit"
                                value={new Date(procedure.reply_at)} /> <FormattedTime hour="numeric"
                                  minute="numeric" value={new Date(procedure.request_at)} />
                            </small>
                          </p>

                        </div>
                        :
                        <div className="text-center">
                          <span className='badge badge-light-primary text-black fw-bold me-1 '>O seu requerimento está aguardando uma verificação.</span>
                        </div>
                    }
                  </>

                }
              </div>
          }


        </div>
      </div>
    </div >
  );
}

export default ManagementHolderResponse;
