/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify'
import { preferenceConsentConfirmationCode, storePreferenceConsentPost, getPreferenceConsentByToken } from '../../modules/helpers/Api';
import { useIntl } from 'react-intl'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { trataRespota } from '../../../app/modules/helpers/helpers'
import { Load } from '../../../_metronic/partials/widgets/spinner/Spinner';
import { useLang } from '../../../_metronic/i18n/Metronici18n'

interface MatchParams {
  lang: string;
  token: string;
  type: string;
}

const initialValues = {
  uuid: '',
  email: '',
  name: '',
  message: '',
  response: '',
}


const ManagementPreferenceConsentResponse: React.FC<RouteComponentProps<MatchParams>> = (props) => {

  const lang = useLang()
  const intl = useIntl();
  const urlLang = props.match.params.lang;
  const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';
  const token = props.match.params.token;
  const pageType = props.match.params.type;

  const [loading, setLoading] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalConfirmCode, setOpenModalConfirmCode] = useState(false);
  const [refStoredCode, setRefStoredCode] = useState('');


  const [openModalAlert, setOpenModalAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseTitle, setResponseTitle] = useState('');
  const [showTitle, setShowTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [currentMail, setCurrentMail] = useState('');


  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: 750,
    maxHeight: 600,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: 1,
    boxShadow: 24,
    outline: 'none'
  };

  const registrationSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${intl.formatMessage({ id: 'O valor não é um endereço de e-mail válido' })}`)
      .max(50, `${intl.formatMessage({ id: 'Máximo de 50 símbolos' })}`)
      .required(`${intl.formatMessage({ id: 'E-mail' })} ${intl.formatMessage({ id: 'is required' })}`),
    name: Yup.string()
      .min(3, `Mínimo de 3 caracteres`)
      .required(`${intl.formatMessage({ id: 'Name' })} ${intl.formatMessage({ id: 'is required' })}`),
    response: Yup.string()
      .required(`${intl.formatMessage({ id: 'Response' })} ${intl.formatMessage({ id: 'is required' })}`)
  })


  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // handleStore(values);
      handleStoreConfirm();
    },
  })

  const formik2 = useFormik({
    initialValues: {
      confirmation_code: '',
    },
    validationSchema: Yup.object().shape({
      confirmation_code: Yup.string()
        .min(6, `6 Caracteres`)
        .max(6, `6 Caracteres`)
        .required(`${intl.formatMessage({ id: 'Code' })} ${intl.formatMessage({ id: 'is required' })}`),
    }),
    onSubmit: (values, { setStatus, setSubmitting }) => {

      handleCheckConfirCode(values);

    },
  })

  const formik3 = useFormik({
    initialValues: { current_mail: '' },
    validationSchema: Yup.object().shape({
      current_mail: Yup.string()
        .email(`${intl.formatMessage({ id: 'O valor não é um endereço de e-mail válido' })}`)
        .max(50, `${intl.formatMessage({ id: 'Máximo de 50 símbolos' })}`)
        .required(`${intl.formatMessage({ id: 'E-mail' })} ${intl.formatMessage({ id: 'is required' })}`),
    }),
    onSubmit: (values, { setStatus, setSubmitting }) => {

      handleGetData(values.current_mail);

    },
  })



  useEffect(() => {
    if (['pt', 'en'].includes(urlLang) && lang != urlLang) {
      localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: urlLang }))
      window.location.reload()
    }

  }, [token]);

  function handleGetData(email: any) {

    setLoading(true);
    setErrorMessage('');

    const get = getPreferenceConsentByToken({ 'token': token, 'lang': lang, 'email': email })

    get.then((res) => {

      if (res.data.status === 'success') {


        setCurrentMail(email);
        formik.setFieldValue('email', email);

        if (res.data.ref_code) {
          setRefStoredCode(res.data.ref_code);
          setResponseTitle(res.data.message)
          setResponseMessage(res.data.message)
        } else {

          formik.setFieldValue('uuid', res.data.data.uuid);
          formik.setFieldValue('title', res.data.data.title);
          formik.setFieldValue('message', res.data.data.message);

          if (res.data.data.show_title) {
            setShowTitle(res.data.data.title)
          }

        }

      } else if (res.data.status == 'error') {
        setOpenModalAlert(true)
        setResponseMessage(res.data.message)
      }

    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setLoading(false)
    });

  }

  function handleCheckConfirCode(values: any) {
    setLoading(true);

    const create = preferenceConsentConfirmationCode({ 'code': refStoredCode, 'confirmation_code': values.confirmation_code, 'lang': lang, });

    create.then((res) => {

      if (res.data.status === 'success') {


        setResponseMessage(res.data.message)
        setOpenModalAlert(true)
        setRefStoredCode('');

        formik.resetForm()
        formik2.resetForm()
        setCurrentMail('');
        formik3.resetForm();

      }
      else if (res.data.status === 'error') {
        // toast.error(res.data.message);

        setResponseMessage(res.data.message)
        setOpenModalAlert(true)
      }

    }).catch((error) => {
      trataRespota('error', error);
    }).finally(() => {
      setLoading(false)
    });


  }

  function handleStoreConfirm() {
    setOpenModalConfirm(true)
  }

  function handleStore() {

    setLoading(true);
    setOpenModalConfirm(false)

    let sendData = {
      token: token,
      lang: lang,
      type: pageType,
      title: formik.getFieldProps('title').value,
      message: formik.getFieldProps('message').value,
      email: formik.getFieldProps('email').value,
      name: formik.getFieldProps('name').value,
      response: formik.getFieldProps('response').value,
      url: window.location.href
    }

    const create = storePreferenceConsentPost(sendData);

    create.then((res) => {

      if (res.data.status === 'success') {

        if (res.data.ref_code) {

          setRefStoredCode(res.data.ref_code);
          setResponseTitle(res.data.message)
          setResponseMessage(res.data.message)

        } else {
          setCurrentMail('');
          setResponseMessage(res.data.message)
          setOpenModalAlert(true)
        }

        // formik.resetForm()



      }
      else if (res.data.status === 'error') {
        // toast.error(res.data.message);

        setResponseMessage(res.data.message)
        setOpenModalAlert(true)
      }

    }).catch((error) => {
      trataRespota('error', error);
    }).finally(() => {
      setLoading(false)
    });
  }
  const messageHtml = formik.getFieldProps('message').value;

  return (

    <div className='d-flex flex-column bg-gray-middle flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/logos/background.png')})`,
        backgroundPosition: 'center',
        backgroundColor: '#1E1E2D',
        backgroundSize: 'cover'
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20'>

        <div className='w-lg-900px bg-white rounded-5 shadow-sm p-10 p-lg-15 mx-auto'>

          {loading ?
            <Load />
            :
            !currentMail ?
              <div>

                <div className='mb-10 text-center'>
                  <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'Management of acepts documents' })}</h1>
                  {/* <div className='text-dark fw-bold fs-4'>Company Example Name LTDA.</div> */}
                </div>

                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='kt_login_signup_form'
                  onSubmit={formik3.handleSubmit}
                >

                  {/* email */}
                  <div className='fv-row mb-3'>
                    <label className='form-label fw-bold text-dark fs-5 required'>{intl.formatMessage({ id: 'E-mail' })}</label>
                    <input
                      disabled={loading}
                      placeholder={intl.formatMessage({ id: 'E-mail' })}
                      type='email'
                      autoComplete='off'
                      {...formik3.getFieldProps('current_mail')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formik3.touched.current_mail && formik3.errors.current_mail },
                        {
                          'is-valid': formik3.touched.current_mail && !formik3.errors.current_mail,
                        }
                      )}
                    />
                    {formik3.touched.current_mail && formik3.errors.current_mail && (
                      <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik3.errors.current_mail}</span>
                        </div>
                      </div>
                    )}
                  </div>



                  <div className='text-center row'>

                    <div className='text-center col-12 mt-12'>
                      <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn bg-hover-dark mb-5 w-100 text-white'
                        //  disabled={!formik.values.acceptTerms}
                        style={{ width: '168px', height: '48px', background: '#65638E', borderRadius: 13 }}

                      >
                        {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Next' })}</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>


                  </div>

                </form>



              </div>
              :
              refStoredCode ?
                <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
                  <div className='mb-10 text-center'>
                    <h6>{responseTitle}</h6>

                    <form
                      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                      noValidate
                      id='kt_login_signup_form'
                      onSubmit={formik2.handleSubmit}
                    >

                      <input
                        disabled={loading}
                        placeholder='######'
                        type='text'
                        autoComplete='off'
                        {...formik2.getFieldProps('confirmation_code')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          { 'is-invalid': formik2.touched.confirmation_code && formik2.errors.confirmation_code },
                          {
                            'is-valid': formik2.touched.confirmation_code && !formik2.errors.confirmation_code,
                          }
                        )}
                      />
                      {formik2.touched.confirmation_code && formik2.errors.confirmation_code && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik2.errors.confirmation_code}</span>
                          </div>
                        </div>
                      )}




                      <div className='text-center row'>

                        <div className='text-center col-12 mt-12'>
                          <button
                            type='submit'
                            id='kt_sign_up_submit'
                            className='btn bg-hover-dark w-100 text-white'
                            //  disabled={!formik.values.acceptTerms}
                            style={{ width: '168px', height: '48px', background: '#65638E', borderRadius: 13 }}

                          >
                            {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Enviar' })}</span>}
                            {loading && (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </div>

                      </div>

                    </form>

                  </div>
                </div>
                :
                formik.getFieldProps('uuid').value ?

                  <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                    onSubmit={formik.handleSubmit}
                  >

                    <div className='mb-10 text-center'>
                      <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'Management of acepts documents' })}</h1>
                      {/* <div className='text-dark fw-bold fs-4'>Company Example Name LTDA.</div> */}
                    </div>

                    {
                      showTitle ?
                        <div className='mb-10 text-center'>
                          <h6 className='text-dark mb-3'>{showTitle}</h6>
                          {/* <div className='text-dark fw-bold fs-4'>Company Example Name LTDA.</div> */}
                        </div>
                        :
                        null
                    }

                    <div className='mb-10'>
                      {/* <h6 className='text-dark mb-3'>{formik.getFieldProps('message').value}</h6> */}
                      <div dangerouslySetInnerHTML={{ __html: messageHtml }} />

                      {/* <div className='text-dark fw-bold fs-4'>Company Example Name LTDA.</div> */}
                    </div>



                    <div className='border-bottom border-gray-300 w-100 mb-6'></div>

                    {/* email */}
                    <div className='fv-row mb-3'>
                      <label className='form-label fw-bold text-dark fs-5 required'>{intl.formatMessage({ id: 'E-mail' })}</label>
                      <input
                        disabled
                        placeholder={intl.formatMessage({ id: 'E-mail' })}
                        type='email'
                        autoComplete='off'
                        {...formik.getFieldProps('email')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          { 'is-invalid': formik.touched.email && formik.errors.email },
                          {
                            'is-valid': formik.touched.email && !formik.errors.email,
                          }
                        )}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='row fv-row mb-2'>

                      {/* Nome */}
                      <div className='col-xl-12'>
                        <div className='fv-row mb-3'>
                          <label className='class="form-label fw-bold text-dark fs-5 mb-1 required'>{intl.formatMessage({ id: 'Name' })}</label>
                          <input
                            disabled={loading}
                            placeholder={intl.formatMessage({ id: 'Name' })}
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('name')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {
                                'is-invalid': formik.touched.name && formik.errors.name,
                              },
                              {
                                'is-valid': formik.touched.name && !formik.errors.name,
                              }
                            )}
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.name}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>


                    </div>


                    <div className='row fv-row mb-2'>
                      <div className='col-xl-12'>

                        <label className='class="form-label fw-bold text-dark fs-5 mb-1 required'>{intl.formatMessage({ id: 'Response' })}</label>

                        <div className={clsx(
                          '',
                          {
                            'is-invalid': formik.touched.response && formik.errors.response,
                          },
                          {
                            'is-valid': formik.touched.response && !formik.errors.response,
                          }
                        )}>

                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name='response' onClick={() => [formik.setFieldValue('response', intl.formatMessage({ id: 'Yes' }))]} />
                            <label className="form-check-label" >{intl.formatMessage({ id: 'Yes' })}</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name='response' onClick={() => [formik.setFieldValue('response', intl.formatMessage({ id: 'No' }))]} />
                            <label className="form-check-label" >{intl.formatMessage({ id: 'No' })}</label>
                          </div>



                        </div>


                        {formik.touched.response && formik.errors.response && (
                          <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.response}</span>
                            </div>
                          </div>
                        )}

                      </div>
                    </div>





                    <div className='text-center row'>

                      <div className='text-center col-12 mt-12'>
                        <button
                          type='submit'
                          id='kt_sign_up_submit'
                          className='btn bg-hover-dark mb-5 w-100 text-white'
                          //  disabled={!formik.values.acceptTerms}
                          style={{ width: '168px', height: '48px', background: '#65638E', borderRadius: 13 }}

                        >
                          {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Enviar' })}</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>


                    </div>
                  </form>
                  :
                  <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
                    <div className='mb-10 text-center'>

                      {errorMessage ?
                        <h6>{errorMessage}</h6>
                        :
                        <h6 className='text-dark mb-3'>{intl.formatMessage({ id: 'No record found' })}</h6>
                      }

                    </div>
                  </div>

          }

        </div>
      </div>

      <Modal
        open={openModalAlert}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>

          <div className='container mt-5 p-5 text-center '>
            {/* <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> {intl.formatMessage({ id: 'Requirement' })}</h1> */}
            <p className='w-90 mx-auto fs-4 mt-5'>
              {responseMessage}
            </p>
          </div>

          <div className='d-flex justify-content-center mb-2'>
            <button
              onClick={() => [setOpenModalAlert(false)]}
              style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark'>
              {intl.formatMessage({ id: 'Close' })}

            </button>

          </div>

        </Box>
      </Modal>

      <Modal
        open={openModalConfirm}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>

          <div className='container mt-5 p-5 text-center '>
            {/* <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> {intl.formatMessage({ id: 'Requirement' })}</h1> */}
            <p className='w-90 mx-auto fs-4 mt-5'>
              {intl.formatMessage({ id: 'pref_consent_msg_send' })}
            </p>
          </div>

          <div className='d-flex justify-content-center mb-2'>
            <button
              onClick={() => [setOpenModalConfirm(false)]}
              style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark mx-3'>
              {intl.formatMessage({ id: 'No' })}
            </button>

            <button
              onClick={() => [handleStore()]}
              style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark'>
              {intl.formatMessage({ id: 'Yes' })}
            </button>

          </div>

        </Box>
      </Modal>

    </div>


  )
}
export default ManagementPreferenceConsentResponse;