/* eslint-disable jsx-a11y/anchor-is-valid */
// import { userInfo } from 'os';
import React, {FC} from 'react'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import { useIntl } from 'react-intl'


type Props = {
  data: {
    id: number,
    status: string,
		company: string | '',
		corporate_name: string | '',
		fantasy_name: string | '',
		document: string | '',
		document_type: string | '',
		dpo_user_id: string | '',
		user_id: string | '',
		zip_code: string | '',
		number: string | '',
		complement: string | '',
		neighborhood: string | '',
		city: string | '',
		uf: string | '',
		tel: string | '',
		country: string | '',
		street: string | '',
		whatsapp_number: string | '',
		reg_state: string | '',
		reg_municipal: string | '',
		site: string | '',
		instagram: string | '',
		facebook: string | '',
		logo_url: string | '',
		observation: string | '',
		created_at: string | '',
		updated_at: string | '',
		deleted_at: string | ''
  }
  changeEdition: (a: boolean) => void;
}

const DetailCompany: FC<Props> = ({...props}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const intl = useIntl();   


  return (
    <div className='card mb-5 mb-xl-10 rounded-4'>
      <div className='collapse show'>
        {
          !['free','manager_adv', 'dpo', 'admin' ].includes(user.roles) ?
          <div className='d-flex justify-content-end py-6 px-9'>
            <button 
              type='submit' 
              onClick={()=>props.changeEdition(true)} 
              className='btn btn-primary text-dark'
              // disabled={ props.data.status === 'active' ? false : true } 
              disabled={ !['super-admin' ].includes(user.roles) && ( props.data.status === 'active' ? false : true) }
            >
              {intl.formatMessage({id: 'Edit'})}
            </button>
          </div>
          :
          <></>
        }
        
        <div className='card-body border-top px-5 py-5'>
          <div className='mx-4 align-items-center'>
          <h3 className=' fw-bolder fs-2'>{intl.formatMessage({id: 'Company Details'})}</h3>
            <div style={{backgroundColor: 'rgba(238, 227, 70, 0.1)'}} className=' col-lg-12 border-dashed border-2 border-primary rounded p-3'>
              <div className='row'>
                {/* <div className='d-flex flex-column mb-3'>
                  <label className='col-form-label fw-bold fs-5'>Company</label>
                  <span className='fw-bold text-gray-700 fs-6'>{props.data.company}</span>
                </div> */}
                <div className='col-lg-6 d-flex flex-column mb-3'>
                  <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Corporate name'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.fantasy_name? props.data.fantasy_name : '---'}</span>
                </div>
                <div className='col-lg-6 d-flex flex-column mb-3'>
                  <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Fantasy name'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.corporate_name? props.data.corporate_name : '---'}</span>
                </div>
                <div className='col-lg-6 d-flex flex-column mb-3'>
                  <label className='col-lg-6 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Municipal Registration'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.reg_municipal? props.data.reg_municipal : '---'}</span>
                </div>
                <div className='col-lg-6 d-flex flex-column mb-3'>
                  <label className='col-lg-6 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'State Registration'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.reg_state? props.data.reg_state : '---'}</span>
                </div>
                <div className='col-lg-6 d-flex flex-column mb-3'>
                  <label className='col-lg-6 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Document'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.document? props.data.document : '---'}</span>
                </div>
              </div>
            </div>

            <div style={{backgroundColor: 'rgba(238, 227, 70, 0.1)'}} className='col-lg-12 border-dashed border-2 border-primary rounded p-3 mt-2'>
              <label className='col-lg-4 col-form-label fw-bolder fs-3'>Logo URL</label>
              <div className='row'>
                <div className='col-lg-12 d-flex flex-column mb-3'>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.logo_url? props.data.logo_url : '---'}</span>
                </div>
              </div>
            </div>

            <div style={{backgroundColor: 'rgba(238, 227, 70, 0.1)'}} className='col-lg-12 border-dashed border-2 border-primary rounded p-3 mt-2'>
              <label className='col-lg-4 col-form-label fw-bolder fs-3'>{intl.formatMessage({id: 'Observation'})}</label>
              <div className='row'>
                <div className='col-lg-12 d-flex flex-column mb-3'>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.observation? props.data.observation : '---'}</span>
                </div>
              </div>
            </div>

            {/* <div className='col-lg-12'>
              <label className='col-lg-4 col-form-label fw-bolder fs-3'>Data Protection Officer</label>
              <div className='row'>
                <div className='col-lg-12 d-flex flex-column mb-3'>
                  <label className='col-lg-4 col-form-label fw-bold fs-5'>DPO</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.dpo_user_id}</span>
                </div>
              </div>
            </div> */}

            <h3 className=' fw-bolder fs-2 mt-5'>{intl.formatMessage({id: 'Address Details'})}</h3>
            <div style={{backgroundColor: 'rgba(238, 227, 70, 0.1)'}} className='col-lg-12 border-dashed border-2 border-primary rounded p-3 mt-2'>
              <div className='row'>
                <div className='col-lg-12 d-flex flex-column mb-3'>
                  <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Phone'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.tel? props.data.tel : '---'}</span>
                </div>
                <div className='col-lg-4 d-flex flex-column mb-3'>
                  <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Postal Code'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.zip_code? props.data.zip_code : '---'}</span>
                </div>
                <div className='col-lg-4 d-flex flex-column mb-3'>
                  <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'State'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.uf? props.data.uf : '---'}</span>
                </div>
                <div className='col-lg-4 d-flex flex-column mb-3'>
                  <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'City'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.city? props.data.city : '---'}</span>
                </div>
                <div className='col-lg-4 d-flex flex-column mb-3'>
                  <label className='col-lg-6 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Neighborhood'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.neighborhood? props.data.neighborhood : '---'}</span>
                </div>
                <div className='col-lg-4 d-flex flex-column mb-3'>
                  <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Street'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.street? props.data.street : '---'}</span>
                </div>
                <div className='col-lg-4 d-flex flex-column mb-3'>
                  <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Number'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.number? props.data.number : '---'}</span>
                </div>
                <div className='col-lg-4 d-flex flex-column mb-3'>
                  <label className='col-lg-6 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Complement'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.complement? props.data.complement : '---'}</span>
                </div>
                <div className='col-lg-4 d-flex flex-column mb-3'>
                  <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Country'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.country? props.data.country : '---'}</span>
                </div>
              </div>
            </div>

            <h3 className=' fw-bolder fs-2 mt-5'>Social</h3>
            <div style={{backgroundColor: 'rgba(238, 227, 70, 0.1)'}} className='col-lg-12 border-dashed border-2 border-primary rounded p-3 mt-2'>
              <div className='row'>
                <div className='col-lg-4 d-flex flex-column mb-3'>
                  <label className='col-lg-12 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Whatsapp Number'})}</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.whatsapp_number? props.data.whatsapp_number : '---'}</span>
                </div>
                <div className='col-lg-4 d-flex flex-column mb-3'>
                  <label className='col-lg-4 col-form-label fw-bold fs-5'>Facebook</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.facebook? props.data.facebook : '---'}</span>
                </div>
                <div className='col-lg-4 d-flex flex-column mb-3'>
                  <label className='col-lg-4 col-form-label fw-bold fs-5'>Instagram</label>
                  <span className='fw-bold text-gray-500 fs-6'>{props.data.instagram? props.data.instagram : '---'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {DetailCompany}
