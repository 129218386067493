import React, {useEffect} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
// import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'

import CustomVideoPlayer from '../helpers/components/CustomVideoPlayer/CustomVideoPlayer'

import {
  DrawerMessenger,
  // ExploreMain,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
  Alert01,
  CreateAction,
  CreateCompany,
} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const MasterLayout: React.FC = ({children}) => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])


  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <CustomVideoPlayer/>

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>{children}</Content>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <ExploreMain /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <Alert01/>
      <CreateAction/>
      <CreateCompany/>
      <ToastContainer />
      
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
