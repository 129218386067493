/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

const AsideDefault: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const {config, classes} = useLayout()
  const {aside} = config

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {aside.theme === 'dark' && (
          <Link to='/dashboard'>
            <img
              alt='Logo'
              className=' logo'
              style={{width: '125px', height: '36px'}}
              src={toAbsoluteUrl('/media/logos/Group 1.svg')}
            />
          </Link>
        )}
        <span className='text-gray-400 text-center '
        style={{background: 'rgba(109, 113, 133, 0.25)',
          borderRadius: '15px',
          fontSize:  '9px' ,
          width: '48px' ,
          height: user.roles === 'free' ? '17px': '',
          padding: user.roles === 'free' ? '3px' : '3px',
          textAlign: 'center'
          }}
        >
          {
          user.roles === 'super-admin' ?
            <>Super</>
            :
            <>
            {
              user.roles === 'company-user'?
              <>User</>
              :
             <>{
              user.roles === 'manager-adm' ?
              <>adm</>
              :
              user.roles
             }</>
            }
            </>
          }
        </span>
        {aside.theme === 'light' && (
          <Link to='/dashboard'>
            <img
              alt='Logo'
              className='h-60px logo'
              src={toAbsoluteUrl('/media/logos/breven_law_color_logo.svg')}
            />
          </Link>
        )}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
      </div>
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
    </div>
  )
}

export {AsideDefault}
