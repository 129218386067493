import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../redux/AuthCRUD'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { toast } from 'react-toastify'
import { trataRespota } from '../../../../app/modules/helpers/helpers'
import { useIntl } from 'react-intl'

const initialValues = {
  email: '',
}



export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const intl = useIntl();   

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${intl.formatMessage({id: 'Formato de e-mail incorreto'})}`)
      .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
      .required(`${intl.formatMessage({id: 'E-mail'})} ${intl.formatMessage({id: 'È requerido'})}`),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
        requestPassword(values.email)
          .then((data) => {
            if(data.data.status === 'error'){
              toast.error(data.data.message);
            }else {
              toast.success(data.data.message);
            }
            setLoading(false)
          })
          .catch((error) => {
            trataRespota('error', error); 
           // toast.error(error.response.data.message);
            setLoading(false)
            setSubmitting(false)
            setStatus(`${intl.formatMessage({id: 'Os detalhes de login estão incorretos'})}`)
          })
    },
  })

  return (
    <div className='d-flex flex-column bg-gray-middle flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    style={{
      backgroundImage: `url(${toAbsoluteUrl('/media/logos/background.png')})`,
      backgroundPosition: 'center',
      backgroundColor: '#1E1E2D',
      backgroundSize: 'cover' 
    }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20'>
        {/* <div className='mb-5'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/breven_law_color_logo.svg')} className='h-95px' />
        </div> */}
        <div className='w-lg-500px bg-white rounded-5 shadow-sm p-10 p-lg-15 mx-auto'
        style={{maxWidth: '450px'}}
        >
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='text-center mb-10'>
              <h1 className='text-primary mb-3'>{intl.formatMessage({id: 'Esqueceu a senha?'})}</h1>
              <div className='text-gray-400 fw-bold fs-4'>{intl.formatMessage({id: 'Insira seu e-mail para redefinir sua senha'})}</div>
            </div>
            {/* {hasErrors === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  {message}
                </div>
              </div>
            )}
            {hasErrors === false && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>{message}</div>
              </div>
            )} */}
            <div className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({id: 'E-mail'})}</label>
              <input
                type='email'
                placeholder='example@mail.com'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block text-danger mt-2 fs-5'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='d-flex flex-wrap justify-content-center pb-lg-0 row'>
              <div className='col-6'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn bg-hover-dark mb-5 text-white w-100'
                  style={{width: '168px', height: '48px', background: '#65638E', borderRadius: 13}}
                >
                  {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Enviar'})}</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              <div className='col-6'>
                <Link to='/auth/login'>
                  <button
                    type='button'
                    id='kt_login_password_reset_form_cancel_button'
                    className='btn btn-lg btn-light-danger w-100 mb-5'
                    style={{border: '1px solid #d9214e', width: '168px', height: '48px', borderRadius: 13}} 
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {intl.formatMessage({id: 'Cancelar'})}
                  </button>
                </Link>{' '}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
