import React, {useState} from 'react'
import { useFormik } from 'formik'
import { registerToken } from '../redux/AuthCRUD'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl'
import { ToastContainer, toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import clsx from 'clsx'
import * as Yup from 'yup'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

interface MatchParams {
  token: string;
  role: string;
}

const initialValues = {
  firstname: '',
  lastname: '',
  email: '', 
  password: '',
  changepassword: '',
  role: null,
  accepted: 1,
  community: "0",
  acceptTerms: false,
  token: null
}

const RegistrationToken: React.FC<RouteComponentProps<MatchParams>> = (props) => {

  const [loading, setLoading] = useState(false)
  const [seePassword, setSeePassword] = useState('password');
  const token = props.match.params.token;
  const role = props.match.params.role;
  const history = useHistory();
  const [data, setData] = useState<any>({});
  const intl = useIntl();   

  const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
      .required(`${intl.formatMessage({id: 'O primeiro nome é requerido'})}`),
    email: Yup.string()
      .email(`${intl.formatMessage({id: 'O valor não é um endereço de e-mail válido'})}`)
      .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
      .required(`${intl.formatMessage({id: 'O e-mail é requerido'})}`),
    lastname: Yup.string()
      .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
      .required(`${intl.formatMessage({id: 'O sobrenome é requerido'})}`),
    password: Yup.string()
      .min(3, 'Mínimo de 6 símbolos')
      .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%^&*])(?=.{6,})/,
        `${intl.formatMessage({id: 'Use 6 ou mais caracteres com uma mistura de letras, números e símbolos'})}`
      )
      .required(`${intl.formatMessage({id: 'A senha é requerida'})}`),
    changepassword: Yup.string()
      .required(`${intl.formatMessage({id: 'A confirmação da senha é necessária'})}`)
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], `${intl.formatMessage({id: 'Senha e Confirmar senha não coincidem'})}`),
      }),
    acceptTerms: Yup.bool().required(`${intl.formatMessage({id: 'Você deve aceitar os Termos e Condições'})}`),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setData(values);
      handleOpen2();
    },
  })

  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [tela, setTela] = useState(0);

  const [responseMessage, setResponseMessage] = useState('');

  const handleRegister2 = (values: any) => {
    
    handleClose2();
    setResponseMessage('')
    setLoading(true);
    
    const send = registerToken(
      values.email, 
      values.firstname, 
      values.lastname, 
      values.password, 
      values.changepassword, 
      values.acceptTerms ? values.acceptTerms : 0, 
      role,
      values.accepted,
      values.community,
      token);

    send.then((data) => {

      if(data.data.status === 'success'){
        setResponseMessage(data.data.message)
        toast.success(data.data.message);
        handleClose2();

        setTimeout(() => {
          history.push("/auth/login");
        }, 3000)
        
      }
      else if(data.data.status === 'error'){
        handleClose();
        setResponseMessage(data.data.message)
        toast.success(data.data.message)
      } else {
        setResponseMessage('Ops!')
        toast.error('Ops!');
      }
    })
    .catch((error) => {
      if (error.response) {
        setResponseMessage(error.response.data.message)
        toast.error(error.response.data.message)
      } else {
        setResponseMessage('Desculpe, ocorreu um erro')
        toast.error(`${intl.formatMessage({id: 'Desculpe, ocorreu um erro'})}`)
      }
    }).finally(()=>setLoading(false));
  }
  // const handleRegister = () => {
  //   const data = {
  //     accepted: 0,
  //     token
  //   }
  //   const send = regToken(data);
  //   send.then((res)=>{
  //     if(res.data.status === 'success'){
  //       toast.success(res.data.message);
  //     }
  //     else if(res.data.status === 'error'){
  //       toast.error(res.data.message);
  //       handleClose();
  //       history.push("/auth/login");
  //     } else {
  //       toast.error('Ops!');
  //     }

  //   }).catch((error)=>{
  //     if(error.response){
  //       toast.error(error.response.data.message);
  //     } else {
  //       toast.error(`${intl.formatMessage({id: 'Desculpe, ocorreu um erro'})}`);
  //     }
  //   }).finally(()=>setLoading(false));
  // }

  return (
    <>
    <div>
    <ToastContainer />
    </div>
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        data-backdrop="static"
        disableAutoFocus
      >
        <Box sx={style}>

          <div className=''>
            <span className='fs-3 text-gray-800 fw-bold'>{intl.formatMessage({id: 'Você recebeu um convite'})}</span>
          </div>
          <div className='my-10'>
            <span className='fs-4 text-gray-700'>{intl.formatMessage({id: 'Deseja aceitar o convite recebido?'})}</span>
          </div>
          <div className='d-flex flex-row justify-content-end aling-items-center'>
            <button 
              className='btn btn-md btn-secondary me-2'
              onClick={()=>handleRegister2({accepted: 0, token})}
            >
              {intl.formatMessage({id: 'Não'})}
            </button>
            <button onClick={ ()=> [ handleClose(), setTela(1) ]} className='btn btn-md btn-primary text-white'>
            {intl.formatMessage({id: 'Sim'})}
            </button>
          </div>
        </Box>
      </Modal>
    </div>

    <div>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        data-backdrop="static"
        disableAutoFocus
      >
        <Box sx={style}>

          <div className=''>
            <span className='fs-3 text-gray-800 fw-bold'>{intl.formatMessage({id: 'Atenção'})}</span>
          </div>
          <div className='my-10'>
            <span className='fs-4 text-gray-700'>{intl.formatMessage({id: 'Deseja enviar as informações?'})}</span>
          </div>
          <div className='d-flex flex-row justify-content-end aling-items-center'>
            <button 
              className='btn btn-md btn-secondary me-2'
              onClick={()=>handleClose2()}
            >
              {intl.formatMessage({id: 'Não'})}
            </button>
            <button disabled={loading} onClick={()=>handleRegister2(data)} className='btn btn-md text-white btn-primary'>
              {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Sim'})}</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Box>
      </Modal>
    </div>

    <div className='d-flex flex-column bg-gray-middle flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundSize: 'contain',
        // backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sigma-1/14.png')})`,
      }}
    >

      <div className='d-flex flex-center flex-column  p-5 pb-lg-20'>
        <div className=''>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/breven_law_color_logo.svg')} className='h-95px' />
        </div>
      </div>


      { 
      tela === 1 && !responseMessage?
        <div className='d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20'>

            <div className='w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
              >

              {/* {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )} */}
              
              <div className='row fv-row mb-2'>
                <div className='col-xl-6'>
                  <div className='fv-row mb-3'>
                    <label className='class="form-label fw-bold text-dark fs-5 mb-1 required'>{intl.formatMessage({id: 'Primeiro nome'})}</label>
                    <input
                      placeholder={intl.formatMessage({id: 'Primeiro nome'})}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('firstname')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.firstname && formik.errors.firstname,
                        },
                        {
                          'is-valid': formik.touched.firstname && !formik.errors.firstname,
                        }
                      )}
                    />
                    {formik.touched.firstname && formik.errors.firstname && (
                      <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.firstname}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>


                <div className='col-xl-6'>
                  <div className='fv-row mb-3'>
                    <label className='class="form-label fw-bold text-dark fs-5 required mb-1'>{intl.formatMessage({id: 'Sobrenome'})}</label>
                    <input
                      placeholder={intl.formatMessage({id: 'Sobrenome'})}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('lastname')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.lastname && formik.errors.lastname,
                        },
                        {
                          'is-valid': formik.touched.lastname && !formik.errors.lastname,
                        }
                      )}
                    />
                    {formik.touched.lastname && formik.errors.lastname && (
                      <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.lastname}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>


              <div className='fv-row mb-3'>
                <label className='form-label fw-bold text-dark fs-5 required'>{intl.formatMessage({id: 'E-mail'})}</label>
                <input
                  placeholder={intl.formatMessage({id: 'E-mail'})}
                  type='email'
                  autoComplete='off'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>


              {/* <div className='fv-row my-4'>
                <label className='form-label fw-bold text-dark fs-5 required'>Role</label>
                <div className='mt-3'>
                  <label className='form-check form-check-custom form-check-solid align-items-center'>
                    <input
                      type='radio'
                      name='role'
                      className='form-check-input me-3'
                      onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
                    />
                    <span className='form-check-label d-flex flex-column align-items-start'>
                      <span className='fw-bolder text-gray-800 fs-6 mb-0'>DPO</span>
                      <span className='text-gray-600 fs-6 text-justify'>
                        In charge of processing personal data of a company, and that does not fit the company's staff.
                      </span>
                    </span>
                  </label>
                  <div className='separator separator-dashed my-6'></div>
                </div>
                <div className='mt-3'>
                  <label className='form-check form-check-custom form-check-solid align-items-center'>
                    <input
                      type='radio'
                      name='role'
                      className='form-check-input me-3'
                      onChange={()=>[setRole('company'), setErrorRole('')]}
                    />
                    <span className='form-check-label d-flex flex-column align-items-start'>
                      <span className='fw-bolder text-gray-800 fs-6 mb-0'>Company</span>
                      <span className='text-gray-600 fs-6 text-justify'>
                        An organization composed of people whose objective is to carry out an economic activity seeking commercial results through the production and/or sale of goods and services.
                      </span>
                    </span>
                  </label>
                  <div className='separator separator-dashed my-6'></div>
                </div>
                <div className='mt-3'>
                  <label className='form-check form-check-custom form-check-solid align-items-center'>
                    <input
                      type='radio'
                      name='role'
                      className='form-check-input me-3'
                      onChange={()=>[setRole('freelancer'), setErrorRole('')]}
                    />
                    <span className='form-check-label d-flex flex-column align-items-start'>
                      <span className='fw-bolder text-gray-800 fs-6 mb-0'>Freelancer</span>
                      <span className='text-gray-600 fs-6 text-justify'>
                        They are professionals who carry out their activity on their own without an employment relationship with anyone, but they must be registered with a professional council body.
                      </span>
                    </span>
                  </label>
                  <div className='separator separator-dashed mt-6 mb-1'></div>
                </div>
                {errorRole && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errorRole}</span>
                    </div>
                  </div>
                )}
              </div>
                  
              <div className='fv-row mb-3'>
                <label className='form-label fw-bold text-dark fs-5 required'>Document (CPF/CNPJ)</label>
                <input
                  placeholder='CPF/CNPJ'
                  type='text'
                  autoComplete='off'
                  maxLength={18}
                  onChange={e=>[addMask(e.currentTarget.value), setErrorDocument('')]}                
                  value={document}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': errorDocument},
                    {
                      'is-valid': document.length >= 14,
                    }
                  )}
                />
                {errorDocument && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errorDocument}</span>
                    </div>
                  </div>
                )}
              </div> */}

              <div className='mb-2 fv-row' data-kt-password-meter='true'>
                <div className='mb-1'>
                  <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Senha'})}</label>
                  <div className='position-relative mb-3'>
                    <div className='d-flex justify-content-between w-100'>
                      <input
                        type={seePassword}
                        placeholder={intl.formatMessage({id: 'Senha'})}
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid w-90 me-1',
                          {
                            'is-invalid': formik.touched.password && formik.errors.password,
                          },
                          {
                            'is-valid': formik.touched.password && !formik.errors.password,
                          }
                        )}
                      />
                      {
                        seePassword === 'password'?
                        <button onClick={()=>setSeePassword('text')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                          <i className="bi bi-eye-slash-fill fs-2x"></i>
                        </button>
                        :
                        <button onClick={()=>setSeePassword('password')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                          <i className="bi bi-eye-fill fs-2x"></i>
                        </button>
                      }
                    </div>
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='fv-row mb-5'>
                <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Confirme a Senha'})}</label>
                <input
                  type='password'
                  placeholder={intl.formatMessage({id: 'Confirme a Senha'})}
                  autoComplete='off'
                  {...formik.getFieldProps('changepassword')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                    },
                    {
                      'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                    }
                  )}
                />
                {formik.touched.changepassword && formik.errors.changepassword && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.changepassword}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-10'>
                <div className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='kt_login_toc_agree'
                    {...formik.getFieldProps('acceptTerms')}
                  />
                  <label
                    className='form-check-label fw-bold text-gray-700 fs-6'
                    htmlFor='kt_login_toc_agree'
                  >
                    {intl.formatMessage({id: 'Eu concordo com os'})}{' '}
                    <Link to='/auth/terms' className='ms-1 link-primary'>
                    {intl.formatMessage({id: 'termos e condições'})}
                    </Link>
                    .
                  </label>
                  {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.acceptTerms}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {
                role === 'dpo'?
                <div className='fv-row mb-10'>
                <div className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    {...formik.getFieldProps('community')}
                    id='kt_login_toc_community'
                  />
                  <label
                    className='form-check-label fw-bold text-gray-700 fs-6'
                    htmlFor='kt_login_toc_community'
                  >
                    {intl.formatMessage({id: 'Eu quero fazer parte da comunidade'})}
                  </label>
                </div>
              </div>
              :
              <></>
              }
              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary text-white w-100 mb-5'
                  disabled={!formik.values.acceptTerms || loading}
                >
                  {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Enviar'})}</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              </form>
            </div>

            
        </div>
      :
          
      responseMessage ? 
        <div className='d-flex flex-center p-5 pb-lg-20'>
          <div className='w-lg-600px bg-white text-center rounded shadow-sm p-10 p-lg-15 mx-auto'>
            {responseMessage}
          </div>
        </div>

        :

        null

      
      }
      
    </div>
    </>
  )
}
export default RegistrationToken;