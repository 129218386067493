/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { deleteAnswer, defaultPaginationList } from '../../../../../app/modules/helpers/Api'
import { converteData } from '../../../../../app/modules/helpers/helpers'
import { KTSVG } from '../../../../helpers'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import ModalMessage from '../../modals/modalMessage'
import { Load } from '../../spinner/Spinner'
import Warning from '../../warning/Warning'
import Pagination from '../../../../helpers/components/CustomPagination/CustomPagination'
import ModalRequerimentToken from './ModalRequerimentToken'
import ModalHolderRequest from './ModalHolderRequest'

let searchData = {
  search_value: '',
  type: '',
  status: '',
  code: '',
  page: 1,
  perpage: 10,
  company: 0
}

type Props = {
  // company_id: number;
  msg: (status: any, message: any) => void;
}

const CompanyRequeriments: React.FC<Props> = ({ ...props }) => {

  const intl = useIntl();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [statusFilter, setStatusFilter] = useState<any>();
  const [searchValueFilter, setSearchValueFilter] = useState<any>();
  const [typeFilter, setTypeFilter] = useState<any>();
  const [openModalReqTokens, setOpenModalReqTokens] = useState(false);
  const [openModalHolderRequest, setOpenModalHolderRequest] = useState(false);
  const [holderRequest, setHolderRequest] = useState([]);

  useEffect(() => {
    handleGetList()
  }, [isLoading2]);

  function handleGetList(data: any = null) {

    setLoading(true);

    // searchData.company = props.company_id
    searchData.search_value = searchValueFilter ? searchValueFilter : '';
    searchData.status = statusFilter ? statusFilter : '';
    searchData.type = typeFilter ? typeFilter : '';

    const get = defaultPaginationList(`company/requeriments`, searchData)
    get.then((res) => {

      if (res.data.status === 'success') {
        setData(res.data.data);
      } else if (res.data.status === 'error') {
        toast.error(res.data.message)
      }

    }).catch((error) => {
      toast.error(error.response.data.message)
    })
      .finally(() => [setLoading(false), setIsLoading2]);
  }

  function handleDelete(id: any = null) {
    const del = deleteAnswer(id);
    del.then((res) => {
      if (res.data.status === 'success') {
        toast.success(res.data.message);
        setOpenModalDelete(false);
      }
      if (res.data.status === 'error') {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      if (error.response) {
        toast.error(error.response.message);
      } else {
        toast.error(`${intl.formatMessage({ id: 'Sorry, an error occurred' })}`);
      }
    }).finally(() =>
      setIsLoading2(true)
    );
  }

  function getFormSelected(id: any, obj: any) {
    // setIdForm(id);
    // setForm(obj.name);
    // setModelForm(obj.model);
    // setOpenModalCalculatorTitle(true);
    // setOpenModalCalculatorType(false);
  }

  const [openModalDelete, setOpenModalDelete] = useState(false);

  return (
    <>
      <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
        <KTSVG path="/media/icons/duotune/general/gen044.svg" className="svg-icon-primary svg-icon-2hx me-5" />
        <div className="d-flex flex-column">
          <h5 className="mb-1">{intl.formatMessage({ id: 'attention' })}!</h5>
          <span className='fs-5'>
            <span>Para visualizar ou criar um token de comunicação  <b><span className='cursor-pointer' onClick={() => setOpenModalReqTokens(true)}>clique aqui</span> </b>.</span>

          </span>
        </div>
      </div>
      {/* <ModalActionPlansReport 
        id={idAnswerReport} 
        title={`${intl.formatMessage({id: 'New Action Plan'})}`} 
        showModal={openModalActionPlans}
        onClose={()=>setOpenModalActionPlans(false)} 
      />
      {
        dataDegreeId !== ''?
        <ModalReportGraph
          title= {`${intl.formatMessage({id: 'Report:'})} ${intl.formatMessage({id: 'Maturity analysis'})}`}
          id={dataDegreeId}
          reportPosition={reportPosition}
          showModal={openModalReportGraph}
          onClose={()=>setOpenModalReportGraph(false)}
        />
        :
        <></>
      } */}
      <ModalMessage
        message={`${intl.formatMessage({ id: 'Are you sure you want to delete this item?' })} `}
        title={`${intl.formatMessage({ id: 'Attention' })} `}
        showModal={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        onAction={() => handleDelete()}
      />
      <div className='card mb-xl-10 rounded-4'>
        <div className='w-100 container'>
          <div className='form'>
            <div className=" row  py-6 d-flex align-items-center justify-content-between" >
              <div
                className="col-md-3"
              >

                <input
                  type="text"
                  className="form-control form-control-solid fs-6 rounded-3"
                  placeholder={intl.formatMessage({ id: 'Search' })}
                  name="search_value"
                  autoComplete='off'
                  value={searchValueFilter}
                  onChange={(e) => [setSearchValueFilter(e.target.value)]}
                />

              </div>

              <div
                className='col-md-3'
              >
                <select className='form-select form-control form-control-solid fs-6'
                  name="status"
                  value={statusFilter}
                  onChange={(e) => [setStatusFilter(e.target.value)]}

                >
                  <option value='' >{intl.formatMessage({ id: 'All Status' })}</option>
                  <option value='Pending' >{intl.formatMessage({ id: 'Pending' })}</option>
                  <option value='Checking'>{intl.formatMessage({ id: 'Checking' })}</option>
                  <option value='Done'>{intl.formatMessage({ id: 'Done' })}</option>
                  <option value='Canceled'>{intl.formatMessage({ id: 'Canceled' })}</option>
                  <option value='Out of date'>{intl.formatMessage({ id: 'Out of date' })}</option>

                </select>
              </div>
              <div
                className='col-md-3'
              >
                <select className='form-select form-control form-control-solid fs-6'
                  name="type"
                  value={typeFilter}
                  onChange={(e) => [setTypeFilter(e.target.value)]}
                >
                  <option value='' >{intl.formatMessage({ id: 'All Types' })}</option>
                  <option value='holders requests' >{intl.formatMessage({ id: 'holders requests' })}</option>
                </select>

              </div>
              <div
                className='col-md-2'
                style={{ marginRight: '20px' }}

              >
                <div className=' '>

                  <button
                    type='button'
                    className='btn bg-hover-dark mb-5 text-white mt-3'
                    disabled={loading}
                    onClick={() => handleGetList(null)}
                    style={{ width: '125px', height: '40px', background: '#65638E', borderRadius: 13 }}
                  >
                    {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Search' })}</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>




                </div>
              </div>



            </div>
          </div>
        </div>





        <div className='card-body py-3'>


          <div className='mt-5 table-responsive'>
            {
              loading && data.length === 0 ?
                <Load />
                :
                <>
                  {
                    data.data && data.data.length === 0 ?
                      <Warning message={intl.formatMessage({ id: 'No record found' })} />
                      :
                      <div>
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                          <thead>
                            <tr className='fw-bolder text-muted'>
                              <th className='min-w-100px text-center'>{intl.formatMessage({ id: 'Actions' })}</th>
                              {/* <th className='min-w-60px'>#</th> */}
                              <th className='min-w-200px text-center'>{intl.formatMessage({ id: 'Company' })}</th>
                              <th className='min-w-250px text-center'>{intl.formatMessage({ id: 'Requester' })}</th>
                              <th className='min-w-150px text-center'>{intl.formatMessage({ id: 'Type' })}</th>
                              <th className='min-w-350px text-center'>{intl.formatMessage({ id: 'Token' })}</th>
                              <th className='min-w-100px text-center'>{intl.formatMessage({ id: 'Status' })}</th>
                              <th className='min-w-200px text-center'>{intl.formatMessage({ id: 'Request in' })}</th>
                              <th className='min-w-200px text-center'>{intl.formatMessage({ id: 'Deadline' })}</th>
                              <th className='min-w-250px text-center'>{intl.formatMessage({ id: 'User' })}</th>
                              <th className='min-w-200px text-center'>{intl.formatMessage({ id: 'Reply in' })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.data?.map((item: any, index: any) =>
                              <tr key={index}>

                                <td className='d-flex align-items-center justify-content-center'>
                                  <button className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1' onClick={() => [setHolderRequest(item), setOpenModalHolderRequest(true)]}>
                                    <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3 svg-icon-primary' />
                                  </button>
                                  {/* <button  className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm' disabled >
                                <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _trash_.svg')} style={{marginRight: '10px'}} />
                              </button> */}
                                </td>

                                {/* <td>
                                  <span className='text-gray-800 fw-bold d-block mb-1 fs-6 '>
                                    {item.code}
                                  </span>
                                </td> */}

                                <td>
                                  <span className='text-gray-800 fw-bold d-block mb-1 fs-6  text-center'>
                                    {item.company}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-gray-800 fw-bold d-block mb-1 fs-6  text-center'>
                                    {item.requester_name == 0 ? <span className='text-danger'>Anonimo</span> : item.requester_name}
                                  </span>
                                </td>

                                <td>
                                  <span className='text-gray-800 fw-bold d-block mb-1 fs-6  text-center'>
                                    {item.type}
                                  </span>
                                </td>

                                <td>
                                  <span className='text-gray-800 fw-bold d-block mb-1 fs-6  text-center'>
                                    {item.token_title}
                                  </span>
                                </td>

                                <td>
                                  <span className='text-gray-800 fw-bold d-block fs-6  text-center'>
                                    {
                                      item.status === 'Pending' ?
                                        <span className='badge badge-light-primary'>{intl.formatMessage({ id: 'Pending' })}</span>
                                        : item.status === 'Checking' ?
                                          <span className='badge badge-light-info'>{intl.formatMessage({ id: 'Checking' })}</span>
                                          : item.status === 'Done' ?
                                            <span className='badge badge-light-success'>{intl.formatMessage({ id: 'Done' })}</span>
                                            : item.status === 'Canceled' ?
                                              <span className='badge badge-light-danger'>{intl.formatMessage({ id: 'Canceled' })}</span>
                                              : item.status === 'Out of date' ?
                                                <span className='badge badge-light-dark'>{intl.formatMessage({ id: 'Out of date' })}</span>
                                                : '- - -'
                                    }
                                  </span>
                                </td>

                                <td>
                                  <span className='text-gray-800 fw-bold d-block mb-1 fs-6  text-center'>
                                    {converteData(item.request_at)}
                                  </span>
                                </td>

                                <td>
                                  <span className='text-gray-800 fw-bold d-block mb-1 fs-6  text-center'>
                                    {converteData(item.deadline)}
                                  </span>
                                </td>

                                <td>
                                  <span className='text-gray-800 fw-bold d-block mb-1 fs-6  text-center'>
                                    {item.user ? item.user : '- - -'}
                                  </span>
                                </td>

                                <td>
                                  <span className='text-gray-800 fw-bold d-block mb-1 fs-6  text-center'>
                                    {item.reply_at ? converteData(item.reply_at) : '- - -'}
                                  </span>
                                </td>


                              </tr>
                            )}
                          </tbody>
                        </table>
                        <Pagination data={data} onSearch={handleGetList} searchData={searchData} />
                      </div>

                  }
                </>
            }
          </div>


          {/* <div className='py-6'>
            <span className='link-warning fs-5 cursor-pointer' onClick={() => setOpenModalReqTokens(true)}>{intl.formatMessage({ id: "Holders' Requirements - Tokens" })} </span>
          </div> */}

          <ModalRequerimentToken
            title={intl.formatMessage({ id: 'New Calculator' })}
            showModal={openModalReqTokens}
            onClose={() => setOpenModalReqTokens(false)}
            onAction={getFormSelected}
          // company_id={props.company_id}
          />

          <ModalHolderRequest
            showModal={openModalHolderRequest}
            onClose={() => setOpenModalHolderRequest(false)}
            onAction={getFormSelected}
            holderRequest={holderRequest}
            handleGetList={() => handleGetList(null)}
          />


        </div>
      </div>

    </>
  )
}

export { CompanyRequeriments }
