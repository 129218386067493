/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { createResources, getResourcesId } from '../../../../../app/modules/helpers/Api'
import { toast } from 'react-toastify'
import ModalMessageConfirm from '../../modals/modalMessageConfirm'
import { Load } from '../../spinner/Spinner'

const schema = Yup.object().shape({
  title: Yup.string()
  .required('Title is required'),
  type: Yup.string()
  .required('Type is required'),
})

interface IResourceProps {
  title: string,
  type: any,
  free: number,
  url: string,
  model: any,
  modelId: any
}

type Props = {
  edit: (action: boolean) => void;
  id?: any,
  resource: IResourceProps
}

const EditResource: FC<Props> = ({...props}) => {  
  const [loading, setLoading] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [file, setFile] = useState<any>('');
  const [errorFile, setErrorFile] = useState<any>('');
  const [fileType, setFileType] = useState<any>('');
  const [fileSize, setFileSize] = useState<any>();
  const [video, setVideo] = useState<any>('');
  const [resourceValues, setResourceValue] = useState<any>({});
  const [resourceData, setResourceData] = useState<any>({});
  const [openModalSubmit, setOpenModalSubmit] = useState(false);

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const files = target.files as FileList;
    const arrayFiles = Array.from(files);
    setFileType(arrayFiles[0].type);
    setFileSize(arrayFiles[0].size);
    setFile(arrayFiles);
  }

  // const uploadVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const target = event.currentTarget as HTMLInputElement;
  //   const files = target.files as FileList;
  //   const arrayFiles = Array.from(files);
  //   setFileType(arrayFiles[0].type);
  //   setFileSize(arrayFiles[0].size);
  //   setVideo(arrayFiles);
  // }


  const initialValues = {
    modelId: props.resource.modelId,
    model: props.resource.model,
    title: props.resource.title,
    free: props.resource.free === 0? false : true,
    type: props.resource.type,
    url: props.resource.url
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      var error = false;
      setTimeout(() => {
        setResourceValue(values);
        if(
            fileType !== 'application/pdf' && 
            fileType !== 'image/png' &&
            fileType !== 'image/jpeg' &&
            fileType !== 'image/jpg' &&
            fileType !== 'audio/mp3' &&
            fileType !== 'video/mp4' &&
            fileType !== 'application/vnd.ms-excel' &&
            fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ){
          setErrorFile('File type is invalid!');
          error = true;
        }
        if(fileSize > 350000000){
          setErrorFile('File size is invalid!');
          error = true;
        }
        if(!error){setOpenModalSubmit(true)}
        setLoading(false)
      }, 1000)
    },
  })

  function save (){
    var formData = new FormData();
    formData.append('modelId', props.id? props.id : resourceValues.modelId);
    formData.append('free', resourceValues.free? '1' : '0');
    formData.append('title', resourceValues.title);
    formData.append('type', resourceValues.type);
    formData.append('url', resourceValues.url);
    formData.append('video', video[0]);
    formData.append('file', file[0]);

    const create = createResources(formData);
    // create.then((res)=>{
    //   if(res.data.status === 'success'){
    //     toast.success(res.data.message);
    //     props.edit(false);
    //   }
    //   if(res.data.status === 'error'){
    //     toast.error(res.data.message);
    //   }
    // }).catch((error)=>{
    //   toast.error(error.response.data.message);
    // });
  }

  return (
   <>
    {
      loadingScreen?
      <Load/>
      :
      <div className='card mb-5 mb-xl-10'>
      <ModalMessageConfirm
        message='Are you sure you want to create this item?'
        title='Attention!'
        showModal={openModalSubmit}
        onClose={()=>setOpenModalSubmit(false)}
        onAction={save}
      />
      <div className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top px-5 py-5'>
            <div className='d-flex justify-content-between border-bottom pb-4 align-items-center mb-3'>
              <label className='form-label fw-bolder text-dark fs-3 '>New resource</label>
              <button onClick={()=>props.edit(false)} type='submit' className='btn btn-sm btn-secondary'>
                Back
              </button>
            </div>
            
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>Type</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('type')}
                    >
                      {/* <option value='1' disabled>URL</option> */}
                      {/* <option value='2' disabled>Form</option> */}
                      <option value='3'>File</option>
                      {/* <option value='4' disabled>Video</option> */}
                    </select>
                  </div>
                  {formik.touched.type && formik.errors.type && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.type}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
              <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>Title</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('title')}
                    >
                      <option value=''>...Select an option</option>
                      <option value='User term dpo'>User term dpo</option>
                      <option value='User term company'>User term company</option>
                      <option value='User term adv'>User term adv</option>
                      <option value='User term liberal'>User term liberal</option>
                      <option value='User term startup'>User term startup</option>
                      <option value='User term'>User term</option>
                    </select>
                  </div>
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
           
            
            {
              // formik.values.type === '1'?
              // <div className='mb-2 fv-row' data-kt-password-meter='true'>
              //   <div className='mb-1 mt-4'>
              //     <label className='form-label fw-bold text-dark fs-6'>URL</label>
              //     <div className='position-relative mb-3'>
              //       <div className='d-flex justify-content-between w-100'>
              //         <input
              //           type='text' 
              //           placeholder='URL'
              //           autoComplete='off'
              //           {...formik.getFieldProps('url')}
              //           className='form-control form-control-lg form-control-solid w-100 me-1'
              //         />
              //       </div>
              //     </div>
              //   </div>
              // </div>
              // :
              <>
                {
                  formik.values.type === '3' || formik.values.type === '5'?
                  <div className='mb-2 fv-row' data-kt-password-meter='true'>
                    <div className='mb-1 mt-4'>
                      <label className='form-label fw-bold text-dark fs-6'>
                        File
                      </label>
                      <div className='position-relative mb-3'>
                        <div className='d-flex justify-content-between w-100 mb-1'>
                          <input
                            type='file' 
                            onChange={(event)=>[uploadFile(event), setErrorFile('')]}
                            className='form-control form-control-lg form-control-solid w-100 me-1'
                          />
                        </div>
                        <small>Format: PDF, MP3, MP4, PNG, JPEG, JPG, XLS, XLSX; Max upload: 2MB</small>
                      </div>
                      {errorFile && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errorFile}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  :
                  <>
                    {/* {
                      formik.values.type === '4'?
                      <div className='mb-2 fv-row' data-kt-password-meter='true'>
                        <div className='mb-1 mt-4'>
                          <label className='form-label fw-bold text-dark fs-6'>
                            Video
                          </label>
                          <div className='position-relative mb-3'>
                            <div className='d-flex justify-content-between w-100'>
                              <input
                                type='file' 
                                onChange={(event)=>[uploadVideo(event)]}
                                className='form-control form-control-lg form-control-solid w-100 me-1'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <></>
                    } */}
                  </> 
                }
              </>
            }

            <div className='fv-row my-5'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='kt_login_toc_agree'
                  {...formik.getFieldProps('free')}
                />
                <label
                  className='form-check-label fw-bold text-gray-700 fs-6'
                  htmlFor='kt_login_toc_agree'
                >
                  Private resource
                </label>
              </div>
            </div>

          </div>
          
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Processando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
    }
   </>
  )
}

export {EditResource}
