import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/system';
import { KTSVG } from '../../../../../helpers';
import { useFormik} from 'formik';
import DatePicker from "react-datepicker";
import { converteData2, copyText } from '../../../../../../app/modules/helpers/helpers';
import { Load } from '../../../spinner/Spinner';
import Pagination from '../../../../../helpers/components/CustomPagination/CustomPagination'
import Modal from '@mui/material/Modal';
import { delAction, defaultPaginationList, monitoringCreate, monitoringUpdate, getResourceLink } from '../../../../../../app/modules/helpers/Api';
import ModalMessage from '../../../modals/modalMessage';
import ActionPlanActionEdition from './ActionPlanActionEdition';
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom';

import { Evidences } from '../Evidences/Evidences'


// import Dropdown from 'react-bootstrap-v5/Dropdown';

import { Dropdown } from 'react-bootstrap-v5'

export interface IActions {
  action_id: number,
  action_plan_id: number,
  id: number,
  status: any,
  date_end: string,
  date_start: string,
  justification: any,
  objective: any,
  resources: any,
  monitoring: any,
  title: any,
  use_degree: any
}

type Props = {
  index: any
  action: IActions
  onFinish: (action: boolean) => void;
  onReload: ()=> void;
}

let searchData = {
  date: '',
  plan_action_id: '',
  page: 1,
  perpage: 5,
}
let color = '#EEE346'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'white',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};



const ActionPlanActionList: React.FC<Props> = ({...props}) => {
  
  const [edition, setEdition] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [monitorings, addItemList] = useState<any>([]);
  const [dataValues, setDataValues] = useState<any>([]);
  const [tela, setTela] = useState(1);
  const [openModalMonitoring, setOpenModalMonitoring] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const [dataValuesEdition, setDataValuesEdition] = useState<any>([]);
  const [load, setLoad] = useState(false);
  const [openModalConfirmEdition, setOpenModalConfirmEdition] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [date, setDate] = useState(new Date);
  const [idActionPlan, setIdActionPlan] = useState<any>();
  const [pageList, setPageList] = useState (1);
  const [enableAddDate, setEnableAddDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getMonitoringFilter, setGetMonitoringFilter] = useState ('');
  const [statusEdition, setStatusEdition] = useState('');
  const [descriptionEdition, setDescriptionEdition] = useState('');
  const [dateEdition, setDateEdition] = useState('');
  const intl = useIntl();   
  const [openModalResources, setOpenModalResources] = useState(false)
  const [resourcesList, setResourcesList] = useState <any> ();

  const [showEvidence, setShowEvidence] = useState(false)
  


  searchData.plan_action_id = idActionPlan;
  searchData.page = pageList;
  searchData.date =  getMonitoringFilter;
  const history = useHistory();

  
    if(moment().format('YYYY-MM-DD') > props.action.date_end){
      color = 'red'
    }if(moment().format('YYYY-MM-DD') <= props.action.date_end  ){
      color = '#EEE346'
    }if(props.action.objective === 'yes'){
      color = 'green'
    }
    if(props.action.objective === 'no'){
      color = 'red'
    }

  function handleGetResource(hash: any) {
    const get = getResourceLink(hash);
  }

  function handleDelete(){
    const del = delAction(props.action.action_plan_id, props.action.action_id);
    del.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        setOpenModalDelete(false);
        props.onFinish(true);
        setOpenModalDelete(false)
        props.onReload()
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
        setOpenModalDelete(false)
        props.onReload()
      }
    }).catch((error)=>{
      toast.error(error.response.data.message);
    })
  }
  const formik = useFormik({
    initialValues: {
      status:  '',
      description: '',
      
    },
    onSubmit: (values) => {
      setOpenModalConfirm(true);
    }
  })
  const save = (values: any) => {
    const data = {
      plan_action_id: props.action.id,
      status: values.status,
      description: values.description,     
      date: moment(date).format('YYYY-MM-DD')
    } 
   
    setOpenModalConfirm(false);
    setIsLoading(true);
    setLoad(true)
    const send = monitoringCreate(data);
    send.then((res)=>{
      if(res.data.status === 'success'){
        const data = {
          date: res.data.data.date,
          status: res.data.data.status,
          description: res.data.data.description
        }
       
        setTela(1)
        toast.success(res.data.message);
        formik.resetForm()
        getMonitoringList()

      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      }
    }).finally(()=>{    
      setIsLoading(false);
    });
  } 
  function handleEdition(){
    setLoad(true);
    setTimeout(()=>{
      setLoad(false);
    },2000);
  }
  const formikEdition = useFormik({
    initialValues: {
      status: statusEdition,
      description: descriptionEdition,
      date: new Date(moment(monitorings[index]?.date).format('YYYY/MM/DD'))

    },
    onSubmit: (values) => {
      setDataValuesEdition(values);
      setOpenModalConfirmEdition(true);
    }
    
  })

  const saveEdition = (values: any) => {
    const data = {
      id: monitorings.data[index].id,
      plan_action_id: props.action.id,
      status: statusEdition,
      description: descriptionEdition,
      date: moment(values.date).format('YYYY-MM-DD'),
      
    } 
    
    setOpenModalConfirmEdition(false);
    setIsLoading(true);
    setLoad(true)
    const send = monitoringUpdate(data, monitorings.data[index].id);
    send.then((res)=>{

      if(res.data.status === 'success' || res.data.status === 'successs'){
        
        setTela(1)
        toast.success(res.data.message);
        formikEdition.resetForm()
        props.onFinish(true)
        props.onReload()
        getMonitoringList()
        
      } else {

        if(res.data.status === 'error'){
          toast.error(res.data.message);
          props.onReload()
        }

      }
      
     
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      }
    }).finally(()=>{    
      setIsLoading(false);      
     
    });
  } 
  
  function handleAddItem(){
    setTela(2)
  }

  function handleCancel(){
    setTela(1)
    formik.resetForm()
    formikEdition.resetForm()
  }
 
  
  function getMonitoringList (){
   setIsLoading(true);
   setEnableAddDate(true)
    const get = defaultPaginationList('monitoring', searchData)
    get.then((res)=>{
 
      if (res.data.status === 'success') {
        
        if (res.data.data.data.length>0) {

          if (res.data.data.data[0].date < moment().format('YYYY-MM-DD')) {
            
            setEnableAddDate(false)

          } else {

            if (res.data.data.data[0].status === 'yes' || res.data.data.data[0].status === 'no') {
              setEnableAddDate(false)
            }

          }

        }

        addItemList(res.data.data);
        setStatusEdition(res.data.data.data[0]?.status)
        setDescriptionEdition(res.data.data.data[0]?.description)
        setDateEdition(res.data.data.data[0]?.date )

      }

    }).catch((error)=>{
      toast.error(error.response.data.message)
    })
    .finally(()=>[
      setOpenModalMonitoring(true),
      setIsLoading(false),
      setLoad(false),
      setLoading2(false),
      searchData.date === ''

    ]);
   
  
  }

  function onCloseEvicende(){

  }

  return(
    <>
    
    {
      edition?
      <ActionPlanActionEdition
      
        onCancel={()=>setEdition(false)}
        onFinish={()=>[props.onFinish(true), props.onReload(), setTimeout(() => {
          setEdition(false)
        }, 150)]}
        action={props.action}
      />
      :
      <div
      
      className='accordion my-2 ' id={`kt_accordion_${props.index}`} >
        {/* <ActionMonitoring
          title='Monitorig'
          plan_action_id={props.action.id}
          status={props.action.status}
          onAction={createMonitoring}
          showModal={openModalResource}
          onClose={()=>setOpenModalResource(false)}
          monitoringList={props.action.monitoring}
        /> */}
        <ModalMessage
          message={intl.formatMessage({id: 'Are you sure you want to create this item?'})}
          title={intl.formatMessage({id: 'Attention'})}
          showModal={openModalConfirm}
          onClose={()=>setOpenModalConfirm(false)}
          onAction={()=>save(dataValues)}
        />
        <ModalMessage
          message={intl.formatMessage({id: 'Are you sure you want to edition this item?'})}
          title={intl.formatMessage({id: 'Attention'})}
          showModal={openModalConfirmEdition}
          onClose={()=>setOpenModalConfirmEdition(false)}
          onAction={()=>saveEdition(dataValuesEdition)}
        />
        <ModalMessage
          message={intl.formatMessage({id: 'Are you sure you want to delete this item?'})}
          title={intl.formatMessage({id: 'Attention'})}
          showModal={openModalDelete}
          onClose={()=>setOpenModalDelete(false)}
          onAction={handleDelete}
        />


        
        <div className='accordion-item w-95 mx-auto' >
          <span className=' accordion-header' id={`kt_accordion_1_header_${props.index}`} >
            <button
              
              className='accordion-button fs-12 fw-bold collapsed'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target={`#kt_accordion_1_body_${props.index}`}
              aria-expanded='false'
              aria-controls={`kt_accordion_1_body_${props.index}`}
              style={
                {
                  color: 'black',  backgroundColor: `${color}`
                  
                }}
            >
              <span >{props.action.title}</span>
            </button>
          </span> 
          <div
            id={`kt_accordion_1_body_${props.index}`}
            className='accordion-collapse collapse'
            aria-labelledby={`kt_accordion_1_header_${props.index}`}
            data-bs-parent={`#kt_accordion_${props.index}`}
            onMouseMove={()=>setIdActionPlan(props.action.id)}
          >
            <div
              
            className='accordion-body' style={{color: 'white', backgroundColor: (props.action.date_end >= moment().format('YYYY-MM-DD') ? '#F3FCE9':'#FCEFE9' )}}>
              
              { 
                props.action.date_end < moment().format('YYYY-MM-DD') ? <strong style={{color: 'red', backgroundColor: (props.action.date_end >= moment().format('YYYY-MM-DD') ? '#F3FCE9':'#FCEFE9' )}}>* {intl.formatMessage({id: 'Editing deadline ended'})}</strong> : null
              }

              

              <div className='row p-2'>
                <div className='col-sm-3 d-flex flex-column mb-3'>
                  <label className='col-lg-8 col-form-label fw-bolder fs-4 text-dark'>{intl.formatMessage({id: 'Initial Date'})}</label>
                  <span className='fw-bold text-gray-700 fs-5'>{moment(props.action.date_start).format('DD/MM/YYYY')}</span>
                </div>
                <div className='col-sm-3 d-flex flex-column mb-3'>
                  <label className='col-lg-7 col-form-label fw-bolder fs-4 text-dark'>{intl.formatMessage({id: 'Final Date'})}</label>
                  <span className='fw-bold text-gray-700 fs-5'>{moment(props.action.date_end).format('DD/MM/YYYY')}</span>
                </div>
                <div className='col-sm-3 d-flex flex-column mb-3'>
                  <label className='col-lg-6 col-form-label fw-bolder fs-4 text-dark'>{intl.formatMessage({id: 'Objective'})}</label>
                  <span className='fw-bold text-gray-700 fs-5'>{props.action.objective ? props.action.objective : '---'}</span>
                </div>
                
                {
                  props.action.objective === 'yes'?
                  <div className='col-sm-12 d-flex flex-column mb-3'>
                    <label className='col-lg-6 col-form-label fw-bolder fs-4 text-dark'>{intl.formatMessage({id: 'Operation difficulty level'})}</label>
                    <span className='fw-bold text-gray-700 fs-5'>{props.action.use_degree? props.action.use_degree : '---'}</span>
                  </div>
                  :
                  <div className='col-sm-12 d-flex flex-column mb-3'>
                    <label className='col-lg-6 col-form-label fw-bolder fs-4 text-dark'>{intl.formatMessage({id: 'Justification'})}</label>
                    <span className='fw-bold text-gray-700 fs-5'>{props.action.justification? props.action.justification : '---'}</span>
                  </div>
                }

              </div> 
              
              <div className='d-flex flex-row w-100 justify-content-end mb-3'>
                
                <Dropdown >
                  
                  <Dropdown.Toggle variant="secondary"   >
                    <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-3  ms-3' />
                    {/* <KTSVG path='/media/icons/duotune/general/gen053.svg' className='svg-icon-3  ms-3' /> */}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>

                    <Dropdown.Item title={intl.formatMessage({id: 'Resources'})} onMouseMove={()=>[setResourcesList(props.action)]} onClick={()=>[setOpenModalResources(true)]} >
                      {!isLoading && intl.formatMessage({id: 'Resources'})}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {intl.formatMessage({id: 'Processing'})}...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                    </Dropdown.Item>

                    <Dropdown.Item 
                      title={intl.formatMessage({id: 'Evidence'})}
                      onMouseMove={()=>[setResourcesList(props.action)]}
                      onClick={()=>[setShowEvidence(true)]} 
                    >

                      {!isLoading && intl.formatMessage({id: 'Evidence'})}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {intl.formatMessage({id: 'Processing'})}...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}

                    </Dropdown.Item>                    
                    
                    <Dropdown.Item title={intl.formatMessage({id: 'Monitoring'})} onMouseMove={()=>[setIdActionPlan(props.action.id)]} onClick={()=>[getMonitoringList()]} disabled={!['yes'].includes(props.action.objective)}>
                      {!isLoading && intl.formatMessage({id: 'Monitoring'})}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {intl.formatMessage({id: 'Processing'})}...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      {/* {intl.formatMessage({id: ''})} */}
                    </Dropdown.Item>
                    
                    <Dropdown.Item title={intl.formatMessage({id: 'Edit action'})} onClick={()=>setEdition(true)} disabled={props.action.date_end < moment().format('YYYY-MM-DD') || props.action.monitoring.length>0 && props.action.monitoring[0].status === 'no'}>
                      {intl.formatMessage({id: 'Edit'})}  
                    </Dropdown.Item>
                    
                    <Dropdown.Item title={intl.formatMessage({id: 'Delete action'})} onClick={()=>setOpenModalDelete(true)} disabled={props.action.date_end < moment().format('YYYY-MM-DD')}>
                      {intl.formatMessage({id: 'Delete'})}
                    </Dropdown.Item>

                  </Dropdown.Menu>

                </Dropdown>


              </div>

              

  
   
            
            </div>
          </div>
        </div>

        <ModalMessage
          message={intl.formatMessage({id: 'Are you sure you want to create this item?'})}
          title={intl.formatMessage({id: 'Attention'})}
          showModal={openModalConfirm}
          onClose={
            ()=>[setOpenModalConfirm(false), 
              setPageList(1), 

              ]}
          onAction={()=>[
            save(dataValues),
            
           
          ]}
        />
        <ModalMessage
          message={intl.formatMessage({id: 'Are you sure you want to edition this item?'})}
          title={intl.formatMessage({id: 'Attention'})}
          showModal={openModalConfirmEdition}
          onClose={()=>setOpenModalConfirmEdition(false)}
          onAction={()=>[
           
            saveEdition(dataValuesEdition)
            ]}
        />
        <Modal
          open={openModalMonitoring}
          onClose={()=>{}}
         
        >
          <Box sx={style}>
            <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
              <span className="modal-title fw-bold fs-3">
                {
                  tela === 1
                  ? `${intl.formatMessage({id: 'Monitoring'})}`
                  : tela === 2 
                  ? `${intl.formatMessage({id: 'Add New Monitoring'})}`
                  : `${intl.formatMessage({id: 'Edit Monitoring'})}`
                }
              </span>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary"
                onClick={()=>[setOpenModalMonitoring(false), setGetMonitoringFilter('')]}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
        {
          tela===1? 
         <>
          {
            load?
            <Load/>
            :
            
          <div className='p-4'>

          <div>
            <div 
            // onSubmit={formik.handleSubmit} 
            className='form align-items-center'>

              <div className="row">
                
                <div className="col-sm-12 ml-auto px-4 d-flex ">

                  <input
                    type="text"
                    className="form-control form-control-solid fs-6"
                    placeholder={intl.formatMessage({id: 'Type a date to search'})} 
                    autoComplete='off'
                    onChange={(e)=>[setGetMonitoringFilter(e.target.value)]}
                    
                    // {...formik.getFieldProps('title')}
                  />

                  <button type='button' className='btn mx-2 btn-primary text-dark' 
                    disabled={loading}
                    onClick={()=> [setIndex (index), getMonitoringList()]}
                    
                    
                  >
                  {!isLoading && intl.formatMessage({id: 'Ok'})}
                    {isLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  {/* {intl.formatMessage({id: ''})} */}
                  
                  </button>

                </div>





                <div className='card-footer d-flex justify-content-end py-6 px-9'>

              </div>

              
                
              </div>


            

              

            </div>
          </div>


        <div className="row">

  <div className="col" style={{overflow: 'scroll', overflowX: 'hidden', maxHeight: 250}}>
          
    {
      monitorings.data?.length === 0?
      <button 
      
      type='button' 
      onClick={()=>handleAddItem()} 
      className='btn btn-md w-100 btn-primary mb-3 text-dark' 
      disabled={isLoading} 
    >
     {intl.formatMessage({id: 'Add'})}
    </button>
    :
    <></>
    
    }
  {
  
    monitorings.data?.map((item:any, index:any) => (

    <div key={index}>

      <div role={'button'}
        style={(moment().format('YYYY-MM-DD') > item.date || index >0 || monitorings.current_page > 1) ? ({pointerEvents: 'none'}): ({pointerEvents: 'auto'})}
        onClick={()=>[
          setIndex(index), 
          setTela(3), 
          handleEdition()]} 
      
        className={`mb-5 w-100 alert text-dark bg-white border-2 border-dashed bg-hover-light-warning ${
          item.status==='yes'? ' border-success bg-light-success ' :
          item.status==='no'|| 
          (moment().format('YYYY-MM-DD') > item.date   && item.status ==='await' ) ? ' border-danger bg-light-danger ' :
          ' border-primary bg-light-primary '} `}
        
        >
      <div className='d-flex flex-row w-100 justify-content-between mb-3'>
        
        <div>
        <div className={`badge me-4 ${
            item.status==='yes'? ' border-dark  badge-success ' :
            item.status==='no' || 
            (moment().format('YYYY-MM-DD') > item.date   && item.status ==='await' )
            ? ' border-dark badge-danger ' :
            ' border-dark badge-primary '
        } fw-bold  text-gray-800` }>{ item.status}</div>
        

          <span className='fw-bold fs-6 text-gray-800'>{converteData2(item.date) }</span>
          
        </div>


      
      </div>
      {item.description ? 
      <div className='d-flex flex-column'>{item.description }</div>
      
      : null
      }

      { 
        item.status==='await' && item.date < moment().format('YYYY-MM-DD') ? <strong style={{color: 'red', backgroundColor: (item.date >= moment().format('YYYY-MM-DD') ? '#F3FCE9':'#FCEFE9' )}}>* {intl.formatMessage({id: 'Editing deadline ended'})}</strong> : null
      }
      

    </div>
    </div>

  ))
}
</div>

</div> <Pagination data={monitorings} onSearch={getMonitoringList} searchData={searchData}/>

<div className='d-flex flex-row w-100 justify-content-end p-5'>
{
      index >=1 ?
      <></>
      :
      <button 
      
      type='button' 
      onClick={()=>handleAddItem()} 
      className='btn btn-md btn-primary mb-3 text-dark' 
      disabled={enableAddDate}
      //  disabled={
      //   isLoading || 
      //   monitorings.data[0].status !='yes' || 
      //   (monitorings.data[0].date < moment().format('YYYY-MM-DD') && monitorings.data[0].status !='yes') ||
      //   monitorings.current_page > 1
                        
      // } 
      >
        {intl.formatMessage({id: 'Add'})}
      </button>
      
      // handleMonitoringController(monitorings.data[0])
        
    }
</div>



          </div>}
         </>
          :
          <div>
            {
              tela===2? 
                <>
                {
                  load ?
                  <Load/>
                  :
                  <div>
                  <div className='px-5' style={{overflow: 'scroll', overflowX: 'hidden', maxHeight: 250}}>
                    <form onSubmit={formik.handleSubmit} id='form-action-plan-action' noValidate className='form w-100'>
                      <div className='align-items-start w-100'>
                        <div className='row'>
                        <label className='col-lg-12 col-form-label fs-5 mt-auto'>
                        {intl.formatMessage({id: 'Pick a date for your return'})} 
                          </label>
                          <div className='col-sm-12 fv-row'>
                            
                            <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Date'})}</label>
                          
                            <DatePicker 
                              selected={date} 
                              dateFormat={'dd/MM/yyyy'}
                              minDate={moment().toDate()}
                              onChange={(date: Date) => setDate(date)} 
                              className='form-control form-control-md form-control-solid fs-4'
                              disabled={moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')}
                            />
                          
                          </div>

                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='d-flex flex-row w-100 justify-content-end p-5'>
                    <button type='button' onClick={()=>handleCancel()} title={intl.formatMessage({id: 'Cancel edition'})} form='form-action-plan-action' className='btn me-2 btn-sm btn-secondary' disabled={isLoading}>
                    {intl.formatMessage({id: 'Cancel'})}
                    </button>
                    <button type='submit' title={intl.formatMessage({id: 'Save changes'})} form='form-action-plan-action' className='btn btn-sm btn-primary text-dark' disabled={isLoading}>
                      {!isLoading && intl.formatMessage({id: 'Create'})}
                      {isLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          {intl.formatMessage({id: 'Processing'})}...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
                }
                </>
                :
                <div>
                  {
                    tela === 3?
                    <div>
                      <div className='px-5' style={{overflow: 'scroll', overflowX: 'hidden', maxHeight: 250}}>
                        {
                          load?
                          <Load/>
                          :
                          <form onSubmit={formikEdition.handleSubmit}  id='form-action-plan-action' noValidate className='form w-100'>
                            <div className='align-items-start w-100'>
                        
                                <div className='row'>

                                  <div className='col-sm-6 fv-row'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Date'})}</label>
                                    <DatePicker 
                                      name='date'
                                      selected={formikEdition.values.date}
                                      dateFormat={'dd/MM/yyyy'}
                                      minDate={moment().toDate()}
                                      onChange={(date: Date) => formikEdition.setFieldValue('date', date)} 
                                      className='form-control form-control-md form-control-solid fs-4'
                                      disabled={moment(formikEdition.values.date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')}
                                      
                                    />
                                  </div>

                                  <div className='col-sm-6 fv-row'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Status'})}</label>
                                    <select className='form-select form-select-solid form-select-lg fw-bold fs-4' 
                                      name='status'                              
                                      onChange={(e)=>setStatusEdition(e.target.value)}
                                      value={statusEdition}
                                                                                                      
                                      
                                    >
                                      <option value='' disabled>{intl.formatMessage({id: 'Select an status'})}</option>
                                      <option value="await">{intl.formatMessage({id: 'Await'})}</option>
                                      <option value='yes'>{intl.formatMessage({id: 'Yes'})}</option>
                                      <option value='no'>{intl.formatMessage({id: 'No'})}</option>
                                    </select>
                                    {formikEdition.touched.status && formikEdition.errors.status && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block text-danger fs-6 mt-1'>{formikEdition.errors.status}</div>
                                      </div>
                                    )}
                                  </div>

                                  <div className='col-lg-12 fv-row'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Description'})}</label>
                                    <input
                                      type='text'
                                      placeholder={intl.formatMessage({id: 'Description'})}
                                      autoComplete='off'
                                      name='description'
                                      onChange={(e)=>setDescriptionEdition(e.target.value)}
                                      value={descriptionEdition}
                                      className='form-control form-control-lg form-control-solid w-100 me-1'
                                    />
                                    {/* {formikEdition.touched.description && formikEdition.errors.description && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block text-danger fs-6 mt-1'>{formikEdition.errors.description}</div>
                                      </div>
                                    )} */}
                                  </div>

                                </div>
                          
                            </div>
                          </form>
                        }
                      </div>
                      <div className='d-flex flex-row w-100 justify-content-end p-5'>
                        <button type='button' onClick={()=>handleCancel()} title={intl.formatMessage({id: 'Cancel edition'})} form='form-action-plan-action' className='btn me-2 btn-sm btn-secondary' disabled={isLoading}>
                        {intl.formatMessage({id: 'Cancel'})}
                        </button>
                        <button type='submit' title={intl.formatMessage({id: 'Save changes'})} form='form-action-plan-action' className='btn btn-sm btn-primary text-dark' disabled={isLoading}>
                          {!isLoading && intl.formatMessage({id: 'Save'})}
                          {isLoading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                             {intl.formatMessage({id: 'Processing'})} ...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                    :
                    <></>
                    }
                  </div>
                }
              </div>
            }
        
          </Box>
        </Modal>

        <Modal
          open={openModalResources}
          onClose={()=>{}}
         
        >
          <Box sx={style}>
            <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
              <span className="modal-title fw-bold fs-3">
               
                 {intl.formatMessage({id: 'Resources'})}
               
              </span>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary"
                onClick={()=>[setOpenModalResources(false)]}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className='container' style={{maxHeight: 400, overflowY: 'auto'}}>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-150px'>{intl.formatMessage({id: 'TITLE'})}</th>
                  <th className='min-w-140px'>{intl.formatMessage({id: 'TYPE'})}</th>
                  <th className='min-w-120px text-center'>{intl.formatMessage({id: 'PRIVATE'})}</th>
                  <th className='min-w-100px text-center'>{intl.formatMessage({id: 'ACTIONS'})}</th>
                </tr>
              </thead>
              <tbody >

                {
                  resourcesList?.resources?.map((item: any, index: any) =>
                    <tr key={index}>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-gray-700 fw-bold fs-6'>
                              {item.title}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className=''>
                            <span className='text-gray-700 fw-bold fs-6'>
                              {
                                item.type === '1'
                                  ? 'URL'
                                  : item.type === '2'
                                    ? `${intl.formatMessage({id: 'Form'})}`
                                    : item.type === '3'
                                      ? `${intl.formatMessage({id: 'File'})}`
                                      : item.type === '4'
                                        ? `${intl.formatMessage({id: 'Video'})}`
                                        : item.type === '5'
                                          ? `${intl.formatMessage({id: 'User Term'})}`
                                          : ''
                              }
                            </span>

                            <span>{item.extension ? ' | ' + item.extension : ''}</span>

                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center justify-content-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-gray-700 fw-bold fs-6'>
                              {
                                item.free === 0
                                  ? `${intl.formatMessage({id: 'No'})}`
                                  : `${intl.formatMessage({id: 'Yes'})}`
                              }
                            </span>
                          </div>
                        </div>
                      </td>


                      <td>

                        {
                          item.type == 2 && item.form !== null ?
                          <button
                                  className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                  title={intl.formatMessage({id: 'Copy'})} 
                                  onClick={() => copyText(item.form, intl)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen028.svg'
                                    className='svg-icon-2x svg-icon-success'
                                  />
                          </button>
                          :
                          null
                        }

                        {
                          item.type == 1 && item.url !== null ?
                          <a
                            href={`${item.url}`}
                            target='_blank'
                            className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                            title='URL'
                          >
                            <KTSVG
                              path='/media/icons/duotune/coding/cod007.svg'
                              className='svg-icon-2 svg-icon-info'
                            />
                          </a>
                          :
                          null
                        }

                        {
                          ['3','4','5'].includes(item.type) ?
                          <>
                          
                            <button
                              onClick={() => handleGetResource(item.hash_name)}
                              className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                              title='View'
                            >
                              <KTSVG
                                path='/media/icons/duotune/files/fil024.svg'
                                className='svg-icon-2x svg-icon-info'
                              />
                            </button>
                          </>
                          :
                          null
                        }
                      </td>

                    </tr>
                  )
                }


              </tbody>
            </table>
            </div>
        
         
          {
            load?
            <Load/>
            :
            
          <div className='p-4'>

          


         <div className="row">

 

        </div>




          </div>
          }
        
        
          </Box>
        </Modal>

        <Evidences show={showEvidence} action={props.action} onClose={ ()=>{setShowEvidence(false)} }/>


      </div>
    }
  </>
  )
} 
export default ActionPlanActionList;