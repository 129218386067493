import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import { createUser, getResourceLink } from '../../../../../app/modules/helpers/Api';
import { mask } from '../../../../../app/modules/helpers/helpers';
import ModalMessageConfirm from '../../modals/modalMessageConfirm';
import { trataRespota } from '../../../../../app/modules/helpers/helpers';
import { KTSVG } from '../../../../helpers';
import { useIntl } from 'react-intl'
import {UserModel} from '../../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import { Load } from '../../spinner/Spinner';
import InputMask from "react-input-mask"

type Props = {
	create: (action: boolean) => void;
}

const NewUser: React.FC<Props> = ({...props}) => {

	const[loading, setLoading] = useState(false);
	const[openModalSubmit, setOpenModalSubmit] = useState(false);
	const[dataValues, setDataValues] = useState<any>({});
  const [seePassword, setSeePassword] = useState('password');
	const intl = useIntl();   
	const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

	const[document, setDocument] = useState('');
	const[errorDocument, setErrorDocument] = useState('');
	const[role, setRole] = useState('');
	const[errorRole, setErrorRole] = useState('');

	const schema = Yup.object().shape({
		name: Yup.string()
		.required(`${intl.formatMessage({id: 'Name'})} ${intl.formatMessage({id: 'is required'})}`),
		use_terms: Yup.string()
		.required(`${intl.formatMessage({id: 'acceptTerms'})} ${intl.formatMessage({id: 'is required'})}`),
		email_verified: Yup.string(),
		last_name: Yup.string()
		.required(`${intl.formatMessage({id: 'Last Name'})} ${intl.formatMessage({id: 'is required'})}`),
		email: Yup.string()
		.email(`${intl.formatMessage({id: 'The value is not a valid email address'})} `)
		.max(50, `${intl.formatMessage({id: 'Maximum 50 symbols'})}`)
		.required(`${intl.formatMessage({id: 'E-mail'})} ${intl.formatMessage({id: 'is required'})}`),
		cpf: Yup.string().min(14, `${intl.formatMessage({id: 'Minimum 14 symbols'})}`),		
		password: Yup.string()
			.min(3, `${intl.formatMessage({id: 'Minimum 6 symbols'})}`)
			.max(50, `${intl.formatMessage({id: 'Maximum 50 symbols'})}`)
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%^&*])(?=.{6,})/,
				`${intl.formatMessage({id: 'Use 6 or more characters with a mix of letters, numbers & symbols'})}`
			)
			.required(`${intl.formatMessage({id: 'Password'})} ${intl.formatMessage({id: 'is required'})}`),
		confirmPass: Yup.string()
			.required(`${intl.formatMessage({id: 'Password confirmation is required'})}`)
			.when('password', {
				is: (val: string) => (val && val.length > 0 ? true : false),
				then: Yup.string().oneOf([Yup.ref('password')], `${intl.formatMessage({id: "Password and Confirm Password didn't match"})}`),
			}),
		})

	const initialValues = {
    name: '',
		use_terms: false,
		email_verified: false,
		last_name: '',
		email: '',
		cpf: '',
		password: '',
		confirmPass: '',	
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      
			var error = false;
      
				if(role === ''){setErrorRole(`${intl.formatMessage({id: "Role"})} ${intl.formatMessage({id: "is required"})}`); error = true}
				// if(document === ''){setErrorDocument('Document is required'); error = true}
				if(!error){
					setDataValues(values);
					setOpenModalSubmit(true);
        	setLoading(false);
				}
        setLoading(false);
      
    },
  });
  function handleGetResource(hash: any) {
		getResourceLink(hash);
  }

	function addMask(e: any){
    setDocument(mask(e));
  }

	function save(values: any) {
		const data = {
			name: values.name,
			last_name: values.last_name,
			use_terms: values.use_terms,
			email_verified_at: values.email_verified,
			email: values.email,
			document: document,
			password: values.password,
			cpf: values.cpf,
			confirm_password: values.confirmPass,
			user_role: role
		}
		setLoading(true);
		const save = createUser(data);
		save.then((res)=>{
			if(res.data.status === 'success'){
				toast.success(res.data.message);
				props.create(false);
			}
			if(res.data.status === 'error'){
				toast.error(res.data.message);
			}
		}).catch((error)=>{
			trataRespota('error', error); 

			// if(error.reponse){
			// 	toast.error(error.response.message)
			// } else {
			// 	toast.error('Sorry, an error occurred!');	
			// }
		}).finally(()=>{
			setLoading(false);
			setOpenModalSubmit(false);
		})
	
	}
	

	return(
		<div>
		{
			loading?
        <Load/>
      :
			<div className='card mb-5 mb-xl-10'>
					<ModalMessageConfirm
						message={intl.formatMessage({id: 'Are you sure you want to create this item?'})}
						title={intl.formatMessage({id: 'Attention'})}
						showModal={openModalSubmit}
						onClose={()=>setOpenModalSubmit(false)}
						onAction={()=>save(dataValues)}
					/>
					<div className='collapse show'>
						<form onSubmit={formik.handleSubmit} noValidate className='form'>
							<div className='card-body border-top px-5 py-5'>
								<div className='d-flex justify-content-between align-items-center mb-3'>
									<label className='form-label fw-bolder text-dark fs-3 '>{intl.formatMessage({id: 'New User'})}</label>
									<button onClick={()=>props.create(false)} type='button' className='btn btn-sm btn-secondary p-3'>
										<KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark'/>
										{intl.formatMessage({id: 'Back'})}
									</button>
									
								</div>
								<div className='mb-2 fv-row border-top pt-3' data-kt-password-meter='true'>
									
									<div className='col-lg-12'>
										<div className='row'>
											<div className='col-lg-6 fv-row mb-3'>
												<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Name'})}</label>
												<div className='position-relative mb-3'>
													<div className='d-flex justify-content-between w-100'>
														<input
															type='text'
															placeholder={intl.formatMessage({id: 'Name'})}
															autoComplete='off'
															{...formik.getFieldProps('name')}
															className='form-control form-control-lg form-control-solid w-100 me-1'
														/>
													</div>
													{formik.touched.name && formik.errors.name && (
														<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
															<div className='fv-help-block'>
																<span role='alert'>{formik.errors.name}</span>
															</div>
														</div>
													)}
												</div>
											</div>
											
											<div className='col-lg-6 fv-row mb-3'>
												<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Last Name'})}</label>
												<div className='position-relative mb-3'>
													<div className='d-flex justify-content-between w-100'>
														<input
															type='text'
															placeholder={intl.formatMessage({id: 'Last Name'})}
															autoComplete='off'
															{...formik.getFieldProps('last_name')}
															className='form-control form-control-lg form-control-solid w-100 me-1'
														/>
													</div>
													{formik.touched.last_name && formik.errors.last_name && (
														<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
															<div className='fv-help-block'>
																<span role='alert'>{formik.errors.last_name}</span>
															</div>
														</div>
													)}
												</div>
											</div>

											<div className='col-lg-6 fv-row mb-3'>
												<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'E-mail'})}</label>
												<div className='position-relative mb-3'>
													<div className='d-flex justify-content-between w-100'>
														<input
															type='text'
															placeholder={intl.formatMessage({id: 'E-mail'})}
															autoComplete='off'
															{...formik.getFieldProps('email')}
															className='form-control form-control-lg form-control-solid w-100 me-1'
														/>
													</div>
													{formik.touched.email && formik.errors.email && (
														<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
															<div className='fv-help-block'>
																<span role='alert'>{formik.errors.email}</span>
															</div>
														</div>
													)}
												</div>
											</div>

											<div className='col-lg-6 fv-row mb-3'>
												<label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'CPF'})}</label>
												<div className='position-relative mb-3'>
													<div className='d-flex justify-content-between w-100'>
														
														<InputMask
                              mask="999.999.999-99"
                              type="text"
                              className="form-control form-control-solid fs-6"
                              placeholder="xxx.xxx.xxx-xx"
                              name='cpf'
                              onChange={ (e) => formik.setFieldValue('cpf', e.target.value)}
                              value={formik.getFieldProps('cpf').value}
                            />	
														
													</div>
													{formik.touched.cpf && formik.errors.cpf && (
														<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
															<div className='fv-help-block'>
																<span role='alert'>{formik.errors.cpf}</span>
															</div>
														</div>
													)}
												</div>
											</div>

											<div className='col-lg-6 fv-row mb-3'>
												<label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'CNPJ'})}</label>
												<div className='position-relative mb-3'>
													<div className='d-flex justify-content-between w-100'>
														<input
															placeholder='CNPJ'
															type='text'
															autoComplete='off'
															name='document'
															maxLength={18}
															onChange={e=>[addMask(e.currentTarget.value), setErrorDocument('')]}                
															value={document}
															className={clsx(
															'form-control form-control-solid fs-5',
															{'is-invalid': errorDocument},
															{
																'is-valid': document.length >= 14,
															}
														)}
														/>
													</div>
													{errorDocument && (
														<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
															<div className='fv-help-block'>
																<span role='alert'>{errorDocument}</span>
															</div>
														</div>
													)}
												</div>
											</div>

											<div className='fv-row mt-4 mb-10'>
												<label className='form-label fw-bold text-dark fs-5 required'>{intl.formatMessage({id: 'Role'})}</label>
												<div className='mt-3'>
													{
														user.roles === 'super-admin' ?
														<label className='form-check form-check-custom form-check-solid align-items-center'>
															<input
																type='radio'
																name='role'
																className='form-check-input me-3'
																onChange={()=>[setRole('admin'), setErrorRole('')]}
															/>
															<span className='form-check-label d-flex flex-column align-items-start'>
																<span className='fw-bolder text-gray-800 fs-6 mb-0'>{intl.formatMessage({id: 'Admin'})}</span>
																<span className='text-gray-600 fs-6 text-justify'>
																{intl.formatMessage({id: 'User Administrator'})}
																</span>
															</span>
														</label>
														:
														''
													}

													<div className='separator separator-dashed my-6'></div>

													<label className='form-check form-check-custom form-check-solid align-items-center'>
														<input
															type='radio'
															name='role'
															className='form-check-input me-3'
															onChange={()=>[setRole('free'), setErrorRole('')]}
														/>
														<span className='form-check-label d-flex flex-column align-items-start'>
															<span className='fw-bolder text-gray-800 fs-6 mb-0'>{intl.formatMessage({id: 'Grátis'})}</span>
															<span className='text-gray-600 fs-6 text-justify'>
															{intl.formatMessage({id: 'User Free'})}
															</span>
														</span>
													</label>

													<div className='separator separator-dashed my-6'></div>

													<label className='form-check form-check-custom form-check-solid align-items-center'>
														<input
															type='radio'
															name='role'
															className='form-check-input me-3'
															onChange={()=>[setRole('support'), setErrorRole('')]}
														/>
														<span className='form-check-label d-flex flex-column align-items-start'>
															<span className='fw-bolder text-gray-800 fs-6 mb-0'>{intl.formatMessage({id: 'Support'})}</span>
															<span className='text-gray-600 fs-6 text-justify'>
															{intl.formatMessage({id: 'User Support'})}
															</span>
														</span>
													</label>

													

													<div className='separator separator-dashed my-6'></div>
												</div>
												
												{/* <div className='mt-3'>
													<label className='form-check form-check-custom form-check-solid align-items-center'>
														<input
															type='radio'
															name='role'
															className='form-check-input me-3'
															onChange={()=>[setRole('company'), setErrorRole('')]}
														/>
														<span className='form-check-label d-flex flex-column align-items-start'>
															<span className='fw-bolder text-gray-800 fs-6 mb-0'>Company</span>
															<span className='text-gray-600 fs-6 text-justify'>
																An organization composed of people whose objective is to carry out an economic activity seeking commercial results through the production and/or sale of goods and services.
															</span>
														</span>
													</label>
													<div className='separator separator-dashed my-6'></div>
												</div>
												<div className='mt-3'>
													<label className='form-check form-check-custom form-check-solid align-items-center'>
														<input
															type='radio'
															name='role'
															className='form-check-input me-3'
															onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
														/>
														<span className='form-check-label d-flex flex-column align-items-start'>
															<span className='fw-bolder text-gray-800 fs-6 mb-0'>DPO</span>
															<span className='text-gray-600 fs-6 text-justify'>
																In charge of processing personal data of a company, and that does not fit the company's staff.
															</span>
														</span>
													</label>
													<div className='separator separator-dashed my-6'></div>
												</div>
												<div className='mt-3'>
													<label className='form-check form-check-custom form-check-solid align-items-center'>
														<input
															type='radio'
															name='role'
															className='form-check-input me-3'
															onChange={()=>[setRole('consultant'), setErrorRole('')]}
														/>
														<span className='form-check-label d-flex flex-column align-items-start'>
															<span className='fw-bolder text-gray-800 fs-6 mb-0'>Consultant</span>
															<span className='text-gray-600 fs-6 text-justify'>
																Best for people primarily using the API.
															</span>
														</span>
													</label>
													<div className='separator separator-dashed mt-6 mb-1'></div>
												</div>
												<div className='mt-3'>
													<label className='form-check form-check-custom form-check-solid align-items-center'>
														<input
															type='radio'
															name='role'
															className='form-check-input me-3'
															onChange={()=>[setRole('freelancer'), setErrorRole('')]}
														/>
														<span className='form-check-label d-flex flex-column align-items-start'>
															<span className='fw-bolder text-gray-800 fs-6 mb-0'>Freelancer</span>
															<span className='text-gray-600 fs-6 text-justify'>
																They are professionals who carry out their activity on their own without an employment relationship with anyone, but they must be registered with a professional council body.
															</span>
														</span>
													</label>
												</div> */}

												{errorRole && (
													<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
														<div className='fv-help-block'>
															<span role='alert'>{errorRole}</span>
														</div>
													</div>
												)}
											</div>

											<div className='col-lg-6 fv-row mb-3'>
												<label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'New Password'})}</label>
												<div className='position-relative mb-3'>
													<div className='d-flex justify-content-between w-100'>
														<input
															type={seePassword}
															placeholder={intl.formatMessage({id: 'New Password'})}
															autoComplete='off'
															{...formik.getFieldProps('password')}
															className={clsx(
																'form-control form-control-lg form-control-solid w-90 me-1',
																{
																	'is-invalid': formik.touched.password && formik.errors.password,
																},
																{
																	'is-valid': formik.touched.password && !formik.errors.password,
																}
															)}
														/>
														{
															seePassword === 'password'?
															<span onClick={()=>setSeePassword('text')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
																<i className="bi bi-eye-slash-fill fs-2x"></i>
															</span>
															:
															<span onClick={()=>setSeePassword('password')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
																<i className="bi bi-eye-fill fs-2x"></i>
															</span>
														}
													</div>
													{formik.touched.password && formik.errors.password && (
														<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
															<div className='fv-help-block'>
																<span role='alert'>{formik.errors.password}</span>
															</div>
														</div>
													)}
												</div>
											</div>

											<div className='col-lg-6 fv-row mb-3'>
												<label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Confirm Password'})}</label>
												<input
													type={seePassword}
													placeholder={intl.formatMessage({id: 'Confirm Password'})}
													autoComplete='off'
													{...formik.getFieldProps('confirmPass')}
													className={clsx(
														'form-control form-control-lg form-control-solid',
														{
															'is-invalid': formik.touched.confirmPass && formik.errors.confirmPass,
														},
														{
															'is-valid': formik.touched.confirmPass && !formik.errors.confirmPass,
														}
													)}
												/>
												{formik.touched.confirmPass && formik.errors.confirmPass && (
													<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
														<div className='fv-help-block'>
															<span role='alert'>{formik.errors.confirmPass}</span>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								<div className='fv-row mb-8'>
									<div className='form-check form-check-custom form-check-solid'>
										<input
											className='form-check-input'
											type='checkbox'
											id='kt_login_toc_agree'
											{...formik.getFieldProps('use_terms')}
										/>
										<label
											className='form-check-label fw-bold text-gray-700 fs-6'
											htmlFor='kt_login_toc_agree'
							
										>
											{intl.formatMessage({id: 'I Agree with the'})}{' '}
											<span className='ms-1 link-primary' onClick={()=> [handleGetResource('user_term')]}>{intl.formatMessage({id: 'terms and conditions.'})}</span>
																					
											{/* <Link to='/auth/file/show?name=use_terms' className='ms-1 link-primary'>
												terms and conditions
											</Link> */}
											
										</label>
										{formik.touched.use_terms && formik.errors.use_terms && (
											<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
												<div className='fv-help-block'>
													<span role='alert'>{formik.errors.use_terms}</span>
												</div>
											</div>
										)}
									</div>
								</div>















								<div className='fv-row mb-10'>
									<div className='form-check form-check-custom form-check-solid'>
										<input
											className='form-check-input'
											type='checkbox'
											id='kt_login_toc_agree'
											{...formik.getFieldProps('email_verified')}
										/>
										<label
											className='form-check-label fw-bold text-gray-700 fs-6'
											htmlFor='kt_login_toc_agree'							
										>
											{intl.formatMessage({id: 'Verified E-mail'})}
										</label>
										{formik.touched.email_verified && formik.errors.email_verified && (
											<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
												<div className='fv-help-block'>
													<span role='alert'>{formik.errors.email_verified}</span>
												</div>
											</div>
										)}
									</div>
								</div>

								
							</div>
							<div className='card-footer d-flex justify-content-end py-6 px-9'>
								<button type='submit' className='btn btn-primary text-dark' disabled={loading }>
									{!loading && intl.formatMessage({id: 'Save'})}
									{loading && (
										<span className='indicator-progress' style={{display: 'block'}}>
											{intl.formatMessage({id: 'Processing'})}...{' '}
											<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
										</span>
									)}
								</button>
							</div>
						</form>
					</div>
			</div>
		}
		</div>
	)
}
export default NewUser;