import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import pareto from 'highcharts/modules/pareto';
import ReactApexChart from 'react-apexcharts'
import { useIntl } from 'react-intl'


pareto(Highcharts);

interface IReportClassificationProps {
	dataReport: any,
	degree: any
}

const ReportClassificaiton: React.FC<IReportClassificationProps> = ({...props}) => {
	const intl = useIntl();   

	const donutOptions: any = {
		chart: {
			type: 'donut',
		},
		labels: [`${intl.formatMessage({id: 'High'})}` , `${intl.formatMessage({id: 'Low'})}`, `${intl.formatMessage({id: 'Most High'})}`, `${intl.formatMessage({id: 'Medium'})}`],
		// labels: ['Short', 'Medium', 'High', 'Most High'],
		colors:['#f1bc00', '#5014d0', '#d9214e', '#0095e8'],
		legend: {
			position: 'bottom',
			fontSize: '14px'
		},
		responsive: [{
			breakpoint: 1024,
			options: {
				chart: {
					width: 400
				},
			}
		}],
		plotOptions: {
			pie: {
				donut: {
					size: '40%',
					customScale: 0.1,
					labels: {
						show: true,
						name: {
							show: true
						},
						value: {
							show: true
						},
					}
				}
			}
		}
	}
	
	const paretoOptions = {
    chart: {
      renderTo: 'container',
      type: 'column'
    },
    title: {
      text: ''
    },
    tooltip: {
      shared: true
    },
    xAxis: {
      // categories: [
      //   'Short', 
      //   'Medium', 
      //   'High', 
      //   'Most High'
      // ],
			categories: [`${intl.formatMessage({id: 'High'})}` , `${intl.formatMessage({id: 'Low'})}`, `${intl.formatMessage({id: 'Most High'})}`, `${intl.formatMessage({id: 'Medium'})}`],
      crosshair: true,
			colors:['#f1bc00', '#d9214e', '#5014d0','#0095e8'],
    },
    yAxis: [{
      title: {
        text: ''
      }
    }, {
      title: {
        text: ''
      },
      minPadding: 0,
      maxPadding: 0,
      max: 100,
      min: 0,
      opposite: true,
      labels: {
        format: "{value}%"
      }
    }],
    series: [{
      type: 'pareto',
      name: 'Pareto',
      yAxis: 1,
      zIndex: 10,
      baseSeries: 1,
      tooltip: {
        valueDecimals: 2,
        valueSuffix: '%'
      }
    }, {
      name: `${intl.formatMessage({id: 'Value'})}`,
      type: 'column',
      zIndex: 2,
      data: props.degree
    }]
	}

  return(
    <>
			<div className='d-flex flex-column border-dashed border-1 p-3 border-primary rounded'>
				<p className='text-gray-600 text-center fw-bold fs-3'>{intl.formatMessage({id: 'Classification'})}</p>
				<div className='d-flex flex-lg-row flex-xxxm-column align-items-xxm-center'>
					
					<ReactApexChart width={'350px'} options={donutOptions} series={props.degree} type='donut'/>
					
					<div className='p-xxm-4 p-lg-0 w-100 d-flex flex-column rounded align-items-center justify-content-center mt-xxm-10 mt-lg-0'>
						{/* <span className='text-dark fw-bolder fs-2'>{degree.reduce((accumulator: any, curr: any) => accumulator + curr)}</span> */}
						<span className='text-dark fw-bolder fs-2'>{props.dataReport.points}</span>
						<span className='text-gray-600 fw-bold fs-3'>{intl.formatMessage({id: 'Total'})}</span>
					</div>

				</div>
			</div>

			<div className='d-flex flex-column border-dashed border-1 p-3 mt-3 border-primary rounded'>
				<p className='text-gray-600 text-center fw-bold fs-3'>{intl.formatMessage({id: 'Pareto'})}</p>
				<HighchartsReact
					highcharts={Highcharts}
					options={paretoOptions}
				/>
			</div>	
		</>
  )
}
export default ReportClassificaiton;