import React, { useState } from 'react';
import { useIntl } from 'react-intl'

interface IReportPoints {
	dataReport: any,
}
  

const ReportPoints: React.FC<IReportPoints> = ({...props}) => {
	const intl = useIntl();   
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<any>([]);


	return(
    <>
			<div className='d-flex flex-column'>
				{
					<>
						{
							props.dataReport.model === 2?
							<>
							<div className='w-100 d-flex flex-column rounded align-items-center border-dashed border-1 p-3 border-primary'>
								<span className='text-dark fw-bolder fs-2'>{parseInt(props.dataReport.points)}</span>
								<span className='text-gray-600 fw-bold fs-3'>{intl.formatMessage({id: 'Points'})}</span>
							</div>
							</>
							:
							<>
								<div className='w-100 d-flex flex-column rounded align-items-center border-dashed border-1 p-3 border-primary'>
									<span className='text-dark fw-bolder fs-2'>{parseInt(props.dataReport.points)}</span>
									<span className='text-gray-600 fw-bold fs-3'>{intl.formatMessage({id: 'Points'})}</span>
								</div>
								<div className='d-flex flex-lg-row flex-sm-column my-2'>
								<div className='w-100 me-2 d-flex flex-column rounded align-items-center border-dashed border-1 p-3 border-primary'>
									<span className='text-dark fw-bolder fs-2'>{parseInt(props.dataReport.gaps)}</span>
									<span className='text-gray-600 fw-bold fs-3'>{intl.formatMessage({id: 'Gaps'})}</span>

									{/* <div className='text-start w-100'>
										<div>
											<span className='text-gray-600 fw-bold fs-6'>{intl.formatMessage({id: 'Actions'})}</span>
										</div> 
										<div className=''>
											<ul>
												<li>
													teste
												</li>
											</ul>
										</div>
									</div> */}
								</div>
								
								
								{
									props.dataReport.degree_risk !== null?
									<div className='w-100 d-flex flex-column rounded align-items-center border-dashed border-1 p-3 mt-sm-2 mt-lg-0 border-primary'>
										<span className='text-dark fw-bolder fs-2'>{parseInt(props.dataReport.degree_risk)}</span>
										<span className='text-gray-600 fw-bold fs-3'>{intl.formatMessage({id: 'Risk Degree'})} </span>
									</div>
									:
									<></>
								}
								</div>
							</>
						}
					</>
				}
			</div>
		</>
  )
}
export default ReportPoints;