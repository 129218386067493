/* eslint-disable jsx-a11y/anchor-is-valid */
// import { userInfo } from 'os';
import React, { FC, useEffect, useState } from 'react'
import { getActionsById, downResource, getResourceLink, delResources } from '../../../../app/modules/helpers/Api';
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl'
import { converteData, handleDownload, copyText } from '../../../../app/modules/helpers/helpers';

import CustomVideoPlayer from '../../../helpers/components/CustomVideoPlayer/CustomVideoPlayer'
import { Load } from '../spinner/Spinner';

type Props = {
  id: any;
  change: (action: boolean, id?: any) => void;
}

const DetailAction: FC<Props> = ({ ...props }) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const intl = useIntl();

  useEffect(() => {
    setLoading(true);
    const get = getActionsById(props.id);
    get.then((res) => {
      setData(res.data.data);
    }).catch((error) => alert(error))
      .finally(() => setLoading(false));
  }, [props.id])

  // function downloadResource(id: any, name: any, extension: any) {
  //   const get = downResource(id);
  //   get.then((res) => {
  //     if (res.statusText === 'OK') {
  //       toast.success(`${intl.formatMessage({ id: 'resource downloaded successfully!' })}`);
  //       handleDownload(res, name, extension);
  //     } else {
  //       toast.error(`${intl.formatMessage({ id: 'Sorry, an error occurred' })}`);
  //     }
  //   }).catch((error) => {
  //     if (error.response) {
  //       toast.error(`${intl.formatMessage({ id: 'Action cannot be performed at this time' })}`);
  //     } else {
  //       toast.error(`${intl.formatMessage({ id: 'Sorry, an error occurred' })}`);
  //     }
  //   })
  // }

  function removeResource(idResource:any){

    const del = delResources(idResource);
    del.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        // setOpenModalDelete(false);
        // setLoadingScreen(true);
        props.change(false)
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      toast.error(error.response.data.message);
    })
  }

  
  function handleGetResource(hash: any) {
    const get = getResourceLink(hash);
    // get.then((res) => {

    //   let myBlob = new Blob([res.data], {
    //     type: "application/pdf",
    //   });

    //   const fileURL = URL.createObjectURL(myBlob);
    //   window.open(fileURL);
    // })
  }

  return (
    <>
      <div className='d-flex justify-content-between'>
        
        <div className=''>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({ id: 'Action' })}: <span className='text-gray-600'>{data?.title}</span></span>
          </h3>
          <p className='text-gray-500 fw-bolder'>{intl.formatMessage({ id: 'Action detail' })}</p>
        </div>
        <button
          style={{ height: '40px'  }}
          onClick={() => props.change(false)} type='button' className='btn btn-sm btn-secondary p-3 m-1 w-10'>
          <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark' />
          {intl.formatMessage({ id: 'Back' })}
        </button>
      </div>
      {
        loading ?
          <><Load /></>
          :

          <>

            <div className='card mb-5 mb-xl-10'>
              <div className='collapse show'>

                <div className='card-body '>
                  <div className='mx-4 align-items-center'>
                    <div className='col-lg-12'>
                      <div className='row'>
                        <div className='col-lg-12 d-flex flex-column mb-3'>
                          <label className='col-form-label fw-bold fs-5'>{intl.formatMessage({ id: 'Title' })}</label>
                          <span className='fw-bold text-gray-700 fs-6'>{data.title}</span>
                        </div>
                        <div className='col-lg-12 d-flex flex-column mb-3'>
                          <label className='col-form-label fw-bold fs-5'>{intl.formatMessage({ id: 'Model' })}</label>
                          <span className='fw-bold text-gray-700 fs-6'>
                            {
                              data.model === 1 ? `${intl.formatMessage({ id: 'Maturity analysis' })}` :
                                data.model === 2 ? `${intl.formatMessage({ id: 'Scored' })}` :
                                  data.model === 3 ? `${intl.formatMessage({ id: 'Not scored' })}` :
                                    data.model === 4 ? `${intl.formatMessage({ id: 'Data Mapping' })}` :
                                      `${intl.formatMessage({ id: 'Unknown' })}`

                            }
                          </span>
                        </div>
                        {
                          user.roles === 'super-admin' ?
                            <>
                              <div className='col-lg-4 d-flex flex-column mb-3'>
                                <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({ id: 'Min Degree' })}</label>
                                <span className='fw-bold text-gray-700 fs-6'>{data.degree_min}</span>
                              </div>
                              <div className='col-lg-4 d-flex flex-column mb-3'>
                                <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({ id: 'Max Degree' })}</label>
                                <span className='fw-bold text-gray-700 fs-6'>{data.degree_max}</span>
                              </div>
                            </>
                            :
                            <></>
                        }
                        <div className='col-lg-4 d-flex flex-column mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({ id: 'Label' })}</label>
                          <span style={{ backgroundColor: `${data.color}`, color: '#fff', width: 20, borderRadius: 4 }} className='fw-bold text-center fs-6'>
                            {data.action_legend_id}
                          </span>
                        </div>

                      </div>

                    </div>
                  </div>


                </div>


              </div>
            </div>

            <div className='card mb-5 mb-xl-10'>

              <div className='d-flex flex-row justify-content-between aling-items-center'>
                <label className='col-form-label fw-bold fs-3 mx-5 my-3'>{intl.formatMessage({ id: 'Resources' })}</label>
              </div>

              <div className='card-body'>
                <div className="row">
                  <div className="col">

                    {
                      data.resources ?

                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0'>
                          <thead>
                            <tr className='fw-bolder text-muted'>
                            <th className='w-100px text-center'>{intl.formatMessage({ id: 'ACTIONS' })}</th>
                              <th className='min-w-150px'>{intl.formatMessage({ id: 'TITLE' })}</th>
                              <th className='min-w-140px'>{intl.formatMessage({ id: 'TYPE' })}</th>
                              <th className='min-w-120px text-center'>{intl.formatMessage({ id: 'PRIVATE' })}</th>
                              
                            </tr>
                          </thead>
                          <tbody>

                            {
                              data.resources.map((item: any, index: any) =>
                                <tr key={index}>

                                  <td>

                                  {
                                    item.type == 2 && item.form !== null ?
                                      <button
                                        className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                        title={intl.formatMessage({ id: 'Copy' })}
                                        onClick={() => copyText(item.form, intl)}
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen028.svg'
                                          className='svg-icon-2x svg-icon-success'
                                        />
                                      </button>
                                      :
                                      null
                                  }

                                  {
                                    item.type == 1 && item.url !== null ?
                                      <a
                                        href={`${item.url}`}
                                        target='_blank'
                                        className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                        title='URL'
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/coding/cod007.svg'
                                          className='svg-icon-2 svg-icon-info'
                                        />
                                      </a>
                                      :
                                      null
                                  }

                                  {
                                    ['3', '4', '5'].includes(item.type) ?
                                      <>
                                        {/* <button
                                            className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                            title='Download'
                                            onClick={() => downloadResource(item.id, item.original_name, item.extension)}
                                          >
                                            <KTSVG
                                              path='/media/icons/duotune/files/fil021.svg'
                                              className='svg-icon-2x svg-icon-primary'
                                            />
                                          </button> */}
                                        <button
                                          onClick={() => handleGetResource(item.hash_name)}
                                          className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                          title='View'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/files/fil024.svg'
                                            className='svg-icon-2x svg-icon-info'
                                          />
                                        </button>
                                      </>
                                      :
                                      null
                                  }


                                <button
                                  className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm'
                                  onClick={()=>[removeResource(item.id)]}
                                  title={`${intl.formatMessage({id: 'Delete resource'})}`}
                                >
                              <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _trash_.svg')} style={{marginRight: '10px'}} />
                                </button>
                               

                                  {/* {
                                        item.url !== null ?
                                        <a
                                          href={`${item.url}`}
                                          target='_blank'
                                          className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                          title='URL'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/coding/cod007.svg'
                                            className='svg-icon-2 svg-icon-info'
                                          />
                                        </a>
                                        :
                                        null
                                      }

                                      {
                                        item.url !== null ?
                                          <a
                                            href={`${item.url}`}
                                            target='_blank'
                                            className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                            title='URL'
                                          >
                                            <KTSVG
                                              path='/media/icons/duotune/coding/cod007.svg'
                                              className='svg-icon-2 svg-icon-info'
                                            />
                                          </a>
                                          : 
                                          <>
                                            <button
                                              className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                              title='Download'
                                              onClick={() => downloadResource(item.id, item.original_name, item.extension)}
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/files/fil021.svg'
                                                className='svg-icon-2x svg-icon-primary'
                                              />
                                            </button>
                                            <button
                                              onClick={() => handleGetResource(item.hash_name)}
                                              className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                              title='View'
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/files/fil024.svg'
                                                className='svg-icon-2x svg-icon-info'
                                              />
                                            </button>
                                          </>
                                      } */}


                                  </td>

                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <span className='text-gray-700 fw-bold fs-6'>
                                          {item.title}
                                        </span>
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className=''>
                                        <span className='text-gray-700 fw-bold fs-6'>
                                          {
                                            item.type === '1'
                                              ? 'URL'
                                              : item.type === '2'
                                                ? `${intl.formatMessage({ id: 'Form' })}`
                                                : item.type === '3'
                                                  ? `${intl.formatMessage({ id: 'File' })}`
                                                  : item.type === '4'
                                                    ? `${intl.formatMessage({ id: 'Video' })}`
                                                    : item.type === '5'
                                                      ? `${intl.formatMessage({ id: 'User Term' })}`
                                                      : ''
                                          }
                                        </span>

                                        <span>{item.extension ? ' | ' + item.extension : ''}</span>

                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <div className='d-flex align-items-center justify-content-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <span className='text-gray-700 fw-bold fs-6'>
                                          {
                                            item.free === 0
                                              ? `${intl.formatMessage({ id: 'No' })}`
                                              : `${intl.formatMessage({ id: 'Yes' })}`
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </td>

                            

                                </tr>
                              )
                            }


                          </tbody>
                        </table>
                        :
                        null
                    }

                  </div>
                </div>
              </div>


            </div>

            <div className='card mb-5 mb-xl-10'>

              <div className='d-flex flex-row justify-content-between aling-items-center'>
                <label className='col-form-label fw-bold fs-3 mx-5 my-3'>{intl.formatMessage({ id: 'Gaps' })}</label>
              </div>

              <div className='card-body'>
                <div className='row'>

                  <div className="col">

                    {
                      data && data.gaps && data.gaps.length > 0 ?

                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0'>
                          <thead>
                            <tr className='fw-bolder text-muted'>
                              <th className='min-w-150px'>{intl.formatMessage({ id: 'TITLE' })}</th>
                            </tr>
                          </thead>
                          <tbody>

                            {
                              data.gaps.map((item: any, index: any) =>
                                <tr key={index}>

                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <span className='text-gray-700 fw-bold fs-6'>
                                          {item.gap} 
                                        </span>
                                      </div>
                                    </div>
                                  </td>

                                </tr>
                              )
                            }



                          </tbody>
                        </table>
                        : <div>Não Possui {intl.formatMessage({ id: 'Gaps' })}</div>
                    }

                  </div>

                </div>


              </div>


            </div>

          </>
      }
    </>
  )
}

export { DetailAction }
