/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ModalMessage from '../../modals/modalMessage';
import Pagination from '../../../../helpers/components/CustomPagination/CustomPagination'
import moment from 'moment';
import StoreBuyForm from '../../../widgets/pages/Store/StoreBuyForm';
import { toast } from 'react-toastify';
import { cancelPayment, retryPayment, checkPayment, changeRecurrentItem, defaultPaginationList, getPaymentsById } from '../../../../../app/modules/helpers/Api';
import { converteData, converteData2 } from '../../../../../app/modules/helpers/helpers';
import { KTSVG } from '../../../../helpers';
import { Load } from '../../spinner/Spinner';
import { useIntl } from 'react-intl'
import { UserModel } from '../../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'

let searchData = {
  title: '',
  code: '',
  page: 1,
  perpage: 10
}

const style = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: 400,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

const TablesPayments: React.FC = () => {

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [id, setId] = useState('');
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [openModalRetry, setOpenModalRetry] = useState(false);
  const [openModalCheck, setOpenModalCheck] = useState(false);
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [dataError, setDataError] = useState<any>([]);
  const [renewProductData, setRenewProductData] = useState<any>({});
  const [openStoreBuyForm, setOpenStoreBuyForm] = useState(false); 
  const [itemCollapse, setItemCollapse] = useState('');
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const intl = useIntl();

  useEffect(()=>{
    
    handleGetList(null)

    const interval = setInterval(() => {
      handleGetList(null)
    }, (1000*60)*2 );
    return () => clearInterval(interval);


  },[loading2]);

  function getPaymentsId(id:any){
    setLoading(true);
    const get = getPaymentsById(id);
    get.then((res) => {

      setDataError( JSON.parse(res.data.data.data[0].invoice_log) );

    }).catch((error) => alert(error))
      .finally(
        () => 
        [setLoading(false),
          setOpenModalDetails(true)
        ]
      );
  }
  function handleGetList(data:null) {
    setLoading(true);
    const get = defaultPaginationList('products/sold', searchData)
    get.then((res)=>{
      setData(res.data.data);

    }).catch((error)=>alert(error))
    .finally(()=>[setLoading(false), setLoading2]);
  }
  
  // function pay(){
  //   const data = {
  //     product: id,
  //     date_payment: null
  //   }
  //   const save = createPayment(data);
  //   save.then((res)=>{
  //     if(res.data.status === 'success'){
  //       toast.success(res.data.message);
  //       setLoading2(true);
  //     }
  //     if(res.data.status === 'error'){
  //       toast.error(res.data.message);
  //     }
  //   }).catch((error)=>{
  //     if(error.response){
  //       toast.error(error.response.message);
  //     }else {
  //       toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}`);
  //     }
  //   })
  // }

  function handleCancel(){
    const cancel = cancelPayment(id);
    cancel.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        setOpenModalCancel(false);
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      toast.error(error.response.data.message);
    })
  }

  function handleRetry(){
    setOpenModalRetry(false);
    setLoading(false)
    const retry = retryPayment(id);
    retry.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        setOpenModalRetry(false);
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
      
    }).catch((error)=>{
      toast.error(error.response.data.message);
    }).finally(()=> {
      setLoading(false)
      setOpenModalCheck(false)
      handleGetList(null)
      
    });
  }

  function handleCheck(){
    setOpenModalCheck(false);
    setLoading(true)
    const check = checkPayment(id);
    check.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
      if(res.data.status === 'warning'){
        toast.warning(res.data.message);
      }
    }).catch((error)=>{
      toast.error(error.response.data.message);
    }).finally(()=> {
      setLoading(false)
      setOpenModalCheck(false)
      handleGetList(null)
      
    });
  }

  function handleChangeItemReccurrent(index: any, item: any)
  {

    // eslint-disable-next-line
    if(confirm(`A recorrência sera ${item.recurrent?'Desativada':'Ativada'}, deseja continuar?`) != true) {
      return;
    }

    const check = changeRecurrentItem(item.code);
    check.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        handleGetList(null)
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
      if(res.data.status === 'warning'){
        toast.warning(res.data.message);
      }
    }).catch((error)=>{
      toast.error(error.response.data.message);
    }).finally(()=> {
    });

  }

  function renewProduct(item:any){

    setRenewProductData({
      product_code: item.product_code,
      renew_from: item.code,
      item_id: item.item_id,
      discount_code: item.discount_code
    })

    setOpenStoreBuyForm(true)
  }

  function disableRenewButtom(code:any){
    if ( data.data.find( (e:any)=> e.renew_from === code) ) {
      return true
    }
  }

  return (
    <>
      <ModalMessage
        message={intl.formatMessage({id: 'Are you sure you want to cancel this item?'})}
        title={intl.formatMessage({id: 'Attention'})}
        showModal={openModalCancel}
        onClose={()=>setOpenModalCancel(false)}
        onAction={handleCancel}
      />
      <ModalMessage
        message={intl.formatMessage({id: 'Are you sure you want to retry this item?'})}
        title={intl.formatMessage({id: 'Attention'})}
        showModal={openModalRetry}
        onClose={()=>setOpenModalRetry(false)}
        onAction={()=>[
          handleRetry()
          
        ]}
      />
      <ModalMessage
        message={intl.formatMessage({id: 'Are you sure you want to check this item?'})}
        title={intl.formatMessage({id: 'Attention'})}
        showModal={openModalCheck}
        onClose={()=>setOpenModalCheck(false)}
        onAction={()=>[
          handleCheck()
        ]}
      />

      <StoreBuyForm
        showModal={openStoreBuyForm}
        title={intl.formatMessage({ id: 'Order details' })}
        onClose={() => setOpenStoreBuyForm(false)}
        productData={ renewProductData }
      /> 

      {
        loading?
        <Load/>
        :
        <div className={`card card mb-5 mb-xl-10`}>
          <div className='card-header border-0 pt-5 d-block'>
            <h3 className='card-title'>
              <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: 'Requests'})}</span>
            </h3>
            <p className='fw-bolder text-gray-600'>{intl.formatMessage({id: 'This page automatically refreshes every 2 minutes'})}</p>
          </div>

          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder text-muted fs-7'>
                    <th className='min-w-80px'>{intl.formatMessage({id: 'DATE'})}</th>
                    <th className='min-w-80px'>{intl.formatMessage({id: 'USER'})}</th>
                    <th className='min-w-80px'>{intl.formatMessage({id: 'ITEM'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'TOTAL'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'DUE DATE'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'STATUS'})}</th>
                    
                    {/* <th className='min-w-200px text-center'>{intl.formatMessage({id: 'ACTIONS'})}</th>  */}
                    
                  </tr>
                </thead>
                <tbody>
                  {
                    data.data?.map((item: any, index: any)=>
                    
                    <>
                      <tr>

                        <td data-bs-toggle='collapse' data-bs-target={`#kt_collapse_${item.id}`} style={{cursor:'pointer'}} onClick={()=>[setItemCollapse(itemCollapse?'':`kt_collapse_${item.id}`)]}><span className='cursor-pointer text-hover-primary'>{converteData(item.date_buy)}</span></td>
                        <td>{item.user}</td>
                        <td>{item.product_name}</td>
                        <td>R$ {item.total}</td>
                        <td><div>{item.invoice_due_date ? `${converteData2(item.invoice_due_date)}` : '- - -'}</div></td>
                        <td > 
                          <span 
                            className={
                              item.status === 'pending'
                              ? 'border-0 badge badge-light-primary'
                              : item.status === 'created'
                              ? 'border-0 badge badge-light'
                              : item.status === 'paid'
                              ? 'border-0 badge badge-light-success'
                              : item.status === 'canceled' || item.status === 'expired' || item.status === 'denied' || item.status === 'failed' || item.status === 'error' 
                              ? 'border-0 badge badge-light-danger'
                              : 'border-0 badge badge-white'
                            }
                          >
                            { item.status === 'created' ? intl.formatMessage({id: 'Waiting'})  : intl.formatMessage({id: item.status}) }
                          </span>                  
                        </td>

                      </tr>

                      <tr id={`kt_collapse_${item.id}`} className={`collapse ${itemCollapse === `kt_collapse_${item.id}`? 'show':'hide'}`} style={{backgroundColor: '#fff9e0'}}>
                        <th colSpan={6} className='card-body border p-4'>
                         
                          { item.slug == 'package' ?
                          <div className='row'>
                            <div className='mt-4 col-12 col-sm-12 col-md-12'>
                              {/* <label htmlFor="">{intl.formatMessage({id: 'RECURRENT'})}</label> */}
                              <small>
                              <div className='form-check form-check-custom form-check-solid'>
                                <input className='form-check-input' type='checkbox' checked={item.recurrent} id={`kt_item_recurrent_${index}`}  onClick={()=> handleChangeItemReccurrent(index, item)} disabled={item.status !== 'paid'}/>
                                <label className='form-check-label fw-bold text-gray-700 ' htmlFor={`kt_item_recurrent_${index}`} >(<span className={item.recurrent?'text-success':'text-danger'}>{intl.formatMessage({id: item.recurrent?'active':'inactive'})}</span>) Gerar automaticamente essa fatura por mês. </label>
                              </div></small>
                            </div>
                          </div>
                          :
                          null
                          }

                          <div className='row'>

                            <div className='mt-4 col-12 col-sm-4 col-md-2'>
                              <label htmlFor="">QUANTIDADE</label>
                              <div>{item.qtd && item.qtd<10?'0'+item.qtd:item.qtd}</div>
                            </div>

                            <div className='mt-4 col-12 col-sm-4 col-md-2'>
                              <label htmlFor="">{intl.formatMessage({id: 'VALUE'})}</label>
                              <div>R$ {item.value}</div>
                            </div>

                            <div className='mt-4 col-12 col-sm-4 col-md-2'>
                              <label htmlFor="">{intl.formatMessage({id: 'COUPON'})}</label>
                              <div>{item.discount_code ? item.discount_code : '- - -'}</div>
                            </div>

                            <div className='mt-4 col-12 col-sm-4 col-md-2'>
                              <label htmlFor="">{intl.formatMessage({id: 'DISCOUNT'})}</label>
                              <div>{item.discount ? 'R$ '+item.discount : '- - -'}</div>
                            </div>

                            <div className='mt-4 col-12 col-sm-4 col-md-2'>
                              <label htmlFor="">{intl.formatMessage({id: 'CURRENCY'})}</label>
                              <div>{item.invoice_currency? item.invoice_currency : '- - -'}</div>
                            </div>

                            {/* <div className='mt-4 col-12 col-sm-4 col-md-2'>
                              <label htmlFor="">{intl.formatMessage({id: 'EXPIRED AT'})}</label>
                              <div>{item.invoice_due_date ? `${converteData(item.invoice_due_date)}` : '- - -'}</div>
                            </div> */}

                            <div className='mt-4 col-12 col-sm-4 col-md-2'>
                              <label htmlFor="">{intl.formatMessage({id: 'DATE PAYMENT'})}</label>
                              <div>
                                {
                                  item.date_payment === null?
                                  <>- - -</>
                                  :
                                  <>
                                    {converteData2(item.date_payment)}
                                  </>
                                }
                              </div>
                            </div>

                            <div className='mt-4 col-12 col-sm-4 col-md-2'>
                              <label htmlFor="">{intl.formatMessage({id: 'PAY METHOD'})}</label>
                              <div>
                                {
                                  ['iugu_bank_slip_test', 'iugu_bank_slip'].includes(item.invoice_payment_method) ? 
                                    'Boleto' 
                                  : ['iugu_pix_test', 'iugu_pix'].includes(item.invoice_payment_method) ? 
                                    'PIX'
                                  : ['iugu_credit_card_test', 'iugu_credit_card'].includes(item.invoice_payment_method) ? 
                                    'Cartão'
                                  : item.invoice_payment_method == null ?
                                    '- - -'
                                  : item.invoice_payment_method
                                
                                }
                              </div>
                            </div>
                        
                          </div>

                          <div className='row'>
                 
                            <div className='col-12 d-flex justify-content-end flex-shrink-0'>

                              {
                                item.status === 'error' ?

                                <button className='btn btn-sm btn-danger ' 
                                onClick={()=>[
                                setLoading(true),
                                getPaymentsId(item.id),
                                ]}>
                                    {item.status}
                                </button>
                                :
                                null
                              }

                              {
                                item.status === 'pending' && item.invoice_secure_url?
                              <a
                                href={item.invoice_secure_url? `${item.invoice_secure_url}` : ''}
                                target='_blank'
                                rel="noreferrer"
                                className='btn btn-sm btn-success px-3 me-1'
                                onClick={()=>setId(item.id)}
                              >
                                {intl.formatMessage({id: 'Pay'})}
                              </a>
                              :
                              null
                              }
                              {
                                item.status === 'pending' && ['admin', 'super-admin'].includes(user.roles) ?
                                <button
                                  className='btn btn-sm btn-danger px-2'
                                  onClick={()=>[setId(item.code), setOpenModalCancel(true)]}
                                >
                                  {intl.formatMessage({id: 'Cancel'})}
                                </button>
                                :
                                null
                              }
                              {
                                item.status === 'partially_create' || item.status === 'failed' || item.status === 'error'?
                                <button
                                  className='px-2 mx-1 btn btn-sm btn-primary text-white'
                                  onClick={()=>[setId(item.code), setOpenModalRetry(true)]}
                                >
                                  {item.status === 'partially_create' ? `${intl.formatMessage({id: 'Create'})}` : `${intl.formatMessage({id: 'Retry'})}`}
                                </button>
                                :
                                <></>
                              }

                              { disableRenewButtom(item.code) ?
                                
                                <span className={'border-0 badge badge-dark'}>Renovado</span> 
                                :
                              
                                ['expired', 'canceled'].includes(item.status) ?
                                  <button
                                    id={'btn_renew_'+item.code}
                                    className='px-2 mx-1 btn btn-sm btn-dark text-white'
                                    onClick={()=>[ renewProduct(item) ]}
                                  >
                                    {intl.formatMessage({id: 'Renew'})}
                                  </button>
                                :
                                null
                              }
                                              
                              { 
                                moment(item.updated_at).add(5,'minutes') < moment() && item.invoice_id && !['expired'].includes(item.status) && !['System'].includes(item.invoice_payment_method)?
                                <button onClick={()=>[setId(item.id), setOpenModalCheck(true)]} className='px-2 mx-1 btn btn-sm btn-info'>
                                  {intl.formatMessage({id: 'Check'})}
                                </button>
                                : 
                                null
                              }

                            </div>

                          </div>
                         
                        </th>
                      </tr>

                    </>
                    
                    )
                  }
                </tbody>
              </table><Pagination data={data} onSearch={handleGetList} searchData={searchData}/>
            </div>

            <Modal
              open={openModalDetails}
              onClose={()=>{}}
              title={intl.formatMessage({id: 'Details'})}
              aria-describedby="modal-modal-description"
            >
      
              <Box sx={style}>
              <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
                  <span className="modal-title fw-bold fs-3 ">{intl.formatMessage({id: 'Error Details'})}</span>
                  <div
                    className="btn btn-icon btn-sm btn-active-light-primary "
                    onClick={()=>[setOpenModalDetails(false), handleGetList(null)]}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon svg-icon-2x"
                    />
                  </div>
                  
                </div>

                <p className='m-5'>{intl.formatMessage({id: 'invoice_create_message_err'})}</p>

            <div className='container p-5' style={{overflowY: 'scroll', overflowX: 'hidden', maxHeight: '250px'}}>

              <div>
                <ul>

                  {
                    dataError && dataError.status_code === 422 ? 
                      ( dataError && dataError.errors?
                      
                      Object.keys(dataError.errors).map( (item: any, index: any) => 

                        <div>
                          <li>{intl.formatMessage({id: item})}</li>
                          {
                            dataError.errors[item].map( (item2: any, index: any) => ( <div>- {item2}</div> ))
                          }
                        </div>
                                  
                      )
                      :
                      'Lista não diponível...'

                    )
                    : null
                  }

                  {
                    dataError && dataError.status_code === 401 ? 
                      ( dataError && dataError.errors?

                        <li>{dataError.errors}</li>

                      :
                      'Lista não diponível...'
                    )
                    : null
                  }

                </ul>

                {
                  dataError && (![422, 401].includes(dataError.status_code) ? `${dataError.status_code}: Erro não conhecido`: null)
                }

              </div>        
                
            </div>
            {/* <div 
             > <p className='fw-bold d-block fs-3 '>
             </p>
            <p  className='p-5 border-top  start-0 w-100 fw-bolder fs-4'>
            {intl.formatMessage({id: 'Check the errors and try again'})}
            </p>
              
            </div> */}

              </Box>
           </Modal>
            
          </div>

        </div>
        }
    </>
  )
}

export {TablesPayments}
