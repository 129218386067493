import React, { useState } from 'react';
import { converteData, converteData2 } from '../../../../../../app/modules/helpers/helpers';
import { KTSVG, toAbsoluteUrl } from '../../../../../helpers';
import ModalProfile from './modals/modalProfile';
import { useIntl } from 'react-intl'

type Props = {
  data: any;
  edition: (action: boolean) => void;
  reload: (action: boolean) => void;
  fetchUser: (userId: any) => void;
}

const ProfileUser: React.FC<Props> = ({...props}) => {
  const [openModalEdition, setOpenModalEdition] = useState(false);
  const intl = useIntl();   

  return (
    <div className='card mb-5 mb-xl-5'>
      <ModalProfile
        title={intl.formatMessage({id: 'Edit Profile User'})} 
        showModal={openModalEdition}
        fetchUser={props.fetchUser}
        onClose={()=>setOpenModalEdition(false)}
        onAction={()=>[setOpenModalEdition(false), props.reload(true)]}
        id={props.data.id}
        data={props.data}
      />
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              {
                props.data.photo ?
                <img src={`data:image/jpeg;base64,${props.data.photo}`} />
                :
                <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='Metornic' />
              }
            </div>
          </div>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <span className='text-gray-800 fs-2 fw-bolder me-2'>
                    {props.data.name} {props.data.last_name}
                  </span>
                  {props.data.roles? <span className="badge badge-light-warning">{props.data.roles[0]}</span> : ''}
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-2'>
                  <span
                    className='d-flex align-items-center text-gray-600'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-4 me-1'
                    />
                    <strong className='me-2'>
                      {intl.formatMessage({id: 'E-mail'})}:
                      </strong>
                      {props.data.email}
                  </span>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-2'>
                  <span
                    className='d-flex align-items-center text-gray-600'
                  >
                    <KTSVG
                      path='/media/icons/duotune/coding/cod006.svg'
                      className='svg-icon-4 me-1'
                    />
                    <strong className='me-2'>
                      {intl.formatMessage({id: 'CPF'})}:
                      </strong>
                      {props.data.cpf}
                  </span>
                </div>
                
                <div className='d-flex flex-wrap fw-bold fs-6 mb-2'>
                  <span
                    className='d-flex align-items-center text-gray-600'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen014.svg'
                      className='svg-icon-4 me-1'
                    />
                    <strong className='me-2'>{intl.formatMessage({id: 'Birthday'})}:</strong>{props.data.birthday? converteData2(props.data.birthday) : '---'}
                  </span>
                </div>
                {/* <div className='d-flex flex-wrap fw-bold fs-6 mb-2'>
                  <span
                    className='d-flex align-items-center text-gray-600'
                  >
                    <KTSVG
                      path='/media/icons/duotune/maps/map004.svg'
                      className='svg-icon-4 me-1'
                    />
                    <strong className='me-2'>Language:</strong> {props.data.language}
                  </span>
                </div> */}
                <div className='d-flex flex-wrap fw-bold fs-6 mb-2'>
                  <span
                    className='d-flex align-items-center text-gray-600'
                  >
                    <KTSVG
                      path='/media/icons/duotune/coding/cod006.svg'
                      className='svg-icon-4 me-1'
                    />
                    <strong className='me-2'>{intl.formatMessage({id: 'Last login'})}:</strong> {converteData(props.data.last_login)}
                  </span>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-2'>
                  <span
                    className='d-flex align-items-center text-gray-600'
                  >
                    <KTSVG
                      path='/media/icons/duotune/coding/cod006.svg'
                      className='svg-icon-4 me-1'
                    />
                    <strong className='me-2'>{intl.formatMessage({id: 'Status'})}:</strong> <span className='text-gray-700 fw-bold fs-6'>
                    {
                      props.data.deleted_users ?
                      <span className="badge badge-light-danger">{intl.formatMessage({id: 'Deleted'})}</span>
                      :
                      <>
                      {                              
                        props.data.status === 'inative' ? <span className="badge badge-light-danger">{intl.formatMessage({id: 'Inactive'})}</span> :
                        props.data.status === 'active'? <span className="badge badge-light-success">{intl.formatMessage({id: 'Active'})}</span>:
                        'status desconhecido'
                      }
                      </>
                    }
                      {/* {                                
                        props.data.status === 'inative' ? <span className="badge badge-light-danger">{intl.formatMessage({id: 'Inactive'})}</span> :
                        props.data.status === 'active'? <span className="badge badge-light-success">{intl.formatMessage({id: 'Active'})}</span>:
                        'status desconhecido'
                      } */}
                    </span>              
                  </span>
                </div>
              </div>              
              <div>
                <button onClick={()=>props.edition(false)} type='button' className='m-2 btn btn-sm btn-secondary p-3'>
                  <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark'/>
                  {intl.formatMessage({id: 'Back'})}
                </button>
                {/* <button className='btn btn-sm btn-secondary me-2' >
                  Back
                </button> */}
                {
                   props.data.deleted_users ?
                   <></>
                   :
                  <button onClick={()=>setOpenModalEdition(true)} className='btn btn-sm btn-primary text-dark me-2'>
                  {intl.formatMessage({id: 'Edit'})}
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProfileUser;