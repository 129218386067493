/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { deleteCoupon, getUsers, defaultPaginationList } from '../../../../../app/modules/helpers/Api';
import { KTSVG, toAbsoluteUrl } from '../../../../helpers'
import ModalMessage from '../../modals/modalMessage';
import { Load } from '../../spinner/Spinner';
import Warning from '../../warning/Warning';
import { useIntl } from 'react-intl'
import Pagination from '../../../../helpers/components/CustomPagination/CustomPagination'
import ModalViewCoupon from './ModalViewCoupon'

let searchData = {
  search_value: '',
  status: '',
  type: ''
}

type Props = {
  create: (action: boolean) => void;
}

const CouponList: React.FC<Props> = ({ ...props }) => {

  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState<any>([]);
  const [deleteUuid, setDeleteUuid] = useState('');
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loading2, setLoading2] = useState(false);  
  const [searchValueFilter, setSearchValueFilter] = useState<any>('');
  const [statusFilter, setStatusFilter] = useState<any>('');
  const [typeFilter, setTypeFilter] = useState<any>('');
  const [viewCoupon, setViewCoupon] = useState('');
  const intl = useIntl();

  useEffect(() => {
    handleGetList(null)
  }, [loading2]);

  function handleGetList(data: null) {

    searchData.search_value = searchValueFilter
    searchData.status = statusFilter
    searchData.type = typeFilter

    setLoading(true);
    const get = defaultPaginationList('coupons', searchData)
    get.then((res) => {
      setDataUser(res.data.data);
    }).catch((error) => {
      toast.error(error.response.data.message)
    })
    .finally(() => [setLoading(false), setLoading2]);
  }

  function handleDelete(uuid: any) {

    setLoading(true)

    const del = deleteCoupon(uuid);
    del.then((res) => {
      if (res.data.status === 'success') {
        toast.success(res.data.message);
        setOpenModalDelete(false);
        handleGetList(null)
      }
      if (res.data.status === 'error') {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      if (error.response) {
        toast.error(error.response.message);
      } else {
        toast.error(`${intl.formatMessage({ id: 'Sorry, an error occurred' })}`)
      }
    }).finally(() => setLoading(false));
  }

  return (
    <>
      <h3 className='card-title align-items-start flex-column mb-3'>
        <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({ id: 'Coupon List' })}</span>&nbsp;
      </h3>

      {
        loading ?
          <Load />
          :
          <div className={`card card mb-5 mb-xl-10`}>
            <ModalMessage
              message={intl.formatMessage({ id: 'Are you sure you want to delete this item?' })}
              title={intl.formatMessage({ id: 'Attention' })}
              showModal={openModalDelete}
              onClose={() => setOpenModalDelete(false)}
              onAction={() => handleDelete(deleteUuid)}
            />
            <div className='row d-flex align-items-center justify-content-end card-header border-0 pt-5'>

              <div >

                <div className=" row" >

                  <div className="col-md-6">

                    <div className=" row" >
                      <div className="col-md-4"
                      >

                        <input
                          type="text"
                          className="form-control form-control-solid rounded-3"
                          placeholder={intl.formatMessage({ id: 'Search' })}
                          name="search_value"
                          autoComplete='off'
                          value={searchValueFilter}
                          onChange={(e) => [setSearchValueFilter(e.target.value)]}

                        />

                      </div>

                      <div className='col-md-4'
                      >
                        <select className='form-select form-control form-control-solid fs-6'
                          name="Type"
                          value={statusFilter}
                          onChange={(e) => [setStatusFilter(e.target.value)]}
                        >
                          <option value='' >{intl.formatMessage({ id: 'All Status' })}</option>
                          <option value='test'>{intl.formatMessage({ id: 'Test' })}</option>
                          <option value='active' >{intl.formatMessage({ id: 'Active' })}</option>
                          <option value='Inactive'>{intl.formatMessage({ id: 'Inactive' })}</option>
                        </select>
                      </div>

                      <div className='col-md-4'
                      >
                        <select className='form-select form-control form-control-solid '
                          name="status"
                          value={typeFilter}
                          onChange={(e) => [setTypeFilter(e.target.value)]}
                        >
                          <option value='' >{intl.formatMessage({ id: 'All Types' })}</option>
                          <option value='fixed' >{intl.formatMessage({ id: 'fixed' })}</option>
                          <option value='percent' >{intl.formatMessage({ id: 'percent' })}</option>
                        </select>
                      </div>
                    </div>

                  </div>

                  <div className="col-12 col-sm-12 col-md-6 d-flex justify-content-end">

                    <button
                      type='button'
                      className='btn bg-hover-dark  text-white'
                      disabled={loading}
                      onClick={() => handleGetList(null)}
                      style={{ width: '125px', height: '40px', background: '#65638E', borderRadius: 13, marginRight: '10px' }}
                    >
                      {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Search' })}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>


                    <button
                      disabled={loading}
                      className='btn btn-sm btn-primary text-white'
                      onClick={() => props.create(true)}
                      // title={intl.formatMessage({ id: 'Click to add a user' })}
                      style={{ height: '40px', width: '125px', borderRadius: 11, marginRight: window.screen.width > 767 ? '13px' : '0px' }}
                    >
                      <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                      {intl.formatMessage({ id: 'New' })}
                    </button>


                  </div>

                </div>
              </div>

            </div>

            <div className='card-body py-3'>
              {
                dataUser.data && dataUser.data.length > 0 ?
                  <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bolder text-dark'>
                          <th className='min-w-120px'>{intl.formatMessage({ id: 'TITLE' })}</th>
                          <th className='min-w-120px'>{intl.formatMessage({ id: 'CODE' })}</th>
                          <th className='min-w-120px'>{intl.formatMessage({ id: 'STATUS' })}</th>
                          <th className='min-w-120px'>{intl.formatMessage({ id: 'TYPE' })}</th>
                          <th className='min-w-120px'>{intl.formatMessage({ id: 'USAGE' })}</th>
                          <th className='min-w-120px'>{intl.formatMessage({ id: 'VALUE' })}</th>
                          <th className='min-w-120px text-center'>{intl.formatMessage({ id: 'ACTIONS' })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          dataUser.data.map((item: any, index: any) =>
                            <tr key={index}>

                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-gray-600 fw-bold d-block fs-7'>{item.title}</span>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-gray-600 fw-bold d-block fs-7'>{item.code}</span>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-gray-700 fw-bold fs-6'>

                                      {
                                        !item.deleted_at && item.status === 'test' ? <span className="badge badge-light-primary">{intl.formatMessage({ id: 'Test' })}</span> :
                                          !item.deleted_at && item.status === 'inactive' ? <span className="badge badge-light-dark">{intl.formatMessage({ id: 'Inactive' })}</span> :
                                            !item.deleted_at && item.status === 'active' ? <span className="badge badge-light-success">{intl.formatMessage({ id: 'Active' })}</span> :
                                              item.deleted_at ? <span className="badge badge-light-danger">{intl.formatMessage({ id: 'Deleted' })}</span> :
                                                '- - -'
                                      }

                                    </span>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-gray-700 fw-bold fs-6'>

                                      {
                                        item.type === 'fixed' ? <span className="badge badge-light-dark">{intl.formatMessage({ id: 'Fixed' })}</span> :
                                          item.type === 'percent' ? <span className="badge badge-light-info">{intl.formatMessage({ id: 'Percent' })}</span> :
                                            '- - -'
                                      }

                                    </span>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-gray-600 fw-bold d-block fs-7'>{item.usage_count?item.usage_count:' - - -'}</span>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-gray-600 fw-bold d-block fs-7'>{item.value}</span>
                                  </div>
                                </div>
                              </td>


                              <td>
                                <div className='d-flex justify-content-center flex-shrink-0'>
                                  <button
                                    disabled={item.deleted_at? true : false}
                                    className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                    onClick={() => [setViewCoupon(item.uuid)]}
                                    title={`${intl.formatMessage({ id: 'Edit' })}`}
                                  >
                                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _edit 2 outline_.svg')} style={{ marginRight: '10px' }} />
                                  </button>
                    
                                  <button
                                    className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm'
                                    title={`${intl.formatMessage({ id: 'Delete' })}`}
                                    onClick={() => [
                                      setDeleteUuid(item.uuid),
                                      setOpenModalDelete(true)
                                    ]}
                                  >
                                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _trash_.svg')} style={{ marginRight: '10px' }} />
                                  </button>
                               
                                </div>
                              </td>

                            </tr>
                          )
                        }
                      </tbody>
                    </table><Pagination data={dataUser} onSearch={handleGetList} searchData={searchData} />

                  </div>
                  :
                  <Warning message={intl.formatMessage({ id: 'Sorry, no content to show yet' })} />
              }
            </div>
          </div>
      }

      <ModalViewCoupon
        modelRef={viewCoupon}
        onClose={() => setViewCoupon('')}
        handleGetList={() => handleGetList(null)}
      />

    </>
  )
}

export { CouponList }
