import React, { useState } from 'react';
import ModalAddress from './modals/modalAddress';
import { useIntl } from 'react-intl'

type Props = {
	data: any;
	reload: (action: boolean) => void;
}

const ProfileAddress: React.FC<Props> = ({...props}) => {
	const [openModalEdition, setOpenModalEdition] = useState(false);
	const intl = useIntl();   

  return(
		<div className="card card-custom card-flush mb-5">
		<ModalAddress
        title={intl.formatMessage({id: 'Edit Address Detail'})}
        showModal={openModalEdition}
        onClose={()=>setOpenModalEdition(false)}
        onAction={()=>[setOpenModalEdition(false), props.reload(true)]}
        id={props.data.id}
        data={props.data}
      />
			<div className="card-header m-0">
      		<h3 className="card-title fs-2 fw-bolder m-0 p-0">{intl.formatMessage({id: 'Address Details'})}</h3>
				{/* <div className="card-toolbar">
          <button onClick={()=>setOpenModalEdition(true)} type="button" className="btn btn-sm btn-primary">
            Edit Address
          </button>
      	</div> */}
			</div>
			<div className="card-body m-0 pt-0 w-100">

				<div className='col-lg-12 w-100 py-3'>
					<div className='w-100 row d-flex align-items-center'>
						<div className='col-lg-12 d-flex flex-column border-bottom-dashed border-1 border-gray-400 pb-2'>
							<span className='text-gray-700 fw-bold fs-6'>{intl.formatMessage({id: 'Phone'})}</span>
							<span className='text-gray-600 fw-bold fs-7'>{props.data.phone? (props.data.phone_prefix+' '+props.data.phone) : '---'}</span>
						</div>
						<div className='col-lg-4 d-flex flex-column border-bottom-dashed border-1 border-gray-400 py-2'>
							<span className='text-gray-700 fw-bold fs-6'>{intl.formatMessage({id: 'Country'})}</span>
							<span className='text-gray-600 fw-bold fs-7'>{props.data.country? props.data.country : '---'}</span>
						</div>
						<div className='col-lg-4 d-flex flex-column border-bottom-dashed border-1 border-gray-400 py-2'>
							<span className='text-gray-700 fw-bold fs-6'>{intl.formatMessage({id: 'State'})}</span>
							<span className='text-gray-600 fw-bold fs-7'>{props.data.state? props.data.state : '---'}</span>
						</div>
						<div className='col-lg-4 d-flex flex-column border-bottom-dashed border-1 border-gray-400 py-2'>
							<span className='text-gray-700 fw-bold fs-6'>{intl.formatMessage({id: 'City'})}</span>
							<span className='text-gray-600 fw-bold fs-7'>{props.data.city? props.data.city : '---'}</span>
						</div>
						<div className='col-lg-4 d-flex flex-column py-2'>
							<span className='text-gray-700 fw-bold fs-6'>{intl.formatMessage({id: 'Postal Code'})}</span>
							<span className='text-gray-600 fw-bold fs-7'>{props.data.post_code? props.data.post_code : '---'}</span>
						</div>
						<div className='col-lg-4 d-flex flex-column py-2'>
							<span className='text-gray-700 fw-bold fs-6'>{intl.formatMessage({id: 'Street'})}</span>
							<span className='text-gray-600 fw-bold fs-7'>{props.data.street? props.data.street : '---'}</span>
						</div>
						<div className='col-lg-4 d-flex flex-column py-2'>
							<span className='text-gray-700 fw-bold fs-6'>{intl.formatMessage({id: 'Number'})}</span>
							<span className='text-gray-600 fw-bold fs-7'>{props.data.number? props.data.number : '---'}</span>
						</div>
						
					</div>
				</div>
				
			</div>
		</div>
  )
}
export default ProfileAddress;