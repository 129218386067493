/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {deleteProfile } from '../../../../app/modules/helpers/Api'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import {useDispatch} from 'react-redux'
import * as auth from "../../../../app/modules/auth/redux/AuthRedux";




const DeleteForm: FC = () => {
  
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
  const[loading, setLoading] = useState(false);
  const [seePassword, setSeePassword] = useState('password');
  const intl = useIntl();  

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }
  
  const schema = Yup.object().shape({
    email: Yup.string().required(`${intl.formatMessage({id: 'E-mail'})} ${intl.formatMessage({id: 'is required'})}`),
    pass: Yup.string()
    .min(3, `${intl.formatMessage({id: 'Minimum 6 symbols'})}`)
    .max(50, `${intl.formatMessage({id: 'Maximum 50 symbols'})}` )
    .required( `${intl.formatMessage({id: 'Password'})} ${intl.formatMessage({id: 'is required'})}`),
  })

  const initialValues = {
    email: '',
    pass: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        save(values);
        setLoading(false)
      }, 1000)
    },
  })

  function save(value: any){

    const save = deleteProfile(value);
    save.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        // toast.success(`${intl.formatMessage({id: 'Obrigado por utilizar nosso Sistema!!'})}`)
        setTimeout(() => {
          logout();
          window.location.reload()
        }, 1500);
        // formik.resetForm();
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      toast.error(error.response.data.message? error.response.data.message : `${intl.formatMessage({id: 'Sorry, an error occurred'})}` );
    })
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body px-5 py-0'>
            <span className='text-gray-700'>{intl.formatMessage({id: 'To continue, please enter your email and password'})}</span>
            <div className='mb-2 fv-row mt-5' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'E-mail'})}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <input
                      type='email' 
                      placeholder={`${intl.formatMessage({id: 'E-mail'})}`}
                      autoComplete='off'
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-100 me-1',
                        {
                          'is-invalid': formik.touched.email && formik.errors.email,
                        },
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Password'})}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <input
                      type={seePassword}
                      placeholder={`${intl.formatMessage({id: 'Password'})}`}
                      autoComplete='off'
                      {...formik.getFieldProps('pass')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-90 me-1',
                        {
                          'is-invalid': formik.touched.pass && formik.errors.pass,
                        },
                        {
                          'is-valid': formik.touched.pass && !formik.errors.pass,
                        }
                      )}
                    />
                    {
                      seePassword === 'password'?
                      <span onClick={()=>setSeePassword('text')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                        <i className="bi bi-eye-slash-fill fs-2x"></i>
                      </span>
                      :
                      <span onClick={()=>setSeePassword('password')} className='d-flex border-0 bg-gray-middle-2 rounded w-10 align-items-center justify-content-center'>
                        <i className="bi bi-eye-fill fs-2x"></i>
                      </span>
                    }
                  </div>
                  {formik.touched.pass && formik.errors.pass && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.pass}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type="button"
              className="btn btn-primary mx-2 text-white"
              data-bs-dismiss="modal"
            >
             {intl.formatMessage({id: 'Cancel'})} 
            </button>
            <button type='submit' className='btn btn-danger' disabled={loading}>
              {!loading && 'Delete'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                 {intl.formatMessage({id: 'Processing'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {DeleteForm}
