import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../../helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { Load } from '../../../spinner/Spinner';
import { getFormQuestions, storeFormQuestions } from '../../../../../../app/modules/helpers/Api';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { useIntl } from 'react-intl'
import { info } from 'console';
import ModalMessageConfirm from '../../../modals/modalMessageConfirm';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 350,
  maxHeight: 550,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

interface IModalQuestionAddProps {
  showModal: boolean;
  model: any;
  title: string;
  id: any;
  onClose: () => void;  
  onAction: (question: any) => void;
  onGetForm: () => void;
}


const QuestionAdd: React.FC<IModalQuestionAddProps> = ({...props}) => {

  const[question, setQuestion] = useState<any>({});
	const[errorQuestion, setErrorQuestion] = useState('');
  const[loading, setLoading] = useState(false);
  const[loading2, setLoading2] = useState(false);
  const[data, setData] = useState<any>([]);
  const intl = useIntl();   

  const[openConfirmNewQuestion, setOpenConfirmNewQuestion] = useState(false);
  

  const [changeIsKey, setIsKey] = useState(false);

  useEffect(()=>{
    setLoading2(true);
    setQuestion({});
    const get = getFormQuestions(props.model);
    get.then((res)=>{
      setData(res.data.data.data);
    }).catch((error)=>{
      toast.error(error.response.message)
    }).finally(()=>setLoading2(false));
  },[]);

  const schema = Yup.object().shape({
    required: Yup.string()
    .required(`${intl.formatMessage({id: 'Please, inform if the question is required'})}` ),
    type: Yup.string()
    .required(`${intl.formatMessage({id: 'Type'})} ${intl.formatMessage({id: 'is required'})}`),
  })
  
  const initialValues = {
    question: '',
    type: '',
    required: '',
    is_key: false
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value, actions) => {

      setOpenConfirmNewQuestion(true);
     
    },
  })

  function handleStoreQuestion() {

    setOpenConfirmNewQuestion(false);

    if(!question.title) {
      toast.error(`${intl.formatMessage({id: 'Unable to add, check information'})}`);
      return;
    }

    let dataQuestion = {
      prescription_id: props.id,
      question_name: question.title,
      is_key: changeIsKey,
      // category_id: value.type === 'input' ? null : parseInt(question.category_name.slice(-2)),
      type: formik.getFieldProps('type').value,
      required: parseInt(formik.getFieldProps('required').value)
    }    

    const store = storeFormQuestions(dataQuestion);
    store.then((res)=>{

      if (res.data.status == 'success') {
        
        setQuestion({});
        setIsKey(false)

        toast.success(res.data.message);

        formik.resetForm();
        props.onClose();
        props.onGetForm();

      } else {

        toast.error(res.data.message);

      }

    }).catch((error)=>{

      toast.error(error.response.message)
    }).finally(()=> {

      setLoading2(false)

    });
    
  }

  function handleChange2(e:any) {
    if (e) {
      setQuestion({
        title: e.label,
        model: 4,
        id: typeof e.value == 'number' ? e.value : null,
        category_name: null
      })
    }    
  }
  function handleInputChange2(e:any) {
    if (e) {
      setQuestion({
        title: e.value,
        model: 4,
        id: null,
        category_name: null
      })
    }
  }

  return(
    <div>

      <ModalMessageConfirm
        message='Você confirma a criação dessa nova pergunta?'
        title={intl.formatMessage({id: 'Attention'})}
        showModal={openConfirmNewQuestion}
        onClose={()=>setOpenConfirmNewQuestion(false)}
        onAction={()=>handleStoreQuestion()}
      />
    


      <Modal
        open={props.showModal}
        onClose={()=>{}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
            <span className="modal-title fw-bold fs-3">{props.title}</span>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary"
              onClick={()=>props.onClose()}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
                  
            {
              loading2?
              <Load/>
              :
              <form onSubmit={formik.handleSubmit} noValidate className='form' id='form'>
                <div className='card-body'>
                  <div className='px-5' style={{overflow: 'auto', overflowX: 'hidden', minHeight: 400}}>
                    <div className='col-lg-12'>
                      <div className='row' >
                        <div className='col-lg-12 fv-row mb-5 '>
                          <span className='col-lg-4 fs-5 text-gray-700 required'>{intl.formatMessage({id: 'Select or type a question below'})}</span>

                          <CreatableSelect
                            isClearable
                            // styles={{ 
                            //   menuPortal: provided  => ({ ...provided , zIndex: 9999, position: 'relative', }),
                            //   menu: provided => ({ ...provided, zIndex: 9999, position: 'relative', })
                            // }}
                            onChange={e => handleChange2(e)}
                            onInputChange={e => handleInputChange2(e)}
                            options={data.map((item: any, index: any) => (
                              {label: item.title, value: item.id}
                            ))}
                          />
                        </div>
                        <div className='col-sm-12 fv-row mb-5'>
                          <span className='col-lg-4 fs-5 text-gray-700 required'>{intl.formatMessage({id: 'What is the question type?'})}</span>
                          <select className='form-select form-control form-control-solid fs-6 mt-2' 
                            {...formik.getFieldProps('type')}
                          >
                            <option value='' disabled>{intl.formatMessage({id: 'Select'})}</option>
                            <option value='single'>{intl.formatMessage({id: 'Single answer'})}</option>
                            <option value='mult'>{intl.formatMessage({id: 'Multiple choice'})}</option>
                            <option value='input'>{intl.formatMessage({id: 'Open answer'})}</option>
                          </select>
                        </div>
                        <div className='col-sm-12 fv-row mb-5'>
                          <span className='col-lg-4 fs-5 text-gray-700 required'>{intl.formatMessage({id: 'Is it mandatory to answer this question?'})}</span>
                          <select className='form-select form-control form-control-solid fs-6 mt-2' 
                            {...formik.getFieldProps('required')}
                          >
                            <option value='' disabled>{intl.formatMessage({id: 'Select'})}</option>
                            <option value='1'>{intl.formatMessage({id: 'Yes'})}</option>
                            <option value='0'>{intl.formatMessage({id: 'No'})}</option>
                          </select>
                        </div>
                      </div>
                    </div>


                      { props.model === 4 ?
                        <div className='row mb-5 mt-4'>
                          <div className='col-lg-9 col-xl-4'>
                            <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <input
                              name="tags"
                              type="checkbox"
                              checked={changeIsKey}
                              className='form-check-input'
                              disabled={!['single','mult'].includes(formik.values.type)}
                              onChange={e => {
                                if (e.target.checked) {
                                  setIsKey(true)
                                } else {
                                  setIsKey(false)
                                }
                              }}
                            />
                              <span className='form-check-label text-muted'>{intl.formatMessage({id: 'key question'})}?</span>
                            </label>
                          </div>
                        </div>
                        :
                        null
                      }
                


                    <div className='fv-row my-4'>
                      {errorQuestion && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errorQuestion}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='card-footer d-flex flex-row w-100 justify-content-end p-5'>
                  
                  <button
                    type="button"
                    className="btn btn-danger mx-2"
                    onClick={()=>props.onClose()}
                    disabled={loading}
                  >
                    {intl.formatMessage({id: 'Cancel'})}
                  </button>

                  <button 
                    type='submit'
                    form='form'
                    className='btn btn-primary text-white mx-2'
                    disabled={loading}
                  >
                    {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Save' })}</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  


                  {/* handleStoreQuestion */}


                </div>

              </form>
            }
                
        </Box>
      </Modal>
    </div>
  )
}
export default QuestionAdd;