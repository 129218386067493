import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../../../helpers';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { upProfile } from '../../../../../../../app/modules/helpers/Api';
import ModalMessageConfirm from '../../../../modals/modalMessageConfirm';
import { useIntl } from 'react-intl'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  maxHeight: 550,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

interface IModalAddressProps {
  showModal: boolean;
  title: string;
  onClose: () => void;
  onAction: () => void;
  id: any;
  data: any
}


const ModalAddress: React.FC<IModalAddressProps> = ({
  ...props
}) => {

  const[loading, setLoading] = useState(false);
  const [value, setValue] = useState<any>({});
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const intl = useIntl();   

  const schema = Yup.object().shape({
    phone: Yup.string()
      .required(`${intl.formatMessage({id: 'Phone'})} ${intl.formatMessage({id: 'is required'})}`),
    country: Yup.string()
      .required(`${intl.formatMessage({id: 'Country'})} ${intl.formatMessage({id: 'is required'})}`),
    state: Yup.string()
      .required(`${intl.formatMessage({id: 'State'})} ${intl.formatMessage({id: 'is required'})}`),
    city: Yup.string()
      .required(`${intl.formatMessage({id: 'City'})} ${intl.formatMessage({id: 'is required'})}`),
    postCode: Yup.string()
      .required(`${intl.formatMessage({id: 'Postal Code'})} ${intl.formatMessage({id: 'is required'})}`),
    street: Yup.string()
      .required(`${intl.formatMessage({id: 'Street'})} ${intl.formatMessage({id: 'is required'})}`),
    number: Yup.string()
      .required(`${intl.formatMessage({id: 'Number'})} ${intl.formatMessage({id: 'is required'})}`),
    
  })
  const initialValues = {
    phone: props.data.tel? props.data.tel : '',
    country: props.data.country? props.data.country : '',
    state: props.data.state? props.data.state : '',
    city: props.data.city? props.data.city : '',
    postCode: props.data.post_code? props.data.post_code: '',
    street: props.data.street? props.data.street : '',
    number: props.data.number? props.data.number : '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        setValue(values);
        setOpenModalSubmit(true);
        setLoading(false)
      }, 1000)
    },
  })

  function save(value: any){
    const data = {
      tel: value.phone,
      country: value.contry,
      city: value.city,
      state: value.state,
      street: value.street,
      number: value.number,
      post_code: value.post_code,
    }
    const save = upProfile(props.id, data);
    save.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        formik.resetForm();
        setOpenModalSubmit(false);
        props.onAction();
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response.data){
        toast.error(error.response.data.message);
      } else {
        toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}`);
      }
    })
  }

  return(
    <>
    <ModalMessageConfirm
      message={intl.formatMessage({id: 'Are you sure you want to edition this item?'})}
      title={intl.formatMessage({id: 'Attention'})}
      showModal={openModalSubmit}
      onClose={()=>setOpenModalSubmit(false)}
      onAction={()=>save(value)}
    />
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
          <span className="modal-title fw-bold fs-3">{props.title}</span>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>props.onClose()}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className='px-5 mb-5 mt-1' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 350}}>

          <form onSubmit={formik.handleSubmit} noValidate className='form' id='form'>
            <div className='card-body'>
              <div className='col-lg-12 fv-row mb-3'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Phone'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'Phone'})}
                          autoComplete='off'
                          {...formik.getFieldProps('phone')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.phone && formik.errors.phone && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.phone}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Country'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'Country'})}
                          autoComplete='off'
                          {...formik.getFieldProps('country')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.country && formik.errors.country && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.country}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'State'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'State'})}
                          autoComplete='off'
                          {...formik.getFieldProps('state')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.state && formik.errors.state && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.state}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'City'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'City'})}
                          autoComplete='off'
                          {...formik.getFieldProps('city')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.city && formik.errors.city && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.city}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Postal Code'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'Postal Code'})}
                          autoComplete='off'
                          {...formik.getFieldProps('postCode')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.postCode && formik.errors.postCode && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.postCode}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Street'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'Street'})}
                          autoComplete='off'
                          {...formik.getFieldProps('street')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.street && formik.errors.street && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.street}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-3'>
                    <label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Number'})}</label>
                    <div className='position-relative mb-3'>
                      <div className='d-flex justify-content-between w-100'>
                        <input
                          type='text'
                          placeholder={intl.formatMessage({id: 'Number'})}
                          autoComplete='off'
                          {...formik.getFieldProps('number')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                      </div>
                      {formik.touched.number && formik.errors.number && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.number}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </form>

        </div>
        <div className='d-flex flex-row w-100 justify-content-end p-5'>
          <button
            type="button"
            className="btn btn-danger mx-2"
            onClick={()=>props.onClose()}
          >
            {intl.formatMessage({id: 'Cancel'})}
          </button>
          <button type='submit' form='form' className='btn btn-primary' disabled={loading}>
            {!loading && intl.formatMessage({id: 'Save'})}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'Processing'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </Box>
    </Modal>
    </>
  )
}
export default ModalAddress;