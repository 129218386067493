import react, { useEffect, useState } from 'react';
import {useIntl} from 'react-intl'

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IModalCalculatorTypeProps {

}

const DashboardFeed: React.FC<IModalCalculatorTypeProps> = ({...props}) => {
  const intl = useIntl(); 

  return(
   <>
    <div className='container bg-white p-5 h-100 rounded-4 w-90 mt-5'>
        <div className='text-center mt-5 w-75 mx-auto'>
           <div>
             <h1>{intl.formatMessage({id: 'Access the tutorials of our'})} <span style={{borderBottom: '5px solid #9400D3', borderRadius: '4px'}}>{intl.formatMessage({id: 'Breven School'})}
                </span>
            </h1>
           </div>
           <div style={{margin: '40px 0'}}>
                <p className=' text-gray-600 fw-bold fs-4'>
                    
                {intl.formatMessage({id: 'The'})} <span className='fw-bolder text-dark'>BrevenLAW </span>
                {intl.formatMessage({id: 'compiled all a didactic material to'})}<span className='fw-bolder text-dark'> {intl.formatMessage({id: 'improve your experience'})} </span>{intl.formatMessage({id: 'here on our'})} <span className='fw-bolder text-dark'>{intl.formatMessage({id: 'platform'})} </span>{intl.formatMessage({id: 'and help you a little more on this journey with the LGPD'})}
                </p>
           </div>
        </div>

        <div className='container p-3  mt-5  mx-auto'>
           <div className='row d-flex justify-content-between p-1'>
                <div className='row m-3 col-md-5' style={{backgroundColor: '#F5F5F5'}}>
                    <div className='row d-flex justify-content-between align-items-center text-center'>
                        <div style={{width: '51px', height: '51px'}} className='m-2 col-3 bg-secondary rounded-3'>

                        </div>
                        <div style={{width: '110px', height: '30px'}} className='m-2 col-5 p-1 bg-secondary rounded-2'>
                            <p className={`text-info fw-bolder ${window.screen.width < 768 ? 'text-center p-2' : 'p-1'} fs-8`}>{intl.formatMessage({id: 'In Progress'})}</p>
                        </div>
                    </div>
                    <div className='col-12 mt-2'>
                        <h3>{intl.formatMessage({id: 'Monitoring'})}</h3>
                        <p className='text-gray-600 fw-bold fs-6'>{intl.formatMessage({id: 'Monitoring of implemented actions, helping the verification of the effectiveness of the action'})}</p>
                    </div>
                    <div className='col-12 mb-5'>
                        <div className="progress" style={{backgroundColor: '#D0BDF9'}}>
                            <div style={{width: '30%'}} className="progress-bar bg-info" role="progressbar"   >
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row m-3 col-md-5' style={{backgroundColor: '#F5F5F5'}}>
                    <div className='row d-flex justify-content-between align-items-center text-center'>
                        <div style={{width: '51px', height: '51px', backgroundColor: '#E8FFF3'}} className='m-2 col-3 rounded-3'>

                        </div>
                        <div style={{width: '110px', height: '30px', backgroundColor: '#E8FFF3'}} className='m-2 col-5 p-1  rounded-2'>
                            <p className={`text-success fw-bolder ${window.screen.width < 768 ? 'text-center p-2' : 'p-1'} fs-8`}>{intl.formatMessage({id: 'Complete'})}</p>
                        </div>
                    </div>
                    <div className='col-12 mt-2'>
                        <h3>{intl.formatMessage({id: 'Risk Reduction'})}</h3>
                        <p className='text-gray-600 fw-bold fs-6'>{intl.formatMessage({id: 'Systematic report of the problems found, provided by the platform'})}</p>
                    </div>
                    <div className='col-12 mb-5'>
                        <div className="progress" style={{backgroundColor: '#E8FFF3'}}>
                            <div style={{width: '100%'}} className="progress-bar bg-success" role="progressbar"   >
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row m-3 col-md-5' style={{backgroundColor: '#F5F5F5'}}>
                    <div className='row d-flex justify-content-between align-items-center text-center'>
                        <div style={{width: '51px', height: '51px', backgroundColor: '#FFF5F8'}} className='m-2 col-3  rounded-3'>

                        </div>
                        <div style={{width: '110px', height: '30px', backgroundColor: '#FFF5F8'}} className='m-2 col-5 p-1 rounded-2'>
                            <p className={`text-danger fw-bolder ${window.screen.width < 768 ? 'text-center p-2' : 'p-1'} fs-8`}>{intl.formatMessage({id: 'In Progress'})}</p>
                        </div>
                    </div>
                    <div className='col-12 mt-2'>
                        <h3>{intl.formatMessage({id: 'Risk Degree'})}</h3>
                        <p className='text-gray-600 fw-bold fs-6'>{intl.formatMessage({id: 'Visualization of gaps in the treatment of personal data in practical reports'})}</p>
                    </div>
                    <div className='col-12 mb-5'>
                        <div className="progress" style={{ backgroundColor: '#FBBFCE'}}>
                            <div style={{width: '75%'}} className="progress-bar bg-danger" role="progressbar"   >
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row m-3 col-md-5' style={{backgroundColor: '#F5F5F5'}}>
                    <div className='row d-flex justify-content-between align-items-center text-center'>
                        <div style={{width: '51px', height: '51px', backgroundColor: '#FFF8DD'}} className='m-2 col-3 rounded-3'>

                        </div>
                        <div style={{width: '110px', height: '30px', backgroundColor: '#FFF8DD'}} className='m-2 col-5 p-1 rounded-2'>
                            <p className={`text-primary fw-bolder ${window.screen.width < 768 ? 'text-center p-2' : 'p-1'} fs-8`}>{intl.formatMessage({id: 'In Progress'})}</p>
                        </div>
                    </div>
                    <div className='col-12 mt-2'>
                        <h3>{intl.formatMessage({id: 'Degree of Action'})}</h3>
                        <p className='text-gray-600 fw-bold fs-6'>{intl.formatMessage({id: 'The system provides models, suggestions and action plans to help the manager'})}</p>
                    </div>
                    <div className='col-12 mb-5'>
                        <div className="progress" style={{backgroundColor: '#FFE99B'}}>
                            <div style={{width: '40%'}} className="progress-bar bg-primary" role="progressbar"   >
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row m-3 col-md-5' style={{backgroundColor: '#F5F5F5'}}>
                    <div className='row d-flex justify-content-between align-items-center text-center'>
                        <div style={{width: '51px', height: '51px', backgroundColor: '#F1FAFF'}} className='m-2 col-3 rounded-3'>

                        </div>
                        <div style={{width: '110px', height: '30px', backgroundColor: '#F1FAFF'}} className='m-2 col-5 p-1 rounded-2'>
                            <p className={`text-warning fw-bolder ${window.screen.width < 768 ? 'text-center p-2' : 'p-1'} fs-8`}>{intl.formatMessage({id: 'In Progress'})}</p>
                        </div>
                    </div>
                    <div className='col-12 mt-2'>
                        <h3>{intl.formatMessage({id: 'Crisis Management'})}</h3>
                        <p className='text-gray-600 fw-bold fs-6'>{intl.formatMessage({id: 'Log and record incidents that have occurred to avoid future similar situations'})}</p>
                    </div>
                    <div className='col-12 mb-5'>
                        <div className="progress" style={{backgroundColor: '#A9DEFD'}}>
                            <div style={{width: '90%'}} className="progress-bar bg-warning" role="progressbar"   >
                                
                            </div>
                        </div>
                    </div>
                </div>
                
                
           </div>

        </div>
        
    </div>
   </>
  )
}
export default DashboardFeed;