
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { RouteComponentProps } from 'react-router';
import { storeManagementHolderRequest, riskAnalysisModelStep, riskAnalysisPost, riskGeneratePdf } from '../../../modules/helpers/Api';
import { useIntl } from 'react-intl'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { trataRespota, converteData2, handleDownload } from '../../../modules/helpers/helpers'
import { useLang } from '../../../../_metronic/i18n/Metronici18n'
import { toast } from 'react-toastify';
import $ from "jquery";
import { Alert } from 'react-bootstrap-v5';
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { info } from 'console';
import { finished } from 'stream';
import GriForm from './GriForm';

interface ITask {
  name: string;
  done: boolean;
}

interface IState {
  newTask: string;
  tasks: Array<ITask>;
}

interface MatchParams {
  lang: string;
  token: string;
  step: string;
}

const initialValues = {
  email: '',
  name: '',
  rights: '',
  description: '',
}
 
const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

const Step2: React.FC<RouteComponentProps<MatchParams>> = (props, IState) => {

  const [loading, setLoading] = useState(false)
  const lang = useLang()
  const intl = useIntl();
  const urlLang = props.match.params.lang;

  const [token, setToken] = useState<string>('');

  const [step, setStep] = useState('step-2');

  const [riskAnaliseData, setRiskAnaliseData] = useState({ 'uuid': '', 'title': '', 'description': '', 'themes': [], 'owner_name': '', 'owner_email': '' })

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalReqAtention, setOpenModalReqAtention] = useState(false);
  const [openModalActionAtention, setOpenModalActionAtention] = useState(false);
  
  const [responseData, setResponseData] = useState([]);

  const [openModalRelatorio, setOpenModalRelatorio] = useState(false);

  const [openModalNotification, setOpenModalNotification] = useState(false);
  const [analisyNotification, setAnalisyNotification] = useState('');

  const [hasError, setHasError] = useState('');

  const [analisyResp, setAnalisyResp] = useState([]);
  const [resp, setResp] = useState([]);

  const [riskCollapse, setRiskCollapse] = useState('');
  const [actionCollapse, setActionCollapse] = useState('');

  const [loadingRelatorio, setLoadingRelatorio] = useState(false)

  const [locked, setLocked] = useState<string>('');
  const [locked2, setLocked2] = useState<string>('');

  const [closeStepOne, setCloseStepOne] = useState(false)
  
  if (['pt', 'en'].includes(urlLang) && lang !== urlLang) {
    // setLanguage(urlLang)
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: urlLang }))
    window.location.reload()
  }

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: 750,
    maxHeight: 600,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: 1,
    boxShadow: 24,
    outline: 'none'
  };

  useEffect(() => {

    $(document).ready(function () {
      mountStep3RespData()

      $(".form-imput-percent").keyup(function (e){
                
        var charCode = (e.which) ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
       
      });

    });

  }, [riskAnaliseData]);

  const registrationSchema = Yup.object().shape({

  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      handleSubmitEtapa2();

    },
  })

  const formik2 = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(`${intl.formatMessage({ id: 'O valor não é um endereço de e-mail válido' })}`)
        .max(50, `${intl.formatMessage({ id: 'Máximo de 50 símbolos' })}`)
        .required(`${intl.formatMessage({ id: 'E-mail' })} ${intl.formatMessage({ id: 'is required' })}`),
    }),
    onSubmit: (values, { setStatus, setSubmitting }) => {

      alert(values.email + ' - Envio por e-mail indisponvível no momento!')

    },
  })

  function gerarRelatorioPdf() {
    const get = riskGeneratePdf(token);
    get.then((res)=>{

      if(res.statusText === 'OK'){
        handleDownload(res, riskAnaliseData.title, 'pdf');
      } else {
        alert('desculpe, um erro ocorreu!');
      }

    }).catch((error)=>{
      if (error.response.status == 404) {
        
        let file = new Blob([error.response.data], {type: 'application/json'});

        file.text()
        .then(value => {

          let object = JSON.parse(value);

          alert(object.message);

        })
        .catch(error => {
          alert('Desculpe um erro ocorreu')
        });

      } else {
        alert('Indiponível no momento!')
      }
    })
  }

  function SendEmalPdf(){

  }

  function getDataActions(risk:any) {

    let cks:any = [];

    // id={`chk_action_${action.action}`}

    // id={`ktformrisk${risk.uuid}actions`}

    $(`#ktformrisk${risk}actions input:checkbox`).each(function(){"input:checkbox[name=type]:checked"
      let name = $(this).attr('name');
      cks.push( {action: name, done: $(`#ktformrisk${risk}actions input:checkbox[name=${name}]:checked`).is(":checked")  });
    });


    return cks;
  }

  function mountData2(step: any) {

    let checkFields = true;
    let checkAction = true;

    var dataResponse = $('#kt_form_ram').serializeArray().reduce(function (obj: any, item) {

      if (!item.value) {
        checkFields = false;
      }

      obj[item.name] = item.value;
      return obj;

    }, {});

    let finalData: any = [];

    riskAnaliseData.themes.forEach((theme: any) => {

      let standards: any = theme['standards'];

      standards.forEach((standard: any) => {

        let risks: any = standard['risks'];

        risks.forEach((risk: any) => {

          if (step == 'step-2') {

            if ( dataResponse['prob_' + risk.uuid] && dataResponse['impact_' + risk.uuid] && dataResponse['nivel_value_' + risk.uuid] ) {
              finalData.push({
                'standard': standard.uuid,
                'risk': risk.uuid,
                'prob': dataResponse['prob_' + risk.uuid],
                'impact': dataResponse['impact_' + risk.uuid],
                'nivel': dataResponse['nivel_value_' + risk.uuid]
                
              });
            }

          } else if (step == 'step-3') {

            if ( dataResponse['treatment_' + risk.uuid] && ( dataResponse['mitigate_' + risk.uuid] != "") && ( dataResponse['providence_' + risk.uuid] != "") ) {
              
              
              
              finalData.push({
                'treatment': dataResponse['treatment_' + risk.uuid],
                'risk': risk.uuid,
                'mitigate': dataResponse['mitigate_' + risk.uuid],
                'providence': dataResponse['providence_' + risk.uuid],
                'actions': getDataActions(risk.uuid)
              });

            } else {

              let actionsList = getDataActions(risk.uuid)
              if ( actionsList && actionsList.length >= 1) {
                checkRiskCollapse(risk.uuid)
                checkAction = false;
              }
            

              
            }
            

        

              // if (risk['actions']) {

              //   risk['actions'].forEach((action: any) => {
  
              //     if ( dataResponse['treatment_' + action.uuid] && dataResponse['mitigate_' + action.uuid] && dataResponse['providence_' + action.uuid] ) {
              //       finalData.push({
              //         'action': action.uuid,
              //         'treatment': dataResponse['treatment_' + action.uuid],
              //         'mitigate': dataResponse['mitigate_' + action.uuid],
              //         'providence': dataResponse['providence_' + action.uuid]
              //       });
              //     }
  
              //   });

          }

        });

      });

    });

    if (!checkFields && closeStepOne) {
      setOpenModalReqAtention(true)
      return;
    }

    if (!checkAction) {
      setOpenModalActionAtention(true)
      return;
    }

    return finalData;
  }

  function getProbText(value: number) {
    if (value === 1) {
      return 'Muito Baixa'
    } else if (value === 2) {
      return 'Baixa'
    } else if (value === 5) {
      return 'Média'
    } else if (value === 8) {
      return 'Alta'
    } else if (value === 10) {
      return 'Muito Alta'
    } else {
      return '???'
    }
  }

  function geImpacto(value: number) {
    if (value === 1) {
      return 'Muito Baixa'
    } else if (value === 2) {
      return 'Baixa'
    } else if (value === 5) {
      return 'Média'
    } else if (value === 8) {
      return 'Alta'
    } else if (value === 10) {
      return 'Muito Alta'
    } else {
      return '???'
    }
  }

  function mountRespData(data: any) {

    data.forEach((resp: any) => {

      if (locked) {

        $(`.prob_${resp.risk}`).html(getProbText(resp.prob));
        $(`.impact_${resp.risk}`).html(geImpacto(resp.impact));

        calcNivel(resp.risk, resp.prob, resp.impact);

      } else {

        $(`#prob_${resp.risk}`).val(resp.prob).trigger('change');
        $(`#impact_${resp.risk}`).val(resp.impact).trigger('change');

        calcNivel(resp.risk);
      }





    });
    // setAnalisyResp([])
  }

  function addChkRiscActionForm(item:any, risk_uuid:any) {



    if ( $(`#ktformrisk${risk_uuid}actions #chk_${risk_uuid}_${item.action}`).text() ) {
      alert('Essa ação já está presente na lista!');
      return;
    }


    
    $(`<div class=' d-flex text-dark mb-4' id='chk_${risk_uuid}_${item.action}'>

      ${!locked2?
        `<span class='me-2'>
          <img src='/media/icons/duotune/general/gen034.svg' class='btn-del-chk ' data-chk-action='chk_${risk_uuid}_${item.action}' />
        </span>`
        :''}

        <input
          id='2chk_${risk_uuid}_${item.action}'
          class='form-check-input me-2'
          type='checkbox'
          name='${item.action}'
          style="min-width:22px;margin-top:1px;"
          ${item.done?'checked':''}
          ${![null, ''].includes(locked2) ? 'disabled' : ''}
        />
  
        <label class='' for='2chk_${risk_uuid}_${item.action}' style="cursor:pointer;">${item.title}</label>

      <div>`
    )
    .on('click', '.btn-del-chk', function(){
      let btnChk = $(this).data("chk-action")

      $(`#${btnChk}`).remove();
      $(`#action_${risk_uuid} option[value='${item.action}']`).show();

    })
    .appendTo(`#ktformrisk${risk_uuid}actions`);

    $(`#action_${risk_uuid} option[value='${item.action}']`).hide();
    

  }

  function mountStep3RespData() {

    riskAnaliseData.themes.forEach((theme: any) => {
      theme['standards'].forEach((standard: any) => {    
        standard['risks'].forEach((risk: any) => {

          if ( risk.resp_data ) {

            $(`#prob_${risk.resp_data.model_risk_uuid}`).val(risk.resp_data.prob).trigger('change');
            $(`#impact_${risk.resp_data.model_risk_uuid}`).val(risk.resp_data.impact).trigger('change');
            calcNivel(risk.resp_data.model_risk_uuid, risk.resp_data.prob, risk.resp_data.impact);

          } 

          if ( risk.resp_management ) {

            $(`#ktformrisk${risk.uuid}actions`).html('')

            if (risk.resp_management.actions.length>0) {
              risk.resp_management.actions.forEach( (item:any) => {
                addChkRiscActionForm(item, risk.uuid)
              });
            } else if (locked2) {
              $(`#ktformrisk${risk.uuid}actions`).html('Nenhuma ação foi adicionada!')
            }
                        
            $(`#treatment_${risk.uuid}`).val(risk.resp_management.treatment);

            checkRiskTreatment(null, risk.uuid, risk.resp_management.treatment);

            $(`#mitigate_${risk.uuid}`).val(risk.resp_management.mitigate);
            $(`#providence_${risk.uuid}`).val( risk.resp_management.providence )

            calcMitigationEnd(risk.uuid)

            
          }



          // if (risk.actions) {
          //   risk.actions.forEach((action: any) => {

          //     if ( action.resp_data ) {
  
          //       $(`#treatment_${action.uuid}`).val(action.resp_data.treatment).trigger('change');
          //       $(`#mitigate_${action.uuid}`).val(action.resp_data.mitigate).trigger('change');
          //       $(`#providence_${action.uuid}`).val( action.resp_data.providence )
  
          //       calcMitigationEnd(risk.uuid)
          //     }
              
          //   });
          // }
          

        });
      });
    });

    // setAnalisyResp([])
  }



  function handleRiscAnalisyStep() {

    setLoading(true);

    const get = riskAnalysisModelStep(token, step);

    get.then((res) => {

      if (res.data.status === 'success') {

        setRiskAnaliseData(res.data.data)
  
        if (res.data.data.finish_at) {
          setLocked(res.data.data.finish_at)
        }
  
        if (res.data.data.finish_at_2) {
          setLocked2(res.data.data.finish_at_2)
        }

      }
      else if (res.data.status === 'error') {
        setHasError(res.data.message);
      }

    }).catch((error) => {
      // console.log(error)

      if (error.response) {
        setHasError(error.response.data.message);
      } else {
        setHasError('Desculpe, não foi possivel recuperar as informações, tente novamente mais tarde...')
      }

    }).finally(() => {
      setLoading(false)
    });
  }


  function between(x: any, min: any, max: any) {
    return x >= min && x <= max;
  }

  function calcNivel(el_id: any, v_prob: any = null, v_impact: any = null) {

    let prob = 0;
    let impact = 0;
    let nivel = 0;
    let color = 'secondary';
    let text = '- - -';

    if (v_prob) {
      prob = v_prob;
    } else {
      let selectedProb = $(`#prob_${el_id} :selected`);
      if (selectedProb) {
        prob = Number(selectedProb.val());
      }
    }

    if (v_impact) {
      impact = v_impact
    } else {
      let selectedImpact = $(`#impact_${el_id} :selected`);
      if (selectedImpact) {
        impact = Number(selectedImpact.val());
      }
    }


    $(`#nivel_color_${el_id}`).removeClass('bg-ram');
    $(`#nivel_color_${el_id}`).removeClass('bg-ram-rb');
    $(`#nivel_color_${el_id}`).removeClass('bg-ram-rm');
    $(`#nivel_color_${el_id}`).removeClass('bg-ram-ra');
    $(`#nivel_color_${el_id}`).removeClass('bg-ram-re');

    $(`#nivel_color2_${el_id}`).removeClass('bg-ram');
    $(`#nivel_color2_${el_id}`).removeClass('bg-ram-rb');
    $(`#nivel_color2_${el_id}`).removeClass('bg-ram-rm');
    $(`#nivel_color2_${el_id}`).removeClass('bg-ram-ra');
    $(`#nivel_color2_${el_id}`).removeClass('bg-ram-re');

    if (prob != 0 && impact != 0) {

      nivel = prob * impact;

      if (between(nivel, 0, 9.99)) {
        color = 'ram-rb';
        text = 'RB';
      }
      else if (between(nivel, 10, 39.99)) {
        color = 'ram-rm';
        text = 'RM';
      }
      else if (between(nivel, 40, 79.99)) {
        color = 'ram-ra';
        text = 'RA';
      }
      else if (between(nivel, 80, 100)) {
        color = 'ram-re';
        text = 'RE';
      } else {
        color = 'ram';
        text = '- - -';
      }


      $(`#nivel_color_${el_id}`).addClass(`bg-${color}`);
      $(`#nivel_color2_${el_id}`).addClass(`bg-${color}`);

      $(`#nivel_value_${el_id}`).val(nivel);
      $(`#nivel_text_${el_id}`).val(`${nivel} - ${text} `);

    } else {
      $(`#nivel_color_${el_id}`).addClass('bg-ram');
      $(`#nivel_color2_${el_id}`).addClass('bg-ram');

      $(`#nivel_value_${el_id}`).val(0);
      $(`#nivel_text_${el_id}`).val('- - -');
    }

  }

  function handleSubmitEtapa2() {
    setStep('step-2')
    let finalData = mountData2('step-2');
    if (finalData) {
      setOpenModalConfirm(true)
      setResponseData(finalData);
    }
  }

  function checkRiskTreatment(event:any, risk_uuid:any, value:any = null)
  {

    let selectdValue = null;
    
    if (event) {
    
      let selected = $(`select#${event.target.id} option:selected`)
      if (selected && selected.val()) {

        selectdValue = selected.val();

      }
    } else if (value) {
      selectdValue = value;
    }

    if ( selectdValue ) {

      let risk = $(`#mitigate_${risk_uuid}`);

      if ( selectdValue == 1 ) {
        risk.val(100);
        risk.prop('readonly', true);
  
      } else if ( selectdValue == 4 ) {
        risk.val(0);
        risk.prop('readonly', true);
  
      } else {
        risk.val('');
        risk.prop('readonly', false);
      }

      // calcMitigationEnd(risk_uuid)

    }

    
    
  }
  function calcMitigationEnd(risk_uuid: any) {

    // var valueArray = $(`.calc-mity-${risk_uuid}`).map(function () {
    //   let value = $(this).val();
    //   return value ? value : 0;
    // }).get();

    // let sum = 0;
    // valueArray.forEach((num: any) => {
    //   sum += Number(num);
    // })

    // sum = sum / valueArray.length;
    // sum = Number(sum.toFixed(2))

    // $(`#risk_mity_end_${risk_uuid}`).val(String(sum))



    // let mitigation = Number($(`#mitigate_${risk_uuid}`).val());

    // let nivel_value = Number($(`#nivel_value_${risk_uuid}`).val());

    // let calc = (sum / 100) * nivel_value

    // calc = Number((nivel_value - calc).toFixed(2));

    // $(`#risk_nivel_end_${risk_uuid}`).val( calc )

    let nivel_value = Number($(`#nivel_value_${risk_uuid}`).val());

    let mitigation = Number($(`#mitigate_${risk_uuid}`).val());

    let calc = (mitigation / 100) * nivel_value

    calc = Number((nivel_value - calc).toFixed(2));

    $(`#risk_nivel_end_${risk_uuid}`).val( calc )

  }

  function checkRiskCollapse(key:any) {
    setRiskCollapse(`kt_collapse_${key}`)
  }

  function letStart() {
    setToken(props.match.params.token);
  }
 
  return (

    <div className='d-flex flex-column bg-gray-middle flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/logos/background.png')})`,
        backgroundPosition: 'center',
        backgroundColor: '#1E1E2D',
        backgroundSize: 'cover'
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-5 '>

        <div className=' bg-white rounded-5 shadow-sm p-6 p-lg-15 row col-sm-12 col-md-12 col-lg-10' style={{ maxWidth: '980px' }}>

          <div className=' text-center'>
            <h1 className='text-dark'>{intl.formatMessage({ id: 'Risk Analysis Management' })}</h1>
            {/* <div className='text-dark fw-bold fs-4'>Company Example Name LTDA.</div> */}
          </div>



          <div className='border-bottom border-gray-300 w-100 mb-6' ></div>

          {
            loading ?
              <div className='text-center'>
                <div><h4 >Aguarde</h4></div>
                <div>
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Processando <span className='spinner-border spinner-border-sm align-middle mr-2'></span>
                  </span>
                </div>
              </div>

              :
              <div>
                {hasError ?
                  <div className='text-center'>
                    <div>{hasError}</div>
                  </div>

                  :

                  <div>

                    {!token ?
                      <div className='text-center'>

                        <p>Seja bem vindo, vamos começar?</p>

                        <div className='card-footer d-flex justify-content-center pt-4'>
                          <button type='submit' className='btn btn-primary text-dark btn-sm' disabled={loading} onClick={() => letStart()}>
                            {!loading && 'Iniciar'}
                            {loading && (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                Processando...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>

                      :

                      <GriForm token={token} edit={true}/>

                    }

                  </div>

                }
              </div>
          }

        </div>

      </div>

    </div>

  )
}
export default Step2;