import react, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../../helpers';
import { getCompaniesToCalculator } from '../../../../../../app/modules/helpers/Api';
import { Load } from '../../../spinner/Spinner';
import {UserModel} from '../../../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import { useIntl } from 'react-intl'
import $ from "jquery";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IModalCalculatorCompanyProps {
  showModal: boolean;
  title: string;
  onClose: () => void;
  onAction: (id: any, form: string) => void;
}

const ModalCalculatorCompany: React.FC<IModalCalculatorCompanyProps> = ({...props}) => {

  const [company, setCompany] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();   

  const [id, setId] = useState<any>();
  const [name, setName] = useState('');
  const [error, setError] = useState('');
    
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  useEffect(()=>{
    setLoading(true);
    setId('');
    setName('');
    if(user.roles !== 'free'){
      const get = getCompaniesToCalculator();
      get.then((res)=>{
        setCompany(res.data.data.data);
      }).catch((error)=>alert(error))
      .finally(()=>setLoading(false));
    }
  },[]);

  function HandleAnswer(answer: any){
    // let error = false;

    // if(answer === 'yes' && name === ''){
    //   setError(`${intl.formatMessage({id: 'Select a company'})}` );
    //   error = true;
    // } 
    
    // if(!error) {
    //   if(answer === 'no'){
    //     props.onAction('', '');
    //   } else {
    //     props.onAction(id, name);
    //   }
    //   onClear();
    // }

    props.onAction(id, name);
    onClear();
  }

  function onClear(){
    setId('');
    setName('');
    $('.radio-opt-select').prop('checked', false);    
  }

  return(
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-70px d-flex flex-row justify-content-between align-items-center w-100'>
          <div className='d-flex flex-row align-items-center'>
              <div className="d-flex bg-light p-3 align-items-center justify-content-center rounded me-4">
                <KTSVG
                  path="/media/icons/duotune/art/art002.svg"
                  className="svg-icon svg-icon-2x svg-icon-primary"
                />
              </div>
              <span className="modal-title fw-bold fs-3">{props.title}</span>
          </div>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>[props.onClose(), onClear()]}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className='px-5 mb-5 mt-3 text-left' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 350}}>
          
          { company && company.length>0 ?
          <div>

            <label className='col-lg-12 m-0 p-0 col-form-label fw-bold fs-5'>
            {intl.formatMessage({id: 'Do you want to add a Company?'})}
            </label>
            {error && (
              <div className='fv-plugins-message-container text-danger fs-6 mt-1 mb-3'>
                <div className='fv-help-block'>
                  <span role='alert'>{error}</span>
                </div>
              </div>
            )}
            {
              loading?
              <Load/>
              :
              <>
              {
                company.map((item: any) => 
                  <label key={item.id} role={'button'} className='alert mt-5 mb-3 p-5 alert-dismissible bg-light-primary border border-dashed border-primary d-flex justify-content-between flex-sm-row form-check form-check-custom form-check-solid align-items-center'>
                    <div className='d-flex'>
                      <span className="me-4 mb-5 mb-sm-0 d-flex align-items-center mx-5 me-10">
                        <i className="fas fa-arrow-right fs-3 text-dark"></i>
                      </span>
                      <div className="d-flex flex-column text-primary justify-content-center">
                        <h5 className='m-0 p-0'>{item.fantasy_name}</h5>
                      </div>
                    </div>
                    <div>
                      <input
                        type='radio'
                        name='form'
                        className='form-check-input radio-opt-select'
                        onChange={()=>[setId(item.id), setName(item.fantasy_name), setError('')]}
                      />
                    </div>                  
                  </label>
                )
              }
              </>
            }

</div>
          :
          <div className='text-center mt-12 mb-12'> <h6>{intl.formatMessage({id: 'No active companies found'})}</h6> </div>
          }

          
        </div>

        <div style={{margin: '14px'}}> 
          <strong>
            {intl.formatMessage({id: 'Selected'})}: {
              name ?
                <span onClick={()=>[onClear()]}>
                  <KTSVG path="/media/icons/duotune/general/gen034.svg" className="svg-icon svg-icon-1x svg-icon-danger mr-4"/>
                  {name}
                </span>
              :
                '- - -'
            }
          </strong>
        </div>

        
        <div className='d-flex flex-row w-100 justify-content-end p-5'>
          {/* <button type='button' onClick={()=>[setId(''), setName(''), HandleAnswer('no')]} className='btn btn-md btn-secondary me-3'>
          {intl.formatMessage({id: 'No'})}
          </button> */}
          <button type='button' onClick={()=>HandleAnswer('yes')} className='btn btn-md btn-primary text-dark'>
          {intl.formatMessage({id: 'Continue'})}
          </button>
        </div>
      </Box>
    </Modal>
  )
}
export default ModalCalculatorCompany;