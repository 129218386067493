/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { deleteQuestions, getQuestions, defaultPaginationList } from '../../../../../app/modules/helpers/Api';
import { converteData } from '../../../../../app/modules/helpers/helpers';
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import ModalMessage from '../../modals/modalMessage';
import { Load } from '../../spinner/Spinner';
import Warning from '../../warning/Warning';
import { useIntl } from 'react-intl'
import Pagination from  '../../../../helpers/components/CustomPagination/CustomPagination'


let searchData = {
  title: '',
  code: '',
  page: 1,
  perpage: 10,
  model: '',
  category_name: ''
}

type Props = {
  create: (action: boolean) => void;
  edition: (action: boolean, id: any) => void;
}

const QuestionsList: React.FC<Props> = ({...props}) => {

  const [loading2, setLoading2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataQuestion, setDataQuestion] = useState<any>([]);
  const [id, setId] = useState('');
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const intl = useIntl();   
  const [titleFilter, setTitleFilter ]= useState<any>('');
  const [modelFilter, setModelFilter] = useState<any>('');
  const [categoryFilter, setCategoryFilter] = useState<any> ('');
  
  searchData.model = modelFilter
  searchData.title = titleFilter
  searchData.category_name = categoryFilter
  useEffect(()=>{
    handleGetList(null)
  },[loading2]);

  function handleGetList(data:null) {
    
    setLoading(true);
    const get = defaultPaginationList('questions', searchData)
    get.then((res)=>{
      setDataQuestion(res.data.data);
      
    }).catch((error)=>{
      toast.error(error.response.data.message)
    })
    .finally(()=>[setLoading(false), setLoading2]);
    
  }

  function handleDelete(id: any) {
    const del = deleteQuestions(id);
    del.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        setOpenModalDelete(false);
      }
      if(res.data.status === 'error'){
        toast.success(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      } else {
        toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}`);
      }
    }).finally(()=>setLoading2(true))
  }

  return (
    <>
      <h3 className='card-title align-items-start flex-column mb-3'>
        <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: 'Questions List'})}</span>
      </h3>
      {
        loading?
        <Load/>
        :
        <div className={`card card mb-5 mb-xl-10`}>
          <ModalMessage
            message={intl.formatMessage({id: 'Are you sure you want to delete this item?'})}
            title={intl.formatMessage({id: 'Attention'})}
            showModal={openModalDelete}
            onClose={()=>setOpenModalDelete(false)}
            onAction={()=>handleDelete(id)}
          />
          <div className='row card-header border-0 pt-5 d-flex align-items-center justify-content-end'>
            <div className='col-md-4'>
              <input
                    type="text"
                    className="form-control form-control-solid fs-6 rounded-3"
                    placeholder={`${intl.formatMessage({id: 'Search a form'})}`}
                    autoComplete='off'
                    style={{height: '40px'}}
                    value={titleFilter}
                    onChange={(e)=>[setTitleFilter(e.target.value)]}
                    onKeyDown={(e)=>e.which === 13 ? handleGetList(null) : '' }
                  
                />
            </div>
            {/* <div className='col-7 d-flex justify-content-end align-items-center'> */}
              <div className='col-md-3'>
                  <select className='form-select form-control form-control-solid fs-6' 
                    value={modelFilter}
                    onChange={(e)=>[setModelFilter(e.target.value)]}
                    style={{height: '40px'}}
                  >
                    <option value='' >{intl.formatMessage({id: 'All Models'})}</option> 
                    <option value='1' >{intl.formatMessage({id: 'Maturity analysis'})}</option>
                    <option value='2'>{intl.formatMessage({id: 'Scored'})}</option>
                    <option value='3' >{intl.formatMessage({id: 'Not scored'})}</option>
                    <option value='4' >{intl.formatMessage({id: 'Data Mapping'})}</option>
                  </select>
              </div>
              <div className='col-md-5 d-flex justify-content-between align-items-center'>
                <button 
                type='button' 
                className='btn bg-hover-dark text-white' 
                disabled={loading}
                onClick={()=>handleGetList(null)}
                style={{width: '125px',height: '40px',marginRight: '5px', background: '#65638E', borderRadius: 13}}
                >
                  {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Search'})}</span>}
                        {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}       
                </button>
                <button
                  className='btn btn-sm btn-dark rounded-3 p-0'
                  onClick={()=>props.create(true)}
                  style={{height: '38px', width: '125px', marginRight: window.screen.width > 767 ? '13px' : '0px'}}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title={intl.formatMessage({id: 'Click to add a question'})}
                >
                  <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  {intl.formatMessage({id: 'New question'})}
                </button>

              </div>         
             
          </div>
          
          <div className='card-body py-3'>
            {
              dataQuestion.length !== 0?
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bolder text-dark'>
                      <th className='w-400px'>{intl.formatMessage({id: 'TITLE'})}</th>
                      <th className='w-140px'>{intl.formatMessage({id: 'MODEL'})}</th>
                      <th className='w-120px'>{intl.formatMessage({id: 'CATEGORY'})}</th>
                      <th className='w-120px'>IN FORMS</th>
                      <th className='w-120px'>{intl.formatMessage({id: 'DATE'})}</th>
                      <th className='w-100px text-center'>{intl.formatMessage({id: 'ACTIONS'})}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dataQuestion.data.map((item: any, index: any)=>
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-gray-700 fw-bold fs-6'>
                                {item.title}
                              </span>
                              {/* {item.title.split('',50)}... */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className="badge badge-light-info">
                              {                                
                                item.model === 1 ? `${intl.formatMessage({id: 'Maturity analysis'})}` :
                                item.model === 2 ? `${intl.formatMessage({id: 'Scored'})}` :
                                item.model === 3 ? `${intl.formatMessage({id: 'Not scored'})}` :
                                item.model === 4 ? `${intl.formatMessage({id: 'Data Mapping'})}` :
                                `${intl.formatMessage({id: 'Unknown'})}`
                              }
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className='text-end'>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-gray-700 fw-bold fs-6'>
                                {item.category_name ? item.category_name : '- - -'}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className='text-end'>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-gray-700 fw-bold fs-6'>
                                {item.in_forms.length <10 ? ('0'+item.in_forms.length) : item.in_forms.length}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className='text-end'>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-gray-700 fw-bold fs-6'>
                                {converteData(item.created_at)}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-center flex-shrink-0'>
                            <button
                              className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                              onClick={()=>props.edition(true, item.id)}
                            >
                            <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _edit 2 outline_.svg')} style={{marginRight: '10px'}} />                             </button>
                            <button
                              className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm'
                              onClick={()=>[setId(item.id), setOpenModalDelete(true)]}
                            >
                             <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _trash_.svg')} style={{marginRight: '10px'}} />
                            </button>
                          </div>
                        </td>
                      </tr>
                      )
                    }
                  </tbody>
                </table><Pagination data={dataQuestion} onSearch={handleGetList} searchData={searchData}/>
              </div>
              :
              <Warning message={intl.formatMessage({id: 'Sorry, no content to show yet'})} />
            }
          </div>
        </div>
      }
    </>
  )
}

export {QuestionsList}
