import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import { editionFormsList, editionSaveFormsList, questionFormCreate } from '../../../../../app/modules/helpers/Api';
import ModalMessageConfirm from '../../modals/modalMessageConfirm';
import { Load } from '../../spinner/Spinner';
import QuestionAdd from './cards/questionAdd';
import QuestionButton from './cards/questionButton';
import QuestionButtonSave from './cards/questionButtonSave';
import QuestionList from './cards/questionList'
import { trataRespota, copyText } from '../../../../../app/modules/helpers/helpers'
import { KTSVG } from '../../../../helpers';
import { useIntl } from 'react-intl'
import {UserModel} from '../../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'

type Props = {
	edition: (action: boolean) => void;
	id: any;
	model: any
}

const EditionForm: React.FC<Props> = ({ ...val }) => {

	const [data, setData] = useState<any>({});
	const [values, setValues] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [openModalSubmit, setOpenModalSubmit] = useState(false);
	const [data2, setData2] = useState<any>([]);
	const [openModalAddQuestion, setOpenModalAddQuestion] = useState(false);
	const [openModalSubmit2, setOpenModalSubmit2] = useState(false);
	const [nextForm, setNextForm] = useState('');
	const intl = useIntl();
	const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

	const [changeForm, setChangeForm] = useState(false);

	const schema = Yup.object().shape({
		name: Yup.string()
			.required(`${intl.formatMessage({ id: 'Name' })} ${intl.formatMessage({ id: 'is required' })}`),
		score: Yup.string()
			.required(`${intl.formatMessage({ id: 'Score' })} ${intl.formatMessage({ id: 'is required' })}`),
		status: Yup.string()
			.required(`${intl.formatMessage({ id: 'Status' })} ${intl.formatMessage({ id: 'is required' })}`),
		phase: Yup.string()
			.required(`${intl.formatMessage({ id: 'Phase' })} ${intl.formatMessage({ id: 'is required' })}`),
	})
	useEffect(() => {
		setLoading2(true);
		handleFormData()
	}, [val.id])

	const initialValues = {
		id: data.id,
		name: data.name,
		score: data.model,
		status: data.status,
		phase: data.phase,
		final_sentence: data.final_sentence,
		next_form: data.next_form,
		code: data.code,
		is_public: data.is_public
	}

	function handleFormData() {

		const get = editionFormsList(val.id);
		get.then((res) => {

			if (res.data.status == 'success') {
				setData(res.data.data.question);
				setData2(res.data.data.question.question_list);
			}

		}).catch((error) => {
			if (error.response) {
				toast.error(error.response.message);
			}
		}).finally(() => setLoading2(false));

	}

	function save(values: any) {

		const data = {
			id: values.id,
			name: values.name,
			model: parseInt(values.score),
			status: parseInt(values.status),
			phase: parseInt(values.phase),
			next_form: values.next_form,
			final_sentence: values.final_sentence,
			is_public: values.is_public
		}

		const save = editionSaveFormsList(val.id, data);
		save.then((res) => {
			if (res.data.status === 'success') {
				toast.success(res.data.message);
				setOpenModalSubmit(false);
				val.edition(false);
			}
			if (res.data.status === 'error') {
				toast.error(res.data.message);
			}
		}).catch((error) => {
			trataRespota('error', error);
			// if(error.reponse){
			// 	toast.error(error.response.message)
			// } else {
			// 	toast.error('Sorry, an error occurred!');	
			// }
		})
	}

	// -------------------------

	function cancelFormEdition() {
		setChangeForm(false);
		handleFormData();
	}



	function addQuestion(question: any) {
		setData2([...data2, question])
	}

	function removeQuestion(question_index: any) {
		data2.splice(question_index, 1);
	}

	function removeAnswer(question_index: any, answer_index: any) {
		data2[question_index].answer_list.splice(answer_index, 1);
	}

	function addAnswer(question_index: any, answer: any) {

		// if(data2[question_index].answer_list[0].answer_name === undefined){
		// 	data2[question_index].answer_list[0] = answer;
		// } else {
		data2[question_index].answer_list.push(answer);
		// }
	}

	function saveQuestion() {
		const data = {
			prescription: val.id,
			answers_list: data2
		}

		setLoading(true)
		const create = questionFormCreate(data);
		create.then((res) => {
			if (res.data.status === 'success') {
				setChangeForm(false)
				toast.success(res.data.message);
			}
			if (res.data.status === 'error') {
				toast.error(res.data.message);
			}
		}).catch((error) => {
			if (error.reponse) {
				toast.error(error.response.message)
			} else {
				toast.error(`${intl.formatMessage({ id: 'Sorry, an error occurred' })}`);
			}
		}).finally(() => {
			setOpenModalSubmit2(false);
			setLoading(false)
		});
	}

	return (
		<>
			<ModalMessageConfirm
				message={intl.formatMessage({ id: 'Are you sure you want to edition this item?' })}
				title={intl.formatMessage({ id: 'Attention' })}
				showModal={openModalSubmit}
				onClose={() => setOpenModalSubmit(false)}
				onAction={() => save(values)}
			/>

			<QuestionAdd
				title={intl.formatMessage({ id: 'New question' })}
				model={val.model}
				id={val.id}
				showModal={openModalAddQuestion}
				onClose={() => setOpenModalAddQuestion(false)}
				onAction={addQuestion}
				onGetForm={handleFormData}
			/>

			<ModalMessageConfirm
				message={intl.formatMessage({ id: 'Are you sure you want to save this item?' })}
				title={intl.formatMessage({ id: 'Attention' })}
				showModal={openModalSubmit2}
				onClose={() => setOpenModalSubmit2(false)}
				onAction={() => saveQuestion()}
			/>
			{
				loading2 ?
					<Load />
					:
					<>
						<div className='card mb-5 mb-xl-10'>
							<div className='collapse show'>
								<Formik
									initialValues={initialValues}
									validationSchema={schema}
									onSubmit={(values) => {
										setValues(values);
										setOpenModalSubmit(true)

									}}
								>
									{props => (
										<div>

											{!changeForm ?

												<div className='card-body border-top px-5 py-5'>

													<div className='d-flex justify-content-end mb-3'>

														<button onClick={() => val.edition(false)} type='button' className='btn btn-sm btn-secondary p-3'>
															{/* <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark'/> */}
															{intl.formatMessage({ id: 'Back' })}
														</button>

														<button onClick={() => setChangeForm(true)} type='button' className='btn btn-sm btn-primary text-dark p-3 mx-2'>
															{/* <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark'/> */}
															{intl.formatMessage({ id: 'Edit' })}
														</button>

													</div>

													<div>
														<label className='form-label fw-bolder text-dark fs-3 mb-4'>{props.values.name}</label>
													</div>

													<div className='mb-2 fv-row '>
														<div className='col-lg-12'>

															<div className='row'>
																<div className='col-md-4 fv-row mb-3'>
																	<label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Code' })}</label>
																	<div className='position-relative mb-3'>
																		<div className='d-flex justify-content-between w-100'>
																			<span className='bg-light-success bg-hover-success text-hover-white p-2 rounded'
																				onClick={() => [copyText(props.values.code, intl)]}
																			>{props.values.code}</span>
																		</div>
																	</div>
																</div>

																<div className='col-md-4 fv-row mb-3'>
																	<label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Type' })}</label>
																	<div className='position-relative mb-3'>
																		<div className='d-flex justify-content-between w-100'>
																			<span className="badge badge-light-info  w-100">
																				{
																					props.values.score === 1 ? `${intl.formatMessage({ id: 'Gap Analysis' })}` :
																						props.values.score === 2 ? `${intl.formatMessage({ id: 'Scored' })}` :
																							props.values.score === 3 ? `${intl.formatMessage({ id: 'Not scored' })}` :
																								props.values.score === 4 ? `${intl.formatMessage({ id: 'Data Mapping' })}` :
																									'Desconhecido'
																				}
																			</span>

																			{props.values.is_public ?
																				<span className="badge badge-light-success mx-2  w-100">Public</span>
																				:
																				null
																			}

																		</div>
																	</div>

																</div>

																<div className='col-md-4 fv-row mb-3'>
																	<label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Status' })}</label>
																	<div className='position-relative mb-3'>
																		<div className='d-flex justify-content-between w-100'>
																			{props.values.status == '' ? 'Não informado'
																				: props.values.status == '0' ? 'Inativo'
																					: props.values.status == '1' ? 'Ativo'
																						: props.values.status == '2' ? 'Teste'
																							: 'Desconhecido'
																			}
																		</div>
																	</div>
																</div>



																<div className='col-lg-3 fv-row mb-3'>
																	<label className='form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'Phase' })}</label>
																	<div className='position-relative mb-3'>
																		<div className='d-flex justify-content-between w-100'>
																			{props.values.phase == '' ? 'Não informado'
																				: props.values.phase == '1' ? '1° Fase'
																					: props.values.phase == '2' ? '2° Fase'
																						: props.values.phase == '3' ? '3° Fase'
																							: props.values.phase == '4' ? '4° Fase'
																								: 'Desconhecido'
																			}
																		</div>
																	</div>
																</div>

																{props.values.next_form ?
																	<div className='col-md-12 fv-row mb-3'>
																		<label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Next Form' })}</label>
																		<div className='position-relative mb-3'>
																			<div className='d-flex justify-content-between w-100'>
																				{props.values.next_form ? props.values.next_form : '- - -'}
																			</div>
																		</div>
																	</div>
																	:
																	<div className='col-lg-3 fv-row mb-3'>
																		<label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Next Form' })}</label>
																		<div className='position-relative mb-3'>
																			<div className='d-flex justify-content-between w-100'>
																				{props.values.next_form ? props.values.next_form : '- - -'}
																			</div>
																		</div>
																	</div>
																}


																<div className='col-md-12 fv-row mb-3'>
																	<label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Final sentence' })}</label>
																	<div className='position-relative mb-3'>
																		<div className='d-flex justify-content-between w-100'>
																			{props.values.final_sentence ? props.values.final_sentence : '- - -'}
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</div>

















												</div>

												:

												<form onSubmit={props.handleSubmit} noValidate className='form'>
													<div className='card-body border-top px-5 py-5'>
														<div className='d-flex justify-content-between align-items-center mb-3'>
															<label className='form-label fw-bolder text-dark fs-3 '>{intl.formatMessage({ id: 'Edition Form' })}</label>
															{/* <button onClick={()=>val.edition(false)}  type='button' className='btn btn-sm btn-secondary p-3'>
														<KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark'/>
														{intl.formatMessage({id: 'Back'})}
												</button> */}

														</div>
														<div className='mb-2 fv-row border-top' data-kt-password-meter='true'>
															<div className='col-lg-12'>
																<div className='row mb-4'>
																	<div className='col-lg-12 fv-row mb-3 d-flex justify-content-end mt-4'>
																		<span className='bg-hover-success text-hover-white p-2 rounded'
																			onClick={() => [copyText(props.values.code, intl)]}
																		>{props.values.code}</span>
																	</div>
																	<div className='col-lg-12 fv-row mb-3 d-flex justify-content-end py-6 px-9'>

																		{props.values.is_public ?
																			<span className="badge badge-light-success mx-2">Public</span>
																			:
																			null
																		}

																		<span className="badge badge-light-info">
																			{

																				props.values.score === 1 ? `${intl.formatMessage({ id: 'Gap Analysis' })}` :
																					props.values.score === 2 ? `${intl.formatMessage({ id: 'Scored' })}` :
																						props.values.score === 3 ? `${intl.formatMessage({ id: 'Not scored' })}` :
																							props.values.score === 4 ? `${intl.formatMessage({ id: 'Data Mapping' })}` :
																								'Unknown'
																			}
																		</span>

																	</div>
																</div>
																<div className='row'>
																	<div className='col-md-12 fv-row mb-3'>
																		<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'Name' })}</label>
																		<div className='position-relative mb-3'>
																			<div className='d-flex justify-content-between w-100'>
																				<input
																					type='text'
																					placeholder={intl.formatMessage({ id: 'Name' })}
																					autoComplete='off'
																					name='name'
																					onChange={props.handleChange}
																					value={props.values.name}
																					className='form-control form-control-lg form-control-solid w-100 me-1'
																				/>
																			</div>
																			{props.touched.name && props.errors.name && (
																				<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
																					<div className='fv-help-block'>
																						<span role='alert'>{props.errors.name}</span>
																					</div>
																				</div>
																			)}
																		</div>
																	</div>
																	{/* <div className='col-lg-6 fv-row mb-3'>
															<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>Score</label>
															<div className='position-relative mb-3'>
																<div className='d-flex justify-content-between w-100'>
																	<select className='form-select form-control form-control-solid fs-6' 
																		name='score'
																		onChange={props.handleChange}
																		value={props.values.score}
																	>
																		<option value='' disabled>Select an option</option>
																		<option value='1'>Gap Score</option>
																		<option value='3'>Not scored</option>
																		<option value='2'>Scored</option>
																	</select>
																</div>
																{props.touched.score && props.errors.score && (
																	<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
																		<div className='fv-help-block'>
																			<span role='alert'>{props.errors.score}</span>
																		</div>
																	</div>
																)}
															</div>
														</div> */}
																	<div className='col-lg-4 fv-row mb-3'>
																		<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'Status' })}</label>
																		<div className='position-relative mb-3'>
																			<div className='d-flex justify-content-between w-100'>
																				<select className='form-select form-control form-control-solid fs-6'
																					name='status'
																					onChange={props.handleChange}
																					value={props.values.status}
																				>
																					<option value='' disabled>{intl.formatMessage({ id: 'Select an option' })}</option>
																					<option value='2'>{intl.formatMessage({ id: 'Test' })}</option>
																					<option value='1'>{intl.formatMessage({ id: 'Active' })}</option>
																					<option value='0'>{intl.formatMessage({ id: 'Inactive' })}</option>
																				</select>
																			</div>
																			{props.touched.status && props.errors.status && (
																				<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
																					<div className='fv-help-block'>
																						<span role='alert'>{props.errors.status}</span>
																					</div>
																				</div>
																			)}
																		</div>
																	</div>
																	<div className='col-lg-4 fv-row mb-3'>
																		<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'Phase' })}</label>
																		<div className='position-relative mb-3'>
																			<div className='d-flex justify-content-between w-100'>
																				<select className='form-select form-control form-control-solid fs-6'
																					name='phase'
																					onChange={props.handleChange}
																					value={props.values.phase}
																				>
																					<option value='' disabled>{intl.formatMessage({ id: 'Select an option' })}</option>
																					<option value='1'>1° {intl.formatMessage({ id: 'Phase' })}</option>
																					<option value='2'>2° {intl.formatMessage({ id: 'Phase' })}</option>
																					<option value='3'>3° {intl.formatMessage({ id: 'Phase' })}</option>
																					<option value='4'>4° {intl.formatMessage({ id: 'Phase' })}</option>
																				</select>
																			</div>
																			{props.touched.phase && props.errors.phase && (
																				<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
																					<div className='fv-help-block'>
																						<span role='alert'>{props.errors.phase}</span>
																					</div>
																				</div>
																			)}
																		</div>
																	</div>
																	<div className='col-md-12 fv-row mb-3'>
																		<label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Next Form' })}</label>
																		<div className='position-relative mb-3'>
																			<div className='d-flex justify-content-between w-100'>
																				<input
																					type='text'
																					placeholder=''
																					autoComplete='off'
																					name='next_form'
																					onChange={props.handleChange}
																					value={props.values.next_form}
																					className='form-control form-control-lg form-control-solid w-100 me-1'
																				/>
																			</div>
																			{/* {formik.touched.phase && formik.errors.phase && (
																	<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
																		<div className='fv-help-block'>
																			<span role='alert'>{formik.errors.phase}</span>
																		</div>// {...formik.getFieldProps('phase')}
																	</div>
																)} */}
																		</div>
																	</div>
																	<div className='col-md-12 fv-row mb-3'>
																		<label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Final sentence' })}</label>
																		<div className='position-relative mb-3'>
																			<div className='d-flex justify-content-between w-100'>
																				<input
																					type='text'
																					disabled
																					value={props.values.final_sentence}
																					className='form-control form-control-lg form-control-solid w-100 me-1 text-gray-500'
																				/>
																			</div>
																			{/* {props.touched.name && props.errors.name && (
																	<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
																		<div className='fv-help-block'>
																			<span role='alert'>{props.errors.name}</span>
																		</div>
																	</div>
																)} */}
																		</div>
																	</div>
																	{ ['super-admin'].includes(user.roles) ?
																	<div className='col-md-12 fv-row mb-3'>
																		<label className='col-lg-4 form-label fw-bold text-dark fs-6'>Public</label>
																		<div className='position-relative mb-3'>
																			<div className='d-flex justify-content-between w-100'>
																				{/* <input
																	type='checkbox'
																	// value='1'
																	className='form-check-input me-1'
																	name='is_public'
																		// onChange={props.handleChange}
																		// checked={props.values.is_public === 1 ? true : false}
																		// onChange={() => [props.setFieldValue('default', props.getFieldProps('default').value ? 0 : 1)]}
																	// onChange={props.handleChange}
																	// checked={props.values.is_public}
																	// onChange={()=>[setRole('dpo-out'), setErrorRole('')]}
																	// onChange={(e) => props.values.is_public = e.target.value}
																	value={props.values.is_public}
																/> */}

																				<select className='form-select form-control form-control-solid fs-6'
																					name='is_public'
																					onChange={props.handleChange}
																					value={props.values.is_public}
																				>
																					<option value='' disabled>{intl.formatMessage({ id: 'Select' })}</option>
																					<option value='0'>{intl.formatMessage({ id: 'No' })}</option>
																					<option value='1'>{intl.formatMessage({ id: 'Yes' })}</option>
																				</select>


																			</div>
																		</div>
																	</div>
																	:
																	null
															}


																</div>
															</div>
														</div>
													</div>
													<div className='card-footer d-flex justify-content-end py-6 px-9'>
														{/* <button type='submit' onClick={()=>val.question(true, '', val.id)} className='btn btn-light-info me-2'>
												Questions
											</button> */}
														{changeForm ?
															<button type='submit' className='btn btn-sm btn-secondary mx-2' disabled={loading} onClick={() => cancelFormEdition()}>
																{intl.formatMessage({ id: 'Cancel' })}
															</button>
															:
															null
														}

														<button type='submit' className='btn btn-sm btn-primary text-dark' disabled={loading}>
															{!loading && intl.formatMessage({ id: 'Save' })}
															{loading && (
																<span className='indicator-progress' style={{ display: 'block' }}>
																	{intl.formatMessage({ id: 'Processing' })}...{' '}
																	<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
																</span>
															)}
														</button>
													</div>
												</form>

											}

										</div>
									)}
								</Formik>

								{!changeForm ?
									<div className='p-4 '>

										<div >
											<label className='form-label fw-bolder text-dark fs-3 '>{intl.formatMessage({ id: 'Questions List' })} ({data2.length})</label>
										</div>

										<div className='border-top'>

											<label className='form-label fw-bolder text-dark fs-3 mb-6'></label>

											<QuestionList
												data={data2}
												delete={removeQuestion}
												deleteAnswer={removeAnswer}
												id={val.id}
												addAnswer={addAnswer}
												model={val.model}
												onGetFormData={handleFormData}
												onModalAddQuestion={setOpenModalAddQuestion}
												language={data.language}
											/>

											{/* <QuestionButton onClick={()=>setOpenModalAddQuestion(true)} /> */}
											{/* <QuestionButtonSave
								onClick={()=>setOpenModalSubmit2(true)}
							/> */}
										</div>

									</div>

									:
									null
								}

							</div>
						</div>

						{/* ------- */}


					</>
			}
		</>
	)
}
export default EditionForm;