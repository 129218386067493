/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { delActions, defaultPaginationList } from '../../../../app/modules/helpers/Api';
import { converteData } from '../../../../app/modules/helpers/helpers';
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { useIntl } from 'react-intl'
import Pagination from '../../../helpers/components/CustomPagination/CustomPagination'
import { Load } from '../spinner/Spinner';
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'

let searchData = {
  title: '',
  code: '',
  page: 1,
  perpage: 10,
  model: '',
  modulo_id: '',
  prescription_answered_id: ''
}

type Props = {
  onEdit: (action: boolean, id: any) => void;
  show: (action: boolean, id: any) => void;
  create: (action: boolean) => void;
  resource: (action: boolean, id: any) => void;
  idModulo?: any;
  titleModule?: any;
  idForm: any;
}

const TablesAction: React.FC<Props> = ({...props}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [id, setId] = useState();
  const [titleFilter, setTitleFilter ]= useState<any>('');
  const [modelFilter, setModelFilter] = useState<any>('');
  const intl = useIntl();   

  searchData.title = titleFilter
  searchData.model = modelFilter
  useEffect(()=>{
    handleGetList(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loading2]);

  function handleGetList(data:null) {
    setLoading(true);
    searchData.modulo_id = props.idModulo
    searchData.prescription_answered_id = props.idForm

    const get = defaultPaginationList('module/actions', searchData)
    get.then((res)=>{
      setData(res.data.data);     
     
    }).catch((error)=>{
      toast.error(error.response.data.message)
    })
    .finally(()=>[setLoading(false), setLoading2]);
  }

  function handleDelete (){
    const del = delActions(id);
    del.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        handleGetList(null)
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
        
      }
    }).catch((error)=>{
      toast.error(error.response.data.message);
    }).finally(()=>setLoading2(true))
  }

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className=''>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: 'Module'})}: <span className='text-gray-600'>{props.titleModule}</span></span>
          </h3>
          <p className='text-gray-500 fw-bolder'>{intl.formatMessage({id: 'Home - Actions List'})}</p>
        </div>
        <button style={{width: '100px', height: '40px'}} onClick={()=>props.show(false, 'back')} type='button' className='btn btn-sm btn-secondary p-3'>
            <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark'/>
            {intl.formatMessage({id: 'Back'})}
        </button>
      </div>
      {
        loading?
        <><Load/></>
        :
        <div className='card mb-5 mb-xl-10'>

          <div className='card-header border-0 pt-5 row d-flex justify-content-between align-items-center '>
            <div className='col-md-4'>
              
              <input
                    type="text"
                    className="form-control form-control-solid fs-6 rounded-3"
                    placeholder={`${intl.formatMessage({id: 'Search a form'})}`}
                    autoComplete='off'
                    value={titleFilter}
                    onChange={(e)=>[setTitleFilter(e.target.value)]}
                    onKeyDown={(e)=>e.which === 13 ? handleGetList(null) : '' }
                    style={{height: '40px'}}
                   
                  />
             
            </div>
           
                 <div className='col-md-3'>
                      <select className='form-select form-control form-control-solid fs-6' 
                        value={modelFilter}
                        onChange={(e)=>[setModelFilter(e.target.value)]}
                        style={{height: '40px'}}
                      >
                        <option value='' >{intl.formatMessage({id: 'All'})}</option> 
                        <option value='1' >{intl.formatMessage({id: 'Maturity analysis'})}</option>
                        <option value='2'>{intl.formatMessage({id: 'Scored'})}</option>
                        <option value='3' >{intl.formatMessage({id: 'Not scored'})}</option>
                        <option value='4' >{intl.formatMessage({id: 'Data Mapping'})}</option>
                      </select>
                  </div>
                  
                  <div className='col-md-5 d-flex justify-content-between align-items-center' id='positionButtonCalc'>
                      <button 
                      type='button' 
                      className='btn bg-hover-dark  text-white' 
                      disabled={loading}
                      onClick={()=>handleGetList(null)}
                      style={{width: '125px',height: '40px', background: '#65638E', borderRadius: 13, marginRight: '10px'}}
                      >
                         {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Search'})}</span>}
                        {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        )}       
                      </button>

                      {/* {
              user.roles === 'super-admin' || user.roles === 'admin'? */}
              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title={intl.formatMessage({id: 'Click to add an action'})}
              >
              <button
                className='btn btn-sm btn-dark rounded-3'
                onClick={()=>props.create(true)}
                style={{height: '38px', width: '125px'}}
                // data-bs-toggle='modal'
                // data-bs-target='#kt_modal_invite_friends'
              >
                <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                {intl.formatMessage({id: 'New Action'})}
              </button>
            </div>
            {/* :
            <></>
            } */}
                  </div>
                   
          </div>
       
          <div className='card-body py-3'>
            { 
              data?.length !== 0?
              <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-gray-600' style={{borderBottom: '2px dashed #E4E6EF'}}>
                    {/* <th className='min-w-150px'>LABEL</th> */}
                    <th className='w-200px text-center'>{intl.formatMessage({id: 'ACTIONS'})}</th>
                    <th className='min-w-150px'>{intl.formatMessage({id: 'TITLE'})}</th>
                    <th className='min-w-150px text-center'>{intl.formatMessage({id: 'MODEL'})}</th>
                    <th className='min-w-150px text-center'>{intl.formatMessage({id: 'RESOURCES'})}</th>
                    <th className='min-w-150px text-center'>{intl.formatMessage({id: 'DATE'})}</th>                   
                  </tr>
                </thead>
                <tbody>
                 {
                   data?.data.map((item: any, index: any)=>
                    <tr key={index}>
                      <td className='text-center' style={{borderBottom: '2px dashed #E4E6EF'}}>

                        <button 
                          className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm  me-2'
                          onClick={()=>props.show(true, item.id)}
                        >
                          {/* <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _edit 2 outline_.svg')} /> */}

                          <i className='far fa-eye text-info'></i>
                          {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 svg-icon-success' /> */}
                        </button>

                        <button 
                          className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm  me-2'
                          onClick={()=>props.onEdit(true, item.id)}
                          disabled={item.default && !['super-admin'].includes(user.roles) }
                        >
                          <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _edit 2 outline_.svg')} />

                          {/* <i className='far fa-edit text-success'></i> */}
                          {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 svg-icon-success' /> */}
                        </button>
                        <button 
                          className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-2'
                          onClick={()=>props.resource(true, item.id)}
                          disabled={item.default && !['super-admin'].includes(user.roles) }
                        >
                          <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _copy outline_.png')} />
                          {/* <i className='far fa-clone text-warning'></i> */}
                          {/* <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-3 svg-icon-warning' /> */}
                        </button>
                        <button 
                          className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-2'
                          onClick={()=>setId(item.id)}
                          data-bs-toggle='modal'
                          disabled={item.default && !['super-admin'].includes(user.roles) }
                          data-bs-target='#kt_modal_del_action'
                        >
                          <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _trash_.svg')} />

                          {/* <i className='far fa-trash-alt text-danger'></i> */}
                          {/* <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 svg-icon-danger' /> */}
                        </button>
                      </td>                    
                      <td style={{borderBottom: '2px dashed #E4E6EF'}}>
                        
                        <span className='text-gray-700 fw-bold d-block mb-1 fs-6 '>
                        {item.default ? <i className="fa fa-asterisk text-primary" /> : null} {item.title}
                        </span>
                      </td>
                      <td style={{borderBottom: '2px dashed #E4E6EF'}} >
                        <span className='text-gray-700 fw-bold d-block mb-1 fs-6 text-center'>
                        {                                
                              item.model === 1 ? `${intl.formatMessage({id: 'Maturity analysis'})}`  :
                              item.model === 2 ? `${intl.formatMessage({id: 'Scored'})}` :
                              item.model === 3 ? `${intl.formatMessage({id: 'Not scored'})}` :
                              item.model === 4 ? `${intl.formatMessage({id: 'Data Mapping'})}` :
                              `${intl.formatMessage({id: 'Unknown'})}`
                        }
                        </span>
                      </td>
                      <td style={{borderBottom: '2px dashed #E4E6EF'}}>
                        <span className='text-gray-700 fw-bold d-block mb-1 fs-6 text-center'>
                          {item.resource_qtd_count}
                        </span>
                      </td>
                      <td style={{borderBottom: '2px dashed #E4E6EF'}}>
                        <span className='text-gray-700 fw-bold d-block mb-1 fs-6 text-center'>
                          {converteData(item.created_at)}
                        </span>
                      </td>                      
                    </tr>
                   )
                 }
                </tbody>
              </table>
            </div>
            :
            <div className='aling-items-center text-center my-6'>
              <div className='fs-5 text-gray-600 '>{intl.formatMessage({id: 'There are no registered actions yet'})}</div>
            </div>
            }
            <Pagination data={data ? data : ''} onSearch={handleGetList} searchData={searchData}/>
          </div>
        </div>
      }
      <div className="modal fade" tabIndex={-1} id="kt_modal_del_action">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fs-3">{intl.formatMessage({id: 'Attention'})}</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <p className='text-gray-700 fs-4'>{intl.formatMessage({id: 'Are you sure you want to delete this item?'})}</p>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={()=>handleDelete()} data-bs-dismiss="modal" className="btn btn-primary text-white">
              {intl.formatMessage({id: 'Yes'})}
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                {intl.formatMessage({id: 'No'})}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {TablesAction}
