/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { deleteAnswer, getAnswers, defaultPaginationList} from '../../../../../app/modules/helpers/Api'
import { converteData } from '../../../../../app/modules/helpers/helpers'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {UserModel} from '../../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import ModalActionPlansReport from './ActionsPlansReport/modalActionPlanReport'
import ModalReportGraph from './Report/ModalReportGraph'
import ModalMessage from '../../modals/modalMessage'
import { Load } from '../../spinner/Spinner'
import Warning from '../../warning/Warning'

import Pagination from '../../../../helpers/components/CustomPagination/CustomPagination'

let searchData = {
  title: '',
  code: '',
  page: 1,
  perpage: 10
}


type Props = {
  id: number;
  msg: (status: any, message: any) => void;
}

const TablesAnswers: React.FC<Props> = ({...props}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const intl = useIntl();   
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [idAnswer, setIdAnswer] = useState(0);
  const [reportPosition, setReportPosition] = useState<any>();
  const [dataDegreeId, setDataDegreeId] = useState('');
  const [isLoading2, setIsLoading2] = useState(false);
  const [statusFilter, setStatusFilter] = useState<any>();
  const [prescriptionFilter, setPrescriptionFilter ]= useState<any>();
  const [modelFilter, setModelFilter] = useState<any>();


  // searchData.prescription_name = prescriptionFilter;
  // searchData.status = statusFilter;
  // searchData.model = modelFilter;
  useEffect(()=>{
    handleGetList(null)
  },[isLoading2]);

  function handleGetList(data:null) {
    setLoading(true);
    const get = defaultPaginationList('prescription/answereds?perpage=300&company='+ props.id, searchData)
    get.then((res)=>{
      setData(res.data.data);
      // if(res.data.status === 'success'){
      //   setPrescriptionFilter('')
      //  }
    }).catch((error)=>{
      toast.error(error.response.data.message)
    })
    .finally(()=>[setLoading(false), setIsLoading2]);
  }



  function handleDelete (id: any){
    const del = deleteAnswer(id);
    del.then((res)=>{
      if(res.data.status === 'success'){
       toast.success(res.data.message);
       setOpenModalDelete(false);
      }
      if(res.data.status === 'error'){
       toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      }else {
        toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}`);
      }
    }).finally(()=>
      setIsLoading2(true)
    );
  }

  const [openModalActionPlans, setOpenModalActionPlans] = useState(false);
  const [openModalReportGraph, setOpenModalReportGraph] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [idAnswerReport, setIdAnswerReport] = useState('');

  return (
    <>
      <ModalActionPlansReport 
        id={idAnswerReport} 
        title={`${intl.formatMessage({id: 'New Action Plan'})}`} 
        showModal={openModalActionPlans}
        onClose={()=>setOpenModalActionPlans(false)} 
      />
      {
        dataDegreeId !== ''?
        <ModalReportGraph
          title= {`${intl.formatMessage({id: 'Report:'})} ${intl.formatMessage({id: 'Maturity analysis'})}`}
          id={dataDegreeId}
          reportPosition={reportPosition}
          showModal={openModalReportGraph}
          onClose={()=>setOpenModalReportGraph(false)}
        />
        :
        <></>
      }
      <ModalMessage
        message={`${intl.formatMessage({id: 'Are you sure you want to delete this item?'})} `} 
        title={`${intl.formatMessage({id: 'Attention'})} `}
        showModal={openModalDelete}
        onClose={()=>setOpenModalDelete(false)}
        onAction={()=>handleDelete(idAnswer)}
      />
      <div className='card mb-xl-10 rounded-4'>
      <div className='w-100 container'>
          <div  className='form'>
            <div className=" row  py-6 d-flex align-items-center justify-content-between" >
                <div 
                className="col-md-3" 
                >

                  <input
                    type="text"
                    className="form-control form-control-solid fs-6 rounded-3"
                    placeholder={intl.formatMessage({id: 'Search a form'})} 
                    name="prescription_name"
                    autoComplete='off'
                    value={prescriptionFilter}
                    onChange={(e)=>[setPrescriptionFilter(e.target.value)]}
                  />

                </div>
               
                  <div 
                  className='col-md-3' 
                  >
                      <select className='form-select form-control form-control-solid fs-6' 
                        name="status"
                        value={statusFilter}
                        onChange={(e)=>[setStatusFilter(e.target.value)]}

                      >
                        <option value='' >{intl.formatMessage({id: 'All Status'})}</option> 
                        <option value='1' >{intl.formatMessage({id: 'Active'})}</option>
                        <option value='2'>{intl.formatMessage({id: 'Inactive'})}</option>
                      </select>
                  </div>
                  <div 
                  className='col-md-3' 
                  >
                      <select className='form-select form-control form-control-solid fs-6' 
                        name="model"
                        value={modelFilter}
                        onChange={(e)=>[setModelFilter(e.target.value)]}
                      >
                        <option value='' >{intl.formatMessage({id: 'All Models'})}</option> 
                        <option value='1' >{intl.formatMessage({id: 'LGPD'})}</option>
                        <option value='2'>{intl.formatMessage({id: 'Scored'})}</option>
                      </select>
                      
                  </div>
                  <div 
                  className='col-md-2' 
                  style={{marginRight: '20px'}}
                  
                  >
                    <div className=' '>
                      <button 
                      type='button' 
                      className='btn bg-hover-dark mb-5 text-white mt-3' 
                      disabled={loading}
                      onClick={()=>handleGetList(null)}
                      style={{width: '125px', height: '40px', background: '#65638E', borderRadius: 13}}
                      >
                      {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Search'})}</span>}
                        {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                      </button>
                    </div>  
                </div>       
                
                

            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='mt-5 table-responsive'>
            {
              loading && data.length === 0?
              <Load/>
              :
              <>
                {
                  data.length === 0?
                  <Warning message={intl.formatMessage({id: 'There are no registered answers yet'})}  />
                  :
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-150px'>{intl.formatMessage({id: 'FORM'})}</th>
                        <th className='min-w-140px'>{intl.formatMessage({id: 'MODEL'})}</th>
                        <th className='min-w-120px'>{intl.formatMessage({id: 'USER'})}</th>
                        <th className='min-w-120px'>{intl.formatMessage({id: 'STATUS'})}</th>
                        <th className='min-w-120px'>{intl.formatMessage({id: 'DATE'})}</th>
                        {
                          user.roles !== 'free'?
                          <th className='min-w-100px text-center'>{intl.formatMessage({id: 'ACTIONS'})}</th>
                          :
                          <></>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {data.data?.map((item: any, index: any)=>
                        <tr key={index}>
                          <td className='d-flex flex-column'>
                            <span className='text-gray-800 fw-bold fs-6'>
                              {item.prescription_name}
                            </span>
                            <span className='text-gray-700 fw-bold fs-7'>
                              {item.name}
                            </span>
                          </td>
                          <td>
                            <span className='text-gray-800 fw-bold d-block fs-6'>
                              {
                                item.model === 1?
                                <span className='badge badge-light-warning'>LGPD</span>
                                :
                                <>
                                  {
                                    item.model === 2?
                                    <span className='badge badge-light-warning'>{intl.formatMessage({id: 'Scored'})}</span>
                                    :
                                    <span className='badge badge-light-warning'>{intl.formatMessage({id: 'Not scored'})}</span>
                                  }
                                </>
                              }
                            </span>
                          </td>
                          
                          <td>
                            <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                              {item.user_name}
                            </span>
                          </td>
                          <td>
                            {
                              item.status?
                              <span className='badge badge-light-success'>{intl.formatMessage({id: 'Active'})}</span>
                              :
                              <span className='badge badge-light'>{intl.formatMessage({id: 'Inactive'})}</span>
                            }
                          </td>
                          <td>
                            <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                              {converteData(item.created_at)}
                            </span>
                          </td>
                          {
                            user.roles !== 'free'?
                            <td className='d-flex align-items-center justify-content-center'>
                              <button
                                className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                onClick={()=>[setDataDegreeId(item.id), setOpenModalReportGraph(true), setReportPosition(index)]}
                              >
                                <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3 svg-icon-primary' />
                              </button>

                              {
                                user.id === item.user_id || item.action_plan_id?
                                <button
                                  className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                  onClick={()=>[
                                    setIdAnswerReport(item.id), 
                                    setOpenModalActionPlans(true),
                                  ]}
                                >
                                  <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-3 svg-icon-success' />
                                </button>
                                :
                                <></>
                              }
                              
                            
                              <button 
                                className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm'
                                disabled={user.roles === 'manager-adv' || item.user_id === user.id? false : true}
                                onClick={()=>[setIdAnswer(item.id), setOpenModalDelete(true)]}
                               >
                                <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _trash_.svg')} style={{marginRight: '10px'}} />

                              </button>
                            </td>
                          :
                          <></>
                          }
                            
                        </tr>
                      )}
                    </tbody>
                  </table> 
                }
              </>
            }
          </div><Pagination data={data} onSearch={handleGetList} searchData={searchData}/>

        </div>
      </div>

    </>
  )
}

export {TablesAnswers}
