import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getUserById } from '../../../../../app/modules/helpers/Api';
import { Load } from '../../spinner/Spinner';
import { DeleteProfile } from './cards/DeleteProfile';
import ProfileAddress from './cards/profileAddress';
import ProfileDetail from './cards/profileDetail';
import ProfileResume from './cards/profileResume';
import ProfileUser from './cards/profileUser';

type Props = {
	edition: (action: boolean) => void;
	id: any;
}

const EditionUser: React.FC<Props> = ({...props}) => {

	const [data, setData] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);

	function reload(action: boolean){
		if(action){
			setLoading2(true);
		}
	}

	useEffect(()=>{
		fetchUser(props.id);
	},[props.id]);

	function fetchUser(userId:any){
		setLoading(true);
		const get = getUserById(userId);
		get.then((res)=>{
			setData(res.data.data);
		}).catch((error)=>{
			toast.error(error.response.message);
		}).finally(()=>[setLoading(false), setLoading2(false)]);
	}

  return(
    <>
			{
				loading?
				<Load/>
				:
				<>
					<ProfileUser reload={reload} edition={props.edition} data={data} fetchUser={fetchUser}/>
					<ProfileAddress reload={reload} data={data} />
					<ProfileResume reload={reload} data={data} />
					<ProfileDetail data={data} />
					{/* <DeleteProfile id={props.id} edition={props.edition}/> */}
				</>
			}
		</>
  )
}
export default EditionUser;