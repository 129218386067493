import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { getResourceLink } from '../../../../../app/modules/helpers/Api';
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers';
import { newPlan, checkCouponCode, getProduct } from '../../../../../app/modules/helpers/Api';
import { trataRespota } from '../../../../../app/modules/helpers/helpers'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IStoreBuyFormProps {
  showModal: boolean;
  title: string;
  productData: any
  onClose: () => void;
}

const StoreBuyForm: React.FC<IStoreBuyFormProps> = ({ ...props }) => {

  const [payable, setPayable] = useState('all');
  const [qtd, setQtd] = useState('1');
  const [useTerm, setUseTerm] = useState(false);
  const [loadingCuponCheck, setLoadingCuponCheck] = useState(false);
  const [currValue, setCurrValue] = useState('0');
  const [currDiscount, setCurrDiscount] = useState('0');
  const [currTotal, setCurrTotal] = useState('0');
  const [couponCode, setCouponCode] = useState('');
  const [verifiedCuponCode, setVerifiedCuponCode] = useState('');
  const [aplyCuponCode, setAplyCuponCode] = useState('');
  const [openConfirmItem, setOpenConfirmItem] = useState(false);
  const [openConfirmTerm, setOpenConfirmTerm] = useState(false);
  const [openConfirmRecurrent, setOpenConfirmRecurrent] = useState(false);
  const [recurrentItem, setRecurrentItem] = useState(false);
  const [dynamicTerm, setDynamicTerm] = useState('');  
  const defaulDataProduct = {'name': '', 'value': 0, 'duration': '', 'has_qtd': 0, 'slug':'', 'slug_name':''}
  const [productData, setProductData] = useState(defaulDataProduct);
  const history = useHistory();
  const intl = useIntl();
  
  useEffect(() => {    
    if (props.showModal) {
      // eslint-disable-next-line
      resetItenValues();
      if ( props.productData ) {
        handleGetItem(props.productData.product_code)
        setCouponCode( props.productData.discount_code )
      }
    }
    // eslint-disable-next-line 
  }, [props.showModal, props.productData]);

  useEffect(() => {
    if (!couponCode) {
      setCouponCode('')
    }
    // eslint-disable-next-line
    resetItenValues();
    // eslint-disable-next-line
  }, [couponCode]);

  function resetItenValues(data:any = null) {
    setCurrValue( formatter.format( data? data.value : productData.value ) )
    setCurrTotal( formatter.format( data? data.value : productData.value ) )
    setCurrDiscount( '0' )
    setVerifiedCuponCode('')
    setAplyCuponCode('')
  }

  function handleGetItem(product_id:number) {

    const check = getProduct( product_id );

      check.then((res) => {

        if (res.data.status === 'success' && res.data.data) {
          
          setProductData(res.data.data);
          resetItenValues(res.data.data);

          if (res.data.data === "dpo") {
            setDynamicTerm('dpo')
          } 
          
          else if (res.data.data === "company") {
            setDynamicTerm('company')
          }
          
          else if (res.data.data === "manager-adm") {
            setDynamicTerm('adv')
          }

          else if (res.data.data === "startup") {
            setDynamicTerm('startup')
          }
          else if (res.data.data === "liberal") {
            setDynamicTerm('liberal')
          }
          else if (res.data.data === "addon-form-1") {
        
            setTimeout(() => {
              setUseTerm(true)        
            }, 1000);
            
          }

        } else {
          toast.warning(res.data.message);
          setProductData(defaulDataProduct);
          resetItenValues()
        }

      }).catch((error) => {
        trataRespota('error', error);
      }).finally(() => {
        // setLoadingCuponCheck(false)
        // setVerifiedCuponCode(couponCode);
      });
  }

  function checkCouponDiscount() {

    if (couponCode) {
      

      // if (couponCode == verifiedCuponCode) {
      //   toast.warning('Código atual ja foi verificado!')
      //   return;
      // }

      setLoadingCuponCheck(true)

      setAplyCuponCode('');

      const check = checkCouponCode( { 'coupon_code': couponCode, 'item_code': props.productData.product_code, 'item_qtd': qtd } );

      check.then((res) => {

        if (res.data.status === 'success' && res.data.data) {

          setCurrValue(res.data.data.item_text)
          setCurrDiscount(`- ${res.data.data.coupon_text}`)
          setCurrTotal( res.data.data.total_text )
          
          setAplyCuponCode(couponCode);
          toast.success('Cupom aplicado: ' + res.data.data.coupon_text);
          
        }
        else {
          toast.warning(res.data.message);
          resetItenValues()
        }

      }).catch((error) => {
        trataRespota('error', error);
      }).finally(() => {
        setLoadingCuponCheck(false)
        setVerifiedCuponCode(couponCode);
      });

    } else {
      toast.warning('Código não informado!')
      resetItenValues()
      return;
    }
   
  }

  function handleGetResource(hash: any) {
    getResourceLink(hash);
  }

  interface saleData {
    code: any,
    use_terms: any,
    qtd: any,
    payable_with: any,
    coupon_code: any,
    recurrent: any,
    product_item_id?: any,
    renew_from?: any,
  }

  function handlePlan() {

    const objSaleDate: saleData = {
      code: props.productData.product_code,
      use_terms: useTerm,
      qtd: parseInt(qtd),
      payable_with: payable,
      coupon_code: couponCode,
      recurrent: recurrentItem
    }

    if (props.productData.item_id) {
      objSaleDate.product_item_id = props.productData.item_id;
    }

    if (props.productData.renew_from) {
      objSaleDate.renew_from = props.productData.renew_from;
    }

    const store = newPlan(objSaleDate);

    store.then((res) => {

      if (res.data.status === 'success') {
        toast.success(res.data.message);
        props.onClose();
        setTimeout(() => {
          history.push('/crafted/pages/commum/payments')

          if (productData.slug === 'package') {
            document.location.reload();
          }
          
        }, 2000)
      } else if (res.data.status === 'error') {
        toast.error(res.data.message);
      } else if (res.data.status === 'warning') {
        toast.warning(res.data.message);
      } else {
        toast.error('Ops! Um erro ocorreu, tente novamente mais tarde!');
      }

    }).catch((error) => {
      trataRespota('error', error);
    }).finally(() => {});
    
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return (
    <div>
      <Modal
        open={props.showModal}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
            <span className="modal-title fw-bold fs-3">
              Revisão
            </span>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary"
              onClick={() => props.onClose()}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>

          </div>

          <div className='px-5 d-flex flex-column w-100' style={{ overflow: 'auto', overflowX: 'hidden', maxHeight: 400 }}>

            <div className='d-flex flex-column border-dashed border-2 border-gray-400 rounded p-3'>

              <h6>{intl.formatMessage({id: 'Order details'})}</h6>
              <hr className='mt-0'/>

              <div className='col-lg-12'>

                <div className='row'>
                  <div className='d-md-flex flex-center'>
                    <span className='col-12 col-md-4 fs-5 text-dark'><strong>{intl.formatMessage({ id: 'Item' })}</strong></span>
                    <div className='w-100'>{productData.name ? productData.name : '- - -'}</div>
                  </div>
                </div>

                <div className='row mt-4'>
                  <div className='d-md-flex flex-center'>
                    <span className='col-12 col-md-4 fs-5 text-dark'><strong>{intl.formatMessage({ id: 'Value' })}</strong></span>
                    <div className='w-100'>BRL {productData.value}</div>
                  </div>
                </div>

                <div className='row mt-4'>
                  <div className='d-md-flex flex-center'>
                    <span className='col-12 col-md-4 fs-5 text-dark'><strong>{intl.formatMessage({ id: 'Duration' })}</strong></span>
                    <div className='w-100'>{productData.duration ? (productData.duration + ' ' + intl.formatMessage({ id: 'Days' })) : '- - -'}</div>
                  </div>
                </div>

                {productData.has_qtd ?
                  <div className='row mt-2'>
                    <div className='d-md-flex flex-center'>
                      <span className='col-12 col-md-4 fs-5 text-dark'><strong>{intl.formatMessage({ id: 'Item quantity' })}</strong></span>
                      <select className='form-select form-control'
                          onChange={e => setQtd(e.currentTarget.value)}
                          value={qtd}
                        >
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='5'>5</option>
                          <option value='6'>6</option>
                          <option value='7'>7</option>
                          <option value='8'>8</option>
                          <option value='9'>9</option>
                          <option value='10'>10</option>
                          <option value='11'>11</option>
                          <option value='12'>12</option>
                      </select>
                    </div>
                  </div>
                  :
                  null
                }

                <div className='row mt-2'>
                  <div className='d-md-flex flex-center'>
                    <span className='col-12 col-md-4 fs-5 text-dark'><strong>{intl.formatMessage({ id: 'payment_form' })}</strong></span>
                    <select className='form-select form-control'
                      onChange={e => setPayable(e.currentTarget.value)}
                      value={payable}
                    >
                      <option value='all'>{intl.formatMessage({ id: 'All' })}</option>
                      <option value='credit_card'>{intl.formatMessage({ id: 'Credit card' })}</option>
                      <option value='bank_slip'>{intl.formatMessage({ id: 'Bank slip' })}</option>
                      <option disabled value='pix'>Pix</option>
                    </select>
                  </div>
                </div>

                <div className='row mt-4'>
                  <div className='d-md-flex flex-center'>
                    <span className='col-12 col-md-4 fs-5 text-dark'><strong>{intl.formatMessage({ id: 'coupon' })}</strong></span>
                    <div className='d-flex flex-center w-100'>
                      <input
                        type='text'
                        className='form-control'
                        name='search'
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                      />

                      <button type='button' onClick={checkCouponDiscount} disabled={loadingCuponCheck || aplyCuponCode?true:false} className={`btn btn-md ${aplyCuponCode?'btn-success':'btn-primary'} text-white mx-3`}>
                        {!loadingCuponCheck ?
                          <span>
                            { aplyCuponCode ?
                              intl.formatMessage({ id: 'Ok' })
                              :
                              intl.formatMessage({ id: 'Check' })
                            }                          
                          </span>
                          :
                          <span className='indicator-progress' style={{display: 'block'}}>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        }
                      </button>

                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div className='row mt-6 mx-4'>
              <div className='col-12 d-flex w-100 justify-content-end '>
                <div className='row d-block h4'>
                  <div className='col d-flex flex-center'>
                    <span className='col-12 text-gray-400'>Valor:</span>
                    <span className='col-12 d-flex flex-end'>{ currValue }</span>
                  </div>
                  <div className='col d-flex flex-center'>
                    <span className='col-12 text-gray-400'>Desconto:</span>
                    <span className='col-12 d-flex flex-end'>{ currDiscount }</span>
                  </div>
                  <div className='col d-flex flex-center mt-4'>
                    <span className='col-12 text-gray-400'>Total:</span>
                    <span className='col-12 d-flex flex-end'>{ currTotal }</span>
                  </div>  
                </div>
              </div>
            </div>

          </div>

          <div className='d-flex flex-row w-100 justify-content-end p-5'>
            <button type='button' onClick={() => [props.onClose(), resetItenValues()]} className='btn btn-md btn-danger me-3'>
              {intl.formatMessage({ id: 'Cancel' })}
            </button>

              { (couponCode && !verifiedCuponCode) || (couponCode && !aplyCuponCode) ? 

                <button type='button' onClick={checkCouponDiscount} disabled={loadingCuponCheck } className='btn btn-md btn-primary text-white mx-3'>
                  {!loadingCuponCheck ?
                    <span>{intl.formatMessage({ id: 'Check' })}</span>
                    :
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  }
                </button>

                :
                <div>
                  { productData.slug_name === "addon-form-1" ?

                    <button type='button' onClick={() => setOpenConfirmItem(true)} className='btn btn-md btn-primary text-white '>
                      {intl.formatMessage({ id: 'Continue' })}
                    </button>

                    :

                    <button type='button' onClick={() => setOpenConfirmTerm(true)} className='btn btn-md btn-primary text-white '>
                      {intl.formatMessage({ id: 'Continue' })}
                    </button>

                  }
                </div>

              }

          </div>

        </Box>
      </Modal>

      {/* MODAL TERMO E CONDIÇÔES */}
      <Modal
        open={openConfirmTerm}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div className='container mt-5 p-5 text-center '>
          
            <p className='w-90 mx-auto fs-4 mt-5'>
              <span>Você concorda com o </span>
              <a href="#/" onClick={() => handleGetResource('User_term_' + dynamicTerm)} className='ms-1 link-primary'>
              {intl.formatMessage({ id: 'terms and conditions' })}
              </a>?
            </p>

          </div>

          <div className='d-flex justify-content-center mb-2'>
            <button
              onClick={() => [setOpenConfirmTerm(false)]}
              style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark mx-3'>
              {intl.formatMessage({ id: 'No' })}
            </button>

            <button
              onClick={() => [setOpenConfirmTerm(false), setUseTerm(true), setOpenConfirmRecurrent(true)]}
              style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark'>
              {intl.formatMessage({ id: 'Yes' })}
            </button>

          </div>

        </Box>
      </Modal>

      {/* MODAL CONFIRM RECURRENT */}
      <Modal
        open={openConfirmRecurrent}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div className='container mt-5 p-5 text-center '>
          
            <p className='w-90 mx-auto fs-4 mt-5'>Deseja gerar automaticamente uma fatura desse item todo mês?</p>

          </div>

          <div className='d-flex justify-content-center mb-2'>
            <button
              onClick={() => [setOpenConfirmRecurrent(false), setRecurrentItem(false), setOpenConfirmItem(true)]}
              style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark mx-3'>
              {intl.formatMessage({ id: 'No' })}
            </button>


            <button
              onClick={() => [setOpenConfirmRecurrent(false), setRecurrentItem(true), setOpenConfirmItem(true)]}
              style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark'>
              {intl.formatMessage({ id: 'Yes' })}
            </button>
            

          </div>

        </Box>
      </Modal>

      {/* MODAL CONFIRM ITEM */}
      <Modal
        open={openConfirmItem}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div className='container mt-5 p-5 text-center '>
          
            <p className='w-90 mx-auto fs-4 mt-5'>Deseja adquirir esse item no valor de {currTotal}</p>

          </div>

          <div className='d-flex justify-content-center mb-2'>
            <button
              onClick={() => [setOpenConfirmItem(false)]}
              style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark mx-3'>
              {intl.formatMessage({ id: 'No' })}
            </button>

            <button
              onClick={() => [setOpenConfirmItem(false), handlePlan()]}
              style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark'>
              {intl.formatMessage({ id: 'Yes' })}
            </button>
             

          </div>

        </Box>
      </Modal>



    </div>
  )
}
export default StoreBuyForm;