/* eslint-disable jsx-a11y/anchor-is-valid */
// import { userInfo } from 'os';
import React, { FC, useEffect, useState } from 'react'
import * as Yup from 'yup';
import { KTSVG } from '../../../../../helpers';
import { Box, Modal } from '@mui/material';
import { useIntl } from 'react-intl'
import { useFormik } from 'formik';
import { actionEvidencePost, defaultPaginationList, downEvidence, deleteActionEvidence, editActionEvidence } from '../../../../../../app/modules/helpers/Api'
import { converteData2, handleDownload } from '../../../../../../app/modules/helpers/helpers';
import { toast } from 'react-toastify';
import $ from "jquery";

export interface IActions {
  // action_id: number,
  // action_plan_id: number,
}

type Props = {
  show: boolean,
  action: any
  onClose: () => void;
}

let paginationData = {
  currentPage: 1,
  total: 0,
  from: 0,
  to: 0,
  lastPage: 0
}

let initialSearchValues = {
  title: '',
  code: '',
  page: 1
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'white',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

const Evidences: FC<Props> = ({ ...props }) => {

  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [showUploadBar, setShowUploadBar] = useState(false);  
  const [delEvidenceHash, setDelEvidenceHash] = useState('');
  const [delEvidenceTitle, setDelEvidenceTitle] = useState(''); 
  const [isUploading, setIsUploading] = useState(false);
  const [evidenceList, setEvidenceList] = useState <[]> ();
  const [lastPage, setLastPage] = useState(0);
  const [tela, setTela] = useState <any> (1);
  const [file, setFile] = useState<any>(''); 
  const [currentFile, setCurrentFile] = useState<any>(''); 
  const [selectedFileName, setSelectedFileName] = useState<any>(''); 
  const [currentUrl, setCurrentUrl] = useState<any>(''); 
  

  useEffect(() => {
    if (props.show) {
      currCancel();
      if (props.show && props.action.id) {
        handleGetEvidences();
      }
    }
  }, [props.show]);

  const formik = useFormik({
    initialValues: {
      uuid: '',
      type: '',
      action_id: props.action.id,
      file: '',
      title: '',
      description: '',
      url: ''
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required(`${intl.formatMessage({id: 'Type'})} ${intl.formatMessage({id: 'is required'})}`),
      title: Yup.string().required(`${intl.formatMessage({id: 'Title'})} ${intl.formatMessage({id: 'is required'})}`).min(3, 'Mínimo de 3 símbolos'),
      description: Yup.string().min(3, 'Mínimo de 3 símbolos')
    }),
    onSubmit: (values) => {

      var formData = new FormData();

      if (formik.getFieldProps('uuid').value) {
        formData.append('uuid', formik.getFieldProps('uuid').value);
      }
      
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('action_id', values.action_id);
      formData.append('type', values.type);

      if (!formik.getFieldProps('uuid').value || currentFile!=selectedFileName || values.url!=currentUrl) {

        if (values.type == 'url') {

          if (!values.url) {
            toast.error('Url não informada');
            return;
          }

          formData.append('url', values.url);
          setFile('');
        } else if (values.type == 'file') {

          if (!file[0]) {
            toast.error('Arquivo não selecionado');
            return;
          }

          formData.append('file', file[0]);
        }

      }
      
      setShowUploadBar(true);
      const create = actionEvidencePost(formData, setIsUploading, handleBack);
    
    },
  })

  function handleBack (){
    currCancel();
  }

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {

    setShowUploadBar(false);

    const target = event.currentTarget as HTMLInputElement;
    const files = target.files as FileList;
    const arrayFiles = Array.from(files);

    setSelectedFileName(arrayFiles[0].name);

    setFile(arrayFiles);
  }

  function currCancel() {
    setTela(1);

    formik.resetForm();
    $("#file_evidence").val('');

    setCurrentFile(''); 
    setSelectedFileName(''); 
    setCurrentUrl(''); 

    setShowUploadBar(false);
    initialSearchValues.page = 1;
    handleGetEvidences();
  }

  let searchData = {
    action_id: props.action.id,
    page: 1,
    perpage: 5
  }

  function handleGetEvidences() {

    // searchData.search_value = searchValueFilter ? searchValueFilter : '';
    // searchData.status = statusFilter ? statusFilter : '';
    // searchData.type = typeFilter ? typeFilter : '';

    searchData.page = initialSearchValues.page;

    setLoading(true);

    const get = defaultPaginationList(`action/evidences`, searchData)
    get.then((res) => {

      paginationData.from = res.data.data.from
      paginationData.to = res.data.data.to
      paginationData.total = res.data.data.total
      paginationData.lastPage = res.data.data.last_page

      setLastPage(res.data.data.last_page);
      setEvidenceList(res.data.data.data);

    }).catch((error) => alert(error))
    .finally(() => setLoading(false));
  }
  
  function handleNextPrevPage(value: any) {
    if (value == 'prev' && initialSearchValues.page > 1) {
      initialSearchValues.page--;
      handleGetEvidences();
    }
    if (value == 'next' && initialSearchValues.page < lastPage) {
      initialSearchValues.page++;
      handleGetEvidences();
    }
  }

  function downloadEvidence (uuid: any, name: any, extension: any) {
    toast.success('Em processo, Aguarde...');
    const get = downEvidence(uuid);
    get.then((res)=>{
      if(res.statusText === 'OK'){
        // toast.success('resource downloaded successfully!');
        handleDownload(res, name, extension);
      } else {
        toast.error('Sorry, an error occurred!');
      }
    }).catch((error)=>{
      if (error.response.status == 404) {
        
        let file = new Blob([error.response.data], {type: 'application/json'});

        file.text()
        .then(value => {

          let object = JSON.parse(value);

          toast.warning(object.message);

        })
        .catch(error => {
          toast.error('Desculpe um erro ocorreu')
          console.log("Desculpe um erro ocorreu" + error);
        });

      } else {
        toast.error('Indiponível no momento!')
      }
    })
  }

  function handleDeleteEvidence(){
    setLoading(true)
    const del = deleteActionEvidence(delEvidenceHash);
    del.then((res)=>{
      if(res.data.status == 'success'){
        handleGetEvidences();
        setDelEvidenceHash('')
        setDelEvidenceTitle('')
        toast.warning(res.data.message);
      }
      if(res.data.status == 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      toast.error(error.response.data.message);
    }).finally(()=> {setLoading(false)});    
  }

  function handleEditEvidence(hash: any) {

    setLoading(true)

    const del = editActionEvidence(hash);

    del.then((res)=>{
      if(res.data.status == 'success'){
        
        formik.setFieldValue('uuid', res.data.data.uuid)
        formik.setFieldValue('title', res.data.data.title ? res.data.data.title : '')
        formik.setFieldValue('type', res.data.data.type ? res.data.data.type : 'url')
        formik.setFieldValue('action_id', res.data.data.plan_action_id)
        formik.setFieldValue('description', res.data.data.description ? res.data.data.description : '')
        formik.setFieldValue('url', res.data.data.url ? res.data.data.url : '')
 
        setSelectedFileName(res.data.data.original_name ? res.data.data.original_name : '')
        setCurrentFile(res.data.data.original_name ? res.data.data.original_name : '')

        setCurrentUrl(res.data.data.url ? res.data.data.url : '')

        setTela(2)
      }
      if(res.data.status == 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      toast.error(error.response.data.message);
    }).finally(()=> {setLoading(false)}); 

  }

  return (
    <div>

      <Modal open={props.show} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
       
        <Box sx={modalStyle} >

          <div className="modal fade" tabIndex={-1} id="kt_modal_del_company2">
            <div className="modal-dialog">
              <div className="modal-content">
    
                <div className="modal-body">
                  <p className='text-gray-700 fs-4'>Deseja remover a evidência <b>{delEvidenceTitle}</b>?</p>
                
                  <div className='d-flex flex-row w-100 justify-content-end p-5'>
                    <button
                      type="button"
                      className="btn btn-primary text-white w-100 me-2"
                      data-bs-dismiss="modal"
                      onClick={()=> [setDelEvidenceHash(''), setDelEvidenceTitle('')]} 
                    >
                      {intl.formatMessage({id: 'No'})}
                    </button>
                    <button type="button" 
                      onClick={()=>handleDeleteEvidence()} 
                      data-bs-dismiss='modal' className="btn btn-danger w-100">
                      {intl.formatMessage({id: 'Yes'})}
                      
                    </button>
                  </div>

                </div>

              </div>
            </div>
          </div>

          <div className="p-5" tabIndex={-1} >
            <div className="modal-dialog modal-dialog-centered mw-900px bg-white" >
              <div className="modal-content mh-600px" >
                
                <div className="modal-header" >
                  <h5 className="">{intl.formatMessage({ id: 'Evidence' })}</h5>
                  <div>
                    { tela == 1 ?
                      <div className="btn btn-icon btn-sm btn-active-light-success ms-2" onClick={() => [setTela(2)]} title='Adicionar'>
                        <KTSVG path="/media/icons/duotune/arrows/arr087.svg" className="svg-icon svg-icon-2x" />
                      </div>
                      :
                      null
                    }
                    <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={() => [props.onClose()]} title='Fechar'>
                      <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
                    </div>
                  </div>

                </div>

        

                <div className="modal-body w-100 pt-1 " style={{ maxHeight: '450px',  overflow: 'auto' }}>

                  {/* <div>{props.action ? props.action.title : '- - -'}</div> */}

                  <div  >
                    
                    { 
                      tela == 1 ?
                      
                        <div>

                          {
                            evidenceList && evidenceList.length>0 ?
                            <div>
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                  <tr className='fw-bolder text-muted'>
                                  <th className='min-w-100px text-center' style={{width: '150px'}}>{intl.formatMessage({ id: 'ACTIONS' })}</th>
                                    <th className='min-w-150px'>{intl.formatMessage({ id: 'TITLE' })}</th>
                                    <th className='min-w-140px'>{intl.formatMessage({ id: 'TYPE' })}</th>
                                    <th className='min-w-120px text-center'>{intl.formatMessage({ id: 'DATE' })}</th>                                    
                                  </tr>
                                </thead>
                                <tbody >
                                  {
                                    evidenceList?.map((item: any, index: any) =>

                                      <tr key={index}>

                                        <td>

                                        {
                                          item.type == 'url' && item.url !== null ?
                                            <a
                                              href={`${item.url}`}
                                              target='_blank'
                                              className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                              title='URL'
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/coding/cod007.svg'
                                                className='svg-icon-2 svg-icon-info'
                                              />
                                            </a>
                                            :
                                            null
                                        }

                                        {
                                          ['file'].includes(item.type) ?
                                            <>

                                              <button
                                                // onClick={() => handleGetResource(item.uuid)}
                                                onClick={() => downloadEvidence(item.uuid, item.title, item.extension)}
                                                className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                                title='Download'
                                              >
                                                <KTSVG
                                                  path='/media/icons/duotune/files/fil021.svg'
                                                  className='svg-icon-2x svg-icon-success'
                                                />
                                              </button>
                                            </>
                                            :
                                            null
                                        }

                                        <button
                                          className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm mx-2'
                                          title='Edit'
                                          onClick={()=> [ handleEditEvidence(item.uuid) ]}
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen055.svg'
                                            className='svg-icon-3 svg-icon-success'
                                          />
                                        </button>

                                        <button
                                          className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm mx-2'
                                          title='Delete'
                                          onClick={()=> [setDelEvidenceHash(item.uuid), setDelEvidenceTitle(item.title)]}
                                          data-bs-toggle='modal'
                                          data-bs-target='#kt_modal_del_company2'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen027.svg'
                                            className='svg-icon-3 svg-icon-danger'
                                          />
                                        </button>

                                        </td>

                                        <td>
                                          <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                              <span className='text-gray-700 fw-bold fs-6'>
                                                {item.title}
                                              </span>
                                            </div>
                                          </div>
                                        </td>

                                        <td>
                                          <div className='d-flex align-items-center'>
                                            <div className=''>
                                              <span className='text-gray-700 fw-bold fs-6'>
                                                {
                                                  item.type === 'url'
                                                    ? 'URL'
                                                    : item.type === 'file'
                                                      ? item.extension
                                            
                                                            : '- - -'
                                                }
                                              </span>

                                            </div>
                                          </div>
                                        </td>

                                        <td>
                                          <div className='d-flex align-items-center justify-content-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                              <span className='text-gray-700 fw-bold fs-6'>
                                                { converteData2(item.updated_at) }
                                              </span>
                                            </div>
                                          </div>
                                        </td>


                                        

                                      </tr>
                                    )
                                  }
                                </tbody>
                              </table>
                            </div>

                            :
                            <div className='text-center mt-6 mb-6'>
                              Nenhuma Evidência registrada até o momento...
                            </div>
                          }

                          {/* <div>
                            <button type='button' className='btn btn-sm w-100 btn-primary text-dark' onClick={() => [setTela(2)]}>Adicionar</button>
                          </div> */}
                          
                          { evidenceList && evidenceList.length>0 ?
                            <div className='d-flex flex-stack flex-wrap p-5'>
                              <div className='fs-6 fw-bold text-gray-700'>{intl.formatMessage({ id: 'Showing' })} {paginationData.from} {intl.formatMessage({ id: 'to' })} {paginationData.to} {intl.formatMessage({ id: 'of' })} {paginationData.total} {intl.formatMessage({ id: 'entries' })}.</div>
                
                              <ul className='pagination'>
                                <li className={'page-item previous ' + (initialSearchValues.page == 1 ? 'disabled' : '')} onClick={() => handleNextPrevPage('prev')}>
                                  <a href='#' className='page-link'>
                                    <i className='previous' ></i>
                                  </a>
                                </li>
              
                                <li className='page-item active'>
                                  <a href='#' className='page-link bg-primary text-dark'>
                                    {initialSearchValues.page}
                                  </a>
                                </li>
              
                                <li className={'page-item next ' + (initialSearchValues.page >= paginationData.lastPage ? 'disabled' : '')} onClick={() => handleNextPrevPage('next')}>
                                  <a href='#' className='page-link'>
                                    <i className='next' ></i>
                                  </a>
                                </li>
              
                              </ul>
                            </div>
                          :
                          null
                          }
                            
                          
                        </div>

                        :

                        tela == 2 ?

                          <div>

                            <form onSubmit={formik.handleSubmit} noValidate className='form w-100 p-3' id='form-action-evidence-post'>
                              
                              <div className='row mt-6'>

                                <div className='col-lg-12 fv-row'>
                                  <label className='col-lg-4 col-form-label fw-bold fs-5 required'>{intl.formatMessage({ id: 'Title' })}</label>
                                  <input
                                    className="form-control form-control-solid fs-5"
                                    placeholder="Title"
                                    {...formik.getFieldProps('title')}
                                  />
                                  {formik.touched.title && formik.errors.title && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.title}</div>
                                    </div>
                                  )}
                                </div>

                                <div className='col-lg-12 fv-row mb-4 mt-6'>
                                  {/* <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({ id: 'Type' })}</label> */}

                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name='type' checked={formik.getFieldProps('type').value=='url'} onClick={() => [formik.setFieldValue('type', 'url'), formik.setFieldValue('file', '')]} />
                                    <label className="form-check-label" >{intl.formatMessage({ id: 'Url' })}</label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name='type' checked={formik.getFieldProps('type').value=='file'} onClick={() => [formik.setFieldValue('type', 'file'), formik.setFieldValue('url', '')]} />
                                    <label className="form-check-label" >{intl.formatMessage({ id: 'File' })}</label>
                                  </div>


                                  {formik.touched.type && formik.errors.type && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.type}</div>
                                    </div>
                                  )}
                                </div>

                                {
                                  formik.getFieldProps('type').value == 'file' ?
                                  <div className='col-lg-12 fv-row'>
                                    <label htmlFor="file_evidence" className="form-control form-control-solid fs-5 text-hover-primary fw-bolder cursor-pointer">{selectedFileName ? selectedFileName : (currentFile?currentFile:'Arquivo não selecionado!')}</label>
                                    <input id="file_evidence" type="file" name="file_evidence" required onChange={(event)=>[ uploadFile(event) ]} className='form-control form-control-lg form-control-solid w-100 me-1' style={{display:'none'}} />
                                  </div>

                                  :

                                  formik.getFieldProps('type').value == 'url' ?
                                  
                                  <div className='col-lg-12 fv-row mb-2'>
                                    <input
                                      className="form-control form-control-solid fs-5"
                                      placeholder="url"
                                      required
                                      {...formik.getFieldProps('url')}
                                    />
                                    {formik.touched.url && formik.errors.url && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block text-danger fs-6 mt-1 required' >{formik.errors.url}</div>
                                      </div>
                                    )}
                                  </div>

                                  :

                                  null


                                }


                                {
                                  formik.getFieldProps('type').value ?
                                <div>

                                

                                  <div className='col-lg-12 fv-row'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({ id: 'Description' })}</label>
                                    <textarea
                                      rows={3}
                                      className="form-control form-control-solid fs-5"
                                      placeholder="Description"
                                      {...formik.getFieldProps('description')}
                                    />
                                    {formik.touched.description && formik.errors.description && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.description}</div>
                                      </div>
                                    )}
                                  </div>

                                </div>
                                :
                                null
                                  }

                              </div>                             

                            </form>


                            { selectedFileName != currentFile && showUploadBar ? 
                              <div className='mt-4 '>
                                <div className='text-gray-600 fw-bold fs-7 text-center'>{intl.formatMessage({id: 'PROGRESS'})}</div>
                                <div className='border-dashed border-gray-300 border- p-5 hover pointer rounded'>
                
                                  <progress id="upload_bar_progress_1" max="100" value={0}></progress>
                                  <p id="dialog_upload_span" />

                                </div>

                              </div>

                              : 
                              null
                            }


                          </div>

                          :

                        <div>Tela não definida</div>

                      }

                  </div>





                </div>

                { tela == 2 ?
                  <div className='d-flex flex-row w-100 justify-content-end p-5'>
                    
                    <button type='button' onClick={()=> currCancel()} title='Cancel edition' form='form-action-evidence-post' className='btn me-2 btn-sm btn-secondary' disabled={loading || isUploading}>
                      { intl.formatMessage({id: 'Back'}) }
                    </button>

                    <button type='submit' form='form-action-evidence-post' className='btn btn-sm btn-primary text-dark' disabled={loading || isUploading}>
                      {!loading && intl.formatMessage({id: formik.getFieldProps('uuid').value ? 'Update' : 'Save'})}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Processando...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>

                  </div>                       
                  : 
                  null 
                }
                
              </div>
            </div>
          </div>



          {/* <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
              <span className="modal-title fw-bold fs-3">
                aff
              </span>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary"
                onClick={()=> [ props.onClose() ]}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>

          <div>ACTION: {props.action ? props.action.id : '---'}</div> */}


        </Box>

      </Modal>

    </div>
  )
}

export { Evidences }
