import React, { useState } from 'react';
import { useIntl } from 'react-intl'
import { Load } from '../../../spinner/Spinner';

type Props = {
  modules: any,
  finalization: any,
  chapter_nivel: any,
  user_nivel: any,
  playerVideo: any,
  indiceChapter: any,
  loadingVideo: any,
  onGetVideo: (hash: any, type: any, index: any) => void;
  onGetQuestion: () => void;
  onGetQuestionResult: () => void;
  onNextChapter: () => void;
}

const ChapterModules: React.FC<Props> = ({ ...props }) => {

  const intl = useIntl();
  // const [loadingVideo, setLoadingVideo] = useState(false);
  const controlsList = 'nodownload';
  const token = localStorage.getItem('token');

  return (
    <div className='card-body'>

      {props.indiceChapter == null ?
        <img src="/media/logos/breven_law_color_logo.svg" />
        :
        <div>
          {
            props.modules.length > 0 ? props.modules.map((item: any, index: number) =>
              <div className={'text-gray-700 fw-bold mb-14'} key={'module_key_' + index}>

                <div>
                  <span className='fs-1 fw-bolder ' dangerouslySetInnerHTML={{ __html: `${item.title}` }} />
                  <hr style={{ marginTop: 0 }} />
                </div>

                {
                  item.blocks && item.blocks.length > 0 ?
                    item.blocks.map((block: any, block_i: number) =>

                      <div key={'block_key_' + block_i} >
                        <div className='blocos' dangerouslySetInnerHTML={{ __html: `${block.title ? block.title : ''}` }} />
                        {
                          block.type === 'text' ?
                            <div className='blocos' dangerouslySetInnerHTML={{ __html: `${block.content}` }} />
                            :
                            block.type === 'video' ?
                              <div>
                        
                                      {
                                        props.playerVideo == `video-player-tutorial_${index}_${block_i}`?
                                          <div
                                            className='mb-3 video-player-tutorial'
                                            id={`video-player-tutorial_${index}_${block_i}`}>

                                            <video id={`example_video_tutorial_${index}_${block_i}`} controls controlsList={`${controlsList}`} className='example_video_tutorial'>
                                              <source id={`video_player_tutorial_${index}_${block_i}`} src="" type=""></source>
                                              Your browser does not support the video tag.
                                            </video>

                                          </div>
                                        :
                                          props.loadingVideo == `video-player-tutorial_${index}_${block_i}` ?
                                            <div className='mb-3 text-white d-flex justify-content-center align-items-center' style={{ background: '#363636', width: '100%', height: '350px', cursor: `${props.loadingVideo ? 'none' : 'pointer'}` }}>
                                              <Load />
                                            </div>
                                          :
                                            <img
                                              style={{ height: '350px', marginBottom: 10, cursor: `${props.loadingVideo ? 'none' : 'pointer'}` }}
                                              role={'button'}
                                              src='/media/image/blank/blank-video-sm.png'
                                              alt="" className='w-100'
                                              onClick={() => [ props.loadingVideo ? null : props.onGetVideo(block.content, 'video', `${index}_${block_i}` ) ]}

                                            />
                                        }
                        
                              </div>
                              :
                              block.type === 'file' ?
                                <div>
                                  {
                                    block.content?.split(',').map((itemBF: any, index_b: any) =>

                                      <div key={index_b}>
                                        <button className='mt-2 button_as_link btn btn-md btn-primary text-dark'
                                          onClick={() => [
                                            props.onGetVideo(itemBF, 'doc', block_i),
                                            // saveAnswersTutorial('noAswser')
                                          ]}
                                        >Visualizar Modelo {index_b + 1}</button>
                                      </div>
                                    )

                                  }
                                </div>
                                :
                                <p style={{ color: 'red' }}>{intl.formatMessage({ id: 'Tipo de bloco desconhecido' })} ({block.type}) : ({block.content})</p>

                        }
                      </div>

                    )
                    :
                    null
                }

              </div>
            )
              :
              intl.formatMessage({ id: 'Conteúdo indisponível no momento!' })
          }

          <div style={{ marginTop: '100px' }}>
            {
              props.finalization ?
                <div className='row col-md-12 mt-5 '>

                  <div className='col-md-9 p-0'>

                    <p
                      style={{ height: '24px', borderLeft: '3px solid #000000' }}

                      className="fw-bold p-0"
                    >&nbsp;&nbsp;
                      {intl.formatMessage({ id: 'Test your new knowledge' })}
                      <span style={{ borderBottom: '1px solid black' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </p>
                  </div>
                  <div className='col-md-2 '>
                    {
                      props.user_nivel > props.chapter_nivel ?
                        <button
                          className='btn btn-outline-secondary fs-7 p-2'
                          style={{ width: '130px', border: '1px solid #6D7185', color: '#6D7185' }}
                          onClick={() => [
                            // setOpenModal(true)
                            props.onGetQuestionResult()
                          ]}
                        > {intl.formatMessage({ id: 'Ver resultado' })}

                        </button>
                        :
                        <button
                          className='btn btn-outline-secondary fs-7 p-2'
                          style={{ width: '130px', border: '1px solid #6D7185', color: '#6D7185' }}
                          onClick={() => [
                            // setOpenModal(true)
                            props.onGetQuestion()
                          ]}
                        > {intl.formatMessage({ id: 'Start Assessment' })}

                        </button>
                    }
                  </div>
                </div>
                :

                <div className='row col-md-12 mt-5 '>
                  <div className='col-md-9 p-0'>
                    {/* <p
                           style={{ height: '24px', borderLeft: '3px solid #000000' }}

                           className="fw-bold p-0"
                         >&nbsp;&nbsp;
                           {intl.formatMessage({ id: 'Test your new knowledge' })}
                           <span style={{ borderBottom: '1px solid black' }}>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           </span>

                         </p> */}
                  </div>

                  <div className='col-md-2 '>
                    {
                      props.user_nivel > props.chapter_nivel ?

                        (props.finalization ?
                          <button
                            className='btn btn-outline-secondary fs-7 p-2'
                            style={{ width: '130px', border: '1px solid #6D7185', color: '#6D7185' }}
                            onClick={() => [
                              // setOpenModal(true)
                            ]}
                          > {intl.formatMessage({ id: 'Ver resultado' })}

                          </button>
                          :
                          '')
                        :
                        // (data && data.data.length>0 ? 

                        <div>

                          <button
                            className='btn btn-outline-secondary fs-7 p-2'
                            style={{ width: '130px', border: '1px solid #6D7185', color: '#6D7185' }}
                            onClick={() => [
                              props.onNextChapter()
                            ]}
                          > {intl.formatMessage({ id: 'Next' })}
                          </button>

                        </div>

                      // :'')
                    }
                  </div>
                </div>

            }
          </div>

        </div>
      }

    </div>
  )
}
export default ChapterModules;