import React, { FC, useState } from 'react'
import { KTSVG } from '../../../helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { countries } from '../../../../app/modules/helpers/country'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import { createCompanies } from '../../../../app/modules/helpers/Api'

const schema = Yup.object().shape({
	company: Yup.string().required('Company is required'),
	corporate_name: Yup.string().required('Corporate name is required'),
	fantasy_name: Yup.string().required('Fantasy name is required'),
	document: Yup.string().required('Document is required'),
})

const CreateCompany: FC = () => {
		const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const [loading, setLoading] = useState(false);
		const [errorManager, setErrorManager] = useState('');
		const [message, setMessage] = useState('');

    const formik = useFormik({
			initialValues: {
				company: '',
				corporate_name: '',
				fantasy_name: '',
				document: '',
				dpo_user_id: '',
				zip_code: '',
				number: '',
				complement: '',
				neighborhood: '',
				city: '',
				state: '',
				phone: '',
				country: '',
				street: '',
				whatsapp_number: '',
				state_reg: '',
				municipal_reg: '',
				instagram: '',
				facebook: '',
				logo: '',
				manager_email: ''
			},
			validationSchema: schema,
			onSubmit: (values) => {
				setLoading(true);
				var error = false;
				setTimeout(() => {
					if(user.roles[0] === 'dpo'){
						if(values.manager_email === ''){
							setErrorManager('Manager mail is required')
							error = true;
						}
					}
					if(!error){store(values)}
					setLoading(false);
				}, 1000)
			},
    })

		function store (value: any){
			const company = {
				company: value.company,
				corporate_name: value.corporate_name,
				fantasy_name: value.fantasy_name,
				document: value.document,
				manager_mail: value.manager_email ? value.manager_email : null,
				dpo_user_id: value.dpo_user_id,
				zip_code: value.zip_code,
				number: value.number,
				complement: value.complement,
				neighborhood: value.neighborhood,
				city: value.city,
				uf: value.state,
				tel: value.phone,
				country: value.country,
				street: value.street,
				whatsapp_number: value.whatsapp_number,
				reg_state: value.state_reg,
				reg_municipal: value.municipal_reg,
				instagram: value.instagram,
				facebook: value.facebook,
				logo: value.logo,
				observation: value.observation,
			}
			const save = createCompanies(company);
			save.then((res)=>{
				setMessage(res.data.message)
			}).catch((error)=>
				setMessage(error.response.data.message)
			)
		}

    return (
        <div className="modal fade" tabIndex={-1} id="kt_modal_create_company">
					<div className="modal-dialog modal-dialog-centered mw-900px">
						<div className="modal-content mh-600px">
							<div className="modal-header">
								<h5 className="modal-title">New Company</h5>
								<div
									className="btn btn-icon btn-sm btn-active-light-primary ms-2"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<KTSVG
										path="/media/icons/duotune/arrows/arr061.svg"
										className="svg-icon svg-icon-2x"
									/>
								</div>
							</div>
							<div className="modal-body w-100 pt-1 " style={{overflow:'auto', overflowX: 'hidden'}}>
								<form onSubmit={formik.handleSubmit} noValidate className='form w-100'>
									<div className='align-items-center w-100'>
										<div className='col-lg-12'>
											<div className='row'>
												<div className='col-lg-6 fv-row'>
													<label className='col-lg-4 col-form-label required fw-bold fs-5'>Company</label>
													<input
														type="text"
														className="form-control form-control-solid fs-5"
														placeholder="Company"
														{...formik.getFieldProps('company')}
													/>
													{formik.touched.company && formik.errors.company && (
														<div className='fv-plugins-message-container'>
															<div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.company}</div>
														</div>
													)}
												</div>
												<div className='col-lg-6 fv-row'>
													<label className='col-lg-6 col-form-label required fw-bold fs-5'>Document (CNPJ)</label>
													<input
														type="text"
														className="form-control form-control-solid fs-5"
														placeholder="Document"
														{...formik.getFieldProps('document')}
													/>
													{formik.touched.document && formik.errors.document && (
														<div className='fv-plugins-message-container'>
															<div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.document}</div>
														</div>
													)}
												</div>
												<div className='col-lg-6 fv-row'>
													<label className='col-lg-4 col-form-label required fw-bold fs-5'>Corporate Name</label>
													<input
														type="text"
														className="form-control form-control-solid fs-5"
														placeholder="Corporate Name"
														{...formik.getFieldProps('corporate_name')}
													/>
													{formik.touched.corporate_name && formik.errors.corporate_name && (
														<div className='fv-plugins-message-container'>
															<div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.corporate_name}</div>
														</div>
													)}
												</div>
												<div className='col-lg-6 fv-row'>
													<label className='col-lg-4 col-form-label required fw-bold fs-5'>Fantasy Name</label>
													<input
														type="text"
														className="form-control form-control-solid fs-5"
														placeholder="Fantasy Name"
														{...formik.getFieldProps('fantasy_name')}
													/>
													{formik.touched.fantasy_name && formik.errors.fantasy_name && (
														<div className='fv-plugins-message-container'>
															<div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.fantasy_name}</div>
														</div>
													)}
												</div>
												{
													user.roles[0] === 'dpo'?
													<div className='col-lg-6 fv-row'>
														<label className='col-lg-6 col-form-label fw-bold required fs-5'>Manager E-mail</label>
														<input
															type="text"
															className="form-control form-control-solid fs-5"
															placeholder="Manager e-mail"
															onKeyUp={()=>setErrorManager('')}
															{...formik.getFieldProps('manager_email')}
														/>
														{errorManager && (
															<div className='fv-plugins-message-container'>
																<div className='fv-help-block text-danger fs-6 mt-1'>{errorManager}</div>
															</div>
														)}
													</div>
													:
													<></>
												}
												<div className='col-lg-6 fv-row'>
													<label className='col-lg-6 col-form-label fw-bold fs-5'>Municipal Registration</label>
													<input
														type="text"
														className="form-control form-control-solid fs-5"
														placeholder="Municipal Registration"
														{...formik.getFieldProps('municipal_reg')}
													/>
												</div>
												<div className='col-lg-6 fv-row'>
													<label className='col-lg-6 col-form-label fw-bold fs-5'>State Registration</label>
													<input
														type="text"
														className="form-control form-control-solid fs-5"
														placeholder="State Registration"
														{...formik.getFieldProps('state_reg')}
													/>
												</div>
												<div className='col-lg-6 fv-row'>
													<label className='col-lg-6 col-form-label fw-bold fs-5'>Logo URL</label>
													<input
														type="text"
														className="form-control form-control-solid fs-5"
														placeholder="Logo"
														{...formik.getFieldProps('logo')}
													/>
												</div>
												<div className='col-lg-12 fv-row'>
													<label className='col-lg-4 col-form-label fw-bold fs-5'>Observation</label>
													<textarea
														rows={3}
														className="form-control form-control-solid fs-5"
														placeholder="Observation"
														{...formik.getFieldProps('observation')}
													/>
												</div>
											</div>
										</div>
										
										<div className='col-lg-12'>
                      <label className='col-lg-4 col-form-label fw-bolder fs-3'>Data Protection Officer</label>
                      <div className='row'>
                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>DPO</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="DPO ID"
                            {...formik.getFieldProps('dpo_user_id')}
                          />
                        </div>
                      </div>
                    </div>

										<div className='col-lg-12'>
                      <label className='col-lg-4 col-form-label fw-bolder fs-3'>Address Details</label>
                      <div className='row'>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Phone</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Phone"
                            {...formik.getFieldProps('phone')}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Postal Code</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Postal code"
                            {...formik.getFieldProps('zip_code')}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>State</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="State"
                            {...formik.getFieldProps('state')}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>City</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="City"
                            {...formik.getFieldProps('city')}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Neighborhood</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Neighborhood"
                            {...formik.getFieldProps('neighborhood')}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Street</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Street"
                            {...formik.getFieldProps('street')}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Number</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Number"
                            {...formik.getFieldProps('number')}
                          />
                        </div>
                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Complement</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Complement"
                            {...formik.getFieldProps('complement')}
                          />
                        </div>
                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Country</label>
                          <select className='form-select form-control form-control-solid fs-6' 
                            {...formik.getFieldProps('country')}
                          >
                            {
                              countries.map((item: any)=>
                                <option key={item.name} value={item.code}>{item.name}</option>
                              )
                            }
                          </select>
                        </div>
                      </div>
                    </div>

										<div className='col-lg-12'>
                      <label className='col-lg-4 col-form-label fw-bolder fs-3'>Social</label>
                      <div className='row'>
                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Whatsapp Number</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Whatsapp Number"
                            {...formik.getFieldProps('whatsapp_number')}
                          />
                        </div>
                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Facebook</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Facebook"
                            {...formik.getFieldProps('facebook')}
                          />
                        </div>
                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Instagram</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Instagram"
                            {...formik.getFieldProps('instagram')}
                          />
                        </div>
                      </div>
                    </div>
										{
                      message !== ''?
                      <div className="alert alert-info d-flex align-items-center p-5 mb-4">                  
                        <div className="d-flex flex-column">
                          <span className='fs-5'>{message}</span>
                        </div>
                      </div>
                      :
                      <></>
                    }
									</div>
									<div className='border-top pt-5 mt-5 d-flex justify-content-end'>
										<button type='button' data-bs-dismiss="modal" className='btn btn-md btn-light ms-4 me-2'>Close</button>
										<button type='submit' className='btn btn-md btn-primary' disabled={loading}>
											{!loading && 'Salvar'}
											{loading && (
												<span className='indicator-progress' style={{display: 'block'}}>
													Processando...{' '}
													<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
												</span>
											)}
										</button>
									</div>
								</form>
							</div>		
						</div>
					</div>
				</div>
    )
}

export { CreateCompany }