/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState } from 'react'
import * as Yup from 'yup'
import { KTSVG } from '../../../helpers'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { createResources } from '../../../../app/modules/helpers/Api'
import { toast } from 'react-toastify'
import {useIntl} from 'react-intl'
import './_resource.scss';

// import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"





type Props = {
  create: (action: boolean) => void;
  id?: any,
  model?: any,
}

const CreateResourceActions: FC<Props> = ({...props}) => {  
  const intl = useIntl(); 
  const[loading, setLoading] = useState(false);
  const [file, setFile] = useState<any>('');
  const [errorFile, setErrorFile] = useState<any>('');
  const [fileType, setFileType] = useState<any>('');
  const [fileSize, setFileSize] = useState<any>();
  const [video, setVideo] = useState<any>('');

  const [uploadValue, setUploaValue] = useState(0);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(null);
  const[upload, setUpload] = useState(false);
  
  const schema = Yup.object().shape({
    title: Yup.string()
    .required(`${intl.formatMessage({id: 'Title'})} ${intl.formatMessage({id: 'is required'})}`),
    type: Yup.string()
    .required(`${intl.formatMessage({id: 'Type'})} ${intl.formatMessage({id: 'is required'})}`),
    free: Yup.string()
    .required(`${intl.formatMessage({id: 'Free'})} ${intl.formatMessage({id: 'is required'})}`),
  })

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const files = target.files as FileList;
    const arrayFiles = Array.from(files);
    setFileType(arrayFiles[0].type);
    setFileSize(arrayFiles[0].size);
    setFile(arrayFiles);
  }
  const uploadVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const files = target.files as FileList;
    const arrayFiles = Array.from(files);
    setFileType(arrayFiles[0].type);
    setFileSize(arrayFiles[0].size);
    setVideo(arrayFiles);
  }

  const initialValues = {
    modelId: '',
    model: '',
    title: '',
    free: false,
    type: '',
    url: '',
    form: '',
    language: ''
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        save(values);
        setLoading(false)
      }, 1000)
    },
  })

  function save (values: any){
    var error = false;


    if(values.type !== '1'){
      
      if(values.type === '3'){
        if(
          fileType !== 'application/pdf' && 
          fileType !== 'image/png' &&
          fileType !== 'image/jpeg' &&
          fileType !== 'image/jpg' &&
          fileType !== 'audio/mp3' &&
          fileType !== 'video/mp4' &&
          fileType !== 'application/vnd.ms-excel' &&
          fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ){
          setErrorFile('File type is invalid!');
          error = true;
        }
      }

      if(values.type === '4'){
        if(!['video/mp4','video/quicktime'].includes(fileType)){
          setErrorFile('File type is invalid!');
          error = true;
        }
      }
     

    }

    if(!error){
      var formData = new FormData();
      formData.append('modelId', props.id? props.id : values.modelId);
      formData.append('free', values.free? '1' : '0');
      formData.append('title', values.title);
      formData.append('type', values.type);
      formData.append('url', values.url);
      formData.append('form', values.form);
      formData.append('video', video[0]);
      formData.append('file', file[0]);
      formData.append('model', props.model? props.model : '');
      formData.append('language', values.language)

      setUpload(true);
      setUploadErrorMessage(null)

      const create = createResources(formData, handleBack);
      // create.then((res)=>{
      //   if(res.data.status === 'success'){
      //     toast.success(res.data.message);

      //     setUploadErrorMessage(res.data.message)

      //   }
      //   if(res.data.status === 'error'){
      //     toast.error(res.data.message);
      //     setUploadErrorMessage(res.data.message)
      //   }
      // }).catch((error)=>{

      //   let errorMessage = error.response.data.message ? 
      //   error.response.data.message : (error.response.statusText ? error.response.statusText : 'Não foi possivel enviar!');

      //   setUploadErrorMessage(errorMessage)
      //   toast.error(errorMessage);
      // });
    }
  }

  function handleBack (){
    props.create(false)
  }

  return (
   <>
    <div>
          <h3 className='card-title align-items-start flex-column'>
              <p className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: 'New resource'})}</p>
              <p className='text-gray-600 fw-bold fs-7'>{intl.formatMessage({id: 'Home - Resources'})} - {intl.formatMessage({id: 'New resource'})}</p>
            </h3>
      </div>
    <div className='card mb-5 mb-xl-10 rounded-4'>
      <div className='collapse show '>
        <form onSubmit={formik.handleSubmit} noValidate className='form '>
          <div className='card-body border-top px-5 py-5 '>
            <div className='d-flex justify-content-end border-2 border-bottom-dashed border-secondary pb-4 align-items-center mb-3'>
              <button onClick={()=>props.create(false)} type='button' className='btn btn-sm btn-secondary p-3'>
              <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark'/>
              {intl.formatMessage({id: 'Back'})}
              </button>
            </div>
            
            <div className='mb-2 fv-row  ' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Type'})}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('type')}
                    >
                      <option value=''>...{intl.formatMessage({id: 'Select an option'})}</option>
                      <option value='1'>URL</option>
                      <option value='2'>{intl.formatMessage({id: 'Form'})}</option>
                      <option value='3'>{intl.formatMessage({id: 'File'})}</option>
                      <option value='4'>{intl.formatMessage({id: 'Video'})}</option>
                      {props.model==='system'?<option value='5'>{intl.formatMessage({id: 'User Term'})}</option>:null}
                      </select>
                  </div>
                  {formik.touched.type && formik.errors.type && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.type}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='mb-2 fv-row  ' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Language'})}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('language')}
                    >
                      <option value=''>...{intl.formatMessage({id: 'Select an option'})}</option>
                      {/* <option value='todos'>{intl.formatMessage({id: 'All'})}</option> */}
                      <option value='en'>{intl.formatMessage({id: 'English'})}</option>
                      <option value='pt'>{intl.formatMessage({id: 'Portuguese'})}</option>
                    
                    </select>
                  </div>
                  {formik.touched.language && formik.errors.language && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.language}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>



              <div className='mb-2 fv-row ' data-kt-password-meter='true'>
              <div className='mb-1 '>
                <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Title'})}</label>
                

                { formik.values.type === '5'?
                 
                  <div className='position-relative mb-3'>
                    <div className='d-flex justify-content-between w-100'>
                      <select className='form-select form-control form-control-solid fs-6' 
                        {...formik.getFieldProps('title')}
                      >
                        <option value=''>...{intl.formatMessage({id: 'Select an option'})}</option>
                        <option value='User term dpo'>DPO</option>
                        <option value='User term company'>{intl.formatMessage({id: 'Company'})}</option>
                        <option value='User term adv'>ADV</option>
                        <option value='User term liberal'>{intl.formatMessage({id: 'Liberal'})}</option>
                        <option value='User term startup'>{intl.formatMessage({id: 'Startup'})}</option>
                        <option value='User term'>{intl.formatMessage({id: 'User term'})}</option>
                      </select>
                    </div>
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.title}</span>
                        </div>
                      </div>
                    )}
                  </div>
                 :
                 <div className='position-relative mb-3'>
                    <div className='d-flex justify-content-between w-100'>
                      <input
                          type='text' 
                          placeholder='Title'
                          autoComplete='off'
                          {...formik.getFieldProps('title')}
                          className='form-control form-control-lg form-control-solid w-100 me-1'
                        />
                    </div>
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.title}</span>
                        </div>
                      </div>
                    )}
                  </div>
                 
                }


              </div>
            </div>




            {
              formik.values.type === '2'?
              <div className='mb-2 fv-row ' data-kt-password-meter='true'>
                <div className='mb-1 mt-4'>
                  <label className='form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Form code'})}</label>
                  <div className='position-relative mb-3'>
                    <div className='d-flex justify-content-between w-100'>
                      <input
                        type='text' 
                        placeholder={`${intl.formatMessage({id: 'Form code'})}`}
                        autoComplete='off'
                        {...formik.getFieldProps('form')}
                        className='form-control form-control-lg form-control-solid w-100 me-1'
                      />
                    </div>
                  </div>
                </div>
              </div>
              :
              null
            }
           
            
            {
              formik.values.type === '1'?
              <div className='mb-2 fv-row ' data-kt-password-meter='true'>
                <div className='mb-1 mt-4'>
                  <label className='form-label fw-bold text-dark fs-6'>URL</label>
                  <div className='position-relative mb-3'>
                    <div className='d-flex justify-content-between w-100'>
                      <input
                        type='text' 
                        placeholder='URL'
                        autoComplete='off'
                        {...formik.getFieldProps('url')}
                        className='form-control form-control-lg form-control-solid w-100 me-1'
                      />
                    </div>
                  </div>
                </div>
              </div>
              :
              <>
                {
                  formik.values.type === '3' || formik.values.type === '5' || formik.values.type === '6'?
                  <div className='mb-2 fv-row' data-kt-password-meter='true'>
                    <div className='mb-1 mt-4'>
                      <label className='form-label fw-bold text-dark fs-6'>
                      {intl.formatMessage({id: 'File'})} 
                      </label>
                      <div className='position-relative mb-3'>
                        <div className='d-flex justify-content-between w-100 mb-1'>
                          <input
                            type='file' 
                            onChange={(event)=>[uploadFile(event), setErrorFile('')]}
                            className='form-control form-control-lg form-control-solid w-100 me-1'
                          />
                        </div>
                        <small>{intl.formatMessage({id: 'Format'})}: PDF, PNG, JPEG, JPG, MP3, XLS, XLSX; Max upload: 8MB</small>
                      </div>
                      {errorFile && (
                        <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errorFile}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  :
                  <>
                    {
                      formik.values.type === '4'?
                      <div className='mb-2 fv-row' data-kt-password-meter='true'>
                        <div className='mb-1 mt-4'>
                          <label className='form-label fw-bold text-dark fs-6'>
                          {intl.formatMessage({id: 'Video'})}
                          </label>
                          <div className='position-relative mb-3'>
                            <div className='d-flex justify-content-between w-100 mb-1'>
                              <input
                                type='file' 
                                onChange={(event)=>[uploadFile(event), setErrorFile('')]}
                                className='form-control form-control-lg form-control-solid w-100 me-1'
                              />
                            </div>
                            <small>{intl.formatMessage({id: 'Format'})}: MP4, MOV; Max upload: 500MB</small>
                          </div>
                          {errorFile && (
                            <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errorFile}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      :
                      <></>
                    }
                  </> 
                }
              </>
            }


            <div className='fv-row my-5 '>
              <div className='form-check form-check-custom form-check-solid  '>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='kt_login_toc_agree'
                  {...formik.getFieldProps('free')}
                />
                <label
                  className='form-check-label fw-bold text-gray-700 fs-6'
                  htmlFor='kt_login_toc_agree'
                >
                 {intl.formatMessage({id: 'Private resource'})} 
                </label>
                {formik.touched.free && formik.errors.free && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.free}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            { upload ? 
              <div className='my-15 '>
                <div className='text-gray-600 fw-bold fs-7 text-center'>{intl.formatMessage({id: 'PROGRESS'})}</div>
                <div className='border-dashed border-gray-300 border- p-5 hover pointer rounded'>

                  <progress id="upload_bar_progress_1" max="100" value={uploadValue}></progress>
                  { uploadErrorMessage ? <p>{uploadErrorMessage}</p> : null }
                  <p id="dialog_upload_span" />
                </div>
              </div>
            : null}

          </div>

        

         
          
          <div className='border-2 border-top-dashed border-secondary d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary text-dark' disabled={loading}>
              {!loading && intl.formatMessage({id: 'Save'})}
              {loading && (
                <span className='indicator-progress ' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'Processing'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
    </>
  )
}

export {CreateResourceActions}
