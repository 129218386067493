import React from 'react';
import { useIntl } from 'react-intl'

type Props = {
  title: string;
  description: string;
  onEdit: () => void;
}

const ActionPlanTitleCard: React.FC<Props> = ({...props}) => {
  const intl = useIntl();   

  return(
    <div className='card-body border-top px-5 py-5'>
      <div className='mx-4 align-items-center'>
        <div className='col-lg-12'>
          <div className='row'>
            <div className='col-lg-6 d-flex flex-column mb-3'>
              <label className='col-lg-4 col-form-label fw-bolder fs-4 text-dark'>{intl.formatMessage({id: 'Title'})}</label>
              <span className='fw-bold text-gray-700 fs-5'>{props.title}</span>
            </div>
            <div className='col-lg-6 d-flex flex-column mb-3'>
              <label className='col-lg-4 col-form-label fw-bolder fs-4 text-dark'>{intl.formatMessage({id: 'Description'})}</label>
              <span className='fw-bold text-gray-700 fs-5'>{props.description}</span>
            </div>
          </div>
        </div>
        <div className='d-flex flex-row w-100 justify-content-end'>
          <button
            type="button"
            className="btn btn-primary btn-sm text-dark"
            onClick={()=>props.onEdit()}
            title={intl.formatMessage({id: 'Edit action plan'})} 
          >
            {intl.formatMessage({id: 'Edit'})}
          </button>
        </div>
      </div>
    </div>
  )
}
export default ActionPlanTitleCard;