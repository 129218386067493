import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts'
import { useIntl } from 'react-intl'

type Props = {
  graph: Array<number>,
  total: number
}

const ActionPlanGraphModal: React.FC<Props> = ({...props}) => {
  const intl = useIntl();   

  const options: any = {
    chart: {
      type: 'donut',
    },
    labels: [`${intl.formatMessage({id: 'Success'})}`, `${intl.formatMessage({id: 'Warning'})}`, `${intl.formatMessage({id: 'Fail'})}`],
    responsive: [{
      breakpoint: 700,
      options: {
        chart: {
          width: 250
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    colors: ['#00e296', '#fdb018', '#F44336'],
    plotOptions: {
      pie: {
        donut: {
          size: `${window.screen.width < 700 ? '25%' : '65%' }`,
          customScale: 0.1,
          labels: {
            show: true,
            name: {
              show: true
            },
            value: {
              show: true
            },
          }
        }
      }
    }
  }

  return(
    <>
    <div className='row'>
      <div className='col-sm-7 d-flex flex-column align-items-center '><ReactApexChart width={'400px'} options={options} series={props.graph} type='donut'/></div>
        <div className='col-sm-5 d-flex p-4'>
          <div style={{minWidth: '150px', borderColor: '#EEE346'}} className='p-4 border-dashed border-1 bg-light-primary w-100 d-flex flex-column rounded align-items-center justify-content-center'>
            <h1 className='text-dark fw-bolder  mt-4 ' style={{fontSize: '36px'}}>{props.total<10? ('0'+props.total) :props.total}</h1>
          <div className={`fw-bold text-gray-700 mb-4 ${window.screen.width < 600 ? 'fs-8' : 'fs-6' }`}>{intl.formatMessage({id: 'Updated suitability level'})}</div>
        </div>
      </div>
    </div>
    </>
  )
}
export default ActionPlanGraphModal;