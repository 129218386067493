/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { deleteAnswer, getAnswers, defaultPaginationList, getHoldeRequest } from '../../../../../app/modules/helpers/Api'
import { converteData, copyText } from '../../../../../app/modules/helpers/helpers'
import { KTSVG, toAbsoluteUrl } from '../../../../helpers'
import { UserModel } from '../../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import moment from "moment";
// import ModalActionPlansReport from './ActionsPlansReport/modalActionPlanReport'
// import ModalReportGraph from './Report/ModalReportGraph'
import ModalMessage from '../../modals/modalMessage'
import { Load } from '../../spinner/Spinner'
import Warning from '../../warning/Warning'

import Pagination from '../../../../helpers/components/CustomPagination/CustomPagination'

import ModalNewPreferenceConsent from './ModalNewPreferenceConsent'
import ModalViewPreferenceConsent from './ModalViewPreferenceConsent'
import ModalListPreferenceContents from './ModalListPreferenceContents'
import ModalSendMailConsent from './ModalSendMailConsent'

const currentLocation = window.location.origin;

let searchData = {
  model: '',
  model_id: 0,
  search_value: '',
  status: '',
  page: 1,
  perpage: 10
}


type Props = {
  // company_id: number;
  msg: (status: any, message: any) => void;
}

const PreferencesConsent: React.FC<Props> = ({ ...props }) => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const intl = useIntl();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [idAnswer, setIdAnswer] = useState(0);
  const [reportPosition, setReportPosition] = useState<any>();
  const [dataDegreeId, setDataDegreeId] = useState('');
  const [isLoading2, setIsLoading2] = useState(false);
  const [statusFilter, setStatusFilter] = useState<any>();
  const [searchValueFilter, setSearchValueFilter] = useState<any>();
  const [typeFilter, setTypeFilter] = useState<any>();



  const [openModalNewConsent, setOpenModalNewConsent] = useState(false);
  const [openModalListConsents, setOpenListConsents] = useState(false);
  const [openModalViewPreferenceConsent, setOpenModalViewPreferenceConsent] = useState(false);
  const [holdeRequestData, setHoldeRequestData] = useState([]);

  const [prefConsentUuid, setPrefConsentUuid] = useState('');



  const [viewConsent, setViewConsent] = useState('');
  const [holderRequest, setHolderRequest] = useState([]);


  const [mailConsent, setMailConsent] = useState([]);
  const [openModalSendmailConsent, setOpenModalSendmailConsent] = useState(false);



  // searchData.prescription_name = prescriptionFilter;
  // searchData.status = statusFilter;
  // searchData.model = modelFilter;
  useEffect(() => {
    handleGetList(null)
  }, [isLoading2]);

  function handleGetList(data: null) {

    setLoading(true);

    searchData.search_value = searchValueFilter ? searchValueFilter : '';
    searchData.status = statusFilter ? statusFilter : '';
    searchData.model = 'Company';

    const get = defaultPaginationList(`company/preference_consent`, searchData)
    get.then((res) => {

      if (res.data.status === 'success') {
        setData(res.data.data);
      }

    }).catch((error) => {
      toast.error(error.response.data.message)
    })
      .finally(() => [setLoading(false), setIsLoading2]);
  }

  function handleDelete(id: any) {
    const del = deleteAnswer(id);
    del.then((res) => {
      if (res.data.status === 'success') {
        toast.success(res.data.message);
        setOpenModalDelete(false);
      }
      if (res.data.status === 'error') {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      if (error.response) {
        toast.error(error.response.message);
      } else {
        toast.error(`${intl.formatMessage({ id: 'Sorry, an error occurred' })}`);
      }
    }).finally(() =>
      setIsLoading2(true)
    );
  }

  function handleGetAnswers(item: any) {

    setPrefConsentUuid(item.uuid);
    setOpenListConsents(true)
  }

  function getFormSelected(id: any, obj: any) {
    // setIdForm(id);
    // setForm(obj.name);
    // setModelForm(obj.model);
    // setOpenModalCalculatorTitle(true);
    // setOpenModalCalculatorType(false);
  }



  const [openModalActionPlans, setOpenModalActionPlans] = useState(false);
  const [openModalReportGraph, setOpenModalReportGraph] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [idAnswerReport, setIdAnswerReport] = useState('');

  return (
    <>
      {/* <ModalActionPlansReport 
        id={idAnswerReport} 
        title={`${intl.formatMessage({id: 'New Action Plan'})}`} 
        showModal={openModalActionPlans}
        onClose={()=>setOpenModalActionPlans(false)} 
      />
      {
        dataDegreeId !== ''?
        <ModalReportGraph
          title= {`${intl.formatMessage({id: 'Report:'})} ${intl.formatMessage({id: 'Maturity analysis'})}`}
          id={dataDegreeId}
          reportPosition={reportPosition}
          showModal={openModalReportGraph}
          onClose={()=>setOpenModalReportGraph(false)}
        />
        :
        <></>
      } */}
      <ModalMessage
        message={`${intl.formatMessage({ id: 'Are you sure you want to delete this item?' })} `}
        title={`${intl.formatMessage({ id: 'Attention' })} `}
        showModal={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        onAction={() => handleDelete(idAnswer)}
      />
      <div className='card mb-xl-10 rounded-4'>
        <div className='w-100 container'>
          <div className='form'>
            <div className=" row  py-6 d-flex " >

              <div
                className="col-md-3"
              >

                <input
                  type="text"
                  className="form-control form-control-solid fs-6 rounded-3 text-gray-400"
                  placeholder={intl.formatMessage({ id: 'Search' })}
                  name="search_value"
                  autoComplete='off'
                  value={searchValueFilter}
                  onChange={(e) => [setSearchValueFilter(e.target.value)]}
                />

              </div>

              <div
                className='col-md-3'
              >
                <select className='form-select form-control form-control-solid fs-6 text-gray-400'
                  name="status"
                  value={statusFilter}
                  onChange={(e) => [setStatusFilter(e.target.value)]}

                >
                  <option value='' >{intl.formatMessage({ id: 'All' })}</option>
                  <option value='active' >{intl.formatMessage({ id: 'Active' })}</option>
                  <option value='old'>{intl.formatMessage({ id: 'Old' })}</option>
                </select>
              </div>


              <div className='col-12 col-sm-12 col-md-6 d-flex justify-content-end' >


                <div className='row  d-flex'>

                  <div className='col-sm-12 d-flex'>

                    <button type='button' className='btn btn-primary text-dark ' disabled={loading} onClick={() => handleGetList(null)}>
                      {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Search' })}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>

                    <button type='button' className='btn btn-primary text-dark mx-2' disabled={loading}
                      onClick={() => setOpenModalNewConsent(true)}
                    >
                      {intl.formatMessage({ id: 'New' })}
                    </button>


                  </div>

                </div>



              </div>



            </div>
          </div>
        </div>






        <div className='card-body py-3'>




          <div className='mt-5 table-responsive'>
            {
              loading ?
                <Load />
                :
                <>
                  {
                    data.data && data.data.length === 0 ?
                      <Warning message={intl.formatMessage({ id: 'No record found' })} />
                      :
                      <div>
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                          <thead>
                            <tr className='fw-bolder text-muted'>

                              <th className='min-w-30px text-center'>{intl.formatMessage({ id: 'Actions' })}</th>
                              <th className='min-w-200px text-center'>{intl.formatMessage({ id: 'Company' })}</th>
                              <th className='min-w-200px text-center'>{intl.formatMessage({ id: 'Title' })}</th>
                              <th className='min-w-100px text-center'>{intl.formatMessage({ id: 'Status' })}</th>
                              <th className='min-w-200px text-center'>{intl.formatMessage({ id: 'User' })}</th>
                              <th className='min-w-200px text-center'>{intl.formatMessage({ id: 'Updated in' })}</th>

                            </tr>
                          </thead>
                          <tbody>
                            {data.data?.map((item: any, index: any) =>
                              <tr key={index}>

                                <td className='d-flex align-items-center justify-content-center'>


                                  <button disabled={item.status == 'old'} className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1' onClick={() => copyText(`${currentLocation}/management/preference_consent/${item.lang ? item.lang : 'pt'}/${item.uuid}/link/response`, intl)} title={`${intl.formatMessage({ id: 'Copy' })}`}>
                                    <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-3 svg-icon-primary' />
                                  </button>

                                  <button className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1' onClick={() => [setViewConsent(item.uuid), setOpenModalViewPreferenceConsent(true)]} title={`${intl.formatMessage({ id: 'Edit' })}`}>
                                    {item.status == 'old' ?
                                      <i className="fas fa-eye text-primary"></i>
                                      :
                                      <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-3 svg-icon-primary' />
                                    }
                                  </button>

                                  <button className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                    // onClick={() => [setHolderRequest(item), setOpenListConsents(true)]} 
                                    onClick={() => handleGetAnswers(item)}
                                    title={`${intl.formatMessage({ id: 'List' })}`}>
                                    <KTSVG path='/media/icons/duotune/general/gen053.svg' className='svg-icon-3 svg-icon-primary' />
                                  </button>

                                  <button disabled={item.status == 'old'} className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1' onClick={() => [setOpenModalSendmailConsent(true), setMailConsent(item)]} title={`Enviar E-mail`}>
                                    <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-3 svg-icon-primary' />
                                  </button>


                                  {/* <button  className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm' disabled >
                                <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _trash_.svg')} style={{marginRight: '10px'}} />
                              </button> */}
                                </td>

                                <td>
                                  <span className='text-gray-800 fw-bold d-block mb-1 fs-6  text-center'>
                                    {item.company}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-gray-800 fw-bold d-block mb-1 fs-6  text-center'>
                                    {item.title}
                                  </span>
                                </td>

                                <td>
                                  <span className='text-gray-800 fw-bold d-block fs-6  text-center'>
                                    {
                                      item.status === 'active' ?
                                        <span className='badge badge-light-success'>{intl.formatMessage({ id: 'Active' })}</span>
                                        : item.status === 'inactive' ?
                                          <span className='badge badge-light-danger'>{intl.formatMessage({ id: 'Inactive' })}</span>
                                          : item.status === 'old' ?
                                            <span className='badge badge-light-secondary'>{intl.formatMessage({ id: 'Old' })}</span>
                                            : '- - -'
                                    }
                                  </span>
                                </td>

                                <td>
                                  <span className='text-gray-800 fw-bold d-block mb-1 fs-6  text-center'>
                                    {item.user ? item.user : '- - -'}
                                  </span>
                                </td>

                                <td>
                                  <span className='text-gray-800 fw-bold d-block mb-1 fs-6  text-center'>
                                    {item.updated_at ? converteData(item.updated_at) : '- - -'}
                                  </span>
                                </td>


                              </tr>
                            )}
                          </tbody>
                        </table>
                        <Pagination data={data} onSearch={handleGetList} searchData={searchData} />
                      </div>
                  }
                </>
            }
          </div>







          <ModalNewPreferenceConsent
            title={intl.formatMessage({ id: 'New Calculator' })}
            showModal={openModalNewConsent}
            onClose={() => setOpenModalNewConsent(false)}
            onAction={getFormSelected}
            // company_id={props.company_id}
            handleGetList={() => handleGetList(null)}
          />

          <ModalViewPreferenceConsent
            showModal={openModalViewPreferenceConsent}
            onClose={() => setOpenModalViewPreferenceConsent(false)}
            onAction={getFormSelected}
            consentUuid={viewConsent}
            handleGetList={() => handleGetList(null)}
          />

          <ModalListPreferenceContents
            showModal={openModalListConsents}
            onClose={() => setOpenListConsents(false)}
            refUuid={prefConsentUuid}
            moment={moment()}
          />

          <ModalSendMailConsent
            showModal={openModalSendmailConsent}
            onClose={() => setOpenModalSendmailConsent(false)}
            consentData={mailConsent}
          />


        </div>
      </div>

    </>
  )
}

export { PreferencesConsent }
