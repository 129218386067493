import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import TutorialChapters from './partials/TutorialChapters';
import { defaultPaginationList, imprimirCertificado, getTutorialChapters, getModuleChapters} from '../../../../../app/modules/helpers/Api';
import { Load } from '../../spinner/Spinner';
import $ from "jquery";
import './_style.scss';
import { UserModel } from '../../../../../app/modules/auth/models/UserModel';
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../../setup';
import { limitString} from '../../../../../app/modules/helpers/helpers';
import { useIntl } from 'react-intl'

let searchData = {
  page: 1,
  perpage: 10,
}

const TutoriaisFeed: React.FC = ({ ...props }) => {

  const [change, setChange] = useState('treinamento');
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>()
  const [currentChapter, setcurrentChapter] = useState<string>('');
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const api = process.env.REACT_APP_API_ENDPOINT + '/api'
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`
  }

  const intl = useIntl();
  const [currentUserNivel, setCurrentUserNivel] = useState(0);
  const [tutorialChapters, setTutorialChapters] = useState<any>([]);
  const [chapterModules, setChapterModules] = useState<any>([]);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [indiceChapter, setIndiceChapter] = useState<any>(null);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [playerVideo, setPlayerVideo] = useState(false);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    handleGetList(null)
  }, [loadingScreen]);

  useEffect(() => {
    HandleSetIndex()
  }, [data]);
  
  function handleGetList(nulo: null) {

    setCurrentUserNivel(0);
    setIndiceChapter(0);
    setTutorialChapters([]);
    setLoading(true);

    const get = defaultPaginationList('tutorials', searchData)
    get.then((res) => {
      setData(res.data.data); 
    }).catch((error) => {
      toast.error(error.response.data.message)
    })
    .finally(() => [

    ]);
  }

  function handleChangeChapter() {

    setLoadingVideo(false)
    setPlayerVideo(false)

    var video = document.getElementById('example_video_tutorial');
    if (video) {
      var source = document.getElementById('video_player_tutorial');
      // @ts-ignore: Object is possibly 'null'.
      video.pause()
      // @ts-ignore: Object is possibly 'null'.
      source.setAttribute('src', '');
      // @ts-ignore: Object is possibly 'null'.
      source.setAttribute('type', '');
      // @ts-ignore: Object is possibly 'null'.
      video.load();
  
    }
   
  }

  function HandleSetIndex() {

    if (data && data.length>0) {
      data[0].chapters.map((item: any, index: any) =>
        item.chapter_nivel === currentUserNivel - 1 ?
          setIndiceChapter(index)
          :
          ''
      )
    }
     
    setLoading(false)
  }

  function handleGetTutorialChapter(item: any = null) {

    let itemId = null;

    if (item && item.id) {
      setSelectedTutorial(item.id)
      setChange(item.id)
      itemId = item.id
    } else {
      itemId = selectedTutorial
    }

    setIndiceChapter(null);
    setTutorialChapters([]);
    setChapterModules([]);

    const send = getTutorialChapters(itemId);

    send.then((res) => {

      if (res.data.status === 'success') {
        setTutorialChapters(res.data.data.data);
        setCurrentUserNivel(res.data.user_nivel);
        $(`#button_title_${res.data.user_nivel}`).trigger("click")
      }
      if (res.data.status === 'error') {
        toast.error(res.data.message);
      }
      if (res.data.status === 'warning') {
        handleGetList(null)
        window.location.reload()
      }
    }).catch((error) => {
      if (error.response) {
        toast.error(error.response.message);
      }
    }).finally(() => {
      setLoading2(false);
    });

  }

  function loadSelectedChapter(index:any, item:any) {

    let keyTutorial = `t${item.tutorial_id}_c${item.id}`;

    if (currentChapter == keyTutorial) {
      return;
    }
   
    const send = getModuleChapters(item.tutorial_id, item.id);

    send.then((res) => {

      handleChangeChapter();

      if (res.data.status === 'success') {

        setChapterModules(res.data.data.data);
        setIndiceChapter(index);

        $(window).scrollTop(0);

      }
      else if (res.data.status === 'level without') {
        toast.warning(res.data.message);
      }
      else if (res.data.status === 'error') {
        toast.error(res.data.message);
      }
      else if (res.data.status === 'warning') {
        window.location.reload()
      }
    }).catch((error) => {
      if (error.response) {
        toast.error(error.response.message);
      }
    }).finally(() => {
      // setLoading2(false);
    });
  }

  return (
    <>
      {
        loading ?
          <Load />
          :
          <div className='container bg-white p-3  rounded-4 w-90 mt-5'>

            {
              data?.data.length == 0 ? 
              <div>

                <div className='row d-flex  p-1'>
                  <div className='col-md-12 mb-5'>
                    <img src="/media/logos/breven_law_color_logo.svg" />
                  </div>
                  <div className='text-center'>{intl.formatMessage({ id: 'Tutoriais indisponíveis no momento!' })}</div>
                </div>

              </div>

              :
            
              <div>

                <div className='d-flex overflow-auto h-55px border-bottom' style={{ maxWidth: '100%', overflowX: 'scroll' }}>
                  <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                    {
                      data?.data.map((item: any, index: number) =>
                        <li className='nav-item' key={index}>
                          <button
                            disabled={item.status == 'inactive' ? true : false }
                            onClick={() => [ item.status == 'inactive' ? null : handleGetTutorialChapter(item) ]}
                            className={
                              ` nav-link text-active-primary me-6  ` +
                              (change == `${item.id}` && 'active')
                            }
                            style={{ whiteSpace: 'nowrap', backgroundColor:'white' }}
                            id={`tab_tutorial_${index}`}
                          >
                            <div className='text-center' style={{textDecoration: `${item.status == 'inactive' ? 'line-through' : 'none'}`}}>
                              <div>{item.status=='test'? '(Test)' : null } {item.titulo}</div>
                              <div style={{fontSize: '12px'}}> {limitString(item.sub_title, 25)}</div>
                            </div>
                            
                          </button>
                        </li>
                      )
                    }

                  </ul>
                </div>

                <TutorialChapters 
                  selectedTutorial={selectedTutorial}
                  chapters={tutorialChapters}
                  user_nivel={currentUserNivel}
                  onGetChapters={handleGetTutorialChapter}
                  onSetIndiceChapter={setIndiceChapter}
                  indiceChapter={indiceChapter} 
                  chapterModules={chapterModules}
                  onLoadSelectedChapter={loadSelectedChapter}
                />

              </div>

            }
          
          </div>
      }
    
    </>
  )
}
export default TutoriaisFeed;
