import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { RouteComponentProps } from 'react-router';
import { storeManagementHolderRequest, verifyTypeRequest } from '../../modules/helpers/Api';
import { useIntl } from 'react-intl'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { trataRespota } from '../../../app/modules/helpers/helpers'
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import { useHistory } from 'react-router-dom';
import { Load } from '../../../_metronic/partials/widgets'
interface MatchParams {
  lang: string;
  token: string;
}
interface Answer {
  [index: number]: string;
}
const initialValues = {
  email: '',
  name: '',
  rights: '',
  description: '',
  anonimo: false
}

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

const ManagementHolderRequest: React.FC<RouteComponentProps<MatchParams>> = (props) => {

  const [loading, setLoading] = useState(false)
  const lang = useLang()
  const intl = useIntl();
  const urlLang = props.match.params.lang;
  const token = props.match.params.token;
  const [anonimo, setAnonimo] = useState(false);
  const [report, setReport] = useState(false);
  const [decision, setDecision] = useState(0);
  const [questions, setQuestions] = useState<any>([]);

  const history = useHistory();


  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  if (['pt', 'en'].includes(urlLang) && lang !== urlLang) {
    // setLanguage(urlLang)
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: urlLang }))
    window.location.reload()
  }

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: 750,
    maxHeight: 600,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: 1,
    boxShadow: 24,
    outline: 'none'
  };

  const registrationSchema = Yup.object().shape({
    email: anonimo ? Yup.string()
      .email(`${intl.formatMessage({ id: 'O valor não é um endereço de e-mail válido' })}`)
      .max(50, `${intl.formatMessage({ id: 'Máximo de 50 símbolos' })}`)
      : Yup.string()
        .email(`${intl.formatMessage({ id: 'O valor não é um endereço de e-mail válido' })}`)
        .max(50, `${intl.formatMessage({ id: 'Máximo de 50 símbolos' })}`)
        .required(`${intl.formatMessage({ id: 'E-mail' })} ${intl.formatMessage({ id: 'is required' })}`),
    name: anonimo ? Yup.string() : Yup.string()
      // .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
      .required(`${intl.formatMessage({ id: 'Name' })} ${intl.formatMessage({ id: 'is required' })}`),
    rights: report ? Yup.string() : Yup.string()
      // .max(50, `${intl.formatMessage({id: 'Máximo de 50 símbolos'})}`)
      .required(`${intl.formatMessage({ id: 'Rights' })} ${intl.formatMessage({ id: 'is required' })}`),
    description: Yup.string()
      .required(`${intl.formatMessage({ id: 'Description' })} ${intl.formatMessage({ id: 'is required' })}`),
    anonimo: Yup.boolean()
  })

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      const res = await verifyTypeRequest(token);
      if (res.data.status === 'success') {
        setReport(res.data.type);
        res.data.type ? setDecision(0) : setDecision(1)
        // 0 a decidir, 1 identificar, 2 anonimo
        setQuestions(res.data.data.requeriments_questions)
        setLoading(false)

      }
    };

    fetchData();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleStore(values);
    },
  })


  const [responseMessage, setResponseMessage] = useState('');
  const [responseCode, setResponseCode] = useState('');


  const [answers, setAnswers] = useState<Answer>({});

  const handleAnswerChange = (questionId: number, answer: string) => {
    setAnswers((prevAnswers: any) => ({
      ...prevAnswers,
      [questionId]: answer
    }));
  };

  function handleStore(values: any) {

    values.answers = answers
    values.token = token
    values.lang = lang
    values.anonimo = anonimo

    setLoading(true);

    const create = storeManagementHolderRequest(values);

    create.then((res) => {

      if (res.data.status === 'success') {

        formik.resetForm()

        // toast.success(res.data.message);
        setResponseCode(res.data.code)
        setResponseMessage(res.data.message)
        setOpenModalConfirm(true)
        setAnswers([])
      }
      else if (res.data.status === 'error') {
        // toast.error(res.data.message);

        setResponseMessage(res.data.message)
        setOpenModalConfirm(true)
      }

    }).catch((error) => {
      trataRespota('error', error);
    }).finally(() => {
      setLoading(false)

    });
  }


  return (

    <div className='d-flex flex-column bg-gray-middle flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/logos/background.png')})`,
        backgroundPosition: 'center',
        backgroundColor: '#1E1E2D',
        backgroundSize: 'cover'
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20'>

        <div className='w-lg-600px bg-white rounded-5 shadow-sm p-10 p-lg-15 mx-auto'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='mb-10 text-center'>
              <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'Requirement' })}</h1>
              {/* <div className='text-dark fw-bold fs-4'>Company Example Name LTDA.</div> */}
            </div>

            <div className='border-bottom border-gray-300 w-100 mb-6'></div>

            {loading ?
              <Load /> : <>

                {decision == 0 ?
                  <>
                    <p className='text-center'>Como deseja responder a esse requerimento?
                    </p>
                    <div className="text-center">
                      <button className='m-2 btn btn-primary text-white' onClick={() => { setDecision(1) }}>identificado</button>
                      <button className='m-2 btn bg-hover-dark text-white' style={{ background: '#65638e' }} onClick={() => [setDecision(2), setAnonimo(true)]}>Anônimo</button>
                    </div>
                  </>
                  :


                  <>

                    {anonimo ?
                      ''
                      :
                      <>
                        <div className='fv-row mb-3'>
                          <label className={`form-label fw-bold text-dark fs-5 ${!anonimo ? 'required' : ''}`}>
                            {intl.formatMessage({ id: 'E-mail' })}
                          </label>
                          <input
                            disabled={loading || anonimo}
                            placeholder={intl.formatMessage({ id: 'E-mail' })}
                            type='email'
                            autoComplete='off'
                            {...formik.getFieldProps('email')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid',
                              { 'is-invalid': formik.touched.email && formik.errors.email },
                              {
                                'is-valid': formik.touched.email && !formik.errors.email,
                              }
                            )}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.email}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    }

                    <div className='row fv-row mb-2'>
                      {anonimo ?
                        ''
                        :
                        <>
                          {/* Nome */}
                          <div className={` ${report ? 'col-xl-12' : 'col-xl-6'}`}>
                            <div className='fv-row mb-3'>
                              <label className={`form-label fw-bold text-dark fs-5 ${!anonimo ? 'required' : ''}`}>
                                {intl.formatMessage({ id: 'Name' })}</label>
                              <input
                                disabled={loading || anonimo}
                                placeholder={intl.formatMessage({ id: 'Name' })}
                                type='text'
                                autoComplete='off'
                                {...formik.getFieldProps('name')}
                                className={clsx(
                                  'form-control form-control-lg form-control-solid',
                                  {
                                    'is-invalid': formik.touched.name && formik.errors.name,
                                  },
                                  {
                                    'is-valid': formik.touched.name && !formik.errors.name,
                                  }
                                )}
                              />
                              {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.name}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      }
                      {/* Direitos */}
                      {
                        report ? '' :


                          <div className='col-xl-6'>
                            <div className='fv-row mb-3'>
                              <label className='class="form-label fw-bold text-dark fs-5 mb-1 required'>{intl.formatMessage({ id: 'Rights' }, { locale: 'en' })}</label>
                              <select
                                disabled={loading}
                                className={clsx(
                                  'form-select form-control form-control-solid fs-6 mt-2',
                                  {
                                    'is-invalid': formik.touched.rights && formik.errors.rights,
                                  },
                                  {
                                    'is-valid': formik.touched.rights && !formik.errors.rights,
                                  }
                                )}
                                {...formik.getFieldProps('rights')}
                              >
                                <option value=''>{intl.formatMessage({ id: 'Select' })}</option>
                                <option value='Access'>{intl.formatMessage({ id: 'Access' })}</option>
                                <option value='Cancellation'>{intl.formatMessage({ id: 'Cancellation' })}</option>
                                <option value='Rectification'>{intl.formatMessage({ id: 'Rectification' })}</option>
                                <option value='Opposition'>{intl.formatMessage({ id: 'Opposition' })}</option>
                                <option value='Automated decision review'>{intl.formatMessage({ id: 'Automated decision review' })}</option>
                                <option value='Portability'>{intl.formatMessage({ id: 'Portability' })}</option>
                              </select>
                              {formik.touched.rights && formik.errors.rights && (
                                <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.rights}</span>
                                  </div>
                                </div>
                              )}

                            </div>
                          </div>
                      }
                    </div>

                    {/* 
                {report ?
                  <div className='row m-1 fv-row mb-4'>
                    <div className=' form-check'>
                      <input
                        type="checkbox"
                        id="anonimoCheckbox"
                        className='form-check-input'
                        checked={anonimo}
                        onChange={() => {
                          setAnonimo(!anonimo);
                          if (!anonimo) {
                            formik.setFieldValue('name', ''); // Limpar campo de nome
                            formik.setFieldValue('email', ''); // Limpar campo de e-mail
                            formik.setFieldTouched('name', false); // Marcar campo de nome como não tocado
                            formik.setFieldTouched('email', false); // Marcar campo de e-mail como não tocado
                          }
                        }} />
                      <label htmlFor="form-check-label" className='form-check-label'>Enviar de forma anônima</label>
                    </div>
                  </div>
                  :
                  null
                } */}

                    {/* Description */}
                    <div className='mb-2 fv-row' data-kt-password-meter='true'>
                      <div className='mb-1'>
                        <label className='form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'requeriment_reason' })}</label>
                        <div className='position-relative mb-3'>
                          <div className='d-flex justify-content-between w-100'>
                            <textarea
                              disabled={loading}
                              placeholder={intl.formatMessage({ id: 'Description' })}
                              autoComplete='off'
                              {...formik.getFieldProps('description')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid w-100 me-1',
                                {
                                  'is-invalid': formik.touched.description && formik.errors.description,
                                },
                                {
                                  'is-valid': formik.touched.description && !formik.errors.description,
                                }
                              )}
                            />
                          </div>
                          {formik.touched.description && formik.errors.description && (
                            <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.description}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>


                    {questions.map((question: any, index: any) => (
                      <fieldset key={index} className='mb-4 '>
                        <legend className='form-label fw-bold text-dark fs-5 mb-1'>{question.text}</legend>

                        {question.type === 'input' ? (
                          <textarea
                            className='form-control form-control-solid fs-6 mt-2'
                            value={answers[question.id] || ''}
                            onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                            placeholder='Digite sua resposta'
                          />
                        ) : (
                          <select
                            className='form-select form-control form-control-solid fs-6 mt-2'
                            value={answers[question.id] || ''}
                            onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                          >
                            <option value="" disabled>Selecione uma opção</option>
                            {question.requeriments_questions_options && question.requeriments_questions_options.map((option: any) => (
                              <option key={option.id} value={option.text}>{option.text}</option>
                            ))}
                          </select>
                        )}
                      </fieldset>
                    ))}



                    <div className='text-center row'>

                      <div className='text-center col-12 mt-12'>
                        <button
                          disabled={loading}
                          type='submit'
                          id='kt_sign_up_submit'
                          className='btn bg-hover-dark mb-5 w-100 text-white'
                          style={{ width: '168px', height: '48px', background: '#65638E', borderRadius: 13 }}

                        >
                          {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Enviar' })}</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <div className="text-center">
                        <p>Possui um código? <b className='text-primary cursor-pointer' onClick={() => { history.push(`/acompaiment`); }}>clique aqui</b>  para acompanhar um requerimento.</p>
                      </div>

                    </div>
                  </>
                }
              </>
            }
          </form>
        </div>
      </div>

      <Modal
        open={openModalConfirm}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>

          <div className='container mt-5 p-5 text-center '>
            <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> {intl.formatMessage({ id: 'Requirement' })}</h1>
            <p className='w-90 mx-auto fs-4 mt-5' >
              <span dangerouslySetInnerHTML={{ __html: responseMessage }}></span>
            </p>
          </div>
          <div className='d-flex justify-content-center m-2'>
            <div className="m-2">
              <button
                onClick={() => [setOpenModalConfirm(false)]}


                style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark'>
                {intl.formatMessage({ id: 'Close' })}

              </button>
            </div>

            {responseCode &&
              <div className="m-2">
                <button
                  onClick={() => { history.push(`/management/holder/response/${lang}/${responseCode}`); }}
                  className="btn btn-sm btn-primary text-white">
                  Acompanhar
                </button>

              </div>
            }



          </div>

        </Box>
      </Modal>

    </div>

  )
}
export default ManagementHolderRequest;