import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { createAnswersTutorials, updateActionPlans, getModuleChapters, getChapterQuestion, handleNextChapter, imprimirCertificado } from '../../../../../../app/modules/helpers/Api';
import ModalMessageConfirm from '../../../modals/modalMessageConfirm';
import { trataRespota } from '../../../../../../app/modules/helpers/helpers'
import { useIntl } from 'react-intl'
import { Load } from '../../../spinner/Spinner';
import axios from 'axios';
import $ from "jquery";
import { UserModel } from '../../../../../../app/modules/auth/models/UserModel';
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../../../setup';
import { Box, Modal } from '@mui/material';
import ChapterModules from './ChapterModules';
import { KTSVG } from '../../../../../helpers';
import { converteData } from '../../../../../../app/modules/helpers/helpers';
import { check } from 'prettier';


type Props = {
  selectedTutorial: any;
  chapters: any;
  user_nivel: any;
  indiceChapter: any;
  chapterModules: any;
  onGetChapters: () => void;
  onSetIndiceChapter: (index: any) => void;
  onLoadSelectedChapter: (index: any, item: any) => void;
}


let widthVar = 550;

if (window.screen.width < 600) {
  widthVar = 500;
  if (window.screen.width < 500) {
    widthVar = 350;
    if (window.screen.width < 400) {
      widthVar = 300;
    }
  }
  if (window.screen.width < 300) {
    widthVar = 250;
  }

}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

let widthVar2 = 550;

if (window.screen.width < 600) {
  widthVar2 = 450;
  if (window.screen.width < 500) {
    widthVar2 = 300;
  }
  if (window.screen.width < 300) {
    widthVar2 = 200;
  }

}


const TutorialChapters: React.FC<Props> = ({ ...props }) => {

  const intl = useIntl();
  const [concludeCourse, setConcludeCourse] = useState(false);
  const [playerVideo, setPlayerVideo] = useState('');
  const [loadingVideo, setLoadingVideo] = useState('');
  const [loading, setLoading] = useState(false);
  const [indiceTutotirial, setIndiceTutorial] = useState(0);
  const [data, setData] = useState<any>()
  const [chapterQuestion, setchapterQuestion] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const [answerTutorialAlternative, setAnswerTutorialAlternative] = useState('');
  const controlsList = 'nodownload';
  const [loading2, setLoading2] = useState(false);
  const api = process.env.REACT_APP_API_ENDPOINT + '/api'
  const token = localStorage.getItem('token');
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`
  }

  function HandleCloseChapter() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setOpenModalConfirm(false)
    $(`#button_title_${props.user_nivel}`).trigger("click")
    setPlayerVideo('')
  }

  function getVideo(hash: any, type: any, index: any) {

    toast.success(`Carregando, aguarde...`, { autoClose: 2000 });

    if (type === 'video') {
      setLoadingVideo(`video-player-tutorial_${index}`)
    }
    let url = `${api}/file/show?name=${hash}`;

    axios.get(url, { headers: headers, responseType: "blob" }).then(function (response) {
      var file = new Blob([response.data], { type: response.data.type });
      var fileURL = URL.createObjectURL(file);

      if (['video/mp4', 'video/quicktime'].includes(response.data.type)) {
        setLoadingVideo('')
        setPlayerVideo(`video-player-tutorial_${index}`)
        let customPlayer = $(`#video-player-tutorial_${index}`);
        if (customPlayer) {
          customPlayer.css("display", "flex")

          // $("#video-player-1").html(`<source src=${fileURL} type="video/mp4"></source>`);
          var video = document.getElementById(`example_video_tutorial_${index}`);
          var source = document.getElementById(`video_player_tutorial_${index}`);
          // @ts-ignore: Object is possibly 'null'.
          video.pause()
          // @ts-ignore: Object is possibly 'null'.
          source.setAttribute('src', fileURL);
          // @ts-ignore: Object is possibly 'null'.
          source.setAttribute('type', response.data.type);

          // @ts-ignore: Object is possibly 'null'.
          video.load();
          // @ts-ignore: Object is possibly 'null'.
          video.play();
          // setPlayerVideo(1)
        } else {
          toast.error(`Não foi possivel carregar o player`, { autoClose: 2000 });
        }

      } else {
        window.open(fileURL);
      }

    })
      .then((response) => {
        toast.success(`Carregamento concluido, abrindo...`, { autoClose: 2000 });
      })
      .catch(function (error) {
        console.log(error);
        toast.error(`Erro ao acessar o arquivo!`, { autoClose: 2000 });
        setPlayerVideo('')
      }).finally(() => {        
        setLoadingVideo('')
      });

  }

  function handleGetChapterQuestionResult() {
    setOpenModal(true);
  }

  function handleGetChapterQuestion() {

    if (props.indiceChapter != null) {

      const send = getChapterQuestion(props.chapters[props.indiceChapter].id);

      send.then((res) => {

        if (res.data.status === 'success') {
          setchapterQuestion(res.data.data);
          setOpenModal(true);
        }
        else if (res.data.status === 'level without') {
          toast.warning(res.data.message);
        }
        else if (res.data.status === 'error') {
          toast.error(res.data.message);
        }
        else if (res.data.status === 'warning') {
          // handleGetList(null)
          window.location.reload()
        }
      }).catch((error) => {
        if (error.response) {
          toast.error(error.response.message);
        }
      }).finally(() => {
        // setLoading2(false);
      });

    } else {
      toast.warning('Capítulo não selecionado!');
    }
  }

  function handleNextChapter2() {

    if (props.indiceChapter != null && props.chapters[props.indiceChapter].finalization == 0) {

      const data2 = {
        module_id: '',
        chapter_id: props.chapters[props.indiceChapter].id,
        tutorial_answer_alternative: answerTutorialAlternative,
        tutorial_answer: 'answerTutorial',
        indice: props.user_nivel ? props.user_nivel : 0
      }
      setLoading2(true);

      const send = handleNextChapter(data2);

      send.then((res) => {

        if (res.data.status === 'success') {

          props.onGetChapters();

          checkTutorialFinished()

        }
        if (res.data.status === 'error') {
          toast.error(res.data.message);
        }
        if (res.data.status === 'warning') {
          // handleGetList(null)
          // window.location.reload()
        }
      }).catch((error) => {
        if (error.response) {
          toast.error(error.response.message);
        }
      }).finally(() => {
        setLoading2(false);
      });

    } else {

    }

  }
  function checkTutorialFinished() {
    if ((props.chapters.length - 1) === props.indiceChapter) {
      setConcludeCourse(true)
      setOpenModalConfirm(true)
    } else {
      // setTimeout(() => {
      //   window.location.reload()
      // }, 2500);
    }
  }
  function saveAnswersTutorial() {

    if (!answerTutorialAlternative) {
      toast.warning('Opção não selecionada');
      return

    }

    if (props.indiceChapter && props.chapters[props.indiceChapter].finalization) {

      const data2 = {
        module_id: null,
        chapter_id: props.chapters[props.indiceChapter].id,
        tutorial_answer_alternative: answerTutorialAlternative
      }

      setLoading2(true);
      const send = createAnswersTutorials(data2);
      send.then((res) => {

        if (res.data.status === 'success') {

          setAnswerTutorialAlternative('')

          toast.success(res.data.message);
          // handleGetList(null)
          setOpenModal(false)
          
          props.onGetChapters();

          setchapterQuestion([]);

          $(`#button_title_${props.user_nivel}`).trigger("click")

          checkTutorialFinished()

        }
        if (res.data.status === 'error') {
          toast.error(res.data.message);
        }
        if (res.data.status === 'warning') {
          // handleGetList(null)
          // window.location.reload()
        }
      }).catch((error) => {
        if (error.response) {
          toast.error(error.response.message);
        }
      }).finally(() => {
        setLoading2(false);
      });

    } else {

    }

  }

  function generateCertificate(download = false) {

    if ( props.chapters.length > props.user_nivel ) {
      // toast.error('Você ainda não alcançou esse ponto!')
      return
    }

    const post = imprimirCertificado({ tutorial_id: props.selectedTutorial })
    post.then((response) => {

      var blob = new Blob([response.data], {
        type: 'application/pdf'
      });

      const anchor = window.document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);

      if (download) {
        anchor.download = 'certificado';
      } else {
        anchor.target = '_blank';

      }

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      // window.URL.revokeObjectURL(anchor.href);


    }).catch((error) => {

      if (error.response.status == 404) {
        
        let file = new Blob([error.response.data], {type: 'application/json'});

        file.text()
        .then(value => {

          let object = JSON.parse(value);

          toast.warning(object.message);

        })
        .catch(error => {
          toast.error('Desculpe um erro ocorreu')
        });

      } else {
        toast.error('Indiponível no momento!')
      }

    })
      .finally(() => [
        setLoading(false),
      ]);
  }

  return (
    <div className='card-body'>

      {/* CHAPTERS */}
      <div className='container p-2  mt-5  mx-auto'>
        {
          props.selectedTutorial == null || props.chapters.length == 0 ?
            <div className='row d-flex  p-1'>
              <div className='col-md-12 mb-5'>
                <img src="/media/logos/breven_law_color_logo.svg" />
              </div>
              <div className='text-center'>
                {
                  props.selectedTutorial == null ? intl.formatMessage({ id: 'Selecione um capítulo para começar!' }) : null
                }
                {
                  props.selectedTutorial != null && props.chapters.length == 0 ? intl.formatMessage({ id: 'Capítulos indisponíveis no momento!' }) : null
                }
              </div>
            </div>
          : 
            <div className='row d-flex  p-1'>
              <div className='col-md-3 mb-5'>
                {
                  props.chapters.map((item: any, index: number) =>
                    <div key={index}
                      style={{ whiteSpace: 'nowrap', cursor: (props.user_nivel >= item.chapter_nivel ? 'pointer ' : 'default') }}
                      role={'button'}
                      onClick={() => (props.user_nivel >= item.chapter_nivel ? [] : [])}
                      className={`${index === props.indiceChapter ? 'text-primary fw-bold ' : (props.user_nivel >= item.chapter_nivel ? 'text-gray-700 fw-bold ' : 'text-gray-300 fw-bold ')}`}
                    >
                      {
                        data?.data[indiceTutotirial].id !== 1 ?
                          <></>
                          :
                          <>
                            <input
                              style={{ width: '17px', height: '17px', margin: '0 0 0 11px' }}
                              className="form-check-input mt-1"
                              type="checkbox"
                              value=""
                              id={`tutorial_item_${index}`}
                              checked={props.user_nivel > item.chapter_nivel}
                              name={`${item.titulo}}`}
                              disabled
                            />
                            &nbsp;&nbsp;&nbsp;
                          </>
                      }
                      <span
                        id={`button_title_${index}`}
                        className='tutorial_button_title'
                        onClick={() => [props.user_nivel >= item.chapter_nivel ? props.onLoadSelectedChapter(index, item) : null]}
                      >{item.titulo}</span>

                    </div>
                  )
                }

                <div className='tutorial_button_title mt-4' style={{ cursor: `${props.chapters.length > props.user_nivel ? 'default' : 'pointer'}`, fontWeight: 'bold', color: `${props.chapters.length > props.user_nivel ? '#E4E6EF' : 'green'}` }} onClick={() => generateCertificate()} >Certificado</div>

              </div>

              <div className='col-md-9'>

                <ChapterModules
                  modules={props.chapterModules}
                  finalization={props.chapters[props.indiceChapter]?.finalization ? props.chapters[props.indiceChapter]?.finalization : 0}
                  chapter_nivel={props.chapters[props.indiceChapter]?.chapter_nivel ? props.chapters[props.indiceChapter].chapter_nivel : 0}
                  user_nivel={props.user_nivel}
                  playerVideo={playerVideo}
                  indiceChapter={props.indiceChapter}
                  onGetVideo={getVideo}
                  onGetQuestion={handleGetChapterQuestion}
                  onGetQuestionResult={handleGetChapterQuestionResult}
                  onNextChapter={handleNextChapter2}
                  loadingVideo={loadingVideo}
                />

              </div>

            </div>
        }

      </div>

      {/* MODAL QUESTIONS */}
      <Modal
        open={openModal}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className='w-100 px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-end align-items-center w-100'>

            <div className='modal-content'>
              <div className='modal-header'>
                {
                  chapterQuestion && chapterQuestion.question ?
                    <p id="question_tutorial" className='text-gray-700 fw-bolder mt-4' dangerouslySetInnerHTML={{ __html: `${chapterQuestion.question}` }} />
                    :
                    <h5 className='modal-title'> {intl.formatMessage({ id: 'Result' })}</h5>
                }
              </div>
            </div>

            <div
              className="btn btn-icon btn-sm btn-active-light-primary"
              onClick={() => [setOpenModal(false)]}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>

          </div>

          <div className='container mt-5 ' >
            {
              props.indiceChapter && props.user_nivel > props.chapters[props.indiceChapter]?.chapter_nivel ?
                <>
                  <div >
                    {
                      props.chapterModules.map((mr: any, index_mr: any) =>
                        <div key={index_mr}>
                          {mr.tutorial_questions ?
                            <div>
                              <p id="question_tutorial" className='text-gray-700 fw-bolder mt-4' dangerouslySetInnerHTML={{ __html: `${mr.tutorial_questions.question ? mr.tutorial_questions.question : 'dadsada'}` }} />
                              <div className='' style={{ maxHeight: 240, overflowY: 'auto' }}>
                                
                                {/* <ul>
                                  {
                                    // tutorial_answer_alternative
                                    mr.tutorial_questions.user_points_tutorials.map((tentativa: any, t_index: any) =>
                                      <li style={{ marginBottom: '10px', color: `${mr.tutorial_questions.right_question === tentativa.tutorial_answer_alternative ? 'green' : 'red'}` }}>
                                        <hr style={{ marginBottom: '0' }} />
                                        <div>{tentativa.tutorial_answer} - {converteData(tentativa.created_at)}</div>  </li>
                                    )
                                  }
                                </ul> */}

                                {
                                  mr.tutorial_questions.user_points_tutorials.map((tentativa: any, t_index: any) =>

                                    <label   className={`alert mb-3 p-5 alert-dismissible ${mr.tutorial_questions.right_question === tentativa.tutorial_answer_alternative ? 'border-success bg-light-success' : 'border-danger bg-light-danger'} border border-dashed    `} >
                                      <p dangerouslySetInnerHTML={{ __html: `${tentativa.tutorial_answer}` }} />
                                      <small>{converteData(tentativa.created_at)}</small>
                                    </label>

                                  )
                                }

                              </div>
                            </div>
                            :
                            'OPS...'
                          }
                        </div>
                      )
                    }
                  </div>
                </>
                :
                <>
                  {
                    chapterQuestion ?
                      <div className=''>

                        <div className='mt-5 p-3 text-gray-600 fs-6' style={{ maxHeight: 450, overflowY: 'auto' }}>
                          <p >
                            <input
                              type='radio'
                              name='form'
                              className='form-check-input '
                              onChange={() => [
                                setAnswerTutorialAlternative('question_alternative1')
                              ]}
                            />
                            <span className='text-gray-600 fs-6' style={{ marginLeft: 5 }} dangerouslySetInnerHTML={{ __html: `${chapterQuestion.question_alternative1}` }} />
                          </p>
                          <p >
                            <input
                              type='radio'
                              name='form'
                              className='form-check-input '
                              onChange={() => [
                                setAnswerTutorialAlternative('question_alternative2')
                              ]}

                            />
                            <span className='text-gray-600 fs-6' style={{ marginLeft: 5 }} dangerouslySetInnerHTML={{ __html: `${chapterQuestion.question_alternative2}` }} />
                          </p>
                          <p >
                            <input
                              type='radio'
                              name='form'
                              className='form-check-input '
                              onChange={() => [
                                setAnswerTutorialAlternative('question_alternative3')
                              ]}
                            />
                            <span className='text-gray-600 fs-6' style={{ marginLeft: 5 }} dangerouslySetInnerHTML={{ __html: `${chapterQuestion.question_alternative3}` }} />
                          </p>
                          <p >
                            <input
                              type='radio'
                              name='form'
                              className='form-check-input '
                              onChange={() => [
                                setAnswerTutorialAlternative('question_alternative4')
                              ]}
                            />
                            <span className='text-gray-600 fs-6' style={{ marginLeft: 5 }} dangerouslySetInnerHTML={{ __html: `${chapterQuestion.question_alternative4}` }} />
                          </p>
                        </div>
                      </div>
                      :
                      <></>
                  }
                </>
            }
          </div>

          <div className='container d-flex justify-content-center' >
            {
              props.chapters[props.indiceChapter]?.chapter_nivel < props.user_nivel ?
                <>
                  <button
                    onClick={() => [setOpenModal(false)]}
                    disabled={loading2}
                    style={{ border: '1.5px solid #F5DD30', margin: '35px', backgroundColor: '#FFFFFF' }} className='btn btn-sm btn-light text-primary'>
                    {!loading2 && <span className='indicator-label'>{intl.formatMessage({ id: 'Close' })}</span>}
                    {loading2 && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <div className='mt-auto mb-auto'>
                    {
                      props.user_nivel > props.chapters[props.chapters.length - 1].chapter_nivel ?
                        <button
                          onClick={() => generateCertificate()}
                          style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark'>
                          {intl.formatMessage({ id: 'Obter Certificado' })}

                        </button>
                        :
                        <></>
                    }
                  </div>
                </>
                :
                <button
                  onClick={() => [
                    saveAnswersTutorial()
                  ]}
                  style={{ border: '1.5px solid #F5DD30', margin: '35px', backgroundColor: '#FFFFFF' }} className='btn btn-sm btn-light text-primary'>
                  {!loading2 && <span className='indicator-label'>{intl.formatMessage({ id: 'Send' })}</span>}
                  {loading2 && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
            }

          </div>

        </Box>
      </Modal>

      {/* Modal CONFIRM */}
      <Modal
        open={openModalConfirm}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {
            concludeCourse ?
              <>
                <div className='container mt-5 p-5 text-center '>
                  <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> {intl.formatMessage({ id: 'CONGRATULATIONS' })}</h1>
                  <p className='w-90 mx-auto fs-4 mt-5'>
                    {intl.formatMessage({ id: 'You have completed the Training Course!!!' })}
                  </p>
                </div>
                <div className='d-flex justify-content-center  mb-2'>

                  <div className='row ' style={{ width: '100%' }}>
                    <div className='col-6 mb-5 text-center'>
                      <button
                        onClick={() => [
                          generateCertificate()
                        ]}
                        style={{ border: '1px solid #000000' }} className='btn btn-sm btn-light text-dark'>
                        {intl.formatMessage({ id: 'Obter Certificado' })}
                      </button>
                    </div>
                    <div className='col-6 mb-5 text-center'>
                      <button
                        onClick={() => [
                          HandleCloseChapter()
                        ]}
                        style={{ border: '1px solid #000000' }} className='btn btn-sm btn-light text-dark'>
                        {intl.formatMessage({ id: 'Close' })}
                      </button>
                    </div>
                  </div>

                </div>
              </>
              :
              <>
                <div className='container mt-5 p-5 text-center '>
                  <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> {intl.formatMessage({ id: 'CONGRATULATIONS' })}</h1>
                  <p className='w-90 mx-auto fs-4 mt-5'>
                    {intl.formatMessage({ id: 'You have just completed another chapter of our tutorial and with that, you have climbed one more step to your professional ascension, keep studying' })}
                  </p>
                </div>
                <div className='d-flex justify-content-center mb-2'>
                  <button
                    // onClick={() => [setOpenModalConfirm(false)]}
                    onClick={() => [
                      HandleCloseChapter()
                    ]}

                    style={{ border: '1.5px solid #000000' }} className='btn btn-sm btn-light text-dark'>
                    {intl.formatMessage({ id: 'Close' })}

                  </button>

                </div>
              </>
          }
        </Box>
      </Modal>

    </div>

  )
}
export default TutorialChapters;