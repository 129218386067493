import { Spinner } from 'react-bootstrap-v5';
import {useIntl} from 'react-intl'

const Load: React.FC = () =>{
  const intl = useIntl(); 

  return(
    <div className='d-flex flex-column justify-items-center align-items-center w-100'>
      <Spinner
        animation='border'
        color='#1f1e2c'
      />
      <span className='text-gray-700 mt-3'>{intl.formatMessage({id: 'Loading'})}...</span>
    </div>
  )
}
export {Load};