import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../helpers';
import { getCoupon, updateCoupon } from '../../../../../app/modules/helpers/Api';
import { trataRespota, converteData } from '../../../../../app/modules/helpers/helpers'
import { toast } from 'react-toastify'
import { Load } from '../../spinner/Spinner';
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import InputMask from "react-input-mask"
import moment from 'moment';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 950,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IModalViewPreferenceConsentProps {
  modelRef: any;
  handleGetList: () => void;
  onClose: () => void;
}

const ModalViewPreferenceConsent: React.FC<IModalViewPreferenceConsentProps> = ({ ...props }) => {

  const [loading, setLoading] = useState(false);  
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const intl = useIntl();

  const initialValues = {
    uuid: '',
    title: '',
		description: '',
		status: 'active',
		type: 'fixed',
		value: '0.00',
		valid_at: undefined,
		limit: 1
  }

  const registrationSchema = Yup.object().shape({
    title: Yup.string()
			.required(`${intl.formatMessage({ id: 'Name' })} ${intl.formatMessage({ id: 'is required' })}`),
		description: Yup.string()
			.min(3, `${intl.formatMessage({ id: 'Minimum 6 symbols' })}`),
		status: Yup.string()
			.required(`${intl.formatMessage({ id: 'Status' })} ${intl.formatMessage({ id: 'is required' })}`),
		type: Yup.string()
			.required(`${intl.formatMessage({ id: 'Type' })} ${intl.formatMessage({ id: 'is required' })}`),
		value: Yup.string()
			.required(`${intl.formatMessage({ id: 'Value' })} ${intl.formatMessage({ id: 'is required' })}`),
		limit: Yup.string()
			.required(`${intl.formatMessage({ id: 'Limit' })} ${intl.formatMessage({ id: 'is required' })}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleConfirmUpdate();
    },
  })

  function handleConfirmUpdate() {
    setLoading(true);
    setOpenModalConfirm(false);

    let update = updateCoupon(formik.getFieldProps('uuid').value, {
      uuid: formik.getFieldProps('uuid').value,
      title: formik.getFieldProps('title').value,
      description: formik.getFieldProps('description').value,
      status: formik.getFieldProps('status').value,
      type: formik.getFieldProps('type').value,
      value: formik.getFieldProps('value').value,
      valid_at: formik.getFieldProps('valid_at').value ? moment(formik.getFieldProps('valid_at').value , 'DDMMYYYY').format('YYYY-MM-DD') : null,
      limit: formik.getFieldProps('limit').value
    });

    update.then((res) => {

      if (res.data.status === 'success') {
        // formik.resetForm()
        toast.success(res.data.message)
        setOpenModalConfirm(false)
        props.handleGetList()
        props.onClose()

      }
      else if (res.data.status === 'error') {
        toast.error(res.data.message);
      }

    }).catch((error) => {
      trataRespota('error', error);
    }).finally(() => {
      setLoading(false)

    });
  }


  useEffect(() => {
    if (props.modelRef) {
      handleGetData();
    }
  }, [props.modelRef]);


  function handleGetData() {

    if (!props.modelRef) {
      return
    }

    setLoading(true)

    const get = getCoupon(props.modelRef)

    get.then((res) => {

      if (res.data.status === 'success') {
        formik.setFieldValue('uuid', res.data.data.uuid);
        formik.setFieldValue('title', res.data.data.title);
        formik.setFieldValue('description', res.data.data.description);
        formik.setFieldValue('status', res.data.data.status ? res.data.data.status : '- - -');
        formik.setFieldValue('type', res.data.data.type);
        formik.setFieldValue('value', res.data.data.value);
        formik.setFieldValue('valid_at', res.data.data.valid_at ? moment(res.data.data.valid_at , 'YYYY-MM-DD').format( 'DDMMYYYY') : null);
        formik.setFieldValue('limit', res.data.data.limit);
        formik.setFieldValue('updated_at', res.data.data.updated_at ? converteData(res.data.data.updated_at) : '- - -');
      }

    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setLoading(false)
    });

  }
  
  return (
    <div>
      <Modal
        open={props.modelRef ? true : false}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div className='px-5 d-flex flex-row justify-content-between align-items-center w-100'>

            <div className='d-flex fw-bold h-60px flex-row align-items-center border-1 border-bottom border-dark w-100'>
              <div className="modal-title fw-bold fs-3" style={{ marginRight: '6px' }}>{intl.formatMessage({ id: 'Edit' })} {intl.formatMessage({ id: 'coupon' })} </div>
              <div className={`badge ${formik.getFieldProps('status').value == 'active' ? 'badge-success' : 'badge-danger'}`}>{intl.formatMessage({ id: formik.getFieldProps('status').value })}</div>

            </div>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary"
              onClick={() => [props.onClose()]}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>

          <div className='px-6 py-8'>

            {loading ?
              <Load />
              :
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
              >
                <input
                  disabled
                  type='hidden'
                  autoComplete='off'
                  {...formik.getFieldProps('uuid')}
                  className='form-control form-control-lg form-control-solid'
                />

                  <div className='row'>
                    <div className='col-12'>
                      
                      <div className='alert alert-primary d-flex align-items-center'>
                        <KTSVG path="/media/icons/duotune/general/gen044.svg" className="svg-icon-primary svg-icon-2hx me-5" />
                        
                        <ul>
                          <li>{intl.formatMessage({ id: 'valid_at' })} - Tempo de duração do cupom, deixe vazio para tempo indeterminado.</li>
                          <li>{intl.formatMessage({ id: 'limit' })} - Limite de utilização por usuário, 0 = sem limite</li>
                        </ul>

                      </div>
                    

                    </div>
                  </div>

                <div className='scroll-y pe-5 h-250px '>


                  {/* title */}
                  <div className='row mb-3'>
                    <div className='col-md-8 col-lg-8 col-xl-4 mb-3'>
                      <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'Title' })}</label>
                      <input
                        disabled={formik.getFieldProps('status').value == 'old'}
                        placeholder={intl.formatMessage({ id: 'Title' })}
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('title')}
                        className='form-control form-control-lg form-control-solid'
                      />
                    </div>

                    <div className='col-md-4 col-lg-4 col-xl-2 mb-3'>
												<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'Status' })}</label>
												<select className='form-select form-control form-control-solid fs-6'
													{...formik.getFieldProps('status')}
												>
													<option value='active'>{intl.formatMessage({ id: 'Active' })}</option>
													<option value='inactive'>{intl.formatMessage({ id: 'Inactive' })}</option>
													<option value='test'>{intl.formatMessage({ id: 'Test' })}</option>
												</select>
											</div>

											{/* valid_at */}
											<div className='col-md-4 col-lg-4 col-xl-2 mb-3'>
												<label className='col-12 form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'valid_at' })}</label>
												<div className='position-relative mb-3'>
													<div className='d-flex justify-content-between w-100'>
														<InputMask
															mask="99/99/9999"
															type="text"
															className="form-control form-control-solid fs-6"
															placeholder="DD/MM/YYYY"
															name='valid_at'
															onChange={(e) => formik.setFieldValue('valid_at', e.target.value)}
															value={formik.getFieldProps('valid_at').value}

														/>
													</div>
													{formik.touched.valid_at && formik.errors.valid_at && (
														<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
															<div className='fv-help-block'>
																<span role='alert'>{formik.errors.valid_at}</span>
															</div>
														</div>
													)}
												</div>
											</div>

											{/* limit */}
											<div className='col-md-4 col-lg-4 col-xl-2 mb-3'>
												<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'limit' })}</label>
												<div className='d-flex justify-content-between w-100'>
													<input
														type='number'
														placeholder="1"
														autoComplete='off'
														{...formik.getFieldProps('limit')}
														className='form-control form-control-lg form-control-solid w-100 me-1'
													/>
												</div>
												{formik.touched.limit && formik.errors.limit && (
													<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
														<div className='fv-help-block'>
															<span role='alert'>{formik.errors.limit}</span>
														</div>
													</div>
												)}
											</div>

											{/* Description */}
											<div className='col-12 mb-4'>
												<label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Description' })}</label>
												<div className='d-flex justify-content-between w-100'>
													<textarea
														placeholder={intl.formatMessage({ id: 'Description' })}
														autoComplete='off'
														{...formik.getFieldProps('description')}
														className='form-control form-control-lg form-control-solid w-100 me-1'
													/>
												</div>
												{formik.touched.description && formik.errors.description && (
													<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
														<div className='fv-help-block'>
															<span role='alert'>{formik.errors.description}</span>
														</div>
													</div>
												)}
											</div>

											{/* Type */}
											<div className='col-md-4 col-lg-4 col-xl-2 mb-3'>
												<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'Type' })}</label>
												<select className='form-select form-control form-control-solid fs-6'
													{...formik.getFieldProps('type')}
												>
													<option value='fixed' >{intl.formatMessage({ id: 'fixed' })}</option>
													<option value='percent' >{intl.formatMessage({ id: 'percent' })}</option>
												</select>
											</div>
											
											{/* Value */}
											<div className='col-md-4 col-lg-4 col-xl-2 mb-3'>
												<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'Value' })}</label>
												<div className='d-flex justify-content-between w-100'>
													<input
														type='number'
														placeholder="0.00"
														autoComplete='off'
														{...formik.getFieldProps('value')}
														className='form-control form-control-lg form-control-solid w-100 me-1'
													/>
												</div>
												{formik.touched.value && formik.errors.value && (
													<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
														<div className='fv-help-block'>
															<span role='alert'>{formik.errors.value}</span>
														</div>
													</div>
												)}
											</div>



                  </div>

                </div>


                <div className='text-center row'>

                  <div className='text-center col-12 mt-12 d-flex'>
                    <button
                      type='button'
                      className='btn bg-danger m-2 w-100 text-white'
                      //  disabled={!formik.values.acceptTerms}
                      onClick={() => props.onClose()}
                    >
                      <span className='indicator-label'>{intl.formatMessage({ id: 'Close' })}</span>
                    </button>


                    { formik.getFieldProps('status').value != 'old' ?

                      <button
                        type='submit'
                        className='btn bg-primary text-dark m-2 w-100'
                      >
                        {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Update' })}</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>

                      :
                      
                      null

                        }

                  </div>


                </div>
              </form>
            }

          </div>

        </Box>
      </Modal>

      <Modal
        open={openModalConfirm}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>

          <div className='container mt-5 p-5 text-center '>
            <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> {intl.formatMessage({ id: 'Requirement' })}</h1>
            <p className='w-90 mx-auto fs-4 mt-5'>
              {/* { responseMessage } */}
              {intl.formatMessage({ id: 'A response email will be sent to:' })} {formik.getFieldProps('requester_mail').value}, {intl.formatMessage({ id: 'do you want to proceed?' })}
            </p>
          </div>

          <div className='row p-4'>

            <div className='col-sm-6'>
              <button
                onClick={() => [setOpenModalConfirm(false)]}
                className='btn btn-sm btn-danger w-100'>
                {intl.formatMessage({ id: 'No' })}
              </button>
            </div>

            <div className='col-sm-6'>
              <button
                onClick={() => [handleConfirmUpdate()]}
                className='btn btn-sm btn-success w-100'>
                {intl.formatMessage({ id: 'Yes' })}
              </button>
            </div>

          </div>

        </Box>
      </Modal>


    </div>
  )
}
export default ModalViewPreferenceConsent;