/* eslint-disable jsx-a11y/anchor-is-valid */
// import { userInfo } from 'os';
import React, {FC, useEffect, useState} from 'react'
import { toast } from 'react-toastify';
import { getActionPlansById, delActionPlans } from '../../../../../app/modules/helpers/Api';
import { KTSVG } from '../../../../helpers';
import ModalMessage from '../../modals/modalMessage';
import { Load } from '../../spinner/Spinner';
import ActionPlanTitleCard from './ActionsPlansCards/ActionPlanTitleCard';
import ActionPlanTitleCardEdit from './ActionsPlansCards/ActionPlanTitleCardEdit';
import ActionPlanActionCreate from './ActionsPlansCards/ActionPlanActionCreate';
import ActionPlanButtonCreate from './ActionsPlansCards/ActionPlanButtonCreate';
import ActionPlanActionList from './ActionsPlansCards/ActionPlanActionList';
import ActionPlanGraph from './ActionsPlansCards/ActionPlanGraph';
import { useIntl } from 'react-intl'

type Props = {
  id?: any;
  form_id?: any;
  idLocation?: any,
  backScreen: (action: boolean) => void;
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 400,
  maxHeight: 450,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

const ActionPlansDetails: FC<Props> = ({...props}) => {
  
  const [actionPlanData, setActionPlanData] = useState<any>([]);
  const [actionData, setActionData] = useState<any>([]);
  const [graphData, setGraphData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [createAction, setCreateAction] = useState(false);
  const [editActionPlanTitle, setEditActionPlanTitle] = useState(false);
  const intl = useIntl();   

  function handleLoadScreen(action: boolean){
    setLoadingScreen(action);
  }

  useEffect(()=>{
    setLoading(true);
    
    setOpenModalDelete(false);
    setCreateAction(false);
    setEditActionPlanTitle(false);

    const get = getActionPlansById(props.idLocation? props.idLocation : props.id);
    get.then((res)=>{
      setActionPlanData(res.data.data);
      setActionData(res.data.data.actions);
      setGraphData(res.data.data.action_progress_details.graph.map((item: any)=> item));
    }).catch((error)=>{
      toast.error(error.response.message);
    }).finally(()=>[setLoading(false), setLoadingScreen(false)]);
  },[props.id, props.idLocation, loadingScreen]);

  function handleNothing (){
    return ''
  }
  function handleDelete(){
    const del = delActionPlans(props.idLocation? props.idLocation : props.id);
    del.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        setOpenModalDelete(false);
        props.backScreen(false);
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      toast.error(error.response.data.message);
    })
    
  }


  return (
    <>
      {
        loading?
        <Load/>
        :
        <>
          <div className='card mb-5 mb-xl-5'>
            <ModalMessage
              message={intl.formatMessage({id: 'Are you sure you want to delete this item?'})} 
              title={intl.formatMessage({id: 'Attention'})}
              showModal={openModalDelete}
              onClose={()=>setOpenModalDelete(false)}
              onAction={handleDelete}
            />
            <div className='collapse show'>        
              <div className='d-flex flex-row justify-content-end aling-items-center mx-5 my-3'>
                <button
                  type="button"
                  className="btn btn-sm me-2 btn-danger m-0 p-0 w-40px h-40px align-items-center justify-content-center d-flex text-center"
                  onClick={()=>setOpenModalDelete(true)}
                  title={intl.formatMessage({id: 'Delete action plan'})} 
                >
                  <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-2 m-0 p-0' />
                </button>
                <button onClick={()=>props.backScreen(false)} title={intl.formatMessage({id: 'Back screen'})} type='button' className='btn btn-sm btn-secondary p-3'>
                    <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark'/>
                    {intl.formatMessage({id: 'Back'})}
                </button>
                
              </div>
              {
                editActionPlanTitle?
                <ActionPlanTitleCardEdit 
                  id={props.idLocation? props.idLocation : props.id}
                  form_id={props.form_id}
                  title={actionPlanData.title}
                  description={actionPlanData.description}
                  onEdit={()=>setEditActionPlanTitle(false)}
                  onFinish={handleLoadScreen}
                />
                :
                <ActionPlanTitleCard 
                  title={actionPlanData.title}
                  description={actionPlanData.description}
                  onEdit={()=>setEditActionPlanTitle(true)}
                />
              }
            </div>
          </div>

         
          <div className='card card-body'>
            <div className='accordion' id='kt_accordion_list'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_list_header_list'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_list_body_list'
                    aria-expanded='false'
                    aria-controls='kt_accordion_list_body_list'
                   
                  >
                    {intl.formatMessage({id: 'Actions'})} 
                  </button>
                </h2>
                <div
                  id='kt_accordion_list_body_list'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_list_header_list'
                  data-bs-parent='#kt_accordion_list'
                >
                  <div className='accordion-body'>
                    
                    <div style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 600}}>
                    {
                      actionData.length === 0?
                      <></>
                      :
                      <>
                        {
                          <ul>
                            <li>
                              <strong>{intl.formatMessage({id: 'It will only be possible to edit/delete where the final data will be less than or equal to the current data'})}</strong>
                            </li>
                          </ul>
                        }
                        {
                          actionData.map((item: any, index: any)=>{
                           
                            return(
                              <ActionPlanActionList
                                onReload={handleNothing}
                                key={index}
                                index={index}
                                action={item}
                                onFinish={handleLoadScreen}/>
                             
                            )
                          })
                        }
                      </>
                    }
                    </div>
                    {
                      createAction?
                      
                      
                      <ActionPlanActionCreate
                        onReload={handleNothing}
                        model={actionPlanData.prescription_answered?.model}
                        onCancel={()=>setCreateAction(false)}
                        idActionPlanItem={actionPlanData.id}
                        form_id={actionPlanData.form_id}
                        onFinish={handleLoadScreen}
                      />
                          
                      :
                      <ActionPlanButtonCreate
                        onClick={()=>setCreateAction(true)

                        }
                      />
                    }
                  </div>
                </div>
              </div> 
            </div>
          </div>

          {
            graphData.length === 0?
            <></>
            :
            <>
              {
                graphData.find((element: any) => element > 0)?
                <div className='card card-body mt-5'>
                  <ActionPlanGraph
                    graph={graphData}
                    total={actionPlanData.action_progress_details.progress_result}
                  />
                </div>
                :
                <></>
              }
            </>
          }

        </>
        
      }
    </>
  )
}

export {ActionPlansDetails}
