/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import { KTSVG } from '../../../helpers'
import { DeleteForm } from './DeleteForm';
import { useIntl } from 'react-intl'


const DeleteProfile: FC = () => {
  const intl = useIntl();   
  const [step, setStep] = useState(0);


  return (
    <>
    <div onClick={()=>setStep(0)} data-bs-toggle="modal" data-bs-target="#kt_modal_delete" className='mt-5 px-4 mb-4 cursor-pointer text-hover-primary'>      
      {intl.formatMessage({id: 'Delete Profile'})}
    </div>

    <div className="modal fade" tabIndex={-1} id="kt_modal_delete">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title fs-2">{intl.formatMessage({id: 'Attention'})}</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body pb-4">
            {
              step === 0?
              <span className='text-gray-700'>{intl.formatMessage({id: 'The action will delete all of your information! Are you sure about it?'})}</span>
              :
              <>
                {
                  step === 1?
                  <span className='text-gray-700'>{intl.formatMessage({id: 'When you delete your profile, you will no longer be able to access the system or view data. Do you really want to proceed?'})}</span>
                  :
                  <><DeleteForm/></>
                }
              </>
            }
          </div>
          {
            step <= 1?
            <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary text-white"
              data-bs-dismiss="modal"
            >
              {intl.formatMessage({id: 'No'})}
            </button>
            <button onClick={()=>setStep(step+1)} type="button" className="btn btn-danger">
            {intl.formatMessage({id: 'Yes'})} 
            </button>
          </div>
          :
          <></>
          }
        </div>
      </div>
    </div>
  </>
  )
}

export {DeleteProfile}
