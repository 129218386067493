import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../../../helpers';
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { upRoles } from '../../../../../../../app/modules/helpers/Api';
import ModalMessageConfirm from '../../../../modals/modalMessageConfirm';
import { useIntl } from 'react-intl'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  maxHeight: 550,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

interface IModalRolesProps {
  showModal: boolean;
  title: string;
  onClose: () => void;
  onAction: () => void;
  id: any;
}

const ModalRoles: React.FC<IModalRolesProps> = ({
  ...props
}) => {

  const[loading, setLoading] = useState(false);
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
	const[role, setRole] = useState('');
	const[errorRole, setErrorRole] = useState('');
  const intl = useIntl();   

  const initialValues = {
    role: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      setLoading(true);
      var error = false;
      setTimeout(() => {
        if(role === '') {setErrorRole(`${intl.formatMessage({id: 'Role'})} ${intl.formatMessage({id: 'is required'})}`); error = true}
        if(!error){
          setOpenModalSubmit(true);
          setLoading(false);
        }
        setLoading(false);
      }, 1000)
    },
  })

  function save(){
    const data = {
      roles: role
    }
    const save = upRoles(props.id, data);
    save.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        formik.resetForm();
        setOpenModalSubmit(false);
        props.onAction();
      }
      if(res.data){
        toast.error(res.data.error_message);
      }
    }).catch((error)=>{
      if(error.response.data){
        toast.error(error.response.data.message);
      } else {
        toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}`);
      }
    })
  }

  return(
    <>
    <ModalMessageConfirm
      message={intl.formatMessage({id: 'Are you sure you want to edition this item?'})}
      title={intl.formatMessage({id: 'Attention'})}
      showModal={openModalSubmit}
      onClose={()=>setOpenModalSubmit(false)}
      onAction={()=>save()}
    />
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
          <span className="modal-title fw-bold fs-3">{props.title}</span>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>props.onClose()}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className='px-5 mb-5 mt-2 ' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 350}}>

          <form onSubmit={formik.handleSubmit} noValidate className='form' id='form'>
            <div className='card-body'>
              <div className='fv-row mt-4'>
                <div className='mt-3'>
                  <label className='form-check form-check-custom form-check-solid align-items-center'>
                    <input
                      type='radio'
                      name='role'
                      className='form-check-input me-3'
                      onChange={()=>[setRole('Admin'), setErrorRole('')]}
                    />
                    <span className='form-check-label d-flex flex-column align-items-start'>
                      <span className='fw-bolder text-gray-800 fs-6 mb-0'>{intl.formatMessage({id: 'Admin'})}</span>
                      <span className='text-gray-600 fs-6 text-justify'>
                      {intl.formatMessage({id: 'Administrator'})}
                      </span>
                    </span>
                  </label>
                  <div className='separator separator-dashed my-6'></div>
                </div>
                <div className='mt-3'>
                  <label className='form-check form-check-custom form-check-solid align-items-center'>
                    <input
                      type='radio'
                      name='role'
                      className='form-check-input me-3'
                      onChange={()=>[setRole('User'), setErrorRole('')]}
                    />
                    <span className='form-check-label d-flex flex-column align-items-start'>
                      <span className='fw-bolder text-gray-800 fs-6 mb-0'>{intl.formatMessage({id: 'User'})}</span>
                      <span className='text-gray-600 fs-6 text-justify'>
                      {intl.formatMessage({id: 'Best for people primarily using the API'})}
                      </span>
                    </span>
                  </label>
                  <div className='separator separator-dashed my-6'></div>
                </div>
                <div className='mt-3'>
                  <label className='form-check form-check-custom form-check-solid align-items-center'>
                    <input
                      type='radio'
                      name='role'
                      className='form-check-input me-3'
                      onChange={()=>[setRole('DPO'), setErrorRole('')]}
                    />
                    <span className='form-check-label d-flex flex-column align-items-start'>
                      <span className='fw-bolder text-gray-800 fs-6 mb-0'>DPO</span>
                      <span className='text-gray-600 fs-6 text-justify'>
                      {intl.formatMessage({id: "In charge of processing personal data of a company, and that does not fit the company's staff"})}
                      </span>
                    </span>
                  </label>
                  <div className='separator separator-dashed my-6'></div>
                </div>
                <div className='mt-3'>
                  <label className='form-check form-check-custom form-check-solid align-items-center'>
                    <input
                      type='radio'
                      name='role'
                      className='form-check-input me-3'
                      onChange={()=>[setRole('Consultant'), setErrorRole('')]}
                    />
                    <span className='form-check-label d-flex flex-column align-items-start'>
                      <span className='fw-bolder text-gray-800 fs-6 mb-0'>{intl.formatMessage({id: 'Consultant'})}</span>
                      <span className='text-gray-600 fs-6 text-justify'>
                      {intl.formatMessage({id: 'Best for people primarily using the API'})}
                      </span>
                    </span>
                  </label>
                  <div className='separator separator-dashed mt-6 mb-1'></div>
                </div>
                {errorRole && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errorRole}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>

        </div>
        <div className='d-flex flex-row w-100 justify-content-end p-5'>
          <button
            type="button"
            className="btn btn-danger mx-2"
            onClick={()=>props.onClose()}
          >
            {intl.formatMessage({id: 'Role'})}Cancel
          </button>
          <button type='submit' form='form' className='btn btn-primary text-white' disabled={loading}>
            {!loading && intl.formatMessage({id: 'Save'})}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'Processing'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </Box>
    </Modal>
  </>
  )
}
export default ModalRoles;