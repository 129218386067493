import React from 'react';
import { useIntl } from 'react-intl'

type Props = {
  onClick: () => void;
  title?: string;
}

const ActionPlanButtonCreate: React.FC<Props> = ({...props}) => {
  const intl = useIntl();   

  return(
    <button onClick={()=>props.onClick()} className="w-95 mx-auto alert alert-dismissible text-dark text-hover-primary bg-white bg-hover-light-primary border-1 border-dashed border-primary  d-flex flex-column flex-sm-row p-4 mb-2">
      <div className="d-flex flex-column text-center w-100 pe-0 pe-sm-10">
        <span className='fs-4 fw-bold'>{props.title? props.title : `${intl.formatMessage({id: 'Add'})} ${intl.formatMessage({id: 'Action'})}`}</span>
      </div>
    </button>
  );
}
export default ActionPlanButtonCreate;