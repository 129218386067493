import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { updateActionPlans } from '../../../../../../app/modules/helpers/Api';
import ModalMessageConfirm from '../../../modals/modalMessageConfirm';
import { trataRespota } from '../../../../../../app/modules/helpers/helpers'
import { useIntl } from 'react-intl'

type Props = {
  title: string;
  description: string;
  id: string;
  form_id?: any; 
  onEdit: () => void;
  onFinish: (action: boolean) => void;
}

const schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
});

const ActionPlanTitleCardEdit: React.FC<Props> = ({...props}) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const [actionPlanTitleValues, setActionPlanTitleValues] = useState<any>({});
  const intl = useIntl();   

  const schema = Yup.object().shape({
    title: Yup.string().required(`${intl.formatMessage({id: 'Title'})} ${intl.formatMessage({id: 'is required'})}`),
    description: Yup.string().required(`${intl.formatMessage({id: 'Description'})} ${intl.formatMessage({id: 'is required'})}`),
  });

  const formik = useFormik({
    initialValues: {
      title: props.title,
      description: props.description,
    },
    validationSchema: schema,
    onSubmit: (values, actions) => {
      setIsLoading(true);
      setTimeout(() => {
        setActionPlanTitleValues(values);
        setOpenModalSubmit(true);
        setIsLoading(false);
      }, 1000)
    },
  })

  function onSave(){
    const data = {
      title: actionPlanTitleValues.title,
      description: actionPlanTitleValues.description,
      prescription_answered_id: props.id,
      form_id: props.form_id
    }
    const getRequest = updateActionPlans(props.id, data);
    getRequest.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        setOpenModalSubmit(false);
        props.onFinish(true);
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      setOpenModalSubmit(false)
      trataRespota('error', error); 
      //toast.error(error.response.statusText);
    })
  }

  return(
    <div className='card-body border-top px-5 py-5'>
      <ModalMessageConfirm
        message={intl.formatMessage({id: 'Are you sure you want to edition this item?'})} 
        title={intl.formatMessage({id: 'Attention'})}
        showModal={openModalSubmit}
        onClose={()=>setOpenModalSubmit(false)}
        onAction={onSave}
      />
      <div className='mx-4 align-items-center'>
        <form onSubmit={formik.handleSubmit} id='form-action-plan' noValidate className='form w-100'>
          <div className='align-items-center w-100'>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Title'})}</label>
                  <input
                    type="text"
                    className="form-control form-control-solid fs-5"
                    placeholder={intl.formatMessage({id: 'Title'})}
                    {...formik.getFieldProps('title')}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.title}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Description'})}</label>
                  <input
                    type="text"
                    className="form-control form-control-solid fs-5"
                    placeholder={intl.formatMessage({id: 'Description'})}
                    {...formik.getFieldProps('description')}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.description}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className='d-flex flex-row w-100 justify-content-end py-5'>
          <button
            type="button"
            className="btn btn-secondary btn-sm me-2"
            onClick={()=>props.onEdit()}
            title={intl.formatMessage({id: 'Cancel edition action plan'})}
          >
            {intl.formatMessage({id: 'Cancel'})}
          </button>
          <button type='submit' title={intl.formatMessage({id: 'Save changes'})}  form='form-action-plan' className='btn btn-sm btn-primary text-dark' disabled={isLoading}>
            {!isLoading && intl.formatMessage({id: 'Save'})}
            {isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'Processing'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
export default ActionPlanTitleCardEdit;