import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { RouteComponentProps } from 'react-router';
import { getHoldeRequest } from '../../modules/helpers/Api';
import { FormattedDate, FormattedTime, useIntl } from 'react-intl';
import { useLang } from '../../../_metronic/i18n/Metronici18n';
import { toast } from 'react-toastify';
import { Load } from '../../../_metronic/partials/widgets';
import { useHistory } from 'react-router-dom';

interface MatchParams {
    lang: string;
    token: string;
}

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';

const ManagementHolderSelect: React.FC<RouteComponentProps<MatchParams>> = (props) => {
    const lang = useLang();
    const intl = useIntl();
    const urlLang = props.match.params.lang;
    const history = useHistory();

    const [token, setToken] = useState<any>('');
    const [loading2, setLoading2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [isMounted, setIsMounted] = useState(true);

    function handleGetData() {
        setLoading(true);

        const get = getHoldeRequest(token);

        get.then((res) => {
            if (res.data.status === 'success') {
                if (res.data.data.requeriment) {
                    if (res.data.data.requeriment.uuid === token) {
                        history.push(`/management/holder/response/${lang}/${token}`);
                        setToken('');
                    } else {
                        // Token inválido
                        showMessage("Token inválido...");
                        setToken('');
                    }
                } else {
                    // Requerimento não encontrado
                    showMessage("Requerimento não encontrado...");
                    setToken('');
                }
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        }).finally(() => {
            setLoading2(false);
            setLoading(false);
        });
    }

    function showMessage(message: any) {
        // Aqui você pode utilizar alguma biblioteca de alerta, como toast, ou mesmo mostrar diretamente no DOM
        setMessage(message);
    }

    return (
        <div className='d-flex  bg-gray-middle flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/logos/background.png')})`,
                backgroundPosition: 'center',
                backgroundColor: '#1E1E2D',
                backgroundSize: 'cover'
            }}
        >
            <div className='d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20'>
                <div className='w-lg-600px bg-white rounded-5 shadow-sm p-10 p-lg-15 mx-auto'>
                    <div className='mb-4 text-center'>
                        <h1 className='mb-3'>{intl.formatMessage({ id: 'Requirement' })}</h1>
                    </div>
                    <div className='border-bottom border-gray-300 w-100 mb-12'></div>
                    <div className='row mb-2'>
                        <label className={`form-label fw-bold text-dark fs-5 required`}>
                            Insira o código do seu requerimento:
                        </label>
                        <div className='fv-row mb-3'>
                            <input
                                disabled={loading}
                                placeholder={intl.formatMessage({ id: 'CODE' })}
                                type='text'
                                autoComplete='off'
                                className='form-control'
                                onChange={(e) => setToken(e.target.value)}
                            />
                        </div>
                        <p className='text-center'>{message && <span className='text-danger'>{message}</span>}</p>

                        <div className="row">
                            <div className="col text-center">
                                <button className='btn btn-primary btn-sm text-white' onClick={() => handleGetData()}>
                                    {loading ? <span className='spinner-border spinner-border-sm align-middle '></span> : <span>{intl.formatMessage({ id: 'Search' })}</span>}
                                </button>
                            </div>


                        </div>

                    </div>

                </div>
            </div>
        </div >
    );
}

export default ManagementHolderSelect;
