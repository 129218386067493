import Pagination from '../../../../helpers/components/CustomPagination/CustomPagination'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ModalMessage from '../../modals/modalMessage';
import moment from 'moment';
import StoreBuyForm from './StoreBuyForm';
import StoreAdmin from './StoreAdmin';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { UserModel } from '../../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { defaultPaginationList, updateDescription } from '../../../../../app/modules/helpers/Api';
import { KTSVG } from '../../../../helpers';
import { Load } from '../../spinner/Spinner';
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

let searchData = {
  title: '',
  code: '',
  page: 1,
  perpage: 6
}
let widthVar = 450

if (window.screen.width < 500) {
  widthVar = 330
  if (window.screen.width < 300) {
    widthVar = 250
  }

}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  paddingBottom: 10,
  transform: 'translate(-50%, -50%)',
  width: widthVar,
  maxHeight: 300,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};


const Store: React.FC = () => {
  const [plan, setPlan] = useState(0);
  const [title, setTitle] = useState('');
  const [value, setValue] = useState('');
  const [duration, setDuration] = useState('');
  const [hasQtd, setHasQtd] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [loading2, setLoading2] = useState(false);
  const [slugName, setSlugName] = useState('');
  const [slug, setSlug] = useState('');
  const [openStoreBuyForm, setOpenStoreBuyForm] = useState(false);
  const [openStoreAdmin, setOpenStoreAdmin] = useState(false);
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const intl = useIntl();
  const [openModalEditDescription, setOpenModalEditDescription] = useState(false);
  const [editDescription, setEditDescription] = useState('');
  const [idDescription, setIdDescription] = useState<any>();
  const [openModalConfirmEdition, setOpenModalConfirmEdition] = useState(false);
  // const [userPlanValid, setUserPlanValid] = useState(user.package !==null ? true : false);
  const [error, setError] = useState(true);

  useEffect(() => {
    handleGetList(null)
    planValidation()
  }, []);
  function planValidation() {

    if (user.package !== null) {

      let datePlan = user.package.slice(user.package.length - 2)
      let datePlanNumber = parseFloat(datePlan)


      let currentData = moment().format('YYYY-MM-DD')
      let currentDateLastNumbers = currentData.slice(currentData.length - 2)
      let currentDateNumber = parseFloat(currentDateLastNumbers)

      if (datePlanNumber - currentDateNumber === 0 || (datePlanNumber - currentDateNumber <= 3 && datePlanNumber - currentDateNumber > 0) || user.package === null) {
        // setUserPlanValid(false)
        setError(false)
      }
    }

  }
  const saveEdition = () => {
    const data = {
      code: idDescription,
      description: editDescription

    }

    setLoading(true);
    const send = updateDescription(data);
    send.then((res) => {
      if (res.data.status === 'success') {
        toast.success(res.data.message);
        setOpenModalEditDescription(false)
        setOpenModalConfirmEdition(false);
      }
      if (res.data.status === 'error') {
        toast.error(res.data.message);
      }
    }).catch((error) => {
      if (error.response) {
        toast.error(error.response.message);
      }
    }).finally(() => {
      setLoading(false);
      handleGetList(null)
    });
  }
  function handlePlanWarning(item: any) {

    if (user.package !== null && item.slug === "package") {
      if (error) {
        toast.warning(`${intl.formatMessage({ id: 'You already have an active plan' })}`)
      } else {
        setOpenStoreBuyForm(true)
      }
    } else {
      setOpenStoreBuyForm(true)
    }
  }
  function handleGetList(data: null) {
    setLoading(true);
    const get = defaultPaginationList('products/sale/packages', searchData)
    get.then((res) => {
      setData(res.data.data);
    }).catch((error) => {
      toast.error(error.response.data.message)
    })
      .finally(() => [setLoading(false), setLoading2]);
  }



  return (
    <>
      <StoreBuyForm
        showModal={openStoreBuyForm}
        title={intl.formatMessage({ id: 'Order details' })}
        onClose={() => setOpenStoreBuyForm(false)}
        productData={ {product_code: plan } }
        
        // duration={duration}
        // slug={slug}
        // has_qtd={hasQtd}
        // titlePlan={title}
        // value={value}
        // slug_name={slugName}
      /> 
      <StoreAdmin
        showModal={openStoreAdmin}
        title={intl.formatMessage({ id: 'Do you want to change this plan?' })}
        onClose={() => setOpenStoreAdmin(false)}
        onAction={() => [
          setTimeout(() => {
            setOpenStoreAdmin(false)
            handleGetList(data)
          }, 50)
        ]}
        id={plan}
        duration={duration}
        titlePlan={title}
        value={value}
        slug_name={slugName}
      />
      {
        
        <div>
          {
            loading ?
              <Load />
              :
              <>

              <div>

              { !user.profile_status?
                <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
                  <KTSVG path="/media/icons/duotune/general/gen044.svg" className="svg-icon-primary svg-icon-2hx me-5" />        
                  <div className="d-flex flex-column">
                    <h5 className="mb-1">{intl.formatMessage({ id: 'attention' })}!</h5>
                    <span className='fs-5'>
                      <span>Os dados em seu perfil </span>
                      <Link to='/crafted/pages/commum/profile' className='link-dark fw-bolder'>
                        Perfil
                      </Link>
                      <span> precisam estar completos para a aquisição de itens!</span>
                    </span> 
                  </div>
                </div>
                :
                null
              }




  

                {
                  data?.data && data.data.length !== 0 ?
                    <>
                      <div className='row'>
                        {
                          data.data.map((item: any, index: any) =>
                            <div  key={`loja_item_${index}`} className='mb-4 col-12 col-sm-12 col-md-6 col-xl-4'>

                              <div
                                className='card'
                                key={`item_card_${index}`}
                                // onMouseMove={() => [setIdDescription(item.id)]}
                              // className='d-flex flex-column justify-content-between border-dashed border-primary border-1 bg-light-primary bg-hover-light h-250px w-25 rounded p-4 me-2'

                              >
                                <div className="card-header">
                                  <h3 className="card-title fw-bolder">{item.name}</h3>
                                </div>
                                <div className="card-body">

                                  <div style={{ overflow: 'auto', overflowX: 'hidden', minHeight: 100, maxHeight: 100 }} className='mb-4'>
                                    <span className='text-gray-600 fs-6 fw-bold'>{item.description}</span>&nbsp;&nbsp;&nbsp;
                                    {['admin', 'super-admin'].includes(user.roles) ?
                                      <button
                                        className='btn btn-light-warning p-0 fs-7'
                                        style={{ width: '80px' }}
                                        onClick={() => [setOpenModalEditDescription(true), setEditDescription(item.description), setIdDescription(item.id)]}
                                      >
                                        {intl.formatMessage({ id: 'Edit' })}
                                      </button>
                                      :
                                      <></>
                                    }
                                  </div>

                                  <div className='d-flex flex-column mb-4'>
                                    <span className='text-gray-600 fs-6 fw-bold'>{intl.formatMessage({ id: 'Total' })}: BRL <strong>{item.value}</strong></span>
                                    <span className='text-gray-600 fs-6 fw-bold'>{intl.formatMessage({ id: 'Duration' })}: <strong>{item.duration ? (item.duration + ' ' + intl.formatMessage({ id: 'Days' })) : '- - -'}</strong> </span>
                                  </div>

                                  {
                                    ['admin', 'super-admin'].includes(user.roles) ?
                                      <button
                                        className='btn btn-sm btn-primary w-100 text-dark'
                                        type='button'
                                        onClick={() => [
                                          setPlan(item.id),
                                          setTitle(item.name),
                                          setValue(item.value),
                                          setDuration(item.duration),
                                          setSlug(item.slug),
                                          setHasQtd(item.has_qtd),
                                          setOpenStoreAdmin(true),
                                          setIdDescription(item.id)
                                        ]}
                                      // disabled={!['super-admin'].includes(user.roles)}
                                      >
                                        {intl.formatMessage({ id: 'Change Item' })}
                                      </button>
                                      :
                                      <button
                                        className='btn btn-sm btn-primary w-100 text-dark'
                                        type='button'
                                        disabled={!user.profile_status || 
                                          item.slug === 'package' && !['free', 'dpo', 'company', 'liberal', 'startup', 'manager'].includes(user.roles)
                                        }
                                        onClick={() => [
                                          setPlan(item.id),
                                          setTitle(item.name),
                                          setValue(item.value),
                                          setDuration(item.duration),
                                          setSlug(item.slug),
                                          setHasQtd(item.has_qtd),
                                          handlePlanWarning(item),
                                          setSlugName(item.slug_name),
                                          setIdDescription(item.id)

                                        ]} >
                                        {/* {
                                      user.roles === 'free' ?
                                      intl.formatMessage({id: 'Select'})

                                      :
                                      intl.formatMessage({id: 'Renew Plan'})

                                    } */}
                                        {intl.formatMessage({ id: 'Select' })}
                                      </button>
                                  }

                                </div>

                                <div className='d-flex flex-column'>


                                </div>





                              </div>

                            </div>


                          )
                        }
                      </div><Pagination data={data} onSearch={handleGetList} searchData={searchData} />

                    </>
                    :
                    <div className='card' >
                      <div className="card-header">
                        <h3 className="card-title fw-bolder">{intl.formatMessage({ id: 'Products' })}</h3>
                      </div>
                      <div className="card-body text-center text-muted fs-6 fw-bold">
                        Nenhum item disponível no momento...
                      </div>
                    </div>              
                }


              </div>
          
              </>
          }
        </div>


      }
      <ModalMessage
        message={intl.formatMessage({ id: 'Are you sure you want to edition this item?' })}
        title={intl.formatMessage({ id: 'Attention' })}
        showModal={openModalConfirmEdition}
        onClose={() => setOpenModalConfirmEdition(false)}
        onAction={() => [saveEdition()]}
      />
      <Modal
        open={openModalEditDescription}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-end align-items-center w-100'>

            <div
              className="btn btn-icon btn-sm btn-active-light-primary"
              onClick={() => [setOpenModalEditDescription(false)]}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className='px-5 border-1 border-bottom border-gray-200' >
            <label className='fs-5 text-gray-700 fw-bolder ' >{intl.formatMessage({ id: 'Desciption' })}:</label>
            <textarea

              className="form-control form-control-solid fs-5"
              placeholder={intl.formatMessage({ id: 'Desciption' })}
              value={editDescription}
              onChange={(e) => setEditDescription(e.currentTarget.value)}
              style={{ height: '140px' }}

            />
          </div>
          <div className='p-5 d-flex justify-content-end'>
            <button className='btn btn-primary w-100 text-dark' onClick={() => [setOpenModalConfirmEdition(true)]}>
              {intl.formatMessage({ id: 'Edit' })}
            </button>
          </div>

        </Box>
      </Modal>
    </>
  )
}
export default Store;