/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { StepThree } from './Step03'

const schema = Yup.object().shape({
  legal_base: Yup.string()
  .required('Legal base is required'),
})

type Props = {
  move: (action: boolean) => void;
  dataStep1: any;
}

const StepTwo: FC<Props> = ({...props}) => {  

  const[loading, setLoading] = useState(false);
  const[next, setNext] = useState('');
  const[dataStep2, setDataStep2] = useState<any>({});
  
  const initialValues = {
    legal_base: '',
    complement: '',
  }
  function move (action: boolean) {
    if(action){
      setNext('');
    }
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false)
        setDataStep2({
          legal_base: values.legal_base,
          complement: values.complement
        })
        setNext('Next')
      }, 1000)
    },
  })

  return (
    <>
    { next === ''?
    <div className='card mb-5 mb-xl-10'>
      <div className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top px-5 py-5'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <label className='form-label fw-bolder text-dark fs-3 '>New Data Mapping - Step 02 of 06</label>
              {/* <button onClick={()=>props.create(false)} type='submit' className='btn btn-sm btn-secondary'>
                Back
              </button> */}
            </div>
            <div className='mb-2 fv-row border-top pt-5' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bold required text-dark fs-6'>Legal Base</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('legal_base')}
                    >
                      <option value=''>...Select an option</option>
                      <option value='Consentimento'>Consentimento</option>
                      <option value='Legítimo interesse do controlador'>Legítimo interesse do controlador</option>
                      <option value='Proteção à vida'>Proteção à vida</option>
                      <option value='Tutela da saúde'>Tutela da saúde</option>
                      <option value='Execução do contrado'>Execução do contrado</option>
                      <option value='Obrigação legal'>Obrigação legal</option>
                      <option value='EXERCÍCIO REGULAR DO DIREITO EM PROCESSO'>EXERCÍCIO REGULAR DO DIREITO EM PROCESSO</option>
                      <option value='Políticas Públicas'>Políticas Públicas</option>
                      <option value='Pesquisa'>Pesquisa</option>
                      <option value='Proteção de Crédito'>Proteção de Crédito</option>
                    </select>
                  </div>
                  {formik.touched.legal_base && formik.errors.legal_base && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.legal_base}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1 mt-4'>
                <label className='form-label fw-bold text-dark fs-6'>Complement</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <textarea
                      placeholder='Complement'
                      autoComplete='off'
                      {...formik.getFieldProps('complement')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-100 me-1',
                        
                      )}
                    />
                  </div>
                  
                </div>
              </div>
            </div>
            
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-secondary me-3' onClick={()=>props.move(true)}>
              Back
            </button>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Next'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Processando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
      :
      <StepThree move={move} dataStep1={props.dataStep1} dataStep2={dataStep2}/>
    }
    </>
  )
}

export {StepTwo}
