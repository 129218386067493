/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { createActions, defaultPaginationList } from '../../../../app/modules/helpers/Api'
import { toast } from 'react-toastify'
import { trataRespota } from '../../../../app/modules/helpers/helpers'
import { KTSVG } from '../../../helpers'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router-dom'
import ActionSelectGap from './ActionSelectGap'



let searchData = {
  title: '',
  code: '',
  module: '',
  form: '',
  page: 1,
  perpage: 100000,
}

let splitVar = 60

if(window.screen.width < 670){
  splitVar = 50
    if(window.screen.width < 570){
      splitVar = 40
      if(window.screen.width < 490){
        splitVar = 25
        if(window.screen.width < 300){
          splitVar = 15
        }
      }
    }
    
}

type Props = {
  create: (action: boolean) => void;
  idModulo: any;
  idForm: any;
}

const CreateAction: React.FC<Props> = ({...props}) => {  
  const[loading, setLoading] = useState(false);
  const intl = useIntl();   
  
  const [openModalSelectGap, setOpenModalSelectGap] = useState(false);
  const [gapList, setGapList] = useState<any>([]);
  const [gapSelectId, setGapSelectId] = useState('');

  const [gapSelect, setGapSelect] = useState(`${intl.formatMessage({id: 'Select'})}`);

  const schema = Yup.object().shape({
    title: Yup.string()
    .required(`${intl.formatMessage({id: 'Title'})} ${intl.formatMessage({id: 'is required'})}`),
    degree_max: Yup.string()
    .required(`${intl.formatMessage({id: 'Max Degree'})} ${intl.formatMessage({id: 'is required'})}`),
    degree_min: Yup.string()
    .required(`${intl.formatMessage({id: 'Min Degree'})} ${intl.formatMessage({id: 'is required'})}`),
    value: Yup.string()
    .required(`${intl.formatMessage({id: 'Value'})} ${intl.formatMessage({id: 'is required'})}`),
    model: Yup.string()
    .required(`${intl.formatMessage({id: 'Model'})} ${intl.formatMessage({id: 'is required'})}`)
    // title_resource: Yup.string()
    // .required('Title resource is required'),
    // url: Yup.string()
    // .required('Url is required'),
  })

  const initialValues = {
    title: '',
    degree_max: '',
    degree_min: '',
    value: '',
    model: ''
  }
  
    function handleGetGapList () {

      setLoading(true);

      searchData.module = props.idModulo;
      searchData.form = props.idForm;

      const action = defaultPaginationList('gaps', searchData);
      action.then((res)=>{
        if (res.data.status == 'error') {
          toast.error(res.data.message)
        } else {
          setGapList(res.data.data.data);
          setOpenModalSelectGap(true)
        }

      }).catch((error)=> {
        toast.error(error.response.data.message)
      })
      .finally(()=>setLoading(false));
    }

  
  function handleSelect(id: any, title: any) {
    setGapSelect(title)
    setGapSelectId(id) 
    setOpenModalSelectGap(false);
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        save(values);
        setLoading(false)
      }, 1000)
    },
  })

  function save (values: any){
    const data = {
      title: values.title,
      degree_max: values.degree_max,
      degree_min: values.degree_min,
      value: values.value,
      model: values.model,
      action_legend_id: values.value,
      modulo_id: props.idModulo,
      form_id: props.idForm,
      gap_id: gapSelectId
    }
    const create = createActions(data);
    create.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        props.create(false)
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      trataRespota('error', error); 
    });
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      {
        gapList !== 0?
        <ActionSelectGap
          dataAction={gapList}
          title={intl.formatMessage({id: 'Select'})}
          showModal={openModalSelectGap}
          onClose={()=>setOpenModalSelectGap(false)}
          onAction={handleSelect}
        />
        :
        <></>
      }
      <div className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top px-5 py-5'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <label className='form-label fw-bolder text-dark fs-3 '>{intl.formatMessage({id: 'New Action'})}</label>
              <button onClick={()=>props.create(false)} type='button' className='btn btn-sm btn-secondary p-3'>
                  <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark'/>
                  {intl.formatMessage({id: 'Back'})}
              </button>
             
            </div>
            <div className='mb-2 fv-row border-top' data-kt-password-meter='true'>
              <div className='mb-1 mt-4'>
                <label className='form-label required fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Title'})}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <input
                      type='text' 
                      placeholder={intl.formatMessage({id: 'Title'})}
                      autoComplete='off'
                      {...formik.getFieldProps('title')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-100 me-1',
                        {
                          'is-invalid': formik.touched.title && formik.errors.title,
                        },
                        {
                          'is-valid': formik.touched.title && !formik.errors.title,
                        }
                      )}
                    />
                  </div>
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label required fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Model'})}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-select-solid form-select-lg fw-bold fs-4'
                    {...formik.getFieldProps('model')}
                    >
                      <option value="">{intl.formatMessage({id: 'Select'})}</option>
                      <option value="1">{intl.formatMessage({id: 'Maturity analysis'})}</option>
                      <option value="2">{intl.formatMessage({id: 'Scored'})}</option>
                      <option value="3">{intl.formatMessage({id: 'Not scored'})}</option>
                      <option value="4">{intl.formatMessage({id: 'Data Mapping'})}</option>
                    </select>
                  </div>
                  {formik.touched.model && formik.errors.model && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.model}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label required fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Max Degree'})}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <input
                      type='text'
                      placeholder={intl.formatMessage({id: 'Max Degree'})}
                      autoComplete='off'
                      {...formik.getFieldProps('degree_max')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-100 me-1',
                        {
                          'is-invalid': formik.touched.degree_max && formik.errors.degree_max,
                        },
                        {
                          'is-valid': formik.touched.degree_max && !formik.errors.degree_max,
                        }
                      )}
                    />
                  </div>
                  {formik.touched.degree_max && formik.errors.degree_max && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.degree_max}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label required fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Min Degree'})}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <input
                      type='text'
                      placeholder={intl.formatMessage({id: 'Min Degree'})}
                      autoComplete='off'
                      {...formik.getFieldProps('degree_min')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-100 me-1',
                        {
                          'is-invalid': formik.touched.degree_min && formik.errors.degree_min,
                        },
                        {
                          'is-valid': formik.touched.degree_min && !formik.errors.degree_min,
                        }
                      )}
                    />
                  </div>
                  {formik.touched.degree_min && formik.errors.degree_min && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.degree_min}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='mb-2 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label required fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Value'})}</label>
                <div className='position-relative mb-3'>
                  <div className='d-flex justify-content-between w-100'>
                    <select className='form-select form-control form-control-solid fs-6' 
                      {...formik.getFieldProps('value')}
                    >
                      <option value=''>{intl.formatMessage({id: 'Select'})}</option>
                      <option style={{backgroundColor: '#c70100', color: '#fff'}} value='1'>1</option>
                      <option style={{backgroundColor: '#008fc7', color: '#fff'}} value='2'>2</option>
                      <option style={{backgroundColor: '#01771f', color: '#fff'}} value='3'>3</option>
                      <option style={{backgroundColor: '#01c732', color: '#fff'}} value='4'>4</option>
                      <option style={{backgroundColor: '#000000', color: '#fff'}} value='5'>5</option>
                      <option style={{backgroundColor: '#af48cf', color: '#fff'}} value='6'>6</option>
                    </select>
                    {/* <input
                      type='text'
                      placeholder='Value'
                      autoComplete='off'
                      {...formik.getFieldProps('value')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid w-100 me-1',
                        {
                          'is-invalid': formik.touched.value && formik.errors.value,
                        },
                        {
                          'is-valid': formik.touched.value && !formik.errors.value,
                        }
                      )}
                    /> */}
                  </div>
                  {formik.touched.value && formik.errors.value && (
                    <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.value}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-lg-12 fv-row'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>{intl.formatMessage({id: 'Gap'})}</label>
                    <button type='button' style={{textAlign: 'start'}} onClick={()=>handleGetGapList()} className='form-select form-select-solid form-select-lg fw-bold fs-4 h-50px'>
                      <span>{gapSelect.slice(0,splitVar)+'...'}</span>
                    </button>
                    
                </div>
              </div>
            </div>
            {/* <div className='border-dashed border-gray-400 border-1 p-5'>
              <label className='form-label fw-bold text-gray-700 fs-4 mb-5'>Add Resources</label>
              <div className='fv-row mb-3'>
                <label className='form-label fw-bold text-dark fs-6'>Title</label>
                <input
                  type='text'
                  placeholder='Title'
                  autoComplete='off'
                  className='form-control form-control-lg form-control-solid'
                  {...formik.getFieldProps('title_resource')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.title_resource && formik.errors.title_resource,
                    },
                    {
                      'is-valid': formik.touched.title_resource && !formik.errors.title_resource,
                    }
                  )}
                />
                {formik.touched.title_resource && formik.errors.title_resource && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.title_resource}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-3'>
                <label className='form-label fw-bold text-dark fs-6'>URL</label>
                <input
                  type='text'
                  placeholder='URL'
                  autoComplete='off'
                  className='form-control form-control-lg form-control-solid'
                  {...formik.getFieldProps('url')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.url && formik.errors.url,
                    },
                    {
                      'is-valid': formik.touched.url && !formik.errors.url,
                    }
                  )}
                />
                {formik.touched.url && formik.errors.url && (
                  <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.url}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-3'>
                <label className='form-label fw-bold text-dark fs-6'>File</label>
                <input
                  type='file'
                  placeholder='Doc'
                  className="form-control form-control-solid fs-5"
                />
              </div>
              <div className='w-100 d-flex align-items-end justify-content-end'>
                <button type='submit' disabled className='btn btn-sm btn-info'>
                  Add resource
                </button>
              </div>
            </div> */}
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary text-dark' disabled={loading}>
              {!loading && intl.formatMessage({id: 'Save'})}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'Processing'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {CreateAction}
