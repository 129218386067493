import React from 'react';
import { useIntl } from 'react-intl'
interface Props {
  data: any,
  searchData: any
  onSearch: (search: any) => void;
} 

const CustomPagination: React.FC<Props> = ({
  ...props
}) => {
  const intl = useIntl();   
  const handleNextPage = () => {
    if (props.data.current_page < props.data.last_page) {
      props.searchData.page = props.data.current_page+1
      props.onSearch(props.searchData)
    }   
  }

  function handlePrevPage(){
    if(props.data.current_page > 1) {
      props.searchData.page = props.data.current_page-1
      props.onSearch(props.searchData)
    }
  }

  return(
    <div className='d-flex flex-stack flex-wrap mt-5 mb-5'>
      <div className='fs-6 fw-bold text-gray-700'>{intl.formatMessage({id: 'Showing'})} {props.data.from?props.data.from:'0'} {intl.formatMessage({id: 'to'})}  {props.data.to?props.data.to:'0'} {intl.formatMessage({id: 'of'})} {props.data.total?props.data.total:'0'} {intl.formatMessage({id: 'entries'})}</div>
      <ul className='pagination'>
        <li className={`page-item previous ${props.data.current_page==1?'disabled':''} ${props.data.current_page?'':'disabled'}` } onClick={handlePrevPage}>
          <a href='#' className='page-link'>
            <i className='previous' ></i>
          </a>
        </li>
        <li className='page-item active'>
          <a href='#' className='page-link bg-primary text-dark'>
            {props.data.current_page ? props.data.current_page : '1'}
          </a>
        </li>
        <li className={`page-item next ${props.data.current_page>=props.data.last_page?'disabled':''} ${props.data.current_page?'':'disabled'}`} onClick={handleNextPage}>
          <a href='#' className='page-link'>
            <i className='next' ></i>
          </a>
        </li>
      </ul>
    </div>
  )
}
export default CustomPagination;