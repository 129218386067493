import axios from 'axios';
import $ from "jquery";
import { toast } from 'react-toastify'
import { trataRespota } from '../../../app/modules/helpers/helpers'

const api = process.env.REACT_APP_API_ENDPOINT + '/api'

const token = localStorage.getItem('token');
let headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Authorization': `Bearer ${token}`
}

// resource link
export function getResourceLink(hash: any) {

  let url = `${api}/file/show?name=${hash}`;

  toast.warning(`Carregando, Aguarde...`, { autoClose: 2500 });

  axios.get(url, { headers: headers, responseType: "blob" }).then(function (response) {
    var file = new Blob([response.data], { type: response.data.type });
    var fileURL = URL.createObjectURL(file);
    
    if (['video/mp4', 'video/quicktime'].includes(response.data.type)) {

      let customPlayer = $("#custom-video-player");
      if (customPlayer) {
        customPlayer.css("display", "flex")

        // $("#video-player-1").html(`<source src=${fileURL} type="video/mp4"></source>`);
        var video = document.getElementById('example_video');
        var source = document.getElementById('video-player-1');
        // @ts-ignore: Object is possibly 'null'.
        video.pause()
        // @ts-ignore: Object is possibly 'null'.
        source.setAttribute('src', fileURL);
        // @ts-ignore: Object is possibly 'null'.
        source.setAttribute('type', response.data.type);

        // @ts-ignore: Object is possibly 'null'.
        video.load();
        // @ts-ignore: Object is possibly 'null'.
        video.play();
      } else {
        toast.error(`Não foi possivel carregar o player`, { autoClose: 2000 });
      }


    } else {
      window.open(fileURL);
    }

  })
    .then((response) => {
      toast.success(`Carregamento concluido, abrindo...`, { autoClose: 2000 });
    })
    .catch(function (error) {
      console.log(error);
      toast.error(`Erro ao acessar o arquivo!`, { autoClose: 2000 });
    });

}

// Companies
export async function upLogo(formData: any, id?: number) {

  $("#upload_bar_progress_1").val(0);
  $('#dialog_upload_span').text("");

  let title = 'Logo da Empresa';
  // for (var pair of formData.entries()) {
  //   if (pair[0] === 'title') {
  //     title = pair[1]
  //   } 
  // }

  toast.warning(`Enviando ${title}... Aguarde!`, { autoClose: 1500 });
  // $('#dialog_upload_span').html(`O envio do arquivo: ${title} está sendo iniciado... Aguarde!`);

  return axios.post(`${api}/companies/logo/change/${id}`, formData, {
    headers: headers,
    onUploadProgress: data => {
      $("#upload_bar_progress_1").val(Math.round((100 * data.loaded) / data.total));
      $('#dialog_upload_span').html(`O envio do arquivo: <b>${title}</b> está em <b>${Math.round((100 * data.loaded) / data.total)}%</b> Aguarde...`);
    }
  }).then((res) => {
    if (res.data.status === 'success') {
      toast.success(res.data.message);
      toast.success(`O envio do arquivo foi concluido!`, { autoClose: 2000 });
      // $('#dialog_upload_span').html(`O envio do arquivo: ${title} foi concluido!`);

      //  funcBack();
    }
    if (res.data.status === 'error') {
      toast.error(`Houve um erro ao enviar o arquivo!`, { autoClose: 2000 });
      toast.error(res.data.message);
      // $('#dialog_upload_span').html(res.data.message);
    }
  }).catch((error) => {
    trataRespota('error', error);
    toast.error(error.response.data.message);

  });

}
export function getCompanies() {
  return axios.get(`${api}/companies`, { headers: headers })
}
export function getCompaniesToCalculator() {
  return axios.get(`${api}/companies?status=active`, { headers: headers })
}

export function deleteActionEvidence(uuid: string) {
  return axios.delete(`${api}/action/evidence/${uuid}`, { headers: headers })
}



export function editActionEvidence(uuid: string) {
  return axios.get(`${api}/action/evidence/${uuid}`, { headers: headers })
}





export function delCompanies(id: number) {
  return axios.delete(`${api}/companies/${id}`, { headers: headers })
}
export function getCompaniesById(id: number) {
  return axios.get(`${api}/companies/${id}`, { headers: headers })
}
export function putCompanies(id: number, data: object) {
  return axios.put(`${api}/companies/${id}`, data, { headers: headers })
}
export function createCompanies(data: object) {
  return axios.post(`${api}/companies`, data, { headers: headers })
}
export function getCompaniesByUser() {
  return axios.get(`${api}/user/companies/get`, { headers: headers })
}

// Prescriptions (Forms)
export function getPrescriptions(data: any) {

  let url = `${api}/prescriptions/list/active?perpage=5`;

  if (data['page']) {
    url = url + '&page=' + data['page'];
  }
  if (data['title']) {
    url = url + '&title=' + data['title'];
  }
  if (data['code']) {
    url = url + '&code=' + data['code'];
  }

  return axios.get(url, { headers: headers })
}

// export function getRequerimentTokens (data: any){
//   // console.log(data);

//   let url = `${api}/company/requeriment/tokens`;

//   // if (data['page']) {
//   //   url = url+'&page='+data['page'];
//   // }
//   // if (data['title']) {
//   //   url = url+'&title='+data['title'];
//   // }
//   // if (data['code']) {
//   //   url = url+'&code='+data['code'];
//   // }

//   return axios.get(url, {headers: headers})
// }




// Sector
export function getSector() {
  return axios.get(`${api}/sectors?perpage=300`, { headers: headers })
}

// Questions
export function getQuestion(idForm: number, idSector: number, idCompany: number) {
  return axios.get(`${api}/prescriptions/questions/list?perpage=100&prescription=${idForm}&sector=${idSector}&company=${idCompany}`, { headers: headers })
}

export function saveAnswer(data: object) {
  return axios.post(`${api}/prescription/answereds`, data, { headers: headers })
}
export function deleteAnswer(id: any) {
  return axios.delete(`${api}/prescription/answereds/${id}`, { headers: headers })
}
export function getAnswers(id: number) {
  return axios.get(`${api}/prescription/answereds?perpage=300&company=${id}`, { headers: headers })
}
// Users
export function getUsersByCompany(id: number) {
  return axios.get(`${api}/company/users?perpage=300&&company=${id}`, { headers: headers })
}
export function delUsers(idCompany: number, id: number) {
  return axios.delete(`${api}/companies/${idCompany}/user/${id}`, { headers: headers })
}
// Report
export function getDegreeByCompany(id: number) {
  return axios.get(`${api}/degree/risk/report/${id}`, { headers: headers })
}
export function getAction(model: any) {
  return axios.get(`${api}/actions?perpage=300&model=${model}`, { headers: headers })
}
//action Plan
export function getActionPlans() {
  return axios.get(`${api}/actionplans?perpage=300`, { headers: headers })
}
export function delActionPlans(id: any) {
  return axios.delete(`${api}/actionplans/${id}`, { headers: headers })
}
export function getActionPlansById(id: number) {
  return axios.get(`${api}/actionplans/${id}`, { headers: headers })
}
export function updateActionPlans(id: any, data: object) {
  return axios.put(`${api}/actionplans/${id}`, data, { headers: headers })
}
export function delAction(plan_id: any, action_id: any) {
  return axios.delete(`${api}/actionplans/${plan_id}/action/${action_id}`, { headers: headers })
}
export function newActionPlan(data: object) {
  return axios.post(`${api}/actionplans`, data, { headers: headers })
}
export function newAction(id: any, data: object) {
  return axios.put(`${api}/actionplans/action/${id}`, data, { headers: headers })
}
// Plans
export function newPlan(data: object) {
  return axios.post(`${api}/user/buy/package`, data, { headers: headers })
}
export function getPlan() {
  return axios.get(`${api}/products/sale/packages`, { headers: headers })
}

export function checkCouponCode(data: object) {
  return axios.post(`${api}/coupons/check`, data, { headers: headers })
}

export function getProduct(id: any) {
  return axios.get(`${api}/products/sale/${id}/get`, { headers: headers })
}

export function updatePlan(data: object) {
  return axios.post(`${api}/products/sale/packages/update`, data, { headers: headers })
}
export function updateDescription(data: object) {
  return axios.post(`${api}/products/sale/packages/updateDescription`, data, { headers: headers })
}

export function deleteConfigApiToken(uuid: any) {
  return axios.delete(`${api}/company/api_token/${uuid}/delete`, { headers: headers })
}

// Add user
export function newUser(data: object) {
  return axios.post(`${api}/companies/invite/send`, data, { headers: headers })
}
// Uer
export function getUserById(id: any) {
  return axios.get(`${api}/users/${id}`, { headers: headers })
}
export function upUser(data: object) {
  return axios.put(`${api}/auth/profile/update`, data, { headers: headers })
}
export function upLanguageUser(data: object) {
  return axios.put(`${api}/auth/profile/update/language`, data, { headers: headers })
}
export async function upPhoto(formData: any, funcBack?: any) {

  $("#upload_bar_progress_1").val(0);
  $('#dialog_upload_span').text("");

  let title = 'Foto de Perfil';
  // for (var pair of formData.entries()) {
  //   if (pair[0] === 'title') {
  //     title = pair[1]
  //   } 
  // }

  toast.warning(`Enviando ${title}... Aguarde!`, { autoClose: 1500 });
  $('#dialog_upload_span').html(`O envio do arquivo: ${title} está sendo iniciado... Aguarde!`);

  return axios.post(`${api}/auth/photo/update`, formData, {
    headers: headers,
    onUploadProgress: data => {
      $("#upload_bar_progress_1").val(Math.round((100 * data.loaded) / data.total));
      // $('#dialog_upload_span').html(`O envio do arquivo: <b>${title}</b> está em <b>${Math.round((100 * data.loaded) / data.total)}%</b> Aguarde...`);
    }
  }).then((res) => {
    if (res.data.status === 'success') {
      toast.success(res.data.message);
      toast.success(`O envio do arquivo: ${title} foi concluido!`, { autoClose: 2000 });
      // $('#dialog_upload_span').html(`O envio do arquivo: ${title} foi concluido!`);

      funcBack();
    }
    if (res.data.status === 'error') {
      toast.error(`Houve um erro ao enviar o arquivo: ${title}!`, { autoClose: 2000 });
      toast.error(res.data.message);
      $('#dialog_upload_span').html(res.data.message);
    }
  }).catch((error) => {
    trataRespota('error', error);
    toast.error(error.response.data.message);

  });

}


export function upUserPass(id: any, data: object) {
  return axios.put(`${api}/auth/me/password/update`, data, { headers: headers })
}
export function deleteProfile(data: any) {
  return axios.post(`${api}/auth/me/delete`, data, { headers: headers })
}

// Change status
export function changeStatus(data: object) {
  return axios.post(`${api}/companies/status/change`, data, { headers: headers })
}

//
export function getActions() {
  return axios.get(`${api}/actions?perpage=300`, { headers: headers })
}
export function getActionsById(id: any) {
  return axios.get(`${api}/actions/${id}`, { headers: headers })
}
export function createActions(data: object) {
  return axios.post(`${api}/actions`, data, { headers: headers })
}
export function upActions(id: any, data: object) {
  return axios.put(`${api}/actions/${id}`, data, { headers: headers })
}
export function delActions(id: any) {
  return axios.delete(`${api}/actions/${id}`, { headers: headers })
}
//Gaps
export function getGapsById(id: any) {
  return axios.get(`${api}/gaps/${id}/edit`, { headers: headers })
}
export function createGaps(data: object) {
  return axios.post(`${api}/gaps`, data, { headers: headers })
}
export function updateGaps(id: any, data: object) {
  return axios.put(`${api}/gaps/${id}`, data, { headers: headers })
}
export function delGaps(id: any) {
  return axios.delete(`${api}/gaps/${id}`, { headers: headers })
}

// Resources
export async function createResources(formData: any, funcBack?: any) {

  $("#upload_bar_progress_1").val(0);
  $('#dialog_upload_span').text("");

  let title: null = null;
  for (var pair of formData.entries()) {
    if (pair[0] === 'title') {
      title = pair[1]
    }
  }

  toast.warning(`Enviando ${title}... Aguarde!`, { autoClose: 1500 });
  $('#dialog_upload_span').html(`O envio do arquivo: ${title} está sendo iniciado... Aguarde!`);

  return axios.post(`${api}/resources`, formData, {
    headers: headers,
    onUploadProgress: data => {
      $("#upload_bar_progress_1").val(Math.round((100 * data.loaded) / data.total));
      $('#dialog_upload_span').html(`O envio do arquivo: <b>${title}</b> está em <b>${Math.round((100 * data.loaded) / data.total)}%</b> Aguarde...`);
    }
  }).then((res) => {
    if (res.data.status === 'success') {
      toast.success(res.data.message);
      toast.success(`O envio do arquivo: ${title} foi concluido!`, { autoClose: 2000 });
      $('#dialog_upload_span').html(`O envio do arquivo: ${title} foi concluido!`);

      funcBack();
    }
    if (res.data.status === 'error') {
      toast.error(`Houve um erro ao enviar o arquivo: ${title} foi concluido!`, { autoClose: 2000 });
      toast.error(res.data.message);
      $('#dialog_upload_span').html(res.data.message);
    }
  }).catch((error) => {

    let errorMessage = error.response.data.message ?
      error.response.data.message : (error.response.statusText ? error.response.statusText : 'Não foi possivel enviar!');

    toast.error(`Houve um erro ao enviar o arquivo: ${title} foi concluido!`, { autoClose: 2000 });

    if (error.response.status === 422) {
      if (error.response.data.errors) {
        let errorValue = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0];
        toast.error(errorValue);
        $('#dialog_upload_span').html(errorValue);
      }
    } else {
      toast.error(errorMessage);
      $('#dialog_upload_span').html(errorMessage);
    }

  });

}
export function downEvidence(uuid: any) {
  return axios.get(`${api}/action/evidence/${uuid}/download`, { headers: headers, responseType: 'blob', })
}

export function downResource(id: any) {
  return axios.get(`${api}/resource/down/${id}`, { headers: headers, responseType: 'blob', })
}
export function getResources() {
  return axios.get(`${api}/resources`, { headers: headers })
}
export function getResourcesId(id: any) {
  return axios.get(`${api}/resources/${id}`, { headers: headers })
}
export function delResources(id: any) {
  return axios.delete(`${api}/resources/${id}`, { headers: headers })
}
// Payments
export function getPayment() {
  return axios.get(`${api}/products/sold`, { headers: headers })
}
export function getPaymentsById(id: any) {
  return axios.get(`${api}/products/sold/${id}`, { headers: headers })
}
export function createPayment(data: object) {
  return axios.post(`${api}/products/sold/pay`, data, { headers: headers })
}
export function cancelPayment(id: any) {
  return axios.get(`${api}/products/sold/invoice/cancel/${id}`, { headers: headers })
}
export function retryPayment(id: any) {
  return axios.get(`${api}/products/sold/invoice/retry/${id}`, { headers: headers })
}
export function checkPayment(id: any) {
  return axios.get(`${api}/products/sold/invoice/check/${id}`, { headers: headers })
}


export function changeRecurrentItem(uuid: any) {
  return axios.get(`${api}/products/sold/invoice/recurrent/change/${uuid}`, { headers: headers })
}

// auth/register
export function regToken(data: object) {
  return axios.post(`${api}/auth/register`, data, { headers: headers })
}
//datamapping
export function createDatamapping(data: object) {
  return axios.post(`${api}/datamapping`, data, { headers: headers })
}
// calculator
export function getForms() {
  return axios.get(`${api}/prescription/answereds`, { headers: headers })
}

// GATE
export function setSubAccountActive() {
  return axios.post(`${api}/user/gate/subaccount/create`, [], { headers: headers })
}
export function sendVerificationSubAccount(id: any, data: object) {
  return axios.post(`${api}/user/gate/subaccount/verification`, data, { headers: headers })
}
export function sendOnOffRepasseValor(data: object) {
  return axios.post(`${api}/user/gate/subaccount/repass/onoff`, data, { headers: headers })
}
export function AttInfoSubAcc() {
  return axios.get(`${api}/user/gate/subaccount/info`, { headers: headers })
}

// forms
export function getFormsList() {
  return axios.get(`${api}/prescriptions?perpage=300`, { headers: headers })
}
export function createFormsList(data: any) {
  return axios.post(`${api}/prescriptions/upstore`, data, { headers: headers })
}
export function editionFormsList(id: any) {
  return axios.get(`${api}/prescriptions/${id}/edit`, { headers: headers })
}
export function editionSaveFormsList(id: any, data: any) {
  return axios.post(`${api}/prescriptions/upstore`, data, { headers: headers })
}
export function deleteForm(id: any) {
  return axios.delete(`${api}/prescriptions/${id}`, { headers: headers })
}

// Form questions 
export function getFormQuestions(model: any) {
  return axios.get(`${api}/questions?perpage=300&model=${model}&order=ASC`, { headers: headers })
}
export function questionFormCreate(data: any) {
  return axios.post(`${api}/prescriptions/questions/upstore`, data, { headers: headers })
}

export function storeFormQuestions(data: any) {
  return axios.post(`${api}/prescriptions/questions/store`, data, { headers: headers })
}

export function removeFormQuestion(id: any) {
  return axios.delete(`${api}/prescriptions/questions/${id}/remove`, { headers: headers })
}



export function addAnswerQuestion(data: any) {
  return axios.post(`${api}/prescriptions/questions/answer/store`, data, { headers: headers })
}

export function removeAnswerQuestion(id: any) {
  return axios.delete(`${api}/prescriptions/questions/answer/${id}/remove`, { headers: headers })
}


export function handleGapByForm(id: any) {
  return axios.get(`${api}/prescriptions/${id}/gaps`, { headers: headers })
}



// Questions
export function getQuestions() {
  return axios.get(`${api}/questions?perpage=300`, { headers: headers })
}
export function getQuestionCategory() {
  return axios.get(`${api}/questions/undefined/edit`, { headers: headers })
}
export function getQuestionById(id: any) {
  return axios.get(`${api}/questions/${id}/edit`, { headers: headers })
}
export function questionCreate(data: any) {
  return axios.post(`${api}/questions/upstore`, data, { headers: headers })
}
export function questionEdit(id: any, data: any) {
  return axios.put(`${api}/questions/${id}/upstore`, data, { headers: headers })
}
export function deleteQuestions(id: any) {
  return axios.delete(`${api}/questions/${id}`, { headers: headers })
}

// Users
export function getUsers() {
  return axios.get(`${api}/users?perpage=300`, { headers: headers })
}
export function getUsersId(id: any) {
  return axios.get(`${api}/users/${id}`, { headers: headers })
}
export function deleteUser(id: any) {
  return axios.delete(`${api}/users/${id}`, { headers: headers })
}

export function deleteCoupon(uuid: any) {
  return axios.delete(`${api}/coupons/${uuid}`, { headers: headers })
}




export function createUser(data: any) {
  return axios.post(`${api}/users/`, data, { headers: headers })
}

export function createCoupon(data: any) {
  return axios.post(`${api}/coupons/`, data, { headers: headers })
}

export function upPassword(id: any, data: any) {
  return axios.put(`${api}/users/password/${id}`, data, { headers: headers })
}
export function upRoles(id: any, data: any) {
  return axios.put(`${api}/users/role/${id}`, data, { headers: headers })
}
export function upEmail(id: any, data: any) {
  return axios.put(`${api}/users/mail/${id}`, data, { headers: headers })
}
export function upProfile(id: any, data: any) {
  return axios.put(`${api}/users/${id}`, data, { headers: headers })
}


// Monitoramento
export function monitoringCreate(data: any) {
  return axios.post(`${api}/monitoring`, data, { headers: headers })
}
export function monitoringUpdate(data: any, id: any) {
  return axios.put(`${api}/monitoring/${id}`, data, { headers: headers })
}

//Category
export function createCategory(data: object) {
  return axios.post(`${api}/categories`, data, { headers: headers })
}
export function delCategory(id: any) {
  return axios.delete(`${api}/categories/${id}`, { headers: headers })
}
export function getCategoriesById(id: any) {
  return axios.get(`${api}/categories/${id}`, { headers: headers })
}
export function upCategories(id: any, data: object) {
  return axios.put(`${api}/categories/${id}`, data, { headers: headers })
}
export function cloneCategory(id: any, data: object) {
  return axios.post(`${api}/categories/${id}/clone`, data, { headers: headers })
}

//Conformity
export function createConformity(data: object) {
  return axios.post(`${api}/conformities`, data, { headers: headers })
}
export function delConformity(id: any) {
  return axios.delete(`${api}/conformities/${id}`, { headers: headers })
}
export function getConformityById(id: any) {
  return axios.get(`${api}/conformities/${id}`, { headers: headers })
}
export function upConformity(id: any, data: object) {
  return axios.put(`${api}/conformities/${id}`, data, { headers: headers })
}

//Modules
export function createModule(data: object) {
  return axios.post(`${api}/modules`, data, { headers: headers })
}
export function delModule(id: any) {
  return axios.delete(`${api}/modules/${id}`, { headers: headers })
}
export function getModuleById(id: any) {
  return axios.get(`${api}/modules/${id}`, { headers: headers })
}
export function upModule(id: any, data: object) {
  return axios.put(`${api}/modules/${id}`, data, { headers: headers })
}

export function changeEmail(data: any) {
  return axios.post(`${api}/reset-email`, data, { headers: headers })
}

// Tutorials
export function createAnswersTutorials(data: object) {
  return axios.post(`${api}/answerstutorial`, data, { headers: headers })
}
export function delAnswersTutorials(id: any) {
  return axios.delete(`${api}/answerstutorial/${id}`, { headers: headers })
}
export function getAnswersTutorialsById(data: any) {
  return axios.get(`${api}/answerstutorial/${data}`, { headers: headers })
}
export function updateAnswersTutorials(id: any, data: object) {
  return axios.put(`${api}/answerstutorial/${id}`, data, { headers: headers })
}


// Chapters
export function getModuleChapterById(data: any) {
  return axios.get(`${api}/chapters/${data}`, { headers: headers })
}
export function handleNextChapter(data: object) {
  return axios.post(`${api}/chapters/next`, data, { headers: headers })
}

export function getTutorialChapters(tutorial_id: any) {
  return axios.get(`${api}/tutorial/${tutorial_id}/chapters`, { headers: headers })
}
export function getModuleChapters(tutorial_id: any, chapter_id: any) {
  return axios.get(`${api}/tutorial/${tutorial_id}/chapter/${chapter_id}`, { headers: headers })
}
export function getChapterQuestion(chapter_id: any) {
  return axios.get(`${api}/tutorial/chapter/${chapter_id}/questions`, { headers: headers })
}

export function imprimirCertificado(data: object) {
  return axios.post(`${api}/certificado/tutorial`, data, { headers: headers, responseType: 'blob', })
}


//Tickets
export function createTicket(data: object) {
  return axios.post(`${api}/support/ticket/store`, data, { headers: headers })
}
export function showSupportTicket(code: any) {
  return axios.get(`${api}/support/ticket/${code}/show`, { headers: headers })
}
export function updateTicket(code: any, data: object) {
  return axios.put(`${api}/support/ticket/${code}/update`, data, { headers: headers })
}

// storeManagementHolderRequest
export function storeManagementHolderRequest(data: object) {
  return axios.post(`${api}/company/requeriment/store`, data, { headers: headers })
}

// verificar se pode enviar requerimento anonimo
export function verifyTypeRequest(uuid: any) {
  return axios.get(`${api}/company/requeriment/verify/${uuid}`, { headers: headers })
}

export function storePreferenceConsentPost(data: object) {
  return axios.post(`${api}/management/preference_consent/post`, data, { headers: headers })
}

export function preferenceConsentConfirmationCode(data: object) {
  return axios.post(`${api}/management/preference_consent/confirmation/code`, data, { headers: headers })
}


export function riskAnalysisModelStep(code: any, step: any) {
  return axios.get(`${api}/risk_analysis/${code}/analisy`, { headers: headers })
}

// export function riskAnalysisShow(code: string) {
//   return axios.get(`${api}/risk_analysis/analisy/${code}/show`, { headers: headers })
// }
export function riskAnalysisPost(data: object) {
  return axios.post(`${api}/risk_analysis/analisy/store`, data, { headers: headers })
}
export function riskAnalysisNewModel(data: object) {
  return axios.post(`${api}/risk_analysis/analisy/new`, data, { headers: headers })
}

export function riskGeneratePdf(code: any) {
  return axios.get(`${api}/risk_analysis/report/pdf/${code}`, { headers: headers, responseType: 'blob', })
}
export function riskSendPdf(data: any) {
  return axios.post(`${api}/risk_analysis/report/pdf/mail`, data, { headers: headers })
}

// export function defaultPaginationList(url: any, searchData: any) {
//   // console.log(searchData)
//   url = urlAppend(url, searchData)
//   // console.log(url);
//   return axios.get(url, { headers: headers })
// }
export function riskAnalysisIndex(data: any) {
  let url = urlAppend('risk_analysis/analisy/index', data);

  return axios.get(url, { headers: headers })
}
export function riskAnalysisUpdate(data: any) {
  return axios.post(`${api}/risk_analysis/analisy/update`, data, { headers: headers })
}

export function cloneModel(data: any) {
  return axios.post(`${api}/risk_analysis/analisy/clone`, data, { headers: headers })
}

export function removeModel(uuid: string) {
  return axios.delete(`${api}/risk_analysis/model/${uuid}/delete`, { headers: headers })
}


//gar model themes
export function getGarModelThemes(uuid: string) {
  return axios.get(`${api}/risk_analysis/analisy/${uuid}/model_themes`, { headers: headers })
}
export function getSendModels(data: any) {
  let url = urlAppend('risk_analysis/analisy/sends', data)
  return axios.get(url, { headers: headers })
}
export function AddThemes(data: object) {
  return axios.post(`${api}/risk_analysis/analisy/add_themes`, data, { headers: headers })
}
export function addStandard(data: object) {
  return axios.post(`${api}/risk_analysis/analisy/add_standard`, data, { headers: headers })
}

export function addRisk(data: object) {
  return axios.post(`${api}/risk_analysis/analisy/risk/add`, data, { headers: headers })
}
export function addAction(data: object) {
  return axios.post(`${api}/risk_analysis/analisy/action/add`, data, { headers: headers })
}
export function deleteThemes(data: object) {
  return axios.post(`${api}/risk_analysis/analisy/deleteThemes`, data, { headers: headers })
}
export function deleteStandard(data: object) {
  return axios.post(`${api}/risk_analysis/analisy/deleteStandards`, data, { headers: headers })
}
export function deleteRisk(data: object) {
  return axios.post(`${api}/risk_analysis/analisy/deleteRisk`, data, { headers: headers })
}
export function deleteActions(data: object) {
  return axios.post(`${api}/risk_analysis/analisy/action/delete`, data, { headers: headers })
}

export function getRiskAnalysis(code: string) {
  return axios.get(`${api}/risk_analysis/${code}/edit`, { headers: headers })
}
export function getGarSectors(uuid: string) {
  return axios.get(`${api}/risk_analysis/sector/${uuid}/index`, { headers: headers })
}


export function getGarStandards(data: any) {
  let url = urlAppend('risk_analysis/standards/get', data)
  return axios.get(url, { headers: headers })
}
export function getModelStandards(id: number) {
  return axios.get(`${api}/risk_analysis/${id}/standards/list`, { headers: headers })
}


export function getModelRisks(id: number) {
  return axios.get(`${api}/risk_analysis/${id}/risks/list`, { headers: headers })
}
export function getModelActions(id: number) {
  return axios.get(`${api}/risk_analysis/${id}/actions/list`, { headers: headers })
}

export function getGarRisks() {
  return axios.get(`${api}/risk_analysis/risks`, { headers: headers })
}
export function getGarActions() {
  return axios.get(`${api}/risk_analysis/actions`, { headers: headers })
}


export function getGarThemes(model: string, uuid: string) {
  return axios.get(`${api}/risk_analysis/themes/index?model=${model}&sector=${uuid}`, { headers: headers })
}


// CRUD THEMES
export function UpdateTheme(data: any) {
  return axios.post(`${api}/risk_analysis/theme/edit`, data, { headers: headers })
}
export function storeThemes(data: any) {
  return axios.post(`${api}/risk_analysis/theme/store`, data, { headers: headers })
}
export function DeleteTheme(data: any) {
  return axios.post(`${api}/risk_analysis/theme/delete`, data, { headers: headers })
}

// CRUD Action
export function UpdateAction(data: any) {
  return axios.post(`${api}/risk_analysis/action/edit`, data, { headers: headers })
}
export function storeAction(data: any) {
  return axios.post(`${api}/risk_analysis/action/store`, data, { headers: headers })
}
export function DeleteAction(data: any) {
  return axios.post(`${api}/risk_analysis/action/delete`, data, { headers: headers })
}
// CRUD Risk
export function UpdateRisk(data: any) {
  return axios.post(`${api}/risk_analysis/risk/edit`, data, { headers: headers })
}
export function storeRisk(data: any) {
  return axios.post(`${api}/risk_analysis/risk/store`, data, { headers: headers })
}
export function DeleteRisk(data: any) {
  return axios.post(`${api}/risk_analysis/risk/delete`, data, { headers: headers })
}


// CRUD Standard
export function UpdateStandard(data: any) {
  return axios.post(`${api}/risk_analysis/standard/edit`, data, { headers: headers })
}
export function storeStandard(data: any) {
  return axios.post(`${api}/risk_analysis/standard/store`, data, { headers: headers })
}
export function DeleteStandard(data: any) {
  return axios.post(`${api}/risk_analysis/standard/delete`, data, { headers: headers })
}

// CRUD Sector
export function DeleteSector(data: any) {
  return axios.post(`${api}/risk_analysis/sector/delete`, data, { headers: headers })
}
export function UpdateSector(data: any) {
  return axios.post(`${api}/risk_analysis/sector/edit`, data, { headers: headers })
}
export function storeSector(data: any) {
  return axios.post(`${api}/risk_analysis/sector/store`, data, { headers: headers })
}




export function UpdateRiskAnalysis(data: any) {
  return axios.post(`${api}/risk_analysis/analisy/update`, data, { headers: headers })
}

export function sendMailGar(data: any) {
  return axios.post(`${api}/risk_analysis/analisy/send/mail`, data, { headers: headers })
}

// export function getTickets(data: any){
//   return axios.get(`${api}/tickets/${data}`, {headers: headers})
// }
// export function upTickets (id:any, data: object){
//   return axios.put(`${api}/tickets/update/${id}`, data, {headers: headers})
// }

// storeHolderRequestToken
export function storeHolderRequestToken(data: object) {
  return axios.post(`${api}/company/holder_request/token/store`, data, { headers: headers })
}
export function storeQuestionsRequestToken(data: object) {
  return axios.post(`${api}/company/holder_request/token/store/questions`, data, { headers: headers })
}
// updateHolderRequestToken
export function updateHolderRequestToken(data: object) {
  return axios.put(`${api}/company/holder_request/token/update`, data, { headers: headers })
}





// PreferenceConsent
export function storePreferenceConsent(data: object) {
  return axios.post(`${api}/company/preference_consent`, data, { headers: headers })
}


export function storeConfigApiToken(data: object) {
  return axios.post(`${api}/company/api_token`, data, { headers: headers })
}

export function getPrefConsentDetails(data: any) {
  return axios.get(`${api}/management/preference_consent/answer/${data.uuid}`, { headers: headers })
}

export function getPrefConsentPdf(code: string) {
  return axios.get(`${api}/management/preference_consent/pdf/${code}`, { headers: headers, responseType: 'blob', })
}

// COMPROVANTE
export function sendMailPrefConsentChecking(data: any) {
  return axios.post(`${api}/management/preference_consent/answer/mailChecking`, data, { headers: headers })
}




export function setResponseHolderRequest(uuid: any, data: object) {
  return axios.put(`${api}/company/requeriment/holder_request/response/${uuid}`, data, { headers: headers })
}

//TreinamentoUsuarios
export function getTreinamentoUsuario(id: any) {
  return axios.get(`${api}/treinamentousuarios/${id}`, { headers: headers })
}

//CerticateUser
// export function getCertificate(data: any){
//   return axios.get(`${api}/certificate/${data}`, {headers: headers})
// }



export function getHoldeRequest(id: any) {
  return axios.get(`${api}/company/holder/request/${id}`, { headers: headers })
}

export function getCoupon(uuid: any) {
  return axios.get(`${api}/coupons/${uuid}/edit`, { headers: headers })
}


export function getPreferenceConsent(uuid: any) {
  return axios.get(`${api}/company/preference_consent/${uuid}/edit`, { headers: headers })
}


export function getPreferenceConsentByToken(data: any) {
  return axios.get(`${api}/management/preference_consent/${data['lang']}/${data['token']}/${data['email']}`, { headers: headers })
}

export function updatePreferenceConsent(uuid: any, data: object) {
  return axios.put(`${api}/company/preference_consent/${uuid}`, data, { headers: headers })
}


export function updateConfigApiToken(uuid: any, data: object) {
  return axios.put(`${api}/company/api_token/${uuid}/update`, data, { headers: headers })
}


export function renewConfigApiToken(uuid: any) {
  return axios.get(`${api}/company/api_token/${uuid}/renew`, { headers: headers })
}

export function updateCoupon(uuid: any, data: object) {
  return axios.put(`${api}/coupons/${uuid}`, data, { headers: headers })
}

export function getConfigApiToken(uuid: any) {
  return axios.get(`${api}/company/api_token/${uuid}/show`, { headers: headers })
}


export function sendMailPrefConsent(data: object) {
  return axios.post(`${api}/management/preference_consent/send/mail`, data, { headers: headers })
}


export function downCalculatorResult(id: any) {
  return axios.get(`${api}/prescription/answereds/pdf/${id}`, { headers: headers, responseType: 'blob', })
}


function urlAppend(url: string, data: any) {
  let urlAppend = '';
  if (data) {
    for (const [key, value] of Object.entries(data)) {
      urlAppend += urlAppend ? `&${key}=${value}` : `?${key}=${value}`
    }
  }
  return `${api}/${url + urlAppend}`
}
export function defaultPaginationList(url: any, searchData: any) {

  url = urlAppend(url, searchData)

  return axios.get(url, { headers: headers })
}










export async function actionEvidencePost(formData: any, isUploading: any, funcBack?: any) {

  $("#upload_bar_progress_1").val(0);
  $('#dialog_upload_span').text("");

  $('#dialog_upload_span').html(`Iniciando envio... Aguarde!`);

  isUploading(true);

  return axios.post(`${api}/action/evidences`, formData, {
    headers: headers,
    onUploadProgress: data => {
      $("#upload_bar_progress_1").val(Math.round((100 * data.loaded) / data.total));
      $('#dialog_upload_span').html(`O envio está em <b>${Math.round((100 * data.loaded) / data.total)}%</b> Aguarde...`);
    }
  }).then((res) => {

    if (res.data.status === 'success') {
      toast.success(res.data.message);

      $('#dialog_upload_span').html(`Envio Completo!`);
      funcBack();
    }
    if (res.data.status === 'error') {
      toast.error(res.data.message);
      $('#dialog_upload_span').html(res.data.message);
    }
  }).catch((error) => {

    console.log(error);

    // trataRespota('error', error);  
    // toast.error(error.response.data.message);

    let errorMessage = error.response.data.message ?
      error.response.data.message : (error.response.statusText ? error.response.statusText : 'Não foi possivel enviar!');

    // toast.error(`Houve um erro ao enviar o arquivo: ${title} foi concluido!`, { autoClose: 2000 });

    if (error.response.status === 422) {
      if (error.response.data.errors) {
        let errorValue = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0];
        toast.error(errorValue);
        $('#dialog_upload_span').html(errorValue);
      }
    } else {
      toast.error(errorMessage);
      $('#dialog_upload_span').html(errorMessage);
    }

  }).finally(() => {
    isUploading(false);
  });



};

export function griReportPreview(code: any) {
  return axios.get(`${api}/preview-gri-report`, { headers: headers })
}