import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../helpers';
import { defaultPaginationList, getPrefConsentDetails, sendMailPrefConsentChecking, getPrefConsentPdf } from '../../../../../app/modules/helpers/Api';
import { trataRespota, converteData, handleDownload } from '../../../../../app/modules/helpers/helpers'

import { Load } from '../../spinner/Spinner';
// import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import Warning from '../../warning/Warning'
// import * as Yup from 'yup'
import { toast } from 'react-toastify';
import ModalMessage from '../../modals/modalMessage';

let paginationData = {
  currentPage: 1,
  total: 0,
  from: 0,
  to: 0,
  lastPage: 0
}



let initialSearchValues = {
  title: '',
  code: '',
  page: 1
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 950,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IModalListPreferenceContentsProps {
  showModal: boolean;
  refUuid: any;
  moment: any;
  onClose: () => void;
}

const ModalListPreferenceContents: React.FC<IModalListPreferenceContentsProps> = ({ ...props }) => {

  const [prescriptions, setPrescriptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loadingMail, setLoadingMail] = useState(false);
  const intl = useIntl();
  const [typeFilter, setTypeFilter] = useState<any>();
  const [statusFilter, setStatusFilter] = useState<any>();
  const [searchValueFilter, setSearchValueFilter] = useState<any>();
  const [prefConsentDetails, setPrefConsentDetails] = useState<any>([]);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [item, setItem] = useState(0);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [lastPage, setLastPage] = useState(0);
 
  function handleViewdetails(item: any) {

    setPrefConsentDetails([]);
    setLoadingDetails(true);
    setViewDetails(true);
    setItem(item);
    let get = getPrefConsentDetails({ 'uuid': item.uuid });
    get.then((res) => {

      if (res.data.status === 'success') {
        setPrefConsentDetails(res.data.data);
      }
    }).catch((error) => {
      trataRespota('error', error);
    }).finally(() => {
      setLoadingDetails(false);
    });
   
  }

  function handleDownloadPdf() {
    let get = getPrefConsentPdf(prefConsentDetails.uuid)
    get.then((res) => {
      if (res.statusText === 'OK') {
        handleDownload(res, ('relatorio_preference_' + prefConsentDetails.owner_name), 'pdf');
        toast.success("Relatorio baixado com sucesso!")
      } else {
        alert('desculpe, um erro ocorreu!');
      }

    }).catch((error) => {
      if (error.response.status == 404) {

        let file = new Blob([error.response.data], { type: 'application/json' });

        file.text()
          .then(value => {
            let object = JSON.parse(value);
            alert(object.message);
          })
          .catch(error => {
            alert('Desculpe um erro ocorreu')
          });

      } else {
        alert('Indiponível no momento!')
      }
    }).finally(() => {
      // setPdfLoading(false)
    });
  }




  let searchData = {
    type: '',
    status: '',
    search_value: '',
    page: 1,
    perpage: 10
  }

  useEffect(() => {
    if (props.refUuid) {
      setViewDetails(false)
      getFormList()

    }
  }, [props.refUuid, props.moment]);

  function onClear() {
    paginationData.from = 0
    paginationData.to = 0
    paginationData.total = 0
    paginationData.lastPage = 0
    initialSearchValues.title = ''
    initialSearchValues.code = ''
    initialSearchValues.page = 1
  }

  function handleNextPrevPage(value: any) {
    if (value === 'prev' && initialSearchValues.page > 1) {
      initialSearchValues.page--;
      getFormList();
    }
    if (value === 'next' && initialSearchValues.page < lastPage) {
      initialSearchValues.page++;
      getFormList();
    }
  }
  function getFormList() {

    searchData.search_value = searchValueFilter ? searchValueFilter : '';
    searchData.status = statusFilter ? statusFilter : '';
    searchData.type = typeFilter ? typeFilter : '';
    setLoading(true);
    const get = defaultPaginationList(`company/preference_consent/${props.refUuid}/answers`, searchData)
    get.then((res) => {

      paginationData.from = res.data.data.from
      paginationData.to = res.data.data.to
      paginationData.total = res.data.data.total
      paginationData.lastPage = res.data.data.last_page

      setLastPage(res.data.data.last_page);
      setPrescriptions(res.data.data.data);
    }).catch((error) => alert(error))
      .finally(() => setLoading(false));
  }
  // function handleSetNewToken() {
  //   formik.resetForm()
  //   // setNewToken(true)
  // }
  function handleSendChecking(item: any) {
    setLoadingMail(true);
    setOpenModalConfirm(false);
    const send = sendMailPrefConsentChecking({ "uuid": item.uuid });

    send.then((res) => {
      if (res.data.status === 'success') {
        toast.success(res.data.message);
        let get = getPrefConsentDetails({ 'uuid': item.uuid });
        get.then((res) => {

          if (res.data.status === 'success') {
            setPrefConsentDetails(res.data.data);
          }
        }).catch((error) => {
          trataRespota('error', error);
        }).finally(() => {
          setLoadingDetails(false)
        });
      } else {
        toast.error(res.data.message);
      }
    })
      .catch((error) => {
        toast.error(error.response.message);
      })

      .finally(() => {
        setLoadingMail(false)

      });
  }

  return (
    <div>
      <ModalMessage
        message={intl.formatMessage({
          id: 'A receipt will be sent to the email address provided:'
        }) + ' ' + prefConsentDetails.owner_email + ', ' + intl.formatMessage({
          id: 'do you wish to continue'
        }) + '?'}
        title={intl.formatMessage({ id: 'Attention' })}
        showModal={openModalConfirm}
        onClose={() => setOpenModalConfirm(false)}
        onAction={() => handleSendChecking(item)}
      />
      <Modal
        open={props.showModal}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div className='px-5 d-flex flex-row justify-content-between align-items-center w-100'>

            <div className='d-flex fw-bold h-60px flex-row align-items-center border-1 border-bottom border-dark w-100'>
              <span className="modal-title fw-bold fs-3">{intl.formatMessage({ id: 'Management of acepts documents' })}

              </span>

            </div>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary"
              onClick={() => [props.onClose(), onClear()]}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          {
            viewDetails ?
              <div >
                {loadingDetails ?
                  <div className='p-8'>
                    <Load />
                  </div>
                  :
                  <div>
                    {prefConsentDetails && prefConsentDetails.uuid ?
                      <div className='p-8 scroll-y pe-5 h-350px'>
                        {/* <div className='row'>
                        <div className='col-12'>
                          <span className='text-gray-700 fw-bold fs-6' style={{ cursor: 'pointer' }}>
                            {
                              prefConsentDetails.status === 'active' && prefConsentDetails.mail_confirmed_at
                                ? <span className="badge badge-light-success" title={converteData(prefConsentDetails.mail_confirmed_at)}>{intl.formatMessage({ id: 'Confirmed' })}</span>
                                : prefConsentDetails.status === 'active' && !prefConsentDetails.mail_confirmed_at
                                  ? <span className="badge badge-light-info" title={'- - -'} >{intl.formatMessage({ id: 'Active' })}</span>
                                  : prefConsentDetails.status === 'old'
                                    ? <span className="badge badge-light-secondary" title={'- - -'}>{intl.formatMessage({ id: 'old' })}</span>
                                    : prefConsentDetails.status
                            }
                          </span>
                        </div>
                      </div> */}
                        <div className='row'>
                          <div className='col-12'>
                            <p>
                              <div dangerouslySetInnerHTML={{ __html: prefConsentDetails.message }} />
                            </p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-sm-6 col-md-4 col-xl-3'>
                            <label className='fw-bolder text-muted'>Respondido por:</label>
                            <p>{prefConsentDetails.owner_name} - {prefConsentDetails.owner_email}</p>
                          </div>
                          <div className='col-12 col-sm-6 col-md-4 col-xl-3'>
                            <label className='fw-bolder text-muted'>Resposta informada:</label>
                            <p>{prefConsentDetails.owner_response}</p>
                          </div>
                          <div className='col-12 col-sm-6 col-md-4 col-xl-3'>
                            <label className='fw-bolder text-muted'>Data da resposta:</label>
                            <p>{prefConsentDetails.created_at ? converteData(prefConsentDetails.created_at) : '- - -'}</p>
                          </div>
                          <div className='col-12 col-sm-6 col-md-4 col-xl-3'>
                            <label className='fw-bolder text-muted'>Resposta confirmada em:</label>
                            <p>{prefConsentDetails.mail_confirmed_at ? converteData(prefConsentDetails.mail_confirmed_at) : '- - -'}</p>
                          </div>
                          <div className='col-12 col-sm-6 col-md-4 col-xl-3'>
                            <label className='fw-bolder text-muted'>Token utilizado:</label>
                            <p>{prefConsentDetails.token_title ? prefConsentDetails.token_title : '- - -'}</p>
                          </div>
                          <div className='col-12 col-sm-6 col-md-4 col-xl-3'>
                            <label className='fw-bolder text-muted'>Código de confirmação:</label>
                            <p>{prefConsentDetails.mail_code ? prefConsentDetails.mail_code : '- - -'}</p>
                          </div>
                          <div className='col-12 col-sm-6 col-md-4 col-xl-3'>
                            <label className='fw-bolder text-muted'>Rementente:</label>
                            <p>{prefConsentDetails.user ? prefConsentDetails.user : '- - -'}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12'>
                            <label className='fw-bolder text-muted'>Url utilizada:</label>
                            <p>{prefConsentDetails.type == 'link' ? 'Link' : (prefConsentDetails.type == 'email' ? 'E-mail' : '- - -')} - {prefConsentDetails.url}</p>
                          </div>
                        </div>
                        <div className='row mt-12'>
                          <div className='col-12'>
                            <div className={`badge ${prefConsentDetails.mail_confirmed_at ? 'badge-light-success' : 'badge-light-primary'} w-100`}>
                              {
                                prefConsentDetails.mail_confirmed_at ?
                                  <h6>
                                    {intl.formatMessage({ id: 'The answer was confirmed at:' })} {prefConsentDetails.mail_confirmed_at ? converteData(prefConsentDetails.mail_confirmed_at) : null}
                                    <br />

                                  </h6>
                                  :
                                  <h6>{intl.formatMessage({ id: 'Waiting for answer...' })}</h6>
                              }


                            </div>
                          </div>
                        </div>
                        <br />
                        <div className={`badge ${prefConsentDetails.nota_send_at ? 'badge-light-success' : 'badge-light-primary'} w-100`}>
                          {
                            prefConsentDetails.nota_send_at ?

                              <h6 className='mt-2'>{intl.formatMessage({ id: 'The receipt was sent:' })}  {prefConsentDetails.nota_send_at ? converteData(prefConsentDetails.nota_send_at) : null}</h6>
                              :
                              <h6>{intl.formatMessage({ id: 'Checking not sent!' })}</h6>
                          }
                        </div>
                      </div>
                      :
                      <span>Vazio</span>
                    }
                    <div className='row'>

                      <div className='col-12 d-flex'>
                        <button
                          type='button'
                          className='m-2 p-2 btn btn-icon btn-bg-secondary bg-hover-secondary me-1'
                          disabled={loadingMail}
                          onClick={() => handleDownloadPdf()}
                        >
                          <KTSVG
                            path='/media/icons/duotune/files/fil021.svg'
                            className='svg-icon-2x svg-icon-info'
                          />
                        </button>

                        <button
                          type='button'
                          className='btn bg-primary m-2 w-100'
                          disabled={loadingMail}
                          onClick={() => setViewDetails(false)}
                        >
                          <span className='indicator-label'>{intl.formatMessage({ id: 'Close' })}</span>
                        </button>
                        {
                          prefConsentDetails.mail_confirmed_at ?
                            <button
                              type='button'
                              className='btn bg-primary m-2 w-100'
                              disabled={loadingMail}
                              onClick={() => setOpenModalConfirm(true)}
                            >
                              {!loadingMail && intl.formatMessage({ id: 'Send Details' })}
                              {loadingMail && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  Processando...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                            :
                            null
                        }

                      </div>
                    </div>
                  </div>
                }
              </div>
              // <div className='px-6 py-8'>
              //   <form
              //     className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              //     noValidate
              //     id='kt_login_signup_form'
              //     onSubmit={formik.handleSubmit}
              //   >
              //     <div className='fv-row mb-3'>
              //       <input
              //         disabled
              //         type='hidden'
              //         autoComplete='off'
              //         {...formik.getFieldProps('id')}
              //         className='form-control form-control-lg form-control-solid'
              //       />
              //     </div>
              //     {/* email */}
              //     <div className='fv-row mb-3'>
              //       <label className='form-label fw-bold text-dark fs-5 required'>{intl.formatMessage({ id: 'Title' })}</label>
              //       <input
              //         disabled={loading}
              //         placeholder={intl.formatMessage({ id: 'Title' })}
              //         type='title'
              //         autoComplete='off'
              //         {...formik.getFieldProps('title')}
              //         className={clsx(
              //           'form-control form-control-lg form-control-solid',
              //           { 'is-invalid': formik.touched.title && formik.errors.title },
              //           {
              //             'is-valid': formik.touched.title && !formik.errors.title,
              //           }
              //         )}
              //       />
              //       {formik.touched.title && formik.errors.title && (
              //         <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
              //           <div className='fv-help-block'>
              //             <span role='alert'>{formik.errors.title}</span>
              //           </div>
              //         </div>
              //       )}
              //     </div>
              //     {/* Description */}
              //     <div className='mb-2 fv-row' data-kt-password-meter='true'>
              //       <div className='mb-1'>
              //         <label className='form-label fw-bold text-dark fs-6 '>{intl.formatMessage({ id: 'Description' })}</label>
              //         <div className='position-relative mb-3'>
              //           <div className='d-flex justify-content-between w-100'>
              //             <textarea
              //               disabled={loading}
              //               placeholder={intl.formatMessage({ id: 'Description' })}
              //               autoComplete='off'
              //               {...formik.getFieldProps('description')}
              //               className={clsx(
              //                 'form-control form-control-lg form-control-solid w-100 me-1',
              //                 {
              //                   'is-invalid': formik.touched.description && formik.errors.description,
              //                 },
              //                 {
              //                   'is-valid': formik.touched.description && !formik.errors.description,
              //                 }
              //               )}
              //             />
              //           </div>
              //           {formik.touched.description && formik.errors.description && (
              //             <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
              //               <div className='fv-help-block'>
              //                 <span role='alert'>{formik.errors.description}</span>
              //               </div>
              //             </div>
              //           )}
              //         </div>
              //       </div>
              //     </div>
              //     {formik.getFieldProps('id').value ?
              //       <div>

              //         <div className='fv-row mb-3'>
              //           <label className='class="form-label fw-bold text-dark fs-5 mb-1'>{intl.formatMessage({ id: 'Status' })}</label>
              //           <select
              //             disabled={loading}
              //             className='form-select form-control form-control-solid fs-6 mt-2'
              //             {...formik.getFieldProps('status')}
              //           >
              //             <option value='active'>{intl.formatMessage({ id: 'Active' })}</option>
              //             <option value='inactive'>{intl.formatMessage({ id: 'Inactive' })}</option>
              //           </select>

              //         </div>

              //         <div className='fv-row mb-3'>
              //           <label className='form-label fw-bold text-dark fs-5 '>{intl.formatMessage({ id: 'Url' })}</label>
              //           <input
              //             readOnly
              //             placeholder={intl.formatMessage({ id: 'Url' })}
              //             type='text'
              //             title={`${intl.formatMessage({ id: 'Copy' })}`}
              //             onClick={() => copyText(formik.getFieldProps('url').value, intl)}
              //             autoComplete='off'
              //             {...formik.getFieldProps('url')}
              //             className='form-control form-control-lg form-control-solid'
              //           />
              //         </div>
              //       </div>
              //       :
              //       null
              //     }


              //     <div className='text-center row'>

              //       <div className='text-center col-12 mt-12 d-flex'>
              //         <button
              //           type='button'
              //           className='btn bg-danger m-2 w-100 text-white'
              //           //  disabled={!formik.values.acceptTerms}

              //           onClick={() => setViewDetails(false)}
              //         >
              //           <span className='indicator-label'>{intl.formatMessage({ id: 'Cancel' })}</span>
              //         </button>



              //         <button
              //           type='submit'
              //           className='btn bg-primary text-dark m-2 w-100'
              //         >
              //           {!loading && <span className='indicator-label'>
              //             {formik.getFieldProps('id').value ?
              //               intl.formatMessage({ id: 'Update' })
              //               :
              //               intl.formatMessage({ id: 'Save' })
              //             }</span>}
              //           {loading && (
              //             <span className='indicator-progress' style={{ display: 'block' }}>
              //               <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              //             </span>
              //           )}
              //         </button>
              //       </div>


              //     </div>
              //   </form>
              // </div>

              :

              <div>

                <div className='w-100 container'>
                  <div className='form'>
                    <div className=" row  py-6 d-flex " >

                      <div
                        className="col-md-3"
                      >

                        <input
                          type="text"
                          className="form-control form-control-solid fs-6 rounded-3"
                          placeholder={intl.formatMessage({ id: 'Search' })}
                          name="search_value"
                          autoComplete='off'
                          value={searchValueFilter}
                          onChange={(e) => [setSearchValueFilter(e.target.value)]}
                        />

                      </div>

                      <div
                        className='col-md-3'
                      >
                        <select className='form-select form-control form-control-solid fs-6'
                          name="Type"
                          value={typeFilter}
                          onChange={(e) => [setTypeFilter(e.target.value)]}
                        >
                          <option value='' >{intl.formatMessage({ id: 'All' })}</option>
                          <option value='link' >{intl.formatMessage({ id: 'Link' })}</option>
                          <option value='email'>{intl.formatMessage({ id: 'E-mail' })}</option>
                        </select>
                      </div>

                      <div
                        className='col-md-3'
                      >
                        <select className='form-select form-control form-control-solid fs-6'
                          name="status"
                          value={statusFilter}
                          onChange={(e) => [setStatusFilter(e.target.value)]}

                        >
                          <option value='' >{intl.formatMessage({ id: 'All' })}</option>
                          <option value='active' >{intl.formatMessage({ id: 'Active' })}</option>
                          <option value='confirmed' >{intl.formatMessage({ id: 'Confirmed' })}</option>
                          <option value='old'>{intl.formatMessage({ id: 'Old' })}</option>
                        </select>
                      </div>


                      <div className='col-12 col-sm-12 col-md-3 d-flex justify-content-end' >

                        <div className='row  d-flex'>

                          <div className='col-sm-12 d-flex'>

                            <button type='button' className='btn btn-primary text-dark ' disabled={loading} onClick={() => getFormList()}>
                              {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Search' })}</span>}
                              {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>

                          </div>

                        </div>



                      </div>



                    </div>
                  </div>
                </div>


                <div className='px-5 my-1 text-center' style={{ overflow: 'auto', maxHeight: 350 }}>
                  {
                    loading ?
                      <Load />
                      :
                      <>
                        {
                          prescriptions.length === 0 ?
                            <Warning message={intl.formatMessage({ id: 'No record found' })} />
                            :
                            <table className='table table-row-bordered table-row-gray-100 gs-0 gy-3'>
                              <thead>
                                <tr className='fw-bolder text-muted'>
                                  <th className='min-w-30px text-center'>{intl.formatMessage({ id: 'Actions' })}</th>
                                  <th className='min-w-60px'>{intl.formatMessage({ id: 'Type' })}</th>
                                  <th className='min-w-60px text-left'>{intl.formatMessage({ id: 'E-mail' })}</th>
                                  <th className='min-w-60px'>{intl.formatMessage({ id: 'Answer' })}</th>
                                  <th className='min-w-150px'>{intl.formatMessage({ id: 'Created at' })}</th>
                                  <th className='min-w-60px'>{intl.formatMessage({ id: 'Status' })}</th>
                                </tr>
                              </thead>
                              <tbody>

                                {prescriptions?.map((item: any, index: any) =>
                                  <tr key={index}>

                                    <td>
                                      <button
                                        className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                        title={`${intl.formatMessage({ id: 'Open' })}`}
                                        onClick={() => handleViewdetails(item)}
                                      > <i className="fas fa-eye text-info"></i>
                                      </button>

                                    </td>

                                    <td>
                                      <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                        {item.type == 'link' ? 'Link' : (item.type == 'email' ? 'E-mail' : '- - -')}
                                      </span>
                                    </td>

                                    <td>
                                      <span className='text-gray-700 fw-bold fs-6 text-left'>
                                        <div>{item.owner_email}</div>
                                        <div>{item.owner_name}</div>
                                      </span>
                                    </td>

                                    <td>
                                      <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                        {item.owner_response}
                                      </span>
                                    </td>

                                    <td>
                                      <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                        {item.created_at ? converteData(item.created_at) : '- - -'}
                                      </span>

                                    </td>

                                    <td>
                                      <span className='text-gray-700 fw-bold fs-6' style={{ cursor: 'pointer' }}>
                                        {
                                          item.status === 'active' && item.mail_confirmed_at
                                            ? <span className="badge badge-light-success" title={converteData(item.mail_confirmed_at)}>{intl.formatMessage({ id: 'Confirmed' })}</span>
                                            : item.status === 'active' && !item.mail_confirmed_at
                                              ? <span className="badge badge-light-info" title={'- - -'} >{intl.formatMessage({ id: 'Active' })}</span>
                                              : item.status === 'old'
                                                ? <span className="badge badge-light-secondary" title={'- - -'}>{intl.formatMessage({ id: 'old' })}</span>
                                                : item.status
                                        }
                                      </span>
                                    </td>

                                  </tr>
                                )}

                              </tbody>
                            </table>
                        }

                      </>
                  }
                </div>

                <div className='d-flex flex-stack flex-wrap p-5'>
                  <div className='fs-6 fw-bold text-gray-700'>{intl.formatMessage({ id: 'Showing' })} {paginationData.from} {intl.formatMessage({ id: 'to' })} {paginationData.to} {intl.formatMessage({ id: 'of' })} {paginationData.total} {intl.formatMessage({ id: 'entries' })}.</div>

                  <ul className='pagination'>
                    <li className={'page-item previous ' + (initialSearchValues.page == 1 ? 'disabled' : '')} onClick={() => handleNextPrevPage('prev')}>
                      <a href='#' className='page-link'>
                        <i className='previous' ></i>
                      </a>
                    </li>

                    <li className='page-item active'>
                      <a href='#' className='page-link bg-primary text-dark'>
                        {initialSearchValues.page}
                      </a>
                    </li>

                    <li className={'page-item next ' + (initialSearchValues.page >= paginationData.lastPage ? 'disabled' : '')} onClick={() => handleNextPrevPage('next')}>
                      <a href='#' className='page-link'>
                        <i className='next' ></i>
                      </a>
                    </li>

                  </ul>
                </div>

              </div>

          }

        </Box>
      </Modal>
    </div>
  )
}
export default ModalListPreferenceContents;