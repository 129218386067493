import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getUserById } from '../../../../../app/modules/helpers/Api';
import { KTSVG } from '../../../../helpers';
import { Load } from '../../spinner/Spinner';
import ProfileAddress from '../Users/cards/profileAddress';
import ProfileDetail from '../Users/cards/profileDetail';
import ProfileResume from '../Users/cards/profileResume';
import { useIntl } from 'react-intl'

type Props = {
	edition: (action: boolean) => void;
	data: any;
}

const ViewUserCompany: React.FC<Props> = ({...props}) => {

	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const intl = useIntl();   

	function reload(action: boolean){
		if(action){
			setLoading2(true);
		}
	}

  return(
    <>
			{
				loading?
				<Load/>
				:
				<>
					<div className='w-100 d-flex justify-content-end mt-3'>
						<button style={{width: '80px', height: '40px'}} onClick={()=>props.edition(true)} type='button' className='btn btn-sm btn-secondary '>
							{/* <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark'/> */}
							{intl.formatMessage({id: 'Back'})}
						</button>
					</div>
					<ProfileAddress reload={reload} data={props.data} />
					<ProfileResume reload={reload} data={props.data} />
					<ProfileDetail data={props.data} />
					{/* <DeleteProfile id={props.id} edition={props.edition}/> */}
				</>
			}
		</>
  )
}
export default ViewUserCompany;