/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

import {KTSVG} from '../../../../helpers'
import ModalDataMappingTriagem from './DataMapping/modalDataMappingTriagem';
import Warning from '../../warning/Warning';

type Props = {
  create: (action: boolean, data: any) => void;
}

const DataMappingList: React.FC<Props> = ({...props}) => {

  const [openModalDataMappingTriagem, setOpenModalDataMappingTriagem] = useState(false);

  return (
    <div className='card mb-5 mb-xl-10'>
      <ModalDataMappingTriagem
        title='New Data Mapping'
        onClose={()=>setOpenModalDataMappingTriagem(false)}
        showModal={openModalDataMappingTriagem}
        goToForm={props.create}
      />
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Data Mapping List</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
        >
          <button
            className='btn btn-sm btn-dark'
            onClick={()=>setOpenModalDataMappingTriagem(true)}
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Add Data Mapping
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          {/* <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>USER</th>
                <th className='min-w-140px'>COMPANY</th>
                <th className='min-w-140px'>SECTOR</th>
                <th className='min-w-120px'>DATA</th>
                <th className='min-w-100px text-center'>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <span role={'button'} onClick={()=>props.action(true, 1)} className='text-gray-800 fw-bold text-hover-primary fs-6'>
                        ---
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  ---
                </td>
                <td>
                  ---
                </td>
                <td>
                  ---
                </td>
                <td>
                  <div className='d-flex justify-content-center flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 svg-icon-primary' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3 svg-icon-danger'
                      />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table> */}
          <Warning message='There are no data mappings to show yet.' />
        </div>
      </div>
    </div>
  )
}

export {DataMappingList}
