/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
// import {Link} from 'react-router-dom'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { Languages } from './Languages'
import * as auth from "../../../../app/modules/auth/redux/AuthRedux";
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import moment from 'moment';

import { converteData2 } from '../../../../app/modules/helpers/helpers';

// Importar para realizar a tradução
import { useIntl } from 'react-intl'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  // criar instancia para realizar a tradução
  const intl = useIntl();

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {/* <img alt='Logo' src={user.pic} /> */}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.name} {user.last_name}

            </div>
            <span className='fw-bold text-muted fs-7'>
              {user.email}
            </span>

            {
              user.sector && user.sector.name ?
                <span className='fw-bold text-muted fs-7'>
                  {user.sector.name}
                </span>
                : null
            }

          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 mx-2'>{user.roles}</span>

      {
        user.trial ?
          <>
            <div className='separator my-2'></div>
            <span className={(moment(user.trial).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') ? 'badge-light-danger' : 'badge-light-info') + ' badge fw-bolder fs-8 px-2 py-1 mx-2'}>Trial: {converteData2(user.trial)}</span>
          </>
          : null
      }

      {
        !user.trial && user.package ?
          <>
            <div className='separator my-2'></div>
            <span className={(moment(user.package).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') ? 'badge-light-danger' : 'badge-light-success') + ' badge fw-bolder fs-8 px-2 py-1 mx-2'}>Plan: {converteData2(user.package)}</span>
          </>
          : null
      }

      <div className='separator my-2'></div>
      

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div> */}

      {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div> */}

      {/* <div className='separator my-2'></div> */}

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/pages/commum/profile' className='menu-link px-5'>
          {intl.formatMessage({ id: 'Profile' })}
        </Link>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/pages/commum/tickets' className='menu-link px-5'>
          {intl.formatMessage({ id: 'Tickets' })}
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {intl.formatMessage({ id: 'Sign Out' })}
        </a>
      </div>



    </div>
  )
}

export { HeaderUserMenu }
