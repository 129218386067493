/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { upPhoto, upUser } from '../../../../app/modules/helpers/Api'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import { toast } from 'react-toastify'
import { countries } from '../../../../app/modules/helpers/country'
import { mask} from '../../../../app/modules/helpers/helpers'
import clsx from 'clsx'
import InputMask from "react-input-mask"
import ModalMessageConfirm from '../modals/modalMessageConfirm'
import moment from 'moment';
import DatePicker from "react-datepicker";
import { useIntl } from 'react-intl'
import { trataRespota } from '../../../../app/modules/helpers/helpers'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { ChangePass, DeleteProfile } from '../../../../_metronic/partials/widgets'
import { KTSVG } from '../../../helpers';

const maxDate = moment().subtract(18, 'years').toDate()

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 950,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

const EditionProfile: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  
  const intl = useIntl();   
  const [loading, setLoading] = useState(false);
  // const [startDate, setStartDate] = useState(user.birthday ? new Date(moment(user.birthday).format('MM-DD-YYYY')) : new Date(moment(maxDate).format('MM-DD-YYYY')));
  const [startDate, setStartDate] = useState(new Date(moment(maxDate).format('MM-DD-YYYY')))
  const [errorDateStart, setErrorDateStart] = useState('');
  const [state, setState] = useState <any> ();
  const [city, setCity] = useState <any> ();
  const [bairro, setBairro] = useState <any> ();
  const [street, setStreet] = useState <any> ();
  const [complement, setComplement] = useState <any> ();

  const [openModalChangePass, setOpenModalChangePass] = useState(false);

  const [openModalDeleteProfile, setOpenModalDeleteProfile] = useState(false);

  const [postalcode, setPostalcode] = useState(user.post_code ? user.post_code : '');
  const [cepError, setCepError] = useState(false);

  const schema = Yup.object().shape({
    name: Yup.string().required(`${intl.formatMessage({id: 'Name'})} ${intl.formatMessage({id: 'is required'})}`),
    last_name: Yup.string().required(`${intl.formatMessage({id: 'Last Name'})} ${intl.formatMessage({id: 'is required'})}`),
    email: Yup.string().required(`${intl.formatMessage({id: 'E-mail'})} ${intl.formatMessage({id: 'is required'})}`),
  })
  const[cepData, setCep] = useState<any>({
    city:       user.city       || undefined,
    state:      user.state      || undefined,
    district:   user.district   || undefined,
    street:     user.street     || undefined,
    complement: user.complement || undefined,
	});
  useEffect(() => {
    setState(cepData.state)
    setCity(cepData.city)
    setBairro(cepData.district)
    setStreet(cepData.street)
    setComplement(cepData.complement)
  }, [cepData])
  
  const initialValues = {
    email: user.email,
    name: user.name,
    last_name: user.last_name,
    birthday: user.birthday ? moment(user.birthday).format('DD-MM-YYYY') : '',
    photo_url: user.photo_url || undefined,
    prefix: user.prefix || undefined,
    phone: user.phone || undefined,
    city: user.city || undefined,
    state: user.state || undefined,
    district: user.district || undefined,
    street: user.street || undefined,
    post_code: user.post_code || undefined,
    country: user.country || undefined,
    complement: user.complement || undefined,
    number: user.number || undefined,
  };

  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const [value, setValue] = useState<any>({});
  
  function save(value: any){

    let birthday = null;
    if (value.birthday ) {
      birthday = moment(value.birthday, 'DDMMYYYY').format('YYYY-MM-DD');
    }

    const data = {
      email: value.email,
      name: value.name,
      last_name: value.last_name,
      birthday: birthday,
      photo_url: value.photo_url,
      document: document,
      prefix: value.prefix,
      phone: value.phone,
      post_code: postalcode,
      country: value.country,
      number: value.number,
      city: city,
      state: state,
      district: bairro,
      street: street,
      complement: value.complement,
    }

    const save = upUser(data);
    setLoading(true);
    save.then((res)=>{
      if(res.data.status === 'success'){
    
        toast.success(`${intl.formatMessage({id: 'Done, reloading...Please wait'})} `);
					
        setTimeout(()=>{
          const temp: any = window;
          temp.location.reload();
        }, 3000); 

      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      trataRespota('error', error); 
    }).finally(()=> {
      setLoading(false);
      setOpenModalSubmit(false);
    });


  }

  const [document, setDocument] = useState(user.document || '');
  const [errorDocument, setErrorDocument] = useState('');

  function addMask(e: any){
    setDocument(mask(e));
  }

  function handleChangeCep(event: any) {

    const value = event.target.value;
    const name = event.target.name;           

    setPostalcode(value)

    if(value.length < 8) {
      return;
    } else {  
 
      toast.warning(`${intl.formatMessage({id: 'Seeking'})}`);
      setCepError(false)

      setTimeout(()=>{

        fetch(`https://viacep.com.br/ws/${value}/json/`).then((res) => res.json()).then((data) => {
          if (data.hasOwnProperty("erro")) {
              // this.setState({data: dataInit});
              toast.error(`${intl.formatMessage({id: 'Zip code not existent'})}`);
              setCepError(true)
          } else {
  
            toast.success(`${intl.formatMessage({id: 'Found'})}`);

            setCep({
              city:       data.localidade,
              state:      data.uf,
              district:   data.bairro,
              street:     data.logradouro,
              complement: data.complemento,
            });
             
          }
        }).catch(err => console.log(err));

      }, 2000);

    }

  }


  return (
    <div className='card mb-5 mb-xl-10'>
      <ModalMessageConfirm
        message={`${intl.formatMessage({id: 'Do you want to update the information? the system will be reloaded automatically!'})}`}
        title={`${intl.formatMessage({id: 'Attention'})}`}
        showModal={openModalSubmit}
        onClose={()=>setOpenModalSubmit(false)}
        onAction={()=> save(value)}
      />
      <div className='collapse show'>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => {

              var error = false;
           
                if(document === ''){setErrorDocument(`${intl.formatMessage({id: 'Document'})} ${intl.formatMessage({id: 'is required'})}`); error = true}
                if(!error){setValue(values); setOpenModalSubmit(true)}
             
            }}
          >
            {
            props => (
              <form onSubmit={props.handleSubmit} noValidate className='form' encType="multipart/form-data">


                <div className='card-body border-top px-5 py-5'>
                  <div className='mx-4 align-items-center'>
                    <div className='col-lg-12 border-dashed border-2 rounded p-3 mt-5'>
                      <label className='col-lg-4 col-form-label fw-bolder fs-3'>{intl.formatMessage({id: 'User Details'})}</label>
                      <div className='row'>
                        <div className='col-lg-4 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Name'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'Name'})}`}
                            name='name'
                            onChange={props.handleChange}
                            value={props.values.name}
                          />
                          {props.errors.name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fs-5'>{props.errors.name}</div>
                            </div>
                          )}
                        </div>
                        <div className='col-lg-4 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold required fs-5'>{intl.formatMessage({id: 'Last Name'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'Last Name'})}`}
                            name='last_name'
                            onChange={props.handleChange}
                            value={props.values.last_name}
                          />
                          {props.errors.last_name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fs-5'>{props.errors.last_name}</div>
                            </div>
                          )}
                        </div>
                        
                        
                        <div className='col-lg-4 fv-row mb-3'>
                          <label className='col-lg-6 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Birthday'})}</label>
                          <div className='d-flex justify-content-between w-100'>

                            <InputMask
                              mask="99/99/9999"
                              maskChar={null}
                              type="text"
                              className="form-control form-control-solid fs-6"
                              placeholder="DD/MM/YYYY"
                              name='birthday'
                              onChange={props.handleChange}
                              value={props.values.birthday}
                            />
                            
                            {/* <DatePicker 
                              selected={startDate} 
                              maxDate={maxDate}
                              dateFormat={'dd/MM/yyyy'}
                              onChange={(date:Date) => [setStartDate(date), setErrorDateStart('')]} 
                              className='form-control form-control-md form-control-solid fs-4'
                            /> */}

                          </div>
                        </div>

                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Phone'})}</label>
                          <div className='col-lg-12 d-flex flex-row'>
                            <div className='col-lg-2 fv-row mb-3 me-2'>
                              {/* <input
                                type="text"
                                className="form-control form-control-solid fs-6 text-center"
                                placeholder="99"
                                maxLength={2}
                                name='prefix'
                                onChange={props.handleChange}
                                value={props.values.prefix}
                              /> */}
                              <InputMask
                                mask="(999)"
                                maskChar={null}
                                type="text"                                
                                className="form-control form-control-solid fs-6"
                                placeholder="(031)"
                                name='prefix'
                                onChange={props.handleChange}
                                value={props.values.prefix}
                              />
                            </div>
                            <div className='col-lg-6 col-md-4 fv-row mb-3'>
                              <InputMask
                                mask="9 9999-9999"
                                maskChar={null}
                                type="text"
                                className="form-control form-control-solid fs-6"
                                placeholder="x xxxx-xxxx"
                                name='phone'
                                onChange={props.handleChange}
                                value={props.values.phone}
                              />
                            </div>
                          </div>
                        </div>

                        {/* <div className='col-md-12'>
                        <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Photo'})} URL</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="https://www.google.com/url?sa=i&url=https%3A%2F%2Fbr.freepik.com%2Ffotos%2Farvore&psig=AOvVaw2x109pB2Z7laBRELiFMkiA&ust=1667923729228000&source=images&cd=vfe&ved=0CA0QjRxqFwoTCMjLnJ26nPsCFQAAAAAdAAAAABAE"
                            name='photo_url'
                            onChange={props.handleChange}
                            value={props.values.photo_url}
                          />
                        </div> */}
                      </div>
                    </div>

                    <div className='col-lg-12 border-dashed border-2 rounded p-3 mt-5'>
                      <label className='col-lg-4 col-form-label fw-bolder required fs-3'>{intl.formatMessage({id: 'Document'})}</label>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          {/* <label className='col-lg-6 col-form-label required fw-bold fs-5'>Document</label> */}
                          <input
                            placeholder='CNPJ/CPF'
                            type='text'
                            autoComplete='off'
                            name='document'
                            maxLength={18}
                            onChange={e=>[addMask(e.currentTarget.value), setErrorDocument('')]}                
                            value={document}
                            className={clsx(
                              'form-control form-control-solid fs-5',
                              {'is-invalid': errorDocument || ![14,18].includes(document.length)},
                              {
                                'is-valid': [14,18].includes(document.length),
                              }
                            )}
                          />
                          {errorDocument && (
                            <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errorDocument}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-12 border-dashed border-2 rounded p-3 mt-5'>
                      <label className='col-lg-4 col-form-label fw-bolder fs-3'>{intl.formatMessage({id: 'Address Details'})}</label>
                      <div className='row'>

                        <div className='col-lg-12 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Postal Code'})}</label>
                          <input
                            type="text"
                            // className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'Postal Code'})}`}
                            name='post_code'
                            onChange={handleChangeCep}
                            autoComplete='off'
                            maxLength={8}
                            value={postalcode} 
                            // value={props.values.post_code}
                            className={clsx(
                              'form-control form-control-solid fs-5',
                              {'is-invalid': cepError|| ![8].includes(postalcode.length)},
                              {
                                'is-valid': [8].includes(postalcode.length),
                              }
                            )}
                          />

{/*   */}

                          {/* <input

                                           
                 
                            className={clsx(
                              'form-control form-control-solid fs-5',
                              {'is-invalid': errorDocument || ![14,18].includes(document.length)},
                              {
                                'is-valid': [14,18].includes(document.length),
                              }
                            )}
                          />
                          {errorDocument && (
                            <div className='fv-plugins-message-container text-danger fs-6 mt-1'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errorDocument}</span>
                              </div>
                            </div>
                          )} */}

                        </div>

                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'State'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'State'})}`}
                            name='state'
                            onChange={(e)=>[setState(e.target.value), props.handleChange]}
                            value={state}
                            
                          />
                        </div>

                        <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'City'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'City'})}`}
                            name='city'
                            onChange={(e)=>[setCity(e.target.value), props.handleChange]}
                            value={city}
                            
                          />
                        </div>

                        <div className='col-lg-4 fv-row mb-3'>
                          <label className='col-lg-12 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Neighborhood'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'Neighborhood'})}`}
                            name='district'
                            onChange={(e)=>[setBairro(e.target.value), props.handleChange]}
                            value={bairro}
                          />
                        </div>

                        <div className='col-lg-4 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Street'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder="Street"
                            name='street'
                            onChange={(e)=>[setStreet(e.target.value), props.handleChange]}
                            value={street}
                          />
                        </div>
                       
                        {/* <div className='col-lg-6 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>Country</label>
                          <select className='form-select form-control form-control-solid fs-6' 
                            name='country'
                            onChange={props.handleChange}
                            value={cepData.country}
                          >
                            
                            {
                              countries.map((item: any)=>
                                <option key={item.name} value={item.code}>{item.name}</option>
                              )
                            }
                          </select>
                        </div> */}

                        <div className='col-lg-4 fv-row mb-3'>
                          <label className='col-lg-5 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Complement'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'Complement'})}`}
                            name='complement'
                            onChange={props.handleChange}
                            value={props.values.complement}
                          />
                        </div>

                        <div className='col-lg-4 fv-row mb-3'>
                          <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Number'})}</label>
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'Number'})}`}
                            name='number'
                            onChange={props.handleChange}
                            value={props.values.number}
                          />
                        </div>
                      </div>

                        

                    </div> 

                    <div className='col-lg-12 border-dashed border-2 rounded p-3 mt-5'>
                      <label className='col-lg-4 col-form-label required fw-bolder fs-3'>{intl.formatMessage({id: 'E-mail'})}</label>
                      <div className='row'>
                        <div className='col-lg-12 fv-row mb-3'>
                          {/* <label className='col-lg-4 col-form-label fw-bold fs-5'>E-mail</label> */}
                          <input
                            type="text"
                            className="form-control form-control-solid fs-6"
                            placeholder={`${intl.formatMessage({id: 'E-mail'})}`}
                            name='email'
                            onChange={props.handleChange}
                            value={props.values.email}
                          />
                          {props.errors.email && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fs-5'>{props.errors.email}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-12 border-dashed border-2 rounded p-3 mt-5'>
                      <label className='fw-bolder fs-3'>{intl.formatMessage({id: 'Password'})}</label>
                      <div className='mt-5 px-4 mb-4 cursor-pointer text-hover-primary'       onClick={()=>[setOpenModalChangePass(true)]}>Alterar Senha</div>
                    </div>

                    <div className='col-lg-12 border-dashed border-2 rounded p-3 mt-5'>
                      <label className='fw-bolder fs-3'>{intl.formatMessage({id: 'Conta'})}</label>
                      <DeleteProfile />        
                    </div>

                  </div>
                </div>

               
                

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button type='submit' className='btn btn-primary text-dark' disabled={loading}>
                    {!loading && intl.formatMessage({id: 'Save'})}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                       {intl.formatMessage({id: 'Processing'})}...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>

              </form>
              
              
              )
            }
          </Formik>
          
      </div>

      <Modal open={openModalChangePass} onClose={()=>{setOpenModalChangePass(false)}} >

        <Box sx={style}>
          <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
            <span className="modal-title fw-bold fs-3 ">Alterar Senha</span>
            <div className="btn btn-icon btn-sm btn-active-light-primary " onClick={()=>[setOpenModalChangePass(false)]} >
              <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
            </div>
          </div>

          <ChangePass/>

        </Box>
      </Modal>

      

      {/* <DeleteProfile/> */}

    </div>
  )
}

export {EditionProfile}
