import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import { createFormsList } from '../../../../../app/modules/helpers/Api';
import ModalMessageConfirm from '../../modals/modalMessageConfirm';
import { trataRespota } from '../../../../../app/modules/helpers/helpers'
import { KTSVG } from '../../../../helpers';
import { useIntl } from 'react-intl'
import {UserModel} from '../../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'

type Props = {
	create: (action: boolean) => void;
	idModulo: any;
}

const NewForm: React.FC<Props> = ({ ...props }) => {
	const intl = useIntl();
	const [loading, setLoading] = useState(false);
	const [openModalSubmit, setOpenModalSubmit] = useState(false);
	const [dataValues, setDataValues] = useState<any>({});
	const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel


	const schema = Yup.object().shape({
		name: Yup.string()
			.required(`${intl.formatMessage({ id: 'Name' })} ${intl.formatMessage({ id: 'is required' })}`),
		score: Yup.string()
			.required(`${intl.formatMessage({ id: 'Score' })} ${intl.formatMessage({ id: 'is required' })}`),
		status: Yup.string()
			.required(`${intl.formatMessage({ id: 'Status' })} ${intl.formatMessage({ id: 'is required' })}`),
		phase: Yup.string()
			.required(`${intl.formatMessage({ id: 'Phase' })} ${intl.formatMessage({ id: 'is required' })}`),
	})

	const initialValues = {
		name: '',
		score: '',
		status: '',
		phase: '',
		final_sentence: '',
		next_form: ''
	}

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: schema,
		onSubmit: (values) => {
			setLoading(true);
			setTimeout(() => {
				setLoading(false);
				setDataValues(values);
				setOpenModalSubmit(true);
			}, 1000)
		},
	})

	function save(values: any) {
		const data = {
			name: values.name,
			conformity_id: props.idModulo,
			model: parseInt(values.score),
			status: parseInt(values.status),
			phase: parseInt(values.phase),
			next_form: values.next_form,
			final_sentence: values.final_sentence,
			is_public: values.is_public
		}
		const save = createFormsList(data);
		save.then((res) => {
			if (res.data.status === 'success') {
				toast.success(res.data.message);
				setOpenModalSubmit(false);
				props.create(false);
			}
			if (res.data.status === 'error') {
				toast.error(res.data.message);
			}
		}).catch((error) => {
			trataRespota('error', error);

		})
	}

	return (
		<div className='card mb-5 mb-xl-10'>
			<ModalMessageConfirm
				message={intl.formatMessage({ id: 'Are you sure you want to create this item?' })}
				title={intl.formatMessage({ id: 'Attention' })}
				showModal={openModalSubmit}
				onClose={() => setOpenModalSubmit(false)}
				onAction={() => save(dataValues)}
			/>
			<div className='collapse show'>
				<form onSubmit={formik.handleSubmit} noValidate className='form'>
					<div className='card-body border-top px-5 py-5'>
						<div className='d-flex justify-content-between align-items-center mb-3'>
							<label className='form-label fw-bolder text-dark fs-3 '>{intl.formatMessage({ id: 'New Form' })}</label>
							<button onClick={() => props.create(false)} type='button' className='btn btn-sm btn-secondary p-3'>
								<KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark' />
								{intl.formatMessage({ id: 'Back' })}
							</button>

						</div>
						<div className='mb-2 fv-row border-top pt-3' data-kt-password-meter='true'>
							<div className='col-lg-12'>
								<div className='row'>
									<div className='col-lg-6 fv-row mb-3'>
										<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'Name' })}</label>
										<div className='position-relative mb-3'>
											<div className='d-flex justify-content-between w-100'>
												<input
													type='text'
													placeholder={intl.formatMessage({ id: 'Name' })}
													autoComplete='off'
													{...formik.getFieldProps('name')}
													className='form-control form-control-lg form-control-solid w-100 me-1'
												/>
											</div>
											{formik.touched.name && formik.errors.name && (
												<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
													<div className='fv-help-block'>
														<span role='alert'>{formik.errors.name}</span>
													</div>
												</div>
											)}
										</div>
									</div>
									<div className='col-lg-6 fv-row mb-3'>
										<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'Score' })}</label>
										<div className='position-relative mb-3'>
											<div className='d-flex justify-content-between w-100'>
												<select className='form-select form-control form-control-solid fs-6'
													{...formik.getFieldProps('score')}
												>
													<option value='' disabled>{intl.formatMessage({ id: 'Select an option' })}</option>
													<option value='1'>{intl.formatMessage({ id: 'Maturity analysis' })}</option>
													<option value='2'>{intl.formatMessage({ id: 'Scored' })}</option>
													<option value='3'>{intl.formatMessage({ id: 'Not scored' })}</option>
													<option value='4'>{intl.formatMessage({ id: 'Data Mapping' })}</option>
												</select>
											</div>
											{formik.touched.score && formik.errors.score && (
												<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
													<div className='fv-help-block'>
														<span role='alert'>{formik.errors.score}</span>
													</div>
												</div>
											)}
										</div>
									</div>
									<div className='col-lg-6 fv-row mb-3'>
										<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'Status' })}</label>
										<div className='position-relative mb-3'>
											<div className='d-flex justify-content-between w-100'>
												<select className='form-select form-control form-control-solid fs-6'
													{...formik.getFieldProps('status')}
												>
													<option value='' disabled>{intl.formatMessage({ id: 'Select an option' })}</option>
													<option value='2'>{intl.formatMessage({ id: 'Test' })}</option>
													<option value='1'>{intl.formatMessage({ id: 'Active' })}</option>
													<option value='0'>{intl.formatMessage({ id: 'Inactive' })}</option>
												</select>
											</div>
											{formik.touched.status && formik.errors.status && (
												<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
													<div className='fv-help-block'>
														<span role='alert'>{formik.errors.status}</span>
													</div>
												</div>
											)}
										</div>
									</div>
									<div className='col-lg-6 fv-row mb-3'>
										<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({ id: 'Phase' })}</label>
										<div className='position-relative mb-3'>
											<div className='d-flex justify-content-between w-100'>
												<select className='form-select form-control form-control-solid fs-6'
													{...formik.getFieldProps('phase')}
												>
													<option value='' disabled>{intl.formatMessage({ id: 'Select an option' })}</option>
													<option value='1'>1° {intl.formatMessage({ id: 'Phase' })}</option>
													<option value='2'>2° {intl.formatMessage({ id: 'Phase' })}</option>
													<option value='3'>3° {intl.formatMessage({ id: 'Phase' })}</option>
													<option value='4'>4° {intl.formatMessage({ id: 'Phase' })}</option>
												</select>
											</div>
											{formik.touched.phase && formik.errors.phase && (
												<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
													<div className='fv-help-block'>
														<span role='alert'>{formik.errors.phase}</span>
													</div>
												</div>
											)}
										</div>
									</div>
									<div className='col-md-12 fv-row mb-3'>
										<label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Final sentence' })}</label>
										<div className='position-relative mb-3'>
											<div className='d-flex justify-content-between w-100'>
												<input
													type='text'
													placeholder={intl.formatMessage({ id: 'Final sentence' })}
													disabled={formik.values.score === '1' ? true : false}
													autoComplete='off'
													{...formik.getFieldProps('final_sentence')}
													className='form-control form-control-lg form-control-solid w-100 me-1'
												/>
											</div>
											{formik.touched.final_sentence && formik.errors.final_sentence && (
												<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
													<div className='fv-help-block'>
														<span role='alert'>{formik.errors.final_sentence}</span>
													</div>
												</div>
											)}
										</div>
									</div>
									<div className='col-md-12 fv-row mb-3'>
										<label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({ id: 'Next Form' })}</label>
										<div className='position-relative mb-3'>
											<div className='d-flex justify-content-between w-100'>
												<input
													className='form-input form-control form-control-solid fs-6'
													type="text"
													placeholder={`2° ${intl.formatMessage({ id: 'Phase' })}`}
													{...formik.getFieldProps('next_form')}
												/>

											</div>
											{/* {formik.touched.phase && formik.errors.phase && (
												<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
													<div className='fv-help-block'>
														<span role='alert'>{formik.errors.phase}</span>
														<select className='form-select form-control form-control-solid fs-6 text-gray-400' 
													disabled
													// {...formik.getFieldProps('phase')}
												>
													<option value='' disabled>Select an option</option>
													<option value='2'>2° Phase</option>
													<option value='3'>3° Phase</option>
													<option value='4'>4° Phase</option>
												</select>
													</div>
												</div>
											)} */}
										</div>
									</div>
									
									{ ['super-admin'].includes(user.roles) ?
									<div className='col-md-12 fv-row mb-3'>
										<label className='col-lg-4 form-label fw-bold text-dark fs-6'>Public</label>
										<div className='position-relative mb-3'>
											<div className='d-flex justify-content-between w-100'>
												<select className='form-select form-control form-control-solid fs-6'
													{...formik.getFieldProps('is_public')}
												>
													<option value='' disabled>{intl.formatMessage({ id: 'Select' })}</option>
													<option value='0'>{intl.formatMessage({ id: 'No' })}</option>
													<option value='1'>{intl.formatMessage({ id: 'Yes' })}</option>
												</select>


											</div>
										</div>
									</div>
									:
									null
										}

								</div>
							</div>
						</div>
					</div>
					<div className='card-footer d-flex justify-content-end py-6 px-9'>
						<button type='submit' className='btn btn-primary text-dark' disabled={loading}>
							{!loading && intl.formatMessage({ id: 'Save' })}
							{loading && (
								<span className='indicator-progress' style={{ display: 'block' }}>
									{intl.formatMessage({ id: 'Processing' })}...{' '}
									<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
								</span>
							)}
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}
export default NewForm;