import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import { getQuestionById, questionEdit } from '../../../../../app/modules/helpers/Api';
import ModalMessageConfirm from '../../modals/modalMessageConfirm';
import { Load } from '../../spinner/Spinner';
import { trataRespota } from '../../../../../app/modules/helpers/helpers';
import { KTSVG } from '../../../../helpers';
import { useIntl } from 'react-intl'

type Props = {
	edition: (action: boolean) => void; 
	id: any;
}


const EditionQuestion: React.FC<Props> = ({...val}) => {

	const [data, setData] = useState<any>({});
	const [data2, setData2] = useState<any>([]);
	const [values, setValues] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const[openModalSubmit, setOpenModalSubmit] = useState(false);
	const intl = useIntl();   

	const schema = Yup.object().shape({
		title: Yup.string()
		.required(`${intl.formatMessage({id: 'Title'})} ${intl.formatMessage({id: 'is required'})}`),
	
		model: Yup.string()
		.required(`${intl.formatMessage({id: 'Model'})} ${intl.formatMessage({id: 'is required'})}`),
	  })
	useEffect(()=>{
		setLoading2(true);
		
		const get = getQuestionById(val.id);
		get.then((res)=>{
			setData(res.data.data.question);
			setData2(res.data.data.categories);
		}).catch((error)=>{
			toast.error(error.response.message);
		}).finally(()=>setLoading2(false));

	},[val.id])

	const initialValues = {
		id: data.id,
    title: data.title,
		model: data.model,
		category: data.category_id
  }	

	function save(values: any) {
		const data = {
			id: values.id,
			title: values.title,
			model: parseInt(values.model),
			category_id: parseInt(values.category),
		}
		const save = questionEdit(val.id, data);
		save.then((res)=>{
			if(res.data.status === 'success'){
				toast.success(res.data.message);
				setOpenModalSubmit(false);
				val.edition(false);
			}
			if(res.data.status === 'error'){
				toast.error(res.data.message);
			}
		}).catch((error)=>{
			trataRespota('error', error); 

			// if(error.reponse){
			// 	toast.error(error.response.message)
			// } else {
			// 	toast.error('Sorry, an error occurred!');	
			// }
		})
	}

  return(
    <>
		<ModalMessageConfirm
        message={intl.formatMessage({id: 'Are you sure you want to edition this item?'})}
        title={intl.formatMessage({id: 'Attention'})}
        showModal={openModalSubmit}
        onClose={()=>setOpenModalSubmit(false)}
        onAction={()=>save(values)}
      />
			{
				loading2?
				<Load/>
				:
				<div className='card mb-5 mb-xl-10'>
					<div className='collapse show'>
					<Formik
						initialValues={initialValues}
						validationSchema={schema}
						onSubmit={(values) => {
							setLoading(true);
				
								setLoading(false);
								setValues(values);
								setOpenModalSubmit(true)
				
						}}
						>
						{props => (
							<form onSubmit={props.handleSubmit} noValidate className='form'>
								<div className='card-body border-top px-5 py-5'>
									<div className='d-flex justify-content-between align-items-center mb-3'>
										<label className='form-label fw-bolder text-dark fs-3 '>{intl.formatMessage({id: 'Edit Question'})}</label>
										<button onClick={()=>val.edition(false)} type='button' className='btn btn-sm btn-secondary p-3'>
											<KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-dark'/>
											{intl.formatMessage({id: 'Back'})}
										</button>
										
									</div>
									<div className='mb-2 fv-row border-top pt-3' data-kt-password-meter='true'>
										<div className='col-lg-12'>
											<div className='row'>
												<div className='col-lg-12 fv-row mb-3'>
													<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Title'})}</label>
													<div className='position-relative mb-3'>
														<div className='d-flex justify-content-between w-100'>
															<textarea
																placeholder={intl.formatMessage({id: 'Title'})}
																autoComplete='off'
																rows={3}
																name='title'
																onChange={props.handleChange}
                                								value={props.values.title}
																className='form-control form-control-lg form-control-solid w-100 me-1'
															/>
														</div>
														{props.touched.title && props.errors.title && (
															<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
																<div className='fv-help-block'>
																	<span role='alert'>{props.errors.title}</span>
																</div>
															</div>
														)}
													</div>
												</div>
												<div className='col-lg-6 fv-row mb-3'>
													<label className='col-lg-4 form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Model'})}</label>
													<div className='position-relative mb-3'>
														<div className='d-flex justify-content-between w-100'>
															<select className='form-select form-control form-control-solid fs-6' 
																name='model'
																onChange={props.handleChange}
																value={props.values.model}
															>

																<option value='' disabled>{intl.formatMessage({id: 'Select an option'})}</option>
																<option value='1'>{intl.formatMessage({id: 'Gap Analysis'})}</option>
																<option value='2'>{intl.formatMessage({id: 'Scored'})}</option>
																<option value='3'>{intl.formatMessage({id: 'Not scored'})}</option>
																<option value='4'>{intl.formatMessage({id: 'Data Mapping'})}</option>



															</select>
														</div>
														{props.touched.model && props.errors.model && (
															<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
																<div className='fv-help-block'>
																	<span role='alert'>{props.errors.model}</span>
																</div>
															</div>
														)}
													</div>
												</div>
												<div className='col-lg-6 fv-row mb-3'>
													<label className='col-lg-4 form-label fw-bold text-dark fs-6'>{intl.formatMessage({id: 'Category'})}</label>
													<div className='position-relative mb-3'>
														<div className='d-flex justify-content-between w-100'>
															<select className='form-select form-control form-control-solid fs-6' 
																name='category'
																onChange={props.handleChange}
                                								value={props.values.category}
															>
																<option value=''>...{intl.formatMessage({id: 'Select an option'})}</option>
																{
																	data2.map((item: any, index: any)=>
																		<option key={index} value={item.value}>{item.text}</option>
																	)
																}
															</select>
														</div>
														{props.touched.category && props.errors.category && (
															<div className='fv-plugins-message-container text-danger fs-6 mt-1'>
																<div className='fv-help-block'>
																	<span role='alert'>{props.errors.category}</span>
																</div>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='card-footer d-flex justify-content-end py-6 px-9'>
									<button type='submit' className='btn btn-primary text-dark' disabled={loading}>
										{!loading && intl.formatMessage({id: 'Save'})}
										{loading && (
											<span className='indicator-progress' style={{display: 'block'}}>
												{intl.formatMessage({id: 'Processing'})}...{' '}
												<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
											</span>
										)}
									</button>
								</div>
							</form>
						)}
					</Formik>
					</div>
				</div>
			}
		</>
  )
}
export default EditionQuestion;