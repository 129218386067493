import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import pareto from 'highcharts/modules/pareto';
import ReactApexChart from 'react-apexcharts'


interface IReportClassificationProps {
    options: any,
	series: any
}

const BarCharts: React.FC<IReportClassificationProps> = ({...props}) => {


    const  series = [{
        data: [
            30, 15, 29
          ]
      }]
      const options: any = {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [
                'Janeiro', 'fevereiro', 'março'
                 
               ]
          }
        }

  return(
    <>
			{/* <div className='d-flex flex-column border-dashed border-1 p-3 border-primary rounded'> */}
				
				{/* <div className='d-flex flex-lg-row flex-xxxm-column align-items-xxm-center'> */}
					
					<ReactApexChart 
                    width={'650px'}
                    height={'550px'}
                    options={props.options} 
                    series={props.series} 
                    type='bar'
                    />
					
                    {/* <div className='p-xxm-4 p-lg-0 w-100 d-flex flex-column rounded align-items-center justify-content-center mt-xxm-10 mt-lg-0'>
						<span className='text-dark fw-bolder fs-2'>{degree.reduce((accumulator: any, curr: any) => accumulator + curr)}</span>
						<span className='text-dark fw-bolder fs-2'>{props.dataReport.points}</span>
						<span className='text-gray-600 fw-bold fs-3'>Total</span>
					</div>  */}

				{/* </div> */}
			{/* </div> */}

		</>
  )
}
export default BarCharts;