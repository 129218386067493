import { useEffect, useState } from 'react';
import { KTSVG } from '../../../../helpers';
import { getPreferenceConsent, updatePreferenceConsent } from '../../../../../app/modules/helpers/Api';
import { trataRespota, copyText, converteData } from '../../../../../app/modules/helpers/helpers';
import { toast } from 'react-toastify';
import { Load } from '../../spinner/Spinner';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

let paginationData = {
  currentPage: 1,
  total: 0,
  from: 0,
  to: 0,
  lastPage: 0
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 750,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

let initialSearchValues = {
  title: '',
  code: '',
  page: 1
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 950,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
  outline: 'none'
};

interface IModalViewPreferenceConsentProps {
  showModal: boolean;
  consentUuid: any;
  handleGetList: () => void;
  onClose: () => void;
  onAction: (id: any, form: string) => void;
}

const ModalViewPreferenceConsent: React.FC<IModalViewPreferenceConsentProps> = ({ ...props }) => {

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const intl = useIntl();

  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const [currentLocation, setCurrentLocation] = useState('');

  const initialValues = {
    uuid: '',
    title: '',
    show_title: 0,
    message: '',
    status: '- - -',
    user: '',
    updated_at: ''
  }

  const registrationSchema = Yup.object().shape({
    // requester_name: Yup.string(),
    // title: Yup.string()
    //   .min(3, `${intl.formatMessage({ id: 'Mínimo de 3 símbolos' })}`)
    //   .required(`${intl.formatMessage({ id: 'Title' })} ${intl.formatMessage({ id: 'is required' })}`),
    // description: Yup.string()
    //   .required(`${intl.formatMessage({ id: 'Description' })} ${intl.formatMessage({ id: 'is required' })}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleResponseHolderRequest(values);
    },
  })

  function handleConfirmUpdate() {
    setLoading(true);
    setOpenModalConfirm(false);

    let update = updatePreferenceConsent(formik.getFieldProps('uuid').value, {
      title: formik.getFieldProps('title').value,
      message: formik.getFieldProps('message').value,
      status: formik.getFieldProps('status').value,
      show_title: formik.getFieldProps('show_title').value ? 1 : 0,
      model_id: formik.getFieldProps('model_id').value
    });

    update.then((res) => {

      if (res.data.status === 'success') {
        // formik.resetForm()
        toast.success(res.data.message)
        setOpenModalConfirm(false)
        props.handleGetList()
        props.onClose()

      }
      else if (res.data.status === 'error') {
        toast.error(res.data.message);
      }

    }).catch((error) => {
      trataRespota('error', error);
    }).finally(() => {
      setLoading(false)

    });
  }

  function handleResponseHolderRequest(values: any) {

    if (values.status === 'Done') {
      setOpenModalConfirm(true)
    } else {
      handleConfirmUpdate();
    }

  }

  function handleGetData() {

    if (!props.consentUuid) {
      return
    }

    setLoading2(true);

    const get = getPreferenceConsent(props.consentUuid)

    get.then((res) => {

      if (res.data.status === 'success') {
        formik.setFieldValue('uuid', res.data.data.uuid);
        formik.setFieldValue('model_id', res.data.data.model_id);
        formik.setFieldValue('title', res.data.data.title);
        formik.setFieldValue('message', res.data.data.message);
        formik.setFieldValue('status', res.data.data.status ? res.data.data.status : '- - -');
        formik.setFieldValue('user', res.data.data.user);
        formik.setFieldValue('updated_user', res.data.data.updated_user);
        formik.setFieldValue('updated_at', res.data.data.updated_at ? converteData(res.data.data.updated_at) : '- - -');
        formik.setFieldValue('created_at', res.data.data.created_at ? converteData(res.data.data.created_at) : '- - -');
        formik.setFieldValue('show_title', res.data.data.show_title ? 1 : 0);

        setCurrentLocation(`${window.location.origin}/management/preference_consent/${res.data.data.lang ? res.data.data.lang : 'pt'}/${res.data.data.uuid}/link/response`);

      }

    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setLoading2(false);
      setLoading(false)
    });

    // }, 3000)

  }

  useEffect(() => {
    if (props.consentUuid) {
      handleGetData();
    }
    // eslint-disable-next-line 
  }, [props.consentUuid]);

  function onClear() {
    paginationData.from = 0
    paginationData.to = 0
    paginationData.total = 0
    paginationData.lastPage = 0

    initialSearchValues.title = ''
    initialSearchValues.code = ''
    initialSearchValues.page = 1

  }

  return (
    <div>
      <Modal
        open={props.showModal}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div className='px-5 d-flex flex-row justify-content-between align-items-center w-100'>

            <div className='d-flex fw-bold h-60px flex-row align-items-center border-1 border-bottom border-dark w-100'>
              <div className="modal-title fw-bold fs-3" style={{ marginRight: '6px' }}>{intl.formatMessage({ id: 'Management of acepts documents' })}

              </div>
              <div className={`badge ${formik.getFieldProps('status').value === 'active' ? 'badge-success' : 'badge-danger'}`}>{intl.formatMessage({ id: formik.getFieldProps('status').value })}</div>

            </div>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary"
              onClick={() => [props.onClose(), onClear()]}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>

          <div className='px-6 py-8'>

            {loading2 ?
              <Load />
              :

              <div>
                <div className=' mb-6 fw-bold' >
                  <span className={'cursor-pointer text-hover-primary'} title={intl.formatMessage({ id: 'Copy' })} onClick={() => copyText(formik.getFieldProps('uuid').value, intl)}>
                    <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-3 svg-icon-success me-2' />
                    <span>{formik.getFieldProps('uuid').value}</span>
                  </span>
                </div>



                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='kt_login_signup_form'
                  onSubmit={formik.handleSubmit}
                >

                  <input
                    disabled
                    type='hidden'
                    autoComplete='off'
                    {...formik.getFieldProps('uuid')}
                    className='form-control form-control-lg form-control-solid'
                  />

                  <div className='scroll-y pe-5 h-350px '>

                    <div className=' mb-12 fw-bold' >
                      <span className={formik.getFieldProps('status').value !== 'old' ? ' cursor-pointer text-hover-primary ' : ''} title={intl.formatMessage({ id: 'Copy' })} onClick={() => formik.getFieldProps('status').value !== 'old' ? copyText(currentLocation, intl) : null}>
                        <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-3 svg-icon-success me-2' />
                        <span>{currentLocation}</span>
                      </span>
                    </div>


                    {/* requester_name */}
                    <div className='row mb-3'>



                      <div className='col-sm-6'>
                        <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'Title' })}</label>
                        <input
                          disabled={formik.getFieldProps('status').value === 'old'}
                          placeholder={intl.formatMessage({ id: 'Title' })}
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('title')}
                          className='form-control form-control-lg form-control-solid'
                        />
                      </div>

                      <div className='col-sm-6'>
                        <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'Status' })}</label>
                        <select
                          disabled={formik.getFieldProps('status').value === 'old'}
                          className='form-select form-control form-control-solid fs-6'
                          {...formik.getFieldProps('status')}
                        >


                          {
                            formik.getFieldProps('status').value === 'old' ?
                              <option value='old' disabled>{intl.formatMessage({ id: 'old' })}</option>
                              :
                              <>
                                <option value='active'>{intl.formatMessage({ id: 'Active' })}</option>
                                <option value='inactive'>{intl.formatMessage({ id: 'Inactive' })}</option>
                              </>
                          }

                        </select>
                      </div>



                    </div>

                    {/* Message */}
                    <div className='mb-2 fv-row' data-kt-password-meter='true'>
                      <div className='mb-1'>
                        <label className='form-label fw-bold text-dark fs-6 '>{intl.formatMessage({ id: 'Message' })}</label>

                        <div className='position-relative mb-3'>
                          <div className='d-flex flex-column w-100 align-items-start'>
                            <div className="w-100 mb-10">
                              <ReactQuill
                                style={{ width: '100%', height: 150 }}
                                readOnly={formik.getFieldProps('status').value === 'old'} 
                                theme='snow'
                                placeholder={intl.formatMessage({ id: 'Message' })}
                                value={formik.getFieldProps('message').value || ''}
                                onChange={(value) => formik.setFieldValue('message', value)}
                              />
                            </div>

                            <div className='form-check form-check-custom form-check-solid mt-8'>
                              <input
                                disabled={formik.getFieldProps('status').value === 'old'}
                                className='form-check-input'
                                type='checkbox'
                                id='kt_login_toc_agree'
                                checked={formik.getFieldProps('show_title').value === 1 ? true : false}
                                onChange={() => [formik.setFieldValue('show_title', formik.getFieldProps('show_title').value ? 0 : 1)]}
                              />
                              <label htmlFor='kt_login_toc_agree' className='form-check-label fw-bold text-gray-700 fs-6' >
                                {intl.formatMessage({ id: 'Display the Title described above to the user along with the informed message.' })}
                              </label>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className='row'>

                      <div className='col-sm-12'>
                        <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'Url' })}</label>
                        <input
                          disabled={formik.getFieldProps('status').value == 'old'}
                          readOnly
                          type='text'
                          autoComplete='off'
                          value={currentLocation}
                          onClick={() => copyText(currentLocation, intl)}
                          className='form-control form-control-lg form-control-solid'
                        />
                      </div>

                    </div> */}


                    <hr />

                    {formik.getFieldProps('updated_user').value ?

                      <div className='row'>

                        <div className='col-sm-8'>
                          <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'Updated by user' })}</label>
                          <div>{formik.getFieldProps('updated_user').value}</div>
                          {/* <input
                            disabled
                            type='text'
                            autoComplete='off'
                            placeholder='- - -'
                            {...formik.getFieldProps('updated_user')}
                            className='form-control form-control-lg form-control-solid'
                          /> */}
                        </div>

                        <div className='col-sm-4'>
                          <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'Updated in' })}</label>
                          <div>{formik.getFieldProps('updated_at').value}</div>
                          {/* <input
                            disabled
                            type='text'
                            autoComplete='off'
                            placeholder='- - -'
                            {...formik.getFieldProps('updated_at')}
                            className='form-control form-control-lg form-control-solid'
                          /> */}
                        </div>

                      </div>

                      :

                      null

                    }


                    <div className='row'>

                      <div className='col-sm-8'>
                        <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'Created by user' })}</label>
                        <div>{formik.getFieldProps('user').value}</div>
                        {/* <input
                          disabled
                          type='text'
                          autoComplete='off'
                          placeholder='- - -'
                          {...formik.getFieldProps('user')}
                          className='form-control form-control-lg form-control-solid'
                        /> */}
                      </div>

                      <div className='col-sm-4'>
                        <label className='form-label fw-bold text-dark fs-5'>{intl.formatMessage({ id: 'Created in' })}</label>
                        <div>{formik.getFieldProps('created_at').value}</div>
                        {/* <input
                          disabled
                          type='text'
                          autoComplete='off'
                          placeholder='- - -'
                          {...formik.getFieldProps('created_at')}
                          className='form-control form-control-lg form-control-solid'
                        /> */}
                      </div>

                    </div>



                  </div>









                  <div className='text-center row'>

                    <div className='text-center col-12 mt-12 d-flex'>
                      <button
                        type='button'
                        className='btn bg-danger m-2 w-100 text-white'
                        //  disabled={!formik.values.acceptTerms}
                        onClick={() => props.onClose()}
                      >
                        <span className='indicator-label'>{intl.formatMessage({ id: 'Close' })}</span>
                      </button>


                      {formik.getFieldProps('status').value !== 'old' ?

                        <button
                          type='submit'
                          className='btn bg-primary text-dark m-2 w-100'
                        >
                          {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Update' })}</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>

                        :

                        null

                      }

                    </div>


                  </div>
                </form>

              </div>
            }

          </div>

        </Box>
      </Modal>

      <Modal
        open={openModalConfirm}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>

          <div className='container mt-5 p-5 text-center '>
            <h1 className='fs-1 fw-bolder' style={{ color: '#F5DD30' }}> {intl.formatMessage({ id: 'Requirement' })}</h1>
            <p className='w-90 mx-auto fs-4 mt-5'>
              {/* { responseMessage } */}
              {intl.formatMessage({ id: 'A response email will be sent to:' })} {formik.getFieldProps('requester_mail').value}, {intl.formatMessage({ id: 'do you want to proceed?' })}
            </p>
          </div>

          <div className='row p-4'>

            <div className='col-sm-6'>
              <button
                onClick={() => [setOpenModalConfirm(false)]}
                className='btn btn-sm btn-danger w-100'>
                {intl.formatMessage({ id: 'No' })}
              </button>
            </div>

            <div className='col-sm-6'>
              <button
                onClick={() => [handleConfirmUpdate()]}
                className='btn btn-sm btn-success w-100'>
                {intl.formatMessage({ id: 'Yes' })}
              </button>
            </div>

          </div>

        </Box>
      </Modal>


    </div>
  )
}
export default ModalViewPreferenceConsent;