import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { getAction, newAction, defaultPaginationList } from '../../../../../../app/modules/helpers/Api';
import DatePicker from "react-datepicker";
import { validarData } from '../../../../../../app/modules/helpers/helpers';
import moment from 'moment';
import ModalMessageConfirm from '../../../modals/modalMessageConfirm';
import { Load } from '../../../spinner/Spinner';
import ActionPlanSelectAction from './ActionPlanSelectAction';
import { useIntl } from 'react-intl'

type Props = {
  model: any;
  idActionPlanItem: any;
  form_id?: any;
  onCancel: () => void;
  onFinish: (action: boolean) => void;
  onReload: ()=> void;
}
let splitVar = 60

if(window.screen.width < 670){
  splitVar = 50
    if(window.screen.width < 570){
      splitVar = 40
      if(window.screen.width < 490){
        splitVar = 25
        if(window.screen.width < 300){
          splitVar = 15
        }
      }
    }
    
}

let searchData = {
  title: '',
  code: '',
  page: 1,
  perpage: 10000,
  form_id: ''
}

const ActionPlanActionCreate: React.FC<Props> = ({...props}) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const [actionList, setActionList] = useState<any>([]);
  const [actionValues, setActionValues] = useState<any>({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [errorDateStart, setErrorDateStart] = useState('');
  const [errorDateEnd, setErrorDateEnd] = useState('');
  const intl = useIntl();   

  const [errorAction, setErrorAction] = useState('');
  const [actionSelect, setActionSelect] = useState(`${intl.formatMessage({id: 'Select an action'})}`);
  const [actionSelectIndex, setActionSelectIndex] = useState('');
  const [actionSelectId, setActionSelectId] = useState('');
  const [openModalSelectAction, setOpenModalSelectAction] = useState(false);


  useEffect(()=>{
    setLoading(true);
    setEndDate(moment(startDate).add(1, 'days').toDate())
    searchData.form_id = props.form_id
    const action = defaultPaginationList('actions', searchData);
    action.then((res)=>{
      setActionList(res.data.data.data);
    }).catch((error)=> {
      toast.error(error.response.data.message)
    })
    .finally(()=>setLoading(false));

  },[props.idActionPlanItem]);

  function handleSelect(id: any, index: any, title: any) {
    setErrorAction('');
    setActionSelect(title);
    setActionSelectId(id);
    setActionSelectIndex(index);
    setOpenModalSelectAction(false);
  }

  const formik = useFormik({
    initialValues: {
      action_plan_id: props.idActionPlanItem,
      action: '',
    },
    onSubmit: (values, actions) => {
      setIsLoading(true);
      var error = false;
      if(validarData(startDate)){setErrorDateStart(`${intl.formatMessage({id: 'Invalid initial date'})}`); error = true}
      if(validarData(endDate)){setErrorDateEnd(`${intl.formatMessage({id: 'Invalid end date'})}`); error = true}
      if(actionSelectId === ''){setErrorAction(`${intl.formatMessage({id: 'Action'})} ${intl.formatMessage({id: 'is required'})}`); error = true}
      setActionValues(values);
      if(!error){setOpenModalSubmit(true)}
      setIsLoading(false);
    },
  })

  function handleSubmit(){
    setOpenModalSubmit(false)
    setLoading(true)
    const data = {
      action_id: actionSelectId,
      date_start: moment(startDate).format('YYYY-MM-DD'),
      date_end:moment(endDate).format('YYYY-MM-DD'),
      action_plan_id: actionValues.action_plan_id,
      objective: null,
      justification: null,
      use_degree: null
    }
    const save = newAction(data.action_id, data);
    save.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        props.onFinish(true);
        formik.resetForm();
        setLoading(false)
        props.onReload()
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
        setLoading(false)
        setOpenModalSubmit(false)
        props.onReload()
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.message);
      }
    })
      
  }


  return(
    <div className='card-body border-dashed border-2 border-gray-300 rounded px-5 py-5'>

      <ModalMessageConfirm
        message={intl.formatMessage({id: 'Are you sure you want to create this item?'})}
        title={intl.formatMessage({id: 'Attention'})}
        showModal={openModalSubmit}
        onClose={()=>setOpenModalSubmit(false)}
        onAction={handleSubmit}
      />
      {
        actionList !== 0?
        <ActionPlanSelectAction
          dataAction={actionList}
          title={intl.formatMessage({id: 'Select an action'})}
          showModal={openModalSelectAction}
          onClose={()=>setOpenModalSelectAction(false)}
          onAction={handleSelect}
        />
        :
        <></>
      }
      {
        loading?
        <Load/>
        :
        <div className='mx-4 align-items-center'>
          <form onSubmit={formik.handleSubmit} id='form-action-plan-action' noValidate className='form w-100'>
            <div className='align-items-center w-100'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({id: 'Action'})}</label>
                    <button type='button' style={{textAlign: 'start'}} onClick={()=>setOpenModalSelectAction(true)} className='form-select form-select-solid form-select-lg fw-bold fs-4 h-50px'>
                      <span>{actionSelect.slice(0,splitVar)+'...'}</span>
                    </button>
                    {errorAction && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fs-5'>{errorAction}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-sm-6 fv-row'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Initial Date'})}</label>
                    <DatePicker 
                      selected={startDate} 
                      dateFormat={'dd/MM/yyyy'}
                      minDate={moment().toDate()}
                      onChange={(date:Date) => [setStartDate(date), setErrorDateStart('')]} 
                      className='form-control form-control-md form-control-solid fs-4'
                    />
                    {errorDateStart && (
                      <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger fs-6 mt-1'>{errorDateStart}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-sm-6 fv-row'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Final Date'})}</label>
                    <DatePicker 
                      selected={endDate} 
                      dateFormat={'dd/MM/yyyy'}
                      minDate={moment().toDate()}
                      onChange={(date:Date) => [setEndDate(date), setErrorDateEnd('')]} 
                      className='form-control form-control-md form-control-solid fs-4'
                    />
                    {errorDateEnd && (
                      <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger fs-6 mt-1'>{errorDateEnd}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className='d-flex flex-row w-100 justify-content-end py-5'>
            <button type='button' onClick={()=>props.onCancel()} title='Cancel creation' form='form-action-plan-action' className='btn me-2 btn-sm btn-secondary' disabled={isLoading}>
            {intl.formatMessage({id: 'Cancel'})}
            </button>
            <button type='submit' title='Create action' form='form-action-plan-action' className='btn btn-sm btn-primary text-dark' disabled={isLoading}>
              {!isLoading && intl.formatMessage({id: 'Create'})}
              {isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'Processing'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      }
    </div>
  )
}
export default ActionPlanActionCreate;