/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {sendVerificationSubAccount, setSubAccountActive, AttInfoSubAcc, sendOnOffRepasseValor } from '../../../../app/modules/helpers/Api'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import { toast } from 'react-toastify'
import clsx from 'clsx'
import ModalMessageConfirm from '../modals/modalMessageConfirm'
import ModalMessage from '../modals/modalMessage';
import { converteData } from '../../../../app/modules/helpers/helpers'
import { useIntl } from 'react-intl'




const SetSubbAccount: FC = () => {
  const intl = useIntl();   
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
  const[loading, setLoading] = useState(false);
  const [seePassword, setSeePassword] = useState('password');
  const [onOffRepasse, setOnOffRepasse] = useState(user.sub_account.status === 1 ? true : false);

  const schema = Yup.object().shape({
    physical_products: Yup.string().required(`${intl.formatMessage({id: 'Physical products'})} ${intl.formatMessage({id: 'is required'})}`),
    business_type: Yup.string().required(`${intl.formatMessage({id: 'Business type'})} ${intl.formatMessage({id: 'is required'})}`),
    bank: Yup.string().required(`${intl.formatMessage({id: 'Bank'})} ${intl.formatMessage({id: 'is required'})}`),
    bank_ag: Yup.string().required(`${intl.formatMessage({id: 'Bank Agency'})} ${intl.formatMessage({id: 'is required'})}`),
    account_type: Yup.string().required(`${intl.formatMessage({id: 'Account Type'})} ${intl.formatMessage({id: 'is required'})}`),
    bank_cc: Yup.string().required(`${intl.formatMessage({id: 'Account number'})} ${intl.formatMessage({id: 'is required'})}`),
  })

  const initialValues = {
    physical_products: '',
    business_type: '',
    bank: '',
    bank_ag: '',
    account_type: '',
    bank_cc: '',
    automatic_transfer: ''
  }

  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const [value, setValue] = useState<any>({});

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setOpenModalSubmit(true);
    },
  })

  
  function handleAttInfoSubAcc(){
    setLoading(true);

    user.sub_account.balance = '- -';
    user.sub_account.protected_balance = '- -';
    user.sub_account.receivable_balance = '- -';
    user.sub_account.updated_at = '- -';

    const attInfo = AttInfoSubAcc();
    attInfo.then((res)=>{

      if(res.data.status === 'success'){

        user.sub_account.balance = res.data.data.balance ? res.data.data.balance : '- -';
        user.sub_account.protected_balance = res.data.data.protected_balance ? res.data.data.protected_balance : '- -';
        user.sub_account.receivable_balance = res.data.data.receivable_balance ? res.data.data.receivable_balance : '- -';
        user.sub_account.updated_at = res.data.data.updated_at ? res.data.data.updated_at : '- -';

        toast.success(res.data.message);        
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      } else {
        toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}`);
      }
    }).finally(()=> {
      setLoading(false);
      setOpenModalCreate(false);
    });
  }

  function handleCreate(){
    setOpenModalCreate(true);
  }

  function activeSubAccount(){  
    setLoading(true);
    const save = setSubAccountActive();
    save.then((res)=>{

      if(res.data.status === 'success'){
        toast.success(res.data.message);        
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      } else {
        toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}`);
      }
    }).finally(()=> {
      setLoading(false);
      setOpenModalCreate(false);
    });

  }

  
  function onOffRepasseValor(value: any) {

    setOnOffRepasse(value)
   
    const save = sendOnOffRepasseValor({status: value});
    save.then((res)=>{

      if(res.data.status === 'success'){
        toast.success(res.data.message);
        
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      } else {
        toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}`);
      }
    }).finally(()=> {
     
    });

  }
 

  function save(value: any){    
    const data = {
      physical_products: value.physical_products,
      business_type: value.business_type,
      bank: value.bank,
      bank_ag: value.bank_ag,
      account_type: value.account_type,
      bank_cc: value.bank_cc,
      automatic_transfer: value.automatic_transfer,
    }
    setLoading(true);
    const save = sendVerificationSubAccount(user.id, data);
    save.then((res)=>{

      if(res.data.status === 'success'){
        toast.success(res.data.message);
        formik.resetForm();
        
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      } else {
        toast.error(`${intl.formatMessage({id: 'Sorry, an error occurred'})}`);
      }
    }).finally(()=> {
      setLoading(false);
      setOpenModalSubmit(false);
    });
  }



  return (
    
    ['dpo', 'manager-adm'].includes(user.roles)
     ?
    <div className='card mb-5 mb-xl-10'>
      
      <ModalMessageConfirm
        message={`${intl.formatMessage({id: 'Are you sure you want to edition this item?'})}`} 
        title={`${intl.formatMessage({id: 'Attention'})}`}
        showModal={openModalSubmit}
        onClose={()=>setOpenModalSubmit(false)}
        onAction={()=>save(value)}
      />

      <ModalMessage
        message={`${intl.formatMessage({id: 'Do you want to activate?'})}`}
        title={`${intl.formatMessage({id: 'Attention'})}`}
        showModal={openModalCreate}
        onClose={()=>setOpenModalCreate(false)}
        onAction={()=>activeSubAccount()}
      />

      <div className='collapse show'>

        <div className='card-body border-dashed border-2 rounded m-10 px-5 py-5'>

          

          <div className='row'>
            <div className='col'>
              <label className='form-label fw-bolder text-dark fs-4 pb-5'>{intl.formatMessage({id: 'Sub-Account'})}</label>
            </div>
            { user.sub_account.verified === 'yes' ? 
            <div className='col d-flex flex-row w-100 justify-content-end'>

              <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                <input
                  name="tags"
                  type="checkbox"
                  value={`${intl.formatMessage({id: 'Yes'})}`}
                  checked={onOffRepasse}
                  className='form-check-input'
                  onChange={e => {
                    if (e.target.checked) {
                      onOffRepasseValor(true)
                    } else {
                      onOffRepasseValor(false)
                    }
                  }}
                  
                />
                <span className='form-check-label text-muted'>{intl.formatMessage({id: 'Activate Transfer of value'})}</span>
              </label>

            </div>
            :
            null
          }
          </div>

          {
            user.sub_account && user.sub_account.create === 'yes' && user.sub_account.verified === 'yes'?
            <div>
              
              <label className='form-label fw-bolder text-gray-400'>{intl.formatMessage({id: 'Status'})}: <span className='form-label fw-bolder text-success'>Active</span></label>
              
              <div className='row'>
                <div className='col-md-3'> <label className='form-label fw-bolder text-gray-400'>{intl.formatMessage({id: 'Balance'})}: <span className='form-label fw-bolder text-dark'>{user.sub_account.balance ? user.sub_account.balance : '- -'}</span></label> </div>
                <div className='col-md-3'> <label className='form-label fw-bolder text-gray-400'>{intl.formatMessage({id: 'Blocked'})}: <span className='form-label fw-bolder text-dark'>{user.sub_account.protected_balance ? user.sub_account.protected_balance : '- -'}</span></label> </div>
                <div className='col-md-3'> <label className='form-label fw-bolder text-gray-400'>{intl.formatMessage({id: 'to be released'})}: <span className='form-label fw-bolder text-dark'>{user.sub_account.receivable_balance ? user.sub_account.receivable_balance : '- -'}</span></label></div>
              </div>

              <div className='row mt-4'>
                <div className='col-md-12'> <label className='form-label fw-bolder text-gray-400'>{intl.formatMessage({id: 'Updated in'})}: <span className='form-label fw-bolder text-dark'> {user.sub_account.updated_at ? (user.sub_account.updated_at != '- -' && user.sub_account.updated_at != null ? converteData(user.sub_account.updated_at) : '- -') : '- -'}</span></label> </div>
              </div>

              <div className='d-flex justify-content-end py-6 px-0 '>
                <button type='button' className='btn btn-primary' disabled={loading} onClick={()=>handleAttInfoSubAcc()}>
                  {!loading && intl.formatMessage({id: 'Update'})}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {intl.formatMessage({id: 'Processing'})}...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>

            </div>
            :
            <div>
              <p>
              {intl.formatMessage({id: 'For the transfer of values ​​to happen, we need your account to be active and properly confirmed and for that we need some more data'})}</p>

              {
                user.sub_account && user.sub_account.create === 'no' ?
                <div className='d-flex justify-content-end py-6 px-0 '>
                  <button type='button' className='btn btn-primary text-dark' disabled={loading} onClick={()=>handleCreate()}>
                    {!loading && intl.formatMessage({id: 'Activate'})}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'Processing'})}...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                :
                null
              }
            
              {
                user.sub_account && user.sub_account.create === 'yes' ?
                <div>
                  <p>* <small>{intl.formatMessage({id: 'Enter the data below correctly, it will not be possible to change it'})}</small></p>
                  <form onSubmit={formik.handleSubmit} className='form'>
                    <div className='col-lg-12 fv-row mb-3'>
                      <label className='col-lg-4 col-form-label fw-bold fs-5 required'>{intl.formatMessage({id: 'Do you sell physical products?'})}</label>
                      <select className='form-select form-control form-control-solid fs-6 ' 
                        {...formik.getFieldProps('physical_products')}
                      >
                        <option key='' value='' disabled>{intl.formatMessage({id: 'Select'})}...</option>
                        <option key='yes' value='true'>{intl.formatMessage({id: 'Yes'})}</option>
                        <option key='no' value='false'>{intl.formatMessage({id: 'No'})}</option>
                      </select>
                    </div>
                    <div className='mb-2 fv-row pt-5' data-kt-password-meter='true'>
                      <div className='mb-1'>
                        <label className='form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Business description'})}</label>
                        <div className='position-relative mb-3'>
                          <div className='d-flex justify-content-between w-100'>
                            <input
                              type='text' 
                              placeholder={`${intl.formatMessage({id: 'Ex: Cleaning Services'})}`} 
                              autoComplete='off'
                              {...formik.getFieldProps('business_type')}
                              className='form-control form-control-lg form-control-solid w-100 me-1' 
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 fv-row mb-3'>
                      <label className='col-lg-4 col-form-label fw-bold fs-5 required'>{intl.formatMessage({id: 'Bank of the account'})}</label>
                      <select className='form-select form-control form-control-solid fs-6' 
                        {...formik.getFieldProps('bank')}
                      >
                        <option key='' value='' disabled>{intl.formatMessage({id: 'Select'})}...</option>
                        <option key='Banco do Brasil' value='Banco do Brasil'>Banco do Brasil</option>
                        <option key='Santander' value='Santander'>Santander</option>
                        <option key='Caixa Econômica' value='Caixa Econômica'>Caixa Econômica (X: {intl.formatMessage({id: 'Operation'})})</option>
                        <option key='Caixa Econômica cp' value='Caixa Econômica'>Caixa Econômica (X: {intl.formatMessage({id: 'Operation'})} - {intl.formatMessage({id: 'New format'})} CP)</option>
                        <option key='Bradesco' value='Bradesco'>Bradesco</option>
                        <option key='Itaú' value='Itaú'>Itaú</option>
                        <option key='Itaú Iti' value='Itaú'>Itaú (Itaú-Iti)</option>
                        <option key='Agibank' value='Agibank'>Agibank</option>
                        <option key='Banpará' value='Banpará'>Banpará</option>
                        <option key='Banrisul' value='Banrisul'>Banrisul</option>
                        <option key='Sicoob' value='Sicoob'>Sicoob</option>
                        <option key='Inter' value='Inter'>Inter</option>
                        <option key='BRB' value='BRB'>BRB</option>
                        <option key='Via Credi' value='Via Credi'>Via Credi</option>
                        <option key='Neon/Votorantim' value='Neon/Votorantim'>Neon/Votorantim</option>
                        <option key='Nubank' value='Nubank'>Nubank</option>
                        <option key='Pagseguro' value='Pagseguro'>Pagseguro</option>
                        <option key='Banco Original' value='Banco Original'>Banco Original</option>
                        <option key='Safra' value='Safra'>Safra</option>
                        <option key='Modal' value='Modal'>Modal</option>
                        <option key='yBanesteses' value='Banestes'>Banestes</option>
                        <option key='Unicred' value='Unicred'>Unicred</option>
                        <option key='Money Plus' value='Money Plus'>Money Plus</option>
                        <option key='Mercantil do Brasil' value='Mercantil do Brasil'>Mercantil do Brasil</option>
                        <option key='JP Morgan' value='JP Morgan'>JP Morgan</option>
                        <option key='Gerencianet Pagamentos do Brasil' value='Gerencianet Pagamentos do Brasil'>Gerencianet Pagamentos do Brasil</option>
                        <option key='Banco C6' value='Banco C6'>Banco C6</option>
                        <option key='BS2' value='BS2'>BS2</option>
                        <option key='Banco Topazio' value='Banco Topazio'>Banco Topazio</option>
                        <option key='Uniprime' value='Uniprime'>Uniprime</option>
                        <option key='Stone' value='Stone'>Stone</option>
                        <option key='Rendimento' value='Rendimento'>Rendimento</option>
                        <option key='Banco Daycoval' value='Banco Daycoval'>Banco Daycoval</option>
                        <option key='Banco do Nordeste' value='Banco do Nordeste'>Banco do Nordeste</option>
                        <option key='Citibank' value='Citibank'>Citibank</option>
                        <option key='PJBank' value='PJBank'>PJBank</option>
                        <option key='Cooperativa Central de Credito Noroeste Brasileiro' value='Cooperativa Central de Credito Noroeste Brasileiro'>Cooperativa Central de Credito Noroeste Brasileiro</option>
                        <option key='Uniprime Norte do Paraná' value='Uniprime Norte do Paraná'>Uniprime Norte do Paraná</option>
                        <option key='Global SCM' value='Global SCM'>Global SCM</option>
                        <option key='Next' value='Next'>Next</option>
                        <option key='Cora' value='Cora'>Cora</option>
                        <option key='Mercado Pago' value='Mercado Pago'>Mercado Pago</option>
                        <option key='Banco da Amazonia' value='Banco da Amazonia'>Banco da Amazonia</option>
                        <option key='BNP Paribas Brasil' value='BNP Paribas Brasil'>BNP Paribas Brasil</option>
                        <option key='Juno' value='Juno'>Juno</option>
                        <option key='Cresol' value='Cresol'>Cresol</option>
                        <option key='BRL Trust DTVM' value='BRL Trust DTVM'>BRL Trust DTVM</option>
                        <option key='Banco Banese' value='Banco Banese'>Banco Banese</option>
                        <option key='Banco BTG Pactual' value='Banco BTG Pactual'>Banco BTG Pactual</option>
                        <option key='Banco Omni' value='Banco Omni'>Banco Omni</option>
                        <option key='Acesso Soluções de pagamento' value='Acesso Soluções de pagamento'>Acesso Soluções de pagamento</option>
                        <option key='CCR de São Miguel do Oeste' value='CCR de São Miguel do Oeste'>CCR de São Miguel do Oeste</option>
                        <option key='Polocred' value='Polocred'>Polocred</option>
                        <option key='Ótimo' value='Ótimo'>Ótimo</option>
                        <option key='Picpay' value='Picpay'>Picpay</option>
                        <option key='Banco Genial' value='Banco Genial'>Banco Genial</option>
                        <option key='Banco Capital S.A' value='Banco Capital S.A'>Banco Capital S.A</option>
                        <option key='Sicredi' value='Sicredi'>Sicredi</option>
                        <option key='Banco Ribeirão Preto' value='Banco Ribeirão Preto'>Banco Ribeirão Preto</option>
                        <option key='ASAAS IP' value='ASAAS IP'>ASAAS IP</option>
                        <option key='Banco Pan' value='Banco Pan'>Banco Pan</option>
                        <option key='Neon' value='Neon'>Neon</option>
                        <option key='VORTX' value='VORTX'>VORTX</option>
                        <option key='Banco' value='Banco'>Banco</option>
                        <option key='Fitbank' value='Fitbank'>Fitbank</option>

                      </select>
                    </div>
                    <div className='mb-2 fv-row pt-5' data-kt-password-meter='true'>
                      <div className='mb-1'>
                        <label className='form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Agency of the account'})}</label>
                        <div className='position-relative mb-3'>
                          <div className='d-flex justify-content-between w-100'>
                            <input
                              type='text' 
                              placeholder='5749'
                              // 'Ex: Serviços de Limpeza'
                              autoComplete='off'
                              {...formik.getFieldProps('bank_ag')}
                              className='form-control form-control-lg form-control-solid w-100 me-1' 
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 fv-row mb-3'>
                      <label className='col-lg-4 col-form-label fw-bold fs-5 required'>{intl.formatMessage({id: 'Account Type'})}</label>
                      <select className='form-select form-control form-control-solid fs-6' 
                        {...formik.getFieldProps('account_type')}
                      >
                        <option key='' value='' disabled>{intl.formatMessage({id: 'Select'})}...</option>
                        <option key='Corrente' value='Corrente'>{intl.formatMessage({id: 'Current'})}</option>
                        <option key='Poupança' value='Poupança'>{intl.formatMessage({id: 'Savings'})}</option>
                      </select>
                    </div>
                    <div className='mb-2 fv-row pt-5' data-kt-password-meter='true'>
                      <div className='mb-1'>
                        <label className='form-label fw-bold text-dark fs-6 required'>{intl.formatMessage({id: 'Account number'})}</label>
                        <div className='position-relative mb-3'>
                          <div className='d-flex justify-content-between w-100'>
                            <input
                              type='text' 
                              placeholder='01322973-4'
                              autoComplete='off'
                              {...formik.getFieldProps('bank_cc')}
                              className='form-control form-control-lg form-control-solid w-100 me-1' 
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 fv-row mb-3'>
                      <label className='col-lg-4 col-form-label fw-bold fs-5 required'>{intl.formatMessage({id: 'Automatic withdrawal'})} ({intl.formatMessage({id: 'We recommend that you send'})}  '{intl.formatMessage({id: 'Yes'})}')</label>
                      <select className='form-select form-control form-control-solid fs-6' 
                        {...formik.getFieldProps('automatic_transfer')}
                      >
                        <option key='' value='' disabled>{intl.formatMessage({id: 'Select'})}...</option>
                        <option key='Corrente' value='true'>{intl.formatMessage({id: 'Yes'})}</option>
                        <option key='Poupança' value='false'>{intl.formatMessage({id: 'No'})}</option>
                      </select>
                    </div>
                
                    <div className='card-footer d-flex justify-content-end py-6 px-0 '>
                      <button type='submit' className='btn btn-primary' disabled={loading}>
                        {!loading && intl.formatMessage({id: 'Submit'})}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {intl.formatMessage({id: 'Processing'})}...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
                :
                null
              }
            </div>
          }
          
        </div>

      </div>
    </div>
    :
    null
  )
}

export {SetSubbAccount}
