import React, { useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { KTSVG } from '../../../../../helpers';
import { useFormik} from 'formik';
import DatePicker from "react-datepicker";
import moment from 'moment';
import ModalMessage from '../../../modals/modalMessage';
import { monitoringCreate, monitoringUpdate, defaultPaginationList } from '../../../../../../app/modules/helpers/Api';
import { toast } from 'react-toastify';
import { converteData2 } from '../../../../../../app/modules/helpers/helpers';
import { Load } from '../../../spinner/Spinner';
import Pagination from '../../../../../helpers/components/CustomPagination/CustomPagination'



let searchData = {
  title: '',
  code: '',
  page: 1,
  perpage: 5
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  maxHeight: 450,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

interface IActionMonitoringsProps {
  showModal: boolean;
  title: string;
  plan_action_id: any;
  status: any;
  monitoringList: any[];
  onClose: () => void;
  onAction: (data: any) => void;
}


const ActionMonitoring: React.FC<IActionMonitoringsProps> = ({...props}) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [date, setDate] = useState(new Date);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalConfirmEdition, setOpenModalConfirmEdition] = useState(false);
  const [dataValues, setDataValues] = useState<any>([]);
  const [dataValuesEdition, setDataValuesEdition] = useState<any>([]);
  const [monitorings, addItemList] = useState<any>([]);
  const [monitoringsData, setMonitoringData] = useState<any> ([]);
  const [tela, setTela] = useState(1);

  if(monitorings.length === 0){
    if (props.monitoringList.length>0) {
      addItemList(props.monitoringList)
    }
  }

  useEffect(()=>{
    handleGetList(null)
  },[loading2]);

  function handleGetList(data:null) {
    setIsLoading(true);
    const get = defaultPaginationList('monitoring', searchData)
    get.then((res)=>{
      const data = {
        date: res.data.date,
        status: res.data.status,
        description: res.data.description
      }
      
      addItemList(data);
    }).catch((error)=>{
      toast.error(error.response.data.message)
    })
    .finally(()=>[setIsLoading(false), setLoading2]);
  }
  
  
  const formik = useFormik({
    initialValues: {
      status: '',
      description: '',
    },
    onSubmit: (values) => {
      setOpenModalConfirm(true);
    }
  })


  const save = (values: any) => {
    const data = {
      plan_action_id: props.plan_action_id,
      status: values.status,
      description: values.status,     
      date: moment(date).format('YYYY-MM-DD')
    } 
   
    setOpenModalConfirm(false);
    setIsLoading(true);
    const send = monitoringCreate(data);
    send.then((res)=>{
      if(res.data.status === 'success'){
        const data = {
          date: res.data.data.date,
          status: res.data.data.status,
          description: res.data.data.description
        }
       
        setTela(1)
        toast.success(res.data.message);
        formik.resetForm()
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      }
    }).finally(()=>{    
      setIsLoading(false);
    });
  } 

  function handleAddItem(){
    setTela(2)
  }
  
  function handleCancel(){
    setTela(1)
    formik.resetForm()
    formikEdition.resetForm()
  }

  const [index, setIndex] = useState<number>(0);
  const [load, setLoad] = useState(false);

  function handleEdition(){
    setLoad(true);
    setTimeout(()=>{
      setLoad(false);
    },2000);
  }

  const formikEdition = useFormik({
    initialValues: {
      status: monitorings[index]?.status,
      description: monitorings[index]?.description,
      date: new Date(moment(monitorings[index]?.date).format('YYYY/MM/DD')) 
    },
    onSubmit: (values) => {
      setDataValuesEdition(values);
      setOpenModalConfirmEdition(true);
    }
    
  })

  const saveEdition = (values: any) => {
    const data = {
      id: monitorings[index].id,
      plan_action_id: props.plan_action_id,
      status: values.status,
      description: values.description,
      date: moment(values.date).format('YYYY-MM-DD'),
      
    } 
    
    setOpenModalConfirmEdition(false);
    setIsLoading(true);
    const send = monitoringUpdate(data, monitorings[index].id);
    send.then((res)=>{
      if(res.data.status === 'success'){
        
        let copy = [...monitorings]  
        copy[index] = data
        addItemList(copy)

        setTela(1)
        toast.success(res.data.message);
        formikEdition.resetForm()
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      if(error.response){
        toast.error(error.response.message);
      }
    }).finally(()=>{    
      setIsLoading(false);
    });
  } 

  return(
    <>
    <ModalMessage
      message='Are you sure you want to create this item?'
      title='Attention!'
      showModal={openModalConfirm}
      onClose={()=>setOpenModalConfirm(false)}
      onAction={()=>save(dataValues)}
    />
    <ModalMessage
      message='Are you sure you want to edit this item?'
      title='Attention!'
      showModal={openModalConfirmEdition}
      onClose={()=>setOpenModalConfirmEdition(false)}
      onAction={()=>saveEdition(dataValuesEdition)}
    />
    <Modal
      open={props.showModal}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
          <span className="modal-title fw-bold fs-3">
            {
              tela === 1
              ? 'Monitoring'
              : tela === 2 
              ? 'Add New Monitoring'
              : 'Edit Monitoring'
            }
          </span>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>props.onClose()}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        {
          tela===1? 
          <div className='p-4'>
            
              <div style={{overflow: 'scroll', overflowX: 'hidden', maxHeight: 250}}>
              
              {
                monitorings.length === 0?
                 <button 
                   
                 type='button' 
                 onClick={()=>handleAddItem()} 
                 className='btn btn-md w-100 btn-primary mb-3' 
                 disabled={isLoading } 
               >
                 Add
               </button>
               :
               <></>
              }
              {
              
                monitorings.map((item:any, index:any) => (
                
               <div key={item.id}>
               

               {
                index >= 1
                ?
                <></>
                :
                <button 
               
                type='button' 
                onClick={()=>handleAddItem()} 
                className='btn btn-md w-100 btn-primary mb-3' 
                disabled={isLoading }
              >
                Add
              </button>
                  
               }

                 <div 
                  
                  role={'button'}
                  onClick={()=>[setIndex(index), setTela(3), handleEdition()]} 
                  className="mb-5 w-100 alert text-dark bg-white bg-hover-light-primary border-2 border-dashed border-primary"
                  >
                  <div className='d-flex flex-row w-100 justify-content-between mb-3'>
                    <div>
                      <span className='fw-bolder fs-5 me-2'>Date:</span>
                      <span className='fw-bold fs-6 text-gray-800'>{converteData2(item.date) }</span>
                      
                    </div>
                    <div>
                      <span className='fw-bolder fs-5 me-2'>Status:</span>
                      <span className='fw-bold fs-6 text-gray-800'                 
                     >{ item.status}</span>
                    </div>
                  </div>
                  <div className='d-flex flex-column'>
                    <span className='fw-bolder fs-5 me-2'>Description:</span>
                    <span className='fw-bold fs-6 text-gray-800'>{item.description ? item.description : '- - -'}</span>
                  </div>
                </div>
               </div>
              
       
              
              ))
            }
            </div><Pagination data={monitorings} onSearch={handleGetList} searchData={searchData}/>
          </div>
          :
          <div>
            {
              tela===2? 
                <div>
                  <div className='px-5' style={{overflow: 'scroll', overflowX: 'hidden', maxHeight: 250}}>
                    <form onSubmit={formik.handleSubmit} id='form-action-plan-action' noValidate className='form w-100'>
                      <div className='align-items-start w-100'>
                        <div className='row'>
                         <label className='col-lg-12 col-form-label fs-5 mt-auto'>
                           Pick a date for your return.
                          </label>
                          <div className='col-sm-6 fv-row'>
                            
                            <label className='col-lg-4 col-form-label required fw-bold fs-5'>Date</label>
                           
                            <DatePicker 
                              selected={date} 
                              dateFormat={'dd/MM/yyyy'}
                              minDate={moment().toDate()}
                              onChange={(date: Date) => setDate(date)} 
                              className='form-control form-control-md form-control-solid fs-4'
                              disabled={moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')}
                            />
                           
                          </div>
                          
                            

                          {/* <div className='col-sm-6 fv-row'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-5'>Status</label>
                            <select className='form-select form-select-solid form-select-lg fw-bold fs-4' 
                            
                              onChange={(status) => setStatus(status.target.value)} 
                              
                            >
                              <option value=''disabled >Select an status</option>
                              <option value='yes'>Yes</option>
                              <option value="await">Await</option>
                              <option value='no'>No</option>
                            </select>
                            {formik.touched.status && formik.errors.status && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.status}</div>
                              </div>
                            )}
                          </div>

                          <div className='col-lg-12 fv-row'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-5'>Description</label>
                            <textarea
                              rows={5}
                              className="form-control form-control-solid fs-5"
                              placeholder="Description"
                              onChange={(description)=> setDescription(description.target.value)}
                            />
                            {formik.touched.description && formik.errors.description && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.description}</div>
                              </div>
                            )}
                          </div> */}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='d-flex flex-row w-100 justify-content-end p-5'>
                    <button type='button' onClick={()=>handleCancel()} title='Cancel edition' form='form-action-plan-action' className='btn me-2 btn-sm btn-secondary' disabled={isLoading}>
                      Cancel
                    </button>
                    <button type='submit' title='Save changes' form='form-action-plan-action' className='btn btn-sm btn-primary' disabled={isLoading}>
                      {!isLoading && 'Save'}
                      {isLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Processando...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
                :
                <div>
                  {
                    tela === 3?
                    <div>
                      <div className='px-5' style={{overflow: 'scroll', overflowX: 'hidden', maxHeight: 250}}>
                        {
                          load?
                          <Load/>
                          :
                          <form onSubmit={formikEdition.handleSubmit} id='form-action-plan-action' noValidate className='form w-100'>
                            <div className='align-items-start w-100'>
                        
                                <div className='row'>

                                  <div className='col-sm-6 fv-row'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-5'>Date</label>
                                    <DatePicker 
                                      selected={formikEdition.values.date} 
                                      dateFormat={'dd/MM/yyyy'}
                                      minDate={moment().toDate()}
                                      onChange={(date: Date) => formikEdition.setFieldValue('date', date)} 
                                      className='form-control form-control-md form-control-solid fs-4'
                                      disabled={moment(formikEdition.values.date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')}
                                    />
                                  </div>

                                  <div className='col-sm-6 fv-row'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-5'>Status</label>
                                    <select className='form-select form-select-solid form-select-lg fw-bold fs-4' 
                                      {...formikEdition.getFieldProps('status')}                                                                   
                                      
                                    >
                                      <option value='' disabled>Select an status</option>
                                      <option value="await">Await</option>
                                      <option value='yes'>Yes</option>
                                      <option value='no'>No</option>
                                    </select>
                                    {formikEdition.touched.status && formikEdition.errors.status && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block text-danger fs-6 mt-1'>{formikEdition.errors.status}</div>
                                      </div>
                                    )}
                                  </div>

                                  <div className='col-lg-12 fv-row'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-5'>Description</label>
                                    <input
                                      type='text'
                                      className="form-control form-control-solid fs-5"
                                      placeholder="Description"
                                      onChange={(e)=>formikEdition.setFieldValue('description', e.currentTarget.value)}
                                      value={formikEdition.values.description}
                                    />
                                    {formikEdition.touched.description && formikEdition.errors.description && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block text-danger fs-6 mt-1'>{formikEdition.errors.description}</div>
                                      </div>
                                    )}
                                  </div>

                                </div>
                          
                            </div>
                          </form>
                        }
                      </div>
                      <div className='d-flex flex-row w-100 justify-content-end p-5'>
                        <button type='button' onClick={()=>handleCancel()} title='Cancel edition' form='form-action-plan-action' className='btn me-2 btn-sm btn-secondary' disabled={isLoading}>
                          Cancel
                        </button>
                        <button type='submit' title='Save changes' form='form-action-plan-action' className='btn btn-sm btn-primary' disabled={isLoading}>
                          {!isLoading && 'Save'}
                          {isLoading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Processando...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                    :
                    <></>
                    }
                  </div>
                }
              </div>
            }
          </Box>
        </Modal>
    </>
  )
}

export default ActionMonitoring;