import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {KTSVG} from '../../../../../helpers'
import Modal from '@mui/material/Modal';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { newActionPlan } from '../../../../../../app/modules/helpers/Api';
import { useHistory } from 'react-router-dom';
import { trataRespota } from '../../../../../../app/modules/helpers/helpers'
import { useIntl } from 'react-intl'

let widthVar = 600;

if(window.screen.width < 700){
  widthVar = 550
  if(window.screen.width < 600){
    widthVar = 400
    if(window.screen.width < 450){
      widthVar = 250
    }
  }
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: widthVar,
  maxHeight: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,
};

interface IModalActionPlansReportProps {
  id: any;
  form_id?: any;
  showModal: boolean;
  title: string;
  onClose: () => void;
}


const ModalActionPlansReport: React.FC<IModalActionPlansReportProps> = ({
  ...props
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const intl = useIntl();   

  const schema = Yup.object().shape({
    title: Yup.string().required(`${intl.formatMessage({id: 'Title'})} ${intl.formatMessage({id: 'is required'})}`)
  });
  
  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: schema,
    onSubmit: (values, actions) => {
      setIsLoading(true);
      setTimeout(() => {
        onSave(values);
        setIsLoading(false);
      }, 1000)
    },
  })

  function onSave(values: any){
    const data = {
      title: values.title,
      description: values.description,
      prescription_answered_id: props.id,
      form_id: props.form_id
    }

    const getRequest = newActionPlan(data);
    getRequest.then((res)=>{

      if(res.data.status === 'success'){
        toast.success(res.data.message);
        props.onClose();
        history.push({
          pathname: "/crafted/pages/action/plansactions",
          state: res.data.data.id,
        })
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      trataRespota('error', error); 
     // toast.error(error.response.statusText);
    })
  }
  
  return(
    <Modal
      open={props.showModal}
      onClose={()=>{}}
    >
      <Box sx={style}>
        <div className='px-5 border-1 border-bottom border-gray-200 h-60px d-flex flex-row justify-content-between align-items-center w-100'>
          <span className="modal-title fw-bold fs-3">{props.title}</span>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary"
            onClick={()=>[props.onClose(), formik.resetForm()]}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className='px-5 mb-5' style={{overflow: 'auto', overflowX: 'hidden', maxHeight: 350}}>
          <form onSubmit={formik.handleSubmit} id='form-action-plan' noValidate className='form w-100'>
            <div className='align-items-center w-100'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-5'>{intl.formatMessage({id: 'Title'})}</label>
                    <input
                        type="text"
                        className="form-control form-control-solid fs-5"
                        placeholder={intl.formatMessage({id: 'Title'})}
                        {...formik.getFieldProps('title')}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                    <label className='col-lg-4 col-form-label fw-bold fs-5'>{intl.formatMessage({id: 'Description'})}</label>
                    <input
                      type="text"
                      className="form-control form-control-solid fs-5"
                      placeholder={intl.formatMessage({id: 'Description'})}
                      {...formik.getFieldProps('description')}
                    />
                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fs-6 mt-1'>{formik.errors.description}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className='d-flex flex-row w-100 justify-content-end p-5'>
          <button type='submit' form='form-action-plan' className='btn btn-md btn-primary text-dark' disabled={isLoading}>
            {!isLoading && intl.formatMessage({id: 'Save'})}
            {isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'Processing'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </Box>
    </Modal>
  )
}
export default ModalActionPlansReport;