/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { delResources, downResource, getResourceLink, getResources, defaultPaginationList } from '../../../../../app/modules/helpers/Api';
import { converteData, handleDownload, copyText} from '../../../../../app/modules/helpers/helpers';
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import ModalMessage from '../../modals/modalMessage';
import { Load } from '../../spinner/Spinner';
import Warning from '../../warning/Warning';
import {UserModel} from '../../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import { useIntl } from 'react-intl'
import Pagination from '../../../../helpers/components/CustomPagination/CustomPagination' 
import { Users } from '../../../../../app/modules/profile/components/Users';

type Props = {
  create: (action: boolean) => void;
  edit: (action: boolean, id?: any, resource?: any) => void;
}

let searchData = {
  page: 1,
  perpage: 10,
  title: null,
  model: null,
  type: null
}
const ResourcesList: React.FC<Props> = ({...props}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const intl = useIntl();   
  const [loading, setLoading] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [resourceList, setResourceList] = useState<any>([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [idResource, setIdResource] = useState('');
  const [size, setSize] = useState('');
  const [titleFilter, setTitleFilter ]= useState<any>('');
  const [modelFilter, setModelFilter] = useState<any>('');
  const [typeFilter, setTypeFilter] = useState<any>('');


  searchData.title = titleFilter
  searchData.model = modelFilter
  searchData.type = typeFilter

  useEffect(()=>{
    handleGetList(null)
  },[loadingScreen]);

  function handleGetList(data:null) {
    setLoading(true);
    const get = defaultPaginationList('resources', searchData)
    get.then((res)=>{
      setResourceList(res.data.data);
      setSize(res.data.size);
    }).catch((error)=>{
      toast.error(error.response.data.message)
    })
    .finally(()=>[
      setLoading(false), 
      setLoadingScreen(false),
    ]);
  }

  function handleDelete(){
    const del = delResources(idResource);
    del.then((res)=>{
      if(res.data.status === 'success'){
        toast.success(res.data.message);
        setOpenModalDelete(false);
        setLoadingScreen(true);
      }
      if(res.data.status === 'error'){
        toast.error(res.data.message);
      }
    }).catch((error)=>{
      toast.error(error.response.data.message);
    })
  }

  // function downloadResource (id: any, name: any, extension: any) {
  //   const get = downResource(id);
  //   get.then((res)=>{
  //     if(res.statusText === 'OK'){
  //       toast.success('resource downloaded successfully!');
  //       handleDownload(res, name, extension);
  //     } else {
  //       toast.error('Sorry, an error occurred!');
  //     }
  //   }).catch((error)=>{
  //     if(error.response){
  //       toast.error('Action cannot be performed at this time!');
  //     } else {
  //       toast.error('Sorry, an error occurred!');
  //     }
  //   })
  // }
  function handleGetResource(hash: any) {
    const get = getResourceLink(hash);
    // get.then((res)=>{

    //   let myBlob = new Blob([res.data], {
    //     type: "application/pdf",
    //   });

    //   const fileURL = URL.createObjectURL(myBlob);
    //   window.open(fileURL);
    // })
  }

  

  return (
    <>
    <div>
        <h3 className='card-title align-items-start flex-column'>
            <p className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: 'Resources List'})}</p>
            <p className='text-gray-600 fw-bold fs-7'>{intl.formatMessage({id: 'Home - Resources'})}</p>
          </h3>
    </div>
      {
        loading?
        <Load/>
        :
        <div className='card mb-5 mb-xl-10 w-100 mx-auto rounded-4 p-2'>
          <ModalMessage
            message={`${intl.formatMessage({id: 'Are you sure you want to delete this item?'})}`}
            title={`${intl.formatMessage({id: 'Attention'})}`}
            showModal={openModalDelete}
            onClose={()=>setOpenModalDelete(false)}
            onAction={handleDelete}
          />
          <div className='card-header pt-5' >
            <div className="row  py-6 d-flex align-items-center justify-content-end w-100">
                <div className="col-md-4">

                  <input
                    type="text"
                    className="form-control form-control-solid fs-6 rounded-3"
                    placeholder={`${intl.formatMessage({id: 'Search a form'})}`}
                    autoComplete='off'
                    style={{height: '40px'}}
                    value={titleFilter}
                    onChange={(e)=>[setTitleFilter(e.target.value)]}
                    onKeyDown={(e)=>e.which === 13 ? handleGetList(null) : '' }
                   
                  />

                </div>
                
                  <div className='col-md-2 '>
                        <select className='form-select form-control form-control-solid fs-6' 
                          onChange={(e)=>[setModelFilter(e.target.value)]}
                          value={modelFilter}
                          style={{height: '40px'}}

                        >
                          <option value='' >{intl.formatMessage({id: 'All Models'})}</option> 
                          <option value='action' >{intl.formatMessage({id: 'Action'})}</option>
                          <option value='system'>{intl.formatMessage({id: 'System'})}</option>
                        </select>
                  </div>
                  <div className='col-md-2 '>
                        <select className='form-select form-control form-control-solid fs-6' 
                          onChange={(e)=>[setTypeFilter(e.target.value)]}
                          value={typeFilter}
                          style={{height: '40px'}}

                        >
                          <option value='' >{intl.formatMessage({id: 'All Types'})}</option> 
                          <option value='1' >{intl.formatMessage({id: 'Url'})}</option>
                          <option value='2'>{intl.formatMessage({id: 'Form'})}</option>
                          <option value='3' >{intl.formatMessage({id: 'File'})}</option>
                          <option value='4'>{intl.formatMessage({id: 'Video'})}</option>
                          <option value='5' >{intl.formatMessage({id: 'Term'})}</option>
                        </select>

                        
                  </div>
                  
                  <div className='col-md-4 d-flex justify-content-between align-items-center' id='positionButtonCalc'>
                      <button 
                      type='button' 
                      className='btn bg-hover-dark  text-white' 
                      disabled={loading}
                      onClick={()=>handleGetList(null)}
                      style={{width: '125px',height: '40px', background: '#65638E', borderRadius: 13, marginRight: '10px'}}
                      >
                      {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Search'})}</span>}
                        {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}                      </button>

                    {
                      user.roles === 'super-admin' || user.roles === 'admin'?
                     
                        <button
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          data-bs-trigger='hover'
                          className='btn btn-sm btn-dark rounded-3'
                          onClick={()=>props.create(true)}
                          title={`${intl.formatMessage({id: 'Create resource'})}`}
                          style={{
                            height: '39px', width: '125px', marginRight: window.screen.width > 767 ? '25px' : '0px',
                            padding: window.screen.width > 767 && window.screen.width < 840  ? '1px': '5px'
                          }}
                        >
                          <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                          {intl.formatMessage({id: 'New resource'})} 
                        </button>
                      :
                      <></>
                    }
                  </div>
                   
               

            </div>
            
           
          </div>

          



          <div className='card-body py-3'>   
            {
              resourceList.length !== 0?
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-150px'>{intl.formatMessage({id: 'TITLE'})}</th>
                      <th className='min-w-140px'>{intl.formatMessage({id: 'MODEL'})}</th>
                      <th className='min-w-120px text-center'>{intl.formatMessage({id: 'PRIVATE'})}</th>
                      <th className='min-w-120px text-center'>{intl.formatMessage({id: 'LANGUAGE'})}</th>
                      <th className='min-w-120px text-center'>{intl.formatMessage({id: 'DATE'})}</th>
                      <th className='min-w-100px text-center'>{intl.formatMessage({id: 'ACTIONS'})}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      resourceList.data.map((item: any, index: any)=>
                      
                        <tr key={index}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-gray-700 fw-bold fs-6'>
                                  {item.title}
                                </span>
                                <span className='text-gray-600 fw-bold fs-7'>
                                  {/* {
                                    item.extension ? 
                                    item.extension : (
                                      item.type === '1'
                                      ? 'URL'
                                      : item.type === '2'
                                      ? 'Form'
                                      : item.type === '3'
                                      ? 'File'
                                      : item.type === '4'
                                      ? 'Video'
                                      : item.type === '5'
                                      ? 'User term'
                                      : ''
                                    )
                                  } | {item.model} */}

{
                                    item.type === '1'
                                    ? 'url'
                                    : item.type === '2'
                                    ? 'form'
                                    : item.type === '3'
                                    ? 'file'
                                    : item.type === '4'
                                    ? 'video'
                                    : item.type === '5'
                                    ? 'term'
                                    : 'language'
                                  } {item.extension ? (' | '+item.extension) : null} {item.size?(' | '+item.size) : null}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-gray-700 fw-bold fs-6'>
                                  {
                                    item.model === 'action'
                                    ? <span className="badge badge-light-info">{intl.formatMessage({id: 'Action'})}</span>
                                    : item.model === 'system'
                                    ? <span className="badge badge-light-warning">{intl.formatMessage({id: 'System'})}</span>
                                    : <span className="badge badge-light-secondary">{intl.formatMessage({id: 'Not Found'})}</span>
                                  }
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center justify-content-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-gray-700 fw-bold fs-6'>
                                  {
                                    item.free === 0
                                    ? <span className="badge badge-light-warning">{intl.formatMessage({id: 'Public'})}</span>
                                    : <span className="badge badge-light-success">{intl.formatMessage({id: 'Private'})}</span>
                                  }
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center justify-content-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-gray-600 fw-bold fs-7'>
                                  {item.language}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center justify-content-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-gray-600 fw-bold fs-7'>
                                  {converteData(item.created_at)}
                                </span>
                              </div>
                            </div>
                          </td>
                         
                          
                          <td>
                            <div className='d-flex justify-content-center flex-shrink-0'>
                              {/* <button
                                className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                title='Edit resource'
                                onClick={()=>props.edit(
                                  true, 
                                  item.id, 
                                {
                                  title: item.title,
                                  url: item.url,
                                  type: item.type,
                                  free: item.free,
                                  model: item.model,
                                  modelId: item.model_id,
                                })}
                              >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 svg-icon-primary' />
                              </button> */}


                                       {
                                          item.type == 2 && item.form !== null ?
                                          <button
                                                  className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                                  title={`${intl.formatMessage({id: 'Copy'})}`}
                                                  onClick={() => copyText(item.form, intl)}
                                                >
                                                  {/* <i className="fas fa-eye text-info"></i> */}

                                                  <KTSVG
                                                    path='/media/icons/duotune/general/gen028.svg'
                                                    className='svg-icon-2x svg-icon-success'
                                                  />
                                          </button>
                                          :
                                          null
                                        }

                                        {
                                          item.type == 1 && item.url !== null ?
                                          <a
                                            href={`${item.url}`}
                                            target='_blank'
                                            className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                            title='URL'
                                          >
                                            <i className="fas fa-eye text-info"></i>

                                            {/* <KTSVG
                                              path='/media/icons/duotune/coding/cod007.svg'
                                              className='svg-icon-2 svg-icon-info'
                                            /> */}
                                          </a>
                                          :
                                          null
                                        }

                                        {
                                          ['3','4','5', '6'].includes(item.type) ?
                                          <>
                                            {/* <button
                                              className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                              title='Download'
                                              onClick={() => downloadResource(item.id, item.original_name, item.extension)}
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/files/fil021.svg'
                                                className='svg-icon-2x svg-icon-primary'
                                              />
                                            </button> */}
                                            <button
                                              onClick={() => handleGetResource(item.hash_name) } 
                                              className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                              title={`${intl.formatMessage({id: 'View'})}`}
                                            >
                                              <i className="fas fa-eye text-info"></i>

                                              {/* <KTSVG
                                                path='/media/icons/duotune/files/fil024.svg'
                                                className='svg-icon-2x svg-icon-info'
                                              /> */}
                                            </button>
                                          </>
                                          :
                                          null
                                        }
                              {/* {
                                item.url !== null ?
                                <a
                                  href={`${item.url}`}
                                  target='_blank'
                                  className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                  title='URL'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/coding/cod007.svg'
                                    className='svg-icon-2 svg-icon-info'
                                  />
                                </a>
                                :
                                <>
                                  <button
                                    className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                    title='View resource'
                                    onClick={()=>downloadResource(item.id, item.original_name, item.extension)}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/files/fil021.svg'
                                      className='svg-icon-2x svg-icon-primary'
                                    />
                                  </button>
                                  <button
                                    onClick={()=>handleGetResource(item.hash_name)}
                                    className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm me-1'
                                    title='View resource'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/files/fil024.svg'
                                      className='svg-icon-2x svg-icon-info'
                                    />
                                  </button>
                                </>
                              } */}
                              {
                                user.roles === 'super-admin' || user.roles === 'admin'?
                                <button
                                  className='btn btn-icon btn-bg-light bg-hover-secondary btn-sm'
                                  onClick={()=>[setIdResource(item.id), setOpenModalDelete(true)]}
                                  title={`${intl.formatMessage({id: 'Delete resource'})}`}
                                >
                              <img alt='Logo' src={toAbsoluteUrl('/media/logos/🦆 icon _trash_.svg')} style={{marginRight: '10px'}} />
                                </button>
                                :
                                <></>
                              }
                            </div>
                          </td>
                        </tr>
                       

                      )
                    }
                  </tbody>
                </table>
              </div>
              :
              <Warning message={`${intl.formatMessage({id: 'No resources to view yet'})}`} />
            }


            { 
              user.roles === 'admin' || user.roles === 'super-admin' ?
                <small>{intl.formatMessage({id: 'Total resource size:'})} {size}</small>
              :
              null
            }
             


            <Pagination data={resourceList} onSearch={handleGetList} searchData={searchData}/>
          </div>
          
        </div>
      }
    </>
  )
}

export {ResourcesList}
